import {ICoreModules} from "./core-modules.interface";
import {MEGenericEntity} from "../../../maennl-commons";
import {TNullableBoolean, TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";

export class CoreModules
    extends MEGenericEntity<ICoreModules>
    implements ICoreModules {

    code: TNullableString=null;
    description: TNullableString=null;
    display_text: TNullableString=null;
    hidden: TNullableBoolean=false;
    id: TNullableNumber=null;
}