<div class="alert alert-info">
    Die Glukoseportal-Abrechnungen enthalten die Grundgebühr für den aktuellen Monat (Grundgebühr ist
    im Voraus fällig!) sowie die Vorgänge der gewählten Periode. Bitte nach Ablauf eines Monats nach
    Periodenablauf nicht mehr neuberechnen!
</div>
<me-data-table
        #abrechnungenTable
        *ngIf="abrechnungen!==null && abrechnungen!==undefined"
        [data]="abrechnungen.asDataTableRows()"
        [rowActionsAsButtons]="true"
        [selectable]="false"
        [tableActionsAsButtons]="true"
        actionsicon="icofont icofont-navigation-menu"
>
    <me-data-table-column [field]="'periode'" [label]="'Periode'"></me-data-table-column>
    <me-data-table-column [field]="'startDay'" [label]="'Start-Tag'"></me-data-table-column>
    <me-data-table-column [field]="'endDay'" [label]="'End-Tag'"></me-data-table-column>
    <me-data-table-column [field]="'username'" [label]="'Benutzer'"></me-data-table-column>
    <me-data-table-column [field]="'projektCode'" [label]="'Projekt'"></me-data-table-column>
    <me-data-table-column [field]="'tarif'" [label]="'Tarif'"></me-data-table-column>
    <me-data-table-column [field]="'transaktionen'" [label]="'Transaktionen'" type="long"></me-data-table-column>
    <me-data-table-column [field]="'kartenanfragen'" [label]="'Kartenanfragen'" type="long"></me-data-table-column>
    <me-data-table-column [field]="'summe'" [label]="'Summe'" type="currency"></me-data-table-column>
    <me-data-table-column [cfg]="{format:'YYYY-MM-DD HH:mm'}" [field]="'lastUpdated'" [label]="'Stand'"
                          type="date"></me-data-table-column>

    <me-data-table-column [cfg]="{format:'YYYY-MM-DD HH:mm'}" [field]="'abgerechnet'" [label]="'Abgerechnet'"
                          type="date"></me-data-table-column>

    <me-data-table-rowaction
            (perform)="markAbrechnungCleared($event)"
            [availableCheck]="recalcActionAvailable"
            icon="icofont icofont-checked"
            label="Abgerechnet"
            title="Abrechnung als abgerechnet markieren"
    ></me-data-table-rowaction>

    <me-data-table-rowaction
            (perform)="recalcAbrechnungGlukoseportal($event)"
            [availableCheck]="recalcActionAvailable"
            icon="icofont icofont-refresh"
            label="Berechnen"
            title="Abrechnung neu durchrechnen"
    ></me-data-table-rowaction>

    <me-data-table-rowaction
            (perform)="displayAbrechnungGlukoseportal($event)"
            icon="icofont icofont-info"
            label="Details"
            title="Abrechnung anschauen und drucken"

    ></me-data-table-rowaction>

    <me-data-table-table-extra-header>
        <me-pagination
                (pageChange)="abrechnungen.setPage($event)"
                (pageCountChange)="abrechnungen.processPageCountChangedEvent($event)"
                [page]="abrechnungen.page"
                [perpage]="abrechnungen.size"
                [settingsService]="benutzerService"
                [total]="abrechnungen.total"
                settingsid="abrehcnung-glukoseportal"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
