<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'heartbeat']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Test-Pings"
        subtitle="neues Test-Ping-System"
></me-area-heading>

<me-card>
    <app-sys-health-test-pings-tab
            [id]="'SH-tab-testpings'"
    ></app-sys-health-test-pings-tab>
</me-card>

