import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'me-date-time-range-selector',
  template: `
    <me-date-time-field
      [label]="startLabel"
      [value]="range[0]"
      (valueChange)="updateRange($event, 0)"
      [nullable]="nullable"
      [displayFormat]="displayFormat"
      [hasSeconds]="hasSeconds"
    ></me-date-time-field>
    <me-date-time-field
      [label]="endLabel"
      [value]="range[1]"
      (valueChange)="updateRange($event, 1)"
      [nullable]="nullable"
      [displayFormat]="displayFormat"
      [hasSeconds]="hasSeconds"
    ></me-date-time-field>
  `,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MEDateTimeRangeSelectorComponent implements OnInit {
  public startLabel = 'Startzeitpunkt';
  public endLabel = 'Endzeitpunkt';

  public range: Date[] = [null, null];

  @Input() nullsAsNull = true;

  @Output() valueChange: EventEmitter<Date[]> = new EventEmitter<Date[]>();

  @Input() nullable = true;
  @Input() displayFormat: any = 'LLL';
  @Input() hasSeconds = false;

  @Input()
  set value(v: Date[]) {
    let changed = false;
    if (v == null && this.nullable) {
      if (this.range[0] != null) {
        changed = true;
      }
      if (this.range[1] != null) {
        changed = true;
      }
      this.range = [null, null];
    } else if (v !== null && v.length === 0 && this.nullable) {
      if (this.range[0] != null) {
        changed = true;
      }
      if (this.range[1] != null) {
        changed = true;
      }
      this.range = [null, null];
    } else if (v !== null && v.length === 1) {
      if (v[0] !== null || this.nullable) {
        if (this.range[0] !== v[0]) {
          changed = true;
        }
        if (this.range[1] !== v[0]) {
          changed = true;
        }
        this.range = [v[0], v[0]];
      }
    } else if (v !== null) {
      if ((v[0] != null || this.nullable) && (v[1] != null || this.nullable)) {
        if (this.range[0] !== v[0]) {
          changed = true;
        }
        if (this.range[1] !== v[1]) {
          changed = true;
        }
        this.range = [v[0], v[1]];
      }
    }

    if (changed) {
      this.notify();
    }

    this.cd.markForCheck();
  }

  notify() {
    if (this.range[0] == null && this.range[1] === null && this.nullsAsNull) {
      this.valueChange.emit(null);
    } else {
      this.valueChange.emit(this.range);
    }
  }

  updateRange(value: Date, pos: number) {
    if (value !== null || this.nullable) {
      const changed = this.range[pos] !== value;
      this.range[pos] = value;
      if (changed) {
        this.notify();
      }
      this.cd.markForCheck();
    }
  }

  constructor(public cd: ChangeDetectorRef) {
    this.range = [null, null];
  }

  ngOnInit() {
    let c = false;
    if (!this.nullable) {
      if (this.range[0] === null) {
        this.range[0] = new Date();
        c = true;
      }
      if (this.range[1] === null) {
        this.range[1] = new Date();
        c = true;
      }
    }
    if (c) {
      this.notify();
    }
    this.cd.markForCheck();
  }
}
