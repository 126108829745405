<me-data-table
        *ngIf="liste!==null && liste!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="liste.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        #tabelle
>
    <me-data-table-column [label]="'Automat'"
                          [field]="'nebenstelle_name'"
                          [title]="'Tankautomat'"
                          [renderer]="nebenstelleR"
    ></me-data-table-column>
    <me-data-table-column [label]="'Adresse'"
                          [field]="'ip_address'"
                          [title]="'IP-Adresse Tankautomat'"
    ></me-data-table-column>
    <me-data-table-column [label]="'Terminal-Adresse'"
                          [field]="'terminal_addr'"
                          [title]="'Terminal-Adresse Tankautomat'"
    ></me-data-table-column>

    <me-data-table-column [label]="'Zeitpunkt letzter Lauf'"
                          [field]="'last_run'" type="date"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
                          [title]="'Datumstempel auf unserem Server'"
    ></me-data-table-column>
    <me-data-table-column [label]="'Zeitpunkt nächster Lauf'"
                          [field]="'next_run'" type="date"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
                          [title]="'Datumstempel auf unserem Server'"
    ></me-data-table-column>

    <me-data-table-column [label]="'Versuchszähler'" [field]="'versuche'"></me-data-table-column>

    <me-data-table-table-extra-header>
        <me-pagination
                [total]="liste.total"
                [page]="liste.page"
                [perpage]="liste.size"
                (pageChange)="liste.setPage($event)"
                (pageCountChange)="liste.processPageCountChangedEvent($event)"
                (queryChange)="liste.processQueryChangedEvent($event)"
                settingsid="clearing-preise-mwstkorrektur"
                [settingsService]="benutzerService"
                [showSearch]="true"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
