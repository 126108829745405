<div class="modal-header">
    <h4 class="modal-title">AT-Kommandos ausführen</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">

    <div *ngIf="!connected" class="alert alert-info">
        Um AT-Kommandos ausführen zu können, muss zunächst eine Verbindung zum
        Ziel aufgebaut werden.<br/>
        <br/>
        <div class="text-center">
            <button class="btn btn-primary" (click)="connect()"><i class="far fa-plug"></i> Verbinden</button>
        </div>
    </div>

    <div *ngIf="connected" class="d-flex justify-content-between">
        <div class="w-50">
            <me-text-field [label]="'Kennwort:'" [(value)]="entity.commandPassword"></me-text-field>
            <me-text-field [label]="'Befehl:'" [(value)]="command"></me-text-field>
            <div class="text-right">
                <button class="btn btn-primary" [disabled]="btnDisbled" (click)="run()"><i class="far fa-bolt"></i>
                    Ausführen
                </button>
            </div>

            <me-tab-set>
                <me-pane title="Kommandos">
                    <button class="btn btn-secondary me-1"
                            *ngFor="let c of commandos" [title]="c.cmd" (click)="command=c.cmd">{{ c.label }}</button>
                </me-pane>
                <me-pane title="G781/G786">
                    <div class="cfgcontainer">
                        <div class="inner">
                            <me-text-field *ngFor="let c of g781; let idx=index"
                                           [label]="c.label"
                                           [value]="c.value"
                                           (valueChange)="setValue('g781',idx,$event)"
                                           [addonicon]="['far fa-cloud-download','far fa-cloud-upload']"
                                           [addonlabel]="['Wert abrufen','Wert senden']"
                                           [enableAddon]="true"
                                           (addonClick)="tfAction(c,$event)"

                            ></me-text-field>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-secondary" (click)="loadConfig(g781,0)"><i
                                class="far fa-cloud-download"></i> Alle Werte abrufen
                        </button>
                        <button class="btn btn-secondary" (click)="saveConfig(g781,0)"><i
                                class="far fa-cloud-upload"></i> Alle Werte speichern
                        </button>
                    </div>
                </me-pane>
            </me-tab-set>
        </div>
        <div class="flex-fill ps-5">
            <me-text-field labelWidth="0"
                           [label]="'Verlauf'"
                           [(value)]="verlauf"
                           [multiline]="true" [cols]="50" [rows]="20"
                           class="w-100"></me-text-field>
            <div class="d-flex justify-content-center">
                <button class="btn btn-secondary" (click)="verlauf=''"><i
                        class="far fa-trash"></i> Verlauf leeren
                </button>
            </div>
        </div>
    </div>


</div>
<div class="modal-footer d-flex justify-content-between">
    <div>
        <span class="badge bg-primary">{{ entity.label }}</span>
    </div>
    <div>
        <button class="btn btn-primary" (click)="connect()" *ngIf="!connected"><i class="far fa-plug"></i> Verbinden
        </button>
        <button class="btn btn-primary" (click)="disconnect()" *ngIf="connected">
            <span style="font-size: 70%">
            <span class="fa-stack">
                <i class="far fa-plug fa-stack-1x"></i>
                <i class="far fa-ban fa-stack-2x"></i>
            </span>
            </span>
            Trennen
        </button>
        <button type="button" class="btn btn-secondary" (click)="cancel()">
            <i class="icofont icofont-close"></i> Schließen
        </button>
    </div>
</div>
