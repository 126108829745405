import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {
  MEHighlitePipe,
  MELocalizedComponent,
  MESelectComponent,
  MESubscriptionHelper,
  TMESelectItemFormatterCallback,
  TMESelectItemMatcherCallback,
  TMESelectItemTextCallback,
} from '../../../maennl-commons';
import {
  TNullableNumber,
  TNullableString,
} from '../../../maennl-commons/tools/types';
import { Firewall } from '../commons/firewall.class';
import { FirewallList } from '../commons/firewall.list';
import { FirewallService } from '../commons/firewall.service';

@Component({
  selector: 'app-firewall-selector',
  templateUrl: './firewall-selector.component.html',
  styleUrls: ['./firewall-selector.component.scss'],
})
export class FirewallSelectorComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() nullable = true;
  @Input() placeholder = 'Firewall auswählen';
  @Input() small = false;

  @Input()
  set value(v: Firewall) {
    if (this._value !== v) {
      this._value = v;
      if (this.meSelect !== null && this.meSelect !== undefined) {
        this.meSelect.value = v;
      }
      this.valueChange.emit(v);
      this.valueIdChange.emit(this.valueId);
    }
  }

  get value(): Firewall {
    return this._value;
  }

  @Input() filterQuery = '';

  private _value: Firewall = null;

  @Output() valueChange = new EventEmitter<Firewall>();
  @Output() valueIdChange = new EventEmitter<TNullableNumber>();
  @ViewChild(MESelectComponent, { static: false }) meSelect: MESelectComponent;

  liste = new FirewallList();

  ngAfterViewInit(): void {
    if (this.meSelect !== null && this.meSelect !== undefined) {
      this.meSelect.onFormat = this.itemFormat;
      this.meSelect.onGetText = this.itemText;
      this.meSelect.onMatch = this.itemMatcher;
      this.meSelect.value = this._value;
    }
    this.liste.start();
  }

  constructor(
    public firewallService: FirewallService,
    public cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe((list: FirewallList) => {
        list.searchString2 = ('' + this.filterQuery).trim();
        this.firewallService
          .list(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.getQuery()
          )
          .subscribe(l => {
            list.populateFromListResult(l);
            this.cd.markForCheck();
          }, undefined);
      }, undefined)
    );

    if (this.meSelect !== null && this.meSelect !== undefined) {
      this.meSelect.onFormat = this.itemFormat;
      this.meSelect.onGetText = this.itemText;
      this.meSelect.onMatch = this.itemMatcher;
    }
    this.liste.start();
  }

  doSearch(value: TNullableString) {
    if (value === null || value === undefined) {
      value = '';
    }

    this.liste.simpleFilter.clear();
    this.liste.searchString2 = ('' + this.filterQuery).trim();
    this.liste.searchString = value.trim();
    this.liste.reload();
  }

  doSelect(value: Firewall) {
    this._value = value;
    this.valueChange.emit(value);
  }

  public itemFormat: TMESelectItemFormatterCallback<Firewall> = (
    item: Firewall,
    search,
    forList: boolean
  ) => {
    if (item === null || item === undefined) {
      return '';
    }
    return MEHighlitePipe.transformString('' + item.name, search);
  };

  public itemText: TMESelectItemTextCallback<Firewall> = (item: Firewall) => {
    if (item === null || item === undefined) {
      return '';
    }
    return item.name;
  };

  public itemMatcher: TMESelectItemMatcherCallback<Firewall> = (
    item: Firewall,
    search
  ) => {
    if (!search) {
      return true;
    }
    if (search.trim() === '') {
      return true;
    }
    if (!item) {
      return false;
    }
    const x = item.name + ' ' + `${item.id}` + ' ' + item.code;

    return x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
  };

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.liste.release();
    super.ngOnDestroy();
  }

  get valueId(): TNullableNumber {
    if (this.value === null || this.value === undefined) {
      return null;
    }
    return this.value.id;
  }

  @Input()
  set valueId(val: TNullableNumber) {
    if (val === null) {
      this.value = null;
      return;
    }
    if (val === this.valueId) {
      return;
    }
    this.firewallService.get(val).subscribe({
      next: v => {
        this.value = v;
      },
      error: () => {
        this.value = null;
      },
    });
  }
}
