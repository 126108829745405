<me-data-table
        *ngIf="liste!==null && liste!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="liste.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        (sortChanged)="liste.processSortChangedEvent($event)"
        [extraRowClasses]="extraRowClasses"
        #clientsTable
>
    <ng-template #imei let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <div *ngIf="t.ip!==''">{{ t.ip }}</div>
            <div class="small text-nowrap"
                 [class.small]="t.ip!==''"
            >
                <ng-container *ngIf="t.tracker_identification!==null && t.tracker_identification!==undefined">
                    <ng-container *ngIf="t.tracker_identification.length===15">
                        <span>{{ t.imei_base }}</span><span
                            style="margin-left: 2px;color:#aaaaaa;">{{ t.imei_addon }}</span>
                    </ng-container>
                    <ng-container *ngIf="t.tracker_identification.length!==15">
                        {{ t.tracker_identification }}
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </ng-template>

    <me-data-table-column [label]="'IP/IMEI'"
                          [field]="'tracker_last_address'"
                          [sortable]="true"
                          [template]="imei"
    ></me-data-table-column>

    <ng-template #tenant let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <div style="min-height: 45px;">
                {{ t.tenant_info }}
                <span class="badge bg-secondary text-dark">{{ t.tenant_projekt }}</span>
            </div>
            <div *ngIf="benutzerService.canEditWebfuelTracker()" class="small">
                <button class="btn btn-sm btn-info" (click)="moveTracker(t)">verschieben</button>
            </div>
        </ng-container>
    </ng-template>

    <me-data-table-column [label]="'Mandant'"
                          [field]="'tenant_info'"
                          [sortable]="true"
                          [template]="tenant"
    >

    </me-data-table-column>
    <ng-template #fahrzeug let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <div style="min-height: 45px;">

                <div>
                    <i [ngClass]="t.fahrzeug_art_icon" *ngIf="t.fahrzeug_art_icon!==''"></i>
                    {{ t.vehicle_display_text }}
                    <span class="badge bg-secondary text-dark">{{ t.vehicle_nummer }}</span>
                </div>
                <div class="d-flex justify-content-start small">
                    <div class="me-2 me-2" title="km-Stand">
                        <i class="fa-light fa-tachometer me-1 me-1"></i>
                        <span *ngIf="t.vehicle_km_stand_metres>0">{{ fNum(t.vehicle_km_stand_metres / 1000, "1.1-1") }}</span>
                        <span *ngIf="t.vehicle_km_stand_metres===0 || t.vehicle_km_stand_metres===null">{{ fNum(t.vehicle_km_stand, "1.0-0") }}</span>
                    </div>
                    <div class="me-2 me-2" title="Betriebsstunden">
                        <i class="fa-light fa-hourglass-clock me-1 me-1"></i>
                        {{ fDuration(t.vehicle_working_hours_as_duration, false, false, true) }}
                    </div>
                </div>
            </div>
            <div *ngIf="benutzerService.canEditWebfuelTracker()" class="small">
                <button class="btn btn-sm btn-info" (click)="editVehicle(t)">bearbeiten</button>
            </div>
        </ng-container>
    </ng-template>

    <me-data-table-column [label]="'Fahrzeug'"
                          [field]="'vehicle_display_text'"
                          [sortable]="true"
                          [template]="fahrzeug"
    ></me-data-table-column>
    <ng-template #tracker_typ let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <div style="min-width: 45px">
                <div class="d-flex justify-content-between align-items-start">
                    <div class="flex-fill">
                        {{ t.tracker_typ_display_text }}
                        <span class="badge bg-secondary text-dark">{{ t.tracker_typ_code }}</span>
                    </div>
                    <div *ngIf="t.tracker_obj.mitschnitt_aktiv" class="has-cursor-pointer" (click)="open_mitschnitt(t)">
                        <span class="badge bg-danger text-light">MITSCHNITT</span>
                    </div>
                </div>
                <div class="d-flex justify-content-start small"
                     *ngIf="t.tracker_last_seen!==null && t.tracker_last_seen!==undefined">
                    <div class="me-2 me-2" title="letzte Meldung">
                        <i class="fa-light fa-calendar me-1 me-1"></i>
                        {{ fDate(t.tracker_last_seen, 'YYYY-MM-DD HH:mm:ss', true) }}
                    </div>
                    <div class="me-2 me-2 "
                         title="Spannung am {{ fDate(t.tracker_last_voltage_zp,'YYYY-MM-DD HH:mm:ss',true) }}">
                        <i class="fa-light fa-bolt me-1 me-1"></i> {{ fNum(t.tracker_last_voltage, '1.2-2') }} V
                    </div>
                    <div class="me-2 me-2 " title="Status Zündung beim letzten Datenempfang"
                         [class.text-success]="t.tracker_obj.last_zuendung"
                         [class.text-secondary]="!t.tracker_obj.last_zuendung"
                         [class.text-bold]="t.tracker_obj.last_zuendung"
                         [class.fw-bold]="t.tracker_obj.last_zuendung">
                        <i class="fa-light fa-fire me-1 me-1"></i>
                        <span *ngIf="t.tracker_obj.last_zuendung"> EIN</span>
                        <span *ngIf="!t.tracker_obj.last_zuendung"> AUS</span>
                    </div>
                    <div class="me-2 me-2 " title="Status GPS Aufzeichnung"
                         [class.text-info]="t.tracker_obj.gps_recording_enabled"
                         [class.text-secondary]="!t.tracker_obj.gps_recording_enabled">

                                    <span class="me-1">
                <fa-icon
                        [icon]="['fal',(t.tracker_obj.gps_recording_enabled) ? 'location-crosshairs' : 'location-crosshairs-slash']"></fa-icon>
            </span>
                        <span *ngIf="t.tracker_obj.gps_recording_enabled"> EIN</span>
                        <span *ngIf="!t.tracker_obj.gps_recording_enabled"> AUS</span>
                    </div>
                </div>
            </div>
            <div *ngIf="benutzerService.canEditWebfuelTracker()" class="small">
                <button class="btn btn-sm btn-info" (click)="editTracker(t)">bearbeiten</button>
            </div>
        </ng-container>
    </ng-template>

    <me-data-table-column [label]="'Tracker'"
                          [field]="'tracker_typ_display_text'"
                          [sortable]="true"
                          [template]="tracker_typ"
    ></me-data-table-column>

    <ng-template #last_position let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <ng-container
                    *ngIf="t.tracker_last_position_id!==null && t.tracker_last_position_id!==undefined && t.tracker_last_position_id>0">
                <div>
                    <a href="https://www.google.com/maps/?q={{t.lp_latitude}},{{t.lp_longitude}}" target="_blank">
                        lat {{ t.lp_latitude }}, lng {{ t.lp_longitude }}
                    </a>
                </div>

                <div class="d-flex justify-content-start small">
                    <div class="me-2 me-2" title="Zeitpunkt">
                        <i class="fa-light fa-calendar me-1 me-1"></i> {{ fDate(t.lp_zeitpunkt, 'YYYY-MM-DD HH:mm:ss', true) }}
                    </div>
                    <div class="me-2 me-2" title="km-Stand">
                        <i class="fa-light fa-tachometer me-1 me-1"></i>&nbsp;{{ fNum(t.lp_odometer, "1.1-1") }}
                    </div>
                    <div class="me-2 me-2" title="Betriebsstunden">
                        <i class="fa-light fa-hourglass-clock me-1 me-1"></i>
                        {{ fDuration(t.lp_working_hours_as_duration, false, false, true) }}
                    </div>
                    <div class="me-2 me-2 " title="Zündung" [class.text-success]="t.lp_zuendung"
                         [class.text-bold]="t.lp_zuendung" [class.fw-bold]="t.lp_zuendung">
                        <i class="fa-light fa-fire me-1 me-1"></i>
                        <span *ngIf="t.lp_zuendung"> EIN</span>
                        <span *ngIf="!t.lp_zuendung"> AUS</span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </ng-template>
    <me-data-table-column [label]="'letzte Position'"
                          [field]="'tracker_last_position_id'"
                          [sortable]="false"
                          [template]="last_position"
    ></me-data-table-column>


    <me-data-table-table-extra-header>
        <me-pagination
                [total]="liste.total"
                [page]="liste.page"
                [perpage]="liste.size"
                (pageChange)="liste.setPage($event)"
                (pageCountChange)="liste.processPageCountChangedEvent($event)"
                (queryChange)="liste.processQueryChangedEvent($event)"
                settingsid="webfuel-tracker-tracker"
                [settingsService]="benutzerService"
                [showSearch]="true"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
