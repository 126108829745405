import { Component, Injector } from '@angular/core';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer';
import { ICheckResult } from '../../common/icheck-result';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';
import { Check } from '../../common/check';

@Component({
  selector: 'app-medtcheck-status-renderer',
  template: `
    <app-check-status-display
      *ngIf="isCellValid()"
      [value]="cell"
      [title]="getTitle()"
    ></app-check-status-display>
  `
})
export class MEDTCheckStatusRendererComponent extends MEDataTableCommonCellRenderer {
  public cell: ICheckResult = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<Check> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }

  getTitle() {
    if (this.row !== null && this.row !== undefined) {
      return ('' + this.row.data.resultInfo).replace('"', "'");
    }
  }
}
