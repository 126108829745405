import {MEConverterTool} from '../../../maennl-commons/tools';

export class ZsrChunk {
    bytes: number[] = [];
    hexBytes: string[] = [];
    public showRaw = false;
    public showFillbytes = true;
    knownDirection = 0;
    type: string = '';

    public add(numByte: number) {
        this.bytes.push(numByte);
        this.hexBytes.push(
            MEConverterTool.right_string('000' + numByte.toString(16), 2)
        );
    }

    public hasBytes() {
        return this.bytes.length > 0;
    }

    isLeft(): boolean {
        return this.guessDirection() === 'left';
    }

    isRight(): boolean {
        return this.guessDirection() === 'right';
    }

    isCenter(): boolean {
        return this.guessDirection() === 'center';
    }

    public guessDirection() {
        if (this.knownDirection === 1 || this.knownDirection === 0x31) {
            return 'left';
        }
        if (this.knownDirection === 2 || this.knownDirection === 0x32) {
            return 'right';
        }
        if (this.knownDirection === 3 || this.knownDirection === 0x33) {
            return 'center';
        }
        if (this.bytes.length > 4) {
            if (this.getType() === 'status-anfrage') {
                return 'left';
            }
            if (this.getType() === 'status-telegramm') {
                return 'right';
            }
            if (this.getType() === 'grundpreis-verstellung') {
                return 'left';
            }
            if (this.getType() === 'sperren') {
                return 'left';
            }
            if (this.getType() === 'tankdaten') {
                return 'right';
            }
            if (this.getType() === 'zapfpunktsummen-anforderung') {
                return 'left';
            }
            if (this.getType() === 'zapfpunktsummen') {
                return 'right';
            }
        }
        return 'center';
    }

    private startsWith(numbers: number[]) {
        if (numbers.length <= this.bytes.length) {
            let found = true;
            numbers.forEach((b, idx) => {
                found = found && this.bytes[idx] === b;
            });
            return found;
        }
        return false;
    }

    private getType() {
        if (this.bytes.length > 3) {
            if (this.startsWith([0x02, 0xe1, 0xd2, 0x0f])) {
                this.type = 'status-anfrage';
                return 'status-anfrage';
            }
            if (this.startsWith([0x02, 0xf0, 0xd2, 0x0f])) {
                this.type = 'status-telegramm';
                return 'status-telegramm';
            }
            if (this.startsWith([0x02, 0xc3, 0xe1, 0x0f])) {
                this.type = 'grundpreis-verstellung';
                return 'grundpreis-verstellung';
            }
            if (this.startsWith([0x02, 0xc3, 0xb4, 0x0f])) {
                this.type = 'sperren';
                return 'sperren';
            }
            if (this.startsWith([0x02, 0xf0, 0xc3, 0x0f])) {
                this.type = 'tankdaten';
                return 'tankdaten';
            }
            if (this.startsWith([0x02, 0xe1, 0x78, 0x0f])) {
                this.type = 'zapfpunktsummen-anforderung';
                return 'zapfpunktsummen-anforderung';
            }
            if (this.startsWith([0x02, 0xd2, 0x78, 0x0f])) {
                this.type = 'zapfpunktsummen';
                return 'zapfpunktsummen';
            }
        }
        if (this.bytes.length > 6) {
            if (this.startsWith([0x02, 0xff, 0xe1, 0xff, 0xd2, 0xff, 0x0f])) {
                this.type = 'status-anfrage';
                return 'status-anfrage';
            }
            if (this.startsWith([0x02, 0xff, 0xf0, 0xff, 0xd2, 0xff, 0x0f])) {
                this.type = 'status-telegramm';
                return 'status-telegramm';
            }
            if (this.startsWith([0x02, 0xff, 0xc3, 0xff, 0xe1, 0xff, 0x0f])) {
                this.type = 'grundpreis-verstellung';
                return 'grundpreis-verstellung';
            }
            if (this.startsWith([0x02, 0xff, 0xc3, 0xff, 0xb4, 0xff, 0x0f])) {
                this.type = 'sperren';
                return 'sperren';
            }
            if (this.startsWith([0x02, 0xff, 0xf0, 0xff, 0xc3, 0xff, 0x0f])) {
                this.type = 'tankdaten';
                return 'tankdaten';
            }
            if (this.startsWith([0x02, 0xff, 0xe1, 0xff, 0x78, 0xff, 0x0f])) {
                this.type = 'zapfpunktsummen-anforderung';
                return 'zapfpunktsummen-anforderung';
            }
            if (this.startsWith([0x02, 0xff, 0xd2, 0xff, 0x78, 0xff, 0x0f])) {
                this.type = 'zapfpunktsummen';
                return 'zapfpunktsummen';
            }
        }
        this.type = 'dummy';
        return 'dummy';
    }

    public isDummy() {
        return this.getType() === 'dummy';
    }

    public getTitle() {
        if (this.getType() === 'status-anfrage') {
            return 'Status-Anfrage';
        }
        if (this.getType() === 'status-telegramm') {
            return 'Status-Telegramm';
        }
        if (this.getType() === 'grundpreis-verstellung') {
            return 'Grundpreis-Verstellung';
        }
        if (this.getType() === 'sperren') {
            return 'Sperren';
        }
        if (this.getType() === 'tankdaten') {
            return 'Tankdaten';
        }
        if (this.getType() === 'zapfpunktsummen-anforderung') {
            return 'Zapfpunktsummen-Anforderung';
        }
        if (this.getType() === 'zapfpunktsummen') {
            return 'Zapfpunktsummen';
        }
        return 'unbekannte/nicht näher definierte Daten';
    }

    public cmdBytes() {
        if (this.isDummy()) {
            return [];
        }
        const cmd = [];
        let found = false;
        this.bytes.forEach((b, idx) => {
            if (!found && idx > 0) {
                if (!this.hasFillbytes() || idx > 1) {
                    cmd.push(this.hexBytes[idx]);
                }
            }
            if (b === 0x0f) {
                if (this.hasFillbytes()) {
                    cmd.push(this.hexBytes[idx + 1]);
                }
                found = true;
            }
        });
        return cmd;
    }

    public dataBytes() {
        if (this.isDummy()) {
            return this.hexBytes;
        }
        const daten = [];
        let found = false;
        for (
            let idx = this.bytes.indexOf(0x0f) - 1;
            idx < this.bytes.length;
            idx++
        ) {
            if (idx >= 0) {
                const b = this.bytes[idx];
                if (found && idx < this.hexBytes.length - 1) {
                    if (!this.hasETX() || (this.hasETX() && idx < this.ETXpos())) {
                        if (this.hasFillbytes()) {
                            if (daten.length % 2 === 1) {
                                daten.push(this.hexBytes[idx]);
                            } else {
                                daten.push(
                                    MEConverterTool.right_string('0' + this.hexBytes[idx], 1)
                                );
                            }
                        } else {
                            daten.push(
                                MEConverterTool.right_string('0' + this.hexBytes[idx], 1)
                            );
                        }
                    }
                }
                if (b === 0x0f) {
                    if (this.hasFillbytes()) {
                        idx++;
                    }
                    found = true;
                }
            }
        }
        return daten;
    }

    public crcBytes() {
        if (this.isDummy()) {
            return [];
        }
        const crc = [];
        let found = false;
        for (let idx = this.ETXpos() - 1; idx < this.bytes.length; idx++) {
            if (idx >= 0) {
                const b = this.bytes[idx];
                if (found && idx < this.hexBytes.length) {
                    crc.push(this.hexBytes[idx]);
                }
                if (b === 0x03) {
                    if (this.hasFillbytes()) {
                        idx++;
                    }
                    found = true;
                }
            }
        }
        return crc;
    }

    public hasSTX() {
        return this.bytes.length > 0 && this.bytes[0] === 0x02;
    }

    public hasETX() {
        return (
            (this.bytes.length > 0 && this.bytes[this.bytes.length - 1] === 0x03) ||
            (this.bytes.length > 1 && this.bytes[this.bytes.length - 2] === 0x03) ||
            (this.bytes.length > 2 && this.bytes[this.bytes.length - 3] === 0x03) ||
            (this.bytes.length > 3 && this.bytes[this.bytes.length - 4] === 0x03) ||
            (this.bytes.length > 4 && this.bytes[this.bytes.length - 5] === 0x03)
        );
    }

    public ETXpos() {
        return this.bytes.lastIndexOf(0x03);
    }

    public hasFillbytes() {
        if (this.bytes.length > 5) {
            return (
                this.bytes[1] === 0xff &&
                this.bytes[3] === 0xff &&
                this.bytes[5] === 0xff
            );
        }
        return false;
    }

    getDataTitle(idx: number) {
        switch (this.type) {
            case 'status-telegramm':
                return this.getStatusTelegrammDataTitle(idx);
            case 'status-anfrage':
                return this.getStatusAnfrageDataTitle(idx);
            case 'grundpreis-verstellung':
                return this.getGrundpreisVerstellungDataTitle(idx);
            case 'sperren':
                return this.getSpeerenDataTitle(idx);
            case 'tankdaten':
                return this.getTankdatenDataTitle(idx);
            case 'zapfpunktsummen-anforderung':
                return this.getZapfpunktsummenAnforderungDataTitle(idx);
            case 'zapfpunktsummen':
                return this.getZapfpunktsummenDataTitle(idx);
            default:
                return 'dummy';
        }
    }

    getStatusTelegrammDataTitle(idx: number): string {
        let res = '';
        let bytes = this.dataBytes();
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                    res += 'Zapfpunktadresse: ' + bytes[0];
                    break;
                case 2:
                    res += 'Zapfpunktstatus: ' + ('0000' + parseInt(bytes[2], 16).toString(2)).slice(-4);
                    switch (bytes[2].toString().toLowerCase()) {
                        case '0':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '1':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '2':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '3':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '4':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '5':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '6':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '7':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '8':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case '9':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case 'a':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case 'b':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case 'c':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        case 'd':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        case 'e':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        case 'f':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        default:
                            break;
                    }
                    break;
                case 4:
                    if (bytes[4].toString() === '0') {
                        res += 'kein Zapfventil entnommen';
                    } else {
                        res += 'Zapfventil ' + Number('0x' + bytes[4].toString()) + ' entnommen';
                    }
                    break;
                case 6:
                    res += 'Belegung Autarksummen (LSD): ' + ('0000' + parseInt(bytes[6], 16).toString(2)).slice(-4);
                    switch (bytes[6].toString().toLowerCase()) {
                        case '1':
                            res += '\nSorte 1 (Links)';
                            break;
                        case '2':
                            res += '\nSorte 2 (Rechts)';
                            break;
                        case '3':
                            res += '\nSorte 1 (Links)\nSorte 2 (Rechts)';
                            break;
                        case '4':
                            res += '\nSorte 3 (Mitte)';
                            break;
                        case '5':
                            res += '\nSorte 1 (Links)\nSorte 3 (Mitte)';
                            break;
                        case '6':
                            res += '\nSorte 2 (Rechts)\nSorte 3 (Mitte)';
                            break;
                        case '7':
                            res += '\nSorte 1 (Links)\nSorte 2 (Rechts)\nSorte 3 (Mitte)';
                            break;
                        case '8':
                            res += '\nSorte 4';
                            break;
                        case '9':
                            res += '\nSorte 1 (Links)\nSorte 4';
                            break;
                        case 'a':
                            res += '\nSorte 2 (Rechts)\nSorte 4';
                            break;
                        case 'b':
                            res += '\nSorte 1 (Links)\nSorte 2 (Rechts)\nSorte 4';
                            break;
                        case 'c':
                            res += '\nSorte 3 (Mitte)\nSorte 4';
                            break;
                        case 'd':
                            res += '\nSorte 1 (Links)\nSorte 3 (Mitte)\nSorte 4';
                            break;
                        case 'e':
                            res += '\nSorte 2 (Rechts)\nSorte 3 (Mitte)\nSorte 4';
                            break;
                        case 'f':
                            res += '\nSorte 1 (Links)\nSorte 2 (Rechts)\nSorte 3 (Mitte)\nSorte 4';
                            break;
                        default:
                            break;
                    }
                    break;
                case 8:
                case 10:
                    res += 'Fehlercode Master: ' + bytes[8] + '' + bytes[10];
                    switch (bytes[8].toString().toLowerCase() + bytes[10].toString().toLowerCase()) {
                        case '00':
                            res += '\nImpulsfehler';
                            break;
                        case '01':
                            res += '\nZählerfehler';
                            break;
                        case '02':
                            res += '\nAnzeigefehler';
                            break;
                        case '03':
                            res += '\nNetzausfall';
                            break;
                        case '04':
                            res += '\nKein Fehler';
                            break;
                        case '05':
                            res += '\nNulltankung';
                            break;
                        case '07':
                            res += '\nTankungsabbruch infolge Fehler';
                            break;
                        case '08':
                            res += '\nTankfehler beim Austria - Zapfsäulenrechner';
                            break;
                    }
                    break;
                case 12:
                case 14:
                    res += 'Fehlercode Slave: ' + bytes[12] + '' + bytes[14];
                    break;
                case 16:
                case 18:
                    res += 'Telegrammvariante: ' + bytes[18] + '' + bytes[16];
                    if (bytes[18].toString() + bytes[16].toString() === '01') {
                        res += '\nVariante 01'
                    } else if (bytes[18].toString() + bytes[16].toString() === '00') {
                        res += '\nNeue Telegramme ZSR 83 gemäß Schnittstellenbeschreibung 270.76.0101.02'
                    }
                    break;
                case 20:
                    res += 'Belegung Autarksummen (MSD): ' + ('0000' + parseInt(bytes[20], 16).toString(2)).slice(-4);
                    switch (bytes[20].toString().toLowerCase()) {
                        case '1':
                            res += '\nSorte 5';
                            break;
                        case '2':
                            res += '\nSorte 6';
                            break;
                        case '3':
                            res += '\nSorte 5\nSorte 6 ';
                            break;
                        case '4':
                            res += '\nSorte 7';
                            break;
                        case '5':
                            res += '\nSorte 5\nSorte 7';
                            break;
                        case '6':
                            res += '\nSorte 6\nSorte 7';
                            break;
                        case '7':
                            res += '\nSorte 5\nSorte 6\nSorte 7';
                            break;
                        case '8':
                            res += '\nSorte 8';
                            break;
                        case '9':
                            res += '\nSorte 5\nSorte 8';
                            break;
                        case 'a':
                            res += '\nSorte 6\nSorte 8';
                            break;
                        case 'b':
                            res += '\nSorte 5\nSorte 6\nSorte 8';
                            break;
                        case 'c':
                            res += '\nSorte 7\nSorte 8';
                            break;
                        case 'd':
                            res += '\nSorte 5\nSorte 7\nSorte 8';
                            break;
                        case 'e':
                            res += '\nSorte 6\nSorte 7\nSorte 8';
                            break;
                        case 'f':
                            res += '\nSorte 5\nSorte 6\nSorte 7\nSorte 8';
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                    res += 'Zapfpunktadresse: ' + bytes[0]
                    break;
                case 1:
                    res += 'Zapfpunktstatus: ' + ('0000' + parseInt(bytes[1], 16).toString(2)).slice(-4)
                    switch (bytes[1].toString().toLowerCase()) {
                        case '0':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '1':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '2':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '3':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '4':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '5':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '6':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '7':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '8':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case '9':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case 'a':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case 'b':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case 'c':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        case 'd':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        case 'e':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        case 'f':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        default:
                            break;
                    }
                    break;
                case 2:
                    if (bytes[2].toString() === '0') {
                        res += 'kein Zapfventil entnommen';
                    } else {
                        res += 'Zapfventil ' + Number('0x' + bytes[2].toString()) + ' entnommen';
                    }
                    break;
                case 3:
                    res += 'Belegung Autarksummen (LSD): ' + ('0000' + parseInt(bytes[3], 16).toString(2)).slice(-4);
                    switch (bytes[3].toString().toLowerCase()) {
                        case '1':
                            res += '\nSorte 1 (Links)';
                            break;
                        case '2':
                            res += '\nSorte 2 (Rechts)';
                            break;
                        case '3':
                            res += '\nSorte 1 (Links)\nSorte 2 (Rechts)';
                            break;
                        case '4':
                            res += '\nSorte 3 (Mitte)';
                            break;
                        case '5':
                            res += '\nSorte 1 (Links)\nSorte 3 (Mitte)';
                            break;
                        case '6':
                            res += '\nSorte 2 (Rechts)\nSorte 3 (Mitte)';
                            break;
                        case '7':
                            res += '\nSorte 1 (Links)\nSorte 2 (Rechts)\nSorte 3 (Mitte)';
                            break;
                        case '8':
                            res += '\nSorte 4';
                            break;
                        case '9':
                            res += '\nSorte 1 (Links)\nSorte 4';
                            break;
                        case 'a':
                            res += '\nSorte 2 (Rechts)\nSorte 4';
                            break;
                        case 'b':
                            res += '\nSorte 1 (Links)\nSorte 2 (Rechts)\nSorte 4';
                            break;
                        case 'c':
                            res += '\nSorte 3 (Mitte)\nSorte 4';
                            break;
                        case 'd':
                            res += '\nSorte 1 (Links)\nSorte 3 (Mitte)\nSorte 4';
                            break;
                        case 'e':
                            res += '\nSorte 2 (Rechts)\nSorte 3 (Mitte)\nSorte 4';
                            break;
                        case 'f':
                            res += '\nSorte 1 (Links)\nSorte 2 (Rechts)\nSorte 3 (Mitte)\nSorte 4';
                            break;
                        default:
                            break;
                    }
                    break;
                case 4:
                case 5:
                    res += 'Fehlercode Master: ' + bytes[4] + '' + bytes[5];
                    switch (bytes[4].toString().toLowerCase() + bytes[5].toString().toLowerCase()) {
                        case '00':
                            res += '\nImpulsfehler';
                            break;
                        case '01':
                            res += '\nZählerfehler';
                            break;
                        case '02':
                            res += '\nAnzeigefehler';
                            break;
                        case '03':
                            res += '\nNetzausfall';
                            break;
                        case '04':
                            res += '\nKein Fehler';
                            break;
                        case '05':
                            res += '\nNulltankung';
                            break;
                        case '07':
                            res += '\nTankungsabbruch infolge Fehler';
                            break;
                        case '08':
                            res += '\nTankfehler beim Austria - Zapfsäulenrechner';
                            break;
                    }
                    break;
                case 6:
                case 7:
                    res += 'Fehlercode Slave: ' + bytes[6] + '' + bytes[7];
                    break;
                case 8:
                case 9:
                    res += 'Telegrammvariante: ' + bytes[9] + '' + bytes[8];
                    if (bytes[9].toString() + bytes[8].toString() === '01') {
                        res += '\nVariante 01'
                    } else if (bytes[9].toString() + bytes[8].toString() === '00') {
                        res += '\nNeue Telegramme ZSR 83 gemäß Schnittstellenbeschreibung 270.76.0101.02'
                    }
                    break;
                case 10:
                    res += 'Belegung Autarksummen (MSD): ' + ('0000' + parseInt(bytes[10], 16).toString(2)).slice(-4);
                    switch (bytes[10].toString().toLowerCase()) {
                        case '1':
                            res += '\nSorte 5';
                            break;
                        case '2':
                            res += '\nSorte 6';
                            break;
                        case '3':
                            res += '\nSorte 5\nSorte 6 ';
                            break;
                        case '4':
                            res += '\nSorte 7';
                            break;
                        case '5':
                            res += '\nSorte 5\nSorte 7';
                            break;
                        case '6':
                            res += '\nSorte 6\nSorte 7';
                            break;
                        case '7':
                            res += '\nSorte 5\nSorte 6\nSorte 7';
                            break;
                        case '8':
                            res += '\nSorte 8';
                            break;
                        case '9':
                            res += '\nSorte 5\nSorte 8';
                            break;
                        case 'a':
                            res += '\nSorte 6\nSorte 8';
                            break;
                        case 'b':
                            res += '\nSorte 5\nSorte 6\nSorte 8';
                            break;
                        case 'c':
                            res += '\nSorte 7\nSorte 8';
                            break;
                        case 'd':
                            res += '\nSorte 5\nSorte 7\nSorte 8';
                            break;
                        case 'e':
                            res += '\nSorte 6\nSorte 7\nSorte 8';
                            break;
                        case 'f':
                            res += '\nSorte 5\nSorte 6\nSorte 7\nSorte 8';
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getStatusAnfrageDataTitle(idx: number): string {
        let res = '';
        let bytes = this.dataBytes();
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                    res += 'Zapfpunktadresse: ' + bytes[0];
                    break;
                case 2:
                    res += 'Hex position ist nicht in Benutzung';
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                    res += 'Zapfpunktadresse: ' + bytes[0];
                    break;
                case 1:
                    res += 'Hex position ist nicht in Benutzung';
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getGrundpreisVerstellungDataTitle(idx: number): string {
        let res = '';
        let bytes = this.dataBytes();
        let zeichentoint = '';
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                case 2:
                case 4:
                case 6:
                    zeichentoint += bytes[6] + '' + bytes[4] + '' + bytes[2] + '' + bytes[0]
                    res += 'Mehrwertsteuer: ' + parseInt(zeichentoint, 10) / 100 + '%';
                    break;
                case 8:
                case 10:
                case 12:
                case 14:
                case 16:
                case 18:
                    res += 'Datum: ' + bytes[10] + '' + bytes[8] + '.' + bytes[14] + '' + bytes[12] + '.' + bytes[18] + '' + bytes[16];
                    break;
                case 20:
                case 22:
                case 24:
                case 26:
                    zeichentoint += bytes[26] + '' + bytes[24] + '' + bytes[22] + '' + bytes[20]
                    res += 'Grundpreis Sorte 1: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 28:
                case 30:
                case 32:
                case 34:
                    zeichentoint += bytes[34] + '' + bytes[32] + '' + bytes[30] + '' + bytes[28]
                    res += 'Grundpreis Sorte 2: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 36:
                case 38:
                case 40:
                case 42:
                    zeichentoint += bytes[42] + '' + bytes[40] + '' + bytes[38] + '' + bytes[36]
                    res += 'Grundpreis Sorte 3: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 44:
                case 46:
                case 48:
                case 50:
                case 52:
                case 54:
                    zeichentoint += bytes[54] + '' + bytes[52] + '' + bytes[50] + '' + bytes[48] + '' + bytes[46] + '' + bytes[44]
                    res += 'Abgabelimit Volumen: ' + parseInt(zeichentoint, 10) / 1000 + ' Liter';
                    break;
                case 56:
                case 58:
                case 60:
                case 62:
                case 64:
                case 66:
                    zeichentoint += bytes[66] + '' + bytes[64] + '' + bytes[62] + '' + bytes[60] + '' + bytes[58] + '' + bytes[56]
                    res += 'Abgabelimit Betrag: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 68:
                    res += 'Sortenkennziffer Sorte 1: ' + bytes[68];
                    break;
                case 70:
                    res += 'Sortenkennziffer Sorte 2: ' + bytes[70];
                    break;
                case 72:
                    res += 'Sortenkennziffer Sorte 3: ' + bytes[72];
                    break;
                case 74:
                    res += 'Steuerbits: ' + ('0000' + parseInt(bytes[74], 16).toString(2)).slice(-4);
                    switch (bytes[74].toString().toLowerCase()) {
                        case '0':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +1';
                            break;
                        case '1':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +1';
                            break;
                        case '2':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +1';
                            break;
                        case '3':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +1';
                            break;
                        case '4':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +1';
                            break;
                        case '5':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +1';
                            break;
                        case '6':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +1';
                            break;
                        case '7':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +1';
                            break;
                        case '8':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +2';
                            break;
                        case '9':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +2';
                            break;
                        case 'a':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +2';
                            break;
                        case 'b':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +2';
                            break;
                        case 'c':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +2';
                            break;
                        case 'd':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +2';
                            break;
                        case 'e':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +2';
                            break;
                        case 'f':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +2';
                            break;
                        default:
                            break;
                    }
                    break;
                case 76:
                    res += 'gespeicherte Tankungsnr.: ' + bytes[76] + ' (nur 1-9 gültig)';
                    if (bytes[76].toString() === '0') {
                        res += '\nZapfpunkt löscht alle Tankwertspeicher';
                    }
                    break;
                case 78:
                    res += 'freigegebene Sorte: ' + bytes[78];
                    if (bytes[78].toString() === '0') {
                        res += '\nAlle Sorten des Zapfpunktes sind freigegeben';
                    }
                    break;
                case 80:
                case 82:
                    res += 'Zapfpunktnummer: ' + bytes[82] + '' + bytes[80];
                    break;
                case 84:
                    res += 'aktuelle Bedienform: ' + bytes[84];
                    switch (bytes[84].toString()) {
                        case '0':
                            res += '\nnicht definiert';
                            break;
                        case '1':
                            res += '\nSelbstbedienungstanken';
                            break;
                        case'2':
                            res += '\nBedienungstanken';
                            break;
                    }
                    break;
                case 86:
                case 88:
                case 90:
                case 92:
                    zeichentoint += bytes[92] + '' + bytes[90] + '' + bytes[88] + '' + bytes[86]
                    res += 'Grundpreis Sorte 4: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 94:
                    res += 'Sortenkennziffer Sorte 4: ' + bytes[94];
                    break;
                case 96:
                    res += 'Zapfpunktadresse: ' + bytes[96];
                    break;
                case 98:
                case 100:
                case 102:
                case 104:
                    zeichentoint += bytes[104] + '' + bytes[102] + '' + bytes[100] + '' + bytes[98]
                    res += 'Grundpreis Sorte 5: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 106:
                    res += 'Sortenkennziffer Sorte 5: ' + bytes[106];
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                case 1:
                case 2:
                case 3:
                    zeichentoint += bytes[3] + '' + bytes[2] + '' + bytes[1] + '' + bytes[0]
                    res += 'Mehrwertsteuer: ' + parseInt(zeichentoint, 10) / 1000 + '%';
                    break;
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    res += 'Datum: ' + bytes[5] + '' + bytes[4] + '.' + bytes[7] + '' + bytes[6] + '.' + bytes[9] + '' + bytes[8];
                    break;
                case 10:
                case 11:
                case 12:
                case 13:
                    zeichentoint += bytes[13] + '' + bytes[12] + '' + bytes[11] + '' + bytes[10]
                    res += 'Grundpreis Sorte 1: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 14:
                case 15:
                case 16:
                case 17:
                    zeichentoint += bytes[17] + '' + bytes[16] + '' + bytes[15] + '' + bytes[14]
                    res += 'Grundpreis Sorte 2: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 18:
                case 19:
                case 20:
                case 21:
                    zeichentoint += bytes[21] + '' + bytes[20] + '' + bytes[19] + '' + bytes[18]
                    res += 'Grundpreis Sorte 3: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                    zeichentoint += bytes[27] + '' + bytes[26] + '' + bytes[25] + '' + bytes[24] + '' + bytes[23] + '' + bytes[22]
                    res += 'Abgabelimit Volumen: ' + parseInt(zeichentoint, 10) / 1000 + ' Liter';
                    break;
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                    zeichentoint += bytes[33] + '' + bytes[32] + '' + bytes[31] + '' + bytes[30] + '' + bytes[29] + '' + bytes[28]
                    res += 'Abgabelimit Betrag: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 34:
                    res += 'Sortenkennziffer Sorte 1: ' + bytes[34];
                    break;
                case 35:
                    res += 'Sortenkennziffer Sorte 2: ' + bytes[35];
                    break;
                case 36:
                    res += 'Sortenkennziffer Sorte 3: ' + bytes[36];
                    break;
                case 37:
                    res += 'Steuerbits: ' + ('0000' + parseInt(bytes[37], 16).toString(2)).slice(-4);
                    switch (bytes[37].toString().toLowerCase()) {
                        case '0':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +1';
                            break;
                        case '1':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +1';
                            break;
                        case '2':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +1';
                            break;
                        case '3':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +1';
                            break;
                        case '4':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +1';
                            break;
                        case '5':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +1';
                            break;
                        case '6':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +1';
                            break;
                        case '7':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +1';
                            break;
                        case '8':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +2';
                            break;
                        case '9':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +2';
                            break;
                        case 'a':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +2';
                            break;
                        case 'b':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt sperren\nnächste Tankungsnummer := +2';
                            break;
                        case 'c':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +2';
                            break;
                        case 'd':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +2';
                            break;
                        case 'e':
                            res += '\nalter Grundpreis, Anzeige des Rechners bleibt unverändert\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +2';
                            break;
                        case 'f':
                            res += '\nneuer Grundpreis wird in die Anzeige hinterlegt\nBit 1 nicht in Benutzung\nZapfpunkt freigeben\nnächste Tankungsnummer := +2';
                            break;
                        default:
                            break;
                    }
                    break;
                case 38:
                    res += 'gespeicherte Tankungsnr.: ' + bytes[38] + ' (nur 1-9 gültig)';
                    if (bytes[38].toString() === '0') {
                        res += '\nZapfpunkt löscht alle Tankwertspeicher';
                    }
                    break;
                case 39:
                    res += 'freigegebene Sorte: ' + bytes[39];
                    if (bytes[39].toString() === '0') {
                        res += '\nAlle Sorten des Zapfpunktes sind freigegeben';
                    }
                    break;
                case 40:
                case 41:
                    res += 'Zapfpunktnummer: ' + bytes[41] + '' + bytes[40];
                    break;
                case 42:
                    res += 'aktuelle Bedienform: ' + bytes[42];
                    switch (bytes[42].toString()) {
                        case '0':
                            res += '\nnicht definiert';
                            break;
                        case '1':
                            res += '\nSelbstbedienungstanken';
                            break;
                        case'2':
                            res += '\nBedienungstanken';
                            break;
                    }
                    break;
                case 43:
                case 44:
                case 45:
                case 46:
                    zeichentoint += bytes[46] + '' + bytes[45] + '' + bytes[44] + '' + bytes[43]
                    res += 'Grundpreis Sorte 4: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 47:
                    res += 'Sortenkennziffer Sorte 4: ' + bytes[47];
                    break;
                case 48:
                    res += 'Zapfpunktadresse: ' + bytes[48];
                    break;
                case 49:
                case 50:
                case 51:
                case 52:
                    zeichentoint += bytes[52] + '' + bytes[51] + '' + bytes[50] + '' + bytes[49];
                    res += 'Grundpreis Sorte 5: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 53:
                    res += 'Sortenkennziffer Sorte 5: ' + bytes[53];
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getSpeerenDataTitle(idx: number): string {
        let res = '';
        let bytes = this.dataBytes();
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                    res += 'Zapfpunktadresse: ' + bytes[0];
                    break;
                case 2:
                    res += 'Hex position ist nicht in Benutzung';
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                    res += 'Zapfpunktadresse: ' + bytes[0];
                    break;
                case 1:
                    res += 'Hex position ist nicht in Benutzung';
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getTankdatenDataTitle(idx: number): string {
        let res = '';
        let bytes = this.dataBytes();
        let zeichentoint = '';
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                case 2:
                case 4:
                case 6:
                case 8:
                case 10:
                    zeichentoint += bytes[10] + '' + bytes[8] + '' + bytes[6] + '' + bytes[4] + '' + bytes[2] + '' + bytes[0];
                    res += 'Tankvolumen: ' + parseInt(zeichentoint, 10) / 1000 + ' Liter';
                    break;
                case 12:
                case 14:
                case 16:
                case 18:
                case 20:
                case 22:
                    zeichentoint += bytes[22] + '' + bytes[20] + '' + bytes[18] + '' + bytes[16] + '' + bytes[14] + '' + bytes[12];
                    res += 'Betrag: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 24:
                    res += 'laufende Tankungsnummer: ' + bytes[24];
                    if (Number('0x' + bytes[24]) > 9) {
                        res += '\nungültige Tankungsnummer';
                    }
                    break;
                case 26:
                    res += 'Sortenkennziffer: ' + bytes[26];
                    if (Number('0x' + bytes[26]) > 8) {
                        res += '\nungültige Sortenkennziffer';
                    }
                    break;
                case 28:
                    res += 'Tankdatenstatus: ' + bytes[28];
                    switch (bytes[28].toString()) {
                        case '0':
                            res += '\nImpulsfehler';
                            break;
                        case '1':
                            res += '\nZählerfehler';
                            break;
                        case '2':
                            res += '\nAnzeigenfehler';
                            break;
                        case '3':
                            res += '\nNetzausfall';
                            break;
                        case '4':
                            res += '\nkein Fehler';
                            break;
                        case '5':
                            res += '\nNulltankung';
                            break;
                        case '7':
                            res += '\nTankungsabbruch infolge Fehler';
                            break;
                        case '8':
                            res += '\nTankfehler beim Austria - Zapfsäulenrechner';
                            break;
                        default:
                            res += '\nnicht definiert';
                            break;
                    }
                    break;
                case 30:
                    res += 'Zapfpunktadresse: ' + bytes[30];
                    break;
                case 32:
                    res += 'Zapfpunktstatus: ' + ('0000' + parseInt(bytes[32], 16).toString(2)).slice(-4);
                    switch (bytes[32].toString().toLowerCase()) {
                        case '0':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '1':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '2':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '3':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '4':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '5':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '6':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '7':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '8':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case '9':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case 'a':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case 'b':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case 'c':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        case 'd':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        case 'e':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        case 'f':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    zeichentoint += bytes[5] + '' + bytes[4] + '' + bytes[3] + '' + bytes[2] + '' + bytes[1] + '' + bytes[0]
                    res += 'Tankvolumen: ' + parseInt(zeichentoint, 10) / 1000 + ' Liter';
                    break;
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    zeichentoint += bytes[11] + '' + bytes[10] + '' + bytes[9] + '' + bytes[8] + '' + bytes[7] + '' + bytes[6]
                    res += 'Betrag: ' + parseInt(zeichentoint, 10) / 1000 + '€';
                    break;
                case 12:
                    res += 'laufende Tankungsnummer: ' + bytes[12];
                    if (Number('0x' + bytes[12]) > 9) {
                        res += '\nungültige Tankungsnummer';
                    }
                    break;
                case 13:
                    res += 'Sortenkennziffer: ' + bytes[13];
                    if (Number('0x' + bytes[13]) > 8) {
                        res += '\nungültige Sortenkennziffer';
                    }
                    break;
                case 14:
                    res += 'Tankdatenstatus: ' + bytes[14];
                    switch (bytes[14].toString()) {
                        case '0':
                            res += '\nImpulsfehler';
                            break;
                        case '1':
                            res += '\nZählerfehler';
                            break;
                        case '2':
                            res += '\nAnzeigenfehler';
                            break;
                        case '3':
                            res += '\nNetzausfall';
                            break;
                        case '4':
                            res += '\nkein Fehler';
                            break;
                        case '5':
                            res += '\nNulltankung';
                            break;
                        case '7':
                            res += '\nTankungsabbruch infolge Fehler';
                            break;
                        case '8':
                            res += '\nTankfehler beim Austria - Zapfsäulenrechner';
                            break;
                        default:
                            res += '\nnicht definiert';
                            break;
                    }
                    break;
                case 15:
                    res += 'Zapfpunktadresse: ' + bytes[15];
                    break;
                case 16:
                    res += 'Zapfpunktstatus: ' + ('0000' + parseInt(bytes[16], 16).toString(2)).slice(-4);
                    switch (bytes[16].toString().toLowerCase()) {
                        case '0':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '1':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '2':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '3':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil eingehängt';
                            break;
                        case '4':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '5':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '6':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '7':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil eingehängt';
                            break;
                        case '8':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case '9':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case 'a':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case 'b':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist offen \nZapfventil ausgehängt';
                            break;
                        case 'c':
                            res += '\nNetzspannung liegt an \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        case 'd':
                            res += '\nNetzausfall \nZapfpunkt gesperrt \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        case 'e':
                            res += '\nNetzspannung liegt an \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        case 'f':
                            res += '\nNetzausfall \nZapfpunkt frei \nFreigabetaster ist geschlossen \nZapfventil ausgehängt';
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getZapfpunktsummenAnforderungDataTitle(idx: number): string {
        let res = '';
        let bytes = this.dataBytes();
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                    res += 'Zapfpunktadresse: ' + bytes[0];
                    break;
                case 2:
                    res += 'Nummer des Summierspeichers: ' + bytes[2];
                    switch (bytes[2].toString().toLowerCase()) {
                        case '0':
                            res += '\nSorte 1';
                            break;
                        case '1':
                            res += '\nSorte 2';
                            break;
                        case '2':
                            res += '\nSorte 3';
                            break;
                        case '3':
                            res += '\nSorte 4';
                            break;
                        case '4':
                            res += '\nSorte 5';
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                    res += 'Zapfpunktadresse: ' + bytes[0];
                    break;
                case 1:
                    res += 'Nummer des Summierspeichers: ' + bytes[1];
                    switch (bytes[1].toString().toLowerCase()) {
                        case '0':
                            res += '\nSorte 1';
                            break;
                        case '1':
                            res += '\nSorte 2';
                            break;
                        case '2':
                            res += '\nSorte 3';
                            break;
                        case '3':
                            res += '\nSorte 4';
                            break;
                        case '4':
                            res += '\nSorte 5';
                            break;
                        default:
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getZapfpunktsummenDataTitle(idx: number): string {
        let res = '';
        let bytes = this.dataBytes();
        let zeichentoint = '';
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                    res += 'Zapfpunktadresse: ' + bytes[0];
                    break;
                case 2:
                    res += 'Nummer des Summierspeichers: ' + bytes[2];
                    switch (bytes[2].toString().toLowerCase()) {
                        case '0':
                            res += '\nSorte 1';
                            break;
                        case '1':
                            res += '\nSorte 2';
                            break;
                        case '2':
                            res += '\nSorte 3';
                            break;
                        case '3':
                            res += '\nSorte 4';
                            break;
                        case '4':
                            res += '\nSorte 5';
                            break;
                        default:
                            break;
                    }
                    break;
                case 4:
                    res += 'Anzahl Summierspeicher: ' + bytes[4];
                    switch (bytes[4].toString().toLowerCase()) {
                        case '1':
                            res += '\n1-Sorten - Zapfpunkt';
                            break;
                        case '2':
                            res += '\n2-Sorten - Zapfpunkt';
                            break;
                        case '3':
                            res += '\n3-Sorten/Mix - Zapfpunkt';
                            break;
                        case '4':
                            res += '\n4-Sorten - Zapfpunkt';
                            break;
                        case '5':
                            res += '\n5-Sorten - Zapfpunkt';
                            break;
                    }
                    break;
                case 6:
                    res += 'nicht benutzt';
                    break;
                case 8:
                    res += 'Sortenkennzeichen: ' + bytes[8];
                    break;
                case 10:
                    res += 'nicht benutzt';
                    break;
                case 12:
                    res += 'Speicherfehler - Flag: ' + bytes[12];
                    if (Number('0x' + bytes[12]) === 0) {
                        res += '\nSpeicher nicht verfälscht';
                    } else {
                        res += 'Speicher verfälscht';
                    }
                    break;
                case 14:
                case 16:
                case 18:
                case 20:
                case 22:
                case 24:
                case 26:
                case 28:
                case 30:
                case 32:
                case 34:
                case 36:
                    zeichentoint += bytes[36] + '' + bytes[34] + '' + bytes[32] + '' + bytes[30] + '' + bytes[28] + '' + bytes[26] + '' + bytes[24] + '' +
                        bytes[22] + '' + bytes[20] + '' + bytes[18] + '' + bytes[16] + '' + bytes[14]
                    res += 'Volumen: ' + parseInt(zeichentoint, 10) / 1000 + ' Liter';
                    break;
                case 38:
                case 40:
                case 42:
                case 44:
                case 46:
                case 48:
                case 50:
                case 52:
                case 54:
                case 56:
                case 58:
                    zeichentoint += bytes[58] + '' + bytes[56] + '' + bytes[54] + '' + bytes[52] + '' + bytes[50] + '' + bytes[48] + '' + bytes[46] + '' +
                        bytes[44] + '' + bytes[42] + '' + bytes[40] + '' + bytes[38]
                    res += 'Betrag: ' + parseInt(zeichentoint, 10) / 100 + '€';
                    break;
                case 60:
                case 62:
                    res += 'nicht belegt';
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                    res += 'Zapfpunktadresse: ' + bytes[0];
                    break;
                case 1:
                    res += 'Nummer des Summierspeichers: ' + bytes[1];
                    switch (bytes[1].toString().toLowerCase()) {
                        case '0':
                            res += '\nSorte 1';
                            break;
                        case '1':
                            res += '\nSorte 2';
                            break;
                        case '2':
                            res += '\nSorte 3';
                            break;
                        case '3':
                            res += '\nSorte 4';
                            break;
                        case '4':
                            res += '\nSorte 5';
                            break;
                        default:
                            break;
                    }
                    break;
                case 2:
                    res += 'Anzahl Summierspeicher: ' + bytes[2];
                    switch (bytes[2].toString().toLowerCase()) {
                        case '1':
                            res += '\n1-Sorten - Zapfpunkt';
                            break;
                        case '2':
                            res += '\n2-Sorten - Zapfpunkt';
                            break;
                        case '3':
                            res += '\n3-Sorten/Mix - Zapfpunkt';
                            break;
                        case '4':
                            res += '\n4-Sorten - Zapfpunkt';
                            break;
                        case '5':
                            res += '\n5-Sorten - Zapfpunkt';
                            break;
                    }
                    break;
                case 3:
                    res += 'nicht benutzt';
                    break;
                case 4:
                    res += 'Sortenkennzeichen: ' + bytes[4];
                    break;
                case 5:
                    res += 'nicht benutzt';
                    break;
                case 6:
                    res += 'Speicherfehler - Flag: ' + bytes[6];
                    if (Number('0x' + bytes[6]) === 0) {
                        res += '\nSpeicher nicht verfälscht';
                    } else {
                        res += 'Speicher verfälscht';
                    }
                    break;
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                    zeichentoint += bytes[18] + '' + bytes[17] + '' + bytes[16] + '' + bytes[15] + '' + bytes[14] + '' + bytes[13] + '' + bytes[12] + '' +
                        bytes[11] + '' + bytes[10] + '' + bytes[9] + '' + bytes[8] + '' + bytes[7]
                    res += 'Volumen: ' + parseInt(zeichentoint, 10) / 1000 + ' Liter';
                    break;
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                case 28:
                case 29:
                    zeichentoint += bytes[29] + '' + bytes[28] + '' + bytes[27] + '' + bytes[26] + '' + bytes[25] + '' + bytes[24] + '' + bytes[23] + '' +
                        bytes[22] + '' + bytes[21] + '' + bytes[20] + '' + bytes[19]
                    res += 'Betrag: ' + parseInt(zeichentoint, 10) / 100 + '€';
                    break;
                case 30:
                case 31:
                    res += 'nicht belegt';
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getClass(idx: number) {
        switch (this.type) {
            case 'status-telegramm':
                return this.getStatusTelegrammClass(idx);
            case 'status-anfrage':
                return this.getStatusAnfrageClass(idx);
            case 'grundpreis-verstellung':
                return this.getGrundpreisVerstellungClass(idx);
            case 'sperren':
                return this.getSpeerenClass(idx);
            case 'tankdaten':
                return this.getTankdatenClass(idx);
            case 'zapfpunktsummen-anforderung':
                return this.getZapfpunktsummenAnforderungClass(idx);
            case 'zapfpunktsummen':
                return this.getZapfpunktsummenClass(idx);
            default:
                return '';
        }
    }

    getStatusTelegrammClass(idx: number): string {
        let res = '';
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                    res += 'lightsteelblue';
                    break;
                case 2:
                    res += 'lightpink';
                    break;
                case 4:
                    res += 'peachpuff';
                    break;
                case 6:
                    res += 'plum';
                    break;
                case 8:
                case 10:
                    res += 'salmon';
                    break;
                case 12:
                case 14:
                    res += 'sandybrown';
                    break;
                case 16:
                case 18:
                    res += 'bisque';
                    break;
                case 20:
                    res += 'thistle';
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                    res += 'lightsteelblue';
                    break;
                case 1:
                    res += 'lightpink';
                    break;
                case 2:
                    res += 'peachpuff';
                    break;
                case 3:
                    res += 'plum';
                    break;
                case 4:
                case 5:
                    res += 'salmon';
                    break;
                case 6:
                case 7:
                    res += 'sandybrown';
                    break;
                case 8:
                case 9:
                    res += 'bisque';
                    break;
                case 10:
                    res += 'thistle';
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getStatusAnfrageClass(idx: number): string {
        let res = '';
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                    res += 'lightsteelblue';
                    break;
                case 2:
                    res += 'Hex position ist nicht in Benutzung';
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                    res += 'lightsteelblue';
                    break;
                case 1:
                    res += 'Hex position ist nicht in Benutzung';
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getGrundpreisVerstellungClass(idx: number): string {
        let res = '';
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                case 2:
                case 4:
                case 6:
                    res += 'darkkhaki';
                    break;
                case 8:
                case 10:
                case 12:
                case 14:
                case 16:
                case 18:
                    res += 'yellowgreen';
                    break;
                case 20:
                case 22:
                case 24:
                case 26:
                    res += 'yellow';
                    break;
                case 28:
                case 30:
                case 32:
                case 34:
                    res += 'khaki';
                    break;
                case 36:
                case 38:
                case 40:
                case 42:
                    res += 'lemonchiffon';
                    break;
                case 44:
                case 46:
                case 48:
                case 50:
                case 52:
                case 54:
                    res += 'turquoise';
                    break;
                case 56:
                case 58:
                case 60:
                case 62:
                case 64:
                case 66:
                    res += 'aquamarine';
                    break;
                case 68:
                    res += 'osybrown';
                    break;
                case 70:
                    res += 'tan';
                    break;
                case 72:
                    res += 'darkseagreen';
                    break;
                case 74:
                    res += 'lightgreen';
                    break;
                case 76:
                    res += 'silver';
                    break;
                case 78:
                    res += 'maroon';
                    break;
                case 80:
                case 82:
                    res += 'peachpuff';
                    break;
                case 84:
                    res += 'indigo';
                    break;
                case 86:
                case 88:
                case 90:
                case 92:
                    res += 'bisque';
                    break;
                case 94:
                    res += 'sandybrown';
                    break;
                case 96:
                    res += 'lightsteelblue';
                    break;
                case 98:
                case 100:
                case 102:
                case 104:
                    res += 'plum';
                    break;
                case 106:
                    res += 'salmon';
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                case 1:
                case 2:
                case 3:
                    res += 'darkkhaki';
                    break;
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                    res += 'yellowgreen';
                    break;
                case 10:
                case 11:
                case 12:
                case 13:
                    res += 'yellow';
                    break;
                case 14:
                case 15:
                case 16:
                case 17:
                    res += 'khaki';
                    break;
                case 18:
                case 19:
                case 20:
                case 21:
                    res += 'lemmonchiffon';
                    break;
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                    res += 'turquoise';
                    break;
                case 28:
                case 29:
                case 30:
                case 31:
                case 32:
                case 33:
                    res += 'aquamarine';
                    break;
                case 34:
                    res += 'osybrown';
                    break;
                case 35:
                    res += 'tan';
                    break;
                case 36:
                    res += 'darkseagreen';
                    break;
                case 37:
                    res += 'lightgreen';
                    break;
                case 38:
                    res += 'silver';
                    break;
                case 39:
                    res += 'maroon';
                    break;
                case 40:
                case 41:
                    res += 'peachpuff';
                    break;
                case 42:
                    res += 'indigo';
                    break;
                case 43:
                case 44:
                case 45:
                case 46:
                    res += 'bisque';
                    break;
                case 47:
                    res += 'sandybrown';
                    break;
                case 48:
                    res += 'lightsteelblue';
                    break;
                case 49:
                case 50:
                case 51:
                case 52:
                    res += 'plum';
                    break;
                case 53:
                    res += 'salmon';
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getSpeerenClass(idx: number): string {
        let res = '';
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                    res += 'lightsteelblue';
                    break;
                case 2:
                    res += 'Hex position ist nicht in Benutzung';
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                    res += 'lightsteelblue';
                    break;
                case 1:
                    res += 'Hex position ist nicht in Benutzung';
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getTankdatenClass(idx: number): string {
        let res = '';
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                case 2:
                case 4:
                case 6:
                case 8:
                case 10:
                    res += 'turquoise';
                    break;
                case 12:
                case 14:
                case 16:
                case 18:
                case 20:
                case 22:
                    res += 'aquamarine';
                    break;
                case 24:
                    res += 'silver';
                    break;
                case 26:
                    res += 'osybrown';
                    break;
                case 28:
                    res += 'salmon';
                    break;
                case 30:
                    res += 'lightsteelblue';
                    break;
                case 32:
                    res += 'lightpink';
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                    res += 'turquoise';
                    break;
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                    res += 'aquamarine';
                    break;
                case 12:
                    res += 'silver';
                    break;
                case 13:
                    res += 'osybrown';
                    break;
                case 14:
                    res += 'salmon';
                    break;
                case 15:
                    res += 'lightsteelblue';
                    break;
                case 16:
                    res += 'lightpink';
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getZapfpunktsummenAnforderungClass(idx: number): string {
        let res = '';
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                    res += 'lightsteelblue';
                    break;
                case 2:
                    res += 'lightsteelblue';
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                    res += 'lightsteelblue';
                    break;
                case 1:
                    res += 'lightsteelblue';
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getZapfpunktsummenClass(idx: number): string {
        let res = '';
        if (this.hasFillbytes()) {
            switch (idx) {
                case 0:
                    res += 'lightsteelblue';
                    break;
                case 2:
                    res += 'yellow';
                    break;
                case 4:
                    res += 'khaki';
                    break;
                case 8:
                    res += 'osybrown';
                    break;
                case 12:
                    res += 'sandybrown';
                    break;
                case 14:
                case 16:
                case 18:
                case 20:
                case 22:
                case 24:
                case 26:
                case 28:
                case 30:
                case 32:
                case 34:
                case 36:
                    res += 'turquoise';
                    break;
                case 38:
                case 40:
                case 42:
                case 44:
                case 46:
                case 48:
                case 50:
                case 52:
                case 54:
                case 56:
                case 58:
                    res += 'aquamarine';
                    break;
                default:
                    break;
            }
        } else {
            switch (idx) {
                case 0:
                    res += 'lightsteelblue';
                    break;
                case 1:
                    res += 'yellow';
                    break;
                case 2:
                    res += 'khaki';
                    break;
                case 4:
                    res += 'osybrown';
                    break;
                case 6:
                    res += 'sandybrown';
                    break;
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 12:
                case 13:
                case 14:
                case 15:
                case 16:
                case 17:
                case 18:
                    res += 'turquoise';
                    break;
                case 19:
                case 20:
                case 21:
                case 22:
                case 23:
                case 24:
                case 25:
                case 26:
                case 27:
                case 28:
                case 29:
                    res += 'aquamarine';
                    break;
                default:
                    break;
            }
        }
        return res
    }

    getInfo() {
        switch (this.type) {
            case 'status-telegramm':
                return this.getStatusTelegrammInfo();
            case 'status-anfrage':
                return this.getStatusAnfrageInfo();
            case 'grundpreis-verstellung':
                return this.getGrundpreisVerstellungInfo();
            case 'sperren':
                return this.getSpeerenInfo();
            case 'tankdaten':
                return this.getTankdatenInfo();
            case 'zapfpunktsummen-anforderung':
                return this.getZapfpunktsummenAnforderungInfo();
            case 'zapfpunktsummen':
                return this.getZapfpunktsummenInfo();
            default:
                return '';
        }
    }

    private getStatusTelegrammInfo() {
        let res = '';
        let bytes = this.dataBytes()
        if (this.hasFillbytes()) {
            res += 'Zapfpunktstatus: ' + (bytes[2].toString() === 'e' ? 'Tankungsbeginn\n' : 'Idle oder Fehler siehe pinkes Feld\n') +
                'Zapfpunkt: ' + (bytes[4].toString() === '0' ? 'keiner entnommen\n' : bytes[4] + ' entnommen\n') +
                'Belegung Autarksummen (LSD): siehe lila Feld\n' +
                'Fehlercode Master: siehe rotes Feld\n' +
                'Fehlercode Slave: siehe orangenens Feld\n' +
                'Belegung Autarksummer (MSD): siehe gräuliches Feld';
        } else {
            res += 'Zapfpunktstatus: ' + (bytes[1].toString() === 'e' ? 'Tankungsbeginn\n' : 'Idle oder Fehler siehe pinkes Feld\n') +
                'Zapfpunkt: ' + (bytes[2].toString() === '0' ? 'keiner entnommen\n' : bytes[2] + ' entnommen\n') +
                'Belegung Autarksummen (LSD): siehe lila Feld\n' +
                'Fehlercode Master: siehe rotes Feld\n' +
                'Fehlercode Slave: siehe orangenens Feld\n' +
                'Belegung Autarksummer (MSD): siehe gräuliches Feld';
        }
        return res;
    }

    private getStatusAnfrageInfo() {
        let bytes = this.dataBytes();
        return 'Zapfpunktadresse: ' + bytes[0];
    }

    private getGrundpreisVerstellungInfo() {
        let res = '';
        let bytes = this.dataBytes();
        if (this.hasFillbytes()) {
            res += 'MwSt.: ' + bytes[6] + '' + bytes[4] + ',' + bytes[2] + '' + bytes[0] + '%\n' +
                'Abgabelimit Volumen: ' + bytes[54] + '' + bytes[52] + '' + bytes[50] + ',' + bytes[48] + '' + bytes[46] + '' + bytes[44] + ' Liter\n' +
                'Abgabelimit Betrag: ' + bytes[66] + '' + bytes[64] + '' + bytes[62] + ',' + bytes[60] + '' + bytes[58] + '' + bytes[56] + '€\n' +
                'GP Sorte 1: ' + bytes[26] + ',' + bytes[24] + '' + bytes[22] + '' + bytes[20] + '€\n' +
                'Sortenkennziffer Sorte 1: ' + bytes[68] + '\n' +
                'GP Sorte 2: ' + bytes[34] + ',' + bytes[32] + '' + bytes[30] + '' + bytes[28] + '€\n' +
                'Sortenkennziffer Sorte 2: ' + bytes[70] + '\n' +
                'GP Sorte 3: ' + bytes[42] + ',' + bytes[40] + '' + bytes[38] + '' + bytes[36] + '€\n' +
                'Sortenkennziffer Sorte 3: ' + bytes[72] + '\n' +
                'GP Sorte 4: ' + bytes[92] + ',' + bytes[90] + '' + bytes[88] + '' + bytes[86] + '€\n' +
                'Sortenkennziffer Sorte 4: ' + bytes[94] + '\n' +
                'GP Sorte 5: ' + bytes[104] + ',' + bytes[102] + '' + bytes[100] + '' + bytes[98] + '€\n' +
                'Sortenkennziffer Sorte 5: ' + bytes[106] + '';
        } else {
            res += 'MwSt.: ' + bytes[3] + '' + bytes[2] + ',' + bytes[1] + '' + bytes[0] + '%\n' +
                'Abgabelimit Volumen: ' + bytes[27] + '' + bytes[26] + '' + bytes[25] + ',' + bytes[24] + '' + bytes[23] + '' + bytes[22] + ' Liter\n' +
                'Abgabelimit Betrag: ' + bytes[33] + '' + bytes[32] + '' + bytes[31] + ',' + bytes[30] + '' + bytes[29] + '' + bytes[28] + '€\n' +
                'GP Sorte 1: ' + bytes[26] + ',' + bytes[24] + '' + bytes[22] + '' + bytes[20] + '€\n' +
                'Sortenkennziffer Sorte 1: ' + bytes[34] + '\n' +
                'GP Sorte 2: ' + bytes[17] + ',' + bytes[16] + '' + bytes[15] + '' + bytes[14] + '€\n' +
                'Sortenkennziffer Sorte 2: ' + bytes[35] + '\n' +
                'GP Sorte 3: ' + bytes[21] + ',' + bytes[20] + '' + bytes[19] + '' + bytes[18] + '€\n' +
                'Sortenkennziffer Sorte 3: ' + bytes[36] + '\n' +
                'GP Sorte 4: ' + bytes[46] + ',' + bytes[45] + '' + bytes[44] + '' + bytes[43] + '€\n' +
                'Sortenkennziffer Sorte 4: ' + bytes[47] + '\n' +
                'GP Sorte 5: ' + bytes[52] + ',' + bytes[51] + '' + bytes[50] + '' + bytes[49] + '€\n' +
                'Sortenkennziffer Sorte 5: ' + bytes[53] + '';
        }
        return res;
    }

    private getSpeerenInfo() {
        let bytes = this.dataBytes();
        return 'Zapfpunktadresse: ' + bytes[0];
    }

    private getTankdatenInfo() {
        let res = '';
        let bytes = this.dataBytes();
        if (this.hasFillbytes()) {
            res += 'Tankdatenstatus: siehe rotes Feld\n' +
                'Volumen: ' + bytes[10] + '' + bytes [8] + '' + bytes[6] + ',' + bytes[4] + '' + bytes[2] + '' + bytes[0] + ' Liter\n' +
                'Betrag: ' + bytes[22] + '' + bytes[20] + '' + bytes [18] + ',' + bytes[16] + '' + bytes[14] + '' + bytes[12] + '€\n' +
                'Tankungsnummer: ' + bytes[24] + '\n' +
                'Sortenkennziffer: ' + bytes[26];
        } else {
            res += 'Tankdatenstatus: siehe rotes Feld\n' +
                'Volumen: ' + bytes[5] + '' + bytes [4] + '' + bytes[3] + ',' + bytes[2] + '' + bytes[1] + '' + bytes[0] + ' Liter\n' +
                'Betrag: ' + bytes[11] + '' + bytes[10] + '' + bytes [9] + ',' + bytes[8] + '' + bytes[7] + '' + bytes[6] + '€\n' +
                'Tankungsnummer: ' + bytes[12] + '\n' +
                'Sortenkennziffer: ' + bytes[13];
        }
        return res;
    }

    private getZapfpunktsummenAnforderungInfo() {
        let bytes = this.dataBytes();
        return 'Zapfpunktadresse: ' + bytes[0];
    }

    private getZapfpunktsummenInfo() {
        let res = '';
        let bytes = this.dataBytes();
        if (this.hasFillbytes()) {
            res += 'Summierspeichernummer: Sorte ' + (Number('0x' + bytes[2]) + 1) + '\n' +
                'Anzahl Summierspeicher: siehe dunkleres hellblau Feld\n' +
                'Sortenkennzeichen: ' + bytes[8] + '\n' +
                'Flag Speicherfehler: ' + (bytes[12].toString()==='0' ? 'nein' : 'ja') + '\n' +
                'Volumen: ' + parseInt(bytes[36] + '' + bytes[34] + '' + bytes[32] + '' + bytes[30] + '' + bytes[28] + '' + bytes[26] + '' + bytes[24] + '' +
                    bytes[22] + '' + bytes[20] + '' + bytes[18] + '' + bytes[16] + '' + bytes[14],10)/1000+' Liter\n' +
                'Betrag: ' + parseInt(bytes[58] + '' + bytes[56] + '' + bytes[54] + '' + bytes[52] + '' + bytes[50] + '' + bytes[48] + '' + bytes[46] + '' +
                    bytes[44] + '' + bytes[42] + '' + bytes[40] + '' + bytes[38] + '' + bytes[36],10)/100+'€';
        } else {
            res += 'Summierspeichernummer: Sorte ' + (Number('0x' + bytes[1]) + 1) + '\n' +
                'Anzahl Summierspeicher: siehe dunkleres hellblau Feld\n' +
                'Sortenkennzeichen: ' + bytes[4] + '\n' +
                'Flag Speicherfehler: ' + (bytes[6].toString()==='0' ? 'nein' : 'ja') + '\n' +
                'Volumen: ' + parseInt(bytes[18] + '' + bytes[17] + '' + bytes[16] + '' + bytes[15] + '' + bytes[14] + '' + bytes[13] + '' + bytes[12] + '' +
                    bytes[11] + '' + bytes[10] + '' + bytes[9] + '' + bytes[8] + '' + bytes[7],10)/1000+' Liter\n' +
                'Betrag: ' + parseInt(bytes[29] + '' + bytes[28] + '' + bytes[27] + '' + bytes[26] + '' + bytes[25] + '' + bytes[24] + '' + bytes[23] + '' +
                    bytes[22] + '' + bytes[21] + '' + bytes[20] + '' + bytes[19],10)/100+'€';
        }
        return res;
    }
}
