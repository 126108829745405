import { MEGenericFilter } from '../../../maennl-commons/filters';
import { CSDServerBridge } from './csdserver-bridge';
import {MEApiTool, METool} from '../../../maennl-commons';

export class SimpleCSDServerBridgeFilter extends MEGenericFilter<CSDServerBridge> {

    is_quickconnect_bridge: boolean|null = null;
    public encodeField(fieldname: string): string {
    if (fieldname === 'is_quickconnect_bridge') {
      if (!METool.isEmpty(this.is_quickconnect_bridge)) {
        return (
          'filter.' +
          encodeURIComponent(fieldname) +
          '=' +
          encodeURIComponent(this.is_quickconnect_bridge?'true':'false')
        );
      }
    }
    return super.encodeField(fieldname);
  }
}
