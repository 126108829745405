import { MEGenericFilter } from '../../maennl-commons/filters';
import { MitschnittstreamPaket } from './mitschnittstream-paket';

export class SimpleMitschnittstreamPaketFilter extends MEGenericFilter<MitschnittstreamPaket> {
  connected: Date[] = null;
  connection_id: number = null;
  hexdata: string = null;
  id: string = null;
  length: number = null;
  machine: string = null;
  machine_type: string = null;
  mitschnitt_id: number = null;
  packet_id: number = null;
  received: Date[] = null;
  protocol: string = null;
}
