import {
  L10nDatePipe,
  L10nDateTimeFormatOptions,
  L10nIntlService,
  L10nLocale,
  L10nNumberFormatOptions,
  L10nNumberPipe,
  L10nTranslationService
} from 'angular-l10n';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Md5 } from 'ts-md5';
import { MEAppInjector } from '../tools/meapp-injector';
import { MEShortenPipe } from '../pipes';
import * as moment from 'moment';
import { MEConverterTool, METool } from '../tools';

@Injectable()
export class MELocalizedComponent implements OnInit, OnDestroy {
  public static MISSING_VALUE = 'KEY.MISSING';

  public get translation(): L10nTranslationService {
    return <L10nTranslationService>MEAppInjector.get(L10nTranslationService);
  }

  public get intl(): L10nIntlService {
    return <L10nIntlService>MEAppInjector.get(L10nIntlService);
  }

  constructor() {}

  public t(okey: string, args?: any): string {
    let key = okey.replace('\n', '###BR###');
    key = key.replace(/\s+/gi, ' ');
    key = key.trim();

    let t = this.translation.translate(key, args);

    if (
      t === '' ||
      t === null ||
      (t === key && key.length > 25 && this._language !== 'de')
    ) {
      t = MELocalizedComponent.MISSING_VALUE;
    }
    if (t === MELocalizedComponent.MISSING_VALUE) {
      this.inform(key, key);
      const skey = '' + Md5.hashStr(key, false);
      t = this.translation.translate(skey, args);
      if (t === MELocalizedComponent.MISSING_VALUE) {
        this.inform(key, skey);
        t = okey;
      }
    }

    t = t.replace('###BR###', '\n');

    if (args !== undefined && args !== null) {
      t = this.handleArgs(t, args);
    }

    return t;
  }

  public _(key: string, args?: any): string {
    return this.t(key, args);
  }

  public inform(key: string, skey: string) {
    /*
        const sprachenService: SprachenService = <SprachenService>MEAppInjector.injector().get(SprachenService);
        if (METool.isDefined(sprachenService)) {
            if (METool.isDefined(this._language)) {
                sprachenService.reportMissingPhrase(
                    this.translation.getLocale().language,
                    skey,
                    window.location.href,
                    key
                );
            }
        }

         */
  }

  get _locale(): L10nLocale {
    if (METool.isDefined(this.translation)) {
      return this.translation.getLocale();
    }
    return { language: 'de' };
  }

  get _language(): string {
    if (METool.isDefined(this._locale)) {
      return this._locale.language;
    }
    return 'de';
  }

  protected handleArgs(value: string, args: any) {
    const TEMPLATE_REGEXP: RegExp = /{{\s?([^{}\s]*)\s?}}/g;
    return value.replace(
      TEMPLATE_REGEXP,
      (substring: string, parsedKey: string) => {
        const replacer = args[parsedKey];
        return typeof replacer !== 'undefined' ? replacer : substring;
      }
    );
  }

  public fDate(val: Date, fmt: string, useMoment = false) {
    if (val == null) {
      return '';
    }
    if (!moment(val).isValid()) {
      return '';
    }
    if (useMoment) {
      return moment(val).format(fmt);
    }

    const p = new L10nDatePipe(this.intl);
    const o: L10nDateTimeFormatOptions = {};
    if (fmt === 'full') {
      o.dateStyle = 'full';
      o.timeStyle = 'full';
    }
    if (fmt === 'long') {
      o.dateStyle = 'long';
      o.timeStyle = 'long';
    }
    if (fmt === 'medium') {
      o.dateStyle = 'medium';
      o.timeStyle = 'medium';
    }
    if (fmt === 'short') {
      o.dateStyle = 'short';
      o.timeStyle = 'short';
    }
    return p.transform(val, this._language, o);
  }

  public fDuration(
    val: any,
    withSuffix = false,
    humanize = false,
    short = false
  ) {
    if (val === null || val === undefined) {
      return 'null';
    }
    const d = moment.duration(val);
    if (!d.isValid()) {
      return 'iv';
    }

    if (humanize) {
      return d.locale(this._language).humanize(withSuffix);
    }
    const h = [];
    if (d.years() > 1) {
      if (short) {
        h.push(this._('{{n}} a', { n: d.years() }));
      } else {
        h.push(this._('{{n}} Jahre', { n: d.years() }));
      }
    } else if (d.years() === 1) {
      if (short) {
        h.push(this._('1 a'));
      } else {
        h.push(this._('1 Jahr'));
      }
    }
    if (d.months() > 1) {
      if (short) {
        h.push(this._('{{n}} m', { n: d.months() }));
      } else {
        h.push(this._('{{n}} Monate', { n: d.months() }));
      }
    } else if (d.months() === 1) {
      if (short) {
        h.push(this._('1 m'));
      } else {
        h.push(this._('1 Monat'));
      }
    }
    if (d.days() > 1) {
      if (short) {
        h.push(this._('{{n}} d', { n: d.days() }));
      } else {
        h.push(this._('{{n}} Tage', { n: d.days() }));
      }
    } else if (d.days() === 1) {
      if (short) {
        h.push(this._('1 d'));
      } else {
        h.push(this._('1 Tag'));
      }
    }
    if (!short || withSuffix) {
      if (d.hours() > 1) {
        if (short) {
          h.push(this._('{{n}} h', { n: d.hours() }));
        } else {
          h.push(this._('{{n}} Stunden', { n: d.hours() }));
        }
      } else if (d.hours() === 1) {
        if (short) {
          h.push(this._('1 h'));
        } else {
          h.push(this._('1 Stunde'));
        }
      }

      if (d.minutes() > 1) {
        if (short) {
          h.push(this._('{{n}} min', { n: d.minutes() }));
        } else {
          h.push(this._('{{n}} Minuten', { n: d.minutes() }));
        }
      } else if (d.minutes() === 1) {
        if (short) {
          h.push(this._('1 min'));
        } else {
          h.push(this._('1 Minute'));
        }
      }

      if (d.seconds() > 1) {
        if (short) {
          h.push(this._('{{n}} s', { n: d.seconds() }));
        } else {
          h.push(this._('{{n}} Sekunden', { n: d.seconds() }));
        }
      } else if (d.seconds() === 1) {
        if (short) {
          h.push(this._('1 s'));
        } else {
          h.push(this._('1 Sekunde'));
        }
      } else if (h.length === 0) {
        if (short) {
          h.push(this._('0 s'));
        } else {
          h.push(this._('{{n}} Sekunden', { n: 0 }));
        }
      }
    } else {
      const x = [];
      x.push(MEConverterTool.right_string('00' + d.hours(), 2));
      x.push(MEConverterTool.right_string('00' + d.minutes(), 2));
      x.push(MEConverterTool.right_string('00' + d.seconds(), 2));
      h.push(x.join(':'));
    }

    return h.join(' ').trim();
  }

  public fNum(
    val: any,
    digitfmt: string | number,
    isCurrency = false,
    currency: string = null
  ) {
    if (('' + digitfmt).match('^[0-9]+$')) {
      digitfmt = '1.' + digitfmt + '-' + digitfmt;
    }
    if (('' + digitfmt).match('^[0-9]+-[0-9]+.[0-9]+$')) {
      const r = /^([0-9]+)-([0-9]+)\.([0-9]+)$/;
      const res = r.exec('' + digitfmt);
      if (res && res.length > 0) {
        digitfmt = res[1] + '.' + res[2] + '-' + res[3];
      }
    }

    if (METool.isNullOrUndefined(val)) {
      return '';
    }
    if (METool.isNaN(val)) {
      return val;
    }

    const p = new L10nNumberPipe(this.intl);
    const o: L10nNumberFormatOptions = { digits: '' + digitfmt };
    if (isCurrency) {
      o.style = 'currency';
      if (!METool.isNullOrUndefined(currency)) {
        o.currency = currency;
      }
    }
    return p.transform(val, this._language, o);
  }

  public fCurrency(
    val: any,
    currency: any,
    symbol: any = 'symbol',
    digitfmt: string | number = '1.2-2'
  ) {
    return this.fNum(val, digitfmt, true, currency);
  }

  public shorten(val: string, length: number) {
    const p = new MEShortenPipe();
    return p.transform(val, length);
  }

  public bytes(val: number, decimals?: number, removeZeroDecimals = false) {
    return MEConverterTool.readableBytes(val, decimals, removeZeroDecimals);
  }

  public ngOnDestroy(): void {}

  public ngOnInit(): void {}
}
