import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { TMERowActionAvailableCheckCallback } from '../../maennl-commons/data-table/actions/types';
import { AbrechnungGlukoseportalList } from '../common/abrechnung-glukoseportal-list';
import {
  MEDataTableComponent,
  MEDataTableRow,
  MERowActionParams,
  TMEDTGetExtraRowClassesCallback
} from '../../maennl-commons/data-table';
import { ToastrService } from 'ngx-toastr';
import { AbrechnungVaroRechnungsserver } from '../common/abrechnung-varo-rechnungsserver';
import { AbrechnungGlukoseportalService } from '../services/abrechnung-glukoseportal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MESubscriptionHelper } from '../../maennl-commons/services';
import { MERichError, noop } from '../../maennl-commons/tools';
import { AbrechnungGlukoseportal } from '../common/abrechnung-glukoseportal';
import { IAbrechnungGlukoseportal } from '../common/iabrechnung-glukoseportal';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { AbrechnungGlukoseportalDetailsComponent } from '../abrechnung-glukoseportal-details/abrechnung-glukoseportal-details.component';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-tab-abrechnung-glukoseportal',
  templateUrl: './tab-abrechnung-glukoseportal.component.html',
  styleUrls: ['./tab-abrechnung-glukoseportal.component.scss']
})
export class TabAbrechnungGlukoseportalComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'TabAbrechnungGlukoseportalComponent';
  __instance = '';
  tabActive = false;

  public abrechnungen: AbrechnungGlukoseportalList =
    new AbrechnungGlukoseportalList();
  @ViewChild(MEDataTableComponent, { static: false }) dt = null;

  constructor(
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    public abrechnungService: AbrechnungGlukoseportalService,
    public modalService: NgbModal,
    public benutzerService: BenutzerService
  ) {}

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.abrechnungen.onUpdateRequired.subscribe(
        (list: AbrechnungGlukoseportalList) => {
          this.abrechnungService
            .listAbrechnungen(
              list.size,
              list.calcOffset(),
              list.order,
              list.simpleFilter
            )
            .subscribe(
              (l) => {
                list.populateFromListResult(l);
                this.cd.markForCheck();
              },
              (e) => {
                console.log(e);
                MERichError.throw(
                  'Fehler beim Datenabruf',
                  'Die Liste der verfügbaren Glukoseportal-Abrechnungen konnte nicht abgerufen werden.'
                );
              }
            );
        },
        undefined
      )
    );
  }

  ngAfterViewInit(): void {
    if (this.dt !== null) {
      this.dt.extraRowClasses = this.diensteRowClassGetter;
    }
  }

  diensteRowClassGetter: TMEDTGetExtraRowClassesCallback<AbrechnungGlukoseportal> =
    (row: MEDataTableRow<AbrechnungGlukoseportal>, idx) => {
      return '';
    };

  onActivate() {
    console.log('Glukoseportal-Abrechnungen aktiviert!');
    this.tabActive = true;
    this.abrechnungen.start();
  }

  onDeactivate() {
    console.log('Glukoseportal-Abrechnungen deaktiviert!');
    this.tabActive = false;
  }

  recalcAbrechnungGlukoseportal(
    $event: MERowActionParams<AbrechnungGlukoseportal>
  ) {
    this.abrechnungService.recalcAbrechnung($event.row.data).subscribe(
      () => {
        this.toastr.success(
          'Die Abrechnung für die Periode ' +
            $event.row.data.periode +
            ' wird nun neu durchgerechnet.'
        );
        setTimeout(() => {
          this.abrechnungen.doListUpdate();
        }, 1000);
        setTimeout(() => {
          this.abrechnungen.doListUpdate();
        }, 5000);
        this.abrechnungen.doListUpdate();
      },
      () => {
        MERichError.throw(
          'Fehler beim Datenabruf',
          'Die Neuberechnung der Abrechnung konnte nicht gestartet werden.'
        );
      }
    );
  }

  displayAbrechnungGlukoseportal(
    $event: MERowActionParams<AbrechnungGlukoseportal>
  ) {
    this.abrechnungService
      .getAbrechnung($event.row.data.id)
      .subscribe((a: IAbrechnungGlukoseportal) => {
        const abrechnung = AbrechnungGlukoseportal.fromResult(a);
        AbrechnungGlukoseportalDetailsComponent.open(
          this.modalService,
          AbrechnungGlukoseportalDetailsComponent,
          abrechnung,
          MEGenericModalComponent.SIZE_MEDIUM
        ).then(noop, noop);
      });
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.abrechnungen.release();
  }

  recalcActionAvailable: TMERowActionAvailableCheckCallback<AbrechnungGlukoseportal> =
    (row: MEDataTableRow<AbrechnungGlukoseportal>) => {
      if (row === null || row === undefined) {
        return false;
      }
      if (row.data === null || row.data === undefined) {
        return false;
      }

      return (
        row.data.abgerechnet === null || row.data.abgerechnet === undefined
      );
    };

  public markAbrechnungCleared(
    $event: MERowActionParams<AbrechnungGlukoseportal>
  ) {
    this.abrechnungService.markAbrechnungCleared($event.row.data).subscribe(
      () => {
        this.toastr.success(
          'Die Abrechnung für die Periode ' +
            $event.row.data.periode +
            ' wurde als abrechnet gekennzeichnet.'
        );
        setTimeout(() => {
          this.abrechnungen.doListUpdate();
        }, 1000);
        this.abrechnungen.doListUpdate();
      },
      () => {
        MERichError.throw(
          'Fehler beim Datenabruf',
          'Die Abrechnung konnte nicht gekennzeichnet werden.'
        );
      }
    );
  }
}
