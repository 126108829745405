export class MEDTExtraColumn {
  public renderer: any = null;
  public label = 'Spalte';
  public field = 'feld';
  public cfg: any = null;
  public editable = false;
  public filterable = false;
  public editor: any = null;
  public sortable = false;
  public title = '';
  public titleField = '';
  public type = 'generic';
  public xtraClasses: string = null;
  public visible = true;

  constructor(
    label: string = '',
    field: string = '',
    cfg: any = null,
    renderer: any = null,
    type: string = 'generic',
    editable: boolean = false,
    filterable: boolean = false,
    editor: any = null,
    sortable: boolean = false,
    title: string = '',
    titleField: string = '',
    xtraClasses: string = '',
    visible: boolean = false
  ) {
    this.renderer = renderer;
    this.label = label;
    this.field = field;
    this.cfg = cfg;
    this.editable = editable;
    this.filterable = filterable;
    this.editor = editor;
    this.sortable = sortable;
    this.title = title;
    this.titleField = titleField;
    this.type = type;
    this.xtraClasses = xtraClasses;
    this.visible = visible;
  }

  public setRenderer(renderer: any): MEDTExtraColumn {
    this.renderer = renderer;
    return this;
  }

  public setLabel(label: string): MEDTExtraColumn {
    this.label = label;
    return this;
  }

  public setField(field: string): MEDTExtraColumn {
    this.field = field;
    return this;
  }

  public setCfg(cfg: any): MEDTExtraColumn {
    this.cfg = cfg;
    return this;
  }

  public setEditable(editable: boolean): MEDTExtraColumn {
    this.editable = editable;
    return this;
  }

  public setFilterable(filterable: boolean): MEDTExtraColumn {
    this.filterable = filterable;
    return this;
  }

  public setEditor(editor: any): MEDTExtraColumn {
    this.editor = editor;
    return this;
  }

  public setSortable(sortable: boolean): MEDTExtraColumn {
    this.sortable = sortable;
    return this;
  }

  public setTitle(title: string): MEDTExtraColumn {
    this.title = title;
    return this;
  }

  public setTitleField(titleField: string): MEDTExtraColumn {
    this.titleField = titleField;
    return this;
  }

  public setType(type: string): MEDTExtraColumn {
    this.type = type;
    return this;
  }

  public setXtraClasses(xtraClasses: string): MEDTExtraColumn {
    this.xtraClasses = xtraClasses;
    return this;
  }

  public setVisible(visible: boolean): MEDTExtraColumn {
    this.visible = visible;
    return this;
  }
}
