import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMEActionResponse } from '../../../maennl-commons/network';
import { TestPing } from '../../common/test-ping';
import { MEGenericModalComponent } from '../../../maennl-commons/megeneric-modal';
import { ITestPing } from '../../common/itest-ping';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MEPubSubService } from '../../../maennl-commons/services';
import { SysHealthService } from '../../services/sys-health.service';
import { SysHealthTestPingsDisplayComponent } from '../sys-health-test-pings-display/sys-health-test-pings-display.component';

@Component({
  selector: 'app-sys-health-test-ping-device-details-popup',
  templateUrl: './sys-health-test-ping-device-details-popup.component.html',
  styleUrls: ['./sys-health-test-ping-device-details-popup.component.scss']
})
export class SysHealthTestPingDeviceDetailsPopupComponent
  extends MEGenericModalComponent<TestPing>
  implements OnInit, AfterViewInit
{
  entity: TestPing = null;

  public initEntity(src: ITestPing): TestPing {
    return TestPing.fromResult(src);
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public cd: ChangeDetectorRef,
    public sysHealthService: SysHealthService,
    public toastr: ToastrService
  ) {
    super(activeModal, pubsub);
  }

  ngOnInit() {}

  ngAfterViewInit(): void {}

  public remove() {
    this.sysHealthService.removeTestpings(this.entity.id).subscribe(
      (a: IMEActionResponse) => {
        if (a.success) {
          this.toastr.success('Alle Einträge wurden gelöscht');
          this.ok();
        } else {
          this.toastr.error('Fehler beim Löschen der Einträge');
        }
      },
      () => {
        this.toastr.error('Fehler beim Löschen der Einträge');
      }
    );
  }
}
