import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import {
  MEGenericModalComponent,
  MELocalizedComponent,
  MERowActionParams,
  MESubscriptionHelper,
  noop,
} from '../../../maennl-commons';
import { Firewall } from '../commons/firewall.class';
import { FirewallList } from '../commons/firewall.list';
import { FirewallService } from '../commons/firewall.service';
import { FirewallEditorComponent } from '../firewall-editor/firewall-editor.component';

@Component({
  selector: 'app-tna-firewalls',
  templateUrl: './tnafirewalls.component.html',
  styleUrls: ['./tnafirewalls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TNAFirewallsComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy
{
  @Input() id = '';

  liste = new FirewallList();

  constructor(
    public cd: ChangeDetectorRef,
    public service: FirewallService,
    public benutzerService: BenutzerService,
    public modalService: NgbModal
  ) {
    super();
  }

  ngOnInit(): void {
    this.cd.markForCheck();
    this.onActivate();
  }

  onActivate() {
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe(() => {
        if (this.liste.loading) {
          return;
        }
        this.liste.loading = true;
        this.service
          .list(
            this.liste.size,
            this.liste.calcOffset(),
            this.liste.order,
            this.liste.simpleFilter,
            this.liste.getQuery()
          )
          .subscribe({
            next: l => {
              this.liste.doPopulateFromListResult(l);
              this.cd.markForCheck();
            },
            error: () => {
              this.liste.clear();
              this.cd.markForCheck();
            },
          });
      })
    );
    this.liste.start();
  }

  onDeactivate() {
    this.liste.disableAutoReload();
  }

  ngOnDestroy() {
    this.liste.release();
    MESubscriptionHelper.release(this);
    super.ngOnDestroy();
  }

  addEntry() {
    FirewallEditorComponent.open(
      this.modalService,
      FirewallEditorComponent,
      null,
      MEGenericModalComponent.SIZE_MEDIUM,
      null
    ).then(() => {
      this.liste.start();
    }, noop);
  }

  editEntry(ev: MERowActionParams<Firewall>) {
    this.service.get(ev.row.data.id).subscribe((e: Firewall) => {
      if (e !== null && e !== undefined && e.id > 0) {
        FirewallEditorComponent.open(
          this.modalService,
          FirewallEditorComponent,
          e,
          MEGenericModalComponent.SIZE_MEDIUM,
          null
        ).then(() => {
          this.liste.start();
        }, noop);
      }
    });
  }

  removeEntry(ev: MERowActionParams<Firewall>) {
    this.service.get(ev.row.data.id).subscribe((e: Firewall) => {
      if (e.id !== null && e.id !== undefined && e.id > 0) {
        this.service.delete(e.id).subscribe(() => {
          this.liste.start();
        });
      }
    });
  }
}
