import {Injectable} from "@angular/core";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {HttpClient} from "@angular/common/http";
import {IMEListResult, MEApiTool, MEResultMetaSort} from "../../../maennl-commons";
import {IMESimpleFilter} from "../../../maennl-commons/filters";
import {TransaktionNeedRecalcRNFilter} from "./transaktion_need_recalc_rn.filter";
import {map, Observable, of, take} from "rxjs";
import {ITransaktionNeedRecalcRN} from "./transaktion_need_recalc_rn.interface";
import {TNullableNumber} from "../../../maennl-commons/tools/types";
import {TransaktionNeedRecalcRN} from "./transaktion_need_recalc_rn.class";
import {TransaktionNeedRecalcRNImpl} from "./transaktion_need_recalc_rn.impl";

@Injectable({
    providedIn: 'root'
})
export class TransaktionNeedRecalcRNService {

    constructor(
        public http:HttpClient,
        public loginService:BenutzerService
    ) {
    }

    public list(
        max = 50,
        offset = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<TransaktionNeedRecalcRNFilter>=null,
        queryString=''
    ): Observable<IMEListResult<ITransaktionNeedRecalcRN>> {
        const uri = MEApiTool.buildListURL(
            'api/webfuel/transaktionneedrecalcrn',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<ITransaktionNeedRecalcRN>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public get(id: TNullableNumber):Observable<TransaktionNeedRecalcRN>{
        if(id===null||id===undefined||id<1){
            return of(null);
        }
        const uri = MEApiTool.buildEntityURL(
            "api/webfuel/transaktionneedrecalcrn",
            id
        );
        return this.http
            .get<TransaktionNeedRecalcRN>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r=>{
                    return TransaktionNeedRecalcRNImpl.fromResult(r);
                }),take(1)
            );
    }
}