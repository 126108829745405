import {IMEListResult, MERemoteList} from '../../maennl-commons';
import {Authority} from './authority.class';
import {AuthorityImpl} from './authority.impl';
import {IAuthority} from './authority.interface';
import {TAuthority, TNullableAuthority} from './authority.types';

export class AuthorityList extends MERemoteList<Authority> {
    __classname = 'AuthorityList';
    __instance = '';

    static fromListResult(res: IMEListResult<IAuthority>): AuthorityList {
        const newList = new AuthorityList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun = false, timeout = 400) {
        super();
        this.initList(autorun, timeout);
    }

    public doPopulateFromListResult(
        res: IMEListResult<TAuthority>,
        defaultSort = 'name',
        defaultOrder = 'asc',
        clearFilter = true
    ): void {
        super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    }

    convertEntity(entity: TAuthority): TNullableAuthority {
        return AuthorityImpl.fromResult(entity);
    }
}
