import {
  IMEListResult,
  MERemoteList
} from '../../../maennl-commons/mecommon-list';
import { CSDServerClientType } from './csdserver-client-type';
import { ICSDServerClientType } from './icsdserver-client-type';
import { SimpleCSDServerClientTypeFilter } from './simple-csdserver-client-type-filter';

export class CSDServerClientTypeList extends MERemoteList<CSDServerClientType> {
  __classname = 'CSDServerClientTypeList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<ICSDServerClientType>
  ): CSDServerClientTypeList {
    const newList = new CSDServerClientTypeList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleCSDServerClientTypeFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'label',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return CSDServerClientType.fromResult(entity);
  }
}
