import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { Device } from '../../router/common/device';
import { ISonde } from './isonde';
import { Peilung } from './peilung';

export class Sonde extends MEGenericEntity<ISonde> implements ISonde {
  id = 0;
  device: Device = null;
  accesscode = '';
  adresse = 0xc1;
  ordernumber = '';
  fertigungsnummer = '';
  offset1 = 0;
  offset2 = 0;
  typ = '';
  revision = '';
  port = '';
  bezeichnung = '';
  info = '';
  lastPeilung: Date = null;
  maxHoehe = 0;
  minHoehe = 0;
  hasSensor2 = false;
  hasTemperaturSensor = false;
  peilungenCount = 0;
  nummer = -1;
  lastPeilungData: Peilung = null;
  inverseMode = false;
  einheit = '';
  warnmenge = 0;
  minTemperatur = -30;
  maxTemperatur = 80;
  firstPeilung: Date = null;
  firstPeilungData: Peilung = null;
  labelStand1: string;
  labelStand2: string;

  static fromResult(entity: ISonde, createIfNull: boolean = false): Sonde {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Sonde();
      }
      return null;
    }

    const instance = new Sonde();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addConverterField(
          'device',
          new MEGenericEntityConverterParams<Device>((value: any) => {
            return Device.fromResult(value);
          })
        )
        .addTextField('accesscode')
        .addLongField('adresse')
        .addTextField('ordernumber')
        .addTextField('fertigungsnummer')
        .addLongField('offset1')
        .addLongField('offset2')
        .addTextField('typ')
        .addTextField('revision')
        .addTextField('port')
        .addTextField('bezeichnung')
        .addTextField('info')
        .addDateField('lastPeilung')
        .addLongField('maxHoehe')
        .addLongField('minHoehe')
        .addBooleanField('hasSensor2')
        .addBooleanField('hasTemperaturSensor')
        .addLongField('peilungenCount')
        .addLongField('nummer')
        .addConverterField(
          'lastPeilungData',
          new MEGenericEntityConverterParams<Peilung>((value: any) => {
            return Peilung.fromResult(value);
          })
        )
        .addConverterField(
          'firstPeilungData',
          new MEGenericEntityConverterParams<Peilung>((value: any) => {
            return Peilung.fromResult(value);
          })
        )
        .addBooleanField('inverseMode')
        .addTextField('einheit')
        .addTextField('labelStand1')
        .addTextField('labelStand2')
        .addDateField('firstPeilung')
    );
    return instance;
  }

  constructor() {
    super();
  }

  displayHeight(n: number) {
    if (this.inverseMode) {
      return this.maxHoehe - n;
    }
    return n;
  }

  getBezeichnung(): string {
    if (
      this.bezeichnung === null ||
      this.bezeichnung === undefined ||
      this.bezeichnung.trim() === ''
    ) {
      if (this.device !== null && this.device !== undefined) {
        if (this.nummer !== null && this.nummer !== undefined) {
          return 'Sonde ' + this.nummer + ' @ ' + this.device.bezeichnung;
        }
        return 'Sonde @ ' + this.device.bezeichnung;
      }
      if (this.nummer !== null && this.nummer !== undefined) {
        return 'Sonde ' + this.nummer;
      }
      return 'Sonde';
    }
    return this.bezeichnung;
  }
}
