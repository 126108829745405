import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  MEDataTableComponent,
  MEDataTableRow,
  MERowActionParams,
  TMEDTGetExtraRowClassesCallback
} from '../../../maennl-commons/data-table';
import { AliveList } from '../../common/alive-list';
import { SysHealthService } from '../../services/sys-health.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Alive } from '../../common/alive';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { MERichError, noop } from '../../../maennl-commons/tools';
import { MEDTMouseOverEvent } from '../../../maennl-commons/data-table/types';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';
import * as moment from 'moment';
import { Duration } from 'moment';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-sys-health-lebenszeichen-display',
  templateUrl: './sys-health-lebenszeichen-display.component.html',
  styleUrls: ['./sys-health-lebenszeichen-display.component.scss']
})
export class SysHealthLebenszeichenDisplayComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'SysHealthHistoryDisplayComponent';
  __instance = '';

  displayActive = false;

  @ViewChild(MEDataTableComponent, { static: false }) dt = null;

  public verlauf: AliveList = new AliveList();
  freshUntil: Duration = moment.duration(30, 'minutes');
  oldAfter: Duration = moment.duration(12, 'hours');
  veryOldAfter: Duration = moment.duration(16, 'hours');
  tooOldAfter: Duration = moment.duration(24, 'hours');

  constructor(
    public healthService: SysHealthService,
    public toastr: ToastrService,
    protected cd: ChangeDetectorRef,
    public modalService: NgbModal,
    public benutzerService: BenutzerService
  ) {
    super();
  }

  rowClassGetter: TMEDTGetExtraRowClassesCallback<Alive> = (
    row: MEDataTableRow<Alive>,
    idx
  ) => {
    if (
      row !== null &&
      row !== undefined &&
      row.data !== null &&
      row.data !== undefined &&
      row.data.zeitpunkt !== null &&
      row.data.zeitpunkt !== undefined
    ) {
      return 'lebenszeichen-' + row.data.status;
    }
  };

  ngAfterViewInit() {
    if (this.dt !== null) {
      this.dt.extraRowClasses = this.rowClassGetter;
    }
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.verlauf.onUpdateRequired.subscribe((list: AliveList) => {
        this.healthService
          .getLebenszeichen(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.searchString
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
            },
            (e) => {
              console.log(e);
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste des verfügbaren Verlaufs konnte nicht abgerufen werden'
              );
            }
          );
      }, undefined)
    );

    if (this.dt !== null) {
      this.dt.extraRowClasses = this.rowClassGetter;
    }
  }

  onActivate() {
    this.verlauf.start();
  }

  onDeactivate() {
    noop();
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.verlauf.release();
  }

  removeDevice($event: MERowActionParams<Alive>) {
    this.healthService.removeAllLebenszeichen($event.row.data).subscribe(
      (a) => {
        this.toastr.success('Alle Einträge zu diesem Gerät wurden entfernt');
        this.verlauf.reload();
      },
      () => {
        this.toastr.error(
          'Beim Löschen der Lebenszeichen-Einträge ist ein Fehler aufgetreten'
        );
      }
    );
  }

  updateLegende($event: MEDTMouseOverEvent) {
    if (
      $event === null ||
      $event === undefined ||
      $event.row === null ||
      $event.row === undefined
    ) {
      this.freshUntil = moment.duration(30, 'minutes');
      this.oldAfter = moment.duration(12, 'hours');
      this.veryOldAfter = moment.duration(16, 'hours');
      this.tooOldAfter = moment.duration(24, 'hours');
    } else {
      const a = $event.row.data as Alive;
      this.freshUntil = moment.duration(a.freshUntil, 'minutes');
      this.oldAfter = moment.duration(a.oldAfter, 'minutes');
      this.veryOldAfter = moment.duration(a.veryOldAfter, 'minutes');
      this.tooOldAfter = moment.duration(a.tooOldAfter, 'minutes');
    }

    this.cd.markForCheck();
  }
}
