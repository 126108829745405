<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'heartbeat']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Systemstatus"
></me-area-heading>

<me-tab-card
    #rootTabCard
>

    <me-pane id="tab-probleme"
             title="Probleme"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
    >
        <app-sys-health-probleme-tab
                [id]="'SH-tab-probleme'"
        ></app-sys-health-probleme-tab>

    </me-pane>
    <me-pane id="tab-history"
             title="Verlauf"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)">
        <app-sys-health-history-tab
                [id]="'SH-tab-history'"
        ></app-sys-health-history-tab>
    </me-pane>
    <me-pane id="tab-details"
             title="Detailansicht"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
    >
        <me-tab-card
            #detailsTabCard
        >
            <me-pane *ngFor="let m of machines.data"
                     id="tab-{{m.code}}"
                     title="{{m.bezeichnung}}"
                     (activated)="activateTab($event.id)"
                     (deactivated)="deactivateTab($event.id)"
            >
                <app-sys-health-windows-machine-tab
                        *ngIf="m.machineType!==undefined && m.machineType!==null && m.machineType.code==='windows'"
                        [id]="'WM-tab-'+m.code"
                        [machine]="m"
                ></app-sys-health-windows-machine-tab>

                <app-sys-health-linux-machine-tab
                        *ngIf="m.machineType!==undefined && m.machineType!==null && m.machineType.code==='linux'"
                        [id]="'LM-tab-'+m.code"
                        [machine]="m"
                ></app-sys-health-linux-machine-tab>
            </me-pane>
        </me-tab-card>
    </me-pane>
    <me-pane
            id="tab-info"
            title="Informationen"
            icon="icofont icofont-info-circle"
    >
        <h2>Telegram-Bot</h2>
        <div class="d-flex justify-content-between mb-3">
            <input class="form-control" value="@maennlcontrol_bot"/>
            <a class="btn btn-primary" href="https://telegram.me/maennlcontrol_bot" target="_blank">
                <i class="fab fa-telegram"></i> in Telegram öffnen
            </a>
        </div>
        <p>
            dient zur Steuerung des Systems via Telegram Messenger. Nachrichten über Statusänderungen von Tests werden
            in den 1:1-Chat gepostet.
        </p>

        <h2>
            Telegram Systemstatus Gruppe
        </h2>
        <div class="d-flex justify-content-between mb-3">
            <input class="form-control" value="https://t.me/joinchat/B058GQ8XqtalGNCL59UfAw"/>
            <a class="btn btn-primary" href="https://t.me/joinchat/B058GQ8XqtalGNCL59UfAw" target="_blank">
                <i class="fab fa-telegram"></i> in Telegram öffnen
            </a>
        </div>
        <p>
            Hier werden Nachrichten zum Systemstatus gepostet. Man muss Mitglied dieser Gruppe sein.
        </p>
        <div class="mt-5">
            <button class="btn btn-primary" (click)="emergency_call()">Telefon-Notruf zu #8824 auslösen</button>
        </div>
    </me-pane>
</me-tab-card>
