<div *ngIf="hasModem()">
    <div class="row">
        <div class="col-6 text-nowrap">
            Signal
        </div>
        <div class="col-6 text-nowrap" title="Signalqualität 0-100">
            {{ testping.signal }}
        </div>
    </div>
    <div class="row">
        <div class="col-6 text-nowrap">
            Netzwerk
        </div>
        <div class="col-6 text-nowrap"
             title="Stärke des Netzwerks, je kleiner desto schlechter, Werte>0 deuten auf Fehler hin">
            {{ fNum(testping.networkStrength, '1.1-1') }} dBm
        </div>
    </div>
    <div class="row">
        <div class="col-6 text-nowrap">
            Netz
        </div>
        <div class="col-6 text-nowrap">
            {{ testping.operator }}
        </div>
    </div>
    <div class="row">
        <div class="col-6 text-nowrap">
            Modem-Status
        </div>
        <div class="col-6 text-nowrap">
            {{ modemState }}
        </div>
    </div>
    <div class="row">
        <div class="col-6 text-nowrap">
            Zugang
        </div>
        <div class="col-6 text-nowrap">
            {{ accesstechnologies }}
        </div>
    </div>
    <div class="row">
        <div class="col-6 text-nowrap">
            Netz-Status
        </div>
        <div class="col-6 text-nowrap">
            {{ status }}
        </div>
    </div>

</div>
<div *ngIf="!hasModem()">
    Kein Modem verbaut oder erkannt
</div>
