<me-data-table
        *ngIf="logs!==null && logs!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="logs.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        [extraRowClasses]="rowClassGetter"
        #logTable
>

    <me-data-table-action
            label="Filter setzen"
            [type]="'dropdown-options'"

            [classes]="getFilterButtonClasses()"

            (perform)="updateFilter()"
    >

        <me-list-option
                [label]="_('Fehler/Servicemeldungen')"
                [icon]="''"
                (perform)="selectFilter(1)"
        ></me-list-option>
    </me-data-table-action>
    <me-data-table-action
            [label]="_('Sortierreihenfolge')"
            [icon]="'icofont icofont-sort'"
            [type]="'dropdown'"
    >
        <me-list-option
                [label]="_('Standard (Vorgang abwärts, Zeitpunkt aufwärts)')"
                [icon]="''"
                (perform)="selectSort(0)"
        ></me-list-option>
        <me-list-option
                [label]="_('Chronologisch (Vorgang aufwärts, Zeitpunkt aufwärts)')"
                [icon]="''"
                (perform)="selectSort(2)"
        ></me-list-option>
        <me-list-option
                [label]="_('Chronologisch abwärts (Vorgang abwärts, Zeitpunkt aufwärts)')"
                [icon]="''"
                (perform)="selectSort(3)"
        ></me-list-option>
        <me-list-option
                [label]="_('zeitlich aufwärts')"
                [icon]="''"
                (perform)="selectSort(4)"
        ></me-list-option>
        <me-list-option
                [label]="_('zeitlich abwärts')"
                [icon]="''"
                (perform)="selectSort(5)"
        ></me-list-option>
        <me-list-option
                [label]="_('KFZ (Kennzeichen aufwärts, Zeitpunkt aufwärts) [funktioniert nur richtig bei KFZ-Filter!]')"
                [icon]="''"
                (perform)="selectSort(1)"
        ></me-list-option>
        >
    </me-data-table-action>
    <me-data-table-action
            [label]="_('Export')"
            [icon]="'icofont icofont-download-alt'"
            [type]="'dropdown'"
    >
        <me-list-option
                [label]="_('Log-Einträge .xlsx')"
                [icon]="'far fa-file-excel'"
                (perform)="downloadEntries('xlsx')"
        ></me-list-option>
    </me-data-table-action>
    <me-data-table-action
            *ngIf="false"
            label="Mellendorf kitzeln"
            [classes]="'btn btn-info'"
            (perform)="tickleMellendorf()"
    ></me-data-table-action>


    <me-data-table-column
            [label]="'Zeitpunkt'"
            [field]="'zeitpunkt'"
            [renderer]="timeR"
    ></me-data-table-column>
    <me-data-table-column label="Vorgang#" field="vorgang"></me-data-table-column>
    <me-data-table-column label="Zeile#" field="zeile"></me-data-table-column>
    <me-data-table-column [label]="'Gerät'" field="device"
                          [renderer]="deviceRenderer"
                          id="device-column"
                          [showFilterButton]="true"
                          (buttonClickEvent)="onButtonClick($event)"
    ></me-data-table-column>
    <me-data-table-column [label]="'Info'"
                          field="message"
                          id="message-column"
                          [showFilterButton]="true"
                          (buttonClickEvent)="onButtonClick($event)"
    ></me-data-table-column>
    <me-data-table-column [label]="'Details'" field="params"
                          [renderer]="paramsR"
                          [showFilterButton]="true"
                          (buttonClickEvent)="onButtonClick($event)"
    ></me-data-table-column>


    <me-data-table-table-extra-header>
        <me-pagination
                [total]="logs.total"
                [page]="logs.page"
                [perpage]="logs.size"
                (pageChange)="logs.setPage($event)"
                (pageCountChange)="logs.processPageCountChangedEvent($event)"
                [showSearch]="true"
                (queryChange)="logs.processQueryChangedEvent($event)"
                settingsid="nozzle-reader-logs"
                [settingsService]="benutzerService"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
