<div class="modal-header">
    <h4 class="modal-title" *ngIf="isAdd()">Eintrag anlegen</h4>
    <h4 class="modal-title" *ngIf="isEdit()">Eintrag bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <me-text-field [label]="'Terminal Info'" [(value)]="entity.display_text"></me-text-field>
    <app-tenant-selector [(value_id)]="entity.tenant"></app-tenant-selector>
    <app-terminal-type-selector [(value_id)]="entity.terminal_type"></app-terminal-type-selector>
    <app-inventory-type-selector [(value_id)]="entity.inventory_type"></app-inventory-type-selector>
    <me-num-field [label]="'Tankserver ID'" [(value)]="entity.tankserver_id"></me-num-field>
    <me-num-field [label]="'Boxnummer'" [(value)]="entity.boxnummer"></me-num-field>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i> Daten speichern
    </button>
</div>