import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  MEPaneComponent,
  MESubscriptionHelper,
  METabCardComponent,
} from '../../maennl-commons';
import { TNullableString } from '../../maennl-commons/tools/types';
import { TNAAddressesComponent } from './tnaaddresses/tnaaddresses.component';
import { TNAGroupsComponent } from './tnagroups/tnagroups.component';
import { TNANetworksComponent } from './tnanetworks/tnanetworks.component';

@Component({
  selector: 'app-ipaddresses',
  templateUrl: './ipaddresses.component.html',
  styleUrls: ['./ipaddresses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IPAddressesComponent
  implements OnInit, AfterViewInit, OnDestroy, AfterContentInit
{
  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;
  @ViewChild(TNANetworksComponent, { static: false })
  networksTab: TNANetworksComponent;
  @ViewChild(TNAAddressesComponent, { static: false })
  addressesTab: TNAAddressesComponent;
  @ViewChild(TNAGroupsComponent, { static: false })
  groupsTab: TNAGroupsComponent;

  constructor(
    public cd: ChangeDetectorRef,
    public route: ActivatedRoute,
    public modalService: NgbModal
  ) {}

  selectDefaultTab(): void {
    if (this.tabs === null || this.tabs === undefined) {
      return;
    }

    let tab = this.route?.snapshot?.queryParams?.selectTab as TNullableString;

    if (tab === null || tab !== undefined || ('' + tab).trim() != '') {
      tab = 'tab-addresses';
    }

    if (
      this.tabs.currentPane !== null &&
      this.tabs.currentPane !== undefined &&
      this.tabs.currentPane.id === tab
    ) {
      return;
    }

    this.tabs.selectById(tab);

    this.cd.markForCheck();
  }

  ngAfterViewInit(): void {
    this.selectDefaultTab();
  }

  ngAfterContentInit(): void {
    this.selectDefaultTab();
  }

  ngOnInit(): void {
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    if (
      this.tabs !== null &&
      this.tabs !== undefined &&
      this.tabs.currentPane !== null &&
      this.tabs.currentPane !== undefined
    ) {
      this.deactivateTab(this.tabs.currentPane.id);
    }
    MESubscriptionHelper.release(this);
  }

  activateTab(s: string) {
    console.log('activateTab', s);
    const t = [this.networksTab, this.addressesTab, this.groupsTab];
    t.forEach(tab => {
      if (tab !== null && tab !== undefined) {
        if (tab.id === 'MECIP-' + s) {
          tab.onActivate();
        }
      }
    });
    this.cd.markForCheck();
  }

  deactivateTab(s: string) {
    console.log('deactivateTab', s);
    const t = [this.networksTab, this.addressesTab, this.groupsTab];
    t.forEach(tab => {
      if (tab !== null && tab !== undefined) {
        if (tab.id === 'MECIP-' + s) {
          tab.onDeactivate();
        }
      }
    });
    this.cd.markForCheck();
  }

  onTabSelect($event: MEPaneComponent) {
    console.log('onTabSelect', $event);
  }
}
