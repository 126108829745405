<div class="modal-header">
    <h4 class="modal-title" *ngIf="isAdd()">Eintrag anlegen</h4>
    <h4 class="modal-title" *ngIf="isEdit()">Eintrag bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <me-tab-card
        #rootTabCard
    >

        <me-pane id="tab-tenant-editor"
                 title="Informationen"
                 (activated)="activate(tenantEditorTab)"
        >
            <app-tab-tenants-editor-infos [entity]="entity" [isAdd]="isAdd()"
                                          #tenantEditorTab></app-tab-tenants-editor-infos>
        </me-pane>
        <ng-container *ngIf="isEdit()">
            <me-pane id="tab-tenant-settings-editor"
                     title="Settings"
                     (activated)="activate(tenantSettingsEditorTab)">
                <app-tab-tenants-editor-settings #tenantSettingsEditorTab [entity]="entity" (entityChanged)="changedEntity($event)" ></app-tab-tenants-editor-settings>
            </me-pane>
            <me-pane id="tab-tenant-module-editor"
                     title="Module"
                     (activated)="activate(tenantModuleEditorTab)">
                <app-tab-tenants-editor-modules #tenantModuleEditorTab [entity]="entity" (entityChanged)="changedEntity($event)"></app-tab-tenants-editor-modules>
            </me-pane>
        </ng-container>
    </me-tab-card>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i> Daten speichern
    </button>
</div>