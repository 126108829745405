import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';

import {
    MEDataTableActionComponent,
    MEDataTableBooleanCellEditorComponent,
    MEDataTableBooleanCellRendererComponent,
    MEDataTableNotBooleanCellRendererComponent,
    MEDataTableBytesCellRendererComponent,
    MEDataTableCellEditorComponent,
    MEDataTableCellFilterComponent,
    MEDataTableCellRendererComponent,
    MEDataTableColumnComponent,
    MEDataTableComponent,
    MEDataTableDateCellRendererComponent,
    MEDataTableDecimalCellRendererComponent,
    MEDataTableIntegerCellFilterComponent,
    MEDataTableIntegerCellRendererComponent,
    MEDataTableNumCellEditorComponent,
    MEDataTableRowActionDirective,
    MEDataTableTableExtraHeaderComponent,
    MEDataTableTextCellEditorComponent,
    MEDataTableTextCellFilterComponent,
    MEDataTableTextCellRendererComponent
} from './data-table';
import {
    MEBooleanFieldComponent,
    MEDateTimeFieldComponent,
    MEDateTimePickerComponent,
    MEDateTimeRangeSelectorComponent,
    MENumFieldComponent,
    MESelectComponent,
    MESelectFieldComponent,
    MESelectFieldOptionDirective,
    METextFieldComponent,
    MEOptionFieldComponent,
    ME_DATETIME_VALUE_ACCESSOR
} from './forms';
import {MEAreaHeadingComponent} from './mearea-heading';
import {MECardComponent} from './mecard';
import {MEConfirmDialogComponent} from './meconfirm-dialog';
import {MEGenericModalComponent} from './megeneric-modal';
import {MEPaginationComponent} from './mepagination';
import {MESortButtonsComponent} from './mesort-buttons';
import {
    MEPaneComponent,
    METabCardComponent,
    METabsetComponent
} from './metab-card';
import {
    MEBytesPipe,
    MEHighlitePipe,
    MERemoveZeroDecimalPlacesPipe,
    MEShortenPipe
} from './pipes';
import {MEPubSubService} from './services';
import {HttpClientModule} from '@angular/common/http';
import {
    MEHttpClientInterceptorConfig,
    ME_HTTP_CLIENT_INTERCEPTOR_CONFIG
} from './network';
import {MEDataTableExtraTableActionComponent} from './data-table';
import {MEIconComponent} from './meicon';
import {MEDataTableCurrencyCellRendererComponent} from './data-table';
import {METabCardExtraHeaderComponent} from './metab-card/metab-card-extra-header.component';
import {MEDiagrammBalkenComponent, MEDiagrammLineComponent} from './svgtools';
import {MEListOptionComponent} from './melist-option/melist-option.component';
import {MESaveHtmlPipe} from './pipes';
import {MESaveStylesPipe} from './pipes';
import {MEOptionComponent} from './meoption/meoption.component';
import {MEGenericFieldComponent} from './forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
    METextFieldIconWithOutputEventComponent
} from "./forms/components/metext-field-icon-with-output-event.component";

const components = [
    MEAreaHeadingComponent,
    MEBooleanFieldComponent,
    MECardComponent,
    MEConfirmDialogComponent,
    MEDataTableBooleanCellEditorComponent,
    MEDataTableBooleanCellRendererComponent,
    MEDataTableNotBooleanCellRendererComponent,
    MEDataTableBytesCellRendererComponent,
    MEDataTableCellEditorComponent,
    MEDataTableCellFilterComponent,
    MEDataTableCellRendererComponent,
    MEDataTableColumnComponent,
    MEDataTableComponent,
    MEDataTableDateCellRendererComponent,
    MEDataTableDecimalCellRendererComponent,
    MEDataTableIntegerCellFilterComponent,
    MEDataTableIntegerCellRendererComponent,
    MEDataTableNumCellEditorComponent,
    MEDataTableTextCellEditorComponent,
    MEDataTableTextCellFilterComponent,
    MEDataTableTextCellRendererComponent,
    MEDateTimeFieldComponent,
    MEDateTimePickerComponent,
    MEGenericModalComponent,
    MENumFieldComponent,
    MEPaginationComponent,
    MEPaneComponent,
    MESelectComponent,
    MESelectFieldComponent,
    MESortButtonsComponent,
    METabCardComponent,
    METabsetComponent,
    METextFieldComponent,
    MEDateTimeRangeSelectorComponent,
    MEDataTableTableExtraHeaderComponent,
    MEDataTableExtraTableActionComponent,
    MEDataTableCurrencyCellRendererComponent,
    MEIconComponent,
    METabCardExtraHeaderComponent,
    MEDiagrammLineComponent,
    MEDiagrammBalkenComponent,
    MEDataTableActionComponent,
    MEListOptionComponent,
    MEOptionComponent,
    MEGenericFieldComponent,
    MEOptionFieldComponent,
    METextFieldIconWithOutputEventComponent
];
const pipes = [
    MERemoveZeroDecimalPlacesPipe,
    MEHighlitePipe,
    MEBytesPipe,
    MESaveHtmlPipe,
    MESaveStylesPipe,
    MEShortenPipe
];
const directives = [
    MESelectFieldOptionDirective,
    MEDataTableRowActionDirective
];

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        FontAwesomeModule
    ],
    declarations: [...components, ...pipes, ...directives],
    exports: [...components, ...pipes, ...directives]
})
export class MaennlCommonsModule {
    public static forRoot(
        httpClientInterceptorConfig: MEHttpClientInterceptorConfig = null
    ) {
        return {
            ngModule: MaennlCommonsModule,
            providers: [
                MEPubSubService,
                ME_DATETIME_VALUE_ACCESSOR,
                {
                    provide: ME_HTTP_CLIENT_INTERCEPTOR_CONFIG,
                    useValue: httpClientInterceptorConfig
                }
            ]
        };
    }
}
