import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {METabCardComponent, METool} from "../../maennl-commons";
import {TabImportsJobsComponent} from "./tab-imports-jobs/tab-imports-jobs.component";

type TTabType= null
    | TabImportsJobsComponent;

@Component({
    selector: 'app-imports',
    templateUrl: './imports.component.html',
    styleUrls: ['./imports.component.scss']
})
export class ImportsComponent implements OnInit, AfterViewInit {
    @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;
    @ViewChild('importsJobsTab', { static: false }) ka: TabImportsJobsComponent;

    private _currentTab: TTabType = null;

    public get currentTab(): TTabType {
        return this._currentTab
    }

    public set currentTab(value: TTabType) {
        if (this._currentTab !== value) {
            if (!METool.isNullOrUndefined(this._currentTab)) {
                this._currentTab.onDeactivate();
            }
            this._currentTab = value;
            if (!METool.isNullOrUndefined(this._currentTab)) {
                this._currentTab.onActivate();
            }
        }
    }

    ngAfterViewInit() {
        this.currentTab = this.ka;
    }

    constructor() {
    }

    ngOnInit() {
    }

    activateTab(s:string) {
        const found = false;

        if (!found) {
            console.log(s);
        }
    }

    deactivateTab(s:string) {
        const found = false;

        if (!found) {
            console.log(s);
        }
    }

    public activate(tab: TTabType) {
        console.log(tab);
        this.currentTab = tab;
    }
}