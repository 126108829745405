import { MEGenericEntity } from '../../maennl-commons/megeneric-entity/megeneric-entity';
import { MEGenericEntityConverterParams } from '../../maennl-commons/megeneric-entity/megeneric-entity-converter-params';
import { MEGenericEntityFields } from '../../maennl-commons/megeneric-entity/megeneric-entity-field';
import { MEConverterTool } from '../../maennl-commons/tools/meconverter-tool';
import { IMitschnittgeraet } from './imitschnittgeraet';
import { IMitschnittstream } from './imitschnittstream';
import { Mitschnittgeraet } from './mitschnittgeraet';

export class Mitschnittstream
  extends MEGenericEntity<IMitschnittstream>
  implements IMitschnittstream
{
  connected: Date = null;
  connectionId = 0;
  device: Mitschnittgeraet = null;
  machine = '';
  maxReceived: Date = null;
  minReceived: Date = null;
  mitschnittId = 0;
  numPackets = 0;
  streamLength = 0;

  static fromResult(
    entity: IMitschnittstream,
    createIfNull: boolean = false
  ): Mitschnittstream {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Mitschnittstream();
      }
      return null;
    }

    const instance: Mitschnittstream = new Mitschnittstream();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addField('connected', 'date')
        .addField('connectionId', 'long')
        .addField('machine', 'text')
        .addField('maxReceived', 'date')
        .addField('minReceived', 'date')
        .addField('mitschnittId', 'long')
        .addField('numPackets', 'long')
        .addField('streamLengh', 'long')
        .addField(
          'device',
          'converter',
          new MEGenericEntityConverterParams<Mitschnittgeraet>((value: any) => {
            return Mitschnittgeraet.fromResult(value);
          })
        )
    );
    return instance;
  }

  constructor(
    connected: any = null,
    connectionId: number = 0,
    device: IMitschnittgeraet = null,
    machine: string = '',
    maxReceived: any = null,
    minReceived: any = null,
    mitschnittId: number = 0,
    numPackets: number = 0,
    streamLength: number = 0
  ) {
    super();
    this.connected = MEConverterTool.toDate(connected);
    this.connectionId = connectionId;
    this.device = Mitschnittgeraet.fromResult(device);
    this.machine = machine;
    this.maxReceived = MEConverterTool.toDate(maxReceived);
    this.minReceived = MEConverterTool.toDate(minReceived);
    this.mitschnittId = mitschnittId;
    this.numPackets = numPackets;
    this.streamLength = streamLength;
  }
}
