import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    MESelectComponent,
    TMESelectItemFormatterCallback,
    TMESelectItemMatcherCallback,
    TMESelectItemTextCallback
} from '../../../maennl-commons/forms/components/me-select';
import {MELocalizedComponent} from '../../../maennl-commons/localized-component';
import {MEHighlitePipe} from '../../../maennl-commons/pipes';
import {MESubscriptionHelper} from '../../../maennl-commons/services';
import {Contact} from '../../common/contact';
import {ContactList} from '../../common/contact-list';
import {SimpleContactFilter} from '../../common/simple-contact-filter';
import {TelefonanlageService} from '../../services/telefonanlage.service';

@Component({
    selector: 'app-contact-selector',
    template: `
        <me-select
                [asMatrix]="false"
                [idField]="'id'"
                [items]="contacts.data"
                [placeholder]="placeholder"
                [small]="small"
                (onSearch)="doSearch($event)"
                (onSelect)="doSelect($event)"
                [nullable]="nullable"
                [onFormat]="itemFormat"
                [onGetText]="itemText"
                [onMatch]="itemMatcher"
        ></me-select>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSelectorComponent
    extends MELocalizedComponent
    implements OnInit, OnDestroy, AfterViewInit {
    private _showInternOnly = false;

    public get showInternOnly(): boolean {
        return this._showInternOnly;
    }

    @Input()
    public set showInternOnly(value: boolean) {
        if (this._showInternOnly !== value) {
            this._showInternOnly = value;
            this.cd.markForCheck();
        }
    }

    @Input()
    set value(v: Contact) {
        if (this._value !== v) {
            this._value = v;
            if (this.meSelect !== null && this.meSelect !== undefined) {
                this.meSelect.value = v;
            }
            this.valueChange.emit(v);
            this.value_idChange.emit(this.value_id);
        }
    }

    get value(): Contact {
        return this._value;
    }

    @Input()
    set value_id(v: number | null) {
        if (v === null || v === undefined || isNaN(v)) {
            this.value = null;
            return;
        }
        if (this.value_id === v) {
            return;
        }
        this.telefonanlageService.getContact(v, false).subscribe({
            next: (v) => {
                this.value = Contact.fromResult(v);
            }, error: (e) => {
                this.value = null;
            }
        })
    }

    get value_id(): number | null {
        if (this.value === null || this.value === undefined) {
            return null;
        }
        return this._value.id;
    }

    __classname = 'ContactSelectorComponent';
    __instance = '';

    @Input() nullable = true;
    @Input() placeholder = 'Kontakt auswählen';
    @Input() small = false;

    @Input() filterQuery = '';

    public _value: Contact = null;

    @Output() valueChange = new EventEmitter<Contact>();
    @Output() value_idChange = new EventEmitter<number | null>();

    public contacts: ContactList = new ContactList();

    @ViewChild(MESelectComponent, {static: false}) meSelect: MESelectComponent;

    ngAfterViewInit(): void {
        if (this.meSelect !== null && this.meSelect !== undefined) {
            this.meSelect.onFormat = this.itemFormat;
            this.meSelect.onGetText = this.itemText;
            this.meSelect.onMatch = this.itemMatcher;
            this.meSelect.value = this._value;
        }
        this.contacts.start();
    }

    constructor(
        public telefonanlageService: TelefonanlageService,
        public cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        this.contacts.setWindowSize(250);

        MESubscriptionHelper.add(
            this,
            this.contacts.onUpdateRequired.subscribe((list: ContactList) => {
                list.searchString2 = ('' + this.filterQuery).trim();
                if (this._showInternOnly) {
                    (list.simpleFilter as SimpleContactFilter).isIntern = true;
                }
                this.telefonanlageService
                    .listContacts(
                        list.size,
                        list.calcOffset(),
                        list.order,
                        list.simpleFilter,
                        list.getQuery()
                    )
                    .subscribe((l) => {
                        list.populateFromListResult(l);
                        this.cd.markForCheck();
                    }, undefined);
            }, undefined)
        );

        if (this.meSelect !== null && this.meSelect !== undefined) {
            this.meSelect.onFormat = this.itemFormat;
            this.meSelect.onGetText = this.itemText;
            this.meSelect.onMatch = this.itemMatcher;
        }
        this.contacts.start();
    }

    doSearch(value) {
        if (value === null || value === undefined) {
            value = '';
        }

        this.contacts.simpleFilter.clear();
        this.contacts.searchString2 = ('' + this.filterQuery).trim();
        this.contacts.searchString = ('' + value).trim();
        this.contacts.reload();
    }

    doSelect(value: Contact) {
        this._value = value;
        this.valueChange.emit(value);
        this.value_idChange.emit(this.value_id);
    }

    public itemFormat: TMESelectItemFormatterCallback<Contact> = (
        item: Contact,
        search,
        forList: boolean
    ) => {
        if (item === null || item === undefined) {
            return '';
        }
        return MEHighlitePipe.transformString('' + item.displayText, search);
    };

    public itemText: TMESelectItemTextCallback<Contact> = (item: Contact) => {
        if (item === null || item === undefined) {
            return '';
        }
        return item.displayText;
    };

    public itemMatcher: TMESelectItemMatcherCallback<Contact> = (
        item: Contact,
        search
    ) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x =
            item.displayText +
            ' ' +
            item.id +
            ' ' +
            item.firma +
            ' ' +
            item.kundennummer +
            ' ' +
            item.vorname +
            ' ' +
            item.nachname;

        return x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
    };

    ngOnDestroy(): void {
        MESubscriptionHelper.release(this);
        this.contacts.release();
        super.ngOnDestroy();
    }
}
