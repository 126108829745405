import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-netz-dessau',
  templateUrl: './netz-dessau.component.html',
  styleUrls: ['./netz-dessau.component.scss']
})
export class NetzDessauComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
