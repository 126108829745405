import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { AbrechnungPosition } from './abrechnung-position';
import { IAbrechnungGlukoseportal } from './iabrechnung-glukoseportal';
import { IAbrechnungPosition } from './iabrechnung-position';

export class AbrechnungGlukoseportal
  extends MEGenericEntity<IAbrechnungGlukoseportal>
  implements IAbrechnungGlukoseportal
{
  anschrift = '';
  kartenanfragen = 0;
  projektCode = '';
  tarif = '';
  transaktionen = 0;
  username = '';
  abgerechnet: Date = null;
  endDay = 32;
  id = 0;
  jahr = 2019;
  lastUpdated: Date = null;
  monat = 0;
  periode = '';
  positionen: IAbrechnungPosition[] = [];
  startDay = 0;
  summe = 0;

  constructor() {
    super();
  }

  static fromResult(
    entity: IAbrechnungGlukoseportal,
    createIfNull: boolean = false
  ): AbrechnungGlukoseportal {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new AbrechnungGlukoseportal();
      }
      return null;
    }

    const instance = new AbrechnungGlukoseportal();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('periode')
        .addLongField('jahr')
        .addLongField('monat')
        .addDecimalField('summe')
        .addLongField('transaktionen')
        .addLongField('kartenanfragen')
        .addTextField('username')
        .addTextField('anschrift')
        .addTextField('projektCode')
        .addTextField('tarif')
        .addDateField('lastUpdated')
        .addLongField('startDay')
        .addLongField('endDay')
        .addDateField('abgerechnet')
        .addConverterField(
          'positionen',
          new MEGenericEntityConverterParams<AbrechnungPosition[]>(
            (value: any) => {
              const r: AbrechnungPosition[] = [];

              if (Array.isArray(value)) {
                value.forEach((e) => {
                  const p = AbrechnungPosition.fromResult(
                    e as IAbrechnungPosition
                  );
                  if (p !== null) {
                    r.push(p);
                  }
                });
              }
              return r;
            }
          )
        )
    );
    return instance;
  }
}
