import { Component, Injector } from '@angular/core';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';
import { MEDataTableLocalizedCellRenderer } from '../../../maennl-commons/data-table/renderer';
import { Device } from '../../common/device';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { RouterService } from '../../services/router.service';
import { IDevice } from '../../common/idevice';

@Component({
  selector: 'app-medtdevice-sys-status-renderer',
  template: `
    <div *ngIf="routerService.isMEConnectRouter(device)">
      <app-device-disk-usage-display
        [device]="device"
      ></app-device-disk-usage-display>
      <div class="text-center">
        <span [class.text-danger]="device.load1 > 5">{{
          fNum(device.load1, '1.2-2')
        }}</span>
        /
        <span [class.text-danger]="device.load5 > 5">{{
          fNum(device.load5, '1.2-2')
        }}</span>
        /
        <span [class.text-danger]="device.load15 > 5">{{
          fNum(device.load15, '1.2-2')
        }}</span>
      </div>
    </div>
    <div *ngIf="routerService.isMEConnectRouterV2(device)">
      <app-device-disk-usage-display
        [device]="device"
      ></app-device-disk-usage-display>
      <div class="text-center">
        <span [class.text-danger]="device.load1 > 5">{{
          fNum(device.load1, '1.2-2')
        }}</span>
        /
        <span [class.text-danger]="device.load5 > 5">{{
          fNum(device.load5, '1.2-2')
        }}</span>
        /
        <span [class.text-danger]="device.load15 > 5">{{
          fNum(device.load15, '1.2-2')
        }}</span>
      </div>
    </div>
  `
})
export class MEDTDeviceSysStatusRendererComponent extends MEDataTableLocalizedCellRenderer {
  public cell: any = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<Device> = null;

  public device: IDevice = null;

  constructor(public injector: Injector, public routerService: RouterService) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');

    if (this.row !== null && this.row !== undefined) {
      this.device = this.row.data;
    }
  }
}
