import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { AbrechnungPosition } from './abrechnung-position';
import { IAbrechnungPickelmann } from './iabrechnung-pickelmann';
import { IAbrechnungPosition } from './iabrechnung-position';

export class AbrechnungPickelmann
  extends MEGenericEntity<IAbrechnungPickelmann>
  implements IAbrechnungPickelmann
{
  menge = 0;
  transaktionen = 0;
  abgerechnet: Date = null;
  endDay = 31;
  id = 0;
  jahr = 0;
  lastUpdated: Date = null;
  monat = 1;
  periode = '0-0';
  positionen: AbrechnungPosition[] = [];
  startDay = 1;
  summe = 0;

  constructor() {
    super();
  }

  static fromResult(
    entity: IAbrechnungPickelmann,
    createIfNull: boolean = false
  ): AbrechnungPickelmann {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new AbrechnungPickelmann();
      }
      return null;
    }

    const instance = new AbrechnungPickelmann();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('periode')
        .addLongField('jahr')
        .addLongField('monat')
        .addDecimalField('summe')
        .addDecimalField('menge')
        .addLongField('transaktionen')
        .addDateField('lastUpdated')
        .addLongField('startDay')
        .addLongField('endDay')
        .addDateField('abgerechnet')
        .addConverterField(
          'positionen',
          new MEGenericEntityConverterParams<AbrechnungPosition[]>(
            (value: any) => {
              const r: AbrechnungPosition[] = [];

              if (Array.isArray(value)) {
                value.forEach((e) => {
                  const p = AbrechnungPosition.fromResult(
                    e as IAbrechnungPosition
                  );
                  if (p !== null) {
                    r.push(p);
                  }
                });
              }
              return r;
            }
          )
        )
    );
    return instance;
  }
}
