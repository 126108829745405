<div
        class="d-flex align-items-start justify-content-between"
        *ngIf="liste!==null && liste!==undefined">
    <div class="flex-fill">
        <me-data-table
            #tnrrnInterfaceTable
            [rowActionsAsButtons]="true"
            [tableActionsAsButtons]="true"
            [data]="liste.asDataTableRows()"
            [selectable]="false"
            [singleSelect]="false"
            [hideCheckboxes]="true"
            actionsicon="icofont icofont-navigation-menu">
            <me-data-table-column
                    [label]="'ID'"
                    [field]="'id'"></me-data-table-column>
            <ng-template #tenant let-p="item" let-row="row">
                <div title="Mandant">
                    [{{ p }}]{{ row.data._tenant_info }}
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'Mandant'"
                    [field]="'tenant'"
                    [template]="tenant"></me-data-table-column>
            <me-data-table-column
                    [label]="'Zeitpunkt'"
                    [field]="'queued'"
                    [template]="timestamp"></me-data-table-column>
            <me-data-table-column
                    [label]="'Station'"
                    [field]="'_station_info'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Terminal'"
                    [field]="'_terminal_info'"></me-data-table-column>
            <ng-template #timestamp let-p="item" let-row="row">
                <div title="zeitpunkt">
                    {{fDate(p,'medium') }}
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'Transaktion ID'"
                    [field]="'transaktion_id'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Version'"
                    [field]="'version'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Eintragnr./Mandant'"
                    [field]="'rn'"></me-data-table-column>
            <me-data-table-table-extra-header>
                <me-pagination
                        [total]="liste.total"
                        [page]="liste.page"
                        [perpage]="liste.size"
                        (pageChange)="liste.setPage($event)"
                        (pageCountChange)="liste.processPageCountChangedEvent($event)"
                        (queryChange)="liste.processQueryChangedEvent($event)"
                        settingsid="position-needs-process-rn"
                        [settingsService]="benutzerservice"
                        [showSearch]="false"
                        [showCount]="true"
                ></me-pagination>
            </me-data-table-table-extra-header>
        </me-data-table>
    </div>
</div>