import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Sonde } from '../common/sonde';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  MEPubSubService,
  MESubscriptionHelper
} from '../../maennl-commons/services';
import { SondeService } from '../services/sonde.service';
import { ISonde } from '../common/isonde';

@Component({
  selector: 'app-sonde-editor',
  templateUrl: './sonde-editor.component.html',
  styleUrls: ['./sonde-editor.component.scss']
})
export class SondeEditorComponent
  extends MEGenericModalComponent<Sonde>
  implements OnInit, OnDestroy
{
  __classname = 'SondeEditorComponent';
  __instance = '';

  entity: Sonde = null;

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public pubsub: MEPubSubService,
    public sondeService: SondeService,
    public cd: ChangeDetectorRef
  ) {
    super(activeModal, pubsub);
  }

  public initEntity(src: ISonde): Sonde {
    return Sonde.fromResult(src, true);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    MESubscriptionHelper.release(this);
  }
}
