import { Injectable } from '@angular/core';
import { BenutzerService } from '../../benutzer/services/benutzer.service';
import { HttpClient } from '@angular/common/http';
import { ISonde } from '../common/isonde';
import { IMEListResult } from '../../maennl-commons/mecommon-list';
import { IMESimpleFilter } from '../../maennl-commons/filters';
import { MEResultMetaSort } from '../../maennl-commons/mesort-buttons';
import { IMEActionResponse, MEApiTool } from '../../maennl-commons/network';
import { IPeilung } from '../common/ipeilung';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class SondeService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public listSonden(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<ISonde> = null
  ): Observable<IMEListResult<ISonde>> {
    const uri = MEApiTool.buildListURL(
      '/api/sonden/sonden',
      max,
      offset,
      sort,
      filter
    );
    return this.http
      .get<IMEListResult<ISonde>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public listPeilungen(
    sonde: ISonde = null,
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IPeilung> = null,
    searchQuery = ''
  ): Observable<IMEListResult<IPeilung>> {
    const uri = MEApiTool.buildListURL(
      sonde === null || sonde === undefined || sonde.id < 1
        ? '/api/sonden/peilungen'
        : '/api/sonden/sonden/' +
            encodeURIComponent('' + sonde.id) +
            '/peilungen',
      max,
      offset,
      sort,
      filter,
      searchQuery
    );
    return this.http
      .get<IMEListResult<IPeilung>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public requestPeilung(sonde: ISonde): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL(
      '/api/sonden/sonden/',
      sonde.id,
      '_peilung'
    );
    return this.http
      .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  getSonde(sondeId: number): Observable<ISonde> {
    const uri = MEApiTool.buildEntityURL('/api/sonden/sonden/', sondeId);
    return this.http
      .get<ISonde>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }
}
