import {IPositionNeedsProcessRN} from "./position_needs_process_rn.interface";
import {MEGenericEntity} from "../../../maennl-commons";
import {TAPIDate, TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";

export class PositionNeedsProcessRN
    extends MEGenericEntity<IPositionNeedsProcessRN>
    implements IPositionNeedsProcessRN {

    changed: TNullableString;
    id: TNullableNumber;
    position_id: TNullableNumber;
    queued: TAPIDate;
    rn: TNullableNumber;
    tenant: TNullableNumber;
    version: TNullableNumber;
    _tenant_info: TNullableString;
    _station_info: TNullableString;
    _terminal_info: TNullableString;
}