import { MEGenericFilter } from '../../maennl-commons/filters/megeneric-filter';
import { Mitschnittgeraet } from './mitschnittgeraet';
import { Mitschnittstream } from './mitschnittstream';
import * as moment from 'moment';

export class SimpleMitschnittstreamFilter extends MEGenericFilter<Mitschnittstream> {
  connection_id: string = null;
  mitschnitt_id: Mitschnittgeraet = null;
  connected: Date[] = null;
  received: Date[] = null;

  public encodeField(fieldname: string): string {
    if (fieldname === 'connection_id') {
      return (
        'filter.' +
        encodeURIComponent(fieldname) +
        '=' +
        encodeURIComponent('' + this.connection_id)
      );
    }
    if (fieldname === 'mitschnitt_id') {
      return (
        'filter.' +
        encodeURIComponent(fieldname) +
        '=' +
        encodeURIComponent('' + this.mitschnitt_id.id)
      );
    }
    if (fieldname === 'connected') {
      if (this.connected != null && this.connected.length > 1) {
        let r = 'filter.' + encodeURIComponent(fieldname) + '=';
        if (this.connected[0] != null) {
          r = r + encodeURIComponent(moment(this.connected[0]).toISOString());
        }
        r = r + ',';
        if (this.connected[1] != null) {
          r = r + encodeURIComponent(moment(this.connected[1]).toISOString());
        }
        return r;
      }
    }
    if (fieldname === 'received') {
      if (this.received != null && this.received.length > 1) {
        let r = 'filter.' + encodeURIComponent(fieldname) + '=';
        if (this.received[0] != null) {
          r = r + encodeURIComponent(moment(this.received[0]).toISOString());
        }
        r = r + encodeURIComponent(',');
        if (this.received[1] != null) {
          r = r + encodeURIComponent(moment(this.received[1]).toISOString());
        }
        return r;
      }
    }
    return super.encodeField(fieldname);
  }
}
