import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BenutzerService} from '../../../benutzer/services/benutzer.service';
import {
    IMEListResult,
    MEApiTool,
    MEResultMetaSort
} from '../../../maennl-commons';
import {IMESimpleFilter} from '../../../maennl-commons/filters';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {IMwstKorrektur} from '../commons/imwst-korrektur';
import {IPreisSendung} from '../commons/ipreis-sendung';
import {IPreistransfer} from '../commons/ipreistransfer';

@Injectable({
    providedIn: 'root'
})
export class PreiseService {
    constructor(public http: HttpClient, public loginService: BenutzerService) {
    }

    public listMwstKorrekturen(
        max: number = 25,
        offset: number = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<IMwstKorrektur> = null,
        queryString = ''
    ): Observable<IMEListResult<IMwstKorrektur>> {
        const uri = MEApiTool.buildListURL(
            '/api/clearing/preise/mwst-korrekturen',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        console.log(uri, queryString);
        return this.http
            .get<IMEListResult<IMwstKorrektur>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public listPreisSendungen(
        max: number = 25,
        offset: number = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<IPreisSendung> = null,
        queryString = ''
    ): Observable<IMEListResult<IPreisSendung>> {
        const uri = MEApiTool.buildListURL(
            '/api/clearing/preise/preis-sendungen',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        console.log(uri, queryString);
        return this.http
            .get<IMEListResult<IPreisSendung>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public listPreistransfers(
        preis_id: number = -1,
        max: number = 100,
        offset: number = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<IPreistransfer> = null,
        queryString = ''
    ): Observable<IMEListResult<IPreistransfer>> {
        const uri = MEApiTool.buildListURL(
            '/api/clearing/preise/preis-sendungen/' +
            encodeURIComponent(preis_id) +
            '/transfers',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        console.log(uri, queryString);
        return this.http
            .get<IMEListResult<IPreistransfer>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }
}
