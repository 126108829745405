import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { SimProvider } from '../../common/sim-provider';
import { SimProviderList } from '../../common/sim-provider-list';
import { RouterService } from '../../services/router.service';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';
import {
  MESelectComponent,
  TMESelectItemFormatterCallback,
  TMESelectItemMatcherCallback,
  TMESelectItemTextCallback
} from '../../../maennl-commons/forms/components/me-select';
import { MEHighlitePipe } from '../../../maennl-commons/pipes';

@Component({
  selector: 'app-sim-provider-selector',
  template: `
    <me-select
      [asMatrix]="false"
      [idField]="'id'"
      [items]="providers.data"
      [placeholder]="placeholder"
      [small]="small"
      (onSearch)="doSearch($event)"
      (onSelect)="doSelect($event)"
      [nullable]="nullable"
      [onFormat]="itemFormat"
      [onGetText]="itemText"
      [onMatch]="itemMatcher"
      [value]="value"
    ></me-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimProviderSelectorComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'SimProviderSelectorComponent';
  __instance = '';

  @Input()
  nullable = true;
  @Input()
  placeholder = 'Dienstanbieter auswählen';
  @Input()
  small = false;

  public _value: SimProvider = null;

  @Input()
  set value(v: SimProvider) {
    if (this._value !== v) {
      console.log(this._value);
      this._value = v;
      if (this.meSelect !== null && this.meSelect !== undefined) {
        this.meSelect.value = v;
        this.meSelect.markForCheck();
      }
      this.cd.markForCheck();

      this.valueChange.emit(v);
    }
  }

  get value(): SimProvider {
    return this._value;
  }

  @Output()
  valueChange = new EventEmitter<SimProvider>();

  public;
  providers: SimProviderList = new SimProviderList();

  @ViewChild(MESelectComponent, { static: false }) meSelect: MESelectComponent;

  constructor(
    public routerService: RouterService,
    public cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.providers.onUpdateRequired.subscribe((list: SimProviderList) => {
        this.routerService
          .listProviders(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.getQuery()
          )
          .subscribe((l) => {
            list.populateFromListResult(l);
            this.cd.markForCheck();
          }, undefined);
      }, undefined)
    );
  }

  ngAfterViewInit() {
    this.meSelect.onFormat = this.itemFormat;
    this.meSelect.onGetText = this.itemText;
    this.meSelect.onMatch = this.itemMatcher;
    this.providers.start();
  }

  doSearch(value) {
    this.providers.simpleFilter.clear();
    this.providers.searchString = ('' + value).trim();
    this.providers.reload();
  }

  doSelect(value: SimProvider) {
    this._value = value;
    this.valueChange.emit(value);
  }

  public itemFormat: TMESelectItemFormatterCallback<SimProvider> = (
    item: SimProvider,
    search,
    forList: boolean
  ) => {
    if (item === null || item === undefined) {
      return '';
    }
    return MEHighlitePipe.transformString('' + item.name, search);
  };

  public itemText: TMESelectItemTextCallback<SimProvider> = (
    item: SimProvider
  ) => {
    if (item === null || item === undefined) {
      return '';
    }
    return item.name;
  };

  public itemMatcher: TMESelectItemMatcherCallback<SimProvider> = (
    item: SimProvider,
    search
  ) => {
    if (!search) {
      return true;
    }
    if (search.trim() === '') {
      return true;
    }
    if (!item) {
      return false;
    }
    const x = item.name + ' ' + item.id + ' ' + item.network;

    return x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
  };

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.providers.release();
    super.ngOnDestroy();
  }
}
