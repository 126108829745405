import { MEGenericEntity } from '../../../maennl-commons';
import {
  TNullableBoolean,
  TNullableNumber,
  TNullableString,
} from '../../../maennl-commons/tools/types';
import { IFirewall } from './firewall.interface';

export class Firewall extends MEGenericEntity<IFirewall> implements IFirewall {
  code: TNullableString = null;
  description: TNullableString = null;
  flush_rules: TNullableBoolean = null;
  id: TNullableNumber = null;
  name: TNullableString = null;

  constructor() {
    super();
  }
}
