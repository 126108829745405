import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { METabCardComponent } from '../maennl-commons/metab-card';
import { MESubscriptionHelper } from '../maennl-commons/services';
import { MEAppInjector } from '../maennl-commons/tools/meapp-injector';
import { TelefonanlageService } from '../telefonanlage/services/telefonanlage.service';
import { MachineList } from './common/machine-list';
import { SysHealthHistoryTabComponent } from './controls/sys-health-history-tab/sys-health-history-tab.component';
import { SysHealthLebenszeichenTabComponent } from './controls/sys-health-lebenszeichen-tab/sys-health-lebenszeichen-tab.component';
import { SysHealthLinuxMachineTabComponent } from './controls/sys-health-linux-machine-tab/sys-health-linux-machine-tab.component';
import { SysHealthProblemeTabComponent } from './controls/sys-health-probleme-tab/sys-health-probleme-tab.component';
import { SysHealthWindowsMachineTabComponent } from './controls/sys-health-windows-machine-tab/sys-health-windows-machine-tab.component';

@Component({
  selector: 'app-sys-health',
  templateUrl: './sys-health.component.html',
  styleUrls: ['./sys-health.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SysHealthComponent
  implements OnInit, AfterViewInit, OnDestroy, AfterContentInit
{
  __classname = 'SysHealthComponent';
  __instance = '';

  public machines = new MachineList();

  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;
  @ViewChild('detailsTabCard', { static: false })
  detailsTabs: METabCardComponent;
  @ViewChildren(SysHealthWindowsMachineTabComponent)
  windowMachines: QueryList<SysHealthWindowsMachineTabComponent>;
  @ViewChildren(SysHealthLinuxMachineTabComponent)
  linuxMachines: QueryList<SysHealthLinuxMachineTabComponent>;
  @ViewChild(SysHealthProblemeTabComponent, { static: false })
  problemetab: SysHealthProblemeTabComponent;
  @ViewChild(SysHealthHistoryTabComponent, { static: false })
  historyTab: SysHealthHistoryTabComponent;
  @ViewChild(SysHealthLebenszeichenTabComponent, { static: false })
  lebenszeichenTab: SysHealthLebenszeichenTabComponent;

  constructor(
    public cd: ChangeDetectorRef,
    public route: ActivatedRoute,
    public toastr: ToastrService
  ) {}

  public refreshTabs() {
    if (this.tabs !== null && this.tabs !== undefined) {
      this.activateTab(this.tabs.currentPane.id);
    }
    this.cd.markForCheck();
  }

  public ngAfterContentInit(): void {
    // ignore
  }

  ngOnInit() {
    this.machines.populateFromListResult(this.route.snapshot.data.machineList);
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.deactivateTab(this.tabs.currentPane.id);
    this.machines.release();
    MESubscriptionHelper.release(this);
  }

  ngAfterViewInit(): void {
    if (
      Object.keys(this.route.snapshot.queryParams).indexOf('selectTab') >= 0
    ) {
      this.tabs.selectById(this.route.snapshot.queryParams.selectTab);
    } else {
      this.tabs.selectById('tab-probleme');
    }
    this.cd.markForCheck();
  }

  activateTab(s: string) {
    let found = false;

    if (s === 'tab-details') {
      if (this.detailsTabs !== null && this.detailsTabs !== undefined) {
        this.activateTab(this.detailsTabs.currentPane.id);
      }
    }

    if (this.windowMachines !== null && this.windowMachines !== undefined) {
      this.windowMachines.forEach((wm: SysHealthWindowsMachineTabComponent) => {
        if (wm.id === 'WM-' + s) {
          wm.onActivate();
          found = true;
        }
      });
    }

    if (this.linuxMachines !== null && this.linuxMachines !== undefined) {
      this.linuxMachines.forEach((lm: SysHealthLinuxMachineTabComponent) => {
        if (lm.id === 'LM-' + s) {
          lm.onActivate();
          found = true;
        }
      });
    }

    if (this.problemetab !== null && this.problemetab !== undefined) {
      if (this.problemetab.id === 'SH-' + s) {
        this.problemetab.onActivate();
        found = true;
      }
    }
    if (this.historyTab !== null && this.historyTab !== undefined) {
      if (this.historyTab.id === 'SH-' + s) {
        this.historyTab.onActivate();
        found = true;
      }
    }

    if (this.lebenszeichenTab !== null && this.lebenszeichenTab !== undefined) {
      if (this.lebenszeichenTab.id === 'SH-' + s) {
        this.lebenszeichenTab.onActivate();
        found = true;
      }
    }

    if (!found) {
      console.log(s);
    }
  }

  deactivateTab(s: string) {
    let found = false;

    if (this.windowMachines !== null && this.windowMachines !== undefined) {
      this.windowMachines.forEach((wm: SysHealthWindowsMachineTabComponent) => {
        if (wm.id === 'WM-' + s) {
          wm.onDeactivate();
          found = true;
        }
      });
    }

    if (this.linuxMachines !== null && this.linuxMachines !== undefined) {
      this.linuxMachines.forEach((lm: SysHealthLinuxMachineTabComponent) => {
        if (lm.id === 'LM-' + s) {
          lm.onDeactivate();
          found = true;
        }
      });
    }

    if (this.problemetab !== null && this.problemetab !== undefined) {
      if (this.problemetab.id === 'SH-' + s) {
        this.problemetab.onDeactivate();
        found = true;
      }
    }
    if (this.historyTab !== null && this.historyTab !== undefined) {
      if (this.historyTab.id === 'SH-' + s) {
        this.historyTab.onDeactivate();
        found = true;
      }
    }
    if (this.lebenszeichenTab !== null && this.lebenszeichenTab !== undefined) {
      if (this.lebenszeichenTab.id === 'SH-' + s) {
        this.lebenszeichenTab.onDeactivate();
        found = true;
      }
    }
    if (!found) {
      console.log(s);
    }
  }

  emergency_call() {
    console.log('emergency_call');
    let s = MEAppInjector.get(TelefonanlageService) as TelefonanlageService;
    if (s === null || s === undefined) {
      this.toastr.error(
        'Dienstkomponente Telefonanlagenservice nicht verfügbar'
      );
      return;
    }

    s.startEmergencyCall().subscribe(r => {
      if (r !== null && r !== undefined && r.success) {
        this.toastr.success('System-Notruf erfolgreich eingeleitet');
      } else {
        this.toastr.error('System-Notruf konnte nicht eingeleitet werden');
      }
    });
  }
}
