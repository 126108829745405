<ng-template #ahIcon>
    <fa-icon [icon]="['fas', 'box-archive']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="webfuel Remote Backup"
></me-area-heading>


<me-tab-card
    #rootTabCard
>

    <me-pane id="tab-customers"
             title="Kunden"
             (activated)="activate(customersTab)"
    >
        <app-tab-rbcustomers #customersTab></app-tab-rbcustomers>
    </me-pane>
    <me-pane id="tab-terminals"
             title="Terminals"
             (activated)="activate(terminalsTab)"
    >
        <app-tab-rbterminals #terminalsTab></app-tab-rbterminals>
    </me-pane>
    <me-pane id="tab-plans"
             title="Abonnements"
             (activated)="activate(plansTab)"
    >
        <app-tab-rbplans #plansTab></app-tab-rbplans>
    </me-pane>

</me-tab-card>
