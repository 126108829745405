import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {MEGenericModalComponent, MEPubSubService, METool} from "../../../../../maennl-commons";
import {WireguardClient} from "../../../commons/wireguard-client.class";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {WireguardClientService} from "../../../commons/wireguard-client.service";
import {ToastrService} from "ngx-toastr";
import {WireguardClientImpl} from "../../../commons/wireguard-client.impl";

@Component({
    selector: 'app-wireguard-client-ip-editor',
    templateUrl: './wireguard-client-ip-editor.component.html',
    styleUrls: ['./wireguard-client-ip-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WireguardClientIPEditorComponent
    extends MEGenericModalComponent<WireguardClient>
    implements OnInit, OnDestroy {

    entity:WireguardClient=null;
    newIP:string ='';

    public initEntity(src: WireguardClient): WireguardClient {
        return WireguardClientImpl.fromResult(src,false);
    }

    constructor(
        public activeModal: NgbActiveModal,
        public pubsub: MEPubSubService,
        public service: WireguardClientService,
        public toastr: ToastrService,
        public cd: ChangeDetectorRef
    ) {
        super(activeModal, pubsub);
    }

    ngOnInit() {
    }

    save() {
        if (!METool.isNullOrUndefined(this.entity)) {
            let re = /^((25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)\.){3}(25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)\/(3[0-2]|[1-2]\d|\d)$/;
            if (!re.test(this.newIP.trim())) {
                this.toastr.error(
                    'Die IP Adresse ist ungültig!'
                )
                return;
            }
            this.entity.allowed_ips.push(this.newIP.trim());
        }
        this.service.store(this.entity).subscribe({
            next: r => {
                if (r.success) {
                    this.ok();
                } else {
                    this.toastr.error(
                        'Die Hinzufügen der IP ist fehlgeschlagen!'
                    );
                }
            },
            error: () => {
                this.toastr.error('Die Hinzufügen der IP ist fehlgeschlagen!');
            }
        });
    }
}