import { Component, Injector } from '@angular/core';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';
import { Device } from '../../common/device';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer';

@Component({
  selector: 'app-medtdevice-disk-usage-display-renderer',
  template: `
    <app-device-disk-usage-display
      [device]="row.data"
      *ngIf="isRowValid()"
    ></app-device-disk-usage-display>
  `
})
export class MEDTDeviceDiskUsageDisplayRendererComponent extends MEDataTableCommonCellRenderer {
  public cell: boolean = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<Device> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }
}
