import { MEGenericEntityFields } from '../../../maennl-commons';
import { BlocklistV4 } from './blocklist-v4.class';
import { TBlocklistV4, TNullableBlocklistV4 } from './blocklist-v4.types';

export class BlocklistV4Impl {
  static fromResult(
    entity: TBlocklistV4,
    createIfNull: boolean = false
  ): TNullableBlocklistV4 {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new BlocklistV4();
      }
      return null;
    }

    const instance = new BlocklistV4();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextFields(['ip', 'info'])
        .addBooleanFields(['enabled', 'is_valid']),
      (e: BlocklistV4) => {
        if (e.ip === null || e.ip === undefined) {
          e.ip = '';
          e.is_valid = false;
        }
        if (e.info === null || e.info === undefined) {
          e.info = '';
        }
        if (e.enabled === null || e.enabled === undefined) {
          e.enabled = false;
        }
        if (e.is_valid === null || e.is_valid === undefined) {
          e.is_valid = false;
        }
      }
    );

    return instance;
  }
}
