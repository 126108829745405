import {Injectable} from '@angular/core';
import {
    IMEActionResponse,
    IMEListResult,
    MEApiTool,
    MEResultMetaSort
} from '../../maennl-commons';
import {IMitschnittgeraet} from '../common/imitschnittgeraet';
import {IMitschnittstream} from '../common/imitschnittstream';
import {IMitschnittstreamPaket} from '../common/imitschnittstream-paket';
import {Mitschnittstream} from '../common/mitschnittstream';
import {HttpClient} from '@angular/common/http';
import {BenutzerService} from '../../benutzer/services/benutzer.service';
import {IMESimpleFilter} from '../../maennl-commons/filters';
import {map, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {Mitschnittgeraet} from "../common/mitschnittgeraet";
import {CSDServerBridge} from "../../meconnect/csdserver/commons/csdserver-bridge";

@Injectable()
export class MitschnittService {
    constructor(public http: HttpClient, public loginService: BenutzerService) {
    }

    public listDevices(
        max: number = 50,
        offset: number = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<IMitschnittgeraet> = null
    ): Observable<IMEListResult<IMitschnittgeraet>> {
        const uri = MEApiTool.buildListURL(
            '/api/mitschnitt/devices',
            max,
            offset,
            sort,
            filter
        );
        return this.http
            .get<IMEListResult<IMitschnittgeraet>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public listStreams(
        max: number = 50,
        offset: number = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<IMitschnittstream> = null
    ): Observable<IMEListResult<IMitschnittstream>> {
        const uri = MEApiTool.buildListURL(
            '/api/mitschnitt/streams',
            max,
            offset,
            sort,
            filter
        );
        return this.http
            .get<IMEListResult<IMitschnittstream>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public listPackets(
        stream: Mitschnittstream,
        max: number = 50,
        offset: number = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<IMitschnittstreamPaket> = null
    ): Observable<IMEListResult<IMitschnittstreamPaket>> {
        const uri = MEApiTool.buildListURL(
            '/api/mitschnitt/streams/' +
            encodeURIComponent('' + stream.connectionId) +
            '/packets',
            max,
            offset,
            sort,
            filter
        );
        return this.http
            .get<IMEListResult<IMitschnittstreamPaket>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public loadDevice(id: number): Observable<IMitschnittgeraet> {
        const uri = MEApiTool.buildEntityURL('/api/mitschnitt/devices/', id);
        return this.http
            .get<IMitschnittgeraet>(uri, this.loginService.getRequestOptions())
            .pipe(
                map((c) => {
                    return Mitschnittgeraet.fromResult(c);
                }),
                take(1)
            );
    }

    public storeDevice(entity: Mitschnittgeraet): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            '/api/mitschnitt/devices',
            entity.id
        );

        if (entity.id === null || entity.id === undefined || entity.id < 1) {
            return this.http
                .post<IMEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(take(1));
        }
        return this.http
            .put<IMEActionResponse>(
                uri,
                entity,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public removeClient(entity: Mitschnittgeraet): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            '/api/mitschnitt/devices',
            entity.id
        );
        return this.http
            .delete<IMEActionResponse>(uri, this.loginService.getRequestOptions())
            .pipe(take(1));
    }
}
