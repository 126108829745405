export class MEVersion {
  major = 0;
  minor = 0;
  patch = 0;
  build = 0;

  constructor(
    ma: any = null,
    mi: number = null,
    p: number = null,
    b: number = null
  ) {
    this.setVersion(ma, mi, p, b);
  }

  setVersion(
    ma: any = null,
    mi: number = null,
    p: number = null,
    b: number = null
  ) {
    if (mi === null && p === null && b === null && ma !== null) {
      const parts: string[] = ('' + ma).split('.');

      while (parts.length < 4) {
        parts.push('0');
      }

      this.major = parseInt('' + parts[0], 10);
      this.minor = parseInt('' + parts[1], 10);
      this.patch = parseInt('' + parts[2], 10);
      this.build = parseInt('' + parts[3], 10);
    } else {
      this.major = parseInt('' + ma, 10);
      this.minor = parseInt('' + mi, 10);
      this.patch = parseInt('' + p, 10);
      this.build = parseInt('' + b, 10);
    }

    if (isNaN(this.major)) {
      this.major = 0;
    }
    if (isNaN(this.minor)) {
      this.minor = 0;
    }
    if (isNaN(this.patch)) {
      this.patch = 0;
    }
    if (isNaN(this.major)) {
      this.build = 0;
    }
  }

  compareTo(v: MEVersion): number {
    if (v == null) {
      return 1;
    }

    if (v.major > this.major) {
      return -1;
    }
    if (v.major < this.major) {
      return 1;
    }

    if (v.minor > this.minor) {
      return -1;
    }
    if (v.minor < this.minor) {
      return 1;
    }

    if (v.patch > this.patch) {
      return -1;
    }
    if (v.patch < this.patch) {
      return 1;
    }

    if (v.build > this.build) {
      return -1;
    }
    if (v.build < this.build) {
      return 1;
    }

    return 0;
  }

  isGreaterThan(v: MEVersion): boolean {
    return this.compareTo(v) > 0;
  }

  isEqualTo(v: MEVersion): boolean {
    return this.compareTo(v) === 0;
  }

  isLowerThan(v: MEVersion): boolean {
    return this.compareTo(v) < 0;
  }

  isGreaterThanEquals(v: MEVersion): boolean {
    return this.compareTo(v) >= 0;
  }

  isLowerThanEquals(v: MEVersion): boolean {
    return this.compareTo(v) <= 0;
  }
}
