import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { Device } from '../../router/common/device';
import { ITestPing } from './itest-ping';

export class TestPing extends MEGenericEntity<ITestPing> implements ITestPing {
  get operator(): string {
    switch (this.operatorCode) {
      case 26201:
        return 'T-Mobile';
      case 26203:
        return 'Fonic/O2/Drillisch';
    }
    return 'unbekannt';
  }
  accessTechnologies = 0;
  device = -1;
  freeSpaceRoot = 0;
  freeSpaceSDCard = 0;
  id = 0;
  ip = '';
  load0 = 0;
  load15 = 0;
  load5 = 0;
  machineId = '';
  modemState = 0;
  networkStrength = 0;
  operatorCode = 0;
  regState = 0;
  signal = 0;
  temperatur = 0;
  totalSpaceRoot = 0;
  totalSpaceSDCard = 0;
  uptime = 0;
  zeitpunkt: Date = null;
  router: Device = null;
  cpuPercent = 0;
  memAvailable = 0;
  memTotal = 0;
  numCpus = 0;
  numPids = 0;
  swapFree = 0;
  swapTotal = 0;
  sequenz = 0;

  static fromResult(
    entity: ITestPing,
    createIfNull: boolean = false
  ): TestPing {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new TestPing();
      }
      return null;
    }

    const instance = new TestPing();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addConverterField(
          'router',
          new MEGenericEntityConverterParams<Device>((value: any) => {
            return Device.fromResult(value);
          })
        )
        .addTextField('ip')
        .addTextField('machineId')
        .addDateField('zeitpunkt')
        .addLongField('device')
        .addLongField('uptime')
        .addDecimalField('load0')
        .addDecimalField('load5')
        .addDecimalField('load15')
        .addDecimalField('temperatur')
        .addLongField('totalSpaceRoot')
        .addLongField('freeSpaceRoot')
        .addLongField('totalSpaceSDCard')
        .addLongField('freeSpaceSDCard')
        .addLongField('modemState')
        .addLongField('signal')
        .addLongField('networkStrength')
        .addLongField('operatorCode')
        .addLongField('accessTechnologies')
        .addLongField('regState')
        .addDecimalField('cpuPercent')
        .addLongField('memAvailable')
        .addLongField('memTotal')
        .addLongField('swapTotal')
        .addLongField('swapFree')
        .addLongField('numCpus')
        .addLongField('numPids')
        .addLongField('sequenz')
    );
    return instance;
  }

  constructor() {
    super();
  }
}
