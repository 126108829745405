<ng-template #ahIcon>
    <i class="icofont icofont-user"></i>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Benutzerverwaltung"
></me-area-heading>

<me-tab-card>
    <me-pane title="Benutzer"
             id="tab-benutzer"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
    >
        <app-tab-benutzer></app-tab-benutzer>
    </me-pane>
</me-tab-card>