import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IAbrechnungPositionArt } from './iabrechnung-position-art';

export class AbrechnungPositionArt
  extends MEGenericEntity<IAbrechnungPositionArt>
  implements IAbrechnungPositionArt
{
  id = 0;
  label = '';

  static fromResult(
    entity: IAbrechnungPositionArt,
    createIfNull: boolean = false
  ): AbrechnungPositionArt {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new AbrechnungPositionArt();
      }
      return null;
    }

    const instance = new AbrechnungPositionArt();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields().addLongField('id').addTextField('label')
    );
    return instance;
  }

  constructor() {
    super();
  }
}
