<div class="d-flex justify-content-between flex-nowrap">
    <div class="controller">
    </div>
    <div style="flex-grow: 100; width: 98%;">
        <div class="text-end options">
            <button type="button" class="btn btn-sm btn-secondary" *ngIf="showOrigin"
                    (click)="showOrigin=false">formatiertes Datenpaket anzeigen
            </button>
            <button type="button" class="btn btn-sm btn-secondary" *ngIf="!showOrigin"
                    (click)="showOrigin=true">Originales Datenpaket anzeigen
            </button>
        </div>
        <div *ngIf="showOrigin">
            <div class="chunk">
                <div class="header d-flex justify-content-between">
                    <div>
                        Originales Datenpaket
                    </div>
                </div>
                <div class="d-flex justify-content-start flex-wrap">
                    <div class='byte' *ngFor="let b of origin">
                        <div class="text-dark bg-secondary">{{ b }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!showOrigin">
            <ul class="chunks">
                <li *ngFor="let chunk of chunks" [ngClass]="'text-'+chunk.guessDirection()"
                >
                    <div class="chunk">
                        <div class="header d-flex justify-content-between">
                            <div class="d-flex">
                                {{ chunk.getTitle() }}
                                <div title="{{chunk.getInfo()}}" style="padding-left: 3px;">
                                    &#9432;
                                </div>
                            </div>
                            <div>
                                <button type="button" class="btn btn-sm" *ngIf="!chunk.isDummy() && !chunk.showRaw"
                                        (click)="chunk.showRaw=true">Rohdaten
                                </button>
                                <button type="button" class="btn btn-sm" *ngIf="!chunk.isDummy() && chunk.showRaw"
                                        (click)="chunk.showRaw=false">formatierte Daten
                                </button>
                                <button type="button" class="btn btn-sm"
                                        *ngIf="!chunk.isDummy() && !chunk.showRaw && chunk.showFillbytes"
                                        (click)="chunk.showFillbytes=false">
                                    <del>FF</del>
                                </button>
                                <button type="button" class="btn btn-sm"
                                        *ngIf="!chunk.isDummy() && !chunk.showRaw && !chunk.showFillbytes"
                                        (click)="chunk.showFillbytes=true"> FF
                                </button>
                            </div>
                        </div>
                        <div class="d-flex justify-content-start flex-wrap" *ngIf="chunk.isDummy() || chunk.showRaw">
                            <div class='byte' *ngFor="let b of chunk.hexBytes">
                                <div class="text-dark bg-secondary">{{ b }}</div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-start flex-wrap" *ngIf="!chunk.isDummy() && !chunk.showRaw">
                            <div class="byte stx" *ngIf="chunk.hasSTX()">STX</div>
                            <div class="byte fillbyte"
                                 *ngIf="chunk.hasSTX() && chunk.hasFillbytes() && chunk.showFillbytes">ff
                            </div>
                            <div class='byte cmd' *ngFor="let b of chunk.cmdBytes();let idx=index"
                                 [class.fillbyte]="b==='ff' && chunk.hasFillbytes() && ((idx % 2)==1)"
                                 [class.hidden]="b==='ff' && chunk.hasFillbytes() && ((idx % 2)==1) && !chunk.showFillbytes"
                            >
                                {{ b }}
                            </div>
                            <div class='byte daten' *ngFor="let b of chunk.dataBytes();let idx=index"
                                 [class.fillbyte]="b==='ff' && chunk.hasFillbytes() && ((idx % 2)==1)"
                                 [class.hidden]="b==='ff' && chunk.hasFillbytes() && ((idx % 2)==1) && !chunk.showFillbytes"
                                 [ngClass]="chunk.getClass(idx)"
                            >
                                <div title="{{chunk.getDataTitle(idx)}}">
                                   {{ b }}
                                </div>
                            </div>
                            <div class="byte etx" *ngIf="chunk.hasETX()">ETX</div>
                            <div class="byte fillbyte"
                                 *ngIf="chunk.hasETX() && chunk.hasFillbytes() && chunk.showFillbytes">ff
                            </div>
                            <div class='byte bg-secondary text-dark' *ngFor="let b of chunk.crcBytes();let idx=index"
                                 [class.fillbyte]="b==='ff' && chunk.hasFillbytes() && ((idx % 2)==1)"
                                 [class.hidden]="b==='ff' && chunk.hasFillbytes() && ((idx % 2)==1) && !chunk.showFillbytes"
                            >
                                {{ b }}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="endpunkt">
    </div>
</div>
