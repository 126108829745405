import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {MELocalizedComponent, MESubscriptionHelper} from "../../../maennl-commons";
import {ImportsJobsList} from "../commons/imports-jobs.list";
import {ImportsJobsService} from "../commons/imports-jobs.service";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-tab-imports-jobs',
    templateUrl: './tab-imports-jobs.component.html',
    styleUrls: ['./tab-imports-jobs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TabImportsJobsComponent
    extends MELocalizedComponent
    implements OnInit,OnDestroy,AfterViewInit {

    @Input() id ='';
    liste = new ImportsJobsList();

    private _tabActive = false;

    public get tabActive():boolean {
        return this._tabActive
    }

    @Input()
    public set tabActive(value:boolean) {
        if(this._tabActive!==value) {
            this._tabActive=value;
            this.cd.markForCheck();
            if(this._tabActive) {
                this.liste.start();
            }
        }
    }

    constructor(
        public cd:ChangeDetectorRef,
        public service: ImportsJobsService,
        public benutzerService: BenutzerService,
        public modalService: NgbModal,
        public toastr: ToastrService,
    ) {
        super();
    }

    ngOnInit() {
        MESubscriptionHelper.add(
            this,
            this.liste.onUpdateRequired.subscribe(() =>{
                this.cd.markForCheck();
                if(this.liste.loading){
                    return;
                }
                this.liste.loading=true;
                this.service.list(
                    this.liste.size,
                    this.liste.calcOffset(),
                    this.liste.order,
                    this.liste.simpleFilter,
                    this.liste.getQuery()
                )
                    .subscribe({
                        next:l => {
                            this.liste.doPopulateFromListResult(l);
                            this.liste.loading=false;
                            this.cd.markForCheck();
                        },
                        error:() =>{
                            this.liste.clear();
                            this.liste.loading=false;
                        }
                    });
                this.cd.markForCheck();
            })
        );
    }

    onActivate() {
        this.tabActive=true;
    }

    onDeactivate() {
        this.tabActive=false;
    }

    ngOnDestroy() {
        this.liste.release();
        MESubscriptionHelper.release(this);
    }

    ngAfterViewInit() {
    }
}