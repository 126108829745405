import {MEGenericEntity} from "../../../maennl-commons";
import {ITerminal} from "./terminal.interface";
import {TAPIDate, TNullableBoolean, TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";

export class Terminal
    extends MEGenericEntity<ITerminal>
    implements ITerminal {

    boxnummer: TNullableNumber=null;
    date_created: TAPIDate=null;
    display_text: TNullableString=null;
    error_state_change: TAPIDate=null;
    failed: TNullableBoolean=null;
    id: TNullableNumber=null;
    identcode: TNullableString=null;
    inventory_type: TNullableNumber=null;
    last_failure: TNullableString=null;
    last_updated: TAPIDate=null;
    nummer: TNullableNumber=null;
    settings_text: TNullableString=null;
    station: TNullableNumber=null;
    tankserver_id: TNullableNumber=null;
    tenant: TNullableNumber=null;
    terminal_type: TNullableNumber=null;
    _station_display_text: TNullableString=null;
    _tenant_info: TNullableString=null;
}