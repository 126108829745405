<div class="modal-header">
    <h4 class="modal-title">Sonde bearbeiten
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">

    <me-text-field label="Bezeichnung" [(value)]="entity.bezeichnung"></me-text-field>

    <me-tab-set>
        <me-pane title="Technische Daten">
            <me-text-field label="Typ" [(value)]="entity.typ"></me-text-field>
            <me-text-field label="Zugriffscode" [(value)]="entity.accesscode"></me-text-field>
            <me-text-field label="Order-Number" [(value)]="entity.ordernumber"></me-text-field>
            <me-text-field label="Fertigungsnummer" [(value)]="entity.fertigungsnummer"></me-text-field>
        </me-pane>
        <me-pane title="Anschluss">
            <app-device-selector [(value)]="entity.device"></app-device-selector>
        </me-pane>
    </me-tab-set>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
</div>
