import { MEPubSubService } from '../services';
import { MEHttpClientInterceptor } from './index';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectionToken, forwardRef } from '@angular/core';
import { Router } from '@angular/router';

export const ME_HTTP_CLIENT_INTERCEPTOR_CONFIG: InjectionToken<MEHttpClientInterceptorConfig> =
  new InjectionToken<MEHttpClientInterceptorConfig>(
    'ME_HTTP_CLIENT_INTERCEPTOR_CONFIG'
  );

export interface MEHttpClientInterceptorConfig {
  loginRoute: string;
}

export const ME_HTTP_CLIENT_INTERCEPTOR: any = {
  provide: HTTP_INTERCEPTORS,
  useClass: forwardRef(() => MEHttpClientInterceptor),
  deps: [MEPubSubService, Router, ME_HTTP_CLIENT_INTERCEPTOR_CONFIG],
  multi: true
};
