<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'heartbeat']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Boxlisten"
></me-area-heading>

<me-tab-card>
    <me-pane id="tab-boxliste"
             title="Boxliste"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
    >
        <app-sys-health-boxliste-tab
                [id]="'SH-tab-boxliste'"
        ></app-sys-health-boxliste-tab>
    </me-pane>

    <me-pane id="tab-boxliste-v4"
             title="Boxliste V4"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
    >
        <app-sys-health-boxliste-v4-tab
                [id]="'SH-tab-boxliste-v4'"
        ></app-sys-health-boxliste-v4-tab>
    </me-pane>
</me-tab-card>
