import { Component } from '@angular/core';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { SimpleMitschnittstreamFilter } from '../common/simple-mitschnittstream-filter';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MEPubSubService } from '../../maennl-commons/services';

@Component({
  selector: 'app-mitschnittstream-filter-dialog',
  templateUrl: './mitschnittstream-filter-dialog.component.html',
  styleUrls: ['./mitschnittstream-filter-dialog.component.css']
})
export class MitschnittstreamFilterDialogComponent extends MEGenericModalComponent<SimpleMitschnittstreamFilter> {
  public initEntity(
    src: SimpleMitschnittstreamFilter
  ): SimpleMitschnittstreamFilter {
    const f = new SimpleMitschnittstreamFilter();
    if (src !== null && src !== undefined) {
      f.connection_id = src.connection_id;
      f.connected = src.connected;
      f.received = src.received;
      f.mitschnitt_id = src.mitschnitt_id;
    }
    return f;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService
  ) {
    super(activeModal, pubsub);
  }
}
