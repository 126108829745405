import { MEGenericFilter } from '../../maennl-commons/filters';
import { ISonde } from './isonde';
import { Peilung } from './peilung';
import * as moment from 'moment';

export class SimplePeilungFilter extends MEGenericFilter<Peilung> {
  sonde: ISonde = null;
  zeitpunkt: Date[] = null;

  public encodeField(fieldname: string): string {
    if (fieldname === 'sonde') {
      if (this.sonde !== null && this.sonde !== undefined) {
        return (
          'filter.' +
          encodeURIComponent(fieldname) +
          '=' +
          encodeURIComponent('' + this.sonde.id)
        );
      } else {
        return '';
      }
    }
    if (fieldname === 'zeitpunkt') {
      if (this.zeitpunkt != null && this.zeitpunkt.length > 1) {
        let r = 'filter.' + encodeURIComponent(fieldname) + '=';
        if (this.zeitpunkt[0] != null) {
          r = r + encodeURIComponent(moment(this.zeitpunkt[0]).toISOString());
        }
        r = r + ',';
        if (this.zeitpunkt[1] != null) {
          r = r + encodeURIComponent(moment(this.zeitpunkt[1]).toISOString());
        }
        return r;
      }
    }
    return super.encodeField(fieldname);
  }
}
