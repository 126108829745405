import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MESelectComponent } from '../../../maennl-commons/forms/components/me-select';
import {
  TMESelectItemFormatterCallback,
  TMESelectItemMatcherCallback,
  TMESelectItemTextCallback
} from '../../../maennl-commons/forms/components/me-select';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';
import { MEHighlitePipe } from '../../../maennl-commons/pipes';
import { Mitschnittgeraet } from '../../common/mitschnittgeraet';
import { MitschnittgeraetList } from '../../common/mitschnittgeraet-list';
import { MitschnittService } from '../../services/mitschnitt.service';
import { MESubscriptionHelper } from '../../../maennl-commons/services';

@Component({
  selector: 'app-mitschnittgeraet-picker',
  template: `
    <me-select
      [asMatrix]="false"
      [idField]="'id'"
      [items]="devices.data"
      [placeholder]="placeholder"
      [small]="small"
      (onSearch)="doSearch($event)"
      (onSelect)="doSelect($event)"
      [nullable]="nullable"
      [onFormat]="itemFormat"
      [onMatch]="itemMatcher"
      [onGetText]="itemText"
    ></me-select>
  `
})
export class MitschnittgeraetPickerComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'MitschnittgeraetPickerComponent';
  __instance = '';

  @Input() nullable = true;
  @Input() placeholder = 'Mitschnittgerät auswählen';
  @Input() small = false;

  public _value: Mitschnittgeraet = null;

  @Output() valueChange = new EventEmitter<Mitschnittgeraet>();

  public devices: MitschnittgeraetList = new MitschnittgeraetList();

  @ViewChild(MESelectComponent, { static: false }) meSelect: MESelectComponent;
  ngAfterViewInit(): void {
    if (this.meSelect !== null && this.meSelect !== undefined) {
      this.meSelect.onFormat = this.itemFormat;
      this.meSelect.onGetText = this.itemText;
      this.meSelect.onMatch = this.itemMatcher;
      this.devices.start();
    }
  }

  @Input()
  set value(v: Mitschnittgeraet) {
    if (this._value !== v) {
      this._value = v;
      if (this.meSelect !== null && this.meSelect !== undefined) {
        this.meSelect.value = v;
      }
      this.valueChange.emit(v);
    }
  }

  get value(): Mitschnittgeraet {
    return this._value;
  }

  constructor(public mitschnittService: MitschnittService) {
    super();
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.devices.onUpdateRequired.subscribe((list: MitschnittgeraetList) => {
        this.mitschnittService
          .listDevices(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter
          )
          .subscribe((l) => {
            list.populateFromListResult(l);
          }, undefined);
      }, undefined)
    );
  }

  doSearch(value) {
    this.devices.simpleFilter.clear();
    if (value.trim() !== '') {
      this.devices.simpleFilter.deviceId = value.trim();
      this.devices.simpleFilter._operations['deviceId'] = 'OR';
    }
    this.devices.reload();
  }

  doSelect(value: Mitschnittgeraet) {
    this._value = value;
    this.valueChange.emit(value);
  }

  public itemFormat: TMESelectItemFormatterCallback<Mitschnittgeraet> = (
    item: Mitschnittgeraet,
    search,
    forList: boolean
  ) => {
    if (item === null || item === undefined) {
      return '';
    }
    return MEHighlitePipe.transformString('' + item.label, search);
  };

  public itemText: TMESelectItemTextCallback<Mitschnittgeraet> = (
    item: Mitschnittgeraet
  ) => {
    if (item === null || item === undefined) {
      return '';
    }
    return item.label;
  };

  public itemMatcher: TMESelectItemMatcherCallback<Mitschnittgeraet> = (
    item: Mitschnittgeraet,
    search
  ) => {
    if (!search) {
      return true;
    }
    if (search.trim() === '') {
      return true;
    }
    if (!item) {
      return false;
    }
    const x = item.deviceId + ' ' + item.id + ' ' + item.label;

    return x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
  };

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.devices.release();
    super.ngOnDestroy();
  }
}
