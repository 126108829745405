import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../../maennl-commons';
import { IPreistransfer } from './ipreistransfer';

export class Preistransfer
  extends MEGenericEntity<IPreistransfer>
  implements IPreistransfer
{
  id = -1;
  master_terminal = -2;
  nebenstelle_id = -1;
  nebenstelle_name = '';
  nebenstelle_nummer = -1;
  preise_nachher = '';
  preise_vorher = '';
  preisidmbox_id = -1;
  zeitpunkt: Date = null;

  static fromResult(
    entity: IPreistransfer,
    createIfNull: boolean = false
  ): Preistransfer {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Preistransfer();
      }
      return null;
    }

    const instance = new Preistransfer();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addLongField('preisidmbox_id')
        .addLongField('nebenstelle_id')
        .addDateField('zeitpunkt')
        .addTextField('preise_vorher')
        .addTextField('preise_nchher')
        .addTextField('nebenstelle_name')
        .addLongField('nebenstelle_nummer')
        .addLongField('master_terminal')
    );
    return instance;
  }

  constructor() {
    super();
  }

  get is_master(): boolean {
    return (
      this.master_terminal === -1 ||
      this.master_terminal === this.nebenstelle_id
    );
  }
}
