<me-data-table
        *ngIf="liste!==null && liste!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="liste.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        (onSortChanged)="liste.processSortChangedEvent($event)"
        #clientsTable
>

    <ng-template #datum let-p="item" let-row="row">
        <div class="text-start text-start" *ngIf="p!==null && p!==undefined">
            {{ fDate(p, 'YYYY-MM-DD HH:mm:ss', true) }}
        </div>
    </ng-template>
    <ng-template #nummer let-p="item" let-row="row">
        <div class="text-end text-end" *ngIf="p!==null && p!==undefined">
            {{ fNum(p, '1.0-0') }}
        </div>
    </ng-template>
    <ng-template #boolean let-p="item" let-row="row">
        <div class="text-start text-start" *ngIf="p!==null && p!==undefined">
                        <span class="me-2">
                <fa-icon [icon]="['far',(p) ? 'square-check' : 'square']"></fa-icon>
            </span>

            <span *ngIf="p">aktiv</span>
            <span *ngIf="!p">inaktiv</span>
        </div>
    </ng-template>


    <ng-template #customerTemplate let-p="item" let-row="row">
        <ng-container *ngFor="let row of typed(row.data)">
            <ng-container *ngIf="row.customerPOJO!==null && row.customerPOJO!==undefined">
                {{ row.customerPOJO.display_text }}
                <span class="badge bg-secondary text-dark">{{row.customerPOJO.projekt}}</span>
            </ng-container>
        </ng-container>
    </ng-template>
    <me-data-table-column [label]="'Kunde'"
                          [field]="'customerPOJO'"
                          [sortable]="true"
                          [template]="customerTemplate"
    ></me-data-table-column>

    <me-data-table-column [label]="'Info'"
                          [field]="'display_text'"
                          [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column [label]="'Boxnummer'"
                          [field]="'boxnummer'"
                          [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column [label]="'IP'"
                          [field]="'ip'"
                          [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column [label]="'Port'"
                          [field]="'port'"
                          [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column [label]="'Terminal-Addr'"
                          [field]="'terminal_addr'"
                          [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column [label]="'Kundennummer (TA)'"
                          [field]="'kundennummer'"
                          [sortable]="true"
    ></me-data-table-column>


    <me-data-table-column [label]="'# Buchungen'"
                          [field]="'num_bookings'"
                          [sortable]="true"
                          [template]="nummer"
    ></me-data-table-column>


    <me-data-table-column [label]="'letzte Buchung'"
                          [field]="'last_booking'"
                          [sortable]="true"
                          [template]="datum"
    ></me-data-table-column>
    <me-data-table-column [label]="'letzter Datenempfang'"
                          [field]="'last_received'"
                          [sortable]="true"
                          [template]="datum"
    ></me-data-table-column>


    <me-data-table-table-extra-header>
        <me-pagination
                [total]="liste.total"
                [page]="liste.page"
                [perpage]="liste.size"
                (pageChange)="liste.setPage($event)"
                (pageCountChange)="liste.processPageCountChangedEvent($event)"
                (queryChange)="liste.processQueryChangedEvent($event)"
                settingsid="webfuel-remotebackup-terminals"
                [settingsService]="benutzerService"
                [showSearch]="true"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
