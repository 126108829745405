<me-data-table
        *ngIf="liste!==null && liste!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="liste.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        (onSortChanged)="liste.processSortChangedEvent($event)"
        #clientsTable
>
    <ng-template #tenant let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <div>{{ t.info }}</div>
            <div class="small text-nowrap">
                {{ t.code }}
            </div>
        </ng-container>
    </ng-template>

    <me-data-table-column [label]="'Mandant'"
                          [field]="'code'"
                          [sortable]="true"
                          [template]="tenant"
    ></me-data-table-column>

    <me-data-table-column [label]="'Projekt'"
                          [field]="'projekt'"
                          [sortable]="true"
    >
    </me-data-table-column>

    <ng-template #modules let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <span class="badge bg-info" *ngFor="let m of t.modules">{{ m }}</span>
        </ng-container>
    </ng-template>

    <me-data-table-column [label]="'Module'"
                          [field]="'modules'"
                          [sortable]="false"
                          [template]="modules"
    ></me-data-table-column>
    <ng-template #digest let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <ng-container *ngIf="t.has_digest()">
                <div>{{ t.ta_access_digest }}</div>
                <div class="small">
                    {{t.ta_access_username}}
                    <span class="badge bg-secondary text-dark">{{t.ta_access_uid}}</span>
                </div>
                <div class="small">
                    Host: {{ t.hosts[0] }}
                </div>
            </ng-container>
            <ng-container *ngIf="!t.has_digest()">
                <button class="btn btn-primary" (click)="createTAAccessUser(t.id)">Benutzer anlegen</button>
            </ng-container>
        </ng-container>
    </ng-template>

    <me-data-table-column [label]="'Benutzer für TA-Access-Modul'"
                          [field]="'ta_access_digest'"
                          [sortable]="false"
                          [template]="digest"
    ></me-data-table-column>

    <me-data-table-action
            label="Eintrag hinzufügen"
            [icon]="'far fa-plus'"
            (perform)="addEntry()"
    ></me-data-table-action>

    <me-data-table-rowaction
            (perform)="editTenant($event)"
            icon="far fa-pencil"
            label="Bearbeiten"
            title="Eintrag bearbeiten"
    ></me-data-table-rowaction>

    <me-data-table-table-extra-header>
        <me-pagination
                [total]="liste.total"
                [page]="liste.page"
                [perpage]="liste.size"
                (pageChange)="liste.setPage($event)"
                (pageCountChange)="liste.processPageCountChangedEvent($event)"
                (queryChange)="liste.processQueryChangedEvent($event)"
                settingsid="webfuel-tenants"
                [settingsService]="benutzerService"
                [showSearch]="true"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
