<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'heartbeat']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="DHCP-Server Status"
></me-area-heading>

<me-tab-card>
    <me-pane id="tab-dhcp-leases"
             title="DHCP Leases"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
    >
        <app-tab-dhcpleases
                [id]="'tab-dhcpleases'"
        ></app-tab-dhcpleases>
    </me-pane>
</me-tab-card>
