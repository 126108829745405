import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'me-boolean-field',
  styles: [
    `
      :host .cbox {
        padding-top: 6px;
      }

      :host i {
        cursor: pointer;
      }

      :host i.disabled {
        cursor: not-allowed;
      }

      :host .addon button:first-child {
        border-left: none;
        padding: 0 5px;
      }
    `
  ],
  template: `
    <div class="form-group row mb-1" *ngIf="!flip">
      <label
        for="{{ id }}"
        class="col-{{ labelWidth }} col-form-label"
        *ngIf="labelWidth > 0"
      >
        {{ label }}
      </label>
      <div class="col-{{ 12 - labelWidth }} cbox">
        <i
          class="far"
          id="{{ id }}"
          [class.fa-check-square]="_value"
          [class.fa-square]="!value"
          [class.disabled]="disabled"
          (click)="toggle()"
          [title]="label"
        ></i>
      </div>
    </div>
    <div class="form-group row" *ngIf="flip">
      <div class="col-{{ 12 - labelWidth }} cbox">
        <i
          class="far"
          id="{{ id }}"
          [class.fa-check-square]="_value"
          [class.fa-square]="!value"
          [class.disabled]="disabled"
          (click)="toggle()"
          [title]="label"
        ></i>
      </div>
      <label
        for="{{ id }}"
        class="col-{{ labelWidth }} col-form-label"
        *ngIf="labelWidth > 0"
      >
        {{ label }}
      </label>
    </div>
  `
})
export class MEBooleanFieldComponent implements OnInit {
  @Input() public disabled = false;
  @Input() public enableAddon = false;
  @Input() public id = '';
  @Input() public label = 'Einschaltfeld';
  @Input() public labelWidth = 3;
  @Input() public small = false;
  @Output() public valueChange = new EventEmitter<boolean>();
  public _addOnLabels: string[] = [];
  public _icons: string[] = [];
  public _value = false;

  private _flip = false;

  public get flip(): boolean {
    return this._flip;
  }

  @Input()
  public set flip(value: boolean) {
    this._flip = value;
  }

  get value(): boolean {
    return this._value;
  }

  @Input()
  set value(v: boolean) {
    if (this._value !== v) {
      this._value = v;
      if (!this.disabled) {
        this.valueChange.emit(v);
      }
    }
  }

  get icon(): any {
    if (this._icons.length < 1) {
      return '';
    } else if (this._icons.length < 2) {
      return this._icons[0];
    }
    return this._icons;
  }

  @Input('addonicon')
  set icon(v: any) {
    this._icons = [];
    if (Array.isArray(v)) {
      v.forEach((i: any) => {
        this._icons.push(i);
      });
    } else {
      this._icons.push(v);
    }
    while (this._addOnLabels.length < this._icons.length) {
      this._addOnLabels.push('');
    }
  }

  @Input()
  set addonlabel(v: any) {
    this._addOnLabels = [];
    if (Array.isArray(v)) {
      v.forEach((i: any) => {
        this._addOnLabels.push(i);
      });
    } else {
      this._addOnLabels.push(v);
    }
    while (this._addOnLabels.length < this._icons.length) {
      this._addOnLabels.push('');
    }
  }

  public ngOnInit() {
    if (this.id === '' || this.id === null || this.id === undefined) {
      this.id =
        'tf' +
        Md5.hashStr(
          'booleanfield-' + this.label + Math.random() + '-' + Math.random()
        );
    }
  }

  public toggle() {
    if (!this.disabled) {
      this.value = !this.value;
    }
  }
}
