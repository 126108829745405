import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { METool } from '../../../maennl-commons/tools';
import { GenericChunk } from './generic-chunk';

@Component({
  selector: 'app-generic-display',
  templateUrl: './generic-display.component.html',
  styleUrls: ['./generic-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericDisplayComponent implements OnInit {
  chunks: GenericChunk[] = [];

  @Input()
  set data(v: any) {
    this.chunks = [];
    if (!METool.isNullOrUndefined(v)) {
      let currentChunk = new GenericChunk();
      const bytes: string[] = v.match(/.{2}/g);
      bytes.forEach((oneByte) => {
        currentChunk.add(oneByte);
        if (currentChunk.isFull()) {
          this.chunks.push(currentChunk);
          currentChunk = new GenericChunk();
        }
      });
      if (currentChunk.hasBytes()) {
        this.chunks.push(currentChunk);
      }
    }
    this.cd.markForCheck();
  }

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  public byteName(number: number) {
    switch (number) {
      case 0:
        return 'NUL';
      case 0x01:
        return 'SOH';
      case 0x02:
        return 'STX';
      case 0x03:
        return 'ETX';
      case 0x04:
        return 'EOT';
      case 0x05:
        return 'ENQ';
      case 0x06:
        return 'ACK';
      case 0x07:
        return 'BEL';
      case 0x08:
        return 'BS';
      case 0x09:
        return 'TAB';
      case 0x0a:
        return 'LF';
      case 0x0b:
        return 'VT';
      case 0x0c:
        return 'FF';
      case 0x0d:
        return 'CR';
      case 0x0e:
        return 'SO';
      case 0x0f:
        return 'SI';
      case 0x10:
        return 'DLE';
      case 0x11:
        return 'DC1';
      case 0x12:
        return 'DC2';
      case 0x13:
        return 'DC3';
      case 0x14:
        return 'DC4';
      case 0x15:
        return 'NAK';
      case 0x16:
        return 'SYN';
      case 0x17:
        return 'ETB';
      case 0x18:
        return 'CAN';
      case 0x19:
        return 'EM';
      case 0x1a:
        return 'SUB';
      case 0x1b:
        return 'ESC';
      case 0x1c:
        return 'FS';
      case 0x1d:
        return 'GS';
      case 0x1e:
        return 'RS';
      case 0x1f:
        return 'US';
      default:
        return '' + number;
    }
  }
}
