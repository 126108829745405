<ng-container #settings [ngTemplateOutlet]="settingtemp">
    <ng-template #settingtemp>
        <div class="form-group row mb-1">
                <div class="col-3">
                    <input type="text" class="form-control" [(ngModel)]="inputName" name="inputName"
                           placeholder="Setting Key(Name)" title="Setting Key(Name)">
                </div>
                <div class="col-7">
                    <div>
                        <input type="text" class="form-control" [(ngModel)]="inputValue" name="inputValue"
                               placeholder="Setting Value(Wert)" title="Setting Value(Wert)">
                    </div>
                </div>
                <div class="col-2 d-flex justify-content-center align-items-center">
                    <i
                            class="fa fa-fw fa-plus has-cursor-pointer"
                            title="Hinzufügen"
                            (click)="add_setting(inputName, inputValue)">
                    </i>
                </div>
        </div>
        <ng-container *ngFor="let setting of settingList.data; trackBy:trackByFn">
            <div class="d-flex justify-content-between align-items-center">
                <ng-container *ngIf="setting.type==='boolean'||setting.type === 'bool'">
                    <div class="col-form-label col-3 ng-star-inserted">
                        {{ setting.name }}
                        <i title="{{setting.description}}" class="icofont icofont-info-circle"></i>
                    </div>
                </ng-container>
                <div class="flex-fill ms-2 ml-2" style="display: flex; justify-content: center">

                    <ng-container *ngIf="setting.type === 'boolean'||setting.type === 'bool'">
                        <ng-container *ngIf="entity.setting_is_true(setting.name)">
                            <div class="d-flex justify-content-center ng-star-inserted align-items-center"
                                 style="flex-basis: 80%"><i
                                    class="fa fa-fw fa-check-square-o has-cursor-pointer"
                                    title="{{setting.name}} deaktivieren"
                                    (click)="edit_setting(setting.name, 'false')"></i>
                            </div>
                            <div class="d-flex justify-content-center ng-star-inserted align-items-center"
                                 style="flex-basis: 20%">
                                <i class="far fa-trash has-cursor-pointer"
                                   title="{{setting.name}} entfernen"
                                   (click)="remove_setting(setting.name)"></i>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!entity.setting_is_true(setting.name)">
                            <div class="d-flex justify-content-center ng-star-inserted align-items-center"
                                 style="flex-basis: 80%"><i
                                    class="fa fa-fw fa-square-o has-cursor-pointer"
                                    title="{{setting.name}} aktivieren"
                                    (click)="add_setting(setting.name, 'true')"></i>
                            </div>
                            <div class="d-flex justify-content-center ng-star-inserted align-items-center"
                                 style="flex-basis: 20%">
                                <ng-container *ngIf="setting_in_entity(setting.name)">

                                    <i class="far fa-trash has-cursor-pointer"
                                       title="{{setting.name}} entfernen"
                                       (click)="remove_setting(setting.name)"></i>

                                </ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="setting.type === 'string'">
                <ng-container *ngIf="setting_in_entity(setting.name)">
                    <me-text-field-icon-with-output-event
                            [label]="setting.name"
                            [(value)]="entitySettings[setting.name]"
                            (iconClick)="this.remove_setting(setting.name)"
                            [icon_title]="''+setting.name+' entfernen'"
                            [icon]="'far fa-trash has-cursor-pointer'"
                    ></me-text-field-icon-with-output-event>
                </ng-container>
                <ng-container *ngIf="!setting_in_entity(setting.name)">
                    <me-text-field [label]="setting.name" [(value)]="entitySettings[setting.name]"></me-text-field>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngFor="let usetting of entitySettings|keyvalue; trackBy:trackByFn">
            <ng-container *ngIf="!setting_in_list(usetting.key.toString())">
                <div class="d-flex justify-content-between align-items-center">
                    <ng-container *ngIf="settingIsBoolean(usetting.key.toString(),usetting.value.toString())">
                        <div class="col-form-label col-3 ng-star-inserted">
                            {{ usetting.key.toString() }}
                        </div>
                    </ng-container>
                    <div class="flex-fill ms-2 ml-2" style="display: flex; justify-content: center">

                        <ng-container *ngIf="settingIsBoolean(usetting.key.toString(),usetting.value.toString())">
                            <ng-container *ngIf="entity.setting_is_true(usetting.key.toString())">
                                <div class="d-flex justify-content-center ng-star-inserted align-items-center"
                                     style="flex-basis: 80%"><i
                                        class="fa fa-fw fa-check-square-o has-cursor-pointer"
                                        title="Einstellung deaktivieren"
                                        (click)="edit_setting(usetting.key.toString(), 'false')"></i>
                                </div>
                                <div class="d-flex justify-content-center ng-star-inserted align-items-center"
                                     style="flex-basis: 20%">
                                    <i class="far fa-trash has-cursor-pointer"
                                       title="{{usetting.key.toString()}} entfernen"
                                       (click)="remove_setting(usetting.key.toString())"></i>
                                </div>

                            </ng-container>
                            <ng-container *ngIf="!entity.setting_is_true(usetting.key.toString())">
                                <div class="d-flex justify-content-center ng-star-inserted align-items-center"
                                     style="flex-basis: 80%"><i
                                        class="fa fa-fw fa-square-o has-cursor-pointer"
                                        title="Einstellung aktivieren"
                                        (click)="add_setting(usetting.key.toString(), 'true')"></i>
                                </div>
                                <div class="d-flex justify-content-center ng-star-inserted align-items-center"
                                     style="flex-basis: 20%">
                                    <ng-container *ngIf="setting_in_entity(usetting.key.toString())">

                                        <i class="far fa-trash has-cursor-pointer"
                                           title="{{usetting.key.toString()}} entfernen"
                                           (click)="remove_setting(usetting.key.toString())"></i>

                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <ng-container *ngIf="settingIsBoolean(usetting.key.toString(),usetting.value.toString())===false">
                    <ng-container *ngIf="setting_in_entity(usetting.key.toString())">
                        <me-text-field-icon-with-output-event
                                [label]="usetting.key.toString()"
                                [(value)]="entitySettings[usetting.key.toString()]"
                                (iconClick)="this.remove_setting(usetting.key.toString())"
                                [icon_title]="''+usetting.key.toString()+' entfernen'"
                                [icon]="'far fa-trash has-cursor-pointer'"
                        ></me-text-field-icon-with-output-event>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="save()">
                <i class="icofont icofont-save"></i> Einstellungen speichern
            </button>
        </div>
    </ng-template>
</ng-container>