import { Component, Input, OnInit } from '@angular/core';
import { ICheckResult } from '../../common/icheck-result';
import { noop } from '../../../maennl-commons/tools';

@Component({
  selector: 'app-check-status-display',
  template: ` <div *ngIf="isValid()" title="{{ title }}">{{ getInfo() }}</div> `
})
export class CheckStatusDisplayComponent implements OnInit {
  @Input() public value: ICheckResult = null;
  @Input() public title = '';

  constructor() {
    noop();
  }

  ngOnInit() {
    noop();
  }

  isValid() {
    return this.value !== null && this.value !== undefined;
  }

  getInfo() {
    let ret = '';
    if (this.isValid()) {
      ret = this.value.info.trim();
      if (ret === '') {
        ret = 'Status: ' + this.value.code;
      }
    }
    return ret;
  }
}
