import { MEGenericEntityFields } from './megeneric-entity-field';
import { TMEGenericEntityAfterUpdateCallback } from './types';

export abstract class MEGenericEntity<I> {
  public getTransients() {
    return [];
  }

  public clear(fields: MEGenericEntityFields = null) {
    if (fields !== null) {
      fields.getFieldNames().forEach((fn) => {
        if (fn !== '_transients') {
          this[fn] = null;
        }
      });
    }
    for (const k of Object.keys(this)) {
      if (k !== '_transients') {
        this[k] = null;
      }
    }
  }

  public convertField(
    fieldname: string,
    value: any,
    fields: MEGenericEntityFields = null
  ): any {
    return fields === null || fields === undefined
      ? value
      : fields.convert(fieldname, value);
  }

  public updateFromResult(
    entity: I,
    fields: MEGenericEntityFields = null,
    afterUpdate: TMEGenericEntityAfterUpdateCallback<MEGenericEntity<I>> = null
  ) {
    this.clear(fields);
    if (entity === null || entity === undefined) {
      return;
    }
    for (const k of Object.keys(this)) {
      if (k !== '_transients') {
        this[k] =
          entity[k] !== undefined
            ? this.convertField(k, entity[k], fields)
            : null;
      }
    }
    fields.getFieldNames().forEach((fn) => {
      if (fn !== '_transients') {
        if (Object.keys(this).indexOf(fn) < 0) {
          this[fn] =
            entity[fn] !== undefined
              ? this.convertField(fn, entity[fn], fields)
              : null;
        }
      }
    });
    if (afterUpdate !== undefined && afterUpdate !== null) {
      afterUpdate(this);
    }

    return this;
  }

  public prepareFieldForJSON(key: string, level: number) {
    return this[key];
  }

  public prepareForJSON(level: number = 0) {
    const transients = this.getTransients();

    const objekt = {};

    Object.keys(this).forEach((key) => {
      if (transients === null || transients === undefined) {
        objekt[key] = this.prepareFieldForJSON(key, level);
      } else if (transients.indexOf(key) < 0) {
        objekt[key] = this.prepareFieldForJSON(key, level);
      }
    });
    return objekt;
  }

  public asJSON(): string {
    return JSON.stringify(this.prepareForJSON(0));
  }
}
