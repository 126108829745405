import {TNullableTerminalType, TTerminalType} from "./terminal-type.types";
import {TerminalType} from "./terminal-type.class";
import {MEGenericEntityFields} from "../../../maennl-commons";

export class TerminalTypeImpl {

    static fromResult(
        entity: TTerminalType,
        createifNull=false
    ): TNullableTerminalType{
        if(entity===null||entity===undefined){
            if(createifNull){
                return new TerminalType()
            }
            return null
        }

        const instance= new TerminalType();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongFields([
                    'id',
                ])
                .addTextFields([
                    'code',
                    'display_text',
                ])
        );
        return instance;
    }
}