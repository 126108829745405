import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {MERichError} from '../../maennl-commons/tools/merich-error';
import {MEDTDeviceRendererComponent} from '../../router/controls/medtdevice-renderer/medtdevice-renderer.component';
import {MitschnittgeraetList} from '../common/mitschnittgeraet-list';
import {MitschnittService} from '../services/mitschnitt.service';
import {noop} from '../../maennl-commons/tools';
import {MESubscriptionHelper} from '../../maennl-commons/services/mesubscription-helper';
import {BenutzerService} from '../../benutzer/services/benutzer.service';
import {MERowActionParams} from "../../maennl-commons";
import {IMitschnittgeraet} from "../common/imitschnittgeraet";
import {ToastrService} from "ngx-toastr";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MitschnittDeviceEditorComponent} from "../device-editor/device-editor.component";

@Component({
    selector: 'app-tab-mitschnitt-devices',
    templateUrl: './tab-mitschnitt-devices.component.html',
    styleUrls: ['./tab-mitschnitt-devices.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabMitschnittDevicesComponent implements OnInit, OnDestroy {
    __classname = 'TabMitschnittDevicesComponent';
    __instance = '';

    public deviceRenderer = MEDTDeviceRendererComponent;

    public devices: MitschnittgeraetList = new MitschnittgeraetList();
    private tabActive = false;

    constructor(
        public toastr: ToastrService,
        public modalService: NgbModal,
        public mitschnittService: MitschnittService,
        public cd: ChangeDetectorRef,
        public benutzerService: BenutzerService
    ) {
    }

    ngOnInit() {
        MESubscriptionHelper.add(
            this,
            this.devices.onUpdateRequired.subscribe((list: MitschnittgeraetList) => {
                this.mitschnittService
                    .listDevices(
                        list.size,
                        list.calcOffset(),
                        list.order,
                        list.simpleFilter
                    )
                    .subscribe(
                        (l) => {
                            list.populateFromListResult(l);
                            this.cd.markForCheck();
                        },
                        () => {
                            MERichError.throw(
                                'Fehler beim Datenabruf',
                                'Die Liste der verfügbaren Mitschnittgeräte konnte nicht geladen werden'
                            );
                        }
                    );
            }, noop)
        );

        this.devices.start();
    }

    tabActivated() {
        this.tabActive = true;
    }

    tabDeactivated() {
        this.tabActive = false;
    }

    ngOnDestroy(): void {
        MESubscriptionHelper.release(this);
        this.devices.release();
    }

    public editDevice($event: MERowActionParams<IMitschnittgeraet>) {
        this.mitschnittService.loadDevice($event.row.data.id).subscribe(
            (dv) => {
                MitschnittDeviceEditorComponent.open(
                    this.modalService,
                    MitschnittDeviceEditorComponent,
                    dv
                ).then(() => {
                    this.devices.reload();
                }, noop);
            },
            () => {
                MERichError.throw(
                    'Fehler beim Datenabruf',
                    'Das gewählte Gerät konnte nicht vom Server abgerufen werden'
                );
            }
        );
    }
}
