<me-data-table
        *ngIf="liste!==null && liste!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="liste.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        #tabelle
>
    <me-data-table-column [label]="'gültig ab'"
                          [field]="'datum_gueltig'" type="date"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
                          [title]="'Zeitpunkt, ab dem der Preis gelten soll'"
    ></me-data-table-column>
    <me-data-table-column [label]="'importiert'"
                          [field]="'datum_import'" type="date"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
                          [title]="'Zeitpunkt, zu dem der Preis importiert wurde'"
    ></me-data-table-column>
    <me-data-table-column [label]="'gesendet'"
                          [field]="'datum_sendung'" type="date"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
                          [title]="'Zeitpunkt, zu dem der Preis vom Automat abgerufen wurde'"
    ></me-data-table-column>
    <me-data-table-column [label]="'bestätigt'"
                          [field]="'datum_empfang'" type="date"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
                          [title]="'Zeitpunkt, zu dem der Preis vom Automat bestätigt wurde'"
    ></me-data-table-column>
    <me-data-table-column [label]="'Transfer'"
                          [field]="'datum_transfer'"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
                          [title]="'Zeitpunkt, zu dem der Preis aktiv zum Automat gesendet wurde'"
                          [renderer]="transferR"
    ></me-data-table-column>
    <me-data-table-column [label]="'Automat'"
                          [field]="'nebenstelle_name'"
                          [title]="'Tankautomat'"
                          [renderer]="nebenstelleR"
    ></me-data-table-column>
    <me-data-table-column [label]="'Preise'"
                          [field]="'produkt_preis_01'"
                          [title]="'an den Automat gesendete Preise'"
                          [renderer]="preiseR"></me-data-table-column>

    <me-data-table-table-extra-header>
        <me-pagination
                [total]="liste.total"
                [page]="liste.page"
                [perpage]="liste.size"
                (pageChange)="liste.setPage($event)"
                (pageCountChange)="liste.processPageCountChangedEvent($event)"
                (queryChange)="liste.processQueryChangedEvent($event)"
                settingsid="clearing-preise-preissendungen"
                [settingsService]="benutzerService"
                [showSearch]="true"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
<div class="alert alert-info">
    Preissendungen an Slave-Automaten (also Systeme wie Kitzingen) erscheinen in der Spalte Transfer als Detail-Position. Grau hinterlegt ist dabei das Master-Terminal
</div>
