import { MEGenericFilter } from '../../maennl-commons/filters';
import { Device } from './device';
import { IDevice } from './idevice';
import { IDeviceType } from './idevice-type';
import { IModem } from './imodem';
import { ISim } from './isim';

export class SimpleDeviceFilter
  extends MEGenericFilter<Device>
  implements IDevice
{
  modem: IModem = null;
  notes: string = null;
  dateCreated: any = null;
  lastUpdated: any = null;
  lastSeen: any = null;
  machine_id: string = null;
  diskCapacity: number = null;
  diskFree: number = null;
  diskAvailable: number = null;
  load1: number = null;
  load5: number = null;
  load15: number = null;
  processes: string = null;
  remoteAccessPort: number = null;
  online: boolean = null;
  tunnelEndpoint: string = null;
  id: number = null;
  bezeichnung: string = null;
  deviceType: IDeviceType = null;
  sim: ISim = null;
  password: string = null;
  username: string = null;
  seriennummer: string = null;
  boxnummer: number = null;
}
