import { MEConverterTool } from '../tools';
import { MEGenericEntityConverterParams } from './megeneric-entity-converter-params';
import { MEGenericEntityInListParams } from './megeneric-entity-in-list-params';

export const MEGEF_STRING = 'string';
export const MEGEF_TEXT = 'text';
export const MEGEF_STRINGS = 'strings';
export const MEGEF_TEXT_UPPERCASE = 'string_uc';
export const MEGEF_STRINGS_UPPERCASE = 'strings_uc';
export const MEGEF_INTEGER = 'integer';
export const MEGEF_DECIMAL = 'decimal';
export const MEGEF_DECIMALS = 'decimals';
export const MEGEF_FLOAT = 'float';
export const MEGEF_DOUBLE = 'double';
export const MEGEF_LONG = 'long';
export const MEGEF_NUMBER = 'number';
export const MEGEF_DATE = 'date';
export const MEGEF_BOOLEAN = 'boolean';
export const MEGEF_IN_LIST = 'inlist';
export const MEGEF_OBJECT = 'object';
export const MEGEF_CONVERTER = 'converter';

export class MEGenericEntityFields {
  protected fields: any = {};
  protected params: any = {};
  protected fieldnames: any = {};

  public getFieldNames(): string[] {
    const r: string[] = [];

    Object.keys(this.fieldnames).forEach((k) => {
      r.push(this.fieldnames[k]);
    });

    return r;
  }

  addField(
    fieldname: string,
    type: string,
    params: any = undefined
  ): MEGenericEntityFields {
    this.fieldnames[fieldname.toUpperCase()] = fieldname;
    this.fields[fieldname.toLowerCase()] = type.toLowerCase();
    if (params !== undefined && params != null) {
      this.params[fieldname.toLowerCase()] = params;
    }
    return this;
  }

  addLongField(fieldname: string, params: any = undefined) {
    return this.addField(fieldname, MEGEF_LONG, params);
  }

  addDecimalField(fieldname: string, params: any = undefined) {
    return this.addField(fieldname, MEGEF_DECIMAL, params);
  }

  addDecimalsField(fieldname: string, params: any = undefined) {
    return this.addField(fieldname, MEGEF_DECIMALS, params);
  }

  addTextField(
    fieldname: string,
    isUppercase = false,
    params: any = undefined
  ) {
    return this.addField(
      fieldname,
      isUppercase ? MEGEF_TEXT_UPPERCASE : MEGEF_TEXT,
      params
    );
  }

  addStringField(
    fieldname: string,
    isUppercase = false,
    params: any = undefined
  ) {
    return this.addTextField(fieldname, isUppercase, params);
  }

  addStringsField(
    fieldname: string,
    isUppercase = false,
    params: any = undefined
  ) {
    return this.addField(
      fieldname,
      isUppercase ? MEGEF_STRINGS_UPPERCASE : MEGEF_STRINGS,
      params
    );
  }

  addDateField(fieldname: string, params: any = undefined) {
    return this.addField(fieldname, MEGEF_DATE, params);
  }

  addBooleanField(fieldname: string, params: any = undefined) {
    return this.addField(fieldname, MEGEF_BOOLEAN, params);
  }

  addObjectField(fieldname: string, params: any = undefined) {
    return this.addField(fieldname, MEGEF_OBJECT, params);
  }

  addConverterField(
    fieldname: string,
    params: MEGenericEntityConverterParams<any>
  ) {
    return this.addField(fieldname, MEGEF_CONVERTER, params);
  }

  getType(fieldname: string) {
    if (fieldname === null || fieldname === undefined) {
      return null;
    }
    if (Object.keys(this.fields).indexOf(fieldname.toLowerCase()) < 0) {
      return null;
    }
    return this.fields[fieldname.toLowerCase()];
  }

  convertByType(value: any, type: string, params: any = null): any {
    if (type === null || type === undefined || type.trim() === '') {
      return value;
    }

    type = type.toLowerCase();

    if (
      type === MEGEF_STRING ||
      type === MEGEF_TEXT ||
      type === MEGEF_TEXT_UPPERCASE
    ) {
      return type === MEGEF_TEXT_UPPERCASE
        ? ('' + value).trim().toUpperCase()
        : ('' + value).trim();
    }
    if (type === MEGEF_STRINGS || type === MEGEF_STRINGS_UPPERCASE) {
      const h = [];
      if (Array.isArray(value)) {
        value.forEach((x) => {
          h.push(
            type === MEGEF_STRINGS_UPPERCASE
              ? ('' + x).trim().toUpperCase()
              : ('' + x).trim()
          );
        });
      } else {
        h.push(
          type === MEGEF_STRINGS_UPPERCASE
            ? ('' + value).trim().toUpperCase()
            : ('' + value).trim()
        );
      }
      return h;
    }
    if (type === MEGEF_DECIMALS) {
      const h = [];
      if (Array.isArray(value)) {
        value.forEach((x) => {
          h.push(parseFloat('' + x));
        });
      } else {
        h.push(parseFloat('' + value));
      }
      return h;
    }
    if (type === MEGEF_INTEGER || type === MEGEF_LONG) {
      return parseInt('' + value, 10);
    }
    if (
      type === MEGEF_DECIMAL ||
      type === MEGEF_FLOAT ||
      type === MEGEF_NUMBER ||
      type === MEGEF_DOUBLE
    ) {
      return parseFloat('' + value);
    }
    if (type === MEGEF_DATE) {
      return MEConverterTool.toDate(value);
    }
    if (type === MEGEF_BOOLEAN) {
      return MEConverterTool.toBoolean(value);
    }
    if (type === MEGEF_IN_LIST) {
      const p: MEGenericEntityInListParams = params;
      if (p != null) {
        const item = this.convertByType(value, p.type);
        let i = p.defaultValue;
        if (Array.isArray(p.list)) {
          p.list.forEach((it) => {
            if (
              p.caseInsensitive &&
              (p.type.toLowerCase() === MEGEF_STRING ||
                p.type.toLowerCase() === MEGEF_TEXT)
            ) {
              if (('' + it).toLowerCase() === ('' + item).toLowerCase()) {
                i = it;
              }
            } else {
              if (it === item) {
                i = it;
              }
            }
          });
        }
        return i;
      }
    }
    if (type === MEGEF_CONVERTER) {
      const p: MEGenericEntityConverterParams<any> = params;
      if (p != null) {
        return p.converter(value);
      }
    }
    return value;
  }

  convert(fieldname: string, value: any): any {
    if (fieldname === null || fieldname === undefined) {
      return value;
    }
    if (Object.keys(this.fields).indexOf(fieldname.toLowerCase()) < 0) {
      return value;
    }
    const type = this.fields[fieldname.toLowerCase()];
    const params =
      this.params === null
        ? null
        : Object.keys(this.params).indexOf(fieldname) >= 0
        ? this.params[fieldname]
        : null;
    return this.convertByType(value, type, params);
  }

  addLongFields(fnames: string[]): MEGenericEntityFields {
    fnames.forEach((fname) => {
      this.addLongField(fname);
    });
    return this;
  }

  addDecimalFields(fnames: string[]): MEGenericEntityFields {
    fnames.forEach((fname) => {
      this.addDecimalField(fname);
    });
    return this;
  }

  addTextFields(fnames: string[]): MEGenericEntityFields {
    fnames.forEach((fname) => {
      this.addTextField(fname);
    });
    return this;
  }

  addBooleanFields(fnames: string[]): MEGenericEntityFields {
    fnames.forEach((fname) => {
      this.addBooleanField(fname);
    });
    return this;
  }

  addDateFields(fnames: string[]): MEGenericEntityFields {
    fnames.forEach((fname) => {
      this.addDateField(fname);
    });
    return this;
  }
}
