import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IContactListEntry } from './icontact-list-entry';

export class ContactListEntry
  extends MEGenericEntity<IContactListEntry>
  implements IContactListEntry
{
  public contactId: number = null;
  public displayText: string = null;
  public firma: string = null;
  public info: string = null;
  public kundennummer: string = null;
  public nachname: string = null;
  public nummer: string = null;
  public nummerId: number = null;
  public vorname: string = null;
  public isIntern: boolean = null;
  public sortRang = 100;

  static fromResult(
    entity: IContactListEntry,
    createIfNull: boolean = false
  ): ContactListEntry {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new ContactListEntry();
      }
      return null;
    }

    const instance = new ContactListEntry();
    return instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('contactId')
        .addLongField('nummerId')
        .addTextField('displayText')
        .addTextField('firma')
        .addTextField('info')
        .addTextField('kundennummer')
        .addTextField('nachname')
        .addBooleanField('isIntern')
        .addTextField('nummer')
        .addTextField('vorname')
        .addLongField('sortRang')
    );
  }

  constructor() {
    super();
  }
}
