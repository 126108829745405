<me-data-table
        [rowActionsAsButtons]="false"
        [tableActionsAsButtons]="true"
        [data]="peilungen.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
>
    <me-data-table-column [label]="'Sonde'"
                          [field]="'sonde'"
                          [renderer]="sondeRenderer"

    ></me-data-table-column>
    <me-data-table-column [label]="'Zeitpunkt'" field="zeitpunkt" type="date" [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>
    <me-data-table-column label="Stand 1" field="cstand1" type="decimal" [cfg]="{formatter:standFormatter}"></me-data-table-column>
    <me-data-table-column label="Stand 2" field="cstand2" type="decimal" [cfg]="{formatter:standFormatter}"></me-data-table-column>
    <me-data-table-column label="Temperatur" field="temperatur" type="decimal" [cfg]="{fdigits:0}"></me-data-table-column>


    <me-data-table-table-extra-header>
        <me-pagination
                [total]="peilungen.total"
                [page]="peilungen.page"
                [perpage]="peilungen.size"
                (pageChange)="peilungen.setPage($event)"
                (pageCountChange)="peilungen.processPageCountChangedEvent($event)"
                settingsid="sonden-peilungen"
                [settingsService]="benutzerService"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>

