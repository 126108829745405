import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { Device } from './device';
import { IDevice } from './idevice';
import { SimpleDeviceFilter } from './simple-device-filter';

export class DeviceList extends MERemoteList<Device> {
  __classname = 'DeviceList';
  __instance = '';

  static fromListResult(res: IMEListResult<IDevice>): DeviceList {
    const newList = new DeviceList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleDeviceFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'bezeichnung',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }
}
