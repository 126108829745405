import { MEDataTableComponent } from '../data-table.component';
import { MEDataTableRow } from '../rows/medata-table-row';

export class MEDataTableRowEditCancelled {
  public dataTable: MEDataTableComponent;
  public row: MEDataTableRow<any>;

  constructor(dataTable: MEDataTableComponent, row: MEDataTableRow<any>) {
    this.dataTable = dataTable;
    this.row = row;
  }
}
