import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  MESelectComponent,
  TMESelectItemFormatterCallback,
  TMESelectItemMatcherCallback,
  TMESelectItemTextCallback
} from '../../../maennl-commons/forms/components/me-select';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { CSDServerClient } from '../../../meconnect/csdserver/commons/csdserver-client';
import { Sim } from '../../common/sim';
import { SimList } from '../../common/sim-list';
import { RouterService } from '../../services/router.service';
import { MEHighlitePipe, METool } from 'src/app/maennl-commons';

@Component({
  selector: 'app-sim-picker',
  template: `
    <me-select
      [asMatrix]="false"
      [idField]="'id'"
      [items]="sims.data"
      [placeholder]="placeholder"
      [small]="small"
      [value]="value"
      (onSearch)="doSearch($event)"
      (onSelect)="doSelect($event)"
      [nullable]="nullable"
      [onFormat]="itemFormat"
      [onMatch]="itemMatcher"
      [onGetText]="itemText"
      [disabled]="disabled"
    ></me-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimPickerComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'SimPickerComponent';
  __instance = '';

  @Input() disabled = false;

  @Input() nullable = true;
  @Input() placeholder = 'SIM auswählen';
  @Input() small = false;

  public _value: Sim = null;

  @Output() valueChange = new EventEmitter<Sim>();

  public sims = new SimList();

  @ViewChild(MESelectComponent, { static: false }) meSelect: MESelectComponent;

  ngAfterViewInit(): void {
    if (this.meSelect !== null && this.meSelect !== undefined) {
      this.meSelect.onFormat = this.itemFormat;
      this.meSelect.onGetText = this.itemText;
      this.meSelect.onMatch = this.itemMatcher;
      this.sims.start();
    }
  }

  @Input()
  set value(v: Sim) {
    if (this._value !== v) {
      this._value = v;
      if (this.meSelect !== null && this.meSelect !== undefined) {
        this.meSelect.value = v;
        this.meSelect.markForCheck();
      }
      this.valueChange.emit(v);
      this.cd.markForCheck();
    }
  }

  get value(): Sim {
    return this._value;
  }

  constructor(
    public routerService: RouterService,
    public cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.sims.onUpdateRequired.subscribe((list: SimList) => {
        this.routerService
          .listSims(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.getQuery()
          )
          .subscribe((l) => {
            list.populateFromListResult(l);
            if (!METool.isNullOrUndefined(this.meSelect)) {
              this.meSelect.markForCheck();
            }
          }, undefined);
      }, undefined)
    );
  }

  doSearch(value) {
    this.sims.searchString = value;
    this.sims.start();
  }

  doSelect(value: Sim) {
    this._value = value;
    this.valueChange.emit(value);
    this.cd.markForCheck();
  }

  public itemFormat: TMESelectItemFormatterCallback<Sim> = (
    item: Sim,
    search,
    forList: boolean
  ) => {
    if (item === null || item === undefined) {
      return '';
    }

    return (
      MEHighlitePipe.transformString('' + item.label, search) +
      (METool.isEmpty(item.msidsn)
        ? ''
        : '<span class="badge bg-secondary text-dark">' +
          MEHighlitePipe.transformString('' + item.msidsn, search) +
          '</span>') +
      (METool.isEmpty(item.imsi)
        ? ''
        : '<span class="badge bg-secondary text-dark">' +
          MEHighlitePipe.transformString('' + item.imsi, search) +
          '</span>') +
      (METool.isEmpty(item.ip)
        ? ''
        : '<span class="badge bg-secondary text-dark">' +
          MEHighlitePipe.transformString('' + item.ip, search) +
          '</span>')
    );
  };

  public itemText: TMESelectItemTextCallback<Sim> = (item: Sim) => {
    if (item === null || item === undefined) {
      return '';
    }
    return (
      item.label +
      (METool.isEmpty(item.msidsn) ? '' : ' [' + item.msidsn + ']') +
      (METool.isEmpty(item.imsi) ? '' : ' [' + item.imsi + ']') +
      (METool.isEmpty(item.ip) ? '' : ' [' + item.ip + ']')
    );
  };

  public itemMatcher: TMESelectItemMatcherCallback<Sim> = (
    item: Sim,
    search
  ) => {
    if (!search) {
      return true;
    }
    if (search.trim() === '') {
      return true;
    }
    if (!item) {
      return false;
    }
    const x =
      item.label +
      ' ' +
      item.id +
      ' ' +
      item.msidsn +
      ' ' +
      item.imsi +
      ' ' +
      item.ip;

    return x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
  };

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.sims.release();
    super.ngOnDestroy();
  }
}
