import { Component, Injector, OnInit } from '@angular/core';
import {
  MEDataTableColumnComponent,
  MEDataTableLocalizedCellRenderer,
  MEDataTableRow
} from '../../../maennl-commons';
import { IPreisSendung } from '../commons/ipreis-sendung';
import { IMwstKorrektur } from '../commons/imwst-korrektur';

@Component({
  selector: 'app-nebenstelle-renderer',
  templateUrl: './nebenstelle-renderer.component.html',
  styleUrls: ['./nebenstelle-renderer.component.scss']
})
export class NebenstelleRendererComponent extends MEDataTableLocalizedCellRenderer {
  public cell: any = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<IPreisSendung | IMwstKorrektur> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }
}
