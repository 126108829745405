import { MEGenericEntity } from '../../../maennl-commons';
import {
  TNullableBoolean,
  TNullableNumber,
  TNullableString,
} from '../../../maennl-commons/tools/types';
import { TNullableCheckPingList } from './check-ping.types';
import { TGruppe, TNullableGruppeList } from './gruppe.types';
import { INetworkAddress } from './network-address.interface';

export class NetworkAddress
  extends MEGenericEntity<INetworkAddress>
  implements INetworkAddress
{
  address: TNullableString = null;
  addr_family: TNullableNumber = null;
  checks_num_ok: TNullableNumber = null;
  checks_num_total: TNullableNumber = null;
  checks_success_ratio: TNullableNumber = null;
  checks_successful: TNullableBoolean = null;
  enabled: TNullableBoolean = null;
  id: TNullableNumber = null;
  info: TNullableString = null;
  is_host: TNullableBoolean = null;
  is_m2m: TNullableBoolean = null;
  is_maennl_apn: TNullableBoolean = null;
  is_maennlex_apn: TNullableBoolean = null;
  mask_len: TNullableNumber = null;
  ignore_check_problems: TNullableBoolean = null;

  _groups: TNullableGruppeList = null;
  _checks: TNullableCheckPingList = null;

  constructor() {
    super();
  }

  is_in_group(id: TNullableNumber): boolean {
    if (
      this._groups === null ||
      this._groups === undefined ||
      id === null ||
      id === undefined
    ) {
      return false;
    }
    return this._groups.some(i => i !== null && i !== undefined && i.id === id);
  }

  add_group(grp: TGruppe): void {
    if (
      this._groups === null ||
      this._groups === undefined ||
      !Array.isArray(this._groups)
    ) {
      this._groups = [];
    }

    if (
      !this._groups.some(i => i !== null && i !== undefined && i.id === grp.id)
    ) {
      this._groups.push(grp);
    }
  }

  remove_group(grp: TGruppe): void {
    if (
      this._groups === null ||
      this._groups === undefined ||
      !Array.isArray(this._groups)
    ) {
      this._groups = [];
    }

    this._groups = this._groups.filter(
      i => i !== null && i !== undefined && i.id !== grp.id
    );
  }
}
