import { IMEListResult, MERemoteList } from '../../../maennl-commons';
import { IPreistransfer } from './ipreistransfer';
import { Preistransfer } from './preistransfer';
import { SimplePreistransferFilter } from './simple-preistransfer-filter';

export class PreistransferList extends MERemoteList<Preistransfer> {
  __classname = 'PreisSendungList';
  __instance = '';

  static fromListResult(res: IMEListResult<IPreistransfer>): PreistransferList {
    const newList = new PreistransferList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimplePreistransferFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'zeitpunkt',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return Preistransfer.fromResult(entity);
  }
}
