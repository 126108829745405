import {MEGenericModalComponent, MEPubSubService} from "../../../../maennl-commons";
import {Terminal} from "../../commons/terminal.class";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {TerminalImpl} from "../../commons/terminal.impl";
import {TerminalService} from "../../commons/terminal.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-tab-terminal-editor',
    templateUrl: './tab-terminal-editor.component.html',
    styleUrls: ['./tab-terminal-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TabTerminalEditorComponent
    extends MEGenericModalComponent<Terminal>
    implements OnInit,OnDestroy
{
    entity: Terminal=null;

    public initEntity(src: Terminal): Terminal {
        return TerminalImpl.fromResult(src,true);
    }

    constructor(
        public activeModal: NgbActiveModal,
        public pubsub: MEPubSubService,
        public service: TerminalService,
        public toastr: ToastrService,
        public cd: ChangeDetectorRef
    ) {
        super(activeModal, pubsub);
    }

    ngOnInit(){}

    save() {
        this.service.store(this.entity).subscribe({
            next:(r) =>{
                if(r.success) {
                    this.ok();
                } else {
                    this.toastr.error('Fehler beim Speichern der Daten');
                }
            },
            error:() =>{
                this.toastr.error('Fehler beim Speichern der Daten');
            }
        });
    }
}