<ng-template #ahIcon>
    <fa-icon [icon]="['fas', 'user-tie']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="webfuel Anwendungen"
></me-area-heading>

<me-tab-card #rootTabCard>
    <me-pane id="tab-anwendung"
             title="Anwendungen"
             (activated)="activate(applicationsTab)"
             >
        <app-tab-applications #applicationsTab></app-tab-applications>
    </me-pane>
</me-tab-card>