import {
    MEGenericEntity,
    MEGenericEntityFields,
} from '../../maennl-commons/megeneric-entity';
import {METool} from '../../maennl-commons/tools';
import {ICDRCall} from './icdrcall';

export class CDRCall extends MEGenericEntity<ICDRCall> implements ICDRCall {
    private _internal_cids: string[] = [];
    private _external_cids: string[] = [];

    private _all_internal_cids: string[] = [];
    private _all_external_cids: string[] = [];
    private _call_path: string[] = [];
    private _full_call_path: string[] = [];

    get all_internal_cids(): string[] {
        return this._all_internal_cids;
    }

    set all_internal_cids(value: string[]) {
        this._all_internal_cids = value;
    }

    get all_external_cids(): string[] {
        return this._all_external_cids;
    }

    set all_external_cids(value: string[]) {
        this._all_external_cids = value;
    }

    get call_path(): string[] {
        return this._call_path;
    }

    set call_path(value: string[]) {
        this._call_path = value;
    }

    get full_call_path(): string[] {
        return this._full_call_path;
    }

    set full_call_path(value: string[]) {
        this._full_call_path = value;
    }

    static fromResult(entity: ICDRCall, createIfNull: boolean = false): CDRCall {
        if (entity === null || entity === undefined) {
            if (createIfNull) {
                return new CDRCall();
            }
            return null;
        }

        const instance = new CDRCall();
        return instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongField('id')
                .addTextField('call_id')
                .addLongField('call_cel_id')
                .addDateField('call_start')
                .addDateField('call_end')
                .addLongField('call_duration')
                .addTextField('call_type')
                .addTextField('origin_cid')
                .addTextField('origin_name')
                .addTextField('destination')
                .addTextField('main_channel')
                .addTextField('last_internal_cid')
                .addTextField('last_external_cid')
                .addBooleanField('failed_call')
                .addTextField('destination_name')
                .addLongField('einheiten')
                .addTextField('internal_cids_text')
                .addTextField('external_cids_text')
                .addTextField('first_internal_cid')
                .addTextField('all_internal_cids_text')
                .addTextField('all_external_cids_text')
                .addTextField('call_path_text')
                .addTextField('full_call_path_text')
                .addTextField('call_type'),
            e => {
                let x = e as CDRCall;
                x._internal_cids = JSON.parse(x.internal_cids_text);
                x._external_cids = JSON.parse(x.external_cids_text);
                x._all_internal_cids = JSON.parse(x.all_internal_cids_text);
                x._all_external_cids = JSON.parse(x.all_external_cids_text);
                x._call_path = JSON.parse(x.call_path_text);
                x._full_call_path = JSON.parse(x.full_call_path_text);
            }
        );
    }

    constructor() {
        super();
    }

    id: number = null;
    call_id: string = null;
    call_cel_id: number = null;
    call_start: Date = null;
    call_end: Date = null;
    call_duration: number = null;
    call_direction: string = null;
    call_type: string = null;
    origin_cid: string = null;
    origin_name: string = null;
    destination: string = null;
    main_channel: string = null;
    last_internal_cid: string = null;
    last_external_cid: string = null;
    failed_call: boolean = null;
    destination_name: string = null;
    einheiten: number = null;
    internal_cids_text: string = null;
    external_cids_text: string = null;
    first_internal_cid: string = null;
    all_internal_cids_text: string = null;
    all_external_cids_text: string = null;
    call_path_text: string = null;
    full_call_path_text: string = null;

    get erfolgreich() {
        return !this.failed_call;
    }

    get internal_cids(): string[] {
        return this._internal_cids;
    }

    set internal_cids(value: string[]) {
        if (Array.isArray(value)) {
            this._internal_cids = value;
        } else {
            this._internal_cids = [];
        }
    }

    get external_cids(): string[] {
        return this._external_cids;
    }

    set external_cids(value: string[]) {
        if (Array.isArray(value)) {
            this._external_cids = value;
        } else {
            this._external_cids = [];
        }
    }

    get use_path() {
        if (this.full_call_path.length - this.call_path.length < 3) {
            return this.full_call_path;
        }
        return this.call_path;
    }
}
