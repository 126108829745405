import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IModem } from './imodem';

export class Modem extends MEGenericEntity<IModem> implements IModem {
  imei = '';
  manufacturer = '';
  model = '';
  revision = '';

  static fromResult(entity: IModem, createIfNull: boolean = false): Modem {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Modem();
      }
      return null;
    }

    const instance = new Modem();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addTextField('imei')
        .addTextField('manufacturer')
        .addTextField('model')
        .addTextField('revision')
    );
    return instance;
  }

  constructor() {
    super();
  }
}
