import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {MEAddOnClickEvent} from "../../../../maennl-commons";
import {WireguardInterface} from "../../commons/wireguard-interface.class";
import {Md5} from "ts-md5";
import {TNullableNumber} from "../../../../maennl-commons/tools/types";
import {WireguardInterfaceService} from "../../commons/wireguard-interface.service";
import {WireguardInterfaceImpl} from "../../commons/wireguard-interface.impl";

@Component({
    selector:'app-wireguard-interface-field',
    styles: [
        `
      :host .addon button:first-child {
        border-left: none;
        padding: 0 5px;
      }
    `
    ],
    template: `
    <me-generic-field
      [label]="label"
      [addonicon]="addonicon"
      [addonlabel]="addonlabel"
      [labelwidth]="labelwidth"
      [disabled]="disabled"
      [enableaddon]="enableaddon"
      [tabindex]="tabindex"
      [small]="small"
      (addonClick)="addOnClicked($event)"
    >
      <app-wireguard-interface-picker
        [tabindex]="tabindex"
        [id]="id"
        [small]="small"
        [value]="value"
        [value_id]="value_id"
        (valueChange)="setValue($event)"
        [disabled]="disabled"
        [nullable]="nullable"
      ></app-wireguard-interface-picker>
    </me-generic-field>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WireguardInterfaceFieldComponent implements OnInit {
    private _id='';

    @Input() label = 'Interface auswählen';
    @Input() addonicon:any=[];
    @Input() addonlabel:any=[];
    @Input() labelwidth=3;
    @Input() enableaddon=false;
    @Input() disabled=false;
    @Input() tabindex:number=null;
    @Input() small=false;
    @Input() nullable=false;

    @Output() addonClick=new EventEmitter<MEAddOnClickEvent>();
    @Output() valueChange=new EventEmitter<WireguardInterface>();
    @Output() value_idChange= new EventEmitter<TNullableNumber>();

    private _value:WireguardInterface=null;

    @Input()
    set value_id(v:TNullableNumber) {
        if(v===null||v===undefined||isNaN(v)){
            this.value=null;
            return;
        }
        if (this.value_id===v) {
            return
        }
        this.service.get(v).subscribe({
            next: (v)=>{
                this.value=WireguardInterfaceImpl.fromResult(v);
            }, error: (e)=>{
                this.value=null;
            }
        })
    }

    get value_id():TNullableNumber{
        if (this.value===null||this.value===undefined){
            return null;
        }
        return this.value.id;
    }

    public get value():WireguardInterface{
        return this._value;
    }

    @Input()
    public set value(value:WireguardInterface) {
        this.setValue(value);
        this.cd.markForCheck();
    }

    setValue(value:WireguardInterface) {
        if (this._value!==value) {
            this._value=value;
            if(!this.disabled) {
                this.valueChange.emit(this._value);
                this.value_idChange.emit(this.value_id);
            }
            this.cd.markForCheck();
        }
    }

    public get id():string {
        if(this._id==='') {
            this._id='tf'+Md5.hashStr('wireguardinterface-'+this.label+Math.random()+'-'+Math.random());
        }
        return this._id;
    }

    @Input()
    public set id(value:string) {
        this._id=value;
    }

    public addOnClicked($event:MEAddOnClickEvent) {
        this.addonClick.emit($event);
    }

    constructor(public cd: ChangeDetectorRef, public service: WireguardInterfaceService) {}

    public ngOnInit() {}
}