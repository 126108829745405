import { MEGenericEntity } from '../../maennl-commons/megeneric-entity';
import { MEGenericEntityFields } from '../../maennl-commons/megeneric-entity';
import { IDeviceType } from './idevice-type';

export class DeviceType
  extends MEGenericEntity<IDeviceType>
  implements IDeviceType
{
  code = '';
  id = 0;
  label = '';

  static fromResult(
    entity: IDeviceType,
    createIfNull: boolean = false
  ): DeviceType {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new DeviceType();
      }
      return null;
    }

    const instance: DeviceType = new DeviceType();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('label')
        .addTextField('code')
    );
    return instance;
  }

  constructor() {
    super();
  }
}
