import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  MEGenericModalComponent,
  MEPubSubService
} from '../../../maennl-commons';
import { BlocklistV4 } from '../commons/blocklist-v4.class';
import { BlocklistV4Impl } from '../commons/blocklist-v4.impl';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlocklistV4Service } from '../commons/blocklist-v4.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-blocklist-editor',
  templateUrl: './blocklist-editor.component.html',
  styleUrls: ['./blocklist-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlocklistEditorComponent
  extends MEGenericModalComponent<BlocklistV4>
  implements OnInit, OnDestroy
{
  entity: BlocklistV4 = null;

  public initEntity(src: BlocklistV4): BlocklistV4 {
    return BlocklistV4Impl.fromResult(src, true);
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public service: BlocklistV4Service,
    public toastr: ToastrService,
    public cd: ChangeDetectorRef
  ) {
    super(activeModal, pubsub);
  }

  ngOnInit(): void {}

  save() {
    this.service.store(this.entity).subscribe({
      next: (r) => {
        if (r.success) {
          this.ok();
        } else {
          this.toastr.error('Fehler beim Speichern der Daten');
        }
      },
      error: () => {
        this.toastr.error('Fehler beim Speichern der Daten');
      }
    });
  }
}
