import {TNullableWireguardInterface, TWireguardInterface} from "./wireguard-interface.types";
import {WireguardInterface} from "./wireguard-interface.class";
import {MEGenericEntityConverterParams, MEGenericEntityFields} from "../../../maennl-commons";
import {TNullableWireguardClientList} from "./wireguard-client.types";
import {IWireguardClient} from "./wireguard-client.interface";
import {WireguardClientImpl} from "./wireguard-client.impl";

export class WireguardInterfaceImpl{
    static fromResult(
        entity: TWireguardInterface,
        createifNull = false
    ): TNullableWireguardInterface {
        if(entity===null||entity===undefined){
            if(createifNull){
                return new WireguardInterface();
            }
            return null
        }

        const instance= new WireguardInterface();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongFields([
                    'id',
                    'port',
                    'peers',
                    ]
                )
                .addTextFields([
                    'display_name',
                    'public_key',
                    'private_key',
                    'info',
                ])
                .addConverterField(
                    '_clients',
                    new MEGenericEntityConverterParams<TNullableWireguardClientList>(
                        (value: TNullableWireguardClientList) =>{
                            if(
                                value===null||
                                value===undefined||
                                !Array.isArray(value)
                            ){
                                return [];
                            }
                            return (value as IWireguardClient[])
                                .map((v:IWireguardClient) =>{
                                    return WireguardClientImpl.fromResult(v,false);
                                })
                                .filter(v=>v!==null&&v!==undefined);
                        }
                    )
                ),
            e=>{
                if(
                    (e as WireguardInterface)._clients===null||
                    (e as WireguardInterface)._clients===undefined
                ) {
                    (<WireguardInterface>e)._clients=[];
                }
            }
        );
        return instance;
    }
}