import {
    MEConfirmDialogComponent, MEGenericModalComponent,
    MELocalizedComponent,
    MERowActionParams,
    MESubscriptionHelper, noop
} from "../../../maennl-commons";
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {TerminalList} from "../commons/terminal.list";
import {TerminalService} from "../commons/terminal.service";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {Terminal} from "../commons/terminal.class";
import {TabTerminalEditorComponent} from "./tab-terminal-editor/tab-terminal-editor.component";

@Component({
    selector: 'app-tab-terminal',
    templateUrl: './tab-terminal.component.html',
    styleUrls: ['./tab-terminal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabTerminalComponent
    extends MELocalizedComponent
    implements OnInit,OnDestroy,AfterViewInit{
    @Input() id='';
    terminalListe=new TerminalList();

    private _tabActive = false;

    public get tabActive(): boolean {
        return this._tabActive
    }

    @Input()
    public set tabActive(value: boolean) {
        if (this._tabActive !== value) {
            this._tabActive = value;
            this.cd.markForCheck();
            if (this._tabActive) {
                this.terminalListe.start();
            }
        }
    }

    constructor(
        public cd: ChangeDetectorRef,
        public terminalService: TerminalService,
        public benutzerService: BenutzerService,
        public modalService: NgbModal,
        public toastr: ToastrService,
    ) {
        super()
    }

    ngOnInit() {
        MESubscriptionHelper.add(
            this,
            this.terminalListe.onUpdateRequired.subscribe(()=>{
                this.cd.markForCheck();
                if(this.terminalListe.loading){
                    return;
                }
                this.terminalListe.loading=true;
                this.terminalService.list(
                    this.terminalListe.size,
                    this.terminalListe.calcOffset(),
                    this.terminalListe.order,
                    this.terminalListe.simpleFilter,
                    this.terminalListe.getQuery()
                )
                    .subscribe({
                        next: l => {
                            this.terminalListe.doPopulateFromListResult(l);
                            this.terminalListe.loading=false;
                            this.cd.markForCheck();
                        },
                        error: () => {
                            this.terminalListe.clear();
                            this.terminalListe.loading=false;
                        }
                    });
                this.cd.markForCheck();
            })
        );
    }

    onActivate() {
        this.tabActive=true;
    }

    onDeactivate() {
        this.tabActive=false;
    }

    ngOnDestroy() {
        this.terminalListe.release();
        MESubscriptionHelper.release(this);
    }

    ngAfterViewInit() {
    }

    removeTerminal($event: MERowActionParams<Terminal>) {
        if (this.terminalService.get($event.row.data.id)!==null && this.terminalService.get($event.row.data.id)!==undefined) {
            MEConfirmDialogComponent.display(
                this.modalService,
                "Terminal Löschung bestätigen",
                "Soll das Terminal ("+$event.row.data.display_text+" "+$event.row.data._station_display_text+") wirklich unwiederruflich gelöscht werden?",
                'Ja, jetzt löschen',
                'icofont icofont-delete'
            ).then(() => {
                this.terminalService.get($event.row.data.id).subscribe((t) =>{
                    if(t.id!==null&&t.id!==undefined&&t.id>0) {
                        this.terminalService.delete(t.id).subscribe((r) => {
                            if(r.success) {
                                this.toastr.info(
                                    'Das Terminal wurde erfolgreich gelöscht.'
                                )
                                this.terminalListe.start();
                            }
                        });
                    }
                });
            }, () => {
                this.toastr.error(
                    'Die Löschung des Terminals wurde abgebrochen.'
                )
            })
        }
    }

    assignTerminals() {
        this.terminalService.assignTerminals().subscribe((r)=> {
            if(r.success) {
                this.toastr.info(
                    'Die Terminals wurden erfolgreich zugeordnet.'
                );
                this.terminalListe.start();
            } else {
                this.toastr.error(
                    'Die Terminals konnten nicht zugeordnet werden.'
                );
            }
        });
    }

    editTerminal($event:MERowActionParams<Terminal>) {
        this.terminalService.get($event.row.data.id).subscribe((t) =>{
            if(t!==null&&t!==undefined&&t.id>0){
                TabTerminalEditorComponent.open(
                    this.modalService,
                    TabTerminalEditorComponent,
                    t,
                    MEGenericModalComponent.SIZE_MEDIUM,
                    null
                ).then(() => {
                    this.terminalListe.start();
                },noop());
            }
        });
    }
}