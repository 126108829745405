import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { SimpleNozzleReaderLogEntryFilter } from '../../common/simple-nozzle-reader-log-entry-filter';
import { MEGenericModalComponent } from '../../../maennl-commons';
import { MEPubSubService } from '../../../maennl-commons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-nozzle-reader-log-filter-popup',
  templateUrl: './nozzle-reader-log-filter-popup.component.html',
  styleUrls: ['./nozzle-reader-log-filter-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NozzleReaderLogFilterPopupComponent
  extends MEGenericModalComponent<SimpleNozzleReaderLogEntryFilter>
  implements OnInit, OnDestroy
{
  entity: SimpleNozzleReaderLogEntryFilter = null;
  infos: string[] = ['Fahrzeug erkannt', 'UHF Lesung'];

  public initEntity(
    src: SimpleNozzleReaderLogEntryFilter
  ): SimpleNozzleReaderLogEntryFilter {
    const f = new SimpleNozzleReaderLogEntryFilter();
    if (src !== null && src !== undefined) {
      f.copyFrom(src);
    }
    return f;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public cd: ChangeDetectorRef
  ) {
    super(activeModal, pubsub);
  }

  setZP(days: number | string, what = 'zeitpunkt') {
    let v = this.entity.zeitpunkt;
    if (what === 'received') {
      v = this.entity.received;
    }
    if (isNaN(parseInt('' + days, 10))) {
      if (days === 'heute') {
        v = [moment().startOf('day').toDate(), null];
      }
      if (days === 'woche') {
        v = [moment().startOf('isoWeek').toDate(), null];
      }
      if (days === 'monat') {
        v = [moment().startOf('month').toDate(), null];
      }
    } else {
      v = [
        moment()
          .subtract(parseInt('' + days, 10), 'days')
          .startOf('day')
          .toDate(),
        null
      ];
    }
    if (what === 'zeitpunkt') {
      this.entity.zeitpunkt = v;
    } else if (what === 'received') {
      this.entity.received = v;
    }
    this.cd.markForCheck();
  }

  setInfo(info: string) {
    this.entity.message = info.trim();
  }
}
