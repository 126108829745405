import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {IMEActionResponse, IMEListResult, MEActionResponse, MEApiTool, MEResultMetaSort} from "../../../maennl-commons";
import {IWireguardClient} from "./wireguard-client.interface";
import {Observable, of} from "rxjs";
import {map, take} from "rxjs/operators";
import {TNullableNumber} from "../../../maennl-commons/tools/types";
import {WireguardClient} from "./wireguard-client.class";
import {WireguardClientImpl} from "./wireguard-client.impl";
import {IMESimpleFilter} from "../../../maennl-commons/filters";

@Injectable({
    providedIn: 'root'
})
export class WireguardClientService {
    constructor(public http:HttpClient, public loginService: BenutzerService) {}

    public list(
        max=50,
        offset=0,
        sort:MEResultMetaSort[]=[],
        filter:IMESimpleFilter<IWireguardClient>=null,
        queryString=''
    ): Observable<IMEListResult<IWireguardClient>>{
        const uri=MEApiTool.buildListURL(
            '/api/meconnect/netzwerk/wireguard/client',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<IWireguardClient>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public store(entity:IWireguardClient): Observable<MEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            '/api/meconnect/netzwerk/wireguard/client',
            entity.id
        );

        if (entity.id ===null || entity.id === undefined || entity.id <1) {
            return this.http
                .post<IMEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r=>{
                        return MEActionResponse.fromRawActionResponse(r)
                    }),
                    take(1)
                );
        } else {
            return this.http
                .put<IMEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r=> {
                        return MEActionResponse.fromRawActionResponse(r);
                    }),
                    take(1)
                );
        }
    }

    public get(id:TNullableNumber): Observable<WireguardClient>{
        if (id===null||id===undefined||id<1){
            return of(null);
        }
        const uri = MEApiTool.buildEntityURL(
            '/api/meconnect/netzwerk/wireguard/client',
            id
        );

        return this.http
            .get<IWireguardClient>(
                uri,
                this.loginService.getRequestOptions()
            ).pipe(
                map(r=> {
                    return WireguardClientImpl.fromResult(r);
                }),
                take(1)
            );
    }

    public delete(id: TNullableNumber):Observable<MEActionResponse> {
        if(id===null||id===undefined||id<1){
            return of(MEActionResponse.error('id is null or undefined'));
        }
        const uri = MEApiTool.buildEntityURL(
            '/api/meconnect/netzwerk/wireguard/client',
            id
        );

        return this.http
            .delete<IMEActionResponse>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r=>{
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }
}