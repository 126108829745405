import { MEDateTimePickerComponent } from './medate-time-picker.component';
import { forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export const ME_noop = () => {
  return;
};

export const ME_DATETIME_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MEDateTimePickerComponent),
  multi: true
};
