import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mehighlight' })
export class MEHighlitePipe implements PipeTransform {
  public static transformString(value: string, search: string): string {
    if (value) {
      let value2 = (' ' + value).trim();
      if (search) {
        if (search.length > 0) {
          if (
            value2.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0
          ) {
            let pos: number = value2
              .toLocaleLowerCase()
              .indexOf(search.toLocaleLowerCase());
            while (pos > -1 && pos < value2.length) {
              const p = pos > 0 ? value2.substr(0, pos) : '';
              const v = value2.substr(pos, search.length);
              const a = value2.substr(
                pos + search.length,
                value2.length - pos - search.length
              );
              value2 = p + '<strong>' + v + '</strong>' + a;
              pos = pos + 17 + search.length;
              pos = value2
                .toLocaleLowerCase()
                .indexOf(search.toLocaleLowerCase(), pos + 1);
            }
          }
        }
      }
      return value2;
    }
    return value;
  }

  public transform(value: string, search: string): string {
    return MEHighlitePipe.transformString(value, search);
  }
}
