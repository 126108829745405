import { Component, Injector } from '@angular/core';
import { MEDataTableLocalizedCellRenderer } from './melocalized-cell-renderer';
import { MEDataTableRow } from '../rows';
import { MEDataTableColumnComponent } from '../columns';

@Component({
  selector: 'me-data-table-currency-cell-renderer',
  template: ` {{ fCurrency(cell, currency, type, digitInfo) }}{{ addon }} `
})
export class MEDataTableCurrencyCellRendererComponent extends MEDataTableLocalizedCellRenderer {
  public defaultLocale: string;

  public digitInfo = '1.2-2';
  public digits = 2;
  public currency: string;
  public type = 'symbol';
  public do_round = false;

  public cell: number = null;
  public row: MEDataTableRow<any> = null;
  public column: MEDataTableColumnComponent = null;

  public addon = '';

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');

    if (this.column !== null && this.column !== undefined) {
      const cfg = this.column.cfg;
      if (cfg !== null && cfg !== undefined) {
        if (cfg.fdigits !== undefined && cfg.fdigits !== null) {
          const fdigits: number = parseInt(cfg.fdigits, 10);
          if (!isNaN(fdigits)) {
            this.digits = fdigits;
            this.digitInfo = '1.' + fdigits + '-' + fdigits;
          }
        }

        if (cfg.round !== undefined && cfg.round !== null) {
          this.do_round = !!cfg.round;
        }

        if (cfg.addon !== undefined) {
          this.addon = cfg.addon;
        }
      }
    }

    if (this.do_round) {
      this.cell =
        Math.round(this.cell * Math.pow(this.digits, 10)) /
        Math.pow(this.digits, 10);
    }
  }
}
