import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { METabCardComponent } from '../maennl-commons/metab-card';
import { TabAbrechnungDtsComponent } from './tab-abrechnung-dts/tab-abrechnung-dts.component';
import { TabAbrechnungGreenlineComponent } from './tab-abrechnung-greenline/tab-abrechnung-greenline.component';
import { noop } from '../maennl-commons/tools';
import { AbrechnungService } from './services/abrechnung.service';
import { ToastrService } from 'ngx-toastr';
import { TabAbrechnungTKTankstellenComponent } from './tab-abrechnung-tktankstellen/tab-abrechnung-tktankstellen.component';
import { TabAbrechnungRolfesComponent } from './tab-abrechnung-rolfes/tab-abrechnung-rolfes.component';
import { TabAbrechnungPickelmannComponent } from './tab-abrechnung-pickelmann/tab-abrechnung-pickelmann.component';
import { TabAbrechnungVaroComponent } from './tab-abrechnung-varo/tab-abrechnung-varo.component';
import { TabAbrechnungHoyerComponent } from './tab-abrechnung-hoyer/tab-abrechnung-hoyer.component';
import { TabAbrechnungVaroRechnungsserverComponent } from './tab-abrechnung-varo-rechnungsserver/tab-abrechnung-varo-rechnungsserver.component';
import { TabAbrechnungGlukoseportalComponent } from './tab-abrechnung-glukoseportal/tab-abrechnung-glukoseportal.component';

@Component({
  selector: 'app-abrechnung',
  templateUrl: './abrechnung.component.html',
  styleUrls: ['./abrechnung.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbrechnungComponent
  implements OnInit, AfterViewInit, OnDestroy, AfterContentInit
{
  @ViewChild(TabAbrechnungDtsComponent, { static: false })
  tabAbrechnungDts: TabAbrechnungDtsComponent;
  @ViewChild(TabAbrechnungGreenlineComponent, { static: false })
  tabAbrechnungGreenline: TabAbrechnungGreenlineComponent;
  @ViewChild(TabAbrechnungTKTankstellenComponent, { static: false })
  tabAbrechnungTKTankstellen: TabAbrechnungTKTankstellenComponent;
  @ViewChild(TabAbrechnungRolfesComponent, { static: false })
  tabAbrechnungRolfes: TabAbrechnungRolfesComponent;
  @ViewChild(TabAbrechnungPickelmannComponent, { static: false })
  tabAbrechnungPickelmann: TabAbrechnungPickelmannComponent;
  @ViewChild(TabAbrechnungVaroComponent, { static: false })
  tabAbrechnungVaro: TabAbrechnungVaroComponent;
  @ViewChild(TabAbrechnungHoyerComponent, { static: false })
  tabAbrechnungHoyer: TabAbrechnungHoyerComponent;
  @ViewChild(TabAbrechnungVaroRechnungsserverComponent, { static: false })
  tabAbrechnungVaroRechnungsserver: TabAbrechnungVaroRechnungsserverComponent;
  @ViewChild(TabAbrechnungGlukoseportalComponent, { static: false })
  tabAbrechnungGlukoseportal: TabAbrechnungGlukoseportalComponent;

  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;

  constructor(
    public cd: ChangeDetectorRef,
    public abrechnungService: AbrechnungService,
    public toaster: ToastrService
  ) {}

  ngOnInit() {
    noop();
  }

  ngAfterContentInit(): void {
    noop();
  }

  ngAfterViewInit(): void {
    this.tabs.selectById('tab-abrechnung-dts');
  }

  ngOnDestroy(): void {
    this.deactivateTab(this.tabs.currentPane.id);
  }

  activateTab(s: string) {
    if (s === 'tab-abrechnung-dts') {
      if (
        this.tabAbrechnungDts !== null &&
        this.tabAbrechnungDts !== undefined
      ) {
        this.tabAbrechnungDts.onActivate();
      }
    }
    if (s === 'tab-abrechnung-greenline') {
      if (
        this.tabAbrechnungGreenline !== null &&
        this.tabAbrechnungGreenline !== undefined
      ) {
        this.tabAbrechnungGreenline.onActivate();
      }
    }
    if (s === 'tab-abrechnung-tktankstellen') {
      if (
        this.tabAbrechnungTKTankstellen !== null &&
        this.tabAbrechnungTKTankstellen !== undefined
      ) {
        this.tabAbrechnungTKTankstellen.onActivate();
      }
    }
    if (s === 'tab-abrechnung-rolfes') {
      if (
        this.tabAbrechnungRolfes !== null &&
        this.tabAbrechnungRolfes !== undefined
      ) {
        this.tabAbrechnungRolfes.onActivate();
      }
    }
    if (s === 'tab-abrechnung-pickelmann') {
      if (
        this.tabAbrechnungPickelmann !== null &&
        this.tabAbrechnungPickelmann !== undefined
      ) {
        this.tabAbrechnungPickelmann.onActivate();
      }
    }
    if (s === 'tab-abrechnung-varo') {
      if (
        this.tabAbrechnungVaro !== null &&
        this.tabAbrechnungVaro !== undefined
      ) {
        this.tabAbrechnungVaro.onActivate();
      }
    }

    if (s === 'tab-abrechnung-hoyer') {
      if (
        this.tabAbrechnungHoyer !== null &&
        this.tabAbrechnungHoyer !== undefined
      ) {
        this.tabAbrechnungHoyer.onActivate();
      }
    }

    if (s === 'tab-abrechnung-varo-rechnungsserver') {
      if (
        this.tabAbrechnungVaroRechnungsserver !== null &&
        this.tabAbrechnungVaroRechnungsserver !== undefined
      ) {
        this.tabAbrechnungVaroRechnungsserver.onActivate();
      }
    }
    if (s === 'tab-abrechnung-glukoseportal') {
      if (
        this.tabAbrechnungGlukoseportal !== null &&
        this.tabAbrechnungGlukoseportal !== undefined
      ) {
        this.tabAbrechnungGlukoseportal.onActivate();
      }
    }
  }

  deactivateTab(s: string) {
    if (s === 'tab-abrechnung-dts') {
      if (
        this.tabAbrechnungDts !== null &&
        this.tabAbrechnungDts !== undefined
      ) {
        this.tabAbrechnungDts.onDeactivate();
      }
    }
    if (s === 'tab-abrechnung-greenline') {
      if (
        this.tabAbrechnungGreenline !== null &&
        this.tabAbrechnungGreenline !== undefined
      ) {
        this.tabAbrechnungGreenline.onDeactivate();
      }
    }
    if (s === 'tab-abrechnung-tktankstellen') {
      if (
        this.tabAbrechnungTKTankstellen !== null &&
        this.tabAbrechnungTKTankstellen !== undefined
      ) {
        this.tabAbrechnungTKTankstellen.onDeactivate();
      }
    }

    if (s === 'tab-abrechnung-rolfes') {
      if (
        this.tabAbrechnungRolfes !== null &&
        this.tabAbrechnungRolfes !== undefined
      ) {
        this.tabAbrechnungRolfes.onDeactivate();
      }
    }

    if (s === 'tab-abrechnung-pickelmann') {
      if (
        this.tabAbrechnungPickelmann !== null &&
        this.tabAbrechnungPickelmann !== undefined
      ) {
        this.tabAbrechnungPickelmann.onDeactivate();
      }
    }

    if (s === 'tab-abrechnung-varo') {
      if (
        this.tabAbrechnungVaro !== null &&
        this.tabAbrechnungVaro !== undefined
      ) {
        this.tabAbrechnungVaro.onDeactivate();
      }
    }

    if (s === 'tab-abrechnung-hoyer') {
      if (
        this.tabAbrechnungHoyer !== null &&
        this.tabAbrechnungHoyer !== undefined
      ) {
        this.tabAbrechnungHoyer.onDeactivate();
      }
    }

    if (s === 'tab-abrechnung-varo-rechnungsserver') {
      if (
        this.tabAbrechnungVaroRechnungsserver !== null &&
        this.tabAbrechnungVaroRechnungsserver !== undefined
      ) {
        this.tabAbrechnungVaroRechnungsserver.onDeactivate();
      }
    }
    if (s === 'tab-abrechnung-glukoseportal') {
      if (
        this.tabAbrechnungGlukoseportal !== null &&
        this.tabAbrechnungGlukoseportal !== undefined
      ) {
        this.tabAbrechnungGlukoseportal.onDeactivate();
      }
    }
  }

  recalcAktuell() {
    this.abrechnungService.recalcAbrechnungen().subscribe(() => {
      this.toaster.success('Alle aktuelle Abrechnungen wurden neu berechnet.');
      this.activateTab(this.tabs.currentPane.id);
    });
  }
}
