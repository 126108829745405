<div class="modal-header">
    <h4 class="modal-title">Kennwort ändern</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <me-text-field type="password" [(value)]="pw1" label="neues Kennwort"></me-text-field>
    <me-text-field type="password" [(value)]="pw2" label="neues Kennwort (Wiederholung)"></me-text-field>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" [disabled]="!canSave()" (click)="changePw()">
        <fa-icon [icon]="['far', 'key']"></fa-icon>
        Kennwort festlegen
    </button>
</div>
