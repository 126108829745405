import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { AbrechnungGreenline } from './abrechnung-greenline';
import { IAbrechnungGreenline } from './iabrechnung-greenline';
import { SimpleAbrechnungGreenlineFilter } from './simple-abrechnung-greenline-filter';

export class AbrechnungGreenlineList extends MERemoteList<AbrechnungGreenline> {
  __classname = 'AbrechnungGreenlineList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IAbrechnungGreenline>
  ): AbrechnungGreenlineList {
    const newList = new AbrechnungGreenlineList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleAbrechnungGreenlineFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'periode',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return AbrechnungGreenline.fromResult(entity);
  }
}
