<ng-container #modules [ngTemplateOutlet]="modulestemp">
    <ng-template #modulestemp>
        <div *ngFor="let module of modulList.data"
             class="d-flex justify-content-between align-items-center">
            <div class="col-form-label col-3 ng-star-inserted">
                {{ module.code }}
                <i title="{{module.description}}" class="icofont icofont-info-circle"></i>
            </div>
            <div class="flex-fill ms-2 ml-2" style="display: flex; justify-content: center">
                <ng-container *ngIf="entity.has_module(module.code)">
                    <i
                            class="fa fa-fw fa-check-square-o has-cursor-pointer"
                            title="{{module.code}} entfernen"
                            (click)="remove_module(module)"></i>
                </ng-container>
                <ng-container *ngIf="!entity.has_module(module.code)">
                    <i
                            class="fa fa-fw fa-square-o has-cursor-pointer"
                            title="{{module.code}} hinzufügen"
                            (click)="add_module(module)"></i>
                </ng-container>
            </div>

        </div>
    </ng-template>
</ng-container>