<div class="modal-header">
    <h4 class="modal-title">Fahrzeug {{entity.tracker.vehicle_display_text}} bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="table-responsive">
        <table class="w-100">
            <tr>
                <th scope="row" class="pe-2 pe-2 me-2 me-2">Nummer</th>
                <td>
                    <me-num-field labelwidth="0" [(value)]="entity.nummer"></me-num-field>
                </td>
            </tr>
            <tr>
                <th scope="row" class="pe-2 pe-2 me-2 me-2">Bezeichnung</th>
                <td>
                    <me-text-field labelWidth="0" [(value)]="entity.display_text"></me-text-field>
                </td>
            </tr>
            <tr>
                <th scope="row" class="pe-2 pe-2 me-2 me-2">VIN</th>
                <td>
                    <me-text-field labelWidth="0" [(value)]="entity.vin"></me-text-field>
                </td>
            </tr>
            <tr>
                <th scope="row" class="pe-2 pe-2 me-2 me-2">KFZ-Kennzeichen</th>
                <td>
                    <me-text-field labelWidth="0" [(value)]="entity.plate"></me-text-field>
                </td>
            </tr>
            <tr>
                <th scope="row" class="pe-2 pe-2 me-2 me-2">km-Stand (m)</th>
                <td>
                    <me-num-field labelwidth="0" [(value)]="entity.km_stand_metres"></me-num-field>
                    <div class="small">
                        {{ fNum(entity.km_stand_metres / 1000, '1.3-3') }}
                    </div>
                </td>
            </tr>
            <tr>
                <th scope="row" class="pe-2 pe-2 me-2 me-2">Betriebsstunden-Stand (sec)</th>
                <td>
                    <me-num-field labelwidth="0" [(value)]="entity.working_hours_seconds"></me-num-field>
                    <div class="small">
                        {{ fDuration(entity.working_hours_seconds * 1000, false, false, true) }}
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="ok()" [disabled]="!can_save()">
        <i class="far fa-floppy-disk"></i> Fahrzeugdaten speichern
    </button>
</div>
