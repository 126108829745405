import {TAJobs} from "./ta-jobs.class";
import {TNullableTAJobs, TTAJobs} from "./ta-jobs.types";
import {MEGenericEntityFields} from "../../../maennl-commons";

export class TAJobsImpl {
    static fromResult(
        entity:TTAJobs,
        createifNull=false
    ): TNullableTAJobs {
        if(entity===null||entity===undefined){
            if(createifNull){
                return new TAJobs();
            }
            return null
        }

        const instance= new TAJobs();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongFields([
                    'id',
                    'tenant',
                    'terminal',
                    'kind',
                    'tries',
                    'prio',
                    'progress',
                ])
                .addTextFields([
                    'options_text',
                    'info',
                    'options_hash',
                    '_station_display_text',
                    '_tenant_info',
                    '_terminal_display_text',
                ])
                .addBooleanFields([
                    '_in_progress',
                ])
                .addDateFields([
                    'date_created',
                    'next_try',
                    'job_started',
                ])
        );
        return instance;
    }
}