import { Benutzer } from '../../benutzer/common/benutzer';
import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IDokument } from './idokument';

export class Dokument extends MEGenericEntity<IDokument> implements IDokument {
  public content = '';
  public dateCreated: Date = null;
  public lastEditorId = 0;
  public lastUpdated: Date = null;
  public subtitle = '';
  public title = '';
  public slug = '';
  public editable = true;
  public id = 0;
  public lastEditor: Benutzer = null;
  public version = 0;

  static fromResult(
    entity: IDokument,
    createIfNull: boolean = false
  ): Dokument {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Dokument();
      }
      return null;
    }

    const instance = new Dokument();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addTextField('slug')
        .addTextField('title')
        .addTextField('subTitle')
        .addTextField('content')
        .addTextField('lastEditor')
        .addDateField('dateCreated')
        .addDateField('lastUpdated')
        .addLongField('id')
        .addLongField('version')
        .addBooleanField('editable')
        .addConverterField(
          'lastEditor',
          new MEGenericEntityConverterParams<Benutzer>((v) => {
            return Benutzer.fromResult(v);
          })
        ),
      (e: Dokument) => {}
    );
    return instance;
  }

  constructor(title: string = '', content: string = '') {
    super();
    this.content = content;
    this.title = title;
  }
}
