import { Injectable } from '@angular/core';
import { IAbrechnungGlukoseportal } from '../common/iabrechnung-glukoseportal';
import { take } from 'rxjs/operators';
import { IMEListResult } from '../../maennl-commons/mecommon-list';
import { IMEActionResponse, MEApiTool } from '../../maennl-commons/network';
import { Observable } from 'rxjs';
import { IMESimpleFilter } from '../../maennl-commons/filters';
import { MEResultMetaSort } from '../../maennl-commons/mesort-buttons';
import { HttpClient } from '@angular/common/http';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Injectable({
  providedIn: 'root'
})
export class AbrechnungGlukoseportalService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public listAbrechnungen(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IAbrechnungGlukoseportal> = null
  ): Observable<IMEListResult<IAbrechnungGlukoseportal>> {
    const uri = MEApiTool.buildListURL(
      '/api/abrechnungen/glukoseportal',
      max,
      offset,
      sort,
      filter
    );
    return this.http
      .get<IMEListResult<IAbrechnungGlukoseportal>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }

  public recalcAbrechnung(
    abrechnung: IAbrechnungGlukoseportal
  ): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL(
      '/api/abrechnungen/glukoseportal',
      abrechnung.id,
      '_recalc'
    );
    return this.http
      .post<IMEActionResponse>(uri, null, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public getAbrechnung(id: number): Observable<IAbrechnungGlukoseportal> {
    const uri = MEApiTool.buildEntityURL('/api/abrechnungen/glukoseportal', id);
    return this.http
      .get<IAbrechnungGlukoseportal>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public markAbrechnungCleared(
    abrechnung: IAbrechnungGlukoseportal
  ): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL(
      '/api/abrechnungen/glukoseportal',
      abrechnung.id,
      '_mark_as_cleared'
    );
    return this.http
      .post<IMEActionResponse>(uri, null, this.loginService.getRequestOptions())
      .pipe(take(1));
  }
}
