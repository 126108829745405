import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { SimProvider } from '../../router/common/sim-provider';
import { IRouterStatus } from './irouter-status';
import { SimStatus } from './sim-status';

export class RouterStatus
  extends MEGenericEntity<IRouterStatus>
  implements IRouterStatus
{
  gesperrt = true;
  ip = '';
  provider: SimProvider = null;
  status: SimStatus = null;
  label = '';
  id = 0;
  lastSeen: Date = null;
  msidsn = '';
  nummer = 0;
  aktuellerStatus = '';

  static fromResult(
    entity: IRouterStatus,
    createIfNull: boolean = false
  ): RouterStatus {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new RouterStatus();
      }
      return null;
    }

    const instance = new RouterStatus();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addBooleanField('gesperrt')
        .addTextField('ip')
        .addTextField('label')
        .addDateField('lastSeen')
        .addTextField('msidsn')
        .addLongField('nummer')
        .addTextField('aktuellerStatus')
        .addConverterField(
          'status',
          new MEGenericEntityConverterParams<SimStatus>((value: any) => {
            return SimStatus.fromResult(value);
          })
        )
        .addConverterField(
          'provider',
          new MEGenericEntityConverterParams<SimProvider>((value: any) => {
            return SimProvider.fromResult(value);
          })
        )
    );
    return instance;
  }

  constructor() {
    super();
  }
}
