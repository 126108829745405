<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'money-bill']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Abrechnung"
></me-area-heading>

<me-tab-card>
    <me-pane id="tab-abrechnung-dts"
             title="DTS"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)">
        <app-tab-abrechnung-dts></app-tab-abrechnung-dts>
    </me-pane>
    <me-pane id="tab-abrechnung-greenline"
             title="Greenline"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)">
        <app-tab-abrechnung-greenline></app-tab-abrechnung-greenline>
    </me-pane>

    <me-pane id="tab-abrechnung-tktankstellen"
             title="TK Tankstellen"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)">
        <app-tab-abrechnung-tktankstellen></app-tab-abrechnung-tktankstellen>
    </me-pane>

    <me-pane id="tab-abrechnung-rolfes"
             *ngIf="false"
             title="Rolfes"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)">
        <app-tab-abrechnung-rolfes></app-tab-abrechnung-rolfes>
    </me-pane>

    <me-pane id="tab-abrechnung-pickelmann"
             title="Pickelmann"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)">
        <app-tab-abrechnung-pickelmann></app-tab-abrechnung-pickelmann>
    </me-pane>

    <me-pane id="tab-abrechnung-varo"
             title="VARO"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)">
        <app-tab-abrechnung-varo></app-tab-abrechnung-varo>
    </me-pane>

    <me-pane id="tab-abrechnung-varo-rechnungsserver"
             title="VARO Rechnungsserver"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)">
        <app-tab-abrechnung-varo-rechnungsserver></app-tab-abrechnung-varo-rechnungsserver>
    </me-pane>

    <me-pane id="tab-abrechnung-hoyer"
             title="Hoyer"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)">
        <app-tab-abrechnung-hoyer></app-tab-abrechnung-hoyer>
    </me-pane>

    <me-pane id="tab-abrechnung-glukoseportal"
             title="Glukoseportal"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)">
        <app-tab-abrechnung-glukoseportal></app-tab-abrechnung-glukoseportal>
    </me-pane>


    <me-tab-card-extra-header>
        <button class="btn btn-secondary"
                title="Alle aktuellen Abrechnungen werden neu berechnet"
                (click)="recalcAktuell()"
        >
            <fa-icon [icon]="['far', 'calculator']"></fa-icon>
            aktuelle Abrechnungen
        </button>
    </me-tab-card-extra-header>
</me-tab-card>
