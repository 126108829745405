<ng-template #ahIcon>
  <fa-icon [icon]="['far', 'network-wired']"></fa-icon>
</ng-template>
<me-area-heading
  [icon]="ahIcon"
  title="IP-Adressen & Netzwerke"></me-area-heading>

<me-tab-card #rootTabCard (tabSelected)="onTabSelect($event)">
  <me-pane
    id="tab-netzwerke"
    title="Netzwerke"
    (activated)="activateTab($event.id)"
    (deactivated)="deactivateTab($event.id)">
    <app-tna-networks [id]="'MECIP-tab-networks'"></app-tna-networks>
  </me-pane>
  <me-pane
    id="tab-addresses"
    title="Einzel-Adressen"
    (activated)="activateTab($event.id)"
    (deactivated)="deactivateTab($event.id)">
    <app-tna-addresses [id]="'MECIP-tab-addresses'"></app-tna-addresses>
  </me-pane>

  <me-pane
    id="tab-groups"
    title="Gruppen"
    (activated)="activateTab($event.id)"
    (deactivated)="deactivateTab($event.id)">
    <app-tna-groups [id]="'MECIP-tab-groups'"></app-tna-groups>
  </me-pane>

  <me-pane
    id="tab-firewalls"
    title="Firewalls"
    (activated)="activateTab($event.id)"
    (deactivated)="deactivateTab($event.id)">
    <app-tna-firewalls [id]="'MECIP-tab-firewalls'"></app-tna-firewalls>
  </me-pane>
</me-tab-card>
