import { Component, Input } from '@angular/core';
import { MELocalizedComponent } from '../../maennl-commons/localized-component';
import { AbrechnungPickelmann } from '../common/abrechnung-pickelmann';

@Component({
  selector: 'app-abrechnung-pickelmann-print-preview',
  template: `
    <div
      style="font-family: Tahoma, Arial, Helvetica, SansSerif, sans;font-size: 9pt; padding: 2.25rem;display: block;"
    >
      <h1 style="margin-bottom: 25px;font-size: 1.8em;">
        Anlage zur Abrechnung
      </h1>
      <h2 style="margin-bottom: 25px;font-size: 1.5em;margin-top: 15px;">
        Informationen
      </h2>
      <table width="100%">
        <tr style="border-bottom: 1px solid #CCCCCC;">
          <td valign="top" style="font-weight: bold;width: 25%;padding: 5px;">
            Kunde
          </td>
          <td>Pickelmann GmbH, Alter Brunnen 1, 91282 Betzenstein</td>
        </tr>
        <tr style="border-bottom: 1px solid #CCCCCC;">
          <td valign="top" style="font-weight: bold;width: 25%;padding: 5px;">
            Datum
          </td>
          <td valign="top" style="padding: 5px;">
            {{ fDate(abrechnung.lastUpdated, 'mediumDate') }}
          </td>
        </tr>
        <tr>
          <td valign="top" style="font-weight: bold;width: 25%;padding: 5px;">
            Abrechnungsperiode
          </td>
          <td valign="top" style="padding:5px;">{{ abrechnung.periode }}</td>
        </tr>
        <tr *ngIf="abrechnung.startDay > 1 || abrechnung.endDay < 28">
          <td valign="top" style="font-weight: bold;width: 25%;padding: 5px;">
            Zeitbereich
          </td>
          <td valign="top" style="padding:5px;">
            {{ abrechnung.startDay }}. bis einschließlich
            {{ abrechnung.endDay }}. des Monats
          </td>
        </tr>
      </table>

      <h2 style="margin-bottom: 25px;font-size: 1.5em;margin-top: 25px;">
        Zusammenfassung
      </h2>
      <table width="100%">
        <thead>
          <tr style="border-top: 1px solid #cccccc;">
            <th
              style="width: 5%;text-align: center;font-weight: bold;padding: 5px;"
            >
              Pos.
            </th>
            <th
              style="width: auto;text-align: left;font-weight: bold;padding: 5px;"
            >
              Text
            </th>
            <th
              style="width: 10%;text-align: right;font-weight: bold;padding: 5px;"
            >
              Menge
            </th>
            <th
              style="width: 10%;text-align: right;font-weight: bold;padding: 5px;"
            >
              E.-Preis
            </th>
            <th
              style="width: 10%;text-align: right;font-weight: bold;padding: 5px;"
            >
              G.-Preis
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let pos of abrechnung.positionen"
            style="border-top: 1px solid #dedede;"
          >
            <td valign="top" style="text-align: center; padding: 5px;">
              {{ pos.pos }}
            </td>
            <td valign="top" style="text-align: left; padding: 5px;">
              <strong>{{ pos.title }}</strong
              ><br />
              <div
                style="font-size: 0.8em;"
                [innerHTML]="pos.getHTML() | MESaveHtml"
              ></div>
            </td>
            <td valign="top" style="text-align: right; padding: 5px;">
              {{ fNum(pos.menge, '1.0-0') }}
            </td>
            <td valign="top" style="text-align: right; padding: 5px;">
              {{ fCurrency(pos.epreis, 'EUR', 'symbol', '1.2-2') }}
            </td>
            <td valign="top" style="text-align: right; padding: 5px;">
              {{ fCurrency(pos.gpreis, 'EUR', 'symbol', '1.2-2') }}
            </td>
          </tr>

          <tr
            style="border-top: 1px solid #cccccc;border-bottom: 2px solid #aaaaaa;"
          >
            <th
              colspan="4"
              valign="top"
              style="text-align: left; padding: 5px;"
            >
              Gesamtsumme (netto ohne Umsatzsteuer)
            </th>
            <td
              valign="top"
              style="text-align: right; font-weight: bold;padding: 5px;"
            >
              {{ fCurrency(abrechnung.summe, 'EUR', 'symbol', '1.2-2') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  `
})
export class AbrechnungPickelmannPrintPreviewComponent extends MELocalizedComponent {
  @Input() abrechnung: AbrechnungPickelmann = new AbrechnungPickelmann();

  constructor() {
    super();
  }
}
