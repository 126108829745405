import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { Device } from '../../router/common/device';
import { INozzleReaderLogEntry } from './inozzle-reader-log-entry';

export class NozzleReaderLogEntry
  extends MEGenericEntity<INozzleReaderLogEntry>
  implements INozzleReaderLogEntry
{
  device: Device = null;
  id = 0;
  message = '';
  params: any = null;
  received: Date = null;
  type = 0;
  vorgang = 0;
  zeile = 0;
  zeitpunkt: Date = null;

  static fromResult(
    entity: INozzleReaderLogEntry,
    createIfNull: boolean = false
  ): NozzleReaderLogEntry {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new NozzleReaderLogEntry();
      }
      return null;
    }
    const instance = new NozzleReaderLogEntry();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addConverterField(
          'device',
          new MEGenericEntityConverterParams<Device>((value: any) => {
            return Device.fromResult(value);
          })
        )
        .addTextField('message')
        .addDateField('received')
        .addDateField('zeitpunkt')
        .addLongField('type')
        .addLongField('vorgang')
        .addLongField('zeile')
        .addObjectField('params'),
      (e: NozzleReaderLogEntry) => {}
    );

    return instance;
  }

  constructor() {
    super();
  }
}
