<me-data-table
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="devices.asDataTableRows()"
        [selectable]="false"
        [actionsicon]="'icofont icofont-navigation-menu'"
>
    <me-data-table-action
            label="aus Tankserver importieren"
            (perform)="importTS()"
    ></me-data-table-action>
    <me-data-table-column [label]="'ID'" [field]="'id'" type="long"></me-data-table-column>
    <me-data-table-column [label]="'Bezeichnung'" [field]="'bezeichnung'"></me-data-table-column>
    <me-data-table-column [label]="'Typ'"
                          [field]="'deviceType'"
                          [renderer]="deviceTypeRenderer"
    ></me-data-table-column>
    <me-data-table-column [label]="'SIM'"
                          [field]="'sim'"
                          [renderer]="simRenderer"
    ></me-data-table-column>
    <me-data-table-column [label]="'Seriennummer'" [field]="'seriennummer'"></me-data-table-column>
    <me-data-table-column [label]="'Boxnummer'" [field]="'boxnummer'"></me-data-table-column>

    <me-data-table-rowaction
            label="Bearbeiten"
            title="Geräte-Einstellungen bearbeiten"
            (perform)="editRouter($event)"
            icon="icofont icofont-ui-edit"
    ></me-data-table-rowaction>
    <me-data-table-rowaction
            label="Reboot"
            title="das Gerät rebooten"
            (perform)="rebootRouter($event)"
            icon="icofont icofont-refresh"
            [availableCheck]="rebootActionAvailable"
    ></me-data-table-rowaction>


    <me-data-table-table-extra-header>
        <me-pagination
                [total]="devices.total"
                [page]="devices.page"
                [perpage]="devices.size"
                (pageChange)="devices.setPage($event)"
                (pageCountChange)="devices.processPageCountChangedEvent($event)"
                [showSearch]="true"
                (queryChange)="devices.processQueryChangedEvent($event)"
                settingsid="router-devices"
                [settingsService]="benutzerService"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>

