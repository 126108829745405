import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MERichError } from '../../maennl-commons/tools';
import { MERowActionParams } from '../../maennl-commons/data-table/actions';
import { Device } from '../../router/common/device';
import { SondeList } from '../common/sonde-list';
import { SondeService } from '../services/sonde.service';
import { Sonde } from '../common/sonde';
import { MEDTDeviceRendererComponent } from '../../router/controls/medtdevice-renderer/medtdevice-renderer.component';
import { ToastrService } from 'ngx-toastr';
import { MESubscriptionHelper } from '../../maennl-commons/services/mesubscription-helper';
import { PeilungenPopupComponent } from '../peilungen-popup/peilungen-popup.component';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ISonde } from '../common/isonde';
import { SondeEditorComponent } from '../sonde-editor/sonde-editor.component';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-tab-sonden',
  templateUrl: './tab-sonden.component.html',
  styleUrls: ['./tab-sonden.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabSondenComponent implements OnInit, OnDestroy {
  __classname = 'TabSondenComponent';
  __instance = '';

  public deviceRenderer = MEDTDeviceRendererComponent;

  public sonden: SondeList = new SondeList();
  private tabActive = false;

  constructor(
    public sondeService: SondeService,
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    public benutzerService: BenutzerService,
    public modalService: NgbModal
  ) {}

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.sonden.onUpdateRequired.subscribe((list: SondeList) => {
        this.sondeService
          .listSonden(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
            },
            (e) => {
              console.log(e);
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste der verfügbaren Sonden konnte nicht geladen werden'
              );
            }
          );
      }, undefined)
    );

    if (this.tabActive) {
      this.sonden.start();
    }
  }

  checkSonde(s: string, $event: MERowActionParams<Device>) {
    if ($event === null || $event === undefined) {
      return false;
    }

    if (s === 'reboot') {
      $event.row.disableAction($event.action);
      $event.row.hideAction($event.action);
    }

    return false;
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.sonden.release();
  }

  peilung($event: MERowActionParams<Sonde>) {
    this.sondeService.requestPeilung($event.row.data).subscribe(
      (r) => {
        if (r.success) {
          this.toastr.success(
            'Die Peilung wurde ausgelöst und sollte in Kürze verfügbar sein'
          );
        } else {
          MERichError.throw(
            'Vorgang nicht möglich',
            'Die Peilung konnte nicht ausgelöst werden.'
          );
        }
      },
      () => {
        MERichError.throw(
          'Vorgang nicht möglich',
          'Die Peilung konnte nicht ausgelöst werden.'
        );
      }
    );
  }

  tabActivated() {
    this.tabActive = true;
    this.sonden.start();
  }

  tabDeactivated() {
    this.tabActive = false;
  }

  showPeilungen(e: MERowActionParams<Sonde>) {
    this.sondeService.getSonde(e.row.data.id).subscribe(
      (s: ISonde) => {
        PeilungenPopupComponent.open(
          this.modalService,
          PeilungenPopupComponent,
          s,
          MEGenericModalComponent.SIZE_LARGE
        ).then(
          () => {
            this.sonden.reload();
          },
          () => {}
        );
      },
      () => {
        MERichError.throw(
          'Sonde nicht gefunden',
          'Zu dieser Sonde wurden keine Daten gefunden'
        );
      }
    );
  }

  editSonde(e: MERowActionParams<Sonde>) {
    this.sondeService.getSonde(e.row.data.id).subscribe(
      (s: ISonde) => {
        SondeEditorComponent.open(
          this.modalService,
          SondeEditorComponent,
          s,
          MEGenericModalComponent.SIZE_MEDIUM
        ).then(
          () => {
            this.sonden.reload();
          },
          () => {}
        );
      },
      () => {
        MERichError.throw(
          'Sonde nicht gefunden',
          'Zu dieser Sonde wurden keine Daten gefunden'
        );
      }
    );
  }
}
