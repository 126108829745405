import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { SysHealthLebenszeichenDisplayComponent } from '../sys-health-lebenszeichen-display/sys-health-lebenszeichen-display.component';
import { noop } from '../../../maennl-commons/tools';

@Component({
  selector: 'app-sys-health-lebenszeichen-tab',
  templateUrl: './sys-health-lebenszeichen-tab.component.html',
  styleUrls: ['./sys-health-lebenszeichen-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SysHealthLebenszeichenTabComponent implements OnInit, OnDestroy {
  __classname = 'SysHealthLebenszeichenTabComponent';
  __instance = '';
  @Input() id = 'SysHealthLebenszeichenTab';
  tabActive = false;
  @ViewChild(SysHealthLebenszeichenDisplayComponent, { static: false }) disp =
    null;

  constructor(public cd: ChangeDetectorRef) {
    noop();
  }

  ngOnInit() {
    noop();
  }

  onDeactivate() {
    this.tabActive = false;
    if (this.disp !== null && this.disp !== undefined) {
      this.disp.onDeactivate();
    }
  }

  onActivate() {
    this.tabActive = true;
    if (this.disp !== null && this.disp !== undefined) {
      this.disp.onActivate();
    }
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.onDeactivate();
    MESubscriptionHelper.release(this);
  }
}
