import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ISonde } from '../../common/isonde';

@Component({
  selector: 'app-sonde-display',
  template: `
    <div *ngIf="isValid()" title="{{ bezeichnung }}">{{ getInfo() }}</div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SondeDisplayComponent implements OnInit, OnDestroy {
  private _value: ISonde = null;

  @Input()
  get value(): ISonde {
    return this._value;
  }

  set value(value: ISonde) {
    if (this._value !== value) {
      this._value = value;
      this.cd.markForCheck();
    }
  }

  get bezeichnung() {
    if (this.isValid()) {
      return this._value.bezeichnung;
    }
    return '';
  }

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit() {
    //
  }

  ngOnDestroy(): void {
    //
  }

  isValid() {
    return this._value !== null && this._value !== undefined;
  }

  getInfo() {
    let ret = '';
    if (this.isValid()) {
      if (
        this._value.bezeichnung !== null &&
        this._value.bezeichnung !== undefined
      ) {
        ret = this._value.bezeichnung.trim();
      }
      if (ret === '') {
        if (this._value.device !== null && this._value.device !== undefined) {
          ret =
            'Sonde ' +
            this._value.nummer +
            ' @ ' +
            this._value.device.bezeichnung;
        } else {
          ret = 'Sonde ' + this._value.nummer;
        }
      }
    }
    return ret;
  }
}
