import { METool } from './metool';
import { Injector } from '@angular/core';

export class MEAppInjector {
  static mode = 'development';

  static injector_reference: Injector = null;

  public static enableProduction() {
    MEAppInjector.mode = 'production';
  }

  static init(injector?: Injector) {
    if (!METool.isNullOrUndefined(injector)) {
      if (METool.isNullOrUndefined(MEAppInjector.injector_reference)) {
        MEAppInjector.injector_reference = injector;
      }
    }
  }

  static injector() {
    return MEAppInjector.injector_reference;
  }

  public static get(token: any, notFound?: any, flags?: any): any {
    return MEAppInjector.injector().get(token, notFound, flags);
  }

  public static initInjector(injectorRef: Injector): Function {
    const res = () => {
      MEAppInjector.init(injectorRef);
    };
    return res;
  }

  public static isDevelopment() {
    return MEAppInjector.mode === 'development';
  }

  public static isProduction() {
    return MEAppInjector.mode === 'production';
  }
}
