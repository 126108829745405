<div class="modal-header">
    <h4 class="modal-title">Fahrzeug {{entity.tracker.tracker_last_address}} bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="table-responsive">
        <table class="w-100">
            <tr>
                <th scope="row" class="pe-2 pe-2 me-2 me-2">Identifikation</th>
                <td>
                    {{ entity.identification }}
                </td>
            </tr>
            <tr class="mt-2 " style="border-top: 1px solid #DDDDDD">
                <th scope="row" class="pt-2 pe-2 pe-2 me-2 me-2">Offset km (m)</th>
                <td class="pt-2">
                    <me-num-field labelwidth="0" [(value)]="entity.offset_odometer"
                                  label="Offset in Meter"></me-num-field>
                    <div class="small">
                        {{ fNum(entity.offset_odometer / 1000, '1.3-3') }}
                    </div>
                </td>
            </tr>
            <tr>
                <th scope="row" class="pe-2 pe-2 me-2 me-2">km-Stand lt Tracker</th>
                <td class="small">
                    {{ fNum(entity.last_odometer, '1.0-0') }} m =
                    {{ fNum(entity.last_odometer / 1000, '1.3-3') }} km
                    ==> <span class="text-info"
                              title="mit Offset ergibt sich">{{ fNum((entity.last_odometer + entity.offset_odometer) / 1000, '1.3-3') }}
                    km</span>
                </td>
            </tr>
            <tr class="mt-2" style="border-top: 1px solid #ddd">
                <th scope="row" class="pe-2  pt-2 pe-2 me-2 me-2">Offset Betriebsstunden (sec)</th>
                <td class="pt-2">
                    <me-num-field labelwidth="0" [(value)]="entity.offset_working_hours"
                                  label="offset in Sekunden"></me-num-field>
                    <div class="small">
                        {{ fDuration(entity.offset_working_hours * 1000, false, false, true) }}
                    </div>
                </td>
            </tr>
            <tr>
                <th scope="row" class="pe-2 pe-2 me-2 me-2">Betriebsstunden-Stand lt Tracker (sec)</th>
                <td class="small">
                    {{ fNum(entity.last_working_hours, '1.0-0') }} sec =
                    {{ fDuration(entity.last_working_hours * 1000, false, false, true) }}
                    => <span class="text-info"
                             title="mit Offset ergibt sich">{{ fDuration((entity.last_working_hours + entity.offset_working_hours) * 1000, false, false, true) }}</span>
                </td>
            </tr>
            <tr class="mt-2" style="border-top: 1px solid #ddd">
                <th scope="row" class="pe-2 pe-2 pt-2 me-2 me-2">SMS-Kennwort</th>
                <td class="pt-2">
                    <me-text-field labelWidth="0" [(value)]="entity.sms_password" label="SMS-Kennwort"></me-text-field>
                </td>
            </tr>
            <tr>
                <th scope="row" class="pe-2 pe-2 me-2 me-2">SMS-Befehle nutzen ID</th>
                <td>
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="me-2 me-2 pt-2">
                            <me-boolean-field [(value)]="entity.sms_use_id" title=""
                                              labelWidth="0"></me-boolean-field>
                        </div>
                        <div>
                            Ja (zb. beim HCV5)
                        </div>
                    </div>
                </td>
            </tr>
            <tr class="mt-2" style="border-top: 1px solid #ddd">
                <th scope="row" class="pe-2 pe-2 pt-2 me-2 me-2">GPS Aufzeichnung</th>
                <td class="pt-2">
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="me-2 me-2 pt-2">
                            <me-boolean-field [(value)]="entity.gps_recording_enabled" title=""
                                              labelWidth="0"></me-boolean-field>
                        </div>
                        <div>
                            aktivieren
                        </div>
                    </div>
                </td>
            </tr>
            <tr class="mt-2" style="border-top: 1px solid #ddd">
                <th scope="row" class="pe-2 pe-2 pt-2 me-2 me-2">Mitschnitt</th>
                <td class="pt-2">
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="me-2 me-2 pt-2">
                            <me-boolean-field [(value)]="entity.mitschnitt_aktiv" title=""
                                              labelWidth="0"></me-boolean-field>
                        </div>
                        <div>
                            aktivieren
                        </div>
                    </div>
                </td>
            </tr>
            <tr class="mt-2 " style="border-top: 1px solid #ddd">
                <th scope="row" class="pe-2 pe-2 me-2 me-2 pt-2">letzte Werte</th>
                <td class="pt-2">
                    <div class="d-flex flex-row flex-wrap justify-content-start small">
                        <div class="me-2 me-2" title="letzte Adresse" *ngIf="entity.last_address!==null">
                            <i class="fa-light fa-globe-europe me-1 me-1"></i>
                            {{ entity.last_address }}
                        </div>
                        <div class="me-2 me-2" title="letzte Meldung" *ngIf="entity.last_seen!==null">
                            <i class="fa-light fa-calendar me-1 me-1"></i>
                            {{ fDate(entity.last_seen, 'YYYY-MM-DD HH:mm:ss', true) }}
                        </div>
                        <div class="me-2 me-2 " *ngIf="entity.last_voltage!==null"
                             title="Spannung am {{ fDate(entity.last_voltage_zp,'YYYY-MM-DD HH:mm:ss',true) }}">
                            <i class="fa-light fa-bolt me-1 me-1"></i> {{ fNum(entity.last_voltage, '1.2-2') }} V
                        </div>
                        <div *ngIf="entity.last_zuendung!==null" class="me-2 me-2 "
                             title="Status Zündung beim letzten Datenempfang"
                             [class.text-success]="entity.last_zuendung"
                             [class.text-secondary]="!entity.last_zuendung"
                             [class.text-bold]="entity.last_zuendung"
                             [class.fw-bold]="entity.last_zuendung">
                            <i class="fa-light fa-fire me-1 me-1"></i>
                            <span *ngIf="entity.last_zuendung"> EIN</span>
                            <span *ngIf="!entity.last_zuendung"> AUS</span>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>
<div class="modal-footer d-flex justify-content-between">
    <div>
        <button class="btn btn-danger" (click)="remove()">Tracker löschen</button>
    </div>
    <div>
        <button type="button" class="btn btn-secondary" (click)="cancel()">
            <i class="icofont icofont-close"></i> Schließen
        </button>
        <button type="button" class="btn btn-primary" (click)="ok()" [disabled]="!can_save()">
            <i class="far fa-floppy-disk"></i> Trackerdaten speichern
        </button>
    </div>
</div>
