<div class="d-flex justify-content-between flex-nowrap">
    <div class="controller">
    </div>
    <div style="flex-grow: 100; width: 98%;">
        <div class="text-end options">
            <button type="button" class="btn btn-sm btn-secondary" *ngIf="showOrigin"
                    (click)="showOrigin=false">formatiertes Datenpaket anzeigen
            </button>
            <button type="button" class="btn btn-sm btn-secondary" *ngIf="!showOrigin"
                    (click)="showOrigin=true">Originales Datenpaket anzeigen
            </button>
        </div>
        <div *ngIf="showOrigin">
            <div class="chunk">
                <div class="header d-flex justify-content-between">
                    <div>
                        Originales Datenpaket
                    </div>
                </div>
                <div class="d-flex justify-content-start flex-wrap">
                    <div class='byte' *ngFor="let b of origin">
                        <div class="text-dark bg-secondary">{{ b }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!showOrigin">
            <ul class="chunks">
                <li *ngFor="let chunk of chunks" [ngClass]="'text-'+chunk.guessDirection(this.flip_sides)"
                >
                    <div class="chunk">
                        <div class="header d-flex justify-content-between">
                            <div>
                                {{ chunk.getTitle() }}
                            </div>
                            <div>
                                <button type="button" class="btn btn-sm"
                                        *ngIf="!chunk.isDummy() && chunk.display_mode!==0"
                                        (click)="chunk.display_mode=0">Rohdaten
                                </button>
                                <button type="button" class="btn btn-sm"
                                        *ngIf="!chunk.isDummy() && chunk.display_mode!==1"
                                        (click)="chunk.display_mode=1">übersetzt
                                </button>
                                <button type="button" class="btn btn-sm"
                                        *ngIf="!chunk.isDummy() && chunk.display_mode!==2"
                                        (click)="chunk.display_mode=2">formatiert
                                </button>
                                <button type="button" class="btn btn-sm"
                                        *ngIf="!chunk.isDummy() && chunk.display_mode!==3"
                                        (click)="chunk.display_mode=3">aufbereitet
                                </button>

                            </div>
                        </div>
                        <div class="d-flex justify-content-start flex-wrap"
                             *ngIf="chunk.isDummy() || chunk.display_mode===0">
                            <div class='byte' *ngFor="let b of chunk.hexBytes">
                                <div class="text-dark bg-secondary">{{ b }}</div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-start flex-wrap"
                             *ngIf="!chunk.isDummy() && chunk.display_mode===1">
                            <div class='byte' *ngFor="let b of chunk.translated">
                                <div class="text-dark bg-secondary">{{ b }}</div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-start flex-wrap"
                             *ngIf="!chunk.isDummy() && chunk.display_mode===2">
                            <div class='block prepulse' *ngFor="let b of chunk.prePulseBytes();let idx=index"
                                 [title]="'Länge: '+chunk.pplen()">
                                V({{ chunk.pplen() }})
                            </div>

                            <div class='byte cmd' *ngFor="let b of chunk.cmdBytes();let idx=index">
                                {{ b }}
                            </div>
                            <div class='byte daten' *ngFor="let b of chunk.dataBytes();let idx=index">
                                {{ b }}
                            </div>
                        </div>
                        <div class="d-flex justify-content-start flex-wrap"
                             *ngIf="!chunk.isDummy() && chunk.display_mode===3">
                            <div class='block prepulse' *ngFor="let b of chunk.prePulseBytes();let idx=index"
                                 [title]="'Länge: '+chunk.pplen()">
                                V({{ chunk.pplen() }})
                            </div>

                            <div class='byte cmd' *ngFor="let b of chunk.cmdBytes();let idx=index">
                                {{ b }}
                            </div>
                            <ng-container [ngSwitch]="chunk.getType()">
                                <ng-container *ngSwitchCase="'endpoint-tankdaten'">
                                    <div class="block daten">Menge {{ fNum(chunk.menge(), 2) }}</div>
                                    <div class="block daten">Preis {{ fNum(chunk.preis(), 2) }}</div>
                                    <div class="block daten" title="Qualität">Q {{ chunk.qualitaet() }}</div>
                                    <div class="block daten" title="Numerateur Zehner">
                                        NZ {{ chunk.numerateur_zehner() }}</div>
                                    <div class="block daten" title="Numerateur Einer">
                                        NE {{ chunk.numerateur_einer() }}</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'endpoint-zapfhahn-ziehen'">
                                    <div class="block daten" title="Qualität">Q {{ chunk.qualitaet() }}</div>
                                    <div class="block daten" title="Fehler-Code">FC {{ chunk.fehler_code() }}</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'endpoint-qualitätsvorwahl'">
                                    <div class="block daten" title="Qualität">Q {{ chunk.qualitaet() }}</div>
                                    <div class="block daten" title="Fehler-Code">FC {{ chunk.fehler_code() }}</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'endpoint-zapfhahn-stecken'">
                                    <div class="block daten" title="Qualität">Q {{ chunk.qualitaet() }}</div>
                                    <div class="block daten" title="Fehler-Code">FC {{ chunk.fehler_code() }}</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'endpoint-summen'">
                                    <div class="block daten">Wert {{ fNum(chunk.menge(), 2) }}</div>
                                    <div class="block daten" title="Qualität & Dimension" *ngIf="chunk.qd()<8">
                                        Menge {{ chunk.qd() + 1 }}</div>
                                    <div class="block daten" title="Qualität & Dimension" *ngIf="chunk.qd()>7">
                                        Preis {{ chunk.qd() - 7 }}</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'endpoint-zwischensummen-autark'">
                                    <div class="block daten">Wert {{ fNum(chunk.menge(), 2) }}</div>
                                    <div class="block daten" title="Qualität">Q {{ chunk.qualitaet() }}</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'master-vorwahl'">
                                    <div class="block daten">SZ
                                        <fa-icon [icon]="['far','square']" *ngIf="!is_bit_set(chunk.steuerzeichen(),3)"
                                                 title="Aktivierung 3-Minuten-Zeitkreis"></fa-icon>
                                        <fa-icon [icon]="['far','square-full']"
                                                 *ngIf="is_bit_set(chunk.steuerzeichen(),3)"
                                                 title="Aktivierung 3-Minuten-Zeitkreis"></fa-icon>

                                        <fa-icon [icon]="['far','square']" *ngIf="!is_bit_set(chunk.steuerzeichen(),2)"
                                                 title="Letzter Vorwahlwert gilt nochmal"></fa-icon>
                                        <fa-icon [icon]="['far','square-full']"
                                                 *ngIf="is_bit_set(chunk.steuerzeichen(),2)"
                                                 title="Letzter Vorwahlwert gilt nochmal"></fa-icon>

                                        <fa-icon [icon]="['far','square']" *ngIf="!is_bit_set(chunk.steuerzeichen(),1)"
                                                 title="Dimension (Betrag/Menge)"></fa-icon>
                                        <fa-icon [icon]="['far','square-full']"
                                                 *ngIf="is_bit_set(chunk.steuerzeichen(),1)"
                                                 title="Dimension (Betrag/Menge)"></fa-icon>
                                        <fa-icon [icon]="['far','square']" *ngIf="!is_bit_set(chunk.steuerzeichen(),0)"
                                                 title="Latenzschaltung"></fa-icon>
                                        <fa-icon [icon]="['far','square-full']"
                                                 *ngIf="is_bit_set(chunk.steuerzeichen(),0)"
                                                 title="Latenzschaltung"></fa-icon>
                                    </div>
                                    <div class="block daten" title="Vorwahl-Menge/Betrag">
                                        {{ fNum(chunk.menge(), 2) }}
                                        <span *ngIf="is_bit_set(chunk.steuerzeichen(),1)"> EUR</span>
                                        <span *ngIf="!is_bit_set(chunk.steuerzeichen(),1)"> l</span>
                                    </div>
                                    <div class="block daten" title="Preis">Preis {{ fNum(chunk.preis(), 3) }}EUR/l
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'master-anforderung-summen'">
                                    <div class="block daten" title="Qualität & Dimension" *ngIf="chunk.qd()<8">
                                        Menge {{ chunk.qd() + 1 }}</div>
                                    <div class="block daten" title="Qualität & Dimension" *ngIf="chunk.qd()>7">
                                        Preis {{ chunk.qd() - 7 }}</div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'master-grundpreisverstellung'">
                                    <div class="block daten" title="Qualität">Q {{ chunk.qualitaet() }}</div>
                                    <div class="block daten" title="Preis">Preis {{ fNum(chunk.preis(), 3) }}EUR/l
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'master-zapfsaeulen-beleuchtung'">
                                    <div class="block daten" title="Status" *ngIf="!chunk.an()">
                                        <i class="far fa-lightbulb"></i> Beleuchtung aus
                                    </div>
                                    <div class="block daten" title="Status" *ngIf="chunk.an()">
                                        <i class="fas fa-lightbulb-on"></i> Beleuchtung an
                                    </div>
                                </ng-container>

                                <ng-container *ngSwitchDefault>
                                    <div class='byte daten' *ngFor="let b of chunk.dataBytes();let idx=index">
                                        {{ b }}
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="endpunkt">
    </div>
</div>
