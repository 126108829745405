import { MEDataTableComponent } from '../data-table.component';

export class MEDataTableEditorAddOnClick {
  public field: string;
  public addon = 0;
  public component: any;
  public dataTable: MEDataTableComponent;

  constructor(
    field: string,
    addon: number,
    component: any,
    dataTable: MEDataTableComponent
  ) {
    this.field = field;
    this.addon = addon;
    this.component = component;
    this.dataTable = dataTable;
  }
}
