<me-data-table
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="devicetypes.asDataTableRows()"
        [selectable]="false"
>
  <me-data-table-column [label]="'ID'" [field]="'id'" type="long"></me-data-table-column>
  <me-data-table-column [label]="'Bezeichnung'" [field]="'label'"></me-data-table-column>

  <me-data-table-table-extra-header>
    <me-pagination
            [total]="devicetypes.total"
            [page]="devicetypes.page"
            [perpage]="devicetypes.size"
            (pageChange)="devicetypes.setPage($event)"
            (pageCountChange)="devicetypes.processPageCountChangedEvent($event)"
            settingsid="router-device-types"
            [settingsService]="benutzerService"
    ></me-pagination>
  </me-data-table-table-extra-header>
</me-data-table>

