import { Component, Input, OnInit } from '@angular/core';
import { TestPing } from '../../common/test-ping';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';

@Component({
  selector: 'app-sys-health-test-ping-system-display',
  templateUrl: './sys-health-test-ping-system-display.component.html',
  styleUrls: ['./sys-health-test-ping-system-display.component.scss']
})
export class SysHealthTestPingSystemDisplayComponent
  extends MELocalizedComponent
  implements OnInit
{
  @Input() testping: TestPing = null;

  constructor() {
    super();
  }

  ngOnInit() {}

  isOk(load: number) {
    return load < 3;
  }

  isWarn(load: number) {
    return load >= 3 && load <= 10;
  }

  isDanger(load: number) {
    return load >= 10;
  }
}
