import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { AbrechnungVaro } from './abrechnung-varo';
import { IAbrechnungVaro } from './iabrechnung-varo';
import { SimpleAbrechnungVaroFilter } from './simple-abrechnung-varo-filter';

export class AbrechnungVaroList extends MERemoteList<AbrechnungVaro> {
  __classname = 'AbrechnungVaroList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IAbrechnungVaro>
  ): AbrechnungVaroList {
    const newList = new AbrechnungVaroList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleAbrechnungVaroFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'periode',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return AbrechnungVaro.fromResult(entity);
  }
}
