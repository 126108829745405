<me-data-table
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="sonden.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
>
    <me-data-table-column [label]="'ID'" [field]="'id'" type="long"></me-data-table-column>
    <me-data-table-column [label]="'Bezeichnung'" [field]="'bezeichnung'"></me-data-table-column>
    <me-data-table-column [label]="'Gerät'"
                          [field]="'device'"
                          [renderer]="deviceRenderer"

    ></me-data-table-column>
    <me-data-table-column [label]="'Port'" field="port"
    ></me-data-table-column>
    <me-data-table-column [label]="'Adresse'" field="adresse"
    ></me-data-table-column>
    <me-data-table-column [label]="'Typ'" field="typ"
    ></me-data-table-column>
    <me-data-table-column [label]="'Serien#'" field="fertigungsnummer"
    ></me-data-table-column>
    <me-data-table-column [label]="'Version'" field="revision"
    ></me-data-table-column>
    <me-data-table-column [label]="'Peilungen'" field="peilungenCount"
    ></me-data-table-column>
    <me-data-table-column [label]="'Letzte Peilung'" field="lastPeilung" type="date"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>


    <me-data-table-rowaction
            label="Bearbeiten"
            title="Geräte-Einstellungen bearbeiten"
            (perform)="editSonde($event)"
            icon="icofont icofont-ui-edit"
    ></me-data-table-rowaction>

    <me-data-table-rowaction
            label="Peilung"
            title="Löst einen Peilvorgang aus"
            (perform)="peilung($event)"
            icon="icofont icofont-gear"
    ></me-data-table-rowaction>

    <me-data-table-rowaction
            [label]="'Peilungen'"
            [title]="'Peilungen anschauen'"
            [icon]="'far fa-database'"
            (perform)="showPeilungen($event)"
    ></me-data-table-rowaction>


    <me-data-table-table-extra-header>
        <me-pagination
                [total]="sonden.total"
                [page]="sonden.page"
                [perpage]="sonden.size"
                (pageChange)="sonden.setPage($event)"
                (pageCountChange)="sonden.processPageCountChangedEvent($event)"
                settingsid="sonden"
                [settingsService]="benutzerService"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>

