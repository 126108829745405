import { MEGenericFilter } from '../../../maennl-commons/filters';
import { TNullableBoolean } from '../../../maennl-commons/tools/types';
import { NetworkAddress } from './network-address.class';
import { TNullableNetworkAddressListDisplayMode } from './network-address.types';

export class NetworkAddressFilter extends MEGenericFilter<NetworkAddress> {
  display_mode: TNullableNetworkAddressListDisplayMode = 'both';
  problems_only: TNullableBoolean = null;

  constructor() {
    super();
  }
}
