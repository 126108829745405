import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import {
  MEGenericModalComponent,
  MELocalizedComponent,
  MERowActionParams,
  MESubscriptionHelper,
  noop,
} from '../../../maennl-commons';
import { Gruppe } from '../commons/gruppe.class';
import { GruppeList } from '../commons/gruppe.list';
import { GruppeService } from '../commons/gruppe.service';
import { GruppeEditorComponent } from '../gruppe-editor/gruppe-editor.component';

@Component({
  selector: 'app-tna-groups',
  templateUrl: './tnagroups.component.html',
  styleUrls: ['./tnagroups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TNAGroupsComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy
{
  @Input() id = '';
  liste = new GruppeList();

  constructor(
    public cd: ChangeDetectorRef,
    public service: GruppeService,
    public benutzerService: BenutzerService,
    public modalService: NgbModal
  ) {
    super();
  }

  ngOnInit(): void {
    this.cd.markForCheck();
    this.onActivate();
  }

  onActivate() {
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe(() => {
        if (this.liste.loading) {
          return;
        }
        this.liste.loading = true;
        this.service
          .list(
            this.liste.size,
            this.liste.calcOffset(),
            this.liste.order,
            this.liste.simpleFilter,
            this.liste.getQuery()
          )
          .subscribe({
            next: l => {
              this.liste.doPopulateFromListResult(l);
              this.cd.markForCheck();
            },
            error: () => {
              this.liste.clear();
              this.cd.markForCheck();
            },
          });
      })
    );
    this.liste.start();
  }

  onDeactivate() {
    this.liste.disableAutoReload();
  }

  ngOnDestroy() {
    this.liste.release();
    MESubscriptionHelper.release(this);
    super.ngOnDestroy();
  }

  addEntry() {
    GruppeEditorComponent.open(
      this.modalService,
      GruppeEditorComponent,
      null,
      MEGenericModalComponent.SIZE_MEDIUM,
      null
    ).then(() => {
      this.liste.start();
    }, noop);
  }

  editEntry(ev: MERowActionParams<Gruppe>) {
    this.service.get(ev.row.data.id).subscribe((e: Gruppe) => {
      if (e !== null && e !== undefined && e.id > 0) {
        GruppeEditorComponent.open(
          this.modalService,
          GruppeEditorComponent,
          e,
          MEGenericModalComponent.SIZE_MEDIUM,
          null
        ).then(() => {
          this.liste.start();
        }, noop);
      }
    });
  }

  removeEntry(ev: MERowActionParams<Gruppe>) {
    this.service.get(ev.row.data.id).subscribe((e: Gruppe) => {
      if (e.id !== null && e.id !== undefined && e.id > 0) {
        this.service.delete(e.id).subscribe(() => {
          this.liste.start();
        });
      }
    });
  }
}
