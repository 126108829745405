import {TransaktionNeedRecalcRN} from "./transaktion_need_recalc_rn.class";
import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {TransaktionNeedRecalcRNFilter} from "./transaktion_need_recalc_rn.filter";
import {ITransaktionNeedRecalcRN} from "./transaktion_need_recalc_rn.interface";

export class TransaktionNeedRecalcRNList extends MERemoteList<TransaktionNeedRecalcRN> {
    __classname:string='TransaktionNeedRecalcRNList';
    __instance:string='';

    static fromListResult(
        res:IMEListResult<ITransaktionNeedRecalcRN>
    ):TransaktionNeedRecalcRNList {
        const newList= new TransaktionNeedRecalcRNList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun:boolean=false, timeout:number=400) {
        super();
        this.simpleFilter= new TransaktionNeedRecalcRNFilter();
        this.initList(autorun,timeout);
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'id',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true
    ) {
        super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    }

    convertEntity(entity: any): any {
        return super.convertEntity(entity);
    }
}