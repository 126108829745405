import { MEGenericEntityFields } from '../../../maennl-commons';
import { Firewall } from './firewall.class';
import { TFirewall, TNullableFirewall } from './firewall.types';

export class FirewallImpl {
  static fromResult(
    entity: TFirewall,
    createIfNull: boolean = false
  ): TNullableFirewall {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Firewall();
      }
      return null;
    }

    const instance = new Firewall();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongFields(['id'])
        .addTextFields(['code', 'name', 'description'])
        .addBooleanFields(['flush_rules']),
      (e: Firewall) => {}
    );

    return instance;
  }
}
