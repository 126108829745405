import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { METabCardComponent } from '../../maennl-commons';
import { METool } from '../../maennl-commons';
import { TabCSDBridgesComponent } from './tab-csdbridges/tab-csdbridges.component';
import { TabCSDClientsComponent } from './tab-csdclients/tab-csdclients.component';

@Component({
  selector: 'app-csdserver',
  templateUrl: './csdserver.component.html',
  styleUrls: ['./csdserver.component.scss']
})
export class CSDServerComponent implements OnInit, AfterViewInit {
  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;
  @ViewChild('dtusTab', { static: false }) ka: TabCSDClientsComponent;

  private _currentTab: TabCSDClientsComponent | TabCSDBridgesComponent = null;

  public get currentTab(): TabCSDClientsComponent | TabCSDBridgesComponent {
    return this._currentTab;
  }

  public set currentTab(
    value: TabCSDClientsComponent | TabCSDBridgesComponent
  ) {
    if (this._currentTab !== value) {
      if (!METool.isNullOrUndefined(this._currentTab)) {
        this._currentTab.onDeactivate();
      }
      this._currentTab = value;
      if (!METool.isNullOrUndefined(this._currentTab)) {
        this._currentTab.onActivate();
      }
    }
  }

  ngAfterViewInit(): void {
    this.currentTab = this.ka;
  }

  constructor() {}

  ngOnInit() {}

  activateTab(s: string) {
    const found = false;

    if (!found) {
      console.log(s);
    }
  }

  deactivateTab(s: string) {
    const found = false;

    if (!found) {
      console.log(s);
    }
  }

  public activate(tab: TabCSDClientsComponent | TabCSDBridgesComponent) {
    console.log(tab);
    this.currentTab = tab;
  }
}
