import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {OpenvpnStatus} from "./openvpn-status.class";
import {IOpenVPNStatus} from "./openvpn-status.interface";

export class OpenVPNStatusList extends MERemoteList<OpenvpnStatus> {
    __classname: string = 'OpenVPNStatusList';
    __instance = '';

    static fromListResult(
        res: IMEListResult<IOpenVPNStatus>
    ): OpenVPNStatusList {
        const newList = new OpenVPNStatusList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun: boolean = false, timeout: number = 400) {
        super();
        this.initList(autorun, timeout);
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'name',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true
    ): void {
        super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    }
}