import { IGenericObject, METool } from '../../../maennl-commons';
import { VehicleTracker } from '../commons/vehicle-tracker';
import { ChangeDetectorRef } from '@angular/core';

interface IOptions extends IGenericObject {
  tracker: VehicleTracker;
  vehicle_id: number;
  tenant: number;
  start_with_zero: boolean;
  keep_offsets: boolean;
  create_vehicle: boolean;
}

export class MoveVehicleOptions {
  private _cd: ChangeDetectorRef = null;

  get cd(): ChangeDetectorRef {
    return this._cd;
  }

  set cd(value: ChangeDetectorRef) {
    this._cd = value;
  }

  mfc() {
    if (this.cd !== null && this.cd !== undefined) {
      this.cd.markForCheck();
    }
  }

  data: IOptions = {
    tracker: null,
    vehicle_id: 0,
    tenant: 0,
    start_with_zero: false,
    keep_offsets: true,
    create_vehicle: false
  };

  clear(): void {
    this.data.tracker = null;
    this.data.vehicle_id = 0;
    this.data.tenant = null;
    this.data.keep_offsets = true;
    this.data.start_with_zero = false;
    this.data.create_vehicle = false;
    this.mfc();
  }

  get vehicle_id(): number {
    return this.data.vehicle_id;
  }

  set vehicle_id(value: number) {
    this.data.vehicle_id = value;
    this.mfc();
  }

  get tracker(): VehicleTracker {
    return this.data.tracker;
  }

  set tracker(value: VehicleTracker) {
    this.data.tracker = value;
    if (!METool.isNullOrUndefined(value)) {
      this.vehicle_id = value.id;
    }
    this.mfc();
  }

  get tenant(): number {
    return this.data.tenant;
  }

  set tenant(v: number) {
    this.data.tenant = v;
    this.mfc();
  }

  get keep_offsets() {
    return this.data.keep_offsets;
  }

  get start_with_zero() {
    return this.data.start_with_zero;
  }

  set keep_offsets(v: boolean) {
    this.data.keep_offsets = v;
    if (v) {
      this.start_with_zero = false;
    }
    this.mfc();
  }

  set start_with_zero(v: boolean) {
    this.data.start_with_zero = v;
    if (v) {
      this.keep_offsets = false;
    }
    this.mfc();
  }

  get create_vehicle() {
    return this.data.create_vehicle;
  }

  set create_vehicle(v: boolean) {
    this.data.create_vehicle = v;
    this.mfc();
  }

  copyFrom(src: MoveVehicleOptions) {
    this.clear();
    Object.keys(src.data).forEach((k) => {
      this.data[k] = src.data[k];
    });
    this.mfc();
  }
}
