import { Component, Input, OnInit } from '@angular/core';
import { SimStatus } from '../../../sys-health/common/sim-status';

@Component({
  selector: 'app-sim-status-display',
  template: `
    <div *ngIf="isValid()" title="Code: {{ value.code }}">
      {{ value.label }}
    </div>
  `,
  styles: [
    `
      :host {
        display: inline-block;
      }
    `
  ]
})
export class MEDTSimStatusDisplayComponent implements OnInit {
  @Input() public value: SimStatus = null;

  constructor() {}

  ngOnInit() {}

  isValid() {
    return this.value !== null && this.value !== undefined;
  }
}
