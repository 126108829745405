import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { MERichError } from '../../maennl-commons/tools';
import { Sim } from '../common/sim';
import { ISim } from '../common/isim';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  MEPubSubService,
  MESubscriptionHelper
} from '../../maennl-commons/services';
import { RouterService } from '../services/router.service';
import { IMEActionResponse } from '../../maennl-commons/network';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sim-editor',
  templateUrl: './sim-editor.component.html',
  styleUrls: ['./sim-editor.component.scss']
})
export class SimEditorComponent
  extends MEGenericModalComponent<Sim>
  implements OnInit, OnDestroy
{
  __classname = 'RouterSimEditorComponent';
  __instance = '';

  entity: Sim = null;

  static editSim(
    simId: number,
    routerService: RouterService,
    modalService: NgbModal
  ) {
    return new Promise((resolve, reject) => {
      routerService.getSim(simId).subscribe(
        (s: ISim) => {
          SimEditorComponent.open(
            modalService,
            SimEditorComponent,
            s,
            MEGenericModalComponent.SIZE_MEDIUM
          ).then(
            (r: any) => {
              resolve(r);
            },
            () => {
              reject();
            }
          );
        },
        (e) => {
          MERichError.throw(
            'Fehler!',
            'Zu dieser SIM-Karte konnten keine Informationen ermittelt werden.'
          );
          reject();
        }
      );
    });
  }

  public initEntity(src: ISim): Sim {
    return Sim.fromResult(src, true);
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public cd: ChangeDetectorRef,
    public routerService: RouterService,
    public toastr: ToastrService
  ) {
    super(activeModal, pubsub);
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    super.ngOnDestroy();
  }

  save() {
    this.routerService.storeSim(this.entity).subscribe(
      (r: IMEActionResponse) => {
        if (r.success) {
          this.ok();
        } else {
          this.toastr.error('Fehler beim Speichern der SIM');
        }
      },
      () => {
        this.toastr.error('Fehler beim Speichern der SIM');
      }
    );
  }
}
