import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { AbrechnungPosition } from './abrechnung-position';
import { IAbrechnungPosition } from './iabrechnung-position';
import { IAbrechnungVaroRechnungsserver } from './iabrechnung-varo-rechnungsserver';

export class AbrechnungVaroRechnungsserver
  extends MEGenericEntity<IAbrechnungVaroRechnungsserver>
  implements IAbrechnungVaroRechnungsserver
{
  rechnungen = 0;
  transaktionen = 0;
  users = 0;
  usersAfter = 0;
  usersBefore = 0;
  abgerechnet: Date = null;
  endDay = 31;
  id = 0;
  jahr = 0;
  lastUpdated: Date = null;
  monat = 1;
  periode = '0-0';
  positionen: AbrechnungPosition[] = [];
  startDay = 1;
  summe = 0;
  rg_nummer_min: number = null;
  rg_nummer_max: number = null;
  rg_datum_min: Date = null;
  rg_datum_max: Date = null;
  rg_eingang_min: Date = null;
  rg_eingang_max: Date = null;

  constructor() {
    super();
  }

  static fromResult(
    entity: IAbrechnungVaroRechnungsserver,
    createIfNull: boolean = false
  ): AbrechnungVaroRechnungsserver {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new AbrechnungVaroRechnungsserver();
      }
      return null;
    }

    const instance = new AbrechnungVaroRechnungsserver();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('periode')
        .addLongField('jahr')
        .addLongField('monat')
        .addDecimalField('summe')
        .addLongField('transaktionen')
        .addLongField('rechnungen')
        .addLongField('users')
        .addLongField('usersBefore')
        .addLongField('usersAfter')
        .addDateField('lastUpdated')
        .addLongField('startDay')
        .addLongField('endDay')
        .addDateField('abgerechnet')
        .addLongField('rg_nummer_min')
        .addLongField('rg_nummer_max')
        .addDateField('rg_datum_min')
        .addDateField('rg_datum_max')
        .addDateField('rg_eingang_min')
        .addDateField('rg_eingang_max')
        .addConverterField(
          'positionen',
          new MEGenericEntityConverterParams<AbrechnungPosition[]>(
            (value: any) => {
              const r: AbrechnungPosition[] = [];

              if (Array.isArray(value)) {
                value.forEach((e) => {
                  const p = AbrechnungPosition.fromResult(
                    e as IAbrechnungPosition
                  );
                  if (p !== null) {
                    r.push(p);
                  }
                });
              }
              return r;
            }
          )
        )
    );
    return instance;
  }
}
