<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'address-book']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Kontakte"
></me-area-heading>

<me-card>


    <me-data-table
            *ngIf="kontakte!==null && kontakte!==undefined"
            [rowActionsAsButtons]="true"
            [tableActionsAsButtons]="true"
            [data]="kontakte.asDataTableRows()"
            [selectable]="false"
            actionsicon="icofont icofont-navigation-menu"
            #contactsTable
            [extraRowClasses]="rowClassGetter"
    >
        <me-data-table-column [label]="'ID'" [field]="'nummerId'"></me-data-table-column>
        <me-data-table-column [label]="'Anzeigetext'" [field]="'displayText'"></me-data-table-column>
        <me-data-table-column [label]="'Kundennummer'" [field]="'kundennummer'"></me-data-table-column>
        <me-data-table-column [label]="'Firma'" [field]="'firma'"></me-data-table-column>
        <me-data-table-column [label]="'Vorname'" [field]="'vorname'"></me-data-table-column>
        <me-data-table-column [label]="'Nachname'" [field]="'nachname'"></me-data-table-column>
        <me-data-table-column [label]="'Info'" [field]="'info'"></me-data-table-column>
        <me-data-table-column [label]="'Rufnummer'" [field]="'nummer'"></me-data-table-column>
        <me-data-table-column [label]="'intern?'" [field]="'isIntern'" type="boolean"></me-data-table-column>


        <me-data-table-action
                [label]="_('Kontakt hinzufügen')"
                [icon]="'far fa-plus'"
                (perform)="addContact()"
        ></me-data-table-action>

        <me-data-table-rowaction
                [label]="_('Kontakt bearbeiten')"
                [icon]="'far fa-edit'"
                (perform)="editContact($event);"
        ></me-data-table-rowaction>

        <me-data-table-table-extra-header>
            <me-pagination
                    [total]="kontakte.total"
                    [page]="kontakte.page"
                    [perpage]="kontakte.size"
                    (pageChange)="kontakte.setPage($event)"
                    (pageCountChange)="kontakte.processPageCountChangedEvent($event)"
                    settingsid="contacts"
                    [settingsService]="benutzerService"
                    [showSearch]="true"
                    (queryChange)="kontakte.processQueryChangedEvent($event)"
            ></me-pagination>
        </me-data-table-table-extra-header>
    </me-data-table>


</me-card>
