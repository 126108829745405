import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { METabCardComponent } from '../../maennl-commons/metab-card';
import { SysHealthBoxlisteTabComponent } from '../controls/sys-health-boxliste-tab/sys-health-boxliste-tab.component';
import { SysHealthBoxlisteV4TabComponent } from '../controls/sys-health-boxliste-v4-tab/sys-health-boxliste-v4-tab.component';

@Component({
  selector: 'app-sys-health-boxliste',
  templateUrl: './sys-health-boxliste.component.html',
  styleUrls: ['./sys-health-boxliste.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SysHealthBoxlisteComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'SysHealthBoxlisteComponent';
  __instance = '';
  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;
  @ViewChild(SysHealthBoxlisteTabComponent, { static: false })
  boxlisteTabComponent: SysHealthBoxlisteTabComponent;
  @ViewChild(SysHealthBoxlisteV4TabComponent, { static: false })
  boxlisteV4TabComponent: SysHealthBoxlisteV4TabComponent;

  constructor(public cd: ChangeDetectorRef, public route: ActivatedRoute) {}

  ngOnInit() {
    if (this.tabs !== null && this.tabs !== undefined) {
      this.activateTab(this.tabs.currentPane.id);
    }
  }

  public activateTab(id: string) {
    if (id === 'tab-boxliste') {
      if (
        this.boxlisteTabComponent !== null &&
        this.boxlisteTabComponent !== undefined
      ) {
        this.boxlisteTabComponent.onActivate();
      }
    }
    if (id === 'tab-boxliste-v4') {
      if (
        this.boxlisteV4TabComponent !== null &&
        this.boxlisteV4TabComponent !== undefined
      ) {
        this.boxlisteV4TabComponent.onActivate();
      }
    }
  }

  public deactivateTab(id: string) {
    if (id === 'tab-boxliste') {
      if (
        this.boxlisteTabComponent !== null &&
        this.boxlisteTabComponent !== undefined
      ) {
        this.boxlisteTabComponent.onDeactivate();
      }
    }
    if (id === 'tab-boxliste-v4') {
      if (
        this.boxlisteV4TabComponent !== null &&
        this.boxlisteV4TabComponent !== undefined
      ) {
        this.boxlisteV4TabComponent.onDeactivate();
      }
    }
  }

  public ngAfterViewInit(): void {
    if (this.tabs !== null && this.tabs !== undefined) {
      this.activateTab(this.tabs.currentPane.id);
    }
  }

  public ngOnDestroy(): void {
    if (this.tabs !== null && this.tabs !== undefined) {
      this.deactivateTab(this.tabs.currentPane.id);
    }
  }
}
