<div class="modal-header">
  <h4 class="modal-title">
    Trackermitschnitt {{ entity.tracker.tracker_identification }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="tablecontainer" #tableContainer>
    <me-data-table
      [rowActionsAsButtons]="true"
      [tableActionsAsButtons]="true"
      [data]="packets.asDataTableRows()"
      [selectable]="false">
      <ng-template #zp let-p="item" let-row="row">
        <div>
          {{ fDate(p, "YYYY-MM-DD HH:mm:ss", true) }}
        </div>
      </ng-template>

      <me-data-table-column
        [label]="'Zeitpunkt'"
        field="zeitpunkt"
        [template]="zp"></me-data-table-column>
      <me-data-table-column
        [label]="'IMEI'"
        [field]="'imei'"></me-data-table-column>
      <me-data-table-column
        [label]="'Adresse'"
        [field]="'ip'"></me-data-table-column>
      <ng-template #entry let-p="item" let-row="row">
        <app-tracker-mitschnitt-entry-display
          [entity]="row.data"></app-tracker-mitschnitt-entry-display>
      </ng-template>
      <me-data-table-column
        [label]="'Daten'"
        [field]="'trace_data_obj'"
        [template]="entry"></me-data-table-column>
      <me-data-table-table-extra-header>
        <me-pagination
          [total]="packets.total"
          [page]="packets.page"
          [perpage]="packets.size"
          (pageChange)="packets.setPage($event)"
          (pageCountChange)="packets.processPageCountChangedEvent($event)"
          (queryChange)="packets.processQueryChangedEvent($event)"
          settingsid="tracker-mitschnitt-details"
          [settingsService]="benutzerService"
          [showSearch]="true"></me-pagination>
      </me-data-table-table-extra-header>
    </me-data-table>
  </div>
  <div class="row">
    <div class="col-6"></div>
    <div class="col-6 text-right">
      <me-pagination
        [total]="packets.total"
        [page]="packets.page"
        [perpage]="packets.size"
        (pageChange)="packets.setPage($event)"
        (pageCountChange)="packets.processPageCountChangedEvent($event)"
        settingsid="tracker-mitschnitt-details"
        [settingsService]="benutzerService"></me-pagination>
    </div>
  </div>
</div>
<div class="modal-footer d-flex justify-content-between">
  <div>
    <button type="button" class="btn btn-secondary" (click)="cancel()">
      <i class="icofont icofont-close"></i> Schließen
    </button>
  </div>
</div>
