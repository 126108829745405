import { Component, Input } from '@angular/core';
import { TestPing } from '../../common/test-ping';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';

@Component({
  selector: 'app-sys-health-test-ping-device-display',
  templateUrl: './sys-health-test-ping-device-display.component.html',
  styleUrls: ['./sys-health-test-ping-device-display.component.scss']
})
export class SysHealthTestPingDeviceDisplayComponent extends MELocalizedComponent {
  @Input() testping: TestPing = null;

  constructor() {
    super();
  }

  isValid() {
    return this.testping !== null && this.testping !== undefined;
  }
}
