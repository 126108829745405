<me-data-table
        *ngIf="leases!==null && leases!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="leases.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        (onSortChanged)="leases.processSortChangedEvent($event)"
        #leasesTable
>

    <me-data-table-column
            field="ip"
            [label]="_('IP-Adresse')"
    ></me-data-table-column>
    <me-data-table-column
            field="mac"
            [label]="_('MAC-Adresse')"
    ></me-data-table-column>
    <me-data-table-column
            field="hostname"
            [label]="_('Hostname (Client)')"
    ></me-data-table-column>
    <me-data-table-column
            field="hostnameDns"
            [label]="_('Hostname (DNS)')"
    ></me-data-table-column>
    <me-data-table-column
            field="start"
            type="Date"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
            [label]="_('Zugeteilt Zeitpunkt')"
    ></me-data-table-column>
    <me-data-table-column
            field="ends"
            type="Date"

            [label]="_('Ablauf Zeitpunkt')"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>


    <me-data-table-table-extra-header>
        <me-pagination
                [total]="leases.total"
                [page]="leases.page"
                [perpage]="leases.size"
                (pageChange)="leases.setPage($event)"
                (pageCountChange)="leases.processPageCountChangedEvent($event)"
                [showSearch]="false"
                (queryChange)="leases.processQueryChangedEvent($event)"
                [pagecounts]="[10, 25, 50, 100,500,1000]"
                settingsid="syshealth-dhcp-leases"
                [settingsService]="benutzerService"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
