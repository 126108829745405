import {MEGenericEntity} from "../../../maennl-commons";
import {ITAJobs} from "./ta-jobs.interface";
import {TAPIDate, TNullableBoolean, TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";

export class TAJobs
    extends MEGenericEntity<ITAJobs>
    implements ITAJobs {

    id: TNullableNumber=null;
    tenant: TNullableNumber=null;
    terminal: TNullableNumber=null;
    kind: TNullableNumber=null;
    options_text: TNullableString=null;
    date_created: TAPIDate=null;
    next_try: TAPIDate=null;
    tries: TNullableNumber=null;
    prio: TNullableNumber=null;
    job_started: TAPIDate=null;
    progress: TNullableNumber=null;
    info: TNullableString=null;
    options_hash: TNullableString=null;
    _tenant_info: TNullableString=null;
    _terminal_display_text: TNullableString=null;
    _station_display_text: TNullableString=null;
    _in_progress: TNullableBoolean=null;
}