import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { TMERowActionAvailableCheckCallback } from '../../maennl-commons/data-table/actions/types';
import { AbrechnungVaroList } from '../common/abrechnung-varo-list';
import {
  MEDataTableComponent,
  MEDataTableRow,
  MERowActionParams,
  TMEDTGetExtraRowClassesCallback
} from '../../maennl-commons/data-table';
import { ToastrService } from 'ngx-toastr';
import { AbrechnungVaroRechnungsserver } from '../common/abrechnung-varo-rechnungsserver';
import { AbrechnungVaroService } from '../services/abrechnung-varo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MESubscriptionHelper } from '../../maennl-commons/services';
import { MERichError, noop } from '../../maennl-commons/tools';
import { AbrechnungVaro } from '../common/abrechnung-varo';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { IAbrechnungVaro } from '../common/iabrechnung-varo';
import { AbrechnungVaroDetailsComponent } from '../abrechnung-varo-details/abrechnung-varo-details.component';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-tab-abrechnung-varo',
  templateUrl: './tab-abrechnung-varo.component.html',
  styleUrls: ['./tab-abrechnung-varo.component.scss']
})
export class TabAbrechnungVaroComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'TabAbrechnungVaroComponent';
  __instance = '';
  tabActive = false;

  public abrechnungen: AbrechnungVaroList = new AbrechnungVaroList();
  @ViewChild(MEDataTableComponent, { static: false }) dt = null;

  recalcActionAvailable: TMERowActionAvailableCheckCallback<AbrechnungVaro> = (
    row: MEDataTableRow<AbrechnungVaro>
  ) => {
    if (row === null || row === undefined) {
      return false;
    }
    if (row.data === null || row.data === undefined) {
      return false;
    }

    return row.data.abgerechnet === null || row.data.abgerechnet === undefined;
  };

  constructor(
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    public abrechnungService: AbrechnungVaroService,
    public modalService: NgbModal,
    public benutzerService: BenutzerService
  ) {}

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.abrechnungen.onUpdateRequired.subscribe(
        (list: AbrechnungVaroList) => {
          this.abrechnungService
            .listAbrechnungen(
              list.size,
              list.calcOffset(),
              list.order,
              list.simpleFilter
            )
            .subscribe(
              (l) => {
                list.populateFromListResult(l);
                this.cd.markForCheck();
              },
              (e) => {
                console.log(e);
                MERichError.throw(
                  'Fehler beim Datenabruf',
                  'Die Liste der verfügbaren Varo-Abrechnungen konnte nicht abgerufen werden.'
                );
              }
            );
        },
        undefined
      )
    );
  }

  ngAfterViewInit(): void {
    if (this.dt !== null) {
      this.dt.extraRowClasses = this.diensteRowClassGetter;
    }
  }

  diensteRowClassGetter: TMEDTGetExtraRowClassesCallback<AbrechnungVaro> = (
    row: MEDataTableRow<AbrechnungVaro>,
    idx
  ) => {
    return '';
  };

  onActivate() {
    console.log('Varo-Abrechnungen aktiviert!');
    this.tabActive = true;
    this.abrechnungen.start();
  }

  onDeactivate() {
    console.log('Varo-Abrechnungen deaktiviert!');
    this.tabActive = false;
  }

  recalcAbrechnungVaro($event: MERowActionParams<AbrechnungVaro>) {
    this.abrechnungService.recalcAbrechnung($event.row.data).subscribe(
      () => {
        this.toastr.success(
          'Die Abrechnung für die Periode ' +
            $event.row.data.periode +
            ' wird nun neu durchgerechnet.'
        );
        setTimeout(() => {
          this.abrechnungen.doListUpdate();
        }, 1000);
        setTimeout(() => {
          this.abrechnungen.doListUpdate();
        }, 5000);
        this.abrechnungen.doListUpdate();
      },
      () => {
        MERichError.throw(
          'Fehler beim Datenabruf',
          'Die Neuberechnung der Abrechnung konnte nicht gestartet werden.'
        );
      }
    );
  }

  displayAbrechnungVaro($event: MERowActionParams<AbrechnungVaro>) {
    this.abrechnungService
      .getAbrechnung($event.row.data.id)
      .subscribe((a: IAbrechnungVaro) => {
        const abrechnung = AbrechnungVaro.fromResult(a);
        AbrechnungVaroDetailsComponent.open(
          this.modalService,
          AbrechnungVaroDetailsComponent,
          abrechnung,
          MEGenericModalComponent.SIZE_MEDIUM
        ).then(noop, noop);
      });
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.abrechnungen.release();
  }

  public markAbrechnungCleared($event: MERowActionParams<AbrechnungVaro>) {
    this.abrechnungService.markAbrechnungCleared($event.row.data).subscribe(
      () => {
        this.toastr.success(
          'Die Abrechnung für die Periode ' +
            $event.row.data.periode +
            ' wurde als abrechnet gekennzeichnet.'
        );
        setTimeout(() => {
          this.abrechnungen.doListUpdate();
        }, 1000);
        this.abrechnungen.doListUpdate();
      },
      () => {
        MERichError.throw(
          'Fehler beim Datenabruf',
          'Die Abrechnung konnte nicht gekennzeichnet werden.'
        );
      }
    );
  }
}
