import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { AbrechnungTKTankstellen } from './abrechnung-tktankstellen';
import { IAbrechnungTKTankstellen } from './iabrechnung-tktankstellen';
import { SimpleAbrechnungTKTankstellenFilter } from './simple-abrechnung-tktankstellen-filter';

export class AbrechnungTKTankstellenList extends MERemoteList<AbrechnungTKTankstellen> {
  __classname = 'AbrechnungTKTankstellenList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IAbrechnungTKTankstellen>
  ): AbrechnungTKTankstellenList {
    const newList = new AbrechnungTKTankstellenList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleAbrechnungTKTankstellenFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'periode',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return AbrechnungTKTankstellen.fromResult(entity);
  }
}
