import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../../maennl-commons';
import { IPreisSendung } from './ipreis-sendung';
import { Preistransfer } from './preistransfer';

export class PreisSendung
  extends MEGenericEntity<IPreisSendung>
  implements IPreisSendung
{
  datum_empfang: Date = null;
  datum_gueltig: Date = null;
  datum_import: Date = null;
  datum_sendung: Date = null;
  datum_transfer: Date = null;
  id = 0;
  nebenstelle_name = '';
  nebenstelle_nummer = 0;
  produkt_name_01 = '';
  produkt_name_02 = '';
  produkt_name_03 = '';
  produkt_name_04 = '';
  produkt_name_05 = '';
  produkt_name_06 = '';
  produkt_name_07 = '';
  produkt_name_08 = '';
  produkt_name_09 = '';
  produkt_name_10 = '';
  produkt_pos_01 = 0;
  produkt_pos_02 = 0;
  produkt_pos_03 = 0;
  produkt_pos_04 = 0;
  produkt_pos_05 = 0;
  produkt_pos_06 = 0;
  produkt_pos_07 = 0;
  produkt_pos_08 = 0;
  produkt_pos_09 = 0;
  produkt_pos_10 = 0;
  produkt_preis_01 = 0;
  produkt_preis_02 = 0;
  produkt_preis_03 = 0;
  produkt_preis_04 = 0;
  produkt_preis_05 = 0;
  produkt_preis_06 = 0;
  produkt_preis_07 = 0;
  produkt_preis_08 = 0;
  produkt_preis_09 = 0;
  produkt_preis_10 = 0;
  transfers: Preistransfer[] = null;
  transfers_pending = true;

  static fromResult(
    entity: IPreisSendung,
    createIfNull: boolean = false
  ): PreisSendung {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new PreisSendung();
      }
      return null;
    }

    const instance = new PreisSendung();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('nebenstelle_name')
        .addLongField('nebenstelle_nummer')
        .addTextField('produkt_name_01')
        .addLongField('produkt_pos_01')
        .addLongField('produkt_preis_01')

        .addTextField('produkt_name_02')
        .addLongField('produkt_pos_02')
        .addLongField('produkt_preis_02')

        .addTextField('produkt_name_03')
        .addLongField('produkt_pos_03')
        .addLongField('produkt_preis_03')

        .addTextField('produkt_name_04')
        .addLongField('produkt_pos_04')
        .addLongField('produkt_preis_04')

        .addTextField('produkt_name_05')
        .addLongField('produkt_pos_05')
        .addLongField('produkt_preis_05')

        .addTextField('produkt_name_06')
        .addLongField('produkt_pos_06')
        .addLongField('produkt_preis_06')

        .addTextField('produkt_name_07')
        .addLongField('produkt_pos_07')
        .addLongField('produkt_preis_07')

        .addTextField('produkt_name_08')
        .addLongField('produkt_pos_08')
        .addLongField('produkt_preis_08')

        .addTextField('produkt_name_09')
        .addLongField('produkt_pos_09')
        .addLongField('produkt_preis_09')

        .addTextField('produkt_name_10')
        .addLongField('produkt_pos_10')
        .addLongField('produkt_preis_10')

        .addDateField('datum_gueltig')
        .addDateField('datum_sendung')
        .addDateField('datum_empfang')
        .addDateField('datum_import')
        .addDateField('datum_transfer')
    );
    return instance;
  }

  has_pending_transfers() {
    if (this.transfers_pending === true) {
      return true;
    }
    if (this.transfers === null || this.transfers === undefined) {
      return false;
    }
    if (this.transfers.length < 1) {
      return false;
    }
    let ret = false;
    this.transfers.forEach((v) => {
      if (v.zeitpunkt === null || v.zeitpunkt === undefined) {
        ret = true;
      }
    });
    return ret;
  }
}
