import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {IMEActionResponse, IMEListResult, MEActionResponse, MEApiTool, MEResultMetaSort} from "../../../maennl-commons";
import {IMESimpleFilter} from "../../../maennl-commons/filters";
import {Terminal} from "./terminal.class";
import {ITerminal} from "./terminal.interface";
import {Observable, of} from "rxjs";
import {map, take} from "rxjs/operators";
import {TNullableNumber} from "../../../maennl-commons/tools/types";
import {TerminalImpl} from "./terminal.impl";

@Injectable({
    providedIn: 'root'
})
export class TerminalService {
    constructor(public http: HttpClient, public loginService: BenutzerService) {
    }

    public list(
        max = 50,
        offset = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<ITerminal> = null,
        queryString = ''
    ): Observable<IMEListResult<ITerminal>> {
        const uri = MEApiTool.buildListURL(
            'api/webfuel/terminal',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<ITerminal>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public store(entity: Terminal): Observable<MEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            'api/webfuel/terminal',
            entity.id
        );

        if (entity.id===null||entity.id===undefined||entity.id<1) {
            return of(MEActionResponse.error('id is null or undefined'));
        }

        return this.http
            .put<IMEActionResponse>(
                uri,
                entity,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }

    public assignTerminals(): Observable<MEActionResponse> {
        const uri = MEApiTool.buildActionURL(
            'api/webfuel/terminal',
            null,
            '_assignTerminals'
        );
        return this.http
            .post<IMEActionResponse>(
                uri,
                null,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }

    public get(id: TNullableNumber): Observable<Terminal> {
        if (id === null || id === undefined || id < 1) {
            return of(null);
        }
        const uri = MEApiTool.buildEntityURL(
            'api/webfuel/terminal',
            id
        );

        return this.http
            .get<ITerminal>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return TerminalImpl.fromResult(r);
                }),
                take(1)
            );
    }

    public delete(id: TNullableNumber): Observable<MEActionResponse> {
        if (id === null || id === undefined || id < 1) {
            return of(MEActionResponse.error('id is null or undefined'));
        }
        const uri = MEApiTool.buildEntityURL(
            'api/webfuel/terminal',
            id
        );

        return this.http
            .delete<IMEActionResponse>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }

    public resetLastFailure(id: TNullableNumber): Observable<MEActionResponse> {
        if (id === null || id === undefined || id < 1) {
            return of(MEActionResponse.error('id is null or undefined'));
        }

        const uri = MEApiTool.buildActionURL(
            'api/webfuel/terminal/',
            id,
            '_resetLastFailure'
        );

        return this.http
            .post<IMEActionResponse>(
                uri,
                null,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }
}