import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IBoxlisteListEntry } from './iboxliste-list-entry';

export class BoxlisteListEntry
  extends MEGenericEntity<IBoxlisteListEntry>
  implements IBoxlisteListEntry
{
  public __classname__ = 'BoxlisteListEntry';
  public _type = 'SysHealthBoxlisteListEntry';

  public automatStart: Date = null;
  public automatVersion: string = null;
  public boxModemTyp: string = null;
  public boxModemVersion: string = null;
  public boxName: string = null;
  public boxNummer: number = null;
  public boxUntersystemId: number = null;
  public boxVersion: string = null;
  public datumSperrliste: Date = null;
  public datumSperrlisteImport: Date = null;
  public id: number = null;
  public versionBox: string = null;

  static fromResult(
    entity: IBoxlisteListEntry,
    createIfNull: boolean = false
  ): BoxlisteListEntry {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new BoxlisteListEntry();
      }
      return null;
    }
    const instance = new BoxlisteListEntry();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addDateField('automatStart')
        .addTextField('automatVersion')
        .addTextField('boxModemTyp')
        .addTextField('boxModemVersion')
        .addTextField('boxName')
        .addLongField('boxNummer')
        .addLongField('boxUntersystemId')
        .addStringField('boxVersion')
        .addDateField('datumSperrliste')
        .addDateField('datumSperrlisteImport')
        .addStringField('versionBox'),
      (e: BoxlisteListEntry) => {
        e.__classname__ = 'BoxlisteListEntry';
        e._type = 'SysHealthBoxlisteListEntry';
      }
    );

    return instance;
  }

  constructor() {
    super();
  }
}
