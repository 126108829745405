<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'server']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="VPN1"
></me-area-heading>

<me-tab-card
    #rootTabCard
>

    <me-pane id="tab-openvpn-status"
             title="OpenVPN Status"
             (activated)="activate(vpn1Tab)"
    >
        <app-tab-vpn1 #vpn1Tab></app-tab-vpn1>
    </me-pane>
    <me-pane id="tab-ipsec-status"
             title="IPsec Status"
             (activated)="activate(ipsecTab)"
    >
        <app-tab-ipsec #ipsecTab></app-tab-ipsec>
    </me-pane>
    <me-pane id="tab-wireguard-status"
             title="WireGuard Status"
             (activated)="activate(wireguardTab)"
             >
        <app-tab-wireguard #wireguardTab></app-tab-wireguard>
    </me-pane>
</me-tab-card>