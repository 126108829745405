<div class="modal-header">
    <h4 class="modal-title" *ngIf="isAdd()">Eintrag anlegen</h4>
    <h4 class="modal-title" *ngIf="isEdit()">Eintrag bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <me-text-field  [label]="'URL'" [(value)]="entity.hostname"></me-text-field>
    <app-tenant-selector [(value_id)]="entity.tenant"></app-tenant-selector>
    <me-boolean-field [label]="'aktiv?'" [(value)]="entity.enabled"></me-boolean-field>
    <me-boolean-field [label]="'API-Host?'" [(value)]="entity.is_api_host"></me-boolean-field>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i> Daten speichern
    </button>
</div>