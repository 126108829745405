import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MERichError, noop } from '../../maennl-commons/tools';
import { DeviceList } from '../common/device-list';
import { MEDTDeviceTypeRendererComponent } from '../controls/medtdevice-type-renderer/medtdevice-type-renderer.component';
import { MEDTSimRendererComponent } from '../controls/medtsim-renderer/medtsim-renderer.component';
import { RouterService } from '../services/router.service';
import { MEDTDeviceOnlineRendererComponent } from '../controls/medtdevice-online-renderer/medtdevice-online-renderer.component';
import { MEDTDeviceDiskUsageDisplayRendererComponent } from '../controls/medtdevice-disk-usage-display-renderer/medtdevice-disk-usage-display-renderer.component';
import { MERowActionParams } from '../../maennl-commons/data-table/actions';
import { Device } from '../common/device';
import { MEDTDeviceSysStatusRendererComponent } from '../controls/medtdevice-sys-status-renderer/medtdevice-sys-status-renderer.component';
import { ToastrService } from 'ngx-toastr';
import { MESubscriptionHelper } from '../../maennl-commons/services';
import { take } from 'rxjs/operators';
import { TMERowActionAvailableCheckCallback } from '../../maennl-commons/data-table/actions/types';
import { RouterRebootConfirmDialogComponent } from '../router-reboot-confirm-dialog/router-reboot-confirm-dialog.component';
import { RebootDialogOptions } from '../router-reboot-confirm-dialog/reboot-dialog-options';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { MEKitchenTimer } from '../../maennl-commons/timers';
import { MEDataTableRow } from '../../maennl-commons/data-table/rows';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-tab-devices',
  templateUrl: './tab-devices.component.html',
  styleUrls: ['./tab-devices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabDevicesComponent implements OnInit, OnDestroy {
  __classname = 'TabDevicesComponent';
  __instance = '';

  public deviceTypeRenderer = MEDTDeviceTypeRendererComponent;
  public simRenderer = MEDTSimRendererComponent;
  public onlineRenderer = MEDTDeviceOnlineRendererComponent;
  public diskUsageRenderer = MEDTDeviceDiskUsageDisplayRendererComponent;
  public sysStatusRenderer = MEDTDeviceSysStatusRendererComponent;

  public devices: DeviceList = new DeviceList();
  private tabActive = false;

  rebootActionAvailable: TMERowActionAvailableCheckCallback<Device> = (
    row: MEDataTableRow<Device>
  ) => {
    return this.routerService.canReboot(row.data);
  };

  constructor(
    public routerService: RouterService,
    public toastr: ToastrService,
    public modalService: NgbModal,
    public cd: ChangeDetectorRef,
    public benutzerService: BenutzerService
  ) {}

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.devices.onUpdateRequired.subscribe((list: DeviceList) => {
        this.routerService
          .listDevices(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.getQuery()
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
            },
            (e) => {
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste der verfügbaren Geräte konnte nicht geladen werden'
              );
            }
          );
      }, undefined)
    );

    if (this.tabActive) {
      this.devices.start();
    }
  }

  editRouter($event: MERowActionParams<Device>) {
    console.log($event);
  }

  rebootRouter($event: MERowActionParams<Device>) {
    RouterRebootConfirmDialogComponent.open(
      this.modalService,
      RouterRebootConfirmDialogComponent,
      new RebootDialogOptions($event.row.data, false),
      MEGenericModalComponent.SIZE_SMALL
    ).then(
      (r: RebootDialogOptions) => {
        this.toastr
          .info(
            'Es wird versucht, das Gerät ' +
              r.device.bezeichnung +
              ' neuzustarten',
            'Löse Reboot aus...',
            {
              timeOut: 15000
            }
          )
          .onShown.pipe(take(1))
          .subscribe(() => {
            this.routerService.rebootDevice(r.device, r.hardReset).subscribe(
              (re) => {
                this.devices.reload();
                if (re.success) {
                  this.toastr.success(
                    'Der Reboot des Gerätes wurde eingeleitet'
                  );
                } else {
                  MERichError.throw(
                    'Fehler bei der Ausführung',
                    'Der Reboot des Gerätes konnte nicht eingeleitet werden'
                  );
                }
              },
              (e) => {
                MERichError.throw(
                  'Fehler bei der Ausführung',
                  'Der Reboot des Gerätes konnte nicht eingeleitet werden'
                );
              }
            );
          });
      },
      () => {
        noop();
      }
    );
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.devices.release();
  }

  tabActivated() {
    this.tabActive = true;
    this.devices.start();
  }

  tabDeactivated() {
    this.tabActive = false;
  }

  reIndexAll() {
    this.routerService.reindexall().subscribe(() => {
      this.toastr.success('Der Indizierungsvorgang wurde gestartet');
    }, noop);
  }

  importTS() {
    this.routerService.importTS().subscribe(() => {
      this.toastr.success('Der Import-Vorgang wurde gestartet.');
    }, noop);
  }
}
