import { MEGenericFilter } from '../../maennl-commons/filters';
import { IContactListEntry } from './icontact-list-entry';
import * as moment from 'moment';

export class SimpleContactListEntryFilter extends MEGenericFilter<IContactListEntry> {
  isIntern: boolean = null;

  public encodeField(fieldname: string): string {
    if (fieldname === 'isIntern') {
      if (this.isIntern !== null) {
        if (this.isIntern) {
          return 'filter.isIntern=true';
        } else {
          return 'filter.isIntern=false';
        }
      }
    }
    return super.encodeField(fieldname);
  }

  public hasFieldValue(key: string): boolean {
    if (key === 'isIntern') {
      return this.isIntern !== null;
    }
    return super.hasFieldValue(key);
  }
}
