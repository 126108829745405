<me-data-table
        *ngIf="liste!==null && liste!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="liste.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        (sortChanged)="liste.processSortChangedEvent($event)"
        #clientsTable
>

    <ng-template #mandant let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <div class="d-flex justify-content-between align-items-center">
                <div class="flex-fill" style="font-size: 1.2rem">
                    {{ t.tenant_info }}
                </div>
                <div>
                    <span class="badge bg-secondary text-dark">{{ t.tenant_projekt }}</span>
                </div>
            </div>
        </ng-container>
    </ng-template>

    <me-data-table-column [label]="'Mandant'"
                          [field]="'tenant'"
                          [sortable]="false"
                          [template]="mandant"
    ></me-data-table-column>

    <ng-template #standort let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <div class="d-flex justify-content-between align-items-center">
                <div class="flex-fill" style="font-size: 1.2rem">
                    {{ t.station_display_text }}
                </div>
                <div>
                    <span class="badge bg-secondary text-dark">{{ t.station_nummer }}</span>
                </div>
            </div>
            <div class="bg-gray-light p-2 small" title="Terminal/Tankautomat"
                 *ngIf="t.terminal_id!==null && t.terminal_id!==undefined && t.terminal_id>0">
                <div class="d-flex justify-content-between align-items-center small">
                    <div class="flex-fill">
                        {{ t.terminal_display_text }}
                    </div>
                    <div>
                        <span class="badge bg-secondary text-dark">{{ t.terminal_nummer }}</span>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center small"
                >
                    <div title="TA-Adresse">
                        {{ t.terminal_address }}
                    </div>
                    <div title="Geräte-Adresse">
                        DA: {{ t.terminal_dev_addr }}
                    </div>
                    <div title="TA-Kundennummer">
                        KD: {{ t.terminal_customer }}
                    </div>
                    <div title="TA-Typ">
                        {{ t.terminal_type_display_text }}
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-template>


    <me-data-table-column [label]="'Standort'"
                          [field]="'station'"
                          [sortable]="false"
                          [template]="standort"
    ></me-data-table-column>
    <ng-template #reader let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <div class="d-flex justify-content-between align-items-center">
                <div class="flex-fill" style="font-size: 1.2rem">
                    {{ t.display_text }}
                </div>
                <div>
                    <span class="badge bg-secondary text-dark">{{ t.nummer }}</span>
                </div>
            </div>
            <div class="bg-gray-light p-2" title="MAC-Adresse">
                <div class="d-flex justify-content-between align-items-center small">
                    <div class="flex-fill">
                        <ng-container *ngIf="t.mac_address!==null && t.mac_address!==undefined && t.mac_address!==''">
                            {{ t.mac_address }}
                        </ng-container>
                    </div>
                    <div class="ms-2 ml-2" title="Datenbank-ID">
                        # {{ t.id }}
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-template>
    <me-data-table-column [label]="'Reader'"
                          [field]="'mac_address'"
                          [sortable]="false"
                          [template]="reader"
    ></me-data-table-column>
    <ng-template #datumFR let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <div *ngIf="t.first_reading!==null && t.first_reading!==undefined" class="text-nowrap"
                 style="font-size: 1.2rem">
                {{ fDate(t.first_reading, "yyyy-MM-DD HH:mm:ss", true) }}
            </div>
        </ng-container>
    </ng-template>

    <me-data-table-column [label]="'Erste Lesung'"
                          [field]="'first_reading'"
                          [sortable]="false"
                          [template]="datumFR"
    ></me-data-table-column>
    <ng-template #datumLR let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <div *ngIf="t.last_reading!==null && t.last_reading!==undefined" class="text-nowrap"
                 style="font-size: 1.2rem">
                {{ fDate(t.last_reading, "yyyy-MM-DD HH:mm:ss", true) }}
            </div>
        </ng-container>
    </ng-template>
    <me-data-table-column [label]="'Letzte Lesung'"
                          [field]="'last_reading'"
                          [sortable]="false"
                          [template]="datumLR"
    ></me-data-table-column>
    <ng-template #nummer let-p="item" let-row="row">
        <div *ngIf="p!==null && p!==undefined && p!==0" class="text-end text-right text-nowrap"
             style="font-size: 1.2rem">
            {{ fNum(p, "1.0-0") }}
        </div>
    </ng-template>

    <me-data-table-column [label]="'Anzahl Lesungen'"
                          [field]="'num_readings'"
                          [sortable]="false"
                          [template]="nummer"
    ></me-data-table-column>

    <ng-template #spannung let-p="item" let-row="row">
        <ng-container *ngFor="let t of typed(row.data)">
            <ng-container *ngIf="t.letzte_spannung!==null && t.letzte_spannung!==undefined && t.letzte_spannung!==0">
                <div class="text-end" style="font-size: 1.2rem"
                     [class.text-danger]="t.state==='fail'||t.state==='warn'"
                     [class.fw-bold]="t.state==='fail'||t.state==='warn'"

                >
                    {{ fNum(t.letzte_spannung / 100.0, "1.2-2") }} V
                </div>
                <div *ngIf="t.letzte_spannung_zeitpunkt!==null && t.letzte_spannung_zeitpunkt!==undefined"
                     class="text-end text-nowrap">
                    {{ fDate(t.letzte_spannung_zeitpunkt, "yyyy-MM-DD HH:mm", true) }}
                </div>

            </ng-container>
        </ng-container>
    </ng-template>


    <me-data-table-column [label]="'Spannung'"
                          [field]="'letzte_spannung'"
                          [sortable]="false"
                          [template]="spannung"
    ></me-data-table-column>


    <me-data-table-table-extra-header>
        <me-pagination
                [total]="liste.total"
                [page]="liste.page"
                [perpage]="liste.size"
                (pageChange)="liste.setPage($event)"
                (pageCountChange)="liste.processPageCountChangedEvent($event)"
                (queryChange)="liste.processQueryChangedEvent($event)"
                settingsid="webfuel-nozzle-readers-readers"
                [settingsService]="benutzerService"
                [showSearch]="true"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
