<div class="modal-header">
    <h4 class="modal-title" *ngIf="isAdd()">Eintrag anlegen</h4>
    <h4 class="modal-title" *ngIf="isEdit()">Eintrag bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <me-tab-card
        #rootTabCard
    >
        <me-pane id="tab-application-editor"
                 title="Informationen"
                 (activated)="activate(applicationsEditorTab)"
        >
            <app-tab-applications-editor-infos [entity]="entity" [isAdd]="isAdd()"
                                               #applicationsEditorTab></app-tab-applications-editor-infos>
        </me-pane>

        <ng-container *ngIf="isEdit()">
            <me-pane id="tab-application-editor-ip-addresses"
                     title="IP-Adressen"
                     (activated)="activate(applicationsEditorIPAddressesTab)"
            >
                <app-tab-applications-editor-ip-addresses [entity]="entity" (entityChanged)="changedEntity($event)"
                                                          #applicationsEditorIPAddressesTab></app-tab-applications-editor-ip-addresses>
            </me-pane>
        </ng-container>
    </me-tab-card>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i> Daten speichern
    </button>
</div>