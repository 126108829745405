import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, take } from 'rxjs';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import {
  IMEActionResponse,
  IMEListResult,
  MEActionResponse,
  MEApiTool,
  MEResultMetaSort,
} from '../../../maennl-commons';
import { IMESimpleFilter } from '../../../maennl-commons/filters';
import { TNullableNumber } from '../../../maennl-commons/tools/types';
import { Gruppe } from './gruppe.class';
import { GruppeImpl } from './gruppe.impl';
import { IGruppe } from './gruppe.interface';

@Injectable({
  providedIn: 'root',
})
export class GruppeService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public list(
    max = 50,
    offset = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IGruppe> = null,
    queryString = ''
  ): Observable<IMEListResult<IGruppe>> {
    const uri = MEApiTool.buildListURL(
      '/api/meconnect/netzwerk/gruppen',
      max,
      offset,
      sort,
      filter,
      queryString
    );
    return this.http
      .get<IMEListResult<IGruppe>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public store(entity: Gruppe): Observable<MEActionResponse> {
    const uri = MEApiTool.buildEntityURL(
      '/api/meconnect/netzwerk/gruppen',
      entity.id
    );

    if (entity.id === null || entity.id === undefined || entity.id < 1) {
      return this.http
        .post<IMEActionResponse>(
          uri,
          entity,
          this.loginService.getRequestOptions()
        )
        .pipe(
          map(r => {
            return MEActionResponse.fromRawActionResponse(r);
          }),
          take(1)
        );
    } else {
      return this.http
        .put<IMEActionResponse>(
          uri,
          entity,
          this.loginService.getRequestOptions()
        )
        .pipe(
          map(r => {
            return MEActionResponse.fromRawActionResponse(r);
          }),
          take(1)
        );
    }
  }

  public get(id: TNullableNumber): Observable<Gruppe> {
    if (id === null || id === undefined || id < 1) {
      return of(null);
    }
    const uri = MEApiTool.buildEntityURL('/api/meconnect/netzwerk/gruppen', id);

    return this.http
      .get<IGruppe>(uri, this.loginService.getRequestOptions())
      .pipe(
        map(r => {
          return GruppeImpl.fromResult(r);
        }),
        take(1)
      );
  }

  public delete(id: TNullableNumber): Observable<MEActionResponse> {
    if (id === null || id === undefined || id < 1) {
      return of(MEActionResponse.error('id is null or undefined'));
    }
    const uri = MEApiTool.buildEntityURL('/api/meconnect/netzwerk/gruppen', id);

    return this.http
      .delete<IMEActionResponse>(uri, this.loginService.getRequestOptions())
      .pipe(
        map(r => {
          return MEActionResponse.fromRawActionResponse(r);
        }),
        take(1)
      );
  }
}
