import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gateway1',
  templateUrl: './gateway1.component.html',
  styleUrls: ['./gateway1.component.scss']
})
export class Gateway1Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
