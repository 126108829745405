import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import { TMECellClassGetter } from '../../../maennl-commons/data-table/renderer/types';
import {
  MEDataTableRow,
  TMEDTGetExtraRowClassesCallback
} from '../../../maennl-commons/data-table/rows';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { MERichError } from '../../../maennl-commons/tools';
import { BoxlisteListEntry } from '../../common/boxliste-list-entry';
import { BoxlisteListEntryList } from '../../common/boxliste-list-entry-list';
import { SysHealthService } from '../../services/sys-health.service';
import * as moment from 'moment';

@Component({
  selector: 'app-sys-health-boxliste-tab',
  templateUrl: './sys-health-boxliste-tab.component.html',
  styleUrls: ['./sys-health-boxliste-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SysHealthBoxlisteTabComponent implements OnInit, OnDestroy {
  __classname = 'SysHealthBoxlisteTabComponent';
  __instance = '';
  liste: BoxlisteListEntryList = new BoxlisteListEntryList();

  private _tabActive = false;

  public get tabActive(): boolean {
    return this._tabActive;
  }

  public set tabActive(value: boolean) {
    if (this._tabActive !== value) {
      this._tabActive = value;
      this.cd.markForCheck();
      if (this._tabActive) {
        this.liste.enableAutoReload();
        this.liste.start();
      } else {
        this.liste.disableAutoReload();
      }
    }
  }

  constructor(
    public cd: ChangeDetectorRef,
    public sysHealthService: SysHealthService,
    public benutzerService: BenutzerService
  ) {}

  rowClassGetter: TMEDTGetExtraRowClassesCallback<BoxlisteListEntry> = (
    row: MEDataTableRow<BoxlisteListEntry>,
    idx
  ) => {
    if (
      row !== null &&
      row !== undefined &&
      row.data !== null &&
      row.data !== undefined
    ) {
      const d = moment(row.data.datumSperrliste);
      const now = moment();
      const duration = moment.duration(now.diff(d));

      if (row.data.boxUntersystemId === 16) {
        return 'bg-gray text-white';
      }

      if (
        row.data.datumSperrliste === null ||
        row.data.datumSperrliste === undefined ||
        '' + row.data.datumSperrliste === '0000-00-00 00:00:00' ||
        !d.isValid() ||
        !duration.isValid() ||
        duration.asHours() < 10
      ) {
        return '';
      }

      if (duration.asHours() > 26) {
        return 'bg-danger';
      }

      if (duration.asHours() > 10) {
        return 'bg-pink';
      }
    }
    return '';
  };

  public cellClassGetter: TMECellClassGetter<BoxlisteListEntry> = (
    value,
    column,
    row
  ) => {
    if (
      row !== null &&
      row !== undefined &&
      row.data !== null &&
      row.data !== undefined
    ) {
      if (
        column.field === 'automatVersion' ||
        column.field === 'automatStart'
      ) {
        if (
          row.data.automatVersion !== null &&
          row.data.automatVersion !== undefined &&
          row.data.automatVersion.startsWith('V:')
        ) {
          return 'bg-gray text-white';
        }
        if (
          row.data.automatVersion !== null &&
          row.data.automatVersion !== undefined &&
          row.data.automatVersion !== ''
        ) {
          const d = moment(row.data.automatStart);
          const now = moment();
          const duration = moment.duration(now.diff(d));

          if (!d.isValid() || duration.asHours() > 24) {
            return 'bg-blue text-white';
          }
        }
      }
    }

    return '';
  };

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe((list) => {
        this.sysHealthService
          .getBoxliste(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.getQuery()
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
            },

            (e) => {
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste des verfügbaren Boxen konnte nicht abgerufen werden'
              );
            }
          );
      })
    );
  }

  public onActivate() {
    this.tabActive = true;
  }

  public onDeactivate() {
    this.tabActive = false;
  }

  public ngOnDestroy(): void {
    this.liste.release();
    MESubscriptionHelper.release(this);
  }
}
