import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../../maennl-commons/megeneric-entity';
import { ICSDServerClientType } from './icsdserver-client-type';

export class CSDServerClientType
  extends MEGenericEntity<ICSDServerClientType>
  implements ICSDServerClientType
{
  public id = 0;
  public info = '';
  public label = '';

  static fromResult(
    entity: ICSDServerClientType,
    createIfNull: boolean = false
  ): CSDServerClientType {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new CSDServerClientType();
      }
      return null;
    }

    const instance = new CSDServerClientType();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('info')
        .addTextField('label'),
      (e: CSDServerClientType) => {}
    );
    return instance;
  }

  constructor() {
    super();
  }
}
