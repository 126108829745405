<ul class="layers {{ classes }}">
    <li class="ib-icon">
        <ng-container *ngIf="isLegacyIcon()">
            <i class="{{ icon }}"></i>
        </ng-container>
        <ng-container *ngIf="!isLegacyIcon()">
            <ng-container *ngTemplateOutlet="icon">
            </ng-container>
        </ng-container>
    </li>
    <li class="ib-title">
        {{ title }}
    </li>
    <li class="ib-value" *ngIf="!hasValueTemplate()">
        {{ value }}
    </li>
    <li class="ib-value-templated" *ngIf="hasValueTemplate()">
        <ng-template
                [ngTemplateOutlet]="valueTemplate"
                [ngTemplateOutletContext]="{ value:value }"
        ></ng-template>
    </li>
    <li class="ib-subtext">
        {{ subtext }}
    </li>
    <li class="ib-controls">
        <ng-content></ng-content>
    </li>
</ul>
