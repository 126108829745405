<div class="modal-header">
    <h4 class="modal-title">Client bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body" *ngIf="entity!==null && entity!==undefined">

    <me-text-field [label]="'Clientkennung'" [(value)]="entity.machineId"></me-text-field>
    <me-text-field [label]="'Clientkennung für DTU-Config'" [value]="entity.machineIdAsHex"
                   [disabled]="true"></me-text-field>
    <me-text-field [label]="'Label'" [(value)]="entity.label"></me-text-field>
    <me-text-field [label]="'Info'" [(value)]="entity.info"></me-text-field>
    <me-text-field [label]="'Escape-Sequenz'" [(value)]="entity.escapeSequence"></me-text-field>
    <me-num-field [label]="'Leerlauf-Timeout (s)'" [(value)]="entity.timeout"></me-num-field>
    <me-boolean-field [label]="'aktiviert'" [(value)]="entity.enabled"></me-boolean-field>
    <me-text-field [label]="'IMEI'" [(value)]="entity.imei"></me-text-field>
    <app-sim-field [label]="'Sim-Karte'" [value]="entity.sim" (valueChange)="setSim($event)"></app-sim-field>
    <me-text-field [label]="'AT-Kennwort'" [(value)]="entity.commandPassword"></me-text-field>

    <app-csdserver-client-type-field [label]="'Client-Typ'" [(value)]="entity.typ"></app-csdserver-client-type-field>
    <me-option-field class="w-20 me-5" [label]="'Kontakt'">
        <app-contact-selector [small]="true" [(value_id)]="entity.contact_id"></app-contact-selector>
    </me-option-field>
</div>
<div class="modal-footer d-flex justify-content-between">
    <div>
        <button type="button" class="btn btn-danger" (click)="delete()" *ngIf="this.entity.id>0">
            <i class="icofont icofont-delete"></i> Löschen
        </button>
    </div>
    <div>
        <button type="button" class="btn btn-secondary" (click)="cancel()">
            <i class="icofont icofont-close"></i> Schließen
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!canSave()">
            <i class="icofont icofont-save"></i> Speichern
        </button>
    </div>
</div>
