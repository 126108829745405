import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {ITerminal} from "./terminal.interface";
import {IMESimpleFilter} from "../../../maennl-commons/filters";
import {IMEActionResponse, IMEListResult, MEActionResponse, MEApiTool, MEResultMetaSort} from "../../../maennl-commons";
import {map, Observable, of} from "rxjs";
import {ITAJobs} from "./ta-jobs.interface";
import {take} from "rxjs/operators";
import {TNullableNumber} from "../../../maennl-commons/tools/types";
import {TAJobsImpl} from "./ta-jobs.impl";
import {TAJobs} from "./ta-jobs.class";

export const tajobtyp = new Map<number, string>();
    tajobtyp.set(0,'unbekannt');
    tajobtyp.set(1,'Abfrage aktueller Buchungen');
    tajobtyp.set(2,'Abfrage Buchungen nach Datum');
    tajobtyp.set(3,'Abfrage Buchungen nach Rechnungsnummer');
    tajobtyp.set(4,'Abfrage Stammdaten');
    tajobtyp.set(5,'Abfrage Stammdaten Änderungen');
    tajobtyp.set(6,'Abfrage Fahrer');
    tajobtyp.set(7,'Setze Zeit');

@Injectable({
    providedIn: 'root'
})
export class TAJobsService {
    constructor(public http:HttpClient, public loginService: BenutzerService) {
    }

    public list(
        max=50,
        offset=0,
        sort:MEResultMetaSort[]=[],
        filter:IMESimpleFilter<ITerminal>=null,
        queryString=''
    ): Observable<IMEListResult<ITAJobs>>{
        const uri = MEApiTool.buildListURL(
            'api/webfuel/tajobs',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<ITAJobs>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public get(id:TNullableNumber):Observable<TAJobs> {
        if(id===null||id===undefined||id<1){
            return of(null);
        }
        const uri=MEApiTool.buildEntityURL(
            'api/webfuel/tajobs',
            id
        );

        return this.http
            .get<ITAJobs>(
            uri,
            this.loginService.getRequestOptions()
            )
            .pipe(
                map(r=> {
                    return TAJobsImpl.fromResult(r);
                }),
                take(1)
            );
    }

    public delete(id:TNullableNumber):Observable<MEActionResponse> {
        if(id===null||id===undefined||id<1){
            return of(MEActionResponse.error('id is null or undefined'));
        }
        const uri=MEApiTool.buildEntityURL(
            'api/webfuel/tajobs',
            id
        );

        return this.http
            .delete<IMEActionResponse>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r=>{
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }
}