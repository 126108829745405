import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';
import { Benutzer } from '../../common/benutzer';
import { BenutzerService } from '../../services/benutzer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ILoginResult } from '../../common/ilogin-result';
import { ToastrService } from 'ngx-toastr';
import { MEPubSubService } from '../../../maennl-commons/services';
import { MESubscriptionHelper } from '../../../maennl-commons/services/mesubscription-helper';
import { noop } from '../../../maennl-commons/tools';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy
{
  __classname = 'LoginComponent';
  __instance = '';

  public benutzer = new Benutzer();
  public submitted = false;
  @ViewChild('pwinput', { static: false }) pwinput: ElementRef;
  public rememberme = false;
  public returnURL = '/';
  showError = false;
  public loginMessage = '';

  constructor(
    public benutzerService: BenutzerService,
    public toastr: ToastrService,
    public router: Router,
    public route: ActivatedRoute,
    public cd: ChangeDetectorRef,
    public pubsub: MEPubSubService
  ) {
    super();
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.pubsub.loginMessageChange.subscribe((msg: string) => {
        if (msg === null || msg === undefined) {
          msg = '';
        }
        this.loginMessage = msg;
        this.cd.markForCheck();
      })
    );

    this.showError = false;
    this.returnURL = this.route.snapshot.queryParams['returnUrl'];
    if (this.returnURL === null || this.returnURL === undefined) {
      this.returnURL = '/';
    }
    if (this.benutzerService.hasRememberedToken()) {
      if (this.benutzerService.hasRefreshToken()) {
        this.benutzerService.refreshLoginTokenLogin().subscribe(
          () => {
            this.router.navigateByUrl(this.returnURL).catch(() => {
              this.router.navigateByUrl('/').catch(noop);
            });
          },
          () => {
            this.benutzerService.rememberedLogin().subscribe(
              () => {
                this.router.navigateByUrl(this.returnURL).catch(() => {
                  this.router.navigateByUrl('/').catch(noop);
                });
              },
              () => {
                this.showError = false;
                this.benutzerService.doLogout();
                this.cd.markForCheck();
              }
            );
          }
        );
      } else {
        this.benutzerService.rememberedLogin().subscribe(
          () => {
            this.router.navigateByUrl(this.returnURL).catch(() => {
              this.router.navigateByUrl('/').catch(noop);
            });
          },
          () => {
            this.showError = false;
            this.benutzerService.doLogout();
            this.cd.markForCheck();
          }
        );
      }
    } else {
      this.benutzerService.doLogout();
      this.cd.markForCheck();
    }
  }

  ngOnDestroy() {
    MESubscriptionHelper.release(this);
  }

  hasLoginMessage() {
    if (this.benutzerService.isLoggedIn()) {
      return false;
    }
    return this.loginMessage.trim() !== '';
  }

  doLogin() {
    this.submitted = true;
    this.benutzerService.doLogin(this.benutzer, this.rememberme).subscribe(
      (benutzer: ILoginResult) => {
        this.submitted = false;
        if (benutzer !== null) {
          this.toastr.success('Herzlich Willkommen');
          window.setTimeout(() => {
            this.router.navigateByUrl(this.returnURL).catch(() => {
              this.router.navigateByUrl('/').catch(noop);
            });
          }, 500);
        } else {
          this.toastr.error(
            'Mit den angegebenen Zugangsdaten konnte ' +
              'keine Verbindung zum System hergestellt werden.',
            'Anmeldung fehlgeschlagen'
          );
        }
        this.showError = false;
        this.cd.markForCheck();
      },
      () => {
        this.showError = true;
        this.submitted = false;
        this.toastr.error(
          'Mit den angegebenen Zugangsdaten konnte keine Verbindung zum System hergestellt werden.',
          'Anmeldung fehlgeschlagen'
        );
        this.cd.markForCheck();
      }
    );
  }

  loginkp(e: KeyboardEvent) {
    this.showError = false;
    this.cd.markForCheck();
    if (e.key === 'Enter') {
      this.doLogin();
    }
  }

  unamekp(e: KeyboardEvent) {
    this.showError = false;
    this.cd.markForCheck();
    if (e.key === 'Enter') {
      if (this.pwinput !== null && this.pwinput !== undefined) {
        this.pwinput.nativeElement.focus();
      }
    }
  }
}
