import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {MEGenericModalComponent, MEPubSubService} from "../../../../../maennl-commons";
import {WireguardInterface} from "../../../commons/wireguard-interface.class";
import {WireguardInterfaceImpl} from "../../../commons/wireguard-interface.impl";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {WireguardInterfaceService} from "../../../commons/wireguard-interface.service";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-wireguard-interface-editor',
    templateUrl: './wireguard-interface-editor.component.html',
    styleUrls: ['./wireguard-interface-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WireguardInterfaceEditorComponent
    extends MEGenericModalComponent<WireguardInterface>
    implements OnInit,OnDestroy
{
    entity: WireguardInterface = null;

    public initEntity(src: WireguardInterface): WireguardInterface {
        return WireguardInterfaceImpl.fromResult(src,true);
    }

    constructor(
        public activeModal: NgbActiveModal,
        public pubsub: MEPubSubService,
        public service: WireguardInterfaceService,
        public toastr: ToastrService,
        public cd: ChangeDetectorRef
    ) {
        super(activeModal, pubsub);
    }

    ngOnInit() {}

    save(){
        this.service.store(this.entity).subscribe({
            next:(r)=>{
                if(r.success){
                    this.ok();
                } else {
                    this.toastr.error('Fehler beim Speichern des Interfaces');
                }
            },
            error:()=>{
                this.toastr.error('Fehler beim Speichern des Interfaces');
            }
        });
    }
}