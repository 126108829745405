import { IMEListResult, MERemoteList } from '../../../maennl-commons';
import { TrackerMitschnitt } from './tracker-mitschnitt.class';
import { ITrackerMitschnitt } from './itracker-mitschnitt';
import { TrackerMitschnittFilter } from './tracker-mitschnitt.filter';

export class TrackerMitschnittList extends MERemoteList<TrackerMitschnitt> {
  __classname = 'TrackerMitschnittList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<ITrackerMitschnitt>
  ): TrackerMitschnittList {
    const newList = new TrackerMitschnittList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun = false, timeout = 400) {
    super();
    this.simpleFilter = new TrackerMitschnittFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort = 'zeitpunkt',
    defaultOrder = 'desc',
    clearFilter = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: ITrackerMitschnitt): TrackerMitschnitt {
    return TrackerMitschnitt.fromResult(entity);
  }
}
