import {
  MEGenericEntityConverterParams,
  MEGenericEntityFields,
} from '../../../maennl-commons';
import { Gruppe } from './gruppe.class';
import { TGruppe, TNullableGruppe } from './gruppe.types';
import { NetworkAddressImpl } from './network-address.impl';
import { INetworkAddress } from './network-address.interface';
import { TNullableNetworkAddressList } from './network-address.types';

export class GruppeImpl {
  static fromResult(entity: TGruppe, createIfNull = false): TNullableGruppe {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Gruppe();
      }
      return null;
    }

    const instance = new Gruppe();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongFields(['id'])
        .addTextFields(['code', 'name', 'description'])
        .addConverterField(
          '_members',
          new MEGenericEntityConverterParams<TNullableNetworkAddressList>(
            (value: TNullableNetworkAddressList) => {
              if (
                value === null ||
                value === undefined ||
                !Array.isArray(value)
              ) {
                return [];
              }
              return (value as INetworkAddress[])
                .map((v: INetworkAddress) => {
                  return NetworkAddressImpl.fromResult(v, false);
                })
                .filter(v => v !== null && v !== undefined);
            }
          )
        )
    );

    return instance;
  }
}
