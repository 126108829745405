import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';

import {
  MEGenericModalComponent,
  MEPubSubService,
} from '../../../maennl-commons';
import { CheckPing } from '../commons/check-ping.class';
import { CheckPingImpl } from '../commons/check-ping.impl';
import { CheckPingService } from '../commons/check-ping.service';
import { TCheckPing, TNullableCheckPing } from '../commons/check-ping.types';

@Component({
  selector: 'app-check-ping-editor',
  templateUrl: './check-ping-editor.component.html',
  styleUrls: ['./check-ping-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckPingEditorComponent
  extends MEGenericModalComponent<CheckPing>
  implements OnInit, OnDestroy
{
  entity: TNullableCheckPing = null;

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public service: CheckPingService,
    public toastr: ToastrService,
    public cd: ChangeDetectorRef
  ) {
    super(activeModal, pubsub);
  }

  public initEntity(src: TCheckPing): TNullableCheckPing {
    return CheckPingImpl.fromResult(src, true);
  }

  ngOnInit(): void {
    this.cd.markForCheck();
  }

  save() {
    this.service.store(this.entity.address, this.entity).subscribe({
      next: r => {
        if (r.success) {
          this.ok();
        } else {
          this.toastr.error('Fehler beim Speichern der Daten');
        }
      },
      error: () => {
        this.toastr.error('Fehler beim Speichern der Daten');
      },
    });
  }
}
