import { MEGenericFilter } from '../../maennl-commons/filters';
import { Contact } from './contact';
import { ICDRCall } from './icdrcall';
import * as moment from 'moment';

export class SimpleCDRCallFilter extends MEGenericFilter<ICDRCall> {
  contact: Contact = null;
  intern_contact: Contact = null;
  date_from: Date = null;
  date_until: Date = null;
  hide_failed = false;

  public skipField(fieldname): boolean {
    if (fieldname === 'contact') {
      // return true;
    }
    return super.skipField(fieldname);
  }

  public encodeField(fieldname: string): string {
    if (fieldname === 'date_from') {
      if (this.date_from !== null && this.date_from !== undefined) {
        return (
          'filter.date_from=' +
          encodeURIComponent(moment(this.date_from).toISOString())
        );
      }
    }
    if (fieldname === 'date_until') {
      if (this.date_until !== null && this.date_until !== undefined) {
        return (
          'filter.date_until=' +
          encodeURIComponent(moment(this.date_until).toISOString())
        );
      }
    }
    if (fieldname === 'intern_contact') {
      if (this.intern_contact !== null && this.intern_contact !== undefined) {
        return (
          'filter.intern_contact=' + encodeURIComponent(this.intern_contact.id)
        );
      }
    }
    if (fieldname === 'contact') {
      if (this.contact !== null && this.contact !== undefined) {
        return 'filter.contact=' + encodeURIComponent(this.contact.id);
      }
    }
    if (fieldname === 'hide_failed') {
      if (this.hide_failed !== null && this.hide_failed !== undefined) {
        return 'filter.hide_failed=' + encodeURIComponent(this.hide_failed);
      }
    }
    return super.encodeField(fieldname);
  }
}
