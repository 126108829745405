<div class="d-flex align-items-center justify-content-center flex-column pt-5 mt-5">
    <div>
        <h1>
            <fa-icon [icon]="['far', 'triangle-person-digging']"></fa-icon>
            BAUSTELLE
        </h1>
    </div>
    <div class="mt-5 pt-5">
        <div class="alert alert-warning p-5" role="alert">
            Hier wird noch gearbeitet...
        </div>
    </div>
</div>