import { Component, Input, OnInit } from '@angular/core';
import { Mitschnittgeraet } from '../../common/mitschnittgeraet';

@Component({
  selector: 'app-mitschnittgeraet-display',
  template: `
    <div
      *ngIf="isValid()"
      title="{{ value?.deviceId }} @ {{ value?.device.bezeichnung }}"
    >
      {{ value.label }}
    </div>
  `,
  styles: [
    `
      :host {
        display: inline-block;
      }
    `
  ]
})
export class MitschnittgeraetDisplayComponent implements OnInit {
  @Input() public value: Mitschnittgeraet = null;

  constructor() {}

  ngOnInit() {}

  isValid() {
    return this.value !== null && this.value !== undefined;
  }
}
