<div class="row">
    <div class="col-4 text-nowrap">
        Uptime
    </div>
    <div class="col-8">
        {{  fDuration(testping.uptime * 1000, false, false, true) }}
    </div>
</div>
<div class="row">
    <div class="col-4">
        Last
    </div>
    <div class="col-8 text-nowrap">
    <span class="badge"
          [class.bg-secondary]="isOk(testping.load0)"
          [class.bg-warn]="isWarn(testping.load0)"
          [class.text-dark]="isOk(testping.load0) || isWarn(testping.load0)"
          [class.bg-danger]="isDanger(testping.load0)"
          title="aktueller Systemlast-Index">{{fNum(testping.load0, '1.2-2')}}
    </span>

        <span class="badge"
              [class.bg-secondary]="isOk(testping.load5)"
              [class.bg-warn]="isWarn(testping.load5)"
              [class.text-dark]="isOk(testping.load5) || isWarn(testping.load5)"
              [class.bg-danger]="isDanger(testping.load5)"
              title="durchschnittlicher Systemlast-Index der letzten 5 Minuten">{{fNum(testping.load5, '1.2-2')}}</span>
        <span class="badge"
              [class.bg-secondary]="isOk(testping.load15)"
              [class.bg-warn]="isWarn(testping.load15)"
              [class.text-dark]="isOk(testping.load15) || isWarn(testping.load15)"
              [class.bg-danger]="isDanger(testping.load15)"
              title="durchschnittlicher Systemlast-Index der letzten 15 Minuten">{{fNum(testping.load15, '1.2-2')}}</span>
    </div>
</div>
<div class="row">
    <div class="col-4 text-nowrap">
        Prozesse
    </div>
    <div class="col-8">
        {{  testping.numPids }}
    </div>
</div>
<div class="row">
    <div class="col-4 text-nowrap">
        RAM
    </div>
    <div class="col-8 pt-1">
        <app-file-system-space-display
                [free]="testping.memAvailable/1024"
                [total]="testping.memTotal/1024"
        >
        </app-file-system-space-display>
    </div>
</div>
<div class="row">
    <div class="col-4 text-nowrap">
        Swap
    </div>
    <div class="col-8" [class.pt-1]="testping.swapTotal>0">
        <app-file-system-space-display
                *ngIf="testping.swapTotal>0"
                [free]="testping.swapFree/1024"
                [total]="testping.swapTotal/1024"
        >
        </app-file-system-space-display>
        <span *ngIf="testping.swapTotal===0">nicht aktiv</span>
    </div>
</div>
