import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { AbrechnungDts } from './abrechnung-dts';
import { IAbrechnungDts } from './iabrechnung-dts';
import { SimpleAbrechnungDtsFilter } from './simple-abrechnung-dts-filter';

export class AbrechnungDtsList extends MERemoteList<AbrechnungDts> {
  __classname = 'AbrechnungDtsList';
  __instance = '';

  static fromListResult(res: IMEListResult<IAbrechnungDts>): AbrechnungDtsList {
    const newList = new AbrechnungDtsList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleAbrechnungDtsFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'periode',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return AbrechnungDts.fromResult(entity);
  }
}
