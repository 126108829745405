<div class="modal-header">
    <h4 class="modal-title">Logeinträge filtern</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <me-tab-set>
        <me-pane title="Systemzeit">
            <me-date-time-range-selector [(value)]="entity.zeitpunkt"></me-date-time-range-selector>

            <button class="btn btn-sm btn-secondary" (click)="setZP('heute','zeitpunkt')">heute</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP('woche','zeitpunkt')">diese Woche</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP('monat','zeitpunkt')">dieser Monat</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP(7,'zeitpunkt')">7 Tage</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP(14,'zeitpunkt')">14 Tage</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP(30,'zeitpunkt')">30 Tage</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP(90,'zeitpunkt')">90 Tage</button>
        </me-pane>
        <me-pane title="Empfangszeit">
            <me-date-time-range-selector [(value)]="entity.received"></me-date-time-range-selector>

            <button class="btn btn-sm btn-secondary" (click)="setZP('heute','received')">heute</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP('woche','received')">diese Woche</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP('monat','received')">dieser Monat</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP(7,'received')">7 Tage</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP(14,'received')">14 Tage</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP(30,'received')">30 Tage</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP(90,'received')">90 Tage</button>
        </me-pane>
        <me-pane title="Vorgang">

            <p>
                Hier kann die Vorgangsnummer eingeschränkt werden, möglich sind
                einzelne IDs, Aufzählungen oder Bereiche:
                1,2,14-85
            </p>

            <me-text-field [label]="'Vorgang'"
                           [(value)]="entity.vorgang">
            </me-text-field>
        </me-pane>
        <me-pane title="Gerät">
            <app-device-selector [(value)]="entity.device" [filterQuery]="'deviceType:5'"></app-device-selector>
        </me-pane>
        <me-pane title="Info">
            <me-text-field [(value)]="entity.message" label="Info-Spalte"></me-text-field>

            <button class="btn btn-sm btn-secondary"
                    *ngFor="let info of infos"
                    (click)="setInfo(info)">
                {{ info }}
            </button>

            <div class="mt-1">
                <ngb-alert type="info" [dismissible]="false">% ist Wildcard-Zeichen</ngb-alert>
            </div>
        </me-pane>
        <me-pane title="Details">
            <me-text-field [(value)]="entity.kfzkennzeichen" label="KFZ-Kennzeichen"></me-text-field>
            <me-text-field [(value)]="entity.vin" label="VIN"></me-text-field>
            <me-text-field [(value)]="entity.fahrzeug" label="Fahrzeug"></me-text-field>
            <me-text-field [(value)]="entity.mac" label="MAC"></me-text-field>
            <div class="mt-1">
                <ngb-alert type="info" [dismissible]="false">% ist Wildcard-Zeichen</ngb-alert>
            </div>
        </me-pane>
    </me-tab-set>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="clearOk()">
        <i class="icofont icofont-eraser"></i> Filter leeren
    </button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="ok()">
        <i class="icofont icofont-filter"></i> Filter setzen
    </button>
</div>
