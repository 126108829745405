import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { SysHealthService } from '../../services/sys-health.service';
import {
  IMEActionResponse,
  MEActionResponse
} from '../../../maennl-commons/network';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sys-health-dashboard-bodies-count',
  templateUrl: './sys-health-dashboard-bodies-count.component.html',
  styleUrls: ['./sys-health-dashboard-bodies-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SysHealthDashboardBodiesCountComponent
  implements OnInit, OnDestroy
{
  public bcount = 0;

  constructor(
    public syshealthService: SysHealthService,
    public cd: ChangeDetectorRef,
    public router: Router
  ) {}

  ngOnInit() {
    this.updateBodiesCount();
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
  }

  updateBodiesCount() {
    this.syshealthService.getLebenszeichenBodiesCount().subscribe(
      (res: IMEActionResponse) => {
        const response = MEActionResponse.fromRawActionResponse(res);
        if (response.success) {
          this.bcount = parseInt('' + (response.params.count || '0'), 10);
        } else {
          this.bcount = 0;
        }
        this.cd.markForCheck();
      },
      () => {
        this.bcount = 0;
        this.cd.markForCheck();
      }
    );
  }

  gotoLZ() {
    this.router.navigate(['status/lebenszeichen']).finally();
  }
}
