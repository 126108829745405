import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {IMEListResult, MEApiTool, MEResultMetaSort} from "../../../maennl-commons";
import {IMESimpleFilter} from "../../../maennl-commons/filters";
import {IImportsJobs} from "./imports-jobs.interface";
import {map, Observable, of, take} from "rxjs";
import {TNullableNumber} from "../../../maennl-commons/tools/types";
import {ImportsJobs} from "./imports-jobs.class";
import {ImportsJobsImpl} from "./imports-jobs.impl";

@Injectable({
    providedIn: 'root'
})
export class ImportsJobsService {

    constructor(public http:HttpClient, public loginService: BenutzerService) {
    }

    public list(
        max = 50,
        offset = 0,
        sort: MEResultMetaSort[]=[],
        filter: IMESimpleFilter<IImportsJobs>=null,
        queryString = ''
    ): Observable<IMEListResult<IImportsJobs>>{
        const uri = MEApiTool.buildListURL(
            'api/webfuel/importjobs',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<IImportsJobs>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public get(id:TNullableNumber):Observable<ImportsJobs>{
        if(id===null||id===undefined||id<1){
            return of(null);
        }
        const uri=MEApiTool.buildEntityURL(
            'api/webfuel/importjobs',
            id
        );
        return this.http
            .get<ImportsJobs>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r =>{
                    return ImportsJobsImpl.fromResult(r);
                }),take(1)
            );
    }
}