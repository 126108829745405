import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IAlive } from './ialive';

export class Alive extends MEGenericEntity<IAlive> implements IAlive {
  public __classname__ = 'alive';
  public _type = 'SysHealthAlive';

  device = '';
  id = 0;
  ip = '';
  lastAction = '';
  zeitpunkt: Date = null;
  freshUntil = 0;
  oldAfter = 0;
  status = '';
  statusNum = 0;
  tooOldAfter = 0;
  veryOldAfter = 0;
  deviceId = 0;
  deviceBezeichnung = '';
  lastActionDescriptive = '';

  static fromResult(entity: IAlive, createIfNull: boolean = false): Alive {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Alive();
      }
      return null;
    }
    const instance = new Alive();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('device')
        .addTextField('lastAction')
        .addTextField('ip')
        .addDateField('zeitpunkt')
        .addLongField('freshUntil')
        .addLongField('oldAfter')
        .addTextField('status')
        .addLongField('statusNum')
        .addLongField('tooOldAfter')
        .addLongField('veryOldAfter')
        .addLongField('deviceId')
        .addTextField('deviceBezeichnung')
        .addTextField('lastActionDescriptive'),
      (e: Alive) => {
        e.__classname__ = 'alive';
        e._type = 'SysHealthAlive';
      }
    );

    return instance;
  }

  constructor() {
    super();
  }

  get devInfo() {
    if (
      this.deviceId !== null &&
      this.deviceId !== undefined &&
      this.deviceId > 0
    ) {
      if (
        this.deviceBezeichnung !== null &&
        this.deviceBezeichnung !== undefined &&
        this.deviceBezeichnung.trim() !== ''
      ) {
        return this.deviceBezeichnung;
      }
    }
    return this.device + ' @ ' + this.ip;
  }
}
