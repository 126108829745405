<me-select
  [asMatrix]="false"
  [idField]="'id'"
  [items]="liste.data"
  [placeholder]="placeholder"
  [small]="small"
  (onSearch)="doSearch($event)"
  (onSelect)="doSelect($event)"
  [nullable]="nullable"
  [onFormat]="itemFormat"
  [onGetText]="itemText"
  [onMatch]="itemMatcher"></me-select>
