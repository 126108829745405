<me-data-table
    #abrechnungenTable
    *ngIf="abrechnungen!==null && abrechnungen!==undefined"
    [data]="abrechnungen.asDataTableRows()"
    [rowActionsAsButtons]="true"
    [selectable]="false"
    [tableActionsAsButtons]="true"
    actionsicon="icofont icofont-navigation-menu"
>
    <me-data-table-column [field]="'periode'" [label]="'Periode'"></me-data-table-column>
    <me-data-table-column [field]="'startDay'" [label]="'Start-Tag'"></me-data-table-column>
    <me-data-table-column [field]="'endDay'" [label]="'End-Tag'"></me-data-table-column>
    <ng-template #usersTpl let-p="item" let-row="row">
        <div class="d-flex justify-content-between align-items-stretch w-100" *ngFor="let rw of typed(row)">
            <div style="border: 1px solid #efefef;padding: 5px;min-width: 80px;"
                 class="d-flex align-items-center justify-content-center">
                <div style="font-size: 1.2rem;">
                    {{ p  }}
                </div>
            </div>
            <div class="flex-fill">
                <div class="d-flex justify-content-between align-items-start"
                     style="font-size: 0.8rem;border: 1px solid #efefef;padding: 2px;">
                    <div>vorher</div>
                    <div class="ms-1 ms-1 text-nowrap" title="Anzahl">
                        {{ rw.usersBefore }}
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-start"
                     style="font-size: 0.8rem;border: 1px solid #efefef;padding: 2px;">
                    <div>nachher</div>
                    <div class="ms-1 ms-1 text-nowrap" title="Anzahl">
                        {{ rw.usersAfter }}
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <me-data-table-column [field]="'users'" [label]="'Benutzer neu'" [template]="usersTpl"></me-data-table-column>
    <ng-template #rechnungenTpl let-p="item" let-row="row">
        <div class="d-flex justify-content-between align-items-stretch w-100" *ngFor="let rw of typed(row)">
            <div style="border: 1px solid #efefef;padding: 5px;min-width: 80px;"
                 class="d-flex align-items-center justify-content-center">
                <div style="font-size: 1.2rem;">
                    {{ p  }}
                </div>
            </div>
            <div class="flex-fill" *ngIf="rw.rg_nummer_min!==null && rw.rg_nummer_min!==undefined">
                <ng-container *ngIf="rw.rg_nummer_min!==null && rw.rg_nummer_min!==undefined && rw.rg_nummer_min>0">
                    <div class="d-flex justify-content-between align-items-start"
                         style="font-size: 0.8rem;border: 1px solid #efefef;padding: 2px;">
                        <div>ab</div>
                        <div>
                            <div class="text-nowrap" *ngIf="rw.rg_nummer_min>0" title="Rechnungsnummer">
                                {{ rw.rg_nummer_min }} #
                            </div>
                            <div class="text-nowrap" title="Rechnungsdatum" *ngIf="rw.rg_datum_min!==null">
                                {{ fDate(rw.rg_datum_min, 'YYYY-MM-DD', true) }} D
                            </div>
                            <div class="text-nowrap" title="Eingangsdatum" *ngIf="rw.rg_datum_min!==null">
                                {{ fDate(rw.rg_eingang_min, 'YYYY-MM-DD HH:mm', true) }} E
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="rw.rg_nummer_max!==null && rw.rg_nummer_max!==undefined && rw.rg_nummer_max>0">
                    <div class="d-flex justify-content-between align-items-start"
                         style="font-size: 0.8rem;border: 1px solid #efefef;padding: 2px;">
                        <div>bis</div>
                        <div>
                            <div class="text-nowrap" *ngIf="rw.rg_nummer_max>0" title="Rechnungsnummer">
                                {{ rw.rg_nummer_max }} #
                            </div>
                            <div class="text-nowrap" title="Rechnungsdatum" *ngIf="rw.rg_datum_max!==null">
                                {{ fDate(rw.rg_datum_max, 'YYYY-MM-DD', true) }} D
                            </div>
                            <div class="text-nowrap" title="Eingangsdatum" *ngIf="rw.rg_datum_max!==null">
                                {{ fDate(rw.rg_eingang_max, 'YYYY-MM-DD HH:mm', true) }} E
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-template>
    <me-data-table-column [field]="'rechnungen'" [label]="'Rechnungen'"
                          [template]="rechnungenTpl"></me-data-table-column>
    <me-data-table-column [field]="'transaktionen'" [label]="'Transaktionen'" type="long"></me-data-table-column>
    <me-data-table-column [field]="'summe'" [label]="'Summe'" type="currency"></me-data-table-column>
    <me-data-table-column [cfg]="{format:'YYYY-MM-DD HH:mm'}" [field]="'lastUpdated'" [label]="'Stand'"
                          type="date"></me-data-table-column>
    <me-data-table-column [cfg]="{format:'YYYY-MM-DD HH:mm'}" [field]="'abgerechnet'" [label]="'Abgerechnet'"
                          type="date"></me-data-table-column>

    <me-data-table-rowaction
            (perform)="markAbrechnungCleared($event)"
            [availableCheck]="recalcActionAvailable"
            icon="icofont icofont-checked"
            label="Abgerechnet"
            title="Abrechnung als abgerechnet markieren"
    ></me-data-table-rowaction>

    <me-data-table-rowaction
            (perform)="recalcAbrechnungVaroRechnungsserver($event)"
            [availableCheck]="recalcActionAvailable"
            icon="icofont icofont-refresh"
            label="Berechnen"
            title="Abrechnung neu durchrechnen"
    ></me-data-table-rowaction>

    <me-data-table-rowaction
            (perform)="displayAbrechnungVaroRechnungsserver($event)"
            icon="icofont icofont-info"
            label="Details"
            title="Abrechnung anschauen und drucken"
    ></me-data-table-rowaction>

    <me-data-table-table-extra-header>
        <me-pagination
                (pageChange)="abrechnungen.setPage($event)"
                (pageCountChange)="abrechnungen.processPageCountChangedEvent($event)"
                [page]="abrechnungen.page"
                [perpage]="abrechnungen.size"
                [settingsService]="benutzerService"
                [total]="abrechnungen.total"
                settingsid="abrechnung-varo-rechnungsserver"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
