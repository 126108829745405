import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meconnect',
  templateUrl: './meconnect.component.html',
  styleUrls: ['./meconnect.component.scss']
})
export class MEConnectComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
