<div class="modal-header">
    <h4 class="modal-title">Client bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">

    <me-text-field [label]="'Bezeichnung'" [(value)]="entity.label"></me-text-field>
    <me-text-field [label]="'Kennung'" [(value)]="entity.deviceId"></me-text-field>
    <app-device-selector-w-lbl [label]="'Router'" [(value)]="entity.device"></app-device-selector-w-lbl>
    <me-boolean-field [label]="'aktivieren'" [(value)]="entity.enabled"></me-boolean-field>
    <me-select-field [label]="'Protokoll'" [value]="entity.protocol"
                     (valueChange)="entity.protocol=$event">
        <me-select-field-option [label]="'dresser'" [value]="'dresser'"></me-select-field-option>
        <me-select-field-option [label]="'dresser-packaged'" [value]="'dresser-packaged'"></me-select-field-option>
        <me-select-field-option [label]="'zsr'" [value]="'zsr'"></me-select-field-option>
        <me-select-field-option [label]="'zsr-packaged'" [value]="'zsr-packaged'"></me-select-field-option>
        <me-select-field-option [label]="'er3-packaged'" [value]="'er3-packaged'"></me-select-field-option>
        <me-select-field-option [label]="'serialcom'" [value]="'serialcom'"></me-select-field-option>
        <me-select-field-option [label]="'csd-modem'" [value]="'csd-modem'"></me-select-field-option>
        <me-select-field-option [label]="'csd-modem-crlf'" [value]="'csd-modem-crlf'"></me-select-field-option>
    </me-select-field>
</div>
<div class="modal-footer d-flex justify-content-between">
    <div>
        <button type="button" class="btn btn-danger" (click)="delete()" *ngIf="entity.id>0">
            <i class="icofont icofont-delete"></i> Löschen
        </button>
    </div>
    <div>
        <button type="button" class="btn btn-secondary" (click)="cancel()">
            <i class="icofont icofont-close"></i> Schließen
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!canSave()">
            <i class="icofont icofont-save"></i> Speichern
        </button>
    </div>
</div>
