<me-data-table
        *ngIf="liste!==null && liste!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="liste.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        (onSortChanged)="liste.processSortChangedEvent($event)"
        #clientsTable
>

    <ng-template #datum let-p="item" let-row="row">
        <div class="text-start text-start" *ngIf="p!==null && p!==undefined">
            {{ fDate(p, 'YYYY-MM-DD HH:mm:ss', true) }}
        </div>
    </ng-template>
    <ng-template #nummer let-p="item" let-row="row">
        <div class="text-end text-end" *ngIf="p!==null && p!==undefined">
            {{ fNum(p, '1.0-0') }}
        </div>
    </ng-template>
    <ng-template #boolean let-p="item" let-row="row">
        <div class="text-start text-start" *ngIf="p!==null && p!==undefined">
            <span class="me-2">
                <fa-icon [icon]="['far',(p) ? 'square-check' : 'square']"></fa-icon>
            </span>
            <span *ngIf="p">aktiv</span>
            <span *ngIf="!p">inaktiv</span>
        </div>
    </ng-template>


    <me-data-table-column [label]="'Kunde'"
                          [field]="'display_text'"
                          [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column [label]="'Projekt'"
                          [field]="'projekt'"
                          [sortable]="true"
                          [template]="nummer"
    ></me-data-table-column>
    <ng-template #planTemplate let-p="item" let-row="row">
        <ng-container *ngFor="let row of typed(row.data)">
            <div class="small">
                <table class="table table-borderless m-0">
                    <tr *ngIf="row.planPOJO!==null && row.planPOJO!==undefined">
                        <td class="p-1">Tarif</td>
                        <td class="p-1">{{ row.planPOJO.display_text }}</td>
                    </tr>
                    <tr *ngIf="row.login!==null && row.login!==undefined && row.login!==''">
                        <td class="p-1">PDF:</td>
                        <td class="p-1">
                            <a class="has-cursor-pointer" (click)="downloadpdf(row.id)">
                                <i class="far fa-file-pdf"></i>
                                PDF mit Zugangsdaten herunterladen
                            </a>
                        </td>
                    </tr>
                    <tr *ngIf="row.login!==null && row.login!==undefined && row.login!==''">
                        <td class="p-1">Url:</td>
                        <td class="p-1"><a href="https://remotebackup.webfuel.de/"
                                           target="_blank">https://remotebackup.webfuel.de/</a></td>
                    </tr>
                    <tr *ngIf="row.login!==null && row.login!==undefined && row.login!==''">
                        <td class="p-1">Login:</td>
                        <td class="p-1">{{ row.login }}</td>
                    </tr>
                    <tr *ngIf="row.login!==null && row.login!==undefined && row.login!==''">
                        <td class="p-1">Key:</td>
                        <td class="p-1">{{ row.key }}</td>
                    </tr>
                </table>
            </div>

        </ng-container>
    </ng-template>
    <me-data-table-column [label]="'Abonnement'"
                          [field]="'planPOJO'"
                          [sortable]="true"
                          [template]="planTemplate"
    ></me-data-table-column>


    <me-data-table-column [label]="'Lückenfüller?'"
                          [field]="'gap_filler_enabled'"
                          [sortable]="true"
                          [template]="boolean"
    ></me-data-table-column>


    <me-data-table-column [label]="'# Buchungen'"
                          [field]="'num_bookings'"
                          [sortable]="true"
                          [template]="nummer"
    ></me-data-table-column>


    <me-data-table-column [label]="'letzte Buchung'"
                          [field]="'last_booking'"
                          [sortable]="true"
                          [template]="datum"
    ></me-data-table-column>
    <me-data-table-column [label]="'letzter Datenempfang'"
                          [field]="'last_received'"
                          [sortable]="true"
                          [template]="datum"
    ></me-data-table-column>


    <me-data-table-table-extra-header>
        <me-pagination
                [total]="liste.total"
                [page]="liste.page"
                [perpage]="liste.size"
                (pageChange)="liste.setPage($event)"
                (pageCountChange)="liste.processPageCountChangedEvent($event)"
                (queryChange)="liste.processQueryChangedEvent($event)"
                settingsid="webfuel-remotebackup-customers"
                [settingsService]="benutzerService"
                [showSearch]="true"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
