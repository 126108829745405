import { Component, Injector } from '@angular/core';
import { MEConverterTool } from '../../tools/meconverter-tool';
import { MEDataTableColumnComponent } from '../columns/me-data-table-column.component';
import { MEDataTableRow } from '../rows/medata-table-row';
import { MEDataTableLocalizedCellRenderer } from './melocalized-cell-renderer';

@Component({
  selector: 'me-data-table-bytes-cell-renderer',
  template: `
    {{ fNum(cell, digitInfo) | meremovezerodecimalplaces }} {{ addon }}
  `
})
export class MEDataTableBytesCellRendererComponent extends MEDataTableLocalizedCellRenderer {
  public defaultLocale: string;

  public digitInfo = '1.3-3';
  public digits = 3;

  public cell: number = null;
  public row: MEDataTableRow<any> = null;
  public column: MEDataTableColumnComponent = null;

  public addon = '';

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');

    if (this.column !== null && this.column !== undefined) {
      const cfg = this.column.cfg;
      if (cfg !== null && cfg !== undefined) {
        if (cfg.fdigits !== undefined && cfg.fdigits !== null) {
          const fdigits: number = parseInt(cfg.fdigits, 10);
          if (!isNaN(fdigits)) {
            this.digits = fdigits;
            this.digitInfo = '1.' + fdigits + '-' + fdigits;
          }
        }
      }
    }

    const bt: any[] = MEConverterTool.readableBytesArray(
      this.cell,
      this.digits
    );
    this.cell = bt[0];
    this.addon = bt[1];
  }
}
