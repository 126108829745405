import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {BenutzerService} from '../../../benutzer/services/benutzer.service';
import {
    MELocalizedComponent,
    MERichError,
    MERowActionParams,
    noop,
    MESubscriptionHelper, METool, MEConfirmDialogComponent
} from '../../../maennl-commons';
import {BridgeEditorComponent} from '../bridge-editor/bridge-editor.component';
import {CSDServerBridge} from '../commons/csdserver-bridge';
import {CSDServerBridgeList} from '../commons/csdserver-bridge-list';
import {CSDServerService} from '../services/csdserver.service';
import {SimpleCSDServerBridgeFilter} from '../commons/simple-csdserver-bridge-filter';

@Component({
    selector: 'app-tab-csdbridges',
    templateUrl: './tab-csdbridges.component.html',
    styleUrls: ['./tab-csdbridges.component.scss']
})
export class TabCSDBridgesComponent
    extends MELocalizedComponent
    implements OnInit, AfterViewInit, OnDestroy {

    private _qc_bridges: boolean | null = false;


    get qc_bridges(): boolean | null {
        return this._qc_bridges;
    }

    @Input()
    set qc_bridges(value: boolean | null) {
        if (this._qc_bridges !== value) {
            this._qc_bridges = value;
            if (this._tabActive) {
                this.bridges.start();
            }
            this.cd.markForCheck()
        }
    }

    public bridges = new CSDServerBridgeList(false, 400);
    private _tabActive = false;
    public get tabActive(): boolean {
        return this._tabActive;
    }

    @Input()
    public set tabActive(value: boolean) {
        if (this._tabActive !== value) {
            this._tabActive = value;
            this.cd.markForCheck();
            if (this._tabActive) {
                this.bridges.start();
            }
        }
    }

    constructor(
        public toastr: ToastrService,
        public cd: ChangeDetectorRef,
        public modalService: NgbModal,
        public benutzerService: BenutzerService,
        public csdServerService: CSDServerService
    ) {
        super();
    }

    ngOnInit() {
        MESubscriptionHelper.add(
            this,
            this.bridges.onUpdateRequired.subscribe((list: CSDServerBridgeList) => {
                if (this._tabActive) {
                    (list.simpleFilter as SimpleCSDServerBridgeFilter).is_quickconnect_bridge = this.qc_bridges;
                    this.csdServerService
                        .listBridges(
                            list.size,
                            list.calcOffset(),
                            list.order,
                            list.simpleFilter,
                            list.getQuery()
                        )
                        .subscribe({
                            next: (l) => {
                                list.populateFromListResult(l);
                                this.cd.markForCheck();
                            },
                            error: (e) => {
                                console.log(e);
                                MERichError.throw(
                                    'Fehler beim Datenabruf',
                                    'Die Liste der Brücken konnte nicht abgerufen werden.'
                                );
                            }
                        });
                }
            }, undefined)
        );
    }

    onActivate() {
        this.tabActive = true;
        console.log('activated');
    }

    onDeactivate() {
        this.tabActive = false;
        console.log('deactivated');
    }

    public ngAfterViewInit(): void {
    }

    public ngOnDestroy(): void {
        MESubscriptionHelper.release(this);
    }

    public addBridge() {
        BridgeEditorComponent.open(
            this.modalService,
            BridgeEditorComponent,
            null
        ).then(() => {
            this.bridges.reload();
        }, noop);
    }

    public editBridge($event: MERowActionParams<CSDServerBridge>) {
        this.csdServerService.loadBridge($event.row.data.id).subscribe(
            (br) => {
                BridgeEditorComponent.open(
                    this.modalService,
                    BridgeEditorComponent,
                    br
                ).then(() => {
                    this.bridges.reload();
                }, noop);
            },
            () => {
                MERichError.throw(
                    'Fehler beim Datenabruf',
                    'Die gewählte Brücke konnte nicht vom Server abgerufen werden'
                );
            }
        );
    }

    public cutConnection($event: MERowActionParams<CSDServerBridge>) {
        if (this.csdServerService.loadBridge($event.row.data.id) !== null || this.csdServerService.loadBridge($event.row.data.id) !== undefined) {
            MEConfirmDialogComponent.display(
                this.modalService,
                'Verbindung trennen bestätigen',
                'Soll die Verbindung wirklich getrennt werden?',
                'Ja, jetzt trennen',
                'icofont icofont-delete'
            ).then(() => {
                    this.csdServerService.loadBridge($event.row.data.id).subscribe(
                        (br) => {
                            this.csdServerService.cutConnection(br).subscribe((r) => {
                                if (!r.success) {
                                    MERichError.throw(
                                        'Fehler beim Trennen',
                                        'Die gewählte Brückenverbindung konnte nicht getrennt werden.'
                                    );
                                }
                                this.bridges.reload()
                            });
                        },
                        () => {
                            MERichError.throw(
                                'Fehler beim Trennen',
                                'Die gewählte Brückenverbindung konnte nicht getrennt werden'
                            );
                        });
                },
                () => {
                    MERichError.throw(
                        'Fehler beim Trennen',
                        'Beim Trennen der Brückenverbindung ist ein Fehler aufgetreten.'
                    );
                });
        }
    }
}
