import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { Contact } from './contact';
import { IContact } from './icontact';
import { SimpleContactFilter } from './simple-contact-filter';

export class ContactList extends MERemoteList<Contact> {
  __classname = 'ContactList';
  __instance = '';

  static fromListResult(res: IMEListResult<IContact>): ContactList {
    const newList = new ContactList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleContactFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'displayText',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return Contact.fromResult(entity);
  }
}
