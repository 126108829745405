import { Component, OnInit, ViewChild } from '@angular/core';
import { TabRBCustomersComponent } from './tab-rbcustomers/tab-rbcustomers.component';
import { METabCardComponent, METool } from '../../maennl-commons';
import { TabRBPlansComponent } from './tab-rbplans/tab-rbplans.component';
import { TabRBTerminalsComponent } from './tab-rbterminals/tab-rbterminals.component';

type TTabType =
  | null
  | TabRBCustomersComponent
  | TabRBPlansComponent
  | TabRBTerminalsComponent;

@Component({
  selector: 'app-remote-backup',
  templateUrl: './remote-backup.component.html',
  styleUrls: ['./remote-backup.component.scss']
})
export class RemoteBackupComponent implements OnInit {
  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;
  @ViewChild('customersTab', { static: false }) ka: TabRBCustomersComponent;

  constructor() {}

  private _currentTab: TTabType = null;

  public get currentTab(): TTabType {
    return this._currentTab;
  }

  public set currentTab(value: TTabType) {
    if (this._currentTab !== value) {
      if (!METool.isNullOrUndefined(this._currentTab)) {
        this._currentTab.onDeactivate();
      }
      this._currentTab = value;
      if (!METool.isNullOrUndefined(this._currentTab)) {
        this._currentTab.onActivate();
      }
    }
  }

  ngAfterViewInit(): void {
    this.currentTab = this.ka;
  }

  ngOnInit() {}

  activateTab(s: string) {
    const found = false;

    if (!found) {
      console.log(s);
    }
  }

  deactivateTab(s: string) {
    const found = false;

    if (!found) {
      console.log(s);
    }
  }

  public activate(tab: TTabType) {
    console.log(tab);
    this.currentTab = tab;
  }
}
