import {ImportsJobs} from "./imports-jobs.class";
import {TNullableImportsJobs} from "./imports-jobs.types";
import {MEGenericEntityFields} from "../../../maennl-commons";

export class ImportsJobsImpl {

    static fromResult(
        entity:ImportsJobs,
        createifNull = false
    ): TNullableImportsJobs {
        if(entity === null || entity === undefined) {
            if(createifNull) {
                new ImportsJobs();
            }
            return null;
        }

        const instance = new ImportsJobs();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongFields([
                    'id',
                    'typ',
                    'size',
                    'tenant'
                ])
                .addDateFields([
                    'date_created',
                    'last_updated',
                    'import_started',
                    'imported',
                    'next_try'
                ])
                .addTextFields([
                    '_typ_info',
                    '_tenant_info',
                    'filename',
                    'file_data'
                ])
        );

        return instance;
    }
}