import { MEGenericEntity } from '../../../maennl-commons';
import {
  TNullableBoolean,
  TNullableNumber,
  TNullableString,
} from '../../../maennl-commons/tools/types';
import { ICheckPing } from './check-ping.interface';
import { TNullableFirewall } from './firewall.types';
import { TNullableNetworkAddress } from './network-address.types';

export class CheckPing
  extends MEGenericEntity<ICheckPing>
  implements ICheckPing
{
  constructor() {
    super();
  }

  _address: TNullableNetworkAddress = null;
  _firewall: TNullableFirewall = null;

  address: TNullableNumber = null;
  check_enabled: TNullableBoolean = true;
  check_interval_secs: TNullableNumber = 3600;
  check_result: TNullableString = null;
  error_count: TNullableNumber = 1;
  firewall: TNullableNumber = 0;
  id: TNullableNumber = null;
  is_ok: TNullableBoolean = null;
  last_check: Date = null;
  next_check: Date = null;
  status_change: Date = null;
  check_tries: TNullableNumber = 10;
  check_delay: TNullableNumber = 250;
  payload_size: TNullableNumber = 1;
}
