import { Component, OnInit, ViewChild } from '@angular/core';
import { TabSondenComponent } from './tab-sonden/tab-sonden.component';
import { TabPeilungenComponent } from './tab-peilungen/tab-peilungen.component';
import { METabCardComponent } from '../maennl-commons/metab-card';

@Component({
  selector: 'app-sonden',
  templateUrl: './sonden.component.html',
  styleUrls: ['./sonden.component.scss']
})
export class SondenComponent implements OnInit {
  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;

  @ViewChild(TabSondenComponent, { static: false })
  sondenTab: TabSondenComponent;
  @ViewChild(TabPeilungenComponent, { static: false })
  peilungenTab: TabPeilungenComponent;

  constructor() {}

  ngOnInit() {}

  activateTab(id: string) {
    if ('tab-sonden' === id) {
      if (this.sondenTab !== null && this.sondenTab !== undefined) {
        this.sondenTab.tabActivated();
      }
    }
    if ('tab-peilungen' === id) {
      if (this.peilungenTab !== null && this.peilungenTab !== undefined) {
        this.peilungenTab.tabActivated();
      }
    }
  }

  deactivateTab(id: string) {
    if ('tab-sonden' === id) {
      if (this.sondenTab !== null && this.sondenTab !== undefined) {
        this.sondenTab.tabDeactivated();
      }
    }
    if ('tab-peilungen' === id) {
      if (this.peilungenTab !== null && this.peilungenTab !== undefined) {
        this.peilungenTab.tabDeactivated();
      }
    }
  }
}
