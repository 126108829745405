import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields,
} from '../../../maennl-commons';
import { MEConverterTool, METool } from '../../../maennl-commons';
import { CSDServerClient } from './csdserver-client';
import { ICSDServerBridge } from './icsdserver-bridge';
import { Md5 } from 'ts-md5';

export class CSDServerBridge
  extends MEGenericEntity<ICSDServerBridge>
  implements ICSDServerBridge
{
  client1: CSDServerClient = null;
  client2: CSDServerClient = null;
  public bytesTransferred = 0;
  public client1Id = 0;
  public client1Info = '';
  public client1MachineId = '';
  public client1TypInfo = '';
  public client1TypLabel = '';
  public client2Id = 0;
  public client2Info = '';
  public client2MachineId = '';
  public client2TypInfo = '';
  public client2TypLabel = '';
  public enabled = false;
  public id = 0;
  public info = '';
  public lastUsed: Date = null;
  public login = '';
  public online = false;

  public bufferSize = 64;
  public releaseOnLeave = true;
  public timeout = 3600;

  public client1simId = 0;
  public client1simRufnummer = '';
  public client1simTitle = '';
  public client2simId = 0;
  public client2simRufnummer = '';
  public client2simTitle = '';

  public failIfNotOnline = true;
  public sendPseudoBaudrate = '19200';
  public useCount = 0;
  public is_quickconnect_bridge = false;
  public expires: Date = null;

  delayForConnect = 2500;
  delayForResponse = 200;
  lineEndings = 'CRLF';

  static fromResult(
    entity: ICSDServerBridge,
    createIfNull = false
  ): CSDServerBridge {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new CSDServerBridge();
      }
      return null;
    }

    const instance = new CSDServerBridge();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addLongField('bytesTransferred')
        .addLongField('client1Id')
        .addTextField('client1Info')
        .addTextField('client1MachineId')
        .addTextField('client1TypInfo')
        .addTextField('client1TypLabel')
        .addLongField('client2Id')
        .addTextField('client2Info')
        .addTextField('client2MachineId')
        .addTextField('client2TypInfo')
        .addTextField('client2TypLabel')
        .addBooleanField('enabled')
        .addBooleanField('online')
        .addTextField('info')
        .addDateField('lastUsed')
        .addTextField('login')
        .addBooleanField('releaseOnLeave')
        .addLongField('timeout')
        .addLongField('bufferSize')
        .addLongField('client1simId')
        .addTextField('client1simTitle')
        .addTextField('client1simRufnummer')
        .addLongField('client2simId')
        .addTextField('client2simTitle')
        .addTextField('client2simRufnummer')
        .addBooleanField('failIfNotOnline')
        .addTextField('sendPseudoBaudrate')
        .addLongField('useCount')
        .addConverterField(
          'client1',
          new MEGenericEntityConverterParams<CSDServerClient>(v => {
            return CSDServerClient.fromResult(v);
          })
        )
        .addConverterField(
          'client2',
          new MEGenericEntityConverterParams<CSDServerClient>(v => {
            return CSDServerClient.fromResult(v);
          })
        )
        .addTextField('lineEndings')
        .addLongField('delayForConnect')
        .addLongField('delayForResponse')
        .addBooleanField('is_quickconnect_bridge')
        .addDateField('expires'),

      (e: CSDServerBridge) => {}
    );
    return instance;
  }

  constructor() {
    super();
  }

  public calculateLoginString() {
    const ls = [7, 7, 5];
    const h = [];

    if (METool.isNullOrUndefined(this.client1)) {
      h.push(
        Md5.hashStr('A-' + JSON.stringify(new Date().getTime() + '-' + this))
      );
    } else {
      h.push(this.client1.login_part);
    }

    if (METool.isNullOrUndefined(this.client2)) {
      h.push(
        Md5.hashStr('B-' + JSON.stringify(new Date().getTime() + '-' + this))
      );
    } else {
      h.push(this.client2.login_part);
    }

    h.push(
      Md5.hashStr(JSON.stringify(new Date().getTime() + '-' + this)).toString()
    );

    for (let i = 0; i < 3; i++) {
      console.log('Vorher', i, h[i]);
      h[i] =
        h[i].length > ls[i] ? MEConverterTool.left_string(h[i], ls[i]) : h[i];
      console.log('Nachher', i, h[i]);
    }

    this.login = h.join('.');
  }
}
