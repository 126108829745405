import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IBoxlisteV4ListEntry } from './iboxliste-v4-list-entry';

export class BoxlisteV4ListEntry
  extends MEGenericEntity<IBoxlisteV4ListEntry>
  implements IBoxlisteV4ListEntry
{
  public __classname__ = 'BoxlisteV4ListEntry';
  public _type = 'SysHealthBoxlisteV4ListEntry';
  public boxNummer: string = null;
  public datumKartenanfrage: Date = null;
  public datumServiceAnfrage: Date = null;
  public datumSondendaten: Date = null;
  public datumSperrdatei: Date = null;
  public datumTankung: Date = null;
  public id: number = null;
  public ip: string = null;
  public nebenstelleName: string = null;
  public nebenstelleNummer: number = null;
  public nebenstelleUntersystemId: number = null;
  public simIp: string = null;
  public statusSimId: number = null;
  public threadStartDatumAR: Date = null;
  public threadStartDatumHS: Date = null;

  static fromResult(
    entity: IBoxlisteV4ListEntry,
    createIfNull: boolean = false
  ): BoxlisteV4ListEntry {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new BoxlisteV4ListEntry();
      }
      return null;
    }
    const instance = new BoxlisteV4ListEntry();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addLongField('boxNummer')
        .addDateField('datumkartenanfrage')
        .addDateField('datumServiceAnfrage')
        .addDateField('datumSondendaten')
        .addDateField('datumSperrdatei')
        .addDateField('datumTankung')
        .addTextField('ip')
        .addTextField('nebenstelleName')
        .addLongField('nebenstelleNummer')
        .addLongField('nebenstelleUntersystemId')
        .addTextField('simIp')
        .addLongField('statusSimId')
        .addDateField('threadStartDatumAR')
        .addDateField('threadStartDatumHS'),
      (e: BoxlisteV4ListEntry) => {
        e.__classname__ = 'BoxlisteV4ListEntry';
        e._type = 'SysHealthBoxlisteV4ListEntry';
      }
    );

    return instance;
  }

  constructor() {
    super();
  }
}
