import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  MEGenericModalComponent,
  MELocalizedComponent,
  MERichError,
  MESubscriptionHelper,
  METool,
  TMEDTGetExtraRowClassesCallback,
} from '../../../maennl-commons';
import { VehicleTrackerList } from '../commons/vehicle-tracker-list';
import { VehicleTrackerService } from '../commons/vehicle-tracker.service';
import { VehicleTracker } from '../commons/vehicle-tracker';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MoveVehiclePopupComponent } from '../move-vehicle-popup/move-vehicle-popup.component';
import { MoveVehicleOptions } from '../move-vehicle-popup/move-vehicle-options';
import { VehicleEditorOptions } from '../vehicle-editor-popup/vehicle-editor-options';
import { VehicleEditorPopupComponent } from '../vehicle-editor-popup/vehicle-editor-popup.component';
import { VehicleService } from '../../vehicles/commons/vehicle.service';
import { ModifyTrackerOptions } from '../modify-tracker-popup/modify-tracker-options';
import { ModifyTrackerPopupComponent } from '../modify-tracker-popup/modify-tracker-popup.component';
import { TrackerMitschnittPopupOptions } from '../tracker-mitschnitt-popup/tracker-mitschnitt-popup-options';
import { TrackerMitschnittPopupComponent } from '../tracker-mitschnitt-popup/tracker-mitschnitt-popup.component';

@Component({
  selector: 'app-tab-vehicle-tracker',
  templateUrl: './tab-vehicle-tracker.component.html',
  styleUrls: ['./tab-vehicle-tracker.component.scss'],
})
export class TabVehicleTrackerComponent
  extends MELocalizedComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  liste = new VehicleTrackerList(false, 400);

  constructor(
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    public benutzerService: BenutzerService,
    public vehicleTrackerService: VehicleTrackerService,
    public modalService: NgbModal,
    public vehicleService: VehicleService
  ) {
    super();
  }

  public extraRowClasses: TMEDTGetExtraRowClassesCallback<VehicleTracker> =
    rw => {
      const classes = [''];
      if (
        rw !== null &&
        rw !== undefined &&
        rw.data !== null &&
        rw.data !== undefined
      ) {
        if (rw.data.is_auto > 0) {
          classes.push('is-auto-object');
        }
        return classes.join(' ');
      }
    };

  private _tabActive = false;

  public get tabActive(): boolean {
    return this._tabActive;
  }

  @Input()
  public set tabActive(value: boolean) {
    if (this._tabActive !== value) {
      this._tabActive = value;
      this.cd.markForCheck();
      if (this._tabActive) {
        this.liste.start();
      }
    }
  }

  ngOnInit(): void {
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe((list: VehicleTrackerList) => {
        if (this.tabActive) {
          this.vehicleTrackerService
            .list(
              list.size,
              list.calcOffset(),
              list.order,
              list.simpleFilter,
              list.getQuery()
            )
            .subscribe({
              next: l => {
                list.populateFromListResult(l);
                this.cd.markForCheck();
              },
              error: e => {
                console.log(e);
                MERichError.throw(
                  'Fehler beim Datenabruf',
                  'Die Liste der Fahrzeug-Tracker konnte nicht abgerufen werden.'
                );
              },
            });
        }
      })
    );
  }

  onActivate() {
    this.tabActive = true;
    console.log('activated');
  }

  onDeactivate() {
    this.tabActive = false;
    console.log('deactivated');
  }

  public ngAfterViewInit(): void {}

  public ngOnDestroy(): void {
    this.liste.release();
    MESubscriptionHelper.release(this);
  }

  typed(n: any): VehicleTracker[] {
    if (METool.isNullOrUndefined(n)) {
      return [];
    }
    return [n as VehicleTracker];
  }

  moveTracker(t: VehicleTracker) {
    const o = new MoveVehicleOptions();
    o.tracker = t;

    MoveVehiclePopupComponent.open(
      this.modalService,
      MoveVehiclePopupComponent,
      o,
      MEGenericModalComponent.SIZE_SMALL
    ).then(
      (f: MoveVehicleOptions) => {
        this.liste.reload();
        this.cd.markForCheck();
      },
      () => {}
    );
  }

  editVehicle(t: VehicleTracker) {
    this.vehicleService.get(t.id).subscribe(v => {
      const o = new VehicleEditorOptions();
      o.tracker = t;
      o.vehicle = v;

      VehicleEditorPopupComponent.open(
        this.modalService,
        VehicleEditorPopupComponent,
        o,
        MEGenericModalComponent.SIZE_SMALL
      ).then(
        f => {
          this.liste.reload();
          this.cd.markForCheck();
        },
        () => {}
      );
    });
  }

  editTracker(t: VehicleTracker) {
    this.vehicleService.get(t.id).subscribe(v => {
      const o = new ModifyTrackerOptions();
      o.tracker = t;
      o.vehicle = v;

      ModifyTrackerPopupComponent.open(
        this.modalService,
        ModifyTrackerPopupComponent,
        o,
        MEGenericModalComponent.SIZE_SMALL
      ).then(
        f => {
          this.liste.reload();
          this.cd.markForCheck();
        },
        () => {
          this.liste.reload();
          this.cd.markForCheck();
        }
      );
    });
  }

  open_mitschnitt(t: VehicleTracker) {
    this.vehicleService.get(t.id).subscribe(v => {
      const o = new TrackerMitschnittPopupOptions();
      o.tracker = t;
      o.data.vehicle = v;

      TrackerMitschnittPopupComponent.open(
        this.modalService,
        TrackerMitschnittPopupComponent,
        o,
        MEGenericModalComponent.SIZE_MEDIUM
      ).then(
        f => {
          this.liste.reload();
          this.cd.markForCheck();
        },
        () => {
          this.liste.reload();
          this.cd.markForCheck();
        }
      );
    });
  }
}
