import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BenutzerService } from '../../benutzer/services/benutzer.service';
import {
  IMEActionResponse,
  IMEListResult,
  IMEResultMetaSort,
  MEApiTool,
  MEResultMetaSort
} from '../../maennl-commons';
import { IMESimpleFilter } from '../../maennl-commons/filters';
import { Check } from '../common/check';
import { CheckHistory } from '../common/check-history';
import { IAlive } from '../common/ialive';
import { IBoxlisteListEntry } from '../common/iboxliste-list-entry';
import { IBoxlisteV4ListEntry } from '../common/iboxliste-v4-list-entry';
import { ICheck } from '../common/icheck';
import { ICheckHistory } from '../common/icheck-history';
import { IDHCPLease } from '../common/idhcplease';
import { IMachine } from '../common/imachine';
import { IRouterStatus } from '../common/irouter-status';
import { ITestPing } from '../common/itest-ping';
import { Machine } from '../common/machine';
import { MachineType } from '../common/machine-type';
import { SimpleMachineFilter } from '../common/simple-machine-filter';

@Injectable()
export class SysHealthService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public listMachines(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IMachine> = null
  ): Observable<IMEListResult<IMachine>> {
    const uri = MEApiTool.buildListURL(
      '/api/health/machines',
      max,
      offset,
      sort,
      filter
    );
    return this.http
      .get<IMEListResult<IMachine>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public getMachine(machineId = 0) {
    const uri = MEApiTool.buildEntityURL('/api/health/machines/', machineId);
    return this.http
      .get<IMachine>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public listChecksByMachine(
    machine: IMachine,
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<ICheck> = null,
    query: string = '',
    notOkOnly = false
  ): Observable<IMEListResult<ICheck>> {
    let params = null;
    if (notOkOnly) {
      params = { notOkOnly: true };
    }
    const uri = MEApiTool.buildListURL(
      '/api/health/machines/' + encodeURI('' + machine.id) + '/checks',
      max,
      offset,
      sort,
      filter,
      query,
      params
    );
    return this.http
      .get<IMEListResult<ICheck>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public storeCheck(entity: Check): Observable<IMEActionResponse> {
    if (entity === null || entity === undefined) {
      return null;
    }
    if (
      entity.machine === null ||
      entity.machine === undefined ||
      entity.machine.id === null ||
      entity.machine.id === undefined ||
      entity.machine.id < 1
    ) {
      return null;
    }

    const base =
      '/api/health/machines/' + encodeURI('' + entity.machine.id) + '/checks';

    if (entity.id === null || entity.id === undefined || entity.id < 1) {
      return this.http
        .post<IMEActionResponse>(
          base,
          entity,
          this.loginService.getRequestOptions()
        )
        .pipe(take(1));
    } else {
      const endpoint = MEApiTool.buildEntityURL(base, entity.id);
      return this.http
        .put<IMEActionResponse>(
          endpoint,
          entity,
          this.loginService.getRequestOptions()
        )
        .pipe(take(1));
    }
  }

  listServices(machine: Machine): Observable<any> {
    if (machine === null || machine === undefined) {
      return null;
    }
    const base =
      '/api/health/machines/' + encodeURI('' + machine.id) + '/services';
    return this.http
      .get(base, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  fetchMachines(type: MachineType): Observable<IMEListResult<IMachine>> {
    const f = new SimpleMachineFilter();
    f.machineType = type;
    return this.listMachines(100, 0, [], f);
  }

  public listChecks(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<ICheck> = null,
    query: string = '',
    notOkOnly = false
  ): Observable<IMEListResult<ICheck>> {
    let params = null;
    if (notOkOnly) {
      params = { notOkOnly: true };
    }

    const uri = MEApiTool.buildListURL(
      '/api/health/checks',
      max,
      offset,
      sort,
      filter,
      query,
      params
    );
    return this.http
      .get<IMEListResult<ICheck>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  reCheck(check: Check): Observable<IMEActionResponse> {
    if (check === null || check === undefined || check.id < 1) {
      return null;
    }
    const uri = MEApiTool.buildActionURL(
      '/api/health/checks',
      check.id,
      '_reCheck'
    );
    return this.http
      .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  restartService(check: Check): Observable<IMEActionResponse> {
    if (check === null || check === undefined || check.id < 1) {
      return null;
    }
    const uri = MEApiTool.buildActionURL(
      '/api/health/checks',
      check.id,
      '_restartService'
    );
    return this.http
      .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  killService(check: Check): Observable<IMEActionResponse> {
    if (check === null || check === undefined || check.id < 1) {
      return null;
    }
    const uri = MEApiTool.buildActionURL(
      '/api/health/checks',
      check.id,
      '_killService'
    );
    return this.http
      .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  deleteCheck(check: Check): Observable<IMEActionResponse> {
    if (
      check === null ||
      check === undefined ||
      check.id === null ||
      check.id === undefined ||
      check.id < 1
    ) {
      return null;
    }
    const uri = MEApiTool.buildEntityURL('/api/health/checks', check.id);
    return this.http
      .delete<IMEActionResponse>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  getCheckHistory(
    check: Check,
    machine: Machine,
    max: number,
    offset: number,
    sort: MEResultMetaSort[],
    filter: IMESimpleFilter<CheckHistory>,
    searchString: string
  ): Observable<IMEListResult<ICheckHistory>> {
    const params = null;

    let uri = '';
    if (check !== null && check !== undefined) {
      uri = MEApiTool.buildListURL(
        '/api/health/checks/' + encodeURI('' + check.id) + '/history',
        max,
        offset,
        sort,
        filter,
        searchString,
        params
      );
    } else if (machine !== null && machine !== undefined) {
      uri = MEApiTool.buildListURL(
        '/api/health/machines/' + encodeURI('' + machine.id) + '/history',
        max,
        offset,
        sort,
        filter,
        searchString,
        params
      );
    } else {
      uri = MEApiTool.buildListURL(
        '/api/health/history',
        max,
        offset,
        sort,
        filter,
        searchString,
        params
      );
    }

    return this.http
      .get<IMEListResult<ICheckHistory>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }

  getCheck(checkId: number): Observable<ICheck> {
    const uri = MEApiTool.buildEntityURL('/api/health/checks/', checkId);
    return this.http
      .get<ICheck>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  getLebenszeichen(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IAlive> = null,
    query: string = ''
  ): Observable<IMEListResult<IAlive>> {
    const params = null;

    const uri = MEApiTool.buildListURL(
      '/api/health/lebenszeichen',
      max,
      offset,
      sort,
      filter,
      query,
      params
    );

    return this.http
      .get<IMEListResult<IAlive>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  removeAllLebenszeichen(data: IAlive): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL(
      '/api/health/lebenszeichen',
      data.id,
      '_removeAll',
      null
    );
    return this.http
      .delete<IMEActionResponse>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  getLebenszeichenBodiesCount(): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildEndpointURL(
      '/api/health/lebenszeichen/numberOfBodies'
    );

    return this.http
      .get<IMEActionResponse>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  getTestPings(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<ITestPing> = null,
    query: string = ''
  ): Observable<IMEListResult<ITestPing>> {
    const params = null;

    const uri = MEApiTool.buildListURL(
      '/api/health/testpings',
      max,
      offset,
      sort,
      filter,
      query,
      params
    );

    return this.http
      .get<IMEListResult<ITestPing>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  getRouterStatus(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IRouterStatus> = null,
    query: string = ''
  ): Observable<IMEListResult<IRouterStatus>> {
    const params = null;

    const uri = MEApiTool.buildListURL(
      '/api/health/routerstatus',
      max,
      offset,
      sort,
      filter,
      query,
      params
    );

    return this.http
      .get<IMEListResult<IRouterStatus>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }

  public removeTestpings(id: number): Observable<IMEActionResponse> {
    const params = null;

    const uri = MEApiTool.buildActionURL(
      '/api/health/testpings',
      id,
      '_removeAll'
    );

    return this.http
      .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public getBoxliste(
    max: number,
    offset: number,
    sort: IMEResultMetaSort[] = [],
    filter: IMESimpleFilter<IBoxlisteListEntry>,
    query: string = ''
  ): Observable<IMEListResult<IBoxlisteListEntry>> {
    const params = null;

    const uri = MEApiTool.buildListURL(
      '/api/health/boxliste',
      max,
      offset,
      sort,
      filter,
      query,
      params
    );

    return this.http
      .get<IMEListResult<IBoxlisteListEntry>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }

  public getBoxlisteV4(
    max: number,
    offset: number,
    sort: IMEResultMetaSort[] = [],
    filter: IMESimpleFilter<IBoxlisteV4ListEntry>,
    query: string = ''
  ): Observable<IMEListResult<IBoxlisteV4ListEntry>> {
    const params = null;

    const uri = MEApiTool.buildListURL(
      '/api/health/boxlisteV4',
      max,
      offset,
      sort,
      filter,
      query,
      params
    );

    return this.http
      .get<IMEListResult<IBoxlisteV4ListEntry>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }

  public getDHCPLeases(
    max: number,
    offset: number,
    sort: IMEResultMetaSort[] = [],
    filter: IMESimpleFilter<IDHCPLease>,
    query: string = ''
  ): Observable<IMEListResult<IDHCPLease>> {
    const params = null;

    const uri = MEApiTool.buildListURL(
      '/api/health/dhcpleases',
      max,
      offset,
      sort,
      filter,
      query,
      params
    );

    return this.http
      .get<IMEListResult<IDHCPLease>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }
}
