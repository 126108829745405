import { Component, Injector } from '@angular/core';
import { SimStatus } from '../../../sys-health/common/sim-status';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';

@Component({
  selector: 'app-medtsim-status-renderer',
  template: `
    <app-sim-status-display
      *ngIf="isCellValid()"
      [value]="cell"
    ></app-sim-status-display>
  `
})
export class MEDTSimStatusRendererComponent extends MEDataTableCommonCellRenderer {
  public cell: SimStatus = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<any> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }
}
