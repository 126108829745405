<me-data-table
        *ngIf="clients!==null && clients!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="clients.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        (sortChanged)="clients.processSortChangedEvent($event)"
        #clientsTable
>
    <me-data-table-column *ngIf="machine_id_visible"
                          [label]="'Client-Kennung'"
                          [field]="'machineId'"
                          [sortable]="true"
    ></me-data-table-column>

    <me-data-table-column [label]="'Label'"
                          [field]="'label'"
                          [sortable]="true"
    ></me-data-table-column>

    <me-data-table-column [label]="'Info'"
                          [field]="'info'"
                          [sortable]="true"
    ></me-data-table-column>


    <ng-template #simTemplate let-p="item" let-row="row">
        <div *ngIf="row.data.simId>0">
            {{ p }}
            <button class="btn btn-sm btn-secondary" (click)="editSim(row.data.simId)" title="Simkarte bearbeiten"><i
                    class="far fa-edit"></i></button>
            <br/>
            <span *ngIf="row.data.simRufnummer!==null && row.data.simRufnummer!==undefined && row.data.simRufnummer!==''">
            Rufnummer: {{ row.data.simRufnummer }}
        </span>
        </div>
    </ng-template>

    <me-data-table-column *ngIf="showSim()"
                          [label]="'Sim'"
                          [field]="'simTitle'"
                          [template]="simTemplate"
                          [sortable]="true"
    ></me-data-table-column>


    <me-data-table-column [label]="'aktiviert?'"
                          [field]="'enabled'"
                          [sortable]="true"
                          type="Boolean"
    ></me-data-table-column>

    <ng-template #bytesTemplate let-p="item" let-row="row">
        <div class="text-end" title="empfangen">
            {{ p | mebytes }} <i class="far fa-arrow-to-left"></i>
        </div>
        <div class="text-end" title="gesendet">
            {{ row.data.bytesSent | mebytes }} <i class="far fa-arrow-from-left"></i>
        </div>
    </ng-template>

    <me-data-table-column [label]="'Traffic'"
                          [field]="'traffic'"
                          [sortable]="true"
                          [template]="bytesTemplate"
    ></me-data-table-column>

    <ng-template #lastUsedTemplate let-p="item" let-row="row">
        <span class="text-success" *ngIf="row.data.online">
        <i class="far fa-globe-europe"></i> online
        </span>
        <span class="text-danger" *ngIf="!row.data.online">
        <i class="far fa-globe-europe"></i> offline
        </span>
        <span *ngIf="row.data.lastIp!==null && row.data.lastIp!==undefined && row.data.lastIp"
              class="badge bg-secondary text-dark">
        {{ row.data.lastIp }}
        </span>
        <br/>
        {{ fDate(p, 'medium') }} <br/>
        {{ row.data.connections }} Verb. bisher
    </ng-template>
    <me-data-table-column [label]="'zuletzt gesehen'"
                          [field]="'lastSeen'"
                          [template]="lastUsedTemplate"
                          [sortable]="true"
    ></me-data-table-column>

    <me-data-table-action
            [title]="'Client anlegen'"
            [label]="'Hinzufügen'"
            (perform)="addClient()"
            [icon]="'far fa-plus'"
    ></me-data-table-action>

    <me-data-table-rowaction
            [title]="'Client bearbeiten'"
            [label]="'Bearbeiten'"
            (perform)="editClient($event)"
            [icon]="'far fa-edit'"
    ></me-data-table-rowaction>

    <me-data-table-rowaction
            *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN'])"
            [title]="'AT Kommandos ausführen'"
            [label]="'AT-Befehle'"
            (perform)="atTool($event)"
            [icon]="'far fa-tools'"
    ></me-data-table-rowaction>


    <me-data-table-table-extra-header>
        <me-pagination
                [total]="clients.total"
                [page]="clients.page"
                [perpage]="clients.size"
                (pageChange)="clients.setPage($event)"
                (pageCountChange)="clients.processPageCountChangedEvent($event)"
                (queryChange)="clients.processQueryChangedEvent($event)"
                settingsid="meconnect-csd-clients"
                [settingsService]="benutzerService"
                [showSearch]="true"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
