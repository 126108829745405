<div class="modal-header">
    <h4 class="modal-title">Peilungen filtern</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <me-tab-set>
        <me-pane title="Zeitbereich">
            <me-date-time-range-selector [(value)]="entity.zeitpunkt"></me-date-time-range-selector>

            <button class="btn btn-sm btn-secondary" (click)="setZP('heute')">heute</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP('woche')">diese Woche</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP('monat')">dieser Monat</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP(7)">7 Tage</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP(14)">14 Tage</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP(30)">30 Tage</button>
            <button class="btn btn-sm btn-secondary" (click)="setZP(90)">90 Tage</button>
        </me-pane>
    </me-tab-set>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="clearOk()">
        <i class="icofont icofont-eraser"></i> Filter leeren
    </button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="ok()">
        <i class="icofont icofont-filter"></i> Filter setzen
    </button>
</div>
