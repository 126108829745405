<div class="modal-header">
  <h4 class="modal-title">TestPings von {{ entity.router.bezeichnung }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <app-sys-health-test-pings-display
          [detailMode]="true"
          [device]="entity.device"
          [autostart]="true"
  ></app-sys-health-test-pings-display>

</div>
<div class="modal-footer d-flex justify-content-between">
  <div>
    <button class="btn btn-danger" (click)="remove()">
        <i class="icofont icofont-trash"></i> Alle Einträge löschen
    </button>
  </div>
  <div>
  <button type="button" class="btn btn-secondary" (click)="clearOk()">
    <i class="icofont icofont-eraser"></i> Filter leeren
  </button>
  <button type="button" class="btn btn-secondary" (click)="cancel()">
    <i class="icofont icofont-close"></i> Schließen
  </button>
  <button type="button" class="btn btn-primary" (click)="ok()">
    <i class="icofont icofont-filter"></i> Filter setzen
  </button>
  </div>
</div>
