import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {METabCardComponent, METool} from "../../maennl-commons";
import {TabApplicationsComponent} from "./tab-applications/tab-applications.component";

type TTabType = null
    | TabApplicationsComponent;

@Component({
    selector: 'app-applications',
    templateUrl: './applications.component.html',
    styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit, AfterViewInit {
    @ViewChild(METabCardComponent, {static:false}) tabs: METabCardComponent;
    @ViewChild('applicationsTab', {static:false}) ka: TabApplicationsComponent;

    private _currentTab: TTabType = null;

    public get currentTab(): TTabType {
        return this._currentTab;
    }

    public set currentTab(value: TTabType) {
        if(this._currentTab !== value) {
            if(!METool.isNullOrUndefined(this._currentTab)) {
                this._currentTab.onDeactivate();
            }
            this._currentTab = value;
            if(!METool.isNullOrUndefined(this._currentTab)) {
                this._currentTab.onActivate();
            }
        }
    }

    ngAfterViewInit(): void {
        this.currentTab = this.ka;
    }

    constructor() {}

    ngOnInit() {}

    activateTab(s:string) {
        const found = false;

        if(!found) {
            console.log(s);
        }
    }

    deactivateTab(s:string) {
        const found = false;

        if(!found) {
            console.log(s);
        }
    }

    public activate(tab: TTabType) {
        console.log(tab);
        this.currentTab = tab;
    }
}