import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {map, Observable, of} from "rxjs";
import {IMEListResult, MEApiTool, MEResultMetaSort} from "../../../maennl-commons";
import {IInventoryType} from "./inventory-type.interface";
import {take} from "rxjs/operators";
import {TNullableNumber} from "../../../maennl-commons/tools/types";
import {InventoryType} from "./inventory-type.class";
import {InventoryTypeImpl} from "./inventory-type.impl";
import {IMESimpleFilter} from "../../../maennl-commons/filters";

@Injectable({
    providedIn: 'root'
})
export class InventoryTypeService {
    constructor(
        public http: HttpClient,
        public loginService: BenutzerService,
    ){}

    public list(
        max=50,
        offset=0,
        sort:MEResultMetaSort[]=[],
        filter:IMESimpleFilter<IInventoryType>=null,
        queryString=''
    ):Observable<IMEListResult<IInventoryType>>{
        const uri = MEApiTool.buildListURL(
            'api/webfuel/inventoryType',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<IInventoryType>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1))
    }

    public get(id:TNullableNumber):Observable<InventoryType>{
        if(id===null||id===undefined||id<1){
            return of(null);
        }
        const uri = MEApiTool.buildEntityURL(
            'api/webfuel/inventoryType',
            id
        )
        return this.http
            .get<IInventoryType>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r=>{
                    return InventoryTypeImpl.fromResult(r)
                }),
            take(1)
            );
    }
}