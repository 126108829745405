import { Component, Injector } from '@angular/core';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { MEDataTableLocalizedCellRenderer } from '../../../maennl-commons/data-table/renderer';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';
import { MitschnittstreamPaket } from '../../common/mitschnittstream-paket';

@Component({
  selector: 'app-medtcustom-renderer1',
  template: `
    <div class="text-nowrap">
      {{ fDate(row.data.received, 'YYYY-MM-DD HH:mm:ss.SSS', true) }}
    </div>
    <div>
      {{ row.data.length | mebytes }}
    </div>
    <div *ngIf="row.data.senderMs > 0">
      Sender: {{ fNum(row.data.senderMs, '1.0-0') }} ms
    </div>
  `
})
export class MedtcustomRenderer1Component extends MEDataTableLocalizedCellRenderer {
  public cell: string = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<MitschnittstreamPaket> = null;

  public chunks: string[] = [];

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }
}
