<ng-container *ngIf="isAdd">
    <me-text-field [label]="'Code'" [(value)]="entity.code"></me-text-field >
</ng-container>
<ng-container *ngIf="!isAdd">
    <me-text-field [disabled]="true" [label]="'Code'" [(value)]="entity.code"></me-text-field >
</ng-container>
<me-num-field [label]="'Projekt'" [(value)]="entity.projekt"></me-num-field >
<me-text-field [label]="'Info'" [(value)]="entity.info"></me-text-field>
<me-boolean-field [label]="'aktiv?'" [(value)]="entity.enabled"></me-boolean-field>
<me-text-field [label]="'App Name'" [(value)]="entity.app_name"></me-text-field>
<me-boolean-field [label]="'Betreiber Männl?'" [(value)]="entity.betreiber_is_maennl"></me-boolean-field>
<me-text-field [label]="'Eingangspunkt'" [(value)]="entity.default_entry_point"></me-text-field>
<me-text-field [label]="'Logo'" [(value)]="entity.logo"></me-text-field>
<me-text-field [label]="'Import Pattern'" [(value)]="entity.terminal_import_pattern"></me-text-field>
<me-text-field [label]="'Admin Nachricht'" [(value)]="entity.admin_message"></me-text-field>
<me-text-field [type]="'email'" [label]="'Sender-Email'" [(value)]="entity.email_from"></me-text-field>
<me-text-field [label]="'Kurzname'" [(value)]="entity.short_name"></me-text-field>







