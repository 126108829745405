import { Injectable } from '@angular/core';
import { BenutzerService } from '../../benutzer/services/benutzer.service';
import { HttpClient } from '@angular/common/http';
import { MEResultMetaSort } from '../../maennl-commons/mesort-buttons';
import { IMESimpleFilter } from '../../maennl-commons/filters';
import { NozzleReaderLogEntry } from '../common/nozzle-reader-log-entry';
import { Observable } from 'rxjs';
import { IMEListResult } from '../../maennl-commons/mecommon-list';
import { INozzleReaderLogEntry } from '../common/inozzle-reader-log-entry';
import { MEApiTool } from '../../maennl-commons/network';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NozzleReaderRemoteLogService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  loadLogs(
    max: number = 10,
    offset: number = 0,
    sort: MEResultMetaSort[],
    filter: IMESimpleFilter<NozzleReaderLogEntry>,
    query: string = ''
  ): Observable<IMEListResult<INozzleReaderLogEntry>> {
    const params = null;

    const uri = MEApiTool.buildListURL(
      '/api/remote-logs/nozzle-reader',
      max,
      offset,
      sort,
      filter,
      query,
      params
    );

    return this.http
      .get<IMEListResult<INozzleReaderLogEntry>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }

  exportData(
    type: string = 'csv',
    filter: IMESimpleFilter<NozzleReaderLogEntry>,
    searchQuery: string = '',
    exporttype: number = 0,
    sort: MEResultMetaSort[] = null
  ): Observable<Blob> {
    const uri = MEApiTool.buildListURL(
      '/api/remote-logs/nozzle-reader.' + encodeURIComponent(type),
      -1,
      0,
      sort !== null
        ? sort
        : [
            new MEResultMetaSort('nr.vorgang', 'asc'),
            new MEResultMetaSort('nr.zeitpunkt', 'asc'),
            new MEResultMetaSort('nr.received', 'asc')
          ],
      filter,
      searchQuery,
      { exporttype: exporttype }
    );
    return this.http.get<Blob>(
      uri,
      this.loginService.getRequestOptions(false, true, true, 'blob')
    );
  }
}
