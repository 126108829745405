import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { EmptyFilter, IMESimpleFilter } from '../filters';
import { MELocalizedComponent } from '../localized-component';
import { MEResultMetaSort } from '../mesort-buttons';
import {
  MEDataTableActionComponent,
  MEDataTableExtraTableActionComponent,
  MEDataTableRowActionDirective,
} from './actions';
import { MEDataTableColumnComponent } from './columns';
import {
  IEditorComponent,
  TMECellEditorAddOnClickCallback,
  TMECellEditorChangedCallback,
} from './editors';
import {
  MEDataTableEditorAddOnClick,
  MEDataTableFilterChanged,
  MEDataTableRowEditCancelled,
  MEDataTableRowSelected,
  MEDataTableSaveRecord,
  MEDataTableSelectionChanged,
  MEDataTableSortChanged,
} from './events';
import { TMECellFilterChangedCallback } from './filters';
import { MEDataTableTableExtraHeaderComponent } from './medata-table-table-extra-header.component';
import { MEDataTableRow, TMEDTGetExtraRowClassesCallback } from './rows';
import { MEDTMouseOverEvent } from './types';

@Component({
  selector: 'me-data-table',
  styleUrls: ['./data-table.component.scss'],
  templateUrl: './data-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MEDataTableComponent
  extends MELocalizedComponent
  implements OnInit, AfterContentInit
{
  @ContentChildren(MEDataTableActionComponent)
  public tableactions: QueryList<MEDataTableActionComponent>;
  @ContentChildren(MEDataTableColumnComponent)
  public cols: QueryList<MEDataTableColumnComponent>;
  @ContentChildren(MEDataTableRowActionDirective)
  public rowactions: QueryList<MEDataTableRowActionDirective>;
  @ContentChildren(MEDataTableTableExtraHeaderComponent)
  extraActionContainers: QueryList<MEDataTableTableExtraHeaderComponent>;
  @ContentChildren(MEDataTableExtraTableActionComponent)
  extraTableActions: QueryList<MEDataTableExtraTableActionComponent>;
  @Input() public filter: IMESimpleFilter<any> = new EmptyFilter();
  @Input() public hideCheckboxes = false;
  @Input() public orders: MEResultMetaSort[] = [];
  @Input() public responsive = true;
  @Input() public rowActionsAsButtons = false;
  @Input() public selectable = true;
  @Input() public showInsertRow = false;
  @Input() public singleSelect = false;
  @Input() public tableActionsAsButtons = true;
  @Output() public newEntityChange = new EventEmitter<any>();
  @Output() public editorAddonClicked =
    new EventEmitter<MEDataTableEditorAddOnClick>();
  @Output() public filterChanged = new EventEmitter<MEDataTableFilterChanged>();
  @Output() public sortChanged = new EventEmitter<MEDataTableSortChanged>();
  @Output() public rowEditCancelled =
    new EventEmitter<MEDataTableRowEditCancelled>();
  @Output() public rowSelected = new EventEmitter<
    MEDataTableRowSelected<any>
  >();
  @Output() public saveRecord = new EventEmitter<MEDataTableSaveRecord>();
  @Output() public selectionChanged =
    new EventEmitter<MEDataTableSelectionChanged>();
  @Output() public mouseOver = new EventEmitter<MEDTMouseOverEvent>();
  public columns: MEDataTableColumnComponent[] = [];
  public self: MEDataTableComponent = this;
  public tabledata: Array<MEDataTableRow<any>> = [];
  extraContainerStyle = {};
  @Input() disabled = false;
  @Input() actionsicon = 'far fa-ellipsis-v';

  constructor(public zone: NgZone, private cd: ChangeDetectorRef) {
    super();
    this.self = this;
  }

  protected _newEntity: any = {};

  get newEntity(): any {
    return this._newEntity;
  }

  @Input()
  set newEntity(v: any) {
    this._newEntity = v;
    if (this.showInsertRow) {
      this.newEntityChange.emit(v);
    }
    this.markForCheck();
  }

  private _footerVisible = false;

  public get footerVisible(): any {
    return this._footerVisible;
  }

  @Input()
  public set footerVisible(value: any) {
    if (this._footerVisible !== value) {
      this._footerVisible = value;
      this.cd.markForCheck();
    }
  }

  private _footer2Visible = false;

  public get footer2Visible(): boolean {
    return this._footer2Visible;
  }

  @Input()
  public set footer2Visible(value: boolean) {
    if (this._footer2Visible !== value) {
      this._footer2Visible = value;
      this.cd.markForCheck();
    }
  }

  private _isLoading = false;

  public get isLoading(): boolean {
    return this._isLoading;
  }

  @Input()
  public set isLoading(value: boolean) {
    if (this._isLoading !== value) {
      this._isLoading = value;
      this.cd.markForCheck();
    }
  }

  @Input()
  set containerHeight(value: number) {
    if (
      value === null ||
      value === undefined ||
      isNaN(parseInt('' + value, 10)) ||
      value < 1
    ) {
      this.extraContainerStyle = { overflow: 'auto' };
    } else {
      this.extraContainerStyle = {
        'max-height.px': value,
        'overflow-y': 'scroll',
      };
    }
    this.cd.markForCheck();
  }

  @Input()
  set data(d: Array<MEDataTableRow<any>>) {
    this.tabledata = d;
    this.reinit();
  }

  // noinspection TsLint
  public onFilterChanged: TMECellFilterChangedCallback<any> = (
    field,
    value
  ) => {
    this.filterChanged.emit(new MEDataTableFilterChanged(field, value));
  };

  // noinspection TsLint
  public editorChanged: TMECellEditorChangedCallback<any> = (
    field,
    value,
    component: IEditorComponent
  ) => {
    if (value !== null && value !== undefined) {
      if (component.dataType === 'number') {
        value = parseInt('' + value, 10);
        if (isNaN(value as number)) {
          value = 0;
        }
        if (component.row === null || component.row === undefined) {
          this._newEntity[field] = value as number;
        } else {
          component.row.data[field] = value as number;
        }
        return;
      } else if (component.dataType === 'text') {
        value = '' + value;
        if (component.row === null || component.row === undefined) {
          this._newEntity[field] = value as string;
        } else {
          component.row.data[field] = value as string;
        }
        return;
      } else if (component.dataType === 'boolean') {
        value = !!value;
        if (component.row === null || component.row === undefined) {
          this._newEntity[field] = value as boolean;
        } else {
          component.row.data[field] = value as boolean;
        }
        return;
      }
    }
    if (component.row === null || component.row === undefined) {
      this._newEntity[field] = value;
    } else {
      component.row.data[field] = value;
    }
  };

  public onEditorAddOnClicked: TMECellEditorAddOnClickCallback = (
    field,
    addon,
    component: IEditorComponent
  ) => {
    this.editorAddonClicked.emit(
      new MEDataTableEditorAddOnClick(field, addon, component, this.self)
    );
  };

  @Input()
  public extraRowClasses: TMEDTGetExtraRowClassesCallback<any> = () => {
    return '';
  };

  public getRealFieldName(field: string): string {
    if (field.indexOf('~') > 0) {
      const p = field.split('~');
      return p[0];
    }
    return field;
  }

  public updateRenderers() {
    this.tabledata.forEach((r: MEDataTableRow<any>) => {
      this.columns.forEach(c => {
        if (r.renderers === null || r.renderers === undefined) {
          r.renderers = {};
        }
        const f = this.getRealFieldName(c.field);
        r.renderers[c.field] = c.getRenderer(r.data[f], r);
      });
    });
    this.cd.markForCheck();
  }

  public ngOnInit() {
    this.reinit();
  }

  public ngAfterContentInit() {
    this.reinit();
  }

  public reinit() {
    if (this.cols != null) {
      this.columns = this.cols.toArray();
    } else {
      this.columns = [];
    }
    this.updateRenderers();
    this.updateflags();
    this.cd.markForCheck();
  }

  public updateflags() {
    this.zone.run(() => {
      if (this.tabledata !== undefined) {
        if (this.tabledata !== null) {
          if (this.rowactions !== undefined) {
            if (this.rowactions !== null) {
              this.tabledata.forEach((d: MEDataTableRow<any>) => {
                this.rowactions.forEach((a: MEDataTableRowActionDirective) => {
                  a.checkIfEnabled(d);
                });
              });
            }
          }
        }
      }
    });
    this.cd.markForCheck();
  }

  public showFirstColumn(): boolean {
    return (
      this.showInsertRow ||
      (this.selectable && !this.hideCheckboxes) ||
      this.isFilterable()
    );
  }

  public hasSelectedRows(): boolean {
    let r = false;
    this.tabledata.forEach((row: MEDataTableRow<any>) => {
      r = r || row.selected;
    });
    return r;
  }

  public allRowsSelected(): boolean {
    let r = true;
    this.tabledata.forEach((row: MEDataTableRow<any>) => {
      if (!row.selected) {
        r = false;
      }
    });
    return r;
  }

  public isFilterable(): boolean {
    let r = false;
    if (this.filter !== undefined && this.filter !== null) {
      this.cols.forEach((c: MEDataTableColumnComponent) => {
        r = r || c.filterable;
      });
    }
    return r;
  }

  public selectAll() {
    if (!this.disabled) {
      let sc = false;
      if (this.allRowsSelected()) {
        this.tabledata.forEach((row: MEDataTableRow<any>) => {
          if (row.isSelected()) {
            row.unselect();
            sc = true;
          }
        });
      } else {
        this.tabledata.forEach((row: MEDataTableRow<any>) => {
          if (!row.isSelected()) {
            row.select();
            sc = true;
            this.rowSelected.emit(new MEDataTableRowSelected(this.self, row));
          }
        });
      }
      if (sc) {
        this.selectionChanged.emit(new MEDataTableSelectionChanged(this.self));
      }
    }
  }

  public hasTableActions(): boolean {
    return (
      (this.tableactions !== null &&
        this.tableactions !== undefined &&
        this.tableactions.length > 0) ||
      (this.extraTableActions !== null &&
        this.extraTableActions !== undefined &&
        this.extraTableActions.length > 0)
    );
  }

  public hasRowActions(): boolean {
    return this.rowactions.length > 0;
  }

  public hasActionsColumn(): boolean {
    return this.hasActions() || this.showInsertRow;
  }

  public hasActions(): boolean {
    return this.hasTableActions() || this.hasRowActions();
  }

  public tableActionToolbar(): boolean {
    return (
      this.tableActionsAsButtons === true ||
      ('' + this.tableActionsAsButtons).toLowerCase() === 'true'
    );
  }

  public toggleSelect(row: MEDataTableRow<any>) {
    if (!this.disabled) {
      let sc = false;
      let skip = false;
      this.zone.runOutsideAngular(() => {
        if (this.singleSelect) {
          this.tabledata.forEach((r: MEDataTableRow<any>) => {
            if (r.isSelected()) {
              if (r !== row) {
                r.unselect();
                sc = true;
              } else {
                r.unselect();
                skip = true;
              }
            }
          });
          if (!skip) {
            row.select();
            this.rowSelected.emit(new MEDataTableRowSelected(this.self, row));
          }
        } else {
          sc = true;
          row.toggleSelect();
          if (row.isSelected()) {
            this.rowSelected.emit(new MEDataTableRowSelected(this.self, row));
          }
        }
      });
      if (sc) {
        this.selectionChanged.emit(new MEDataTableSelectionChanged(this.self));
      }
    }
  }

  public onSortChanged(data: MEResultMetaSort) {
    this.sortChanged.emit(
      new MEDataTableSortChanged(data.field, data.direction, data.idx, data.add)
    );
  }

  public saveNew(): void {
    this.saveRecord.emit(
      new MEDataTableSaveRecord(this._newEntity, this.self, true)
    );
  }

  public saveRow(row: MEDataTableRow<any>): void {
    this.saveRecord.emit(new MEDataTableSaveRecord(row.data, this.self, false));
  }

  public cancelNew() {
    this.showInsertRow = false;
  }

  public showEditor() {
    this.showInsertRow = true;
  }

  public cancelRow(row: MEDataTableRow<any>): void {
    this.rowEditCancelled.emit(new MEDataTableRowEditCancelled(this.self, row));
  }

  public unselectAll() {
    if (!this.disabled) {
      let sc = false;
      if (this.tabledata !== undefined && this.tabledata !== null) {
        this.tabledata.forEach((r: MEDataTableRow<any>) => {
          if (r.isSelected()) {
            sc = true;
            r.unselect();
          }
        });
      }
      if (sc) {
        this.selectionChanged.emit(new MEDataTableSelectionChanged(this));
      }
    }
  }

  public selectFirst() {
    if (!this.disabled) {
      if (this.tabledata !== undefined && this.tabledata !== null) {
        if (this.singleSelect) {
          this.unselectAll();
        }
        if (this.tabledata.length > 0) {
          this.tabledata[0].select();
          this.rowSelected.emit(
            new MEDataTableRowSelected(this, this.tabledata[0])
          );
        }
      }
    }
  }

  hasTableActionContainers(): boolean {
    return (
      this.extraActionContainers !== null &&
      this.extraActionContainers !== undefined &&
      this.extraActionContainers.length > 0
    );
  }

  hasToolbar(): boolean {
    if (
      this.tabledata !== null &&
      this.tabledata !== undefined &&
      this.cols.length > 0 &&
      this.hasTableActions() &&
      this.tableActionToolbar()
    ) {
      return true;
    }
    return this.hasTableActionContainers();
  }

  getExtraRowClasses(
    row: MEDataTableRow<any>,
    idx,
    tabledata: Array<MEDataTableRow<any>>
  ) {
    if (this.extraRowClasses !== undefined && this.extraRowClasses !== null) {
      return this.extraRowClasses(row, idx, tabledata);
    }
    return '';
  }

  mouseOverRow($event: MouseEvent, row: MEDataTableRow<any>) {
    this.mouseOver.emit(new MEDTMouseOverEvent($event, row));
  }

  public showFooter() {
    return this._footerVisible;
  }

  public showFooter2() {
    return this._footer2Visible;
  }

  markForCheck() {
    this.cd.markForCheck();
  }
}
