<me-data-table
        *ngIf="verlauf!==null && verlauf!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="verlauf.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        [extraRowClasses]="rowClassGetter"
        (onSortChanged)="verlauf.processSortChangedEvent($event)"
        #verlaufTable
>

    <me-data-table-column
            field="label"
            [label]="_('Bezeichnung')"
            [sortable]="true"

    ></me-data-table-column>
    <me-data-table-column
            field="nummer"
            [label]="_('Nummer/Station')"
            [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column
            field="ip"
            [label]="_('IP-Adresse')"
            [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column
            field="msidsn"
            [label]="_('MSIDSN')"
            [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column
            field="provider"
            [label]="_('Provider')"
            [renderer]="providerRenderer"
            [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column
            field="status"
            [label]="_('Status')"
            [renderer]="statusRenderer"
            [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column
            field="lastSeen"
            type="Date"
            [label]="_('Meldezeitpunkt')"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
            [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column
        field="aktuellerStatus"
        [label]="_('aktueller Status')"
        [sortable]="true"
        [formatter]="statusFormatter"
    ></me-data-table-column>

    <me-data-table-table-extra-header>
        <me-pagination
                [total]="verlauf.total"
                [page]="verlauf.page"
                [perpage]="verlauf.size"
                (pageChange)="verlauf.setPage($event)"
                (pageCountChange)="verlauf.processPageCountChangedEvent($event)"
                [showSearch]="true"
                (queryChange)="verlauf.processQueryChangedEvent($event)"
                [pagecounts]="[10, 25, 50, 100,500,1000]"
                settingsid="syshealth-sim-status"
                [settingsService]="benutzerService"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
