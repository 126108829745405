import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { AbrechnungGlukoseportal } from './abrechnung-glukoseportal';
import { IAbrechnungGlukoseportal } from './iabrechnung-glukoseportal';
import { SimpleAbrechnungGlukoseportalFilter } from './simple-abrechnung-glukoseportal-filter';

export class AbrechnungGlukoseportalList extends MERemoteList<AbrechnungGlukoseportal> {
  __classname = 'AbrechnungGlukoseportalList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IAbrechnungGlukoseportal>
  ): AbrechnungGlukoseportalList {
    const newList = new AbrechnungGlukoseportalList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleAbrechnungGlukoseportalFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'periode',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return AbrechnungGlukoseportal.fromResult(entity);
  }
}
