import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  MESubscriptionHelper,
  METabCardComponent,
  noop
} from '../maennl-commons';
import { TabNozzleReaderLogsComponent } from './tab-nozzle-reader-logs/tab-nozzle-reader-logs.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-remote-log',
  templateUrl: './remote-log.component.html',
  styleUrls: ['./remote-log.component.scss']
})
export class RemoteLogComponent implements OnInit, OnDestroy, AfterViewInit {
  __classname = 'RemoteLogComponent';
  __instance = '';

  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;
  @ViewChild(TabNozzleReaderLogsComponent, { static: false })
  nozzleReaderTab: TabNozzleReaderLogsComponent;

  constructor(public cd: ChangeDetectorRef, public route: ActivatedRoute) {}

  ngOnInit() {
    noop();
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
  }

  ngAfterViewInit(): void {
    if (
      Object.keys(this.route.snapshot.queryParams).indexOf('selectTab') >= 0
    ) {
      this.tabs.selectById(this.route.snapshot.queryParams.selectTab);
    } else {
      this.tabs.selectById('tab-nozzlereader');
    }
    this.cd.markForCheck();
  }

  activateTab(id: string) {
    if (id === 'tab-nozzlereader') {
      if (this.nozzleReaderTab !== null && this.nozzleReaderTab !== undefined) {
        this.nozzleReaderTab.onActivate();
      }
    }
  }

  deactivateTab(id: string) {
    if (id === 'tab-nozzlereader') {
      if (this.nozzleReaderTab !== null && this.nozzleReaderTab !== undefined) {
        this.nozzleReaderTab.onDeactivate();
      }
    }
  }
}
