<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'phone-rotary']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="CSD-Server"
></me-area-heading>


<me-tab-card
    #rootTabCard
>

    <me-pane id="tab-dtus"
             title="Geräte"
             (activated)="activate(dtusTab)"
    >
        <app-tab-csdclients #dtusTab [type]="'dtu'"
                            [sim_visible]="true"
                            [machine_id_visible]="true"
        ></app-tab-csdclients>
    </me-pane>

    <me-pane id="tab-vcoms"
             title="virtuelle Coms"
             (activated)="activate(vcomsTab)"
    >
        <app-tab-csdclients #vcomsTab [type]="'vcom'"
                            [sim_visible]="false"
                            [machine_id_visible]="false"
        ></app-tab-csdclients>
    </me-pane>

    <me-pane id="tab-network"
             title="Netzwerk-Ports"
             (activated)="activate(networkTab)"
    >
        <app-tab-csdclients #networkTab [type]="'network'"
                            [sim_visible]="true"
                            [machine_id_visible]="true"
        ></app-tab-csdclients>
    </me-pane>

    <me-pane id="tab-bridges"
             title="Brücken"
             (activated)="activate(bridgesTab)"
    >
        <app-tab-csdbridges [qc_bridges]="false" #bridgesTab></app-tab-csdbridges>
    </me-pane>

    <me-pane id="tab-quickconnect-clients"
             title="QuickConnect Clients"
             (activated)="activate(qconnectTab)"
    >
        <app-tab-csdclients #qconnectTab [type]="'quick-connect'"
                            [sim_visible]="false"
                            [machine_id_visible]="true"
        ></app-tab-csdclients>
    </me-pane>
    <me-pane id="tab-quickconnect-bridges"
             title="QuickConnect Brücken"
             (activated)="activate(quickConnectBridgesTab)"
    >
        <app-tab-csdbridges #quickConnectBridgesTab  [qc_bridges]="true"></app-tab-csdbridges>
    </me-pane>
</me-tab-card>
