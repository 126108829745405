import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-netz-m2m',
  templateUrl: './netz-m2m.component.html',
  styleUrls: ['./netz-m2m.component.scss']
})
export class NetzM2MComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
