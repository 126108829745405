import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { SondeService } from '../../services/sonde.service';
import { Sonde } from '../../common/sonde';
import { ISonde } from '../../common/isonde';
import { Router } from '@angular/router';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';
import { PeilungenPopupComponent } from '../../peilungen-popup/peilungen-popup.component';
import { MEGenericModalComponent } from '../../../maennl-commons/megeneric-modal';
import { MERichError, noop } from '../../../maennl-commons/tools';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sonde-dashboard-display',
  templateUrl: './sonde-dashboard-display.component.html',
  styleUrls: ['./sonde-dashboard-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SondeDashboardDisplayComponent
  extends MELocalizedComponent
  implements OnInit
{
  @Input() sondeId = 0;

  sonde: Sonde = null;

  get zeitpunkt() {
    if (!this.isValid()) {
      return '';
    }
    return this.fDate(this.sonde.lastPeilungData.zeitpunkt, 'medium');
  }

  get bezeichnung(): string {
    if (!this.isValid()) {
      return '';
    }
    if (
      this.sonde.bezeichnung !== null &&
      this.sonde.bezeichnung !== undefined &&
      this.sonde.bezeichnung.trim() !== ''
    ) {
      return this.sonde.bezeichnung;
    }
    if (this.sonde.device !== null && this.sonde.device !== undefined) {
      return (
        ('Sonde ' + this.sonde.nummer).trim() +
        ' @ ' +
        this.sonde.device.bezeichnung
      );
    }
    return ('Sonde ' + this.sonde.nummer).trim();
  }

  get stand(): string {
    if (!this.isValid()) {
      return '';
    }
    let v = this.sonde.lastPeilungData.stand1;
    if (this.sonde.inverseMode) {
      v = this.sonde.maxHoehe - this.sonde.lastPeilungData.stand1;
    }
    return this.fNum(v, '1.1-1') + ' cm';
  }

  constructor(
    public sondeService: SondeService,
    public cd: ChangeDetectorRef,
    public modalService: NgbModal,
    public router: Router
  ) {
    super();
  }

  isValid() {
    return this.sondeId > 0 && this.sonde !== null && this.sonde !== undefined;
  }

  update() {
    this.sondeService.getSonde(this.sondeId).subscribe(
      (so: ISonde) => {
        this.sonde = Sonde.fromResult(so);
        this.cd.markForCheck();
      },
      () => {
        this.sonde = null;
        this.cd.markForCheck();
      }
    );
  }

  ngOnInit() {
    this.update();
  }

  gotoSonden() {
    this.router.navigate(['sonden']).finally();
  }

  showPeilungen() {
    this.sondeService.getSonde(this.sondeId).subscribe(
      (s: ISonde) => {
        PeilungenPopupComponent.open(
          this.modalService,
          PeilungenPopupComponent,
          s,
          MEGenericModalComponent.SIZE_LARGE
        ).then(noop, noop);
      },
      () => {
        MERichError.throw(
          'Sonde nicht gefunden',
          'Zu dieser Sonde wurden keine Daten gefunden'
        );
      }
    );
  }
}
