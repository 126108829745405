import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BenutzerService } from '../../benutzer/services/benutzer.service';
import { IMEActionResponse, MEApiTool } from '../../maennl-commons/network';
import { Dokument } from './dokument';
import { IDokument } from './idokument';

@Injectable({
  providedIn: 'root'
})
export class DokumentationService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public loadPage(slug: any): Observable<IDokument> {
    const uri = MEApiTool.buildEntityURL('/api/dokumentation/dokument', slug);
    return this.http
      .get<IDokument>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public storePage(doc: Dokument): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildEntityURL(
      '/api/dokumentation/dokument',
      doc.slug
    );

    if (doc.slug === '') {
      return this.http
        .post<IMEActionResponse>(
          uri,
          doc,
          this.loginService.getRequestOptions()
        )
        .pipe(take(1));
    }
    return this.http
      .put<IMEActionResponse>(uri, doc, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public deletePage(doc: Dokument): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildEntityURL(
      '/api/dokumentation/dokument',
      doc.slug,
      { doc_id: doc.id }
    );
    return this.http
      .delete<IMEActionResponse>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }
}
