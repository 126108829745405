import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { METabCardComponent, METool } from '../../maennl-commons';
import {TabVPN1Component} from "./tab-vpn1/tab-vpn1.component";
import {TabIPsecComponent} from "./tab-ipsec/tab-ipsec.component";
import {TabWireguardComponent} from "./tab-wireguard/tab-wireguard.component";
import {
    TabWireguardInterfaceComponent
} from "./tab-wireguard/tab-wireguard-interface/tab-wireguard-interface.component";
import {TabWireguardClientComponent} from "./tab-wireguard/tab-wireguard-client/tab-wireguard-client.component";

type TTabType =
    | null
    | TabVPN1Component
    | TabIPsecComponent
    | TabWireguardComponent
    | TabWireguardInterfaceComponent
    | TabWireguardClientComponent;

@Component({
    selector: 'app-vpn1',
    templateUrl: './vpn1.component.html',
    styleUrls: ['./vpn1.component.scss']
})
export class VPN1Component implements OnInit, AfterViewInit {
    @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;
    @ViewChild('vpn1Tab', { static: false }) ka: TabVPN1Component;

    private _currentTab: TTabType = null;

    public get currentTab(): TTabType {
        return this._currentTab;
    }

    public set currentTab(value: TTabType) {
        if (this._currentTab !== value) {
            if (!METool.isNullOrUndefined(this._currentTab)) {
                this._currentTab.onDeactivate();
            }
            this._currentTab = value;
            if (!METool.isNullOrUndefined(this._currentTab)) {
                this._currentTab.onActivate();
            }
        }
    }

    ngAfterViewInit(): void {
        this.currentTab = this.ka;
    }

    constructor() {}

    ngOnInit() {}

    activateTab(s: string) {
        const found = false;

        if (!found) {
            console.log(s);
        }
    }

    deactivateTab(s: string) {
        const found = false;

        if (!found) {
            console.log(s);
        }
    }

    public activate(tab: TTabType) {
        console.log(tab);
        this.currentTab = tab;
    }
}
