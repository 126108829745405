import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MEAddOnClickEvent } from '../../../maennl-commons/forms/events';
import { MEConfirmDialogComponent } from '../../../maennl-commons/meconfirm-dialog';
import { MEGenericModalComponent } from '../../../maennl-commons/megeneric-modal';
import { MEPubSubService } from '../../../maennl-commons/services';
import { MERichError, METool } from '../../../maennl-commons/tools';
import { CSDServerBridge } from '../commons/csdserver-bridge';
import { ICSDServerBridge } from '../commons/icsdserver-bridge';
import { CSDServerService } from '../services/csdserver.service';

@Component({
  selector: 'app-bridge-editor',
  templateUrl: './bridge-editor.component.html',
  styleUrls: ['./bridge-editor.component.scss']
})
export class BridgeEditorComponent
  extends MEGenericModalComponent<CSDServerBridge>
  implements OnInit, OnDestroy
{
  __classname = 'BridgeEditorComponent';

  public initEntity(src: ICSDServerBridge): CSDServerBridge {
    return CSDServerBridge.fromResult(src, true);
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public csdServerService: CSDServerService,
    public modalService: NgbModal,
    public cd: ChangeDetectorRef,
    public toastr: ToastrService
  ) {
    super(activeModal, pubsub);
  }

  public canSave() {
    if (METool.isNullOrUndefined(this.entity)) {
      return false;
    }

    if (METool.isEmpty(this.entity.info)) {
      return false;
    }

    if (METool.isNullOrUndefined(this.entity.client1)) {
      return false;
    }
    if (METool.isNullOrUndefined(this.entity.client1.id)) {
      return false;
    }

    if (
      !METool.isNullOrUndefined(this.entity.client2) &&
      this.entity.client1.id === this.entity.client2.id
    ) {
      return false;
    }

    return true;
  }

  public save() {
    if (this.canSave()) {
      this.csdServerService.storeBridge(this.entity).subscribe(
        (r) => {
          if (r.success) {
            this.activeModal.close(this.entity);
          } else {
            MERichError.throw(
              'Fehler beim Speichern',
              'Beim Speichern des Datensatzes ist ein Fehler aufgetreten.'
            );
          }
        },
        () => {
          MERichError.throw(
            'Fehler beim Speichern',
            'Beim Speichern des Datensatzes ist ein Fehler aufgetreten.'
          );
        }
      );
    }
  }

  public delete() {
    if (!METool.isNullOrUndefined(this.entity.id) && this.entity.id > 0) {
      MEConfirmDialogComponent.display(
        this.modalService,
        'Löschen bestätigen',
        'Sollen diese Brücke wirklich unwiderruflich gelöscht werden?',
        'Ja, jetzt löschen',
        'icofont icofont-delete'
      ).then(() => {
        this.csdServerService.removeBridge(this.entity).subscribe(
          (r) => {
            if (r.success) {
              this.activeModal.close();
            } else {
              MERichError.throw(
                'Fehler beim Löschen',
                'Beim Löschen des Clients ist ein Fehler aufgetreten.'
              );
            }
          },
          () => {
            MERichError.throw(
              'Fehler beim Löschen',
              'Beim Löschen des Clients ist ein Fehler aufgetreten.'
            );
          }
        );
      });
    }
  }

  public addonClick(field: string, $event: MEAddOnClickEvent) {
    if (field === 'login') {
      if ($event.addon === 0) {
        if (!METool.isNullOrUndefined(this.entity)) {
          this.entity.calculateLoginString();
        }
        this.cd.markForCheck();
      }
    }
  }

  public set_offline() {
    this.csdServerService.clearBridgeOnlineState(this.entity).subscribe(
      (r) => {
        if (r.success) {
          this.entity.online = false;
          this.cd.markForCheck();
          this.toastr.success('Onlinestatus wurde gelöscht');
        } else {
          MERichError.throw(
            'Fehler beim Löschen des Online-Status',
            'Beim Löschen des Online-Status ist ein Fehler aufgetreten.'
          );
        }
      },
      () => {
        MERichError.throw(
          'Fehler beim Löschen des Online-Status',
          'Beim Löschen des Online-Status ist ein Fehler aufgetreten.'
        );
      }
    );
  }
}
