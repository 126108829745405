import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Device } from '../common/device';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { SimpleNozzleReaderLogEntryFilter } from '../../remote-log/common/simple-nozzle-reader-log-entry-filter';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  MEPubSubService,
  MESubscriptionHelper
} from '../../maennl-commons/services';
import { RebootDialogOptions } from './reboot-dialog-options';

@Component({
  selector: 'app-router-reboot-confirm-dialog',
  templateUrl: './router-reboot-confirm-dialog.component.html',
  styleUrls: ['./router-reboot-confirm-dialog.component.scss']
})
export class RouterRebootConfirmDialogComponent
  extends MEGenericModalComponent<RebootDialogOptions>
  implements OnInit, OnDestroy
{
  __classname = 'RouterRebootConfirmDialogComponent';
  __instance = '';

  entity: RebootDialogOptions = null;

  public initEntity(src: RebootDialogOptions): RebootDialogOptions {
    return src;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public cd: ChangeDetectorRef
  ) {
    super(activeModal, pubsub);
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    super.ngOnDestroy();
  }
}
