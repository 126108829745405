import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MdEditorOption } from 'ngx-markdown-editor';
import { BenutzerService } from '../benutzer/services/benutzer.service';
import { MEConfirmDialogComponent, METool, noop } from '../maennl-commons';
import { MEActionResponse } from '../maennl-commons/network';
import { Dokument } from './commons/dokument';
import { DokumentationService } from './commons/dokumentation.service';

@Component({
  selector: 'app-dokumentation',
  templateUrl: './dokumentation.component.html',
  styleUrls: ['./dokumentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: true
})
export class DokumentationComponent implements OnInit {
  private _currentDokument = new Dokument(
    'Dokumentation',
    'Es wurde noch kein Dokument geladen'
  );

  private _editMode = false;

  private _editormode = 'editor';

  editorOptions: MdEditorOption = {
    resizable: true
  };
  private currentSlug = '';

  public;

  public get editormode(): string {
    return this._editormode;
  }

  public set editormode(value: string) {
    this._editormode = value;
    this.cd.markForCheck();
  }

  public get editMode(): boolean {
    return this._editMode;
  }

  public set editMode(value: boolean) {
    this._editMode = value;
    this.cd.markForCheck();
  }

  public get currentDokument(): Dokument {
    return this._currentDokument;
  }

  public set currentDokument(value: Dokument) {
    if (this._currentDokument !== value) {
      this._currentDokument = value;
      this.cd.markForCheck();
    }
  }

  constructor(
    public cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private dokumentationService: DokumentationService,
    private benutzerService: BenutzerService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.currentSlug = params.get('slug') || 'home';
      this.loadPage(this.currentSlug);
    });
  }

  createDoc(title: string, content = 'kein Inhalt', slug = '') {
    const doc = new Dokument();
    doc.slug = slug;
    doc.title = title;
    doc.content = content;
    this.currentDokument = doc;
  }

  loadPage(slug) {
    this.editMode = false;
    this.dokumentationService.loadPage(slug).subscribe(
      (pg) => {
        this.currentSlug = pg.slug;
        this.currentDokument = Dokument.fromResult(pg);
      },

      () => {
        if (slug === '') {
          this.createDoc('leeres Dokument', '', '');
        } else {
          this.createDoc(slug, 'kein Inhalt', slug);
        }
      }
    );
  }

  createDokument() {
    this.createDoc('Neues Dokument', 'Inhalt hier einfügen');
    this.editMode = true;
  }

  public editDokument() {
    this.editMode = true;
  }

  public canEdit() {
    if (this.currentDokument === null || this.currentDokument === undefined) {
      return false;
    }
    if (this.currentDokument.editable) {
      return true;
    }
    return (
      this.benutzerService.currentUser.id === this.currentDokument.lastEditorId
    );
  }

  public cancelEdit() {
    this.editMode = false;
    this.loadPage(this.currentSlug);
  }

  public storeDocument() {
    this.dokumentationService.storePage(this.currentDokument).subscribe((r) => {
      if (r.success) {
        const ar = MEActionResponse.fromRawActionResponse(r);
        this.currentSlug = ar.params['slug'];
        this.loadPage(this.currentSlug);
        this.router
          .navigate(['/dokumentation/' + this.currentSlug], {
            replaceUrl: true
          })
          .then(noop, noop);
      }
    });
  }

  public canSave() {
    if (!this.editMode) {
      return false;
    }

    if (METool.isNullOrUndefined(this.currentDokument)) {
      return false;
    }

    if (METool.isNullOrUndefined(this.currentDokument.title)) {
      return false;
    }

    if (this.currentDokument.title.trim() === '') {
      return false;
    }

    return true;
  }

  public deleteDokument() {
    MEConfirmDialogComponent.display(
      this.modalService,
      'Dokument löschen',
      'Soll das gezeigte Dokument wirklich gelöscht werden?',
      'Ja, Dokument jetzt löschen',
      'far fa-trash'
    ).then(() => {
      this.dokumentationService.deletePage(this.currentDokument).subscribe(
        () => {
          this.router.navigate(['/dokumentation/home']).then(noop, noop);
        },
        () => {}
      );
    }, noop);
  }
}
