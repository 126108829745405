import {
  Component,
  ComponentFactoryResolver,
  Injector,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { MEDataTableCellRendererComponent } from '../../../maennl-commons/data-table/renderer';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';
import { TestPing } from '../../common/test-ping';

@Component({
  selector: 'app-sys-health-test-ping-system-renderer',
  template: `
    <app-sys-health-test-ping-system-display
      [testping]="row.data"
    ></app-sys-health-test-ping-system-display>
  `
})
export class SysHealthTestPingSystemRendererComponent extends MEDataTableCellRendererComponent {
  public cell: boolean = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<TestPing> = null;
  constructor(
    public resolver: ComponentFactoryResolver,
    public _view: ViewContainerRef,
    public injector: Injector
  ) {
    super(resolver, _view);
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }
}
