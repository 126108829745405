import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BenutzerService } from '../../benutzer/services/benutzer.service';
import {
  MEDataTableComponent,
  MEDataTableRow,
  MERowActionParams,
  TMEDTGetExtraRowClassesCallback
} from '../../maennl-commons/data-table';
import { MELocalizedComponent } from '../../maennl-commons/localized-component';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { MESubscriptionHelper } from '../../maennl-commons/services';
import { MERichError, noop } from '../../maennl-commons/tools';
import { ContactListEntry } from '../common/contact-list-entry';
import { ContactListEntryList } from '../common/contact-list-entry-list';
import { IContact } from '../common/icontact';
import { TelefonanlageService } from '../services/telefonanlage.service';
import { ContactEditorComponent } from './contact-editor/contact-editor.component';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'ContactsComponent';
  __instance = '';
  tabActive = false;

  public kontakte: ContactListEntryList = new ContactListEntryList();
  @ViewChild(MEDataTableComponent, { static: false }) dt = null;

  constructor(
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    public benutzerService: BenutzerService,
    public telefonanlageService: TelefonanlageService,
    public modalService: NgbModal
  ) {
    super();
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.kontakte.onUpdateRequired.subscribe((list: ContactListEntryList) => {
        this.telefonanlageService
          .listContactsWithNumbers(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.getQuery()
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
            },
            (e) => {
              console.log(e);
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste der verfügbaren Kontakte konnte nicht abgerufen werden.'
              );
            }
          );
      }, undefined)
    );
  }

  ngAfterViewInit(): void {
    if (this.dt !== null) {
      this.dt.extraRowClasses = this.rowClassGetter;
    }
    this.onActivate();
  }

  rowClassGetter: TMEDTGetExtraRowClassesCallback<ContactListEntry> = (
    row: MEDataTableRow<ContactListEntry>,
    idx
  ) => {
    return '';
  };

  onActivate() {
    console.log('Benutzer aktiviert!');
    this.tabActive = true;
    this.kontakte.start();
  }

  onDeactivate() {
    console.log('Benutzer deaktiviert!');
    this.tabActive = false;
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.kontakte.release();
  }

  public addContact() {
    ContactEditorComponent.open(
      this.modalService,
      ContactEditorComponent,
      null,
      MEGenericModalComponent.SIZE_MEDIUM,
      null
    ).then(() => {
      this.kontakte.start();
    }, noop);
  }

  public editContact($event: MERowActionParams<ContactListEntry>) {
    this.telefonanlageService
      .getContact($event.row.data.contactId, true)
      .subscribe((contact: IContact) => {
        ContactEditorComponent.open(
          this.modalService,
          ContactEditorComponent,
          contact,
          MEGenericModalComponent.SIZE_MEDIUM,
          null
        ).then(() => {
          this.kontakte.start();
        }, noop);
      }, noop());
  }
}
