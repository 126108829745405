import {InventoryType} from "./inventory-type.class";
import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {InventoryTypeImpl} from "./inventory-type.impl";
import {SimpleInventoryTypeFilter} from "./inventory-type.filter";
import {IInventoryType} from "./inventory-type.interface";

export class InventoryTypeList
    extends MERemoteList<InventoryType>{
    __classname:string = 'InventoryTypeList';
    __instance = '';

    static fromListResult(
        res:IMEListResult<IInventoryType>
    ):InventoryTypeList{
        const newList=new InventoryTypeList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun: boolean=false,timeout:number=400) {
        super();
        this.simpleFilter = new SimpleInventoryTypeFilter();
        this.initList(autorun,timeout);
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'code',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true
    ) {
        super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    }

    convertEntity(entity: any): any {
        return InventoryTypeImpl.fromResult(entity);
    }
}