import {IWireguardInterface} from "./wireguard-interface.interface";
import {MEGenericEntity} from "../../../maennl-commons";
import {TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";
import {TNullableWireguardClientList} from "./wireguard-client.types";

export class WireguardInterface
extends MEGenericEntity<IWireguardInterface>
implements IWireguardInterface
{
    id:TNullableNumber=null;
    display_name:TNullableString=null;
    public_key:TNullableString=null;
    private_key:TNullableString=null;
    port:TNullableNumber=null;
    info:TNullableString=null;
    peers:TNullableNumber=null;

    _clients:TNullableWireguardClientList=null;

    constructor() {
        super();
    }
}