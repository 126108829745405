<div class="row">
    <div class="col-6 text-nowrap">
        # CPUs
    </div>
    <div class="col-6 text-nowrap">
        {{ testping.numCpus }}
    </div>
</div>
<div class="row">
    <div class="col-6 text-nowrap">
        °C
    </div>
    <div class="col-6 text-nowrap">
    <span
            [class.badge]="testping.temperatur>47"
            [class.bg-warning]="testping.temperatur>47 && testping.temperatur<55"
            [class.text-dark]="testping.temperatur>47 && testping.temperatur<55"
            [class.bg-danger]="testping.temperatur>=55"
            [class.text-light]="testping.temperatur>=55"
    >
    {{ fNum(testping.temperatur, '1.1-1') }}
    </span>
    </div>
</div>
<div class="row">
    <div class="col-6 text-nowrap">
        Last
    </div>
    <div class="col-6 text-nowrap">
        {{ fNum(testping.cpuPercent, '1.2-2') }} %
    </div>
</div>
