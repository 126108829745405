import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';
import {
  MEDataTableComponent,
  MEDataTableRow,
  TMEDTGetExtraRowClassesCallback
} from '../../../maennl-commons/data-table';
import { RouterStatusList } from '../../common/router-status-list';
import { SysHealthService } from '../../services/sys-health.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RouterStatus } from '../../common/router-status';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { MERichError, noop } from '../../../maennl-commons/tools';
import { MEDTSimProviderRendererComponent } from '../../../router/controls/medtsim-provider-renderer/medtsim-provider-renderer.component';
import { MEDTSimStatusRendererComponent } from '../../../router/controls/medtsim-status-renderer/medtsim-status-renderer.component';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import { TMECellRendererValueFormatter } from '../../../maennl-commons/data-table/renderer/types';

@Component({
  selector: 'app-sys-health-router-status-display',
  templateUrl: './sys-health-router-status-display.component.html',
  styleUrls: ['./sys-health-router-status-display.component.scss']
})
export class SysHealthRouterStatusDisplayComponent
  extends MELocalizedComponent
  implements OnInit
{
  __classname = 'SysHealthRouterStatusDisplayComponent';
  __instance = '';

  @Input()
  autostart = false;

  public providerRenderer = MEDTSimProviderRendererComponent;
  public statusRenderer = MEDTSimStatusRendererComponent;

  @ViewChild(MEDataTableComponent, { static: false }) dt = null;

  public verlauf: RouterStatusList = new RouterStatusList();

  constructor(
    public healthService: SysHealthService,
    public toastr: ToastrService,
    protected cd: ChangeDetectorRef,
    public modalService: NgbModal,
    public benutzerService: BenutzerService
  ) {
    super();
  }

  rowClassGetter: TMEDTGetExtraRowClassesCallback<RouterStatus> = (
    row: MEDataTableRow<RouterStatus>,
    idx
  ) => {
    const styles = [];
    if (
      row !== null &&
      row !== undefined &&
      row.data !== null &&
      row.data !== undefined &&
      row.data.lastSeen !== null &&
      row.data.lastSeen !== undefined
    ) {
      styles.push('row-sim-status-' + row.data.status.code);
      styles.push('row-sim-aktueller_status-' + row.data.aktuellerStatus);
    }
    return styles.join(' ');
  };

  public statusFormatter: TMECellRendererValueFormatter<RouterStatus> = (
    value: string,
    column,
    row
  ) => {
    if (value === 'fresh') {
      return this._('Meldung vor kurzem erfolgt');
    } else if (value.startsWith('aktiv')) {
      return this._('OK');
    } else if (
      value.startsWith('overdue') &&
      row.data.status.code !== 'aktiv-ohne-meldung'
    ) {
      return this._('überfällig!');
    } else if (
      value.startsWith('due') &&
      row.data.status.code !== 'aktiv-ohne-meldung'
    ) {
      return this._('fällig');
    }
    return '';
  };

  ngAfterViewInit() {
    if (this.dt !== null) {
      this.dt.extraRowClasses = this.rowClassGetter;
    }
    if (this.autostart) {
      this.onActivate();
    }
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.verlauf.onUpdateRequired.subscribe((list: RouterStatusList) => {
        this.healthService
          .getRouterStatus(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.searchString
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
            },
            (e) => {
              console.log(e);
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste des verfügbaren Status konnte nicht abgerufen werden'
              );
            }
          );
      }, undefined)
    );

    if (this.dt !== null) {
      this.dt.extraRowClasses = this.rowClassGetter;
    }
  }

  onActivate() {
    this.verlauf.start();
  }

  onDeactivate() {
    noop();
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.verlauf.release();
  }
}
