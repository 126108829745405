import {
  MEGenericEntityConverterParams,
  MEGenericEntityFields,
} from '../../../maennl-commons';
import { CheckPingImpl } from './check-ping.impl';
import { ICheckPing } from './check-ping.interface';
import { TNullableCheckPingList } from './check-ping.types';
import { GruppeImpl } from './gruppe.impl';
import { IGruppe } from './gruppe.interface';
import { TNullableGruppeList } from './gruppe.types';
import { NetworkAddress } from './network-address.class';
import {
  TNetworkAddress,
  TNullableNetworkAddress,
} from './network-address.types';

export class NetworkAddressImpl {
  static fromResult(
    entity: TNetworkAddress,
    createIfNull = false
  ): TNullableNetworkAddress {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new NetworkAddress();
      }
      return null;
    }

    const instance = new NetworkAddress();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongFields([
          'id',
          'addr_family',
          'checks_num_ok',
          'checks_num_total',
          'mask_len',
        ])
        .addDecimalField('checks_success_ratio')
        .addTextFields(['address', 'info'])
        .addBooleanFields([
          'enabled',
          'is_m2m',
          'checks_successfull',
          'is_host',
          'is_maennl_apn',
          'is_maennlex_apn',
          'ignore_check_problems',
        ])
        .addConverterField(
          '_groups',
          new MEGenericEntityConverterParams<TNullableGruppeList>(
            (value: TNullableGruppeList) => {
              if (
                value === null ||
                value === undefined ||
                !Array.isArray(value)
              ) {
                return [];
              }
              return (value as IGruppe[])
                .map((v: IGruppe) => {
                  return GruppeImpl.fromResult(v, false);
                })
                .filter(v => v !== null && v !== undefined);
            }
          )
        )
        .addConverterField(
          '_checks',
          new MEGenericEntityConverterParams<TNullableCheckPingList>(
            (value: TNullableCheckPingList) => {
              if (
                value === null ||
                value === undefined ||
                !Array.isArray(value)
              ) {
                return [];
              }
              return (value as ICheckPing[])
                .map((v: ICheckPing) => {
                  return CheckPingImpl.fromResult(v, false);
                })
                .filter(v => v !== null && v !== undefined);
            }
          )
        ),
      e => {
        if (
          (e as NetworkAddress)._groups === null ||
          (e as NetworkAddress)._groups === undefined
        ) {
          (<NetworkAddress>e)._groups = [];
        }
        if (
          (e as NetworkAddress)._checks === null ||
          (e as NetworkAddress)._checks === undefined
        ) {
          (<NetworkAddress>e)._checks = [];
        }
      }
    );

    return instance;
  }
}
