import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';

import {
  MEGenericModalComponent,
  MEPubSubService,
} from '../../../maennl-commons';
import { NetworkAddress } from '../commons/network-address.class';
import { NetworkAddressImpl } from '../commons/network-address.impl';
import { NetworkAddressService } from '../commons/network-address.service';
import {
  TNetworkAddress,
  TNullableNetworkAddress,
} from '../commons/network-address.types';

@Component({
  selector: 'app-ip-address-editor',
  templateUrl: './ip-address-editor.component.html',
  styleUrls: ['./ip-address-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpAddressEditorComponent
  extends MEGenericModalComponent<NetworkAddress>
  implements OnInit, OnDestroy
{
  entity: TNullableNetworkAddress = null;

  public initEntity(src: TNetworkAddress): TNullableNetworkAddress {
    return NetworkAddressImpl.fromResult(src, true);
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public service: NetworkAddressService,
    public toastr: ToastrService,
    public cd: ChangeDetectorRef
  ) {
    super(activeModal, pubsub);
  }

  ngOnInit(): void {
    this.cd.markForCheck();
  }

  save() {
    this.service.store(this.entity).subscribe({
      next: r => {
        if (r.success) {
          this.ok();
        } else {
          this.toastr.error('Fehler beim Speichern der Daten');
        }
      },
      error: () => {
        this.toastr.error('Fehler beim Speichern der Daten');
      },
    });
  }
}
