import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, take } from 'rxjs';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import {
  IMEActionResponse,
  IMEListResult,
  MEActionResponse,
  MEApiTool,
  MEResultMetaSort,
} from '../../../maennl-commons';
import { IMESimpleFilter } from '../../../maennl-commons/filters';
import { TNullableNumber } from '../../../maennl-commons/tools/types';
import { CheckPing } from './check-ping.class';
import { CheckPingImpl } from './check-ping.impl';
import { ICheckPing } from './check-ping.interface';

@Injectable({
  providedIn: 'root',
})
export class CheckPingService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  base_endpoint(address_id: TNullableNumber): string {
    return (
      MEApiTool.buildEntityURL(
        '/api/meconnect/netzwerk/addresses',
        address_id
      ) + '/checks'
    );
  }

  public list(
    address_id: number,
    max = 50,
    offset = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<ICheckPing> = null,
    queryString = ''
  ): Observable<IMEListResult<ICheckPing>> {
    const uri = MEApiTool.buildListURL(
      this.base_endpoint(address_id),
      max,
      offset,
      sort,
      filter,
      queryString
    );
    return this.http
      .get<IMEListResult<ICheckPing>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }

  public store(
    address_id: number,
    entity: CheckPing
  ): Observable<MEActionResponse> {
    if (address_id === null || address_id === undefined || address_id < 1) {
      return of(MEActionResponse.error('address_id is null or undefined'));
    }

    const uri = MEApiTool.buildEntityURL(
      this.base_endpoint(address_id),
      entity.id
    );
    console.log('uri: ' + uri);

    if (entity.id === null || entity.id === undefined || entity.id < 1) {
      return this.http
        .post<IMEActionResponse>(
          uri,
          entity,
          this.loginService.getRequestOptions()
        )
        .pipe(
          map(r => {
            return MEActionResponse.fromRawActionResponse(r);
          }),
          take(1)
        );
    } else {
      return this.http
        .put<IMEActionResponse>(
          uri,
          entity,
          this.loginService.getRequestOptions()
        )
        .pipe(
          map(r => {
            return MEActionResponse.fromRawActionResponse(r);
          }),
          take(1)
        );
    }
  }

  public get(address_id: number, id: TNullableNumber): Observable<CheckPing> {
    if (
      address_id === null ||
      address_id === undefined ||
      address_id < 1 ||
      id === null ||
      id === undefined ||
      id < 1
    ) {
      return of(null);
    }
    const uri = MEApiTool.buildEntityURL(this.base_endpoint(address_id), id);

    return this.http
      .get<ICheckPing>(uri, this.loginService.getRequestOptions())
      .pipe(
        map(r => {
          return CheckPingImpl.fromResult(r);
        }),
        take(1)
      );
  }

  public delete(
    address_id: number,
    id: TNullableNumber
  ): Observable<MEActionResponse> {
    if (
      address_id === null ||
      address_id === undefined ||
      address_id < 1 ||
      id === null ||
      id === undefined ||
      id < 1
    ) {
      return of(MEActionResponse.error('id is null or undefined'));
    }
    const uri = MEApiTool.buildEntityURL(this.base_endpoint(address_id), id);

    return this.http
      .delete<IMEActionResponse>(uri, this.loginService.getRequestOptions())
      .pipe(
        map(r => {
          return MEActionResponse.fromRawActionResponse(r);
        }),
        take(1)
      );
  }

  public run(
    address_id: number,
    id: TNullableNumber
  ): Observable<MEActionResponse> {
    if (
      address_id === null ||
      address_id === undefined ||
      address_id < 1 ||
      id === null ||
      id === undefined ||
      id < 1
    ) {
      return of(MEActionResponse.error('id is null or undefined'));
    }
    const uri = MEApiTool.buildActionURL(
      this.base_endpoint(address_id),
      id,
      '_runCheck'
    );

    return this.http
      .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
      .pipe(
        map(r => {
          return MEActionResponse.fromRawActionResponse(r);
        }),
        take(1)
      );
  }
}
