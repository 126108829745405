import { EventEmitter } from '@angular/core';

export class MEKitchenTimer extends EventEmitter<MEKitchenTimer> {
  public timeoutFunc = null;
  public tout = 400;
  public fired = false;

  constructor(timeout: number = 400) {
    super();
    this.setTimeout(timeout);
  }

  public setTimeout(timeout: number) {
    this.tout = timeout;
  }

  public fire() {
    this.fired = true;
    this.emit(this);
    this.timeoutFunc = null;
  }

  public start(force = false) {
    if (force) {
      this.stop();
      this.fire();
      return;
    }
    if (this.timeoutFunc === null) {
      this.timeoutFunc = setTimeout(() => {
        this.fire();
        this.timeoutFunc = null;
      }, this.tout);
    }
  }

  public stop() {
    if (this.timeoutFunc !== null) {
      clearTimeout(this.timeoutFunc);
      this.timeoutFunc = null;
    }
  }

  public rewind() {
    this.stop();
    this.start();
  }

  // noinspection JSUnusedGlobalSymbols
  public restart() {
    this.rewind();
  }

  public end() {
    if (this.timeoutFunc !== null) {
      clearTimeout(this.timeoutFunc);
      this.timeoutFunc = null;
      this.fire();
    }
  }

  public reset() {
    this.stop();
    this.fired = false;
  }

  public wasFired(): boolean {
    return this.fired;
  }

  public fireWhenNotBefore(force: boolean = false) {
    if (!this.wasFired()) {
      this.start(force);
    }
  }
}
