import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { AbrechnungHoyer } from './abrechnung-hoyer';
import { IAbrechnungHoyer } from './iabrechnung-hoyer';
import { SimpleAbrechnungHoyerFilter } from './simple-abrechnung-hoyer-filter';

export class AbrechnungHoyerList extends MERemoteList<AbrechnungHoyer> {
  __classname = 'AbrechnungHoyerList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IAbrechnungHoyer>
  ): AbrechnungHoyerList {
    const newList = new AbrechnungHoyerList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleAbrechnungHoyerFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'periode',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return AbrechnungHoyer.fromResult(entity);
  }
}
