import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MEGenericModalComponent,
  MEPubSubService,
  METool,
} from '../../../maennl-commons';
import { VehicleEditorOptions } from './vehicle-editor-options';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { VehicleService } from '../../vehicles/commons/vehicle.service';

@Component({
  selector: 'app-vehicle-editor-popup',
  templateUrl: './vehicle-editor-popup.component.html',
  styleUrls: ['./vehicle-editor-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleEditorPopupComponent
  extends MEGenericModalComponent<VehicleEditorOptions>
  implements OnInit, OnDestroy
{
  entity: VehicleEditorOptions = null;

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public cd: ChangeDetectorRef,
    public toastr: ToastrService,
    public vehicleService: VehicleService
  ) {
    super(activeModal, pubsub);
  }

  public initEntity(src: VehicleEditorOptions): VehicleEditorOptions {
    const f = new VehicleEditorOptions();
    if (src !== null && src !== undefined) {
      f.copyFrom(src);
    }
    return f;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.entity.cd = this.cd;
  }

  can_save() {
    if (METool.isNullOrUndefined(this.entity)) {
      return false;
    }
    if (METool.isEmpty(this.entity.nummer)) {
      return false;
    }
    if (METool.isEmpty(this.entity.display_text)) {
      return false;
    }
    return true;
  }

  ok() {
    this.vehicleService
      .patch(this.entity.vehicle_id, this.entity.data)
      .subscribe({
        next: r => {
          if (r.success) {
            this.toastr.success('Fahrzeugdaten wurden gespeichert');
            super.ok();
          } else {
            this.toastr.error('Fehler beim Speichern der Fahrzeugdaten');
          }
        },
        error: () => {
          this.toastr.error('Fehler beim Speichern der Fahrzeugdaten');
        },
      });
  }
}
