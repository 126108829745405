import {
  TNullableNumber,
  TNullableString,
} from 'src/app/maennl-commons/tools/types';
import {
  MEGenericEntity,
  MEGenericEntityFields,
} from '../../../maennl-commons';
import { INozzleReader } from './inozzle-reader';

export class NozzleReader
  extends MEGenericEntity<INozzleReader>
  implements INozzleReader
{
  date_created: Date | null = null;
  display_text: TNullableString = null;
  first_reading: Date | null = null;
  id: TNullableNumber = null;
  inventory_type: TNullableNumber = null;
  last_reading: Date | null = null;
  last_updated: Date | null = null;
  mac_address: TNullableString = null;
  mac_address_short: TNullableString = null;
  num_readings: TNullableNumber = null;
  nummer: TNullableNumber = null;
  settings_text: TNullableString = null;
  station: TNullableNumber = null;
  station_display_text: TNullableString = null;
  station_nummer: TNullableNumber = null;
  tankserver_id: TNullableNumber = null;
  tenant: TNullableNumber = null;
  tenant_code: TNullableString = null;
  tenant_info: TNullableString = null;
  tenant_projekt: TNullableNumber = null;
  terminal_address: TNullableString = null;
  terminal_customer: TNullableString = null;
  terminal_dev_addr: TNullableString = null;
  terminal_display_text: TNullableString = null;
  terminal_id: TNullableNumber = null;
  terminal_nummer: TNullableNumber = null;
  terminal_type_code: TNullableString = null;
  terminal_type_display_text: TNullableString = null;
  letzte_spannung: TNullableNumber = null;
  letzte_spannung_zeitpunkt: Date | null = null;
  state: TNullableString = null;

  constructor() {
    super();
  }

  static fromResult(
    entity: INozzleReader,
    createIfNull: boolean = false
  ): NozzleReader {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new NozzleReader();
      }
      return null;
    }

    const instance = new NozzleReader();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongFields([
          'id',
          'tenant_projekt',
          'station_nummer',
          'terminal_nummer',
          'inventory_type',
          'station',
          'nummer',
          'tankserver_id',
          'tenant',
          'terminal_id',
          'num_readings',
          'letzte_spannung',
        ])
        .addTextFields([
          'tenant_code',
          'tenant_info',
          'station_display_text',
          'terminal_display_text',
          'terminal_type_code',
          'terminal_type_display_text',
          'terminal_address',
          'terminal_dev_addr',
          'terminal_customer',
          'mac_address_short',
          'display_text',
          'settings_text',
          'mac_address',
          'state',
        ])
        .addDateFields([
          'date_created',
          'last_updated',
          'first_reading',
          'last_reading',
          'letzte_spannung_zeitpunkt',
        ])
    );
    return instance;
  }
}
