<div class="modal-header">
    <h4 class="modal-title" *ngIf="isAdd()">Eintrag anlegen</h4>
    <h4 class="modal-title" *ngIf="isEdit()">Eintrag bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <app-firewall-field
            label="Firewall"
            [(valueId)]="entity.firewall"></app-firewall-field>
    <me-boolean-field
            label="aktiv?"
            [(value)]="entity.check_enabled"></me-boolean-field>
    <me-num-field
            label="Test-Interval (in Sekunden)"
            [(value)]="entity.check_interval_secs"></me-num-field>
    <me-num-field
            label="Anzahl der Versuche pro Check"
            [(value)]="entity.check_tries"></me-num-field>
    <me-num-field
            label="Wartezeit zw. Versuchen in ms"
            [(value)]="entity.check_delay"></me-num-field>
    <me-num-field
            label="# Fehler bis Meldung"
            title="Anzahl der Fehlschläge bis ein Test als fehlerhaft gilt"
            [(value)]="entity.error_count"></me-num-field>
    <me-num-field
            label="Größe des PING-Payloads"
            title="Anzahl der Bytes der Nutzlast (0-230)"
            [(value)]="entity.payload_size"></me-num-field>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i> Daten speichern
    </button>
</div>
