import { MEGenericEntity } from '../../../maennl-commons';
import {
  TNullableBoolean,
  TNullableNumber,
  TNullableString
} from '../../../maennl-commons/tools/types';
import { IBlocklistV4 } from './blocklist-v4.interface';

export class BlocklistV4
  extends MEGenericEntity<IBlocklistV4>
  implements IBlocklistV4
{
  enabled: TNullableBoolean = null;
  id: TNullableNumber = null;
  info: TNullableString = null;
  ip: TNullableString = null;
  is_valid: TNullableBoolean = null;

  constructor() {
    super();
  }
}
