<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'shield']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="VPN Linux-Server"
></me-area-heading>

<div class="d-flex justify-content-between flex-wrap">
    <me-card [title]="'verbundene Clients'"></me-card>
    <me-card [title]="'verfügbare Routen'"></me-card>
</div>
