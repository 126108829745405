import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeNetmask',
})
export class RemoveNetmaskPipe implements PipeTransform {
  transform(value: string): string {
    if (value === null || value === undefined) {
      return '';
    }
    return value.split('/')[0].trim();
  }
}
