<ng-template #ahIcon>
    <i class="me-icon me-icon-sonde"></i>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Sonden und Peilungen"
></me-area-heading>

<me-tab-card>
    <me-pane title="Sonden"
             id="tab-sonden"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
    >
        <app-tab-sonden></app-tab-sonden>
    </me-pane>
</me-tab-card>