<ng-template #ahIcon>
    <fa-icon [icon]="['fas', 'chart-network']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="ME Connect"
></me-area-heading>

<me-card>
    <button routerLink="/meconnect/csd" class="btn btn-sm btn-primary">CSD Server</button>
</me-card>
