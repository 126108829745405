import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { Check } from './check';
import { ICheck } from './icheck';
import { SimpleCheckFilter } from './simple-check-filter';

export class CheckList extends MERemoteList<Check> {
  __classname = 'CheckList';
  __instance = '';

  static fromListResult(res: IMEListResult<ICheck>): CheckList {
    const newList = new CheckList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleCheckFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'info',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return Check.fromResult(entity);
  }
}
