<div class="modal-header">
    <h5 class="modal-title">Sim-Karte bearbeiten</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="alert alert-info">
        Achtung! Einige dieser Angaben werden eim Import/Synchronisation der Simdaten aus
        der alten Datenbank überschrieben!
    </div>

    <me-text-field label="Bezeichnung" [(value)]="entity.label"></me-text-field>
    <app-sim-provider-field label="Dienstanbieter" [(value)]="entity.simProvider"
                            [nullable]="true"></app-sim-provider-field>
    <me-tab-set>
        <me-pane title="Erreichbarkeit">
            <me-text-field label="IMSI" [(value)]="entity.imsi"></me-text-field>
            <me-text-field label="MSIDSN (Rufnummer)" [(value)]="entity.msidsn"></me-text-field>
            <me-text-field label="IP-Adresse" [(value)]="entity.ip"></me-text-field>
            <me-boolean-field label="direkter Zugriff auf die IP möglich?" labelWidth="3"
                              [(value)]="entity.directAccess"></me-boolean-field>
        </me-pane>
        <me-pane title="Tarif">
            <me-text-field label="Tarif" [(value)]="entity.tarif"></me-text-field>
            <me-num-field label="inkl. Volumen (MB)" [(value)]="entity.inklTaffic"></me-num-field>
        </me-pane>
        <me-pane title="Sonstiges">
            <me-boolean-field label="Karte gesperrt" [(value)]="entity.gesperrt"></me-boolean-field>
            <me-text-field [multiline]="true" label="Info" [rows]="8" [(value)]="entity.info"></me-text-field>
        </me-pane>
    </me-tab-set>


</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Abbrechen
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i>
        Änderungen speichern
    </button>
</div>
