import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {IMEListResult, MEActionResponse, MEApiTool, MEResultMetaSort, METool} from "../../../maennl-commons";
import {IMESimpleFilter} from "../../../maennl-commons/filters";
import {TenantHosts} from "./tenant-hosts.class";
import {map, Observable, of} from "rxjs";
import {ITenantHosts} from "./tenant-hosts.interface";
import {take} from "rxjs/operators";
import {TNullableNumber} from "../../../maennl-commons/tools/types";
import {TenantHostsImpl} from "./tenant-hosts.impl";

@Injectable({
    providedIn: 'root'
})
export class TenantHostsService {

    constructor(
        public http: HttpClient,
        public loginService: BenutzerService
    ) {
    }

    public list(
        max = 50,
        offset = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<TenantHosts> = null,
        queryString = ''
    ): Observable<IMEListResult<ITenantHosts>> {

        const uri = MEApiTool.buildListURL(
            'api/webfuel/core/tenanthosts',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<ITenantHosts>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public get(id: TNullableNumber): Observable<TenantHosts> {
        if (id === null || id === undefined || id < 1) {
            return of(null);
        }
        const uri = MEApiTool.buildEntityURL(
            'api/webfuel/core/tenanthosts',
            id
        );
        return this.http
            .get<ITenantHosts>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return TenantHostsImpl.fromResult(r);
                }),
                take(1)
            );
    }

    public store(entity: TenantHosts): Observable<MEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            'api/webfuel/core/tenanthosts',
            entity.id
        )

        if (entity.id === null || entity.id === undefined || entity.id < 1) {
            return this.http
                .post<MEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r => {
                        return MEActionResponse.fromRawActionResponse(r);
                    }),
                    take(1)
                );
        } else {
            return this.http
                .put<MEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r => {
                        return MEActionResponse.fromRawActionResponse(r);
                    }),
                    take(1)
                );
        }
    }

    public delete(id: TNullableNumber): Observable<MEActionResponse> {
        if (METool.isNullOrUndefined(id) || id < 1) {
            return of(MEActionResponse.error('id is null or undefined'));
        }

        const uri = MEApiTool.buildEntityURL(
            'api/webfuel/core/tenanthosts',
            id
        );

        return this.http
            .delete<MEActionResponse>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }
}