import {MEGenericEntity} from "../../../maennl-commons";
import {IImportsJobs} from "./imports-jobs.interface";
import {TAPIDate, TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";

export class ImportsJobs
    extends MEGenericEntity<IImportsJobs>
    implements IImportsJobs {

    _tenant_info: TNullableString=null;
    _typ_info: TNullableString=null;
    date_created: TAPIDate=null;
    file_data: TNullableString=null;
    filename: TNullableString=null;
    id: TNullableNumber=null;
    import_started: TAPIDate=null;
    imported: TNullableNumber=null;
    last_updated: TAPIDate=null;
    next_try: TAPIDate=null;
    size: TNullableNumber=null;
    tenant: TNullableNumber=null;
    typ: TNullableNumber=null;
}