<div class="modal-header">
    <h4 class="modal-title">Benutzer bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">

    <me-text-field [label]="'Kennung'" [(value)]="entity.username"></me-text-field>
    <me-text-field [label]="'Name'" [(value)]="entity.fullname"></me-text-field>
    <me-boolean-field [label]="'sperren'" [(value)]="!entity.enabled"></me-boolean-field>
    <me-text-field [label]="'E-Mail'" [(value)]="entity.email"></me-text-field>
    <me-text-field [label]="'Telegram-Username'" [(value)]="entity.telegramUsername"></me-text-field>
</div>
<div class="modal-footer d-flex justify-content-between">
    <div>
        <button type="button" class="btn btn-danger" (click)="delete()" *ngIf="entity.id>0">
            <i class="icofont icofont-delete"></i> Löschen
        </button>
    </div>
    <div>
        <button type="button" class="btn btn-secondary" (click)="cancel()">
            <i class="icofont icofont-close"></i> Schließen
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!canSave()">
            <i class="icofont icofont-save"></i> Speichern
        </button>
    </div>
</div>
