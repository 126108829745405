import {
    MEGenericEntityConverterParams,
    MEGenericEntityFields,
} from '../../maennl-commons';
import {Authority} from './authority.class';
import {TAuthority, TNullableAuthority} from './authority.types';
import {Benutzer} from './benutzer';
import {IBenutzer} from './ibenutzer';
import {TNullableBenutzerList} from './benutzer.types';

export class AuthorityImpl {
    static fromResult(entity: TAuthority, createIfNull = false): TNullableAuthority {
        if (entity === null || entity === undefined) {
            if (createIfNull) {
                return new Authority();
            }
            return null;
        }

        const instance = new Authority();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongFields(['id'])
                .addTextFields(['version', 'authority'])
                .addConverterField(
                    '_members',
                    new MEGenericEntityConverterParams<TNullableBenutzerList>(
                        (value: TNullableBenutzerList) => {
                            if (
                                value === null ||
                                value === undefined ||
                                !Array.isArray(value)
                            ) {
                                return [];
                            }
                            return (value as IBenutzer[])
                                .map((v: IBenutzer) => {
                                    return Benutzer.fromResult(v, false);
                                })
                                .filter(v => v !== null && v !== undefined);
                        }
                    )
                )
        );

        return instance;
    }
}
