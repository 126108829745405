import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Output,
  QueryList
} from '@angular/core';
import { MEPaneComponent } from './mepane.component';

@Component({
  selector: 'me-tab-set',
  template: `
    <ul class="nav nav-tabs">
      <li class="nav-item" *ngFor="let p of panes">
        <a
          class="nav-link"
          [class.active]="p.active"
          [class.disabled]="p.disabled"
          (click)="selectByClick(p)"
        >
          <i class="{{ p.icon }}" *ngIf="p.hasIcon()"></i>
          {{ p.title }}
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .nav-item a {
        cursor: pointer;
      }

      .tab-content {
        padding: 10px;
        border-left: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class METabsetComponent implements AfterContentInit, AfterViewChecked {
  @ContentChildren(MEPaneComponent) public cpanes: QueryList<MEPaneComponent>;
  @Output() onTabSelect: EventEmitter<MEPaneComponent> =
    new EventEmitter<MEPaneComponent>();
  panes: MEPaneComponent[] = [];
  public currentPane: MEPaneComponent;

  constructor(protected cd: ChangeDetectorRef) {}

  public ngAfterContentInit(): void {
    this.cpanes.forEach((p: MEPaneComponent) => {
      this.panes.push(p);
    });
  }

  public select(pane: MEPaneComponent, deferEvent: number = 0) {
    this.currentPane = pane;
    this.panes.forEach((p: MEPaneComponent) => {
      const h = p.forceEvents;
      if (p.active) {
        p.forceEvents = true;
      }
      p.active = p === pane;
      p.forceEvents = h;
    });
    this.cd.markForCheck();
    if (deferEvent > 0) {
      setTimeout(() => {
        this.onTabSelect.emit(pane);
      }, deferEvent);
    } else {
      this.onTabSelect.emit(pane);
    }
  }

  public selectById(id: string, deferEvent: number = 0) {
    this.panes.forEach((pane) => {
      if (pane.id === id) {
        this.select(pane, deferEvent);
      }
    });
  }

  ngAfterViewChecked(): void {
    if (
      (this.currentPane === null || this.currentPane === undefined) &&
      this.panes.length > 0
    ) {
      this.select(this.panes[0]);
    }
  }

  selectByClick(p: MEPaneComponent) {
    if (p !== this.currentPane) {
      this.select(p);
    }
  }
}
