import { IMEListResult, MERemoteList } from '../../../maennl-commons';
import { IRBCustomer } from './irbcustomer';
import { RBCustomer } from './rbcustomer';
import { SimpleRBCustomerFilter } from './simple-rbcustomer-filter';

export class RBCustomerList extends MERemoteList<RBCustomer> {
  __classname = 'RBCustomerList';
  __instance = '';

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleRBCustomerFilter();
    this.initList(autorun, timeout);
  }

  static fromListResult(res: IMEListResult<IRBCustomer>): RBCustomerList {
    const newList = new RBCustomerList();
    newList.populateFromListResult(res);
    return newList;
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'display_text',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return RBCustomer.fromResult(entity);
  }
}
