<div
        class="d-flex align-items-start justify-content-between"
        *ngIf="taJobsListe !== null && taJobsListe !== undefined">
    <div class="flex-fill">
        <me-data-table
            #wgInterfaceTable
            [rowActionsAsButtons]="true"
            [tableActionsAsButtons]="true"
            [data]="taJobsListe.asDataTableRows()"
            [selectable]="false"
            [singleSelect]="false"
            [hideCheckboxes]="true"
            actionsicon="icofont icofont-navigation-menu">
            <me-data-table-column
                    [label]="'ID'"
                    [field]="'id'"></me-data-table-column>
            <ng-template #tajobinfo let-p="item" let-row="row">
                <div title="Job Info">
                    {{jobinfo(p)}}
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'Job Info'"
                    [field]="'kind'"
                    [template]="tajobinfo">
                ></me-data-table-column>
            <ng-template #tenant let-p="item" let-row="row">
                <div title="Mandant">
                    [{{p}}]{{row.data._tenant_info}}
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'Mandant'"
                    [field]="'tenant'"
                    [template]="tenant"></me-data-table-column>
            <me-data-table-column
                    [label]="'Standort'"
                    [field]="'_station_display_text'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Fehler Info'"
                    [field]="'info'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Terminal Info'"
                    [field]="'_terminal_display_text'">
            ></me-data-table-column>
            <me-data-table-column
                    [label]="'in Bearbeitung'"
                    [field]="'_in_progress'"
                    type="boolean"
            ></me-data-table-column>

            <me-data-table-rowaction
                    (perform)="removeTAJobs($event)"
                    icon="far fa-trash"
                    label="Löschen"
                    title="Eintrag löschen"
            ></me-data-table-rowaction>

            <me-data-table-table-extra-header>
                <me-pagination
                        [total]="taJobsListe.total"
                        [page]="taJobsListe.page"
                        [perpage]="taJobsListe.size"
                        (pageChange)="taJobsListe.setPage($event)"
                        (pageCountChange)="taJobsListe.processPageCountChangedEvent($event)"
                        (queryChange)="taJobsListe.processQueryChangedEvent($event)"
                        settingsid="ta-jobs-info"
                        [settingsService]="benutzerService"
                        [showSearch]="false"></me-pagination>
            </me-data-table-table-extra-header>
        </me-data-table>
    </div>
</div>