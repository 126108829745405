<div class="modal-header">
    <h4 class="modal-title">Hoyer Abrechnung</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <iframe id="printpreview" name="printpreview" src="about:blank"
            style="width:100%; height:450px; border: 1px solid #AAAAAA;">
    </iframe>
    <div id="previewData">
        <app-abrechnung-hoyer-print-preview
                [abrechnung]="entity"
        ></app-abrechnung-hoyer-print-preview>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="print()">
        <fa-icon [icon]="['far', 'print']"></fa-icon>
        Drucken
    </button>
    <button type="button" class="btn btn-primary" (click)="ok()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
</div>
