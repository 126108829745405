import { IMEListResult, MERemoteList } from '../../../maennl-commons';
import { NetworkAddress } from './network-address.class';
import { NetworkAddressFilter } from './network-address.filter';
import { NetworkAddressImpl } from './network-address.impl';
import { INetworkAddress } from './network-address.interface';

export class NetworkAddressList extends MERemoteList<NetworkAddress> {
  __classname: string = 'NetworkAddressV4List';
  __instance = '';

  static fromListResult(
    res: IMEListResult<INetworkAddress>
  ): NetworkAddressList {
    const newList = new NetworkAddressList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new NetworkAddressFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'address',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return NetworkAddressImpl.fromResult(entity);
  }
}
