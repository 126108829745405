import { Component, OnDestroy, OnInit } from '@angular/core';
import { MELocalizedComponent } from '../localized-component';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { MEPubSubService, MESubscriptionHelper } from '../services';

@Component({
  selector: 'me-generic-modal',
  template: '<ng-content></ng-content>'
})
export class MEGenericModalComponent<T>
  extends MELocalizedComponent
  implements OnInit, OnDestroy
{
  static SIZE_SMALL = 'sm';
  static SIZE_LARGE = 'lg';
  static SIZE_MEDIUM = 'md';

  __classname = 'MEGenericModalComponent<T>';
  __instance = '';

  public meta: any = {};

  public entity: T;
  public mode = 'add';

  static open(
    modalService: NgbModal,
    content,
    element: any = null,
    size: String = MEGenericModalComponent.SIZE_MEDIUM,
    meta: any = {}
  ): Promise<any> {
    const o: NgbModalOptions = {
      size:
        size === MEGenericModalComponent.SIZE_SMALL
          ? 'sm'
          : size === MEGenericModalComponent.SIZE_LARGE
          ? 'lg'
          : undefined,
      backdrop: 'static',
      keyboard: false
    };
    const r: NgbModalRef = modalService.open(content, o);
    (r.componentInstance as MEGenericModalComponent<any>).meta = meta;
    (r.componentInstance as MEGenericModalComponent<any>).open(element);
    return r.result;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService
  ) {
    super();
  }

  isAdd() {
    return this.mode === 'add';
  }

  isEdit() {
    return this.mode === 'edit';
  }

  public initEntity(src: T): T {
    throw new Error('method not overridden!');
  }

  public open(element: T = null) {
    if (element === null || element === undefined) {
      this.mode = 'add';
    } else {
      this.mode = 'edit';
    }

    this.entity = this.initEntity(element);
  }

  cancel() {
    this.activeModal.dismiss('cancel');
  }

  ok() {
    this.activeModal.close(this.entity);
  }

  clear() {
    if (eval('typeof this.entity.clear') === 'function') {
      eval('this.entity.clear()');
    }
  }

  clearOk() {
    this.clear();
    this.ok();
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.pubsub.loginError.subscribe(
        () => {
          this.cancel();
        },
        () => {}
      )
    );
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
  }
}
