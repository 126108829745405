import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {
    MEConfirmDialogComponent,
    MELocalizedComponent,
    MERowActionParams,
    MESubscriptionHelper
} from "../../../maennl-commons";
import {TerminalList} from "../commons/terminal.list";
import {TerminalService} from "../commons/terminal.service";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {ToastrService} from "ngx-toastr";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TerminalFilter} from "../commons/terminal.filter";
import {Terminal} from "../commons/terminal.class";

@Component({
    selector: 'app-tab-terminal-malfunctioning',
    templateUrl: './tab-terminal-malfunctioning.component.html',
    styleUrls: ['./tab-terminal-malfunctioning.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabTerminalMalfunctioningComponent
    extends MELocalizedComponent
    implements OnInit, OnDestroy, AfterViewInit {
    @Input() id = '';
    terminalListe = new TerminalList();

    private _tabActive = false;

    public get tabActive(): boolean {
        return this._tabActive
    }

    @Input()
    public set tabActive(value: boolean) {
        if (this._tabActive !== value) {
            this._tabActive = value;
            this.cd.markForCheck();
            if (this._tabActive) {
                this.terminalListe.start();
            }
        }
    }

    constructor(
        public cd: ChangeDetectorRef,
        public terminalService: TerminalService,
        public benutzerService: BenutzerService,
        public modalService: NgbModal,
        public toastr: ToastrService
    ) {
        super();
    }

    ngOnInit() {
        MESubscriptionHelper.add(
            this,
            this.terminalListe.onUpdateRequired.subscribe(() => {
                this.cd.markForCheck();
                if (this.terminalListe.loading) {
                    return;
                }
                this.terminalListe.loading = true;
                (this.terminalListe.simpleFilter as TerminalFilter).display_mode = 'fail';
                this.terminalService.list(
                    this.terminalListe.size,
                    this.terminalListe.calcOffset(),
                    this.terminalListe.order,
                    this.terminalListe.simpleFilter,
                    this.terminalListe.getQuery()
                )
                    .subscribe({
                        next: l => {
                            this.terminalListe.doPopulateFromListResult(l);
                            this.terminalListe.loading = false;
                            this.cd.markForCheck();
                        },
                        error: () => {
                            this.terminalListe.clear();
                            this.terminalListe.loading = false;
                        }
                    });
                this.cd.markForCheck();
            })
        )
    }

    onActivate() {
        this.tabActive=true;
    }

    onDeactivate() {
        this.tabActive=false;
    }

    ngOnDestroy() {
        this.terminalListe.release();
        MESubscriptionHelper.release(this);
    }

    public ngAfterViewInit() {
    }


    resetLastFailure($event: MERowActionParams<any>) {
        if (this.terminalService.get($event.row.data.id) !== null && this.terminalService.get($event.row.data.id) !== undefined) {
            MEConfirmDialogComponent.display(
                this.modalService,
                "Terminal Fehler zurücksetzen",
                "Wollen Sie die Fehler des Terminals wirklich zurücksetzen?",
                'Ja, jetzt zurücksetzen',
            ).then(()=>{
                this.terminalService.get($event.row.data.id).subscribe((t:Terminal)=>{
                    if (t.id!==null && t.id!==undefined && t.id > 0) {
                        this.terminalService.resetLastFailure(t.id).subscribe((r) => {
                            if (r.success) {
                                this.toastr.info(
                                    'Fehler des Terminals wurde erfolgreich zurückgesetzt',
                                )
                                this.terminalListe.start();
                            }
                        });
                    }
                });
            }, () => {
                this.toastr.error(
                    'Fehler des Terminals konnten nicht zurückgesetzt werden',
                )
            });
        }
    }
}