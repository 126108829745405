import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { AbrechnungPositionArt } from './abrechnung-position-art';
import { IAbrechnungPosition } from './iabrechnung-position';

export class AbrechnungPosition
  extends MEGenericEntity<IAbrechnungPosition>
  implements IAbrechnungPosition
{
  abrechnungId = 0;
  abrechnungType = '';
  art: AbrechnungPositionArt = null;
  epreis = 0;
  gpreis = 0;
  id = 0;
  menge = 0;
  pos = 0;
  text = '';
  title = '';
  abgerechnet: Date = null;

  constructor() {
    super();
  }

  static fromResult(
    entity: IAbrechnungPosition,
    createIfNull: boolean = false
  ): AbrechnungPosition {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new AbrechnungPosition();
      }
      return null;
    }

    const instance = new AbrechnungPosition();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addLongField('abrechnungId')
        .addTextField('abrechnungType')
        .addLongField('abrechnungId')
        .addConverterField(
          'art',
          new MEGenericEntityConverterParams<AbrechnungPositionArt>(
            (value: any) => {
              return AbrechnungPositionArt.fromResult(value);
            }
          )
        )
        .addDecimalField('epreis')
        .addDecimalField('gpreis')
        .addDecimalField('menge')
        .addDateField('abgerechnet')
        .addLongField('pos')
        .addTextField('text')
        .addTextField('title')
    );
    return instance;
  }

  public getHTML() {
    return this.text.replace(/\n/g, '<br />\n');
  }
}
