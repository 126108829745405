import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {IPSecInfo} from "./ipsec-info.class";

export class IPSecInfoList extends MERemoteList<IPSecInfo>{
    __classname: string = 'IPSecInfoList';
    __instance = '';

    static fromListResult(
        res:IMEListResult<IPSecInfo>
    ):IPSecInfoList{
        const newList= new IPSecInfoList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun: boolean = false, timeout: number = 400){
        super();
        this.initList(autorun,timeout)
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'name',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true) {
        super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    }
}