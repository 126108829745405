import { Component, Injector, OnInit } from '@angular/core';
import {
  MEDataTableColumnComponent,
  MEDataTableLocalizedCellRenderer,
  MEDataTableRow
} from '../../../maennl-commons';
import { PreisSendung } from '../commons/preis-sendung';

@Component({
  selector: 'app-transfer-renderer',
  templateUrl: './transfer-renderer.component.html',
  styleUrls: ['./transfer-renderer.component.scss']
})
export class TransferRendererComponent extends MEDataTableLocalizedCellRenderer {
  public cell: any = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<PreisSendung> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }

  into_typed(): PreisSendung[] {
    if (!this.isRowValid()) {
      return [];
    }
    return [this.row.data];
  }
}
