<ng-template #ahIcon>
    <fa-icon [icon]="['fas', 'circle-location-arrow']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="webfuel Fahrzeug-Tracker"
></me-area-heading>

<me-tab-card
    #rootTabCard
>

    <me-pane id="tab-tracker"
             title="Tracker"
             (activated)="activate(trackerTab)"
    >
        <app-tab-vehicle-tracker #trackerTab></app-tab-vehicle-tracker>
    </me-pane>
    <me-pane id="tab-commands"
             title="SMS-Befehle"
             (activated)="activate(commandsTab)"
    >
        <app-tab-tracker-smscommands #commandsTab></app-tab-tracker-smscommands>
    </me-pane>
</me-tab-card>
