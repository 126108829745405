<div
        class="d-flex align-items-start justify-content-between"
        *ngIf="liste !== null && liste !== undefined">
    <div class="flex-fill">
        <me-data-table
            #ipsecTable
            [rowActionsAsButtons]="true"
            [tableActionsAsButtons]="true"
            [data]="liste.asDataTableRows()"
            [selectable]="true"
            [singleSelect]="true"
            [hideCheckboxes]="true"
            actionsicon="icofont icofont-navigation-menu"
            (rowSelected)="onRowSelected($event)">
            <me-data-table-column
                    [label]="'ID'"
                    [field]="'unique_id'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Name'"
                    [field]="'name'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Zustand'"
                    [field]="'state'"></me-data-table-column>
            <ng-template #bridge let-p="item" let-row="row">
                    <span class="badge bg-gray text-light">
                        {{p}}:{{row.data.local_port}} <=> {{row.data.remote_host}}:{{row.data.remote_port}}
                    </span>
            </ng-template>
            <me-data-table-column
                    [label]="'Brücke (local <=> remote)'"
                    [field]="'local_host'"
                    [template]="bridge"></me-data-table-column>
            <me-data-table-column
                    [label]="'NAT-any'"
                    [field]="'nat_any'"
                    type="Boolean"
            ></me-data-table-column>

            <me-data-table-column
                    [label]="'NAT remote'"
                    [field]="'nat_remote'"
                    type="Boolean"
            ></me-data-table-column>

            <me-data-table-column
                    [label]="'SAs'"
                    [field]="'child_length'"
            ></me-data-table-column>

            <me-data-table-table-extra-header>
                <me-pagination
                        [total]="liste.total"
                        [page]="liste.page"
                        [perpage]="liste.size"
                        (pageChange)="liste.setPage($event)"
                        (pageCountChange)="liste.processPageCountChangedEvent($event)"
                        (queryChange)="liste.processQueryChangedEvent($event)"
                        settingsid="ipsec-info"
                        [settingsService]="benutzerService"
                        [showSearch]="false"></me-pagination>
            </me-data-table-table-extra-header>
        </me-data-table>
    </div>
    <ng-container #detailTabs *ngIf="ipsecTable.hasSelectedRows()" [ngTemplateOutlet]="details">
    </ng-container>
</div>
<ng-template #details>
    <ng-container *ngIf="current.child_length>0">
        <div class="ml-2 ms-2 ps-2 pl-2" style="min-width: 450px">
            <me-tab-card>

                <ng-container *ngFor="let child of current.child_sas">
                    <me-pane id="subtab-{{child.name}}-{{child.unique_id}}"
                             title="{{child.name+'-'+child.unique_id}}">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="flex-fill ms-2 ml-2">
                                <span>Name: {{ child.name }}<br>
                                ID: {{ child.unique_id }}<br>
                                    Modus: {{ child.mode }}<br>
                                Zustand: {{ child.state }}<br>
                                    Verbunden seit: {{fDate(child.install_time_date, 'medium')}}<br>
                                    Local: {{child.local_ts}}<br>
                                    Remote: {{child.remote_ts}}<br>
                                    Bytes empfangen: {{child.bytes_in|mebytes}}<br>
                                    Bytes gesendet: {{child.bytes_out|mebytes}}
                                </span>
                            </div>
                        </div>
                    </me-pane>
                </ng-container>
            </me-tab-card>
        </div>
    </ng-container>
</ng-template>