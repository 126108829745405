import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {MELocalizedComponent, MESubscriptionHelper} from "../../../maennl-commons";
import {MissingTerminalTransactionsList} from "../commons/missing-terminal-transactions.list";
import {MissingTerminalTransactionsService} from "../commons/missing-terminal-transactions.service";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-tab-missing-terminal-transactions',
    templateUrl: './tab-missing-terminal-transactions.component.html',
    styleUrls: ['./tab-missing-terminal-transactions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabMissingTerminalTransactionsComponent
    extends MELocalizedComponent
    implements OnInit,OnDestroy,AfterViewInit{

    @Input() id='';
    mttliste=new MissingTerminalTransactionsList();

    private _tabActive = false;

    public get tabActive():boolean {
        return this._tabActive
    }

    @Input()
    public set tabActive(value:boolean) {
        if(this._tabActive!==value) {
            this._tabActive=value;
            this.cd.markForCheck();
            if(this._tabActive) {
                this.mttliste.start();
            }
        }
    }

    constructor(
        public cd:ChangeDetectorRef,
        public mttService: MissingTerminalTransactionsService,
        public benutzerService: BenutzerService,
        public modalService: NgbModal,
        public toastr: ToastrService,
    ) {
        super();
    }

    ngOnInit() {
        MESubscriptionHelper.add(
            this,
            this.mttliste.onUpdateRequired.subscribe(()=>{
                this.cd.markForCheck();
                if(this.mttliste.loading){
                    return;
                }
                this.mttliste.loading=true;
                this.mttService.list(
                    this.mttliste.size,
                    this.mttliste.calcOffset(),
                    this.mttliste.order,
                    this.mttliste.simpleFilter,
                    this.mttliste.getQuery()
                )
                    .subscribe({
                        next:l=>{
                            this.mttliste.doPopulateFromListResult(l);
                            this.mttliste.loading=false;
                            this.cd.markForCheck();
                        },
                        error:()=>{
                            this.mttliste.clear();
                            this.mttliste.loading=false;
                        }
                    });
                this.cd.markForCheck();
            })
        );
    }

    onActivate() {
        this.tabActive=true;
    }

    onDeactivate() {
        this.tabActive=false;
    }

    ngOnDestroy() {
        this.mttliste.release();
        MESubscriptionHelper.release(this);
    }

    ngAfterViewInit() {
    }
}