export class MEDateTag {
  tag = 0;
  display = '';
  prevMonth = false;
  nextMonth = false;
  dow = 0;
  currentDate = false;

  constructor(
    tag: number = 0,
    display: string = '',
    prevMonth: boolean = false,
    nextMonth: boolean = false,
    dow: number = 0,
    currentDate: boolean = false
  ) {
    this.tag = tag;
    this.display = display;
    this.prevMonth = prevMonth;
    this.nextMonth = nextMonth;
    this.dow = dow;
    this.currentDate = currentDate;
  }
}
