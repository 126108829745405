import { Component, Injector } from '@angular/core';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer/mecommon-cell-renderer';
import { Device } from '../../common/device';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';

@Component({
  selector: 'app-medtdevice-online-renderer',
  template: `
    <app-device-online-display
      [device]="row.data"
      *ngIf="isRowValid()"
    ></app-device-online-display>
  `
})
export class MEDTDeviceOnlineRendererComponent extends MEDataTableCommonCellRenderer {
  public cell: boolean = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<Device> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }
}
