import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {Terminal} from "./terminal.class";
import {TerminalImpl} from "./terminal.impl";
import {TerminalFilter} from "./terminal.filter";
import {ITerminal} from "./terminal.interface";

export class TerminalList extends MERemoteList<Terminal>{
    __classname: string = 'TerminalList';
    __instance = '';

    static fromListResult(
        res:IMEListResult<ITerminal>
    ):TerminalList{
        const newList= new TerminalList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun: boolean = false, timeout: number = 400){
        super();
        this.simpleFilter = new TerminalFilter();
        this.initList(autorun,timeout)
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'display_text',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true
    ) {
        super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    }

    convertEntity(entity: any): any {
        return TerminalImpl.fromResult(entity);
    }
}