<me-tab-card #childRootTabCard>
    <me-pane id="tab-wireguard-interface-status"
    title="WireGuard Interface Status"
    (activated)="activate(wireguardInterfaceTab)">
        <app-tab-wireguard-interface #wireguardInterfaceTab></app-tab-wireguard-interface>
    </me-pane>

    <me-pane id="tab-wireguard-client-status"
             title="WireGuard Client Status"
             (activated)="activate(wireguardClientTab)">
        <app-tab-wireguard-client #wireguardClientTab></app-tab-wireguard-client>
    </me-pane>
</me-tab-card>