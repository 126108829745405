import {Terminal} from "./terminal.class";
import {MEGenericFilter} from "../../../maennl-commons/filters";
import {TNullableTerminalListDisplayMode} from "./terminal.types";

export class TerminalFilter extends MEGenericFilter<Terminal>{
    display_mode: TNullableTerminalListDisplayMode = 'all';

    constructor() {
        super();
    }
}