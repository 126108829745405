import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'splitExtension',
})
export class SplitExtensionPipe implements PipeTransform {
    transform(value: string, what: string = 'extension'): string {
        if (value === undefined || value === null) {
            return '';
        }
        let parts = value.split('@');
        if (what === 'extension') {
            if (parts.length > 0) {
                let n = parts[0];
                if (n === '8840' || n === '8841' || n === '1201') {
                    n = 'Mülleimer';
                }
                return n;
            }
        } else if (what === 'type') {
            if (parts.length > 1) {
                return parts[1];
            }
        }

        return '';
    }
}
