import { MEDataTableRow } from './rows';

export class MEDTMouseOverEvent {
  originalEvent: MouseEvent;
  row: MEDataTableRow<any>;

  constructor(
    originalEvent: MouseEvent = null,
    row: MEDataTableRow<any> = null
  ) {
    this.originalEvent = originalEvent;
    this.row = row;
  }
}
