import { Component, Injector } from '@angular/core';
import { ICheck } from '../../common/icheck';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';

@Component({
  selector: 'app-medtcheck-renderer',
  template: `
    <app-check-display
      *ngIf="isCellValid()"
      [value]="cell"
      [title]="getTitle()"
    ></app-check-display>
  `
})
export class MEDTCheckRendererComponent extends MEDataTableCommonCellRenderer {
  public cell: ICheck = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<any> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }

  getTitle() {
    return '';
  }
}
