import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MEConverterTool, METool } from '../../../maennl-commons';
import { DresserChunk } from '../dresser-display/dresser-chunk';
import { DresserCommand } from '../dresser-display/dresser-command';

@Component({
  selector: 'app-dresser-packaged-display',
  templateUrl: './dresser-packaged-display.component.html',
  styleUrls: ['./dresser-packaged-display.component.scss']
})
export class DresserPackagedDisplayComponent implements OnInit {
  chunks: DresserChunk[] = [];
  origin = [];

  _data = '';

  private _flip_sides = true;

  public showOrigin = false;

  get flip_sides(): boolean {
    return this._flip_sides;
  }

  @Input()
  set flip_sides(value: boolean) {
    if (this._flip_sides !== value) {
      this._flip_sides = value;
      this.build();
      this.cd.markForCheck();
    }
  }

  constructor(public cd: ChangeDetectorRef) {}

  @Input()
  set data(v: string) {
    this._data = v;
    this.build();
  }

  build(): void {
    const v = this._data;
    this.chunks = [];
    if (!METool.isNullOrUndefined(v)) {
      let bytes: string[] = v.match(/.{2}/g);
      this.origin = bytes;
      if (bytes[0] === '40' && bytes.length > 6) {
        // Datenpaket
        const direction = parseInt(bytes[1], 16);
        const lines: string[] = [];
        let line = '';
        let lastByte = '';
        let mode = 0;
        let rest = 0;
        let pos = 6;
        while (pos < bytes.length - 1) {
          pos++;
          const b = bytes[pos];

          line = line + b;

          if (b === 'fa') {
            if (lastByte === '03') {
              mode = 6;
            }
          }
          lastByte = b;

          if (mode === 0) {
            mode = 1;
          } else if (mode === 1) {
            if (b.startsWith('3')) {
              mode = 2;
            } else {
              mode = 6;
            }
          } else if (mode === 2) {
            if (
              b === '01' ||
              b === '02' ||
              b === '03' ||
              b === '04' ||
              b === '05'
            ) {
              mode = 3;
            } else {
              rest = 2;
              mode = 5;
            }
          } else if (mode === 3) {
            rest = parseInt(b, 10);
            mode = 4;
          } else if (mode === 4) {
            rest = rest - 1;
            if (rest <= 0) {
              mode = 2;
            }
          } else if (mode === 5) {
            rest = rest - 1;
            if (rest <= 0) {
              mode = 6;
            }
          } else if (mode === 6) {
            if (b.toLowerCase() === 'fa') {
              lines.push(line.toLowerCase());
              line = '';
              mode = 0;
            }
          }
        }

        if (line !== '') {
          lines.push(line.toLowerCase());
        }

        lines.forEach((l) => {
          const dc: DresserChunk = new DresserChunk();
          dc.knownDirection = direction;
          dc.flip_sides = this.flip_sides;
          dc.raw = l;
          dc.valid = false;
          if (l.endsWith('fa') && l.length > 12) {
            dc.valid = true;
            dc.checksum = l.substring(l.length - 8, l.length - 2);
            dc.end = 'fa';
            l = l.substring(0, l.length - 8);

            let xmode = 0;
            let cmd: DresserCommand = new DresserCommand();

            bytes = l.match(/.{2}/g);

            bytes.forEach((b) => {
              if (xmode === 0) {
                if (!b.startsWith('5')) {
                  dc.valid = false;
                  xmode = 5;
                } else {
                  dc.startcode = b;
                  dc.address = (parseInt(b, 16) - 0x50).toString(16);
                  xmode = 1;
                }
              } else if (xmode === 1) {
                if (!b.startsWith('3')) {
                  dc.valid = false;
                  xmode = 5;
                } else {
                  dc.sequenz = b;
                  dc.position = (parseInt(b, 16) - 0x30).toString(10);
                  if (parseInt(dc.position, 10) > 15) {
                    dc.valid = false;
                    xmode = 5;
                  }
                  if (parseInt(dc.position, 10) < 0) {
                    dc.valid = false;
                    xmode = 5;
                  }
                  xmode = 2;
                }
              } else if (xmode === 2) {
                cmd.cmd = b;
                xmode = 3;
              } else if (xmode === 3) {
                cmd.length = b;
                if (parseInt(b, 16) > 0) {
                  xmode = 4;
                } else {
                  dc.commands.push(cmd);
                  cmd = new DresserCommand();
                  xmode = 2;
                }
              } else if (xmode === 4) {
                cmd.data = cmd.data + b;
                if (cmd.data.length >= parseInt(cmd.length, 16) * 2) {
                  dc.commands.push(cmd);
                  cmd = new DresserCommand();
                  xmode = 2;
                }
              }
            });
          }
          if (this.chunks.length > 0) {
            dc.lastChunk = this.chunks[this.chunks.length - 1];
          }
          this.chunks.push(dc);
        });
      }
    }
    this.cd.markForCheck();
  }

  ngOnInit(): void {}

  fNum(num: number, nks: number) {
    return MEConverterTool.formatNum(num, nks);
  }

  is_bit_set(bf: any, bit: number) {
    return MEConverterTool.isBitSet(bf, bit);
  }
}
