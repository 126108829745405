<me-data-table
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="streams.asDataTableRows()"
        [selectable]="false"
>
    <me-data-table-action
            [label]="'Filter setzen'"
            title="Datensätze filtern"
            icon="icofont icofont-filter"
            (perform)="setFilter()"
    ></me-data-table-action>
    <me-data-table-action
            [label]="'Liste aktualisieren'"
            title="Liste aktualisieren"
            icon="icofont icofont-refresh"
            (perform)="reloadList()"
    ></me-data-table-action>

    <me-data-table-column [label]="'ID'" [field]="'connectionId'" type="long"></me-data-table-column>
    <me-data-table-column [label]="'Gerät'"
                          [field]="'device'"
                          [renderer]="deviceRenderer"
    ></me-data-table-column>
    <me-data-table-column [label]="'verbunden'" [field]="'connected'" type="date"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"></me-data-table-column>
    <me-data-table-column [label]="'erstes Datenpaket'" [field]="'minReceived'" type="date"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"></me-data-table-column>
    <me-data-table-column [label]="'letztes Datenpaket'" [field]="'maxReceived'" type="date"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"></me-data-table-column>
    <me-data-table-column [label]="'Kennung'" [field]="'machine'"></me-data-table-column>
    <me-data-table-column [label]="'Länge'" [field]="'streamLength'" type="bytes"></me-data-table-column>
    <me-data-table-column [label]="'#Pakete'" [field]="'numPackets'" type="long"></me-data-table-column>


    <me-data-table-rowaction
            label="Details"
            icon="icofont icofont-info-circle"
            (perform)="showStream($event)"
    ></me-data-table-rowaction>

</me-data-table>

<div class="row">
    <div class="col-6"></div>
    <div class="col-6 text-right">
        <me-pagination
                [total]="streams.total"
                [page]="streams.page"
                [perpage]="streams.size"
                (pageChange)="streams.setPage($event)"
                (pageCountChange)="streams.processPageCountChangedEvent($event)"
                settingsid="mitschnitt-streams"
                [settingsService]="benutzerService"
        ></me-pagination>
    </div>
</div>


