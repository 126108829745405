import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { SysHealthRouterStatusTabComponent } from '../controls/sys-health-router-status-tab/sys-health-router-status-tab.component';

@Component({
  selector: 'app-sys-health-router-status',
  templateUrl: './sys-health-router-status.component.html',
  styleUrls: ['./sys-health-router-status.component.scss']
})
export class SysHealthRouterStatusComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(SysHealthRouterStatusTabComponent, { static: false })
  statusTab: SysHealthRouterStatusTabComponent;

  constructor() {}

  ngOnInit() {
    if (this.statusTab !== null && this.statusTab !== undefined) {
      this.statusTab.onActivate();
    }
  }

  ngOnDestroy(): void {
    if (this.statusTab !== null && this.statusTab !== undefined) {
      this.statusTab.onDeactivate();
    }
  }

  ngAfterViewInit(): void {
    if (this.statusTab !== null && this.statusTab !== undefined) {
      this.statusTab.onActivate();
    }
  }
}
