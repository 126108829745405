import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IRufnummer } from './irufnummer';

export class Rufnummer
  extends MEGenericEntity<IRufnummer>
  implements IRufnummer
{
  public contactId: number = null;
  public id: number = null;
  public info: string = null;
  public nummer: string = null;

  constructor(
    id: number = null,
    contactId: number = null,
    info: string = null,
    nummer: string = null
  ) {
    super();
    this.contactId = contactId;
    this.id = id;
    this.info = info;
    this.nummer = nummer;
  }

  static fromResult(
    entity: IRufnummer,
    createIfNull: boolean = false
  ): Rufnummer {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Rufnummer();
      }
      return null;
    }

    const instance = new Rufnummer();
    return instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('contactId')
        .addLongField('id')
        .addTextField('info')
        .addTextField('nummer')
    );
  }
}
