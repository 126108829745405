import { Component, Input, OnInit } from '@angular/core';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';
import { TestPing } from '../../common/test-ping';

@Component({
  selector: 'app-sys-health-test-ping-cpudisplay',
  templateUrl: './sys-health-test-ping-cpudisplay.component.html',
  styleUrls: ['./sys-health-test-ping-cpudisplay.component.scss']
})
export class SysHealthTestPingCPUDisplayComponent extends MELocalizedComponent {
  @Input()
  testping: TestPing = null;

  constructor() {
    super();
  }
}
