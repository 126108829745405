import { IMachine } from '../common/imachine';
import { IMEListResult } from '../../maennl-commons';
import { Resolve } from '@angular/router';
import { SysHealthService } from './sys-health.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class MachinesResolver implements Resolve<any> {
  constructor(private syshealthService: SysHealthService) {}

  resolve(): Observable<IMEListResult<IMachine>> {
    return this.syshealthService.fetchMachines(null);
  }
}
