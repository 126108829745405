import { Component, Inject } from '@angular/core';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';
import { MEConverterTool, METool } from '../../../maennl-commons/tools';

@Component({
  selector: 'app-medtmitschnittstream-paket-daten-renderer',
  template: `
    <app-dresser-display
      *ngIf="isDresser()"
      [data]="cell"
    ></app-dresser-display>
    <app-dresser-packaged-display
      *ngIf="isDresserPackaged()"
      [data]="cell"
      [flip_sides]="getFlipSides()"
    ></app-dresser-packaged-display>
    <app-zsr-display *ngIf="isZSR()" [data]="cell"></app-zsr-display>
    <app-zsrpackaged-display
      *ngIf="isZSRPackaged()"
      [data]="cell"
    ></app-zsrpackaged-display>
    <app-generic-display
      *ngIf="isGeneric()"
      [data]="cell"
    ></app-generic-display>
    <app-er3-packaged-display
      *ngIf="isER3Packaged()"
      [data]="cell"
      [flip_sides]="getFlipSides()"
    ></app-er3-packaged-display>
  `
})
export class MEDTMitschnittstreamPaketDatenRendererComponent extends MEDataTableCommonCellRenderer {
  public chunks: string[] = [];

  constructor(
    @Inject('cell') public cell: string,
    @Inject('column') public column: MEDataTableColumnComponent = null,
    @Inject('row') public row: MEDataTableRow<any> = null
  ) {
    super();
  }

  public getProtocol() {
    let protocolField = 'protocol';
    if (!METool.isNullOrUndefined(this.column)) {
      if (!METool.isNullOrUndefined(this.column.cfg)) {
        if (
          Object.keys(this.column.cfg).indexOf('protocolField') >= 0 &&
          !METool.isNullOrUndefined(this.column.cfg.protocolField)
        ) {
          protocolField = '' + this.column.cfg.protocolField;
        }
      }
    }
    if (protocolField.trim() === '') {
      protocolField = 'protocol';
    }

    if (!METool.isNullOrUndefined(this.row)) {
      if (!METool.isNullOrUndefined(this.row.data)) {
        if (Object.keys(this.row.data).indexOf(protocolField) >= 0) {
          return METool.isNullOrUndefined(this.row.data[protocolField])
            ? 'unknown'
            : ('' + this.row.data[protocolField]).toLowerCase();
        }
      }
    }
    return 'unknown';
  }

  public getFlipSides() {
    if (!METool.isNullOrUndefined(this.column)) {
      if (!METool.isNullOrUndefined(this.column.cfg)) {
        if (
          Object.keys(this.column.cfg).indexOf('flip_sides') >= 0 &&
          !METool.isNullOrUndefined(this.column.cfg.flip_sides)
        ) {
          return MEConverterTool.toBoolean(this.column.cfg.flip_sides);
        }
      }
    }
    return false;
  }

  public isDresser(): boolean {
    return this.getProtocol() === 'dresser';
  }

  public isDresserPackaged(): boolean {
    return this.getProtocol() === 'dresser-packaged';
  }

  public isZSR(): boolean {
    return this.getProtocol() === 'zsr';
  }

  public isGeneric(): boolean {
    return !(
      this.isDresser() ||
      this.isZSR() ||
      this.isZSRPackaged() ||
      this.isER3Packaged() ||
      this.isDresserPackaged()
    );
  }

  public isZSRPackaged() {
    return this.getProtocol() === 'zsr-packaged';
  }

  public isER3Packaged() {
    return this.getProtocol() === 'er3-packaged';
  }
}
