import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-vpnlinuxserver',
  templateUrl: './open-vpnlinuxserver.component.html',
  styleUrls: ['./open-vpnlinuxserver.component.css']
})
export class OpenVPNLinuxserverComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
