import {MEGenericEntity} from '../../maennl-commons';
import {
    TNullableNumber,
    TNullableString,
} from '../../maennl-commons/tools/types';
import {IAuthority} from './authority.interface';
import {IBenutzer} from './ibenutzer';

export class Authority extends MEGenericEntity<IAuthority> implements IAuthority {
    id: TNullableNumber = null;
    version: TNullableNumber = null;
    authority: TNullableString = null;

    _members: IBenutzer[] | null = null;

    constructor() {
        super();
    }
}
