import {HttpClient} from "@angular/common/http";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {IMEListResult, MEApiTool, MEResultMetaSort} from "../../../maennl-commons";
import {IMESimpleFilter} from "../../../maennl-commons/filters";
import {IMissingTerminalTransactions} from "./missing-terminal-transactions.interface";
import {map, Observable, of} from "rxjs";
import {take} from "rxjs/operators";
import {TNullableNumber} from "../../../maennl-commons/tools/types";
import {MissingTerminalTransactions} from "./missing-terminal-transactions.class";
import {MissingTerminalTransactionsImpl} from "./missing-terminal-transactions.impl";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class MissingTerminalTransactionsService{

    constructor(public http:HttpClient,public loginService:BenutzerService) {
    }

    public list(
        max=50,
        offset=0,
        sort:MEResultMetaSort[]=[],
        filter:IMESimpleFilter<IMissingTerminalTransactions>=null,
        queryString=''
    ): Observable<IMEListResult<IMissingTerminalTransactions>>{
        const uri= MEApiTool.buildListURL(
            'api/webfuel/missingterminaltransactions',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<IMissingTerminalTransactions>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public get(id:TNullableNumber):Observable<MissingTerminalTransactions>{
        if(id===null||id===undefined||id<1){
            return of(null);
        }
        const uri=MEApiTool.buildEntityURL(
            'api/webfuel/missingterminaltransactions',
            id
        );
        return this.http
            .get<MissingTerminalTransactions>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r=>{
                    return MissingTerminalTransactionsImpl.fromResult(r);
                }),take(1)
            );
    }
}