import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {
    IMEActionResponse,
    IMEListResult,
    MEActionResponse,
    MEApiTool,
    MEResultMetaSort,
    METool
} from "../../../maennl-commons";
import {IMESimpleFilter} from "../../../maennl-commons/filters";
import {ICoreModules} from "./core-modules.interface";
import {map, Observable, of} from "rxjs";
import {take} from "rxjs/operators";
import {TNullableNumber} from "../../../maennl-commons/tools/types";
import {CoreModules} from "./core-modules.class";
import {CoreModulesImpl} from "./core-modules.impl";

@Injectable({
    providedIn: 'root'
})
export class CoreModulesService {

    constructor(
        public http: HttpClient,
        public loginService: BenutzerService
    ) {
    }

    public list(
        max = 50,
        offset = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<ICoreModules> = null,
        queryString = ''
    ): Observable<IMEListResult<ICoreModules>> {

        const uri=MEApiTool.buildListURL(
            'api/webfuel/core/coremodules',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<ICoreModules>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public get(id:TNullableNumber):Observable<CoreModules>{
        if(id===null||id===undefined||id<1){
            return of(null);
        }
        const uri = MEApiTool.buildEntityURL(
            'api/webfuel/core/coremodules',
            id
        );
        return this.http
            .get<ICoreModules>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r=>{
                    return CoreModulesImpl.fromResult(r);
                }),
                take(1)
            );
    }

    public store(entity: CoreModules):Observable<MEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            'api/webfuel/core/coremodules',
            entity.id
        );

        if(entity.id===null||entity.id===undefined||entity.id<1) {
            return this.http
                .post<MEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r => {
                        return MEActionResponse.fromRawActionResponse(r);
                    }),
                    take(1)
                );
        } else {
            return this.http
                .put<IMEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r => {
                        return MEActionResponse.fromRawActionResponse(r);
                    }),
                    take(1)
                );
        }
    }

    public delete(id:TNullableNumber):Observable<MEActionResponse>{
        if(METool.isNullOrUndefined(id)||id<1){
            return of(MEActionResponse.error('id is null or undefined'));
        }

        const uri = MEApiTool.buildEntityURL(
            'api/webfuel/core/coremodules',
            id
        );

        return this.http
            .delete<IMEActionResponse>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r=>{
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }
}