import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MEGenericModalComponent } from '../../../maennl-commons/megeneric-modal';
import { MEPubSubService } from '../../../maennl-commons/services';
import { Contact } from '../../common/contact';
import { IContact } from '../../common/icontact';
import { Rufnummer } from '../../common/rufnummer';
import { TelefonanlageService } from '../../services/telefonanlage.service';

@Component({
  selector: 'app-contact-editor',
  templateUrl: './contact-editor.component.html',
  styleUrls: ['./contact-editor.component.scss']
})
export class ContactEditorComponent
  extends MEGenericModalComponent<Contact>
  implements OnInit, OnDestroy
{
  entity: Contact = null;

  public initEntity(src: IContact): Contact {
    return Contact.fromResult(src, true);
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public telefonanlageService: TelefonanlageService,
    public toastr: ToastrService,
    public cd: ChangeDetectorRef
  ) {
    super(activeModal, pubsub);
  }

  public save() {
    this.telefonanlageService.store(this.entity).subscribe(
      (r) => {
        if (r.success) {
          this.ok();
        } else {
          this.toastr.error('Fehler beim Speichern der Daten');
        }
      },
      () => {
        this.toastr.error('Fehler beim Speichern der Daten');
      }
    );
  }

  public addNumber() {
    if (
      this.entity.rufnummern === null ||
      this.entity.rufnummern === undefined
    ) {
      this.entity.rufnummern = [];
    }
    const rn = new Rufnummer();
    rn.contactId = this.entity.id;
    this.entity.rufnummern.push(rn);
    this.cd.markForCheck();
  }

  public removeNumber(idx: number) {
    this.entity.rufnummern.splice(idx, 1);
    this.cd.markForCheck();
  }
}
