import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { TMERowActionAvailableCheckCallback } from '../../maennl-commons/data-table/actions/types';
import { MERichError, noop } from '../../maennl-commons/tools';
import { MESubscriptionHelper } from '../../maennl-commons/services';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { AbrechnungHoyer } from '../common/abrechnung-hoyer';
import { AbrechnungVaroRechnungsserver } from '../common/abrechnung-varo-rechnungsserver';
import { IAbrechnungHoyer } from '../common/iabrechnung-hoyer';
import { MERowActionParams } from '../../maennl-commons/data-table/actions';
import {
  MEDataTableRow,
  TMEDTGetExtraRowClassesCallback
} from '../../maennl-commons/data-table/rows';
import { AbrechnungHoyerList } from '../common/abrechnung-hoyer-list';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbrechnungHoyerService } from '../services/abrechnung-hoyer.service';
import { ToastrService } from 'ngx-toastr';
import { MEDataTableComponent } from '../../maennl-commons/data-table';
import { AbrechnungHoyerDetailsComponent } from '../abrechnung-hoyer-details/abrechnung-hoyer-details.component';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-tab-abrechnung-hoyer',
  templateUrl: './tab-abrechnung-hoyer.component.html',
  styleUrls: ['./tab-abrechnung-hoyer.component.scss']
})
export class TabAbrechnungHoyerComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'TabAbrechnungHoyerComponent';
  __instance = '';
  tabActive = false;

  public abrechnungen: AbrechnungHoyerList = new AbrechnungHoyerList();
  @ViewChild(MEDataTableComponent, { static: false }) dt = null;

  constructor(
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    public abrechnungService: AbrechnungHoyerService,
    public modalService: NgbModal,
    public benutzerService: BenutzerService
  ) {}

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.abrechnungen.onUpdateRequired.subscribe(
        (list: AbrechnungHoyerList) => {
          this.abrechnungService
            .listAbrechnungen(
              list.size,
              list.calcOffset(),
              list.order,
              list.simpleFilter
            )
            .subscribe(
              (l) => {
                list.populateFromListResult(l);
                this.cd.markForCheck();
              },
              (e) => {
                console.log(e);
                MERichError.throw(
                  'Fehler beim Datenabruf',
                  'Die Liste der verfügbaren Hoyer-Abrechnungen konnte nicht abgerufen werden.'
                );
              }
            );
        },
        undefined
      )
    );
  }

  ngAfterViewInit(): void {
    if (this.dt !== null) {
      this.dt.extraRowClasses = this.diensteRowClassGetter;
    }
  }

  diensteRowClassGetter: TMEDTGetExtraRowClassesCallback<AbrechnungHoyer> = (
    row: MEDataTableRow<AbrechnungHoyer>,
    idx
  ) => {
    return '';
  };

  onActivate() {
    console.log('Hoyer-Abrechnungen aktiviert!');
    this.tabActive = true;
    this.abrechnungen.start();
  }

  onDeactivate() {
    console.log('Hoyer-Abrechnungen deaktiviert!');
    this.tabActive = false;
  }

  recalcAbrechnungHoyer($event: MERowActionParams<AbrechnungHoyer>) {
    this.abrechnungService.recalcAbrechnung($event.row.data).subscribe(
      () => {
        this.toastr.success(
          'Die Abrechnung für die Periode ' +
            $event.row.data.periode +
            ' wird nun neu durchgerechnet.'
        );
        setTimeout(() => {
          this.abrechnungen.doListUpdate();
        }, 1000);
        setTimeout(() => {
          this.abrechnungen.doListUpdate();
        }, 5000);
        this.abrechnungen.doListUpdate();
      },
      () => {
        MERichError.throw(
          'Fehler beim Datenabruf',
          'Die Neuberechnung der Abrechnung konnte nicht gestartet werden.'
        );
      }
    );
  }

  displayAbrechnungHoyer($event: MERowActionParams<AbrechnungHoyer>) {
    this.abrechnungService
      .getAbrechnung($event.row.data.id)
      .subscribe((a: IAbrechnungHoyer) => {
        const abrechnung = AbrechnungHoyer.fromResult(a);
        AbrechnungHoyerDetailsComponent.open(
          this.modalService,
          AbrechnungHoyerDetailsComponent,
          abrechnung,
          MEGenericModalComponent.SIZE_MEDIUM
        ).then(noop, noop);
      });
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.abrechnungen.release();
  }

  recalcActionAvailable: TMERowActionAvailableCheckCallback<AbrechnungHoyer> = (
    row: MEDataTableRow<AbrechnungHoyer>
  ) => {
    if (row === null || row === undefined) {
      return false;
    }
    if (row.data === null || row.data === undefined) {
      return false;
    }

    return row.data.abgerechnet === null || row.data.abgerechnet === undefined;
  };

  public markAbrechnungCleared($event: MERowActionParams<AbrechnungHoyer>) {
    this.abrechnungService.markAbrechnungCleared($event.row.data).subscribe(
      () => {
        this.toastr.success(
          'Die Abrechnung für die Periode ' +
            $event.row.data.periode +
            ' wurde als abrechnet gekennzeichnet.'
        );
        setTimeout(() => {
          this.abrechnungen.doListUpdate();
        }, 1000);
        this.abrechnungen.doListUpdate();
      },
      () => {
        MERichError.throw(
          'Fehler beim Datenabruf',
          'Die Abrechnung konnte nicht gekennzeichnet werden.'
        );
      }
    );
  }
}
