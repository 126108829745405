import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Point } from './point';

@Component({
  selector: '[me-diagramm-line]',
  template: `
    <svg:g *ngIf="visible">
      <svg:polygon
        style="stroke: none;opacity:0.03;"
        xmlns:svg="http://www.w3.org/1999/html"
        [style.fill]="color"
        [attr.points]="plg"
      />
      <svg:polyline
        style="stroke-width:1px;fill: none;"
        [style.stroke]="color"
        [attr.points]="pl"
      />
      <svg:circle
        style="stroke-width:1px;opacity:0.75;"
        [style.stroke]="color"
        [style.fill]="color"
        *ngFor="let p of points"
        [attr.cx]="p.x"
        [attr.cy]="p.y"
        r="3"
        (mouseover)="mover(p, $event)"
      ></svg:circle>
    </svg:g>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MEDiagrammLineComponent implements OnInit, OnDestroy {
  points: Point[] = [];
  plg = '';
  pl = '';

  private _pts: Point[] = [];
  private _area: Point[] = [];
  private _color = '#3983ab';
  private _showDots = false;
  private _visible = true;

  get visible(): boolean {
    return this._visible;
  }

  @Input()
  set visible(value: boolean) {
    this._visible = value;
    this.update();
  }

  get color(): string {
    return this._color;
  }

  @Input()
  set color(value: string) {
    this._color = value;
    this.update();
  }

  get showDots(): boolean {
    return this._showDots;
  }

  @Input()
  set showDots(value: boolean) {
    this._showDots = value;
    this.update();
  }

  get area(): Point[] {
    return this._area;
  }

  @Input()
  set area(value: Point[]) {
    this._area = value;
    this.update();
  }

  @Input()
  set pts(value: Point[]) {
    this._pts = value;
    this.update();
  }

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  private update() {
    this.points = [];
    if (this._pts === null || this._pts === undefined || this._pts.length < 1) {
      return;
    }
    if (
      this._area === null ||
      this._area === undefined ||
      this._area.length < 2
    ) {
      return;
    }
    const p: string[] = [];

    p.push(new Point(this._area[0].x, this._area[1].y).getPair());

    this._pts.forEach((pt: Point) => {
      p.push(pt.getPair());
      if (this._showDots && pt.mark) {
        this.points.push(pt);
      }
    });

    p.push(new Point(this._area[1].x, this._area[1].y).getPair());
    p.push(new Point(this._area[0].x, this._area[1].y).getPair());

    this.plg = p.join(' ');
    p.splice(0, 1);
    p.splice(p.length - 2);
    this.pl = p.join(' ');
    this.cd.markForCheck();
  }

  mover(p, $event) {
    console.log(p, $event);
  }
}
