import { Component, OnDestroy, OnInit } from '@angular/core';
import { MELocalizedComponent } from '../../localized-component';
import { IMEDataTableCellRenderer } from './mecell-renderer';
import { MEDataTableRow } from '../rows';

@Component({
  template: ''
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class MEDataTableLocalizedCellRenderer
  extends MELocalizedComponent
  implements IMEDataTableCellRenderer, OnInit, OnDestroy
{
  public cell: any;
  public row: MEDataTableRow<any> = null;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    // do nothing
  }

  public ngOnDestroy() {
    // do nothing
  }

  public XtraClasses(): string {
    return '';
  }

  public isCellValid() {
    return this.cell !== null && this.cell !== undefined;
  }

  public isRowValid() {
    return (
      this.row !== null &&
      this.row !== undefined &&
      this.row.data !== null &&
      this.row.data !== undefined
    );
  }
}
