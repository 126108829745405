<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'firewall']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="IP-Blockliste"
></me-area-heading>

<div class="w-100">

    <me-card [title]="'blockierte IP-Adressen'">
        <div class="alert alert-info">
            Die hier aufgeführten IP-Adressen werden (zukünftig) auf <span style="text-decoration: dotted">allen</span>
            Firewall-Systemen blockiert.
        </div>


        <me-data-table
                *ngIf="liste!==null && liste!==undefined"
                [rowActionsAsButtons]="true"
                [tableActionsAsButtons]="true"
                [data]="liste.asDataTableRows()"
                [selectable]="false"
                [extraRowClasses]="rowClassGetter"
                actionsicon="icofont icofont-navigation-menu"
                #blocklistTable
        >

            <me-data-table-column [label]="'IP oder Subnetz'" [field]="'ip'"></me-data-table-column>
            <me-data-table-column [label]="'Info'" [field]="'info'"></me-data-table-column>
            <me-data-table-column [label]="'aktiv'" [field]="'enabled'" type="Boolean"></me-data-table-column>

            <me-data-table-action
                    label="Eintrag hinzufügen"
                    [icon]="'far fa-plus'"
                    (perform)="addEntry()"
            ></me-data-table-action>


            <me-data-table-rowaction
                    (perform)="editEntry($event)"
                    icon="far fa-pencil"
                    label="Bearbeiten"
                    title="Eintrag bearbeiten"
            ></me-data-table-rowaction>

            <me-data-table-rowaction
                    (perform)="removeIp($event)"
                    icon="far fa-trash"
                    label="Löschen"
                    title="Eintrag löschen"
            ></me-data-table-rowaction>

            <me-data-table-table-extra-header>
                <me-pagination
                        [total]="liste.total"
                        [page]="liste.page"
                        [perpage]="liste.size"
                        (pageChange)="liste.setPage($event)"
                        (pageCountChange)="liste.processPageCountChangedEvent($event)"
                        (queryChange)="liste.processQueryChangedEvent($event)"
                        settingsid="netzwerk-blockliste-v4"
                        [settingsService]="benutzerService"
                        [showSearch]="true"
                ></me-pagination>
            </me-data-table-table-extra-header>

        </me-data-table>


    </me-card>
</div>
