import { MEGenericFilter } from '../../maennl-commons/filters';
import { ICheck } from './icheck';
import { ServiceType } from './service-type';

export class SimpleCheckFilter extends MEGenericFilter<ICheck> {
  serviceType: ServiceType = null;

  public encodeField(fieldname: string): string {
    if (fieldname === 'serviceType') {
      if (
        this.serviceType !== null &&
        this.serviceType !== undefined &&
        this.serviceType.code !== ''
      ) {
        return (
          'filter.' +
          encodeURIComponent(fieldname) +
          '=' +
          encodeURIComponent('' + this.serviceType.id)
        );
      }
    }
    return super.encodeField(fieldname);
  }
}
