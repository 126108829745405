<dashboard-info-block
        *ngIf="isValid()"
        [title]="bezeichnung"
        [notransform]="true"
        [value]="stand"
        icon="far fa-ruler-vertical"
        [classes]="'green'"
        (click)="showPeilungen()"
        [subtext]="zeitpunkt"
></dashboard-info-block>
