import {TNullableTenantHosts, TTenantHosts} from "./tenant-hosts.types";
import {TenantHosts} from "./tenant-hosts.class";
import {MEGenericEntityFields} from "../../../maennl-commons";

export class TenantHostsImpl {

    static fromResult(
        entity: TTenantHosts,
        createifnull:boolean=false
    ): TNullableTenantHosts {
        if(entity===null||entity===undefined){
            if(createifnull){
                return new TenantHosts();
            }
            return null
        }
        const instance = new TenantHosts();
        instance.updateFromResult(
          entity,
          new MEGenericEntityFields()
              .addLongFields([
                  'id',
                  'tenant',
                  'rang',
              ])
              .addBooleanFields([
                  'is_api_host',
                  'enabled',
              ])
              .addTextFields([
                  'hostname',
                  '_tenant_info',
              ])
        );
        return instance;
    }
}