import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {MEGenericModalComponent, MEPubSubService, METabCardComponent, METool} from "../../../../maennl-commons";
import {Applications} from "../../commons/applications.class";
import {ApplicationsImpl} from "../../commons/applications.impl";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ApplicationsService} from "../../commons/applications.service";
import {ToastrService} from "ngx-toastr";
import {
    TabApplicationsEditorInfosComponent
} from "./tab-applications-editor-infos/tab-applications-editor-infos.component";
import {
    TabApplicationsEditorIPAddressesComponent
} from "./tab-applications-editor-ip-addresses/tab-applications-editor-ip-addresses.component";

type TTabType=null
    | TabApplicationsEditorInfosComponent
    | TabApplicationsEditorIPAddressesComponent;

@Component({
    selector: 'app-tab-applications-editor',
    templateUrl: './tab-applications-editor.component.html',
    styleUrls: ['./tab-applications-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabApplicationsEditorComponent
    extends MEGenericModalComponent<Applications>
    implements OnInit, OnDestroy, AfterViewInit
{
    @ViewChild(METabCardComponent, {static: false}) tabs: METabCardComponent;
    @ViewChild('applicationsEditorTab', {static: false}) ka: TabApplicationsEditorInfosComponent;

    entity: Applications=null;
    private _currentTab: TTabType = null;

    public initEntity(src:Applications):Applications{
        return ApplicationsImpl.fromResult(src,true);
    }

    constructor(
        public activeModal: NgbActiveModal,
        public pubsub: MEPubSubService,
        public service: ApplicationsService,
        public toastr: ToastrService,
        public cd: ChangeDetectorRef
    ) {
        super(activeModal, pubsub)
    }

    ngOnInit() {}

    public get currentTab():TTabType{
        return this._currentTab;
    }

    public set currentTab(value:TTabType){
        if(this._currentTab!==value){
            if(!METool.isNullOrUndefined(this._currentTab)) {
                this._currentTab.onDeactivate();
            }
            this._currentTab = value;
            if(!METool.isNullOrUndefined(this._currentTab)) {
                this._currentTab.onActivate();
            }
        }
    }

    ngAfterViewInit() {
        this.currentTab=this.ka;
    }

    public activate(tab:TTabType) {
        this.currentTab=tab;
    }

    save() {
        this.service.store(this.entity).subscribe({
            next:(r) => {
                if(r.success) {
                    this.ok();
                } else {
                    this.toastr.error('Fehler beim Speichern der Daten');
                }
            },
            error:() => {
                this.toastr.error('Fehler beim Speichern der Daten');
            }
        });
    }

    changedEntity($event: Applications) {
        this.entity=$event;
    }
}