<me-tab-set>
    <me-pane id="tab-dienste"
             title="Dienste"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)">

        <app-sys-health-checks-list [machine]="machine"
                                    [servicetype]="systemDService"
                                    (onServiceEditAdd)="addEditDienst($event)"
                                    #systemDListe
        ></app-sys-health-checks-list>
    </me-pane>
    <me-pane id="tab-docker"
             title="Docker-Container"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
             *ngIf="machine.hasDocker"
    >

        <app-sys-health-checks-list [machine]="machine"
                                    [servicetype]="docker"
                                    [useExtendedInfo]="true"
                                    (onServiceEditAdd)="addEditDocker($event)"
                                    [extraColumns]="dockerExtraColumns"
                                    [serviceColumnLabel]="'Container'"
                                    #dockerListe
        ></app-sys-health-checks-list>


    </me-pane>
    <me-pane id="tab-database"
             title="Datenbank-Dienste"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
             *ngIf="machine.hasDatabase"
    >

        <app-sys-health-checks-list [machine]="machine"
                                    [servicetype]="database"
                                    [useExtendedInfo]="true"
                                    (onServiceEditAdd)="addEditDatabase($event)"
                                    [extraColumns]="databaseExtraColumns"
                                    [serviceColumnLabel]="'Datenbank'"
                                    #dbListe
        ></app-sys-health-checks-list>


    </me-pane>
    <me-pane id="tab-diskusage"
             title="Festplatten-Belegung"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)">
        <ng-template #duResultRenderer let-item="item">
            <ng-container *ngIf="is_valid_result(item)">
                <ng-container *ngFor="let i of du_result(item)">
                    <ng-container *ngIf="is_valid_string(i.message)">
                        {{ i.message }}
                    </ng-container>
                    <ng-container *ngIf="!is_valid_string(i.message)">
                        <table class="table-borderless small">
                            <tr *ngIf="is_valid_string(i.mountpoint)">
                                <th scope="row" class="ps-0 pt-0 pe-1 pe-1 pb-1">
                                    Mount-Point
                                </th>
                                <td class="p-0">{{ i.mountpoint }}</td>
                            </tr>
                            <tr *ngIf="is_valid_string(i.device)">
                                <th scope="row" class="ps-0 pt-0 pe-1 pe-1 pb-1">
                                    Gerät
                                </th>
                                <td class="p-0">{{ i.device }}</td>
                            </tr>
                            <tr *ngIf="is_valid_string(i.filesystem)">
                                <th scope="row" class="ps-0 pt-0 pe-1 pe-1 pb-1">
                                    Datei-System
                                </th>
                                <td class="p-0">{{ i.filesystem }}</td>
                            </tr>
                            <tr *ngIf="is_valid_number(i.size)">
                                <th scope="row" class="ps-0 pt-0 pe-1 pe-1 pb-1">
                                    Größe
                                </th>
                                <td class="p-0">{{ fNum(i.size, 0) }} MB</td>
                            </tr>
                            <tr *ngIf="is_valid_number(i.used)">
                                <th scope="row" class="ps-0 pt-0 pe-1 pe-1 pb-1">
                                    belegt
                                </th>
                                <td class="p-0">{{ fNum(i.used, 0) }} MB
                                    <span *ngIf="is_valid_number(i.percent_used)">
                                ({{ fNum(i.percent_used, 0) }} %)
                            </span>

                                </td>
                            </tr>
                            <tr *ngIf="is_valid_number(i.avail)">
                                <th scope="row" class="ps-0 pt-0 pe-1 pe-1 pb-1">Verfügbar</th>
                                <td class="p-0">{{ fNum(i.avail, 0) }} MB</td>
                            </tr>

                        </table>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-template>
        <app-sys-health-checks-list [machine]="machine"
                                    [servicetype]="diskusage"
                                    [useExtendedInfo]="true"
                                    (onServiceEditAdd)="addEditDiskusage($event)"
                                    [extraColumns]="diskusageExtraColumns"
                                    [serviceColumnLabel]="'Festplattenbelegung'"
                                    [ergebnisTemplate]="duResultRenderer"
                                    #duListe
        ></app-sys-health-checks-list>
    </me-pane>

</me-tab-set>
