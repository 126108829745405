import { MEDataTableComponent } from '../data-table.component';
import { MEDataTableRow } from '../rows/medata-table-row';

export class MEDataTableRowSelected<T> {
  public row: MEDataTableRow<T>;
  public dataTable: MEDataTableComponent;

  constructor(dataTable: MEDataTableComponent, row: MEDataTableRow<T>) {
    this.dataTable = dataTable;
    this.row = row;
  }
}
