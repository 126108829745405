import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { DHCPLease } from './dhcplease';
import { IDHCPLease } from './idhcplease';
import { SimpleDHCPLeaseFilter } from './simple-dhcplease-filter';

export class DHCPLeaseList extends MERemoteList<DHCPLease> {
  __classname = 'DHCPLeaseList';
  __instance = '';

  static fromListResult(res: IMEListResult<IDHCPLease>): DHCPLeaseList {
    const newList = new DHCPLeaseList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleDHCPLeaseFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'start',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return DHCPLease.fromResult(entity);
  }
}
