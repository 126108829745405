import {MEGenericEntity} from "../../../maennl-commons";
import {IWireguardClient} from "./wireguard-client.interface";
import {TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";

export class WireguardClient
    extends MEGenericEntity<IWireguardClient>
    implements IWireguardClient {

    allowed_ips: TNullableString[]=null;
    bytes_in: TNullableString=null;
    bytes_out: TNullableString=null;
    display_name: TNullableString=null;
    endpoint: TNullableString=null;
    id: TNullableNumber=null;
    persistent_keep_alive: TNullableNumber=null;
    pub_key: TNullableString=null;
    wg_interface: TNullableNumber=null;

    constructor() {
        super();
    }

}