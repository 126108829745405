import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {
    MEConfirmDialogComponent,
    MEGenericModalComponent,
    MELocalizedComponent,
    MERowActionParams,
    MESubscriptionHelper, METool, noop
} from "../../../maennl-commons";
import {TenantHostsList} from "../commons/tenant-hosts.list";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {TenantHostsService} from "../commons/tenant-hosts.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {TenantHosts} from "../commons/tenant-hosts.class";
import {TabTenantHostEditorComponent} from "./tab-tenant-hosts-editor/tab-tenant-host-editor.component";

@Component({
    selector: 'app-tab-tenant-hosts',
    templateUrl: './tab-tenant-hosts.component.html',
    styleUrls: ['./tab-tenant-hosts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TabTenantHostsComponent
    extends MELocalizedComponent
    implements OnInit,OnDestroy,AfterViewInit {

    @Input() id='';
    thListe=new TenantHostsList();

    private _tabActive = false;

    constructor(
        public cd:ChangeDetectorRef,
        public thService: TenantHostsService,
        public benutzerService: BenutzerService,
        public modalService: NgbModal,
        public toastr: ToastrService
    ) {
        super();
    }

    public get tabActive():boolean {
        return this._tabActive;
    }

    @Input()
    public set tabActive(value: boolean){
        if(this._tabActive!==value){
            this._tabActive = value;
            this.cd.markForCheck();
            if(this._tabActive) {
                this.thListe.start();
            }
        }
    }

    ngOnInit() {
        MESubscriptionHelper.add(
            this,
            this.thListe.onUpdateRequired.subscribe(()=>{
                this.cd.markForCheck();
                if(this.thListe.loading){
                    return;
                }
                this.thListe.loading=true;
                this.thService.list(
                    this.thListe.size,
                    this.thListe.calcOffset(),
                    this.thListe.order,
                    this.thListe.simpleFilter,
                    this.thListe.getQuery()
                )
                    .subscribe({
                        next:l =>{
                            this.thListe.doPopulateFromListResult(l);
                            this.thListe.loading=false;
                            this.cd.markForCheck();
                        },
                        error:()=>{
                            this.thListe.clear();
                            this.thListe.loading=false;
                        }
                    });
                this.cd.markForCheck();
            })
        );
    }

    onActivate(){
        this.tabActive=true;
    }

    onDeactivate() {
        this.tabActive=false;
    }

    ngOnDestroy() {
        this.thListe.release();
        MESubscriptionHelper.release(this)
    }

    ngAfterViewInit() {
    }

    addEntry() {
        TabTenantHostEditorComponent.open(
            this.modalService,
            TabTenantHostEditorComponent,
            null,
            MEGenericModalComponent.SIZE_MEDIUM,
            null
        ).then(()=>{
            this.thListe.start()
        },noop());
    }

    editTenantHost($event:MERowActionParams<TenantHosts>) {
        this.thService.get($event.row.data.id).subscribe((th)=>{
            if(th!==null&&th!==undefined&&th.id>0){
                TabTenantHostEditorComponent.open(
                    this.modalService,
                    TabTenantHostEditorComponent,
                    th,
                    MEGenericModalComponent.SIZE_MEDIUM,
                    null
                ).then(()=>{
                    this.thListe.start()
                },noop());
            }
        });
    }

    removeEntry($event:MERowActionParams<TenantHosts>) {
        if(!METool.isNullOrUndefined(this.thService.get($event.row.data.id))){
            MEConfirmDialogComponent.display(
                this.modalService,
                "Tenant Host Löschung bestätigen",
                "Soll der Tenant Host wirklich unwiderruflich gelöscht werden?",
                'Ja, jetzt löschen',
                'icofont icofont-delete'
            ).then(()=>{
                this.thService.get($event.row.data.id).subscribe((th:TenantHosts)=>{
                    if(!METool.isNullOrUndefined(th.id)&&th.id>0) {
                        this.thService.delete(th.id).subscribe(()=>{
                            this.thListe.start();
                        });
                    }
                });
            }, () =>{
                this.toastr.error(
                    "Die Löschung des Tenant Hosts wurde abgebrochen."
                )
            });
        }
    }
}