<div *ngIf="row!==null && row!==undefined && row.data!==null && row.data!==undefined">
    <div *ngFor="let p of preise" class="d-flex justify-content-between">
        <div>
            {{ p.produkt }} [{{p.pos}}]
        </div>
        <div class="text-end">
            {{ fNum(p.preis, 3)}}
        </div>
    </div>
</div>
