import {TIPSecInfo, TNullableIPSecInfo} from "./ipsec-info.types";
import {IPSecInfo} from "./ipsec-info.class";
import {MEGenericEntityConverterParams, MEGenericEntityFields} from "../../../maennl-commons";
import {TNullableIPSecInfoChildList} from "./ipsec-info-child.types";
import {IIPSecInfoChild} from "./ipsec-info-child.interface";
import {IPSecInfoChildImpl} from "./ipsec-info-child.impl";

export class IPSecInfoImpl {
    static fromResult(
        entity: TIPSecInfo,
        createIfNull = false
    ): TNullableIPSecInfo {
        if (entity === null || entity === undefined) {
            if (createIfNull) {
                return new IPSecInfo();
            }
            return null
        }

        const instance = new IPSecInfo();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongFields([
                    'child_length', 'encr_keysize', 'established', 'local_port', 'reauth_time', 'remote_port', 'unique_id', 'version'
                ])
                .addBooleanFields([
                    'nat_any', 'nat_remote'
                ])
                .addTextFields([
                    'dh_group', 'encr_alg', 'integ_alg', 'prf_alg', 'state', 'if_id_in', 'if_id_out', 'initiator', 'initiator_spi',
                    'responder_spi', 'local_host', 'local_id', 'name', 'remote_host', 'remote_id', 'name', 'state'
                ])
                .addConverterField(
                    'child_sas',
                    new MEGenericEntityConverterParams<TNullableIPSecInfoChildList>(
                        (value: TNullableIPSecInfoChildList) => {
                            if (
                                value === null ||
                                value === undefined ||
                                !Array.isArray(value)
                            ) {
                                return [];
                            }
                            return (value as IIPSecInfoChild[])
                                .map((v: IIPSecInfoChild) => {
                                    return IPSecInfoChildImpl.fromResult(v, false);
                                })
                                .filter(v => v !== null && v !== undefined);
                        }
                    )
                ),
            e=>{
                if(
                    (e as IPSecInfo).child_sas===null||
                    (e as IPSecInfo).child_sas===undefined
                ) {
                    (<IPSecInfo>e).child_sas=[];
                }
            }
        );
        return instance;
    }
}