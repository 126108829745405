import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TabMitschnittDevicesComponent } from './tab-mitschnitt-devices/tab-mitschnitt-devices.component';
import { METabCardComponent } from '../maennl-commons/metab-card';
import { TabMitschnittStreamsComponent } from './tab-mitschnitt-streams/tab-mitschnitt-streams.component';
import { METool } from '../maennl-commons';

@Component({
  selector: 'app-mitschnitt',
  templateUrl: './mitschnitt.component.html',
  styleUrls: ['./mitschnitt.component.scss']
})
export class MitschnittComponent implements OnInit, AfterViewInit {
  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;

  @ViewChild(TabMitschnittDevicesComponent, { static: false })
  devicesTab: TabMitschnittDevicesComponent;
  @ViewChild(TabMitschnittStreamsComponent, { static: false })
  streamsTab: TabMitschnittStreamsComponent;

  constructor() {}

  ngOnInit() {
    if (!METool.isNullOrUndefined(this.tabs)) {
      this.tabs.selectById('tab-streams');
    }
  }
  ngAfterViewInit() {
    if (!METool.isNullOrUndefined(this.tabs)) {
      this.tabs.selectById('tab-streams');
    }
  }

  activateTab(id: string) {
    if ('tab-devices' === id) {
      if (this.devicesTab !== null && this.devicesTab !== undefined) {
        this.devicesTab.tabActivated();
      }
    }
    if ('tab-streams' === id) {
      if (this.streamsTab !== null && this.streamsTab !== undefined) {
        this.streamsTab.tabActivated();
      }
    }
  }

  deactivateTab(id: string) {
    if ('tab-devices' === id) {
      if (this.devicesTab !== null && this.devicesTab !== undefined) {
        this.devicesTab.tabDeactivated();
      }
    }
    if ('tab-streams' === id) {
      if (this.streamsTab !== null && this.streamsTab !== undefined) {
        this.streamsTab.tabDeactivated();
      }
    }
  }
}
