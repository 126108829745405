<div class="me-select">
    <div class="input-group" [class.input-group-sm]="small">
        <div class="form-control display-box"
             [class.form-control-sm]="small"
             (click)="showInput()"
             [class.hidden]="_inputVisible"
             [innerHTML]="displayValue(false)"
        ></div>
        <input class="form-control"
               [class.form-control-sm]="small"
               (keyup)="onkey($event)"
               [value]="_showValue"
               [placeholder]="placeholder"
               [title]="placeholder"
               (click)="_showList=true"
               #searchField
               (blur)="hideInput()"
               (focus)="gotFocus()"
               [class.hidden]="!_inputVisible"
        >
        <span class="input-group-btn add-on" *ngIf="nullable">
        <button class="btn btn-secondary"
                [class.btn-sm]="small"
                type="button"
                (click)="clear()">
            <i class="icofont icofont-eraser"></i>
        </button>
    </span>
        <span class="input-group-btn add-on">
        <button class="btn btn-secondary"
                [class.btn-sm]="small"
                type="button"
                (click)="toggleDropDown()">
            <i class="icofont icofont-rounded-down" [class.hidden]="_showList"></i>
            <i class="icofont icofont-rounded-up" [class.hidden]="!_showList"></i>
        </button>
  </span>
    </div>
    <ul
            class="items"
            [class.small]="small"
            [class.visible]="_showList && _itemsShown.length>0"
            [class.is-matrix]="asMatrix"
            *ngIf="(_showList && _itemsShown.length>0)"

    >
        <li
                *ngFor="let aItem of _itemsShown;let idx=index"
                (click)="select2(idx)"
        >
            <div title="{{ getText(aItem) }}" [innerHtml]="format(aItem,_search,true)"></div>
        </li>
    </ul>
</div>