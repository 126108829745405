import { Component, Injector } from '@angular/core';
import { ISonde } from '../../common/isonde';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';

@Component({
  selector: 'app-medtsonde-cell-renderer',
  template: `
    <app-sonde-display *ngIf="isCellValid()" [value]="cell"></app-sonde-display>
  `
})
export class MEDTSondeCellRendererComponent extends MEDataTableCommonCellRenderer {
  public cell: ISonde = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<any> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }
}
