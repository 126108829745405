import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { METabCardComponent } from '../../maennl-commons/metab-card';
import { TabDHCPLeasesComponent } from './tab-dhcpleases/tab-dhcpleases.component';

@Component({
  selector: 'app-sys-health-dhcpstatus',
  templateUrl: './sys-health-dhcpstatus.component.html',
  styleUrls: ['./sys-health-dhcpstatus.component.scss']
})
export class SysHealthDHCPStatusComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'SysHealthDHCPStatusComponent';
  __instance = '';
  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;
  @ViewChild(TabDHCPLeasesComponent, { static: false })
  dhcpleasesTabComponent: TabDHCPLeasesComponent;

  constructor(public cd: ChangeDetectorRef, public route: ActivatedRoute) {}

  ngOnInit() {
    if (this.tabs !== null && this.tabs !== undefined) {
      this.activateTab(this.tabs.currentPane.id);
    }
  }

  public activateTab(id: string) {
    if (id === 'tab-dhcp-leases') {
      if (
        this.dhcpleasesTabComponent !== null &&
        this.dhcpleasesTabComponent !== undefined
      ) {
        this.dhcpleasesTabComponent.onActivate();
      }
    }
  }

  public deactivateTab(id: string) {
    if (id === 'tab-dhcp-leases') {
      if (
        this.dhcpleasesTabComponent !== null &&
        this.dhcpleasesTabComponent !== undefined
      ) {
        this.dhcpleasesTabComponent.onDeactivate();
      }
    }
  }

  public ngAfterViewInit(): void {
    if (this.tabs !== null && this.tabs !== undefined) {
      this.activateTab(this.tabs.currentPane.id);
    }
  }

  public ngOnDestroy(): void {
    if (this.tabs !== null && this.tabs !== undefined) {
      this.deactivateTab(this.tabs.currentPane.id);
    }
  }
}
