import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    Input,
    Output,
    ReflectiveInjector,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import {IMESimpleFilter} from '../../filters';
import {MEDataTableComponent} from '../data-table.component';
import {
    MEDataTableBooleanCellEditorComponent,
    MEDataTableNumCellEditorComponent,
    MEDataTableTextCellEditorComponent,
} from '../editors';
import {MEButtonClickEvent} from '../events/me-button-click-event';
import {
    MEDataTableIntegerCellFilterComponent,
    MEDataTableTextCellFilterComponent,
} from '../filters';
import {
    IMEDataTableCellRenderer,
    MEDataTableBooleanCellRendererComponent,
    MEDataTableBytesCellRendererComponent,
    MEDataTableCurrencyCellRendererComponent,
    MEDataTableDateCellRendererComponent,
    MEDataTableDecimalCellRendererComponent,
    MEDataTableIntegerCellRendererComponent,
    MEDataTableNotBooleanCellRendererComponent,
    MEDataTableTextCellRendererComponent,
} from '../renderer';
import {
    TMECellClassGetter,
    TMECellRendererValueFormatter,
    TMEFooterValueGetter,
} from '../renderer/types';
import {MEDataTableRow} from '../rows';
import {IEditorDef} from './editor-def';
import {IFilterDef} from './filter-def';
import {IRendererDef} from './renderer-def';

@Component({
    selector: 'me-data-table-column',
    template: '',
})
export class MEDataTableColumnComponent {
    @Input() public cfg: any = null;
    @Input() public editable = false;
    @Input() public field = '';
    @Input() public filterable = false;
    @Input() public label = '';
    @Input() public renderer: any = null;
    @Input() public editor: any = null;
    @Input() public sortable = false;
    @Input() public title = '';
    @Input() public titleField = '';
    @Input() public type = 'generic';
    @Input() public xtraClasses: string = null;
    @Input() public visible = true;
    @Input() public formatter: TMECellRendererValueFormatter<any> = null;
    @Input() public classgetter: TMECellClassGetter<any> = null;
    @Input() public footerValue: TMEFooterValueGetter<any> = null;
    @Input() public footer2Value: TMEFooterValueGetter<any> = null;
    @Input() public showFilterButton = false;
    @Input() public footerIsSum = false;
    @Input() public template: TemplateRef<any> = null;
    @Input() public footerTemplate: TemplateRef<any> = null;

    @Input() public component: any = null;
    @Output() public buttonClickEvent: EventEmitter<MEButtonClickEvent> =
        new EventEmitter<MEButtonClickEvent>();

    constructor(
        public resolver: ComponentFactoryResolver,
        public view: ViewContainerRef
    ) {
    }

    private _id = null;

    get id(): any {
        if (this._id === null || this._id === undefined) {
            return 'me-data-table-column';
        }
        return this._id;
    }

    @Input()
    set id(value: any) {
        this._id = value;
    }

    get realField() {
        if (this.field.indexOf('~') > 0) {
            const p = this.field.split('~');
            return p[0];
        }
        return this.field;
    }

    get cleanFieldName() {
        return this.field.replace('@', '-').replace('~', '-').toLowerCase();
    }

    public hasFormatter() {
        return this.formatter !== null && this.formatter !== undefined;
    }

    public hasRenderer() {
        return this.renderer !== null && this.renderer !== undefined;
    }

    public hasTemplate() {
        return this.template !== null && this.template !== undefined;
    }

    public hasFooterTemplate() {
        return this.footerTemplate !== null && this.footerTemplate !== undefined;
    }

    public extraClasses(
        value: any = null,
        row: MEDataTableRow<any> = null
    ): string {
        if (this.xtraClasses == null) {
            this.xtraClasses = '';

            const rd: any = this.getRenderer(null, null);

            const inputProviders = Object.keys(rd.inputs).map(inputName => {
                return {provide: inputName, useValue: rd.inputs[inputName]};
            });
            const resolvedInputs = ReflectiveInjector.resolve(inputProviders);
            const injector = ReflectiveInjector.fromResolvedProviders(
                resolvedInputs,
                this.view.injector
            );
            const factory = this.resolver.resolveComponentFactory(rd.component);
            const renderer = factory.create(injector);

            const instance = (renderer as ComponentRef<IMEDataTableCellRenderer>)
                .instance;
            if (instance.XtraClasses !== undefined && instance.XtraClasses !== null) {
                this.xtraClasses = instance.XtraClasses();
            }
        }

        const classes = [this.xtraClasses === null ? '' : this.xtraClasses];
        if (this.classgetter !== null && this.classgetter !== undefined) {
            const c = this.classgetter(value, this, row);
            if (c !== null && c !== undefined && c !== '') {
                classes.push(c);
            }
        }

        return classes.join(' ').trim();
    }

    public displayLabel(): string {
        if (this.label !== undefined) {
            if (this.label !== null) {
                if (this.label !== '') {
                    return this.label;
                }
            }
        }
        return this.field;
    }

    public displayTitle(row?: MEDataTableRow<any>): string {
        if (this.titleField !== '') {
            if (row !== undefined && row !== null) {
                return '' + row.data[this.titleField];
            }
        }
        return this.title;
    }

    public isSortable(): boolean {
        return this.sortable;
    }

    public isFilterable(): boolean {
        return this.filterable;
    }

    public isEditable(): boolean {
        return this.editable;
    }

    public isString(): boolean {
        return this.type.toLowerCase() === 'text';
    }

    public hasTitle(): boolean {
        if (
            this.titleField !== null &&
            this.titleField !== undefined &&
            this.titleField !== ''
        ) {
            return true;
        }
        return this.title !== undefined && this.title !== null && this.title !== '';
    }

    public getRenderer(c?: any, r?: any) {
        const rd: IRendererDef = {
            component: MEDataTableTextCellRendererComponent,
            inputs: {
                cell: c,
                column: this,
                row: r,
            },
        };

        if (this.renderer !== undefined && this.renderer !== null) {
            rd.component = this.renderer;
        } else if (this.type.toLowerCase() === 'integer') {
            rd.component = MEDataTableIntegerCellRendererComponent;
        } else if (this.type.toLowerCase() === 'long') {
            rd.component = MEDataTableIntegerCellRendererComponent;
        } else if (this.type.toLowerCase() === 'boolean') {
            rd.component = MEDataTableBooleanCellRendererComponent;
        } else if (this.type.toLowerCase() === 'notboolean') {
            rd.component = MEDataTableNotBooleanCellRendererComponent;
        } else if (this.type.toLowerCase() === 'date') {
            rd.component = MEDataTableDateCellRendererComponent;
        } else if (
            this.type.toLowerCase() === 'number' ||
            this.type.toLowerCase() === 'decimal'
        ) {
            rd.component = MEDataTableDecimalCellRendererComponent;
        } else if (this.type.toLowerCase() === 'bytes') {
            rd.component = MEDataTableBytesCellRendererComponent;
        } else if (this.type.toLowerCase() === 'currency') {
            rd.component = MEDataTableCurrencyCellRendererComponent;
        }

        return rd;
    }

    public getFilter(
        filter: IMESimpleFilter<any>,
        dt: MEDataTableComponent
    ): IFilterDef {
        if (!this.filterable) {
            return null;
        }

        if (filter === undefined) {
            return null;
        }

        if (filter === null) {
            return null;
        }

        if (filter[this.field] === undefined) {
            return null;
        }

        const ft: IFilterDef = {
            component: MEDataTableTextCellFilterComponent,
            inputs: {
                column: this,
                field: this.field,
                onChange: dt.onFilterChanged,
                placeholder: this.displayLabel(),
                value: filter[this.field],
            },
        };

        if (this.type !== undefined && this.type !== null) {
            if (this.type.toLowerCase() === 'integer') {
                ft.component = MEDataTableIntegerCellFilterComponent;
            } else if (this.type.toLowerCase() === 'long') {
                ft.component = MEDataTableIntegerCellFilterComponent;
            }
        }

        return ft;
    }

    public getEditor(
        field?: any,
        dt?: MEDataTableComponent,
        r?: MEDataTableRow<any>
    ) {
        const rd: IEditorDef = {
            component: MEDataTableTextCellEditorComponent,
            inputs: {
                addOnIcons: [],
                addOnLabels: [],
                addOns: false,
                column: this,
                onAddOnClick: dt.onEditorAddOnClicked,
                onChange: dt.editorChanged,
                row: r,
                value: field,
            },
        };

        if (
            this.cfg !== undefined &&
            this.cfg !== null &&
            this.cfg.addOns !== undefined &&
            this.cfg.addOns !== null &&
            Array.isArray(this.cfg.addOns)
        ) {
            this.cfg.addOns.forEach((a: any[]) => {
                rd.inputs.addOns = true;
                rd.inputs.addOnLabels.push(a[0]);
                rd.inputs.addOnIcons.push(a[1]);
            });
        }
        if (this.editor !== undefined && this.editor !== null) {
            rd.component = this.editor;
        } else if (this.type.toLowerCase() === 'integer') {
            rd.component = MEDataTableNumCellEditorComponent;
        } else if (this.type.toLowerCase() === 'long') {
            rd.component = MEDataTableNumCellEditorComponent;
        } else if (this.type.toLowerCase() === 'boolean') {
            rd.component = MEDataTableBooleanCellEditorComponent;
        }

        return rd;
    }

    public sendButtonClicked(group: string, button: string, params: any) {
        const bc = new MEButtonClickEvent(group, button, params);
        this.buttonClickEvent.emit(bc);
    }

    getColumnClasses(value: any = null, row: MEDataTableRow<any> = null) {
        let c = this.extraClasses(value, row).split(' ');
        if (!Array.isArray(c)) {
            c = [];
        }
        c.push('column-' + this.cleanFieldName);
        c.push('column-' + this.id.toLowerCase());
        c.push('column-' + this.type.toLowerCase());
        c.push(this.type.toLowerCase() + '-column');
        c.push('column-' + this.field.toLowerCase());
        return c;
    }

    public getFooterValue(
        realField: string,
        tabledata: Array<MEDataTableRow<any>>,
        what = 'footer'
    ) {
        if (what === 'footer') {
            if (this.footerValue != null) {
                return this.footerValue(realField, this, tabledata);
            }
            if (this.footerIsSum) {
                let sum = 0;

                tabledata.forEach(row => {
                    if (row.data !== null && row.data !== undefined) {
                        if (Object.keys(row.data).indexOf(realField) >= 0) {
                            sum = sum + row.data[realField];
                        }
                    }
                });
                return sum;
            }
        }
        if (what === 'footer2') {
            if (this.footer2Value != null) {
                return this.footer2Value(realField, this, tabledata);
            }
        }
        return '';
    }
}
