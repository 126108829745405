import { Component, Injector } from '@angular/core';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';
import { Mitschnittgeraet } from '../../common/mitschnittgeraet';

@Component({
  selector: 'app-medtmitschnittgeraet-renderer',
  template: `
    <app-mitschnittgeraet-display
      *ngIf="isCellValid()"
      [value]="cell"
    ></app-mitschnittgeraet-display>
  `
})
export class MEDTMitschnittgeraetRendererComponent extends MEDataTableCommonCellRenderer {
  public cell: Mitschnittgeraet = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<any> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }
}
