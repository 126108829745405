import {TNullableWireguardClient, TWireguardClient} from "./wireguard-client.types";
import {WireguardClient} from "./wireguard-client.class";
import {MEGenericEntityFields} from "../../../maennl-commons";

export class WireguardClientImpl {
    static fromResult(
        entity: TWireguardClient,
        createIfNull = false
    ): TNullableWireguardClient {
        if (entity===null||entity===undefined){
            if(createIfNull){
                return new WireguardClient();
            }
            return null;
        }

        const instance = new WireguardClient();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongFields([
                    'id',
                    'wg_interface',
                    'persistent_keep_alive',
                ])
                .addTextFields([
                    'display_name',
                    'pub_key',
                    'pre_shared_key',
                    'endpoint',
                    'bytes_in',
                    'bytes_out',
                ])
                .addStringsField('allowed_ips')
        );
        return instance;
    }
}