import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  MELocalizedComponent,
  MESubscriptionHelper
} from '../../../maennl-commons';

@Component({
  selector: 'app-tab-tracker-smscommands',
  templateUrl: './tab-tracker-smscommands.component.html',
  styleUrls: ['./tab-tracker-smscommands.component.scss']
})
export class TabTrackerSMSCommandsComponent
  extends MELocalizedComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private _tabActive = false;
  public get tabActive(): boolean {
    return this._tabActive;
  }

  @Input()
  public set tabActive(value: boolean) {
    if (this._tabActive !== value) {
      this._tabActive = value;
      this.cd.markForCheck();
      if (this._tabActive) {
        //  this.bridges.start();
      }
    }
  }

  constructor(public toastr: ToastrService, public cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {}

  onActivate() {
    this.tabActive = true;
    console.log('activated');
  }

  onDeactivate() {
    this.tabActive = false;
    console.log('deactivated');
  }

  public ngAfterViewInit(): void {}

  public ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
  }
}
