import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlainObject } from 'jquery';
import { MELocalizedComponent } from '../../../maennl-commons';
import { MEDTExtraColumn } from '../../../maennl-commons/data-table/columns';
import { MEGenericModalComponent } from '../../../maennl-commons/megeneric-modal';
import { METabsetComponent } from '../../../maennl-commons/metab-card';
import { METool, noop } from '../../../maennl-commons/tools';
import { TValidItem } from '../../../maennl-commons/tools/metool';
import { Check } from '../../common/check';
import { DUCheckResultInfo } from '../../common/du-check-result-info';
import { ICheck } from '../../common/icheck';
import { Machine } from '../../common/machine';
import { ServiceType } from '../../common/service-type';
import { SysHealthService } from '../../services/sys-health.service';
import { MEDTCheckParamRendererComponent } from '../medtcheck-param-renderer/medtcheck-param-renderer.component';
import { SysHealthDatabaseEditDialogComponent } from '../sys-health-database-edit-dialog/sys-health-database-edit-dialog.component';
import { SysHealthDiskusageEditDialogComponent } from '../sys-health-diskusage-edit-dialog/sys-health-diskusage-edit-dialog.component';
import { SysHealthDockerEditDialogComponent } from '../sys-health-docker-edit-dialog/sys-health-docker-edit-dialog.component';
import { SysHealthSystemDserviceEditDialogComponent } from '../sys-health-system-dservice-edit-dialog/sys-health-system-dservice-edit-dialog.component';

@Component({
  selector: 'app-sys-health-linux-machine-tab',
  templateUrl: './sys-health-linux-machine-tab.component.html',
  styleUrls: ['./sys-health-linux-machine-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SysHealthLinuxMachineTabComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy
{
  __classname = 'SysHealthLinuxMachineTabComponent';
  __instance = '';

  systemDService = ServiceType.systemDService();
  docker = ServiceType.docker();
  database = ServiceType.database();
  diskusage = ServiceType.diskusage();

  dockerExtraColumns: MEDTExtraColumn[] = [
    new MEDTExtraColumn(
      'IP',
      'params~1',
      { item: 'ip' },
      MEDTCheckParamRendererComponent
    ),
  ];

  databaseExtraColumns: MEDTExtraColumn[] = [
    new MEDTExtraColumn(
      'IP',
      'params~1',
      { item: 'ip' },
      MEDTCheckParamRendererComponent
    ),
  ];

  diskusageExtraColumns: MEDTExtraColumn[] = [
    new MEDTExtraColumn(
      'Pfad',
      'params~1',
      { item: 'path' },
      MEDTCheckParamRendererComponent
    ),
  ];

  @Input() id = 'SysHealthLinuxMachineTabComponent';
  @Input() machine: Machine = new Machine(0);
  @ViewChild(METabsetComponent, { static: false }) tabs: METabsetComponent;
  @ViewChild('systemDListe', { static: false }) dienstChecks = null;
  @ViewChild('dockerListe', { static: false }) dockerChecks = null;
  @ViewChild('dbListe', { static: false }) dbChecks = null;
  @ViewChild('duListe', { static: false }) duChecks = null;

  constructor(
    public modalService: NgbModal,
    public healthService: SysHealthService,
    public cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    if (this.tabs !== null && this.tabs !== undefined) {
      if (
        this.tabs.currentPane !== null &&
        this.tabs.currentPane !== undefined
      ) {
        return this.deactivateTab(this.tabs.currentPane.id);
      }
    }
  }

  onActivate() {
    if (this.tabs !== null && this.tabs !== undefined) {
      if (
        this.tabs.currentPane !== null &&
        this.tabs.currentPane !== undefined
      ) {
        return this.activateTab(this.tabs.currentPane.id);
      }
      this.tabs.selectById('tab-dienste');
    }
  }

  onDeactivate() {
    if (this.tabs !== undefined && this.tabs !== null) {
      this.deactivateTab(this.tabs.currentPane.id);
    }
  }

  activateTab(s: string) {
    if (s === 'tab-dienste') {
      if (this.dienstChecks !== null && this.dienstChecks !== undefined) {
        this.dienstChecks.onActivate();
      }
    }
    if (s === 'tab-docker') {
      if (this.dockerChecks !== null && this.dockerChecks !== undefined) {
        this.dockerChecks.onActivate();
      }
    }
    if (s === 'tab-database') {
      if (this.dbChecks !== null && this.dbChecks !== undefined) {
        this.dbChecks.onActivate();
      }
    }
    if (s === 'tab-diskusage') {
      if (this.duChecks !== null && this.duChecks !== undefined) {
        this.duChecks.onActivate();
      }
    }
  }

  deactivateTab(s: string) {
    if (s === 'tab-dienste') {
      if (this.dienstChecks !== null && this.dienstChecks !== undefined) {
        this.dienstChecks.onDeactivate();
      }
    }
    if (s === 'tab-docker') {
      if (this.dockerChecks !== null && this.dockerChecks !== undefined) {
        this.dockerChecks.onDeactivate();
      }
    }
    if (s === 'tab-database') {
      if (this.dbChecks !== null && this.dbChecks !== undefined) {
        this.dbChecks.onDeactivate();
      }
    }
    if (s === 'tab-diskusage') {
      if (this.duChecks !== null && this.duChecks !== undefined) {
        this.duChecks.onDeactivate();
      }
    }
  }

  addEditDienst($event: Check = null) {
    if ($event === null || $event === undefined) {
      this.openAddEditDienst(null);
    } else {
      this.healthService.getCheck($event.id).subscribe((c: ICheck) => {
        this.openAddEditDienst(Check.fromResult(c));
      });
    }
  }

  openAddEditDienst($event: Check = null) {
    SysHealthSystemDserviceEditDialogComponent.open(
      this.modalService,
      SysHealthSystemDserviceEditDialogComponent,
      $event,
      MEGenericModalComponent.SIZE_MEDIUM,
      {
        machine: this.machine,
      }
    ).then(() => {
      if (this.dienstChecks !== null && this.dienstChecks !== undefined) {
        this.dienstChecks.refreshList();
      }
    }, noop);
  }

  addEditDocker($event: Check) {
    SysHealthDockerEditDialogComponent.open(
      this.modalService,
      SysHealthDockerEditDialogComponent,
      $event,
      MEGenericModalComponent.SIZE_MEDIUM,
      {
        machine: this.machine,
      }
    ).then(() => {
      if (this.dockerChecks !== null && this.dockerChecks !== undefined) {
        this.dockerChecks.refreshList();
      }
    }, noop);
  }

  addEditDatabase($event: Check) {
    SysHealthDatabaseEditDialogComponent.open(
      this.modalService,
      SysHealthDatabaseEditDialogComponent,
      $event,
      MEGenericModalComponent.SIZE_MEDIUM,
      {
        machine: this.machine,
      }
    ).then(() => {
      if (this.dbChecks !== null && this.dbChecks !== undefined) {
        this.dbChecks.refreshList();
      }
    }, noop);
  }

  addEditDiskusage($event: Check) {
    SysHealthDiskusageEditDialogComponent.open(
      this.modalService,
      SysHealthDiskusageEditDialogComponent,
      $event,
      MEGenericModalComponent.SIZE_MEDIUM,
      {
        machine: this.machine,
      }
    ).then(() => {
      if (this.duChecks !== null && this.duChecks !== undefined) {
        this.duChecks.refreshList();
      }
    }, noop);
  }

  is_valid_result(item: any) {
    if (METool.isNullOrUndefined(item) || METool.isEmpty(item)) {
      return false;
    }

    try {
      let a = JSON.parse(item);
      return !METool.isNullOrUndefined(a) && a !== '';
    } catch (e) {
      return false;
    }
  }

  du_result(item: any): DUCheckResultInfo[] {
    if (METool.isNullOrUndefined(item) || METool.isEmpty(item)) {
      return [];
    }
    try {
      let a = JSON.parse(item);
      if (!METool.isNullOrUndefined(a) && a !== '') {
        return [a as DUCheckResultInfo];
      }
    } catch (e) {}
    return [];
  }

  is_valid_number: TValidItem = METool.isValidNumber;
  is_valid_string: TValidItem = METool.isValidString;
}
