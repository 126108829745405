import {CoreModules} from "./core-modules.class";
import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {SimpleCoreModulesFilter} from "./core-modules.filter";
import {CoreModulesImpl} from "./core-modules.impl";
import {ICoreModules} from "./core-modules.interface";

export class CoreModulesList
    extends MERemoteList<CoreModules> {
    __classname: string = 'CoreModulesList';
    __instance = '';

    static fromListResult(
        res: IMEListResult<ICoreModules>
    ): CoreModulesList {
        const newList = new CoreModulesList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun: boolean = false, timeout: number = 400) {
        super();
        this.simpleFilter = new SimpleCoreModulesFilter();
        this.initList(autorun, timeout);
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'id',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true
    ) {
        super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    }

    convertEntity(entity:any):any {
        return CoreModulesImpl.fromResult(entity);
    }
}