import { Component, Injector } from '@angular/core';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { Device } from '../../common/device';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer';

@Component({
  selector: 'app-medtdevice-renderer',
  template: `
    <div class="d-flex justify-content-start flex-nowrap">
      <div>
        <app-device-display
          *ngIf="isCellValid()"
          [value]="cell"
        ></app-device-display>
      </div>
      <div *ngIf="showfilter()" class="ms-1">
        <button
          class="btn btn-sm"
          (click)="setFilter(cell)"
          title="Nach {{ cell.bezeichnung }} filtern"
        >
          <i class="far fa-filter"></i>
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .btn-sm {
        font-size: 7pt;
        padding: 0;
        margin: 0;
      }
    `
  ]
})
export class MEDTDeviceRendererComponent extends MEDataTableCommonCellRenderer {
  public cell: Device = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<any> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }

  showfilter(): boolean {
    if (this.column === null || this.column === undefined) {
      return false;
    }
    return this.column.showFilterButton;
  }

  setFilter(value: any) {
    this.column.sendButtonClicked(this.column.id, 'filter', { value: value });
  }
}
