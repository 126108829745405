import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BenutzerService } from '../../benutzer/services/benutzer.service';
import { MEDataTableColumnComponent } from '../../maennl-commons/data-table/columns';
import { TMECellRendererValueFormatter } from '../../maennl-commons/data-table/renderer/types';
import {
  MEDataTableRow,
  TMEDTGetExtraRowClassesCallback
} from '../../maennl-commons/data-table/rows';
import { MELocalizedComponent } from '../../maennl-commons/localized-component';
import {
  TelefonanlageService,
  knownNumbers
} from '../services/telefonanlage.service';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { CDRCallList } from '../common/cdrcall-list';
import { CDRCall } from '../common/cdrcall';
import { SimpleCDRCallFilter } from '../common/simple-cdrcall-filter';
import { METool } from '../../maennl-commons';

@Component({
  selector: 'app-verbindungsuebersicht',
  templateUrl: './verbindungsuebersicht.component.html',
  styleUrls: ['./verbindungsuebersicht.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerbindungsuebersichtComponent
  extends MELocalizedComponent
  implements OnInit
{
  that = this;
  loading = false;
  public rufnummer = (
    value: any,
    column: MEDataTableColumnComponent,
    row: MEDataTableRow<CDRCall>,
    what: String
  ) => {
    const rn = '' + value;
    if (knownNumbers.has(rn)) {
      return knownNumbers.get(rn);
    }
    return rn;
  };

  verbindungen = new CDRCallList().setWindowSize(50) as CDRCallList;

  constructor(
    public cd: ChangeDetectorRef,
    public telefonanlageService: TelefonanlageService,
    public benutzerService: BenutzerService,
    public toastr: ToastrService
  ) {
    super();
    this.that = this;
    this.selectPreset(0);
  }

  rowClassGetter: TMEDTGetExtraRowClassesCallback<CDRCall> = (
    row: MEDataTableRow<CDRCall>,
    idx
  ) => {
    return '';
  };

  public dauerFormatter: TMECellRendererValueFormatter<CDRCall> = (
    value: any,
    column: MEDataTableColumnComponent,
    row: MEDataTableRow<CDRCall>,
    what = 'unknown'
  ) => {
    return column.component !== null && column.component !== undefined
      ? (column.component as VerbindungsuebersichtComponent).fDuration(
          value * 1000,
          false,
          false,
          true
        )
      : '';
  };
  public aeFormatter: TMECellRendererValueFormatter<CDRCall> = (
    value: any,
    column: MEDataTableColumnComponent,
    row: MEDataTableRow<CDRCall>,
    what = 'unknown'
  ) => {
    return column.component !== null && column.component !== undefined
      ? (column.component as VerbindungsuebersichtComponent).fNum(
          value,
          '1.0-0'
        )
      : '';
  };
  public endeFormatter: TMECellRendererValueFormatter<CDRCall> = (
    value: any,
    column: MEDataTableColumnComponent,
    row: MEDataTableRow<CDRCall>,
    what = 'unknown'
  ) => {
    const c = column.component as VerbindungsuebersichtComponent;

    if (what === 'footer') {
      if (c === null || c === undefined) {
        return '';
      }
      return c.verbindungen.pageCount() > 1
        ? c._('Zwischensumme')
        : c._('Summe');
    }
    if (what === 'footer2') {
      if (c === null || c === undefined) {
        return '';
      }
      return c._('Summe');
    }
    if (c === null || c === undefined) {
      return value;
    }
    return c.fDate(value, 'llll', true);
  };

  public footerTotalValue = (
    fieldname: any,
    column: MEDataTableColumnComponent,
    rows: Array<MEDataTableRow<CDRCall>>
  ) => {
    const c = column.component as VerbindungsuebersichtComponent;
    if (c === null || c === undefined) {
      return null;
    }

    if (fieldname === 'call_duration') {
      fieldname = 'dauer';
    }
    if (fieldname === 'einheiten') {
      fieldname = 'ae';
    }

    if (
      c.verbindungen.extra !== null &&
      c.verbindungen.extra !== undefined &&
      Object.keys(c.verbindungen.extra).indexOf(fieldname) >= 0
    ) {
      return c.verbindungen.extra[fieldname];
    }

    return 0;
  };

  ngOnInit() {
    this.verbindungen.onUpdateRequired.subscribe((list: CDRCallList) => {
      this.loading = true;
      this.cd.markForCheck();
      (list.simpleFilter as SimpleCDRCallFilter).hide_failed = true;
      this.telefonanlageService
        .listVerbindungen(
          list.size,
          list.calcOffset(),
          list.order,
          list.simpleFilter,
          list.getQuery()
        )
        .subscribe(
          (l) => {
            if (l !== null && l !== undefined) {
              list.doPopulateFromListResult(l, 'call_start', 'asc', false);
            }
            this.loading = false;
            this.cd.markForCheck();
          },
          () => {
            this.loading = false;
            this.cd.markForCheck();
          }
        );
    });
  }

  public downloadReport(mode = 0) {
    (this.verbindungen.simpleFilter as SimpleCDRCallFilter).hide_failed = true;

    this.telefonanlageService
      .downloadVerbindungen(
        this.verbindungen.simpleFilter,
        this.verbindungen.getQuery(),
        mode
      )
      .subscribe(
        (response: Blob) => {
          FileSaver.saveAs(response, this._('verbindungen.pdf'));
        },
        (e) => {
          this.toastr.error(
            this._('Die Liste der Verbindungen konnte nicht exportiert werden.')
          );
        }
      );
  }

  public selectPreset(p: number) {
    const now = moment();
    switch (p) {
      case 1:
        now.subtract(1, 'month');
        now.startOf('month');
        (this.verbindungen.simpleFilter as SimpleCDRCallFilter).date_from =
          now.toDate();
        now.endOf('month');
        (this.verbindungen.simpleFilter as SimpleCDRCallFilter).date_until =
          now.toDate();
        break;
      default:
        now.startOf('month');
        (this.verbindungen.simpleFilter as SimpleCDRCallFilter).date_from =
          now.toDate();
        now.endOf('month');
        (this.verbindungen.simpleFilter as SimpleCDRCallFilter).date_until =
          now.toDate();
    }
  }

  typed(row: MEDataTableRow<CDRCall>): CDRCall[] {
    if (METool.isNullOrUndefined(row)) {
      return [];
    }
    return [row.data];
  }
}
