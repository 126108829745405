import { DresserCommand } from './dresser-command';

export class DresserChunk {
  startcode = '';
  address = '';
  sequenz = '';
  position = '';
  commands: DresserCommand[] = [];
  checksum = '';
  end = '';
  valid = false;
  raw = '';
  knownDirection = 0;
  flip_sides = false;

  lastChunk: DresserChunk = null;

  guessDirection(flip_sides = null) {
    if (flip_sides === null) {
      flip_sides = this.flip_sides;
    }
    this.flip_sides = flip_sides;
    if (this.knownDirection === 2 || this.knownDirection === 0x32) {
      if (this.flip_sides) {
        return 'right';
      }
      return 'left';
    }
    if (this.knownDirection === 1 || this.knownDirection === 0x31) {
      if (this.flip_sides) {
        return 'left';
      }
      return 'right';
    }
    if (this.knownDirection === 3 || this.knownDirection === 0x33) {
      return 'center';
    }
    let dir = '';
    this.commands.forEach((cmd) => {
      if (dir === '') {
        if (cmd.cmd === '05') {
          dir = 'left';
        } else if (cmd.cmd === '02') {
          if (cmd.length === '08') {
            dir = 'right';
          }
        } else if (cmd.cmd === '03') {
          if (
            cmd.data.substr(0, 6) === '000000' &&
            cmd.data.substr(6, 2) !== '00'
          ) {
            dir = 'right';
          } else if (
            cmd.data.substr(6, 2) === '00' &&
            cmd.data.substr(0, 6) !== '000000'
          ) {
            if (parseInt(cmd.data.substr(0, 6), 10) > 1000) {
              // wahrscheinlich Einzelpreis mit Status
              dir = 'right';
            } else {
              dir = 'left';
            }
          } else if (cmd.data.substr(6, 2) !== '00') {
            // augenscheinlich ZP-Status
            dir = 'right';
          } else if (parseInt(cmd.data.substr(0, 6), 10) > 10) {
            // augenscheinlich Mengenbegrenzung
            dir = 'left';
          }
        } else if (cmd.cmd === '04') {
          dir = 'left';
        } else if (cmd.cmd === '01') {
          if (cmd.data === '01') {
            dir = 'right';
          } else if (cmd.data === '02') {
            dir = 'right';
          } else if (cmd.data === '07') {
            dir = 'right';
          } else if (cmd.data === '03') {
            dir = 'left';
          } else if (cmd.data === '08') {
            dir = 'left';
          } else if (cmd.data === '0A') {
            dir = 'left';
          }
        }
      }
    });
    if (dir === '') {
      if (this.lastChunk !== null && this.lastChunk !== undefined) {
        if (this.startcode === this.lastChunk.startcode) {
          if (this.position === this.lastChunk.position + 1) {
            dir = this.lastChunk.guessDirection();
          }
        }
      }
    }
    if (dir === '') {
      dir = 'center';
    }
    return dir;
  }

  isLeft(): boolean {
    return this.guessDirection() === 'left';
  }

  isRight(): boolean {
    return this.guessDirection() === 'right';
  }

  isCenter(): boolean {
    return this.guessDirection() === 'center';
  }
}
