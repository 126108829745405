<ng-template #ahIcon>
    <fa-icon [icon]="['fas', 'circle-location-arrow']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="webfuel Nozzle-Readers"
></me-area-heading>

<me-tab-card
    #rootTabCard
>

    <me-pane id="tab-readers"
             title="Nozzle-Readers"
             (activated)="activate(readersTab)"
    >
        <app-tab-nozzle-readers #readersTab></app-tab-nozzle-readers>
    </me-pane>
</me-tab-card>
