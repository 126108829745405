import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SimProvider } from '../../common/sim-provider';
import { MEAddOnClickEvent } from '../../../maennl-commons/forms/events';
import { noop } from '../../../maennl-commons/tools';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-sim-provider-field',
  template: `
    <div class="form-group row">
      <label
        for="{{ id }}"
        class="col-{{ labelWidth }} col-form-label"
        *ngIf="labelWidth > 0"
      >
        {{ label }}
      </label>
      <div class="col-{{ 12 - labelWidth }}">
        <div
          class=""
          [class.input-group]="hasAddon()"
          [class.input-group-sm]="hasAddon() && small"
        >
          <app-sim-provider-selector
            [value]="value"
            (valueChange)="update($event)"
            [nullable]="nullable"
            [small]="small"
          ></app-sim-provider-selector>
          <span
            class="input-group-btn addon"
            *ngFor="let ai of addonIcons; let idx = index"
          >
            <button
              class="btn btn-secondary addon-btn-{{ idx }}"
              [class.btn-sm]="small"
              type="button"
              title="{{ _addOnLabels[idx] }}"
              (click)="aClick(idx)"
            >
              <i class="{{ ai }}"></i>
            </button>
          </span>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host .addon button:first-child {
        border-left: none;
        padding: 0 5px;
      }
    `
  ]
})
export class SimProviderFieldComponent implements OnInit {
  public _value: SimProvider = null;
  public _icons: string[] = [];

  @Input() nullable = true;

  @Input() label = 'Provider auswählen';

  @Input('addonicon')
  set icon(v: any) {
    this._icons = [];
    if (Array.isArray(v)) {
      v.forEach((i) => {
        this._icons.push(i);
      });
    } else {
      this._icons.push(v);
    }
    while (this._addOnLabels.length < this._icons.length) {
      this._addOnLabels.push('');
    }
  }

  get icon(): any {
    if (this._icons.length < 1) {
      return '';
    } else if (this._icons.length < 2) {
      return this._icons[0];
    }
    return this._icons;
  }

  _addOnLabels: string[] = [];

  @Input()
  set addonlabel(v: any) {
    this._addOnLabels = [];
    if (Array.isArray(v)) {
      v.forEach((i) => {
        this._addOnLabels.push(i);
      });
    } else {
      this._addOnLabels.push(v);
    }
    while (this._addOnLabels.length < this._icons.length) {
      this._addOnLabels.push('');
    }
  }

  @Input() enableAddon = false;
  @Input() id = '';
  @Input() labelWidth = 3;
  @Input() disabled = false;
  @Input() small = false;

  @Input()
  set value(v: SimProvider) {
    if (this._value !== v) {
      this._value = v;
      if (!this.disabled) {
        this.valueChange.emit(v);
      }
    }
  }

  @Output() valueChange = new EventEmitter<SimProvider>();
  @Output() addonClick = new EventEmitter<MEAddOnClickEvent>();

  get value(): SimProvider {
    return this._value;
  }

  constructor() {
    noop();
  }

  ngOnInit() {
    if (this.id === '') {
      this.id =
        'tf' +
        Md5.hashStr(
          'simProvider-' + this.label + Math.random() + '-' + Math.random()
        );
    }
  }

  update(event: SimProvider) {
    if (!this.disabled) {
      this.value = event;
    }
  }

  hasAddon(): boolean {
    return !this.disabled && this.enableAddon && this._icons.length > 0;
  }

  get addonIcons(): any[] {
    if (this.hasAddon()) {
      return this._icons;
    }
    return [];
  }

  aClick(i?: any) {
    const e: MEAddOnClickEvent = new MEAddOnClickEvent();
    e.component = this;
    e.addon = i;
    this.addonClick.emit(e);
  }
}
