import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import {
  IMEListResult,
  MEApiTool,
  MEResultMetaSort,
} from '../../../maennl-commons';
import { IMESimpleFilter } from '../../../maennl-commons/filters';
import { INozzleReader } from './inozzle-reader';

@Injectable({
  providedIn: 'root',
})
export class NozzleReaderService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public list(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<INozzleReader> = null,
    queryString = ''
  ): Observable<IMEListResult<INozzleReader>> {
    const uri = MEApiTool.buildListURL(
      '/api/webfuel/nozzle-readers/readers',
      max,
      offset,
      sort,
      filter,
      queryString
    );
    return this.http
      .get<IMEListResult<INozzleReader>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }
}
