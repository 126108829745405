import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {TenantSettings} from "./tenant-settings.class";
import {ITenantSettings} from "./tenant-settings.interface";

export class TenantSettingsList extends MERemoteList<TenantSettings> {
    __classname:string = 'TenantSettingsList';
    __instance = '';

    static fromListResult(
        res: IMEListResult<ITenantSettings>
    ): TenantSettingsList {
        const newList= new TenantSettingsList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun:boolean=false,timeout: number=400) {
        super();
        this.initList(autorun,timeout);
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'name',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true
    ):void {
        super.doPopulateFromListResult(res,defaultSort,defaultOrder,clearFilter);
    }
}