import {
  MEGenericEntity,
  MEGenericEntityFields,
  METool,
} from '../../../maennl-commons';
import { ITrackerOptions } from './itracker-options';
import { IVehicleTracker } from './ivehicle-tracker';
import * as moment from 'moment';

export class VehicleTracker
  extends MEGenericEntity<IVehicleTracker>
  implements IVehicleTracker
{
  fahrzeug_art_code: string = null;
  fahrzeug_art_display_text: string = null;
  fahrzeug_art_icon: string = null;
  fahrzeug_art_id: number = null;
  id: number = null;
  lp_altitude: number = null;
  lp_angle: number = null;
  lp_hdop: number = null;
  lp_latitude: number = null;
  lp_longitude: number = null;
  lp_odometer: number = null;
  lp_satellites: number = null;
  lp_speed: number = null;
  lp_working_hours: number = null;
  lp_zeitpunkt: Date = null;
  lp_zuendung: boolean = null;
  tenant: number = null;
  tenant_code: string = null;
  tenant_info: string = null;
  tenant_host: string = null;
  tenant_projekt: number = null;
  tracker_identification: string = null;
  tracker_last_position_id: number = null;
  tracker_last_seen: Date = null;
  tracker_last_voltage: number = null;
  tracker_last_voltage_zp: Date = null;
  tracker_last_address: string = null;
  tracker_text: string = null;
  tracker_typ_code: string = null;
  tracker_typ_display_text: string = null;
  tracker_typ_id: number = null;
  vehicle_display_text: string = null;
  vehicle_nummer: number = null;
  vehicle_plate: string = null;
  vehicle_vin: string = null;
  vehicle_km_stand: number = null;
  vehicle_km_stand_metres: number = null;
  vehicle_working_hours: number = null;
  vehicle_working_hours_seconds: number = null;
  is_auto: number = null;
  lp_odometer_metres: number = null;
  lp_working_hours_seconds: number = null;
  tracker_obj: ITrackerOptions = null;

  constructor() {
    super();
  }

  get imei_base() {
    if (METool.isEmpty(this.tracker_identification)) {
      return '';
    }
    if (this.tracker_identification.trim().length < 14) {
      return this.tracker_identification.trim();
    }
    return this.tracker_identification.trim().substring(0, 14);
  }

  get imei_addon() {
    if (METool.isEmpty(this.tracker_identification)) {
      return '';
    }
    if (this.tracker_identification.trim().length < 15) {
      return '';
    }
    return this.tracker_identification.trim().substring(14);
  }

  get vehicle_working_hours_as_duration() {
    if (
      METool.isNullOrUndefined(this.vehicle_working_hours_seconds) ||
      this.vehicle_working_hours_seconds === 0
    ) {
      return moment.duration(this.vehicle_working_hours, 'hours');
    }
    return moment.duration(this.vehicle_working_hours_seconds, 'seconds');
  }

  get lp_working_hours_as_duration() {
    if (
      METool.isNullOrUndefined(this.lp_working_hours_seconds) ||
      this.lp_working_hours_seconds === 0
    ) {
      return moment.duration(this.lp_working_hours, 'hours');
    }
    return moment.duration(this.lp_working_hours_seconds, 'seconds');
  }

  get ip() {
    if (METool.isEmpty(this.tracker_last_address)) {
      return '';
    }
    const n = ('' + this.tracker_last_address).trim().split(':');
    return n[0];
  }

  static fromResult(
    entity: IVehicleTracker,
    createIfNull = false
  ): VehicleTracker {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new VehicleTracker();
      }
      return null;
    }

    const instance = new VehicleTracker();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addLongField('tenant')
        .addStringField('tenant_code')
        .addStringField('tenant_info')
        .addLongField('tenant_projekt')
        .addStringField('tenant_host')
        .addLongField('vehicle_nummer')
        .addStringField('vehicle_display_text')
        .addStringField('vehicle_vin')
        .addStringField('vehicle_plate')
        .addLongField('vehicle_km_stand')
        .addLongField('vehicle_km_stand_metres')
        .addLongField('vehicle_working_hours')
        .addLongField('vehicle_working_hours_seconds')
        .addLongField('fahrzeug_art_id')
        .addStringField('fahrzeug_art_code')
        .addStringField('fahrzeug_art_display_text')
        .addStringField('fahrzeug_art_icon')
        .addStringField('tracker_text')
        .addStringField('tracker_last_address')
        .addLongField('tracker_typ_id')
        .addStringField('tracker_typ_code')
        .addTextField('tracker_typ_display_text')
        .addStringField('tracker_identification')
        .addDateField('tracker_last_seen')
        .addDecimalField('tracker_last_voltage')
        .addDateField('tracker_last_voltage_zp')
        .addLongField('tracker_last_position_id')
        .addDateField('lp_zeitpunkt')
        .addDecimalField('lp_longitude')
        .addDecimalField('lp_latitude')
        .addDecimalField('lp_altitude')
        .addDecimalField('lp_angle')
        .addDecimalField('lp_speed')
        .addDecimalField('lp_hop')
        .addLongField('lp_satellites')
        .addDecimalField('lp_odometer')
        .addDecimalField('lp_working_hours')
        .addLongField('is_auto'),
      e => {
        const x = e as VehicleTracker;
        x.tracker_obj = JSON.parse(x.tracker_text) as ITrackerOptions;
      }
    );
    return instance;
  }
}
