import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, EventEmitter,
    Input,
    OnDestroy,
    OnInit, Output, TemplateRef,
    ViewChild, ViewContainerRef,
    ViewEncapsulation
} from "@angular/core";
import {Applications} from "../../../commons/applications.class";
import {ApplicationsService} from "../../../commons/applications.service";
import {BenutzerService} from "../../../../../benutzer/services/benutzer.service";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-tab-applications-editor-ip-addresses',
    templateUrl: './tab-applications-editor-ip-addresses.component.html',
    styleUrls: ['./tab-applications-editor-ip-addresses.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TabApplicationsEditorIPAddressesComponent
    implements OnInit, OnDestroy {
    @Input() entity: Applications = null;
    @ViewChild('ipaddresses', {read: ViewContainerRef}) ipaddressesRef: ViewContainerRef;
    @ViewChild('ipaddressestemp', {read: TemplateRef}) ipaddressRef: TemplateRef<any>;
    @Output() entityChanged = new EventEmitter<Applications>();
    inputValue = '';

    private _tabActive = false;

    constructor(
        public cd: ChangeDetectorRef,
        public service: ApplicationsService,
        public benutzerService: BenutzerService,
        public toastr: ToastrService,
    ) {
    }

    public get tabActive():boolean {
        return this._tabActive;
    }

    @Input()
    public set tabActive(value: boolean) {
        if(this._tabActive!==value) {
            this._tabActive = value;
            this.cd.markForCheck();
        }
    }

    onActivate() {
        this.tabActive = true;
    }

    onDeactivate() {
        this.tabActive = false;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    refresh_view() {
        if (this.ipaddressesRef !== null && this.ipaddressesRef !== undefined) {
            this.ipaddressesRef.clear();
            this.ipaddressesRef.createEmbeddedView(this.ipaddressRef);
        }
    }

    add_ip_address(value:string) {
        if(
            this.entity === null ||
            this.entity === undefined ||
            value === null ||
            value === undefined ||
            value.trim() === ''
        ) {
            this.toastr.error('ungültige IP Adresse');
            return;
        }
        let regexip = new RegExp(/(^((2[0-5][0-5]|1?\d?\d)?\.(2[0-5][0-5]|1?\d?\d)?\.(2[0-5][0-5]|1?\d?\d)?\.(2[0-5][0-5]|1?\d?\d)?(\/(3[0-2]|[1-2]?\d))?)$)|(^(([0-9a-fA-F]{0,4}:){0,7}[0-9a-fA-F]{0,4}(\/(1[0-1]\d|12[0-8]|\d?\d))?)?$)/, 's')
        if(!regexip.test(value.trim())||value.trim().split('::').length>2) {
            this.toastr.error('ungültige IP Adresse');
            return;
        }
        let value_new = value.trim().replace(/\.\./, '.0.');
        if (value_new.startsWith('.')) {
            value_new = '0' + value_new;
        }
        value_new= value_new.replace(/\.\//, '.0/');
        if(this.entity.ip_addresses.includes(value_new)) {
            return;
        }
        this.service.add_ip_address(this.entity.id, value_new).subscribe({
            next: r => {
                if(r.success) {
                    this.reloadEntity();
                } else {
                    this.toastr.error('ungültige IP Adresse');
                }
            },
            error:() => {
                this.toastr.error('Das Hinzufügen der IP Adresse ist fehlgeschlagen');
            }
        });
    }

    remove_ip_address(value:string) {
        if(
            this.entity === null ||
            this.entity === undefined||
            value === null ||
            value === undefined ||
            value.trim() === ''
        ) {
            return;
        }
        this.service.remove_ip_address(this.entity.id, value).subscribe( {
            next: r => {
                if(r.success) {
                    this.reloadEntity();
                } else {
                    this.toastr.error(
                        'Das Entfernen der IP Adresse ist fehlgeschlagen'
                    )
                }
            },
            error: () => {
                this.toastr.error('Das Entfernen der IP Adresse ist fehlgeschlagen');
            }
        });
    }

    entityChangedHandler(entity:Applications) {
        this.entityChanged.emit(entity);
    }

    reloadEntity(){
        this.service.get(this.entity.id).subscribe((a:Applications) =>{
            if(this.entity.id === a.id) {
                this.entity=a;
                this.entityChangedHandler(this.entity);
                this.refresh_view();
            }
            this.cd.markForCheck();
        });
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}