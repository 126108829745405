import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Tenant } from '../commons/tenant';
import { TenantList } from '../commons/tenant-list';
import { RouterService } from '../../../router/services/router.service';
import {
  MEHighlitePipe,
  MELocalizedComponent,
  MESelectComponent,
  MESubscriptionHelper,
  METool,
  TMESelectItemFormatterCallback,
  TMESelectItemMatcherCallback,
  TMESelectItemTextCallback
} from '../../../maennl-commons';
import { TenantService } from '../commons/tenant.service';

@Component({
  selector: 'app-tenant-selector',
  template: `
    <me-select
      [asMatrix]="false"
      [idField]="'id'"
      [items]="liste.data"
      [placeholder]="placeholder"
      [small]="small"
      (onSearch)="doSearch($event)"
      (onSelect)="doSelect($event)"
      [nullable]="nullable"
      [onFormat]="itemFormat"
      [onGetText]="itemText"
      [onMatch]="itemMatcher"
    ></me-select>
  `,
  styleUrls: ['./tenant-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantSelectorComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input()
  set value(v: Tenant) {
    if (this._value !== v) {
      this._value = v;
      if (this.meSelect !== null && this.meSelect !== undefined) {
        this.meSelect.value = v;
      }
      this.valueChange.emit(v);
      this.value_idChange.emit(this.value_id);
    }
  }

  get value(): Tenant {
    return this._value;
  }

  get value_id(): number {
    if (METool.isNullOrUndefined(this.value)) {
      return null;
    }
    return this.value.id;
  }

  @Input()
  set value_id(v: number) {
    if (METool.isNullOrUndefined(v)) {
      this.value = null;
      return;
    }
    if (v === this.value_id) {
      return;
    }
    if (
      !METool.isNullOrUndefined(this.liste) &&
      Array.isArray(this.liste.data)
    ) {
      for (let i = 0; i < this.liste.data.length; i++) {
        if (this.liste.data[i].id === v) {
          this.value = this.liste.data[i];
          return;
        }
      }
    }
    this.tenantService.get(v).subscribe({
      next: (t) => {
        if (t !== null) {
          this.liste.data.push(t);
        }
        this.value = t;
      },
      error: () => {
        this.value = null;
      }
    });
  }

  __classname = 'TenantSelectorComponent';
  __instance = '';

  @Input() nullable = true;
  @Input() placeholder = 'Mandant auswählen';
  @Input() small = false;

  @Input() filterQuery = '';

  public _value: Tenant = null;

  @Output() valueChange = new EventEmitter<Tenant>();
  @Output() value_idChange = new EventEmitter<number>();

  public liste = new TenantList();

  @ViewChild(MESelectComponent, { static: false }) meSelect: MESelectComponent;

  ngAfterViewInit(): void {
    if (this.meSelect !== null && this.meSelect !== undefined) {
      this.meSelect.onFormat = this.itemFormat;
      this.meSelect.onGetText = this.itemText;
      this.meSelect.onMatch = this.itemMatcher;
      this.meSelect.value = this._value;
    }
    this.liste.start();
  }

  constructor(
    public tenantService: TenantService,
    public cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.liste.setWindowSize(150);
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe((list: TenantList) => {
        list.searchString2 = ('' + this.filterQuery).trim();
        this.tenantService
          .list(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.getQuery()
          )
          .subscribe((l) => {
            list.populateFromListResult(l);
            this.cd.markForCheck();
          });
      }, undefined)
    );

    if (this.meSelect !== null && this.meSelect !== undefined) {
      this.meSelect.onFormat = this.itemFormat;
      this.meSelect.onGetText = this.itemText;
      this.meSelect.onMatch = this.itemMatcher;
    }
    this.liste.start();
  }

  doSearch(value) {
    if (value === null || value === undefined) {
      value = '';
    }

    this.liste.simpleFilter.clear();
    this.liste.searchString2 = ('' + this.filterQuery).trim();
    this.liste.searchString = ('' + value).trim();
    this.liste.reload();
  }

  doSelect(value: Tenant) {
    this._value = value;
    this.valueChange.emit(value);
    this.value_idChange.emit(this.value_id);
  }

  public itemFormat: TMESelectItemFormatterCallback<Tenant> = (
    item: Tenant,
    search,
    forList: boolean
  ) => {
    if (item === null || item === undefined) {
      return '';
    }
    return MEHighlitePipe.transformString('' + item.info, search);
  };

  public itemText: TMESelectItemTextCallback<Tenant> = (item: Tenant) => {
    if (item === null || item === undefined) {
      return '';
    }
    return item.info;
  };

  public itemMatcher: TMESelectItemMatcherCallback<Tenant> = (
    item: Tenant,
    search
  ) => {
    if (!search) {
      return true;
    }
    if (search.trim() === '') {
      return true;
    }
    if (!item) {
      return false;
    }
    const x = item.info + ' ' + item.id + ' ' + item.code;

    return x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
  };

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.liste.release();
    super.ngOnDestroy();
  }
}
