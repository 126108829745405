import { Component, Input, OnInit } from '@angular/core';
import { ICheck } from '../../common/icheck';
import { noop } from '../../../maennl-commons/tools';

@Component({
  selector: 'app-check-display',
  template: ` <div *ngIf="isValid()" title="{{ title }}">{{ getInfo() }}</div> `
})
export class CheckDisplayComponent implements OnInit {
  @Input() public value: ICheck = null;
  @Input() public title = '';

  constructor() {
    noop();
  }

  ngOnInit() {
    noop();
  }

  isValid() {
    return this.value !== null && this.value !== undefined;
  }

  getInfo() {
    let ret = '';
    if (this.isValid()) {
      ret = this.value.info.trim();
    }
    return ret;
  }
}
