import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { MEGenericModalComponent } from '../../../maennl-commons/megeneric-modal';
import { SimplePeilungFilter } from '../../common/simple-peilung-filter';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MEPubSubService } from '../../../maennl-commons/services';
import * as moment from 'moment';

@Component({
  selector: 'app-peilungen-filter-dialog',
  templateUrl: './peilungen-filter-dialog.component.html',
  styleUrls: ['./peilungen-filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeilungenFilterDialogComponent extends MEGenericModalComponent<SimplePeilungFilter> {
  public initEntity(src: SimplePeilungFilter): SimplePeilungFilter {
    const f = new SimplePeilungFilter();
    if (src !== null && src !== undefined) {
      f.sonde = src.sonde;
      f.zeitpunkt = src.zeitpunkt;
    }
    return f;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public cd: ChangeDetectorRef
  ) {
    super(activeModal, pubsub);
  }

  setZP(days: number | string) {
    if (isNaN(parseInt('' + days, 10))) {
      if (days === 'heute') {
        (this.entity as SimplePeilungFilter).zeitpunkt = [
          moment().startOf('day').toDate(),
          null
        ];
      }
      if (days === 'woche') {
        (this.entity as SimplePeilungFilter).zeitpunkt = [
          moment().startOf('isoWeek').toDate(),
          null
        ];
      }
      if (days === 'monat') {
        (this.entity as SimplePeilungFilter).zeitpunkt = [
          moment().startOf('month').toDate(),
          null
        ];
      }
    } else {
      (this.entity as SimplePeilungFilter).zeitpunkt = [
        moment()
          .subtract(parseInt('' + days, 10), 'days')
          .startOf('day')
          .toDate(),
        null
      ];
    }
    this.cd.markForCheck();
  }
}
