import { Component, Input, ViewChild } from '@angular/core';
import { SysHealthHistoryDisplayComponent } from '../sys-health-history-display/sys-health-history-display.component';
import { noop } from '../../../maennl-commons/tools';

@Component({
  selector: 'app-sys-health-history-tab',
  templateUrl: './sys-health-history-tab.component.html',
  styleUrls: ['./sys-health-history-tab.component.scss']
})
export class SysHealthHistoryTabComponent {
  @Input() id = 'SysHealthHistoryTabComponent';
  @ViewChild(SysHealthHistoryDisplayComponent, { static: false }) history =
    null;

  constructor() {
    noop();
  }

  onActivate() {
    if (this.history !== null && this.history !== undefined) {
      this.history.onActivate();
    }
  }

  onDeactivate() {
    if (this.history !== null && this.history !== undefined) {
      this.history.onDeactivate();
    }
  }
}
