import {CommonModule, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {APP_INITIALIZER, ErrorHandler, Injector, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NgbModule, NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {L10nIntlModule, L10nLoader, L10nTranslationModule} from 'angular-l10n';
import * as moment from 'moment';
import {MarkdownModule} from 'ngx-markdown';
import {LMarkdownEditorModule} from 'ngx-markdown-editor';
import {GlobalConfig, ToastrModule} from 'ngx-toastr';
import {environment} from '../environments/environment';
import {AbrechnungDtsDetailsComponent} from './abrechnung/abrechnung-dts-details/abrechnung-dts-details.component';
import {
    AbrechnungDtsPrintPreviewComponent
} from './abrechnung/abrechnung-dts-print-preview/abrechnung-dts-print-preview.component';
import {
    AbrechnungGlukoseportalDetailsComponent
} from './abrechnung/abrechnung-glukoseportal-details/abrechnung-glukoseportal-details.component';
import {
    AbrechnungGlukoseportalPrintPreviewComponent
} from './abrechnung/abrechnung-glukoseportal-print-preview/abrechnung-glukoseportal-print-preview.component';
import {
    AbrechnungGreenlineDetailsComponent
} from './abrechnung/abrechnung-greenline-details/abrechnung-greenline-details.component';
import {
    AbrechnungGreenlinePrintPreviewComponent
} from './abrechnung/abrechnung-greenline-print-preview/abrechnung-greenline-print-preview.component';
import {
    AbrechnungHoyerDetailsComponent
} from './abrechnung/abrechnung-hoyer-details/abrechnung-hoyer-details.component';
import {
    AbrechnungHoyerPrintPreviewComponent
} from './abrechnung/abrechnung-hoyer-print-preview/abrechnung-hoyer-print-preview.component';
import {
    AbrechnungPickelmannDetailsComponent
} from './abrechnung/abrechnung-pickelmann-details/abrechnung-pickelmann-details.component';
import {
    AbrechnungPickelmannPrintPreviewComponent
} from './abrechnung/abrechnung-pickelmann-print-preview/abrechnung-pickelmann-print-preview.component';
import {
    AbrechnungRolfesDetailsComponent
} from './abrechnung/abrechnung-rolfes-details/abrechnung-rolfes-details.component';
import {
    AbrechnungRolfesPrintPreviewComponent
} from './abrechnung/abrechnung-rolfes-print-preview/abrechnung-rolfes-print-preview.component';
import {
    AbrechnungTKTankstellenDetailsComponent
} from './abrechnung/abrechnung-tktankstellen-details/abrechnung-tktankstellen-details.component';
import {
    AbrechnungTKTankstellenPrintPreviewComponent
} from './abrechnung/abrechnung-tktankstellen-print-preview/abrechnung-tktankstellen-print-preview.component';
import {AbrechnungVaroDetailsComponent} from './abrechnung/abrechnung-varo-details/abrechnung-varo-details.component';
import {
    AbrechnungVaroPrintPreviewComponent
} from './abrechnung/abrechnung-varo-print-preview/abrechnung-varo-print-preview.component';
import {
    AbrechnungVaroRechnungsserverDetailsComponent
} from './abrechnung/abrechnung-varo-rechnungsserver-details/abrechnung-varo-rechnungsserver-details.component';
import {
    AbrechnungVaroRechnungsserverPrintPreviewComponent
} from './abrechnung/abrechnung-varo-rechnungsserver-print-preview/abrechnung-varo-rechnungsserver-print-preview.component';
import {AbrechnungComponent} from './abrechnung/abrechnung.component';
import {AbrechnungDtsService} from './abrechnung/services/abrechnung-dts.service';
import {AbrechnungGlukoseportalService} from './abrechnung/services/abrechnung-glukoseportal.service';
import {AbrechnungHoyerService} from './abrechnung/services/abrechnung-hoyer.service';
import {AbrechnungPickelmannService} from './abrechnung/services/abrechnung-pickelmann.service';
import {AbrechnungRolfesService} from './abrechnung/services/abrechnung-rolfes.service';
import {AbrechnungTKTankstellenService} from './abrechnung/services/abrechnung-tktankstellen.service';
import {AbrechnungVaroRechnungsserverService} from './abrechnung/services/abrechnung-varo-rechnungsserver.service';
import {AbrechnungVaroService} from './abrechnung/services/abrechnung-varo.service';
import {AbrechnungService} from './abrechnung/services/abrechnung.service';
import {TabAbrechnungDtsComponent} from './abrechnung/tab-abrechnung-dts/tab-abrechnung-dts.component';
import {
    TabAbrechnungGlukoseportalComponent
} from './abrechnung/tab-abrechnung-glukoseportal/tab-abrechnung-glukoseportal.component';
import {
    TabAbrechnungGreenlineComponent
} from './abrechnung/tab-abrechnung-greenline/tab-abrechnung-greenline.component';
import {TabAbrechnungHoyerComponent} from './abrechnung/tab-abrechnung-hoyer/tab-abrechnung-hoyer.component';
import {
    TabAbrechnungPickelmannComponent
} from './abrechnung/tab-abrechnung-pickelmann/tab-abrechnung-pickelmann.component';
import {TabAbrechnungRolfesComponent} from './abrechnung/tab-abrechnung-rolfes/tab-abrechnung-rolfes.component';
import {
    TabAbrechnungTKTankstellenComponent
} from './abrechnung/tab-abrechnung-tktankstellen/tab-abrechnung-tktankstellen.component';
import {
    TabAbrechnungVaroRechnungsserverComponent
} from './abrechnung/tab-abrechnung-varo-rechnungsserver/tab-abrechnung-varo-rechnungsserver.component';
import {TabAbrechnungVaroComponent} from './abrechnung/tab-abrechnung-varo/tab-abrechnung-varo.component';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BenutzerComponent} from './benutzer/controls/benutzer/benutzer.component';
import {IsLoggedInDirective} from './benutzer/controls/is-logged-in.directive';
import {LoginComponent} from './benutzer/controls/login/login.component';
import {TabBenutzerComponent} from './benutzer/controls/tab-benutzer/tab-benutzer.component';
import {UserNameDisplayComponent} from './benutzer/controls/user-name-display/user-name-display.component';
import {BenutzerService} from './benutzer/services/benutzer.service';
import {LoggedInGuard} from './benutzer/services/logged-in-guard';
import {ClearingComponent} from './clearing/clearing.component';
import {DashboardInfoBlockComponent} from './common-controls/dashboard-info-block/dashboard-info-block.component';
import {DokumentationService} from './dokumentation/commons/dokumentation.service';
import {DokumentationComponent} from './dokumentation/dokumentation.component';
import {HttpClientInterceptorConfig} from './http-client-interceptor-config';
import {HttpTranslationLoader} from './http-translation-loader';
import {IndexComponent} from './index/index.component';
import {initL10n, l10nConfig} from './l10nconfig';
import {MEErrorHandler, ME_HTTP_CLIENT_INTERCEPTOR, MaennlCommonsModule} from './maennl-commons';
import {MEAppInjector} from './maennl-commons/tools/meapp-injector';
import {ATCommandToolComponent} from './meconnect/csdserver/atcommand-tool/atcommand-tool.component';
import {BridgeEditorComponent} from './meconnect/csdserver/bridge-editor/bridge-editor.component';
import {ClientEditorComponent} from './meconnect/csdserver/client-editor/client-editor.component';
import {MitschnittDeviceEditorComponent} from "./mitschnitt/device-editor/device-editor.component";
import {UserEditorComponent} from "./benutzer/benutzer-editor/benutzer-editor.component";
import {
    CSDServerClientFieldComponent
} from './meconnect/csdserver/controls/csdserver-client-field/csdserver-client-field.component';
import {
    CSDServerClientPickerComponent
} from './meconnect/csdserver/controls/csdserver-client-picker/csdserver-client-picker.component';
import {
    CSDServerClientTypeFieldComponent
} from './meconnect/csdserver/controls/csdserver-client-type-field/csdserver-client-type-field.component';
import {
    CSDServerClientTypePickerComponent
} from './meconnect/csdserver/controls/csdserver-client-type-picker/csdserver-client-type-picker.component';
import {CSDServerComponent} from './meconnect/csdserver/csdserver.component';
import {CSDServerService} from './meconnect/csdserver/services/csdserver.service';
import {TabCSDBridgesComponent} from './meconnect/csdserver/tab-csdbridges/tab-csdbridges.component';
import {TabCSDClientsComponent} from './meconnect/csdserver/tab-csdclients/tab-csdclients.component';
import {MEConnectComponent} from './meconnect/meconnect.component';
import {OpenVPNLinuxserverComponent} from './meconnect/open-vpnlinuxserver/open-vpnlinuxserver.component';
import {OpenVPNTelefonanlageComponent} from './meconnect/open-vpntelefonanlage/open-vpntelefonanlage.component';
import {DresserDisplayComponent} from './mitschnitt/controls/dresser-display/dresser-display.component';
import {GenericDisplayComponent} from './mitschnitt/controls/generic-display/generic-display.component';
import {MedtcustomRenderer1Component} from './mitschnitt/controls/medtcustom-renderer1/medtcustom-renderer1.component';
import {
    MEDTMitschnittgeraetRendererComponent
} from './mitschnitt/controls/medtmitschnittgeraet-renderer/medtmitschnittgeraet-renderer.component';
import {
    MEDTMitschnittstreamPaketDatenRendererComponent
} from './mitschnitt/controls/medtmitschnittstream-paket-daten-renderer/medtmitschnittstream-paket-daten-renderer.component';
import {
    MitschnittgeraetDisplayComponent
} from './mitschnitt/controls/mitschnittgeraet-display/mitschnittgeraet-display.component';
import {
    MitschnittgeraetPickerComponent
} from './mitschnitt/controls/mitschnittgeraet-picker/mitschnittgeraet-picker.component';
import {ZSRDisplayComponent} from './mitschnitt/controls/zsrdisplay/zsrdisplay.component';
import {
    MitschnittStreamDetailsPopupComponent
} from './mitschnitt/mitschnitt-stream-details-popup/mitschnitt-stream-details-popup.component';
import {MitschnittComponent} from './mitschnitt/mitschnitt.component';
import {
    MitschnittstreamFilterDialogComponent
} from './mitschnitt/mitschnittstream-filter-dialog/mitschnittstream-filter-dialog.component';
import {MitschnittService} from './mitschnitt/services/mitschnitt.service';
import {TabMitschnittDevicesComponent} from './mitschnitt/tab-mitschnitt-devices/tab-mitschnitt-devices.component';
import {TabMitschnittStreamsComponent} from './mitschnitt/tab-mitschnitt-streams/tab-mitschnitt-streams.component';
import {NavComponent} from './nav/nav.component';
import {SideBarGroupComponent} from './nav/side-bar-group/side-bar-group.component';
import {SideBarLinkComponent} from './nav/side-bar-link/side-bar-link.component';
import {SidebarTogglerComponent} from './nav/sidebar/sidebar-toggler.component';
import {SidebarComponent} from './nav/sidebar/sidebar.component';
import {SysInfoService} from './nav/sys-info.service';
import {
    RemoteLogParamsRendererComponent
} from './remote-log/remote-log-params-renderer/remote-log-params-renderer.component';
import {RemoteLogTimeRendererComponent} from './remote-log/remote-log-time-renderer/remote-log-time-renderer.component';
import {RemoteLogComponent} from './remote-log/remote-log.component';
import {NozzleReaderRemoteLogService} from './remote-log/services/nozzle-reader-remote-log.service';
import {
    NozzleReaderLogFilterPopupComponent
} from './remote-log/tab-nozzle-reader-logs/nozzle-reader-log-filter-popup/nozzle-reader-log-filter-popup.component';
import {TabNozzleReaderLogsComponent} from './remote-log/tab-nozzle-reader-logs/tab-nozzle-reader-logs.component';
import {
    DeviceDiskUsageDisplayComponent
} from './router/controls/device-disk-usage-display/device-disk-usage-display.component';
import {DeviceDisplayComponent} from './router/controls/device-display/device-display.component';
import {DeviceFieldComponent} from './router/controls/device-field/device-field.component';
import {DeviceOnlineDisplayComponent} from './router/controls/device-online-display/device-online-display.component';
import {DeviceSelectorComponent} from './router/controls/device-selector/device-selector.component';
import {
    DeviceSelectorWithLabelComponent
} from './router/controls/device-selector-w-lbl/device-selector-w-lbl.component';
import {DeviceTypeDisplayComponent} from './router/controls/device-type-display/device-type-display.component';
import {
    MEDTDeviceDiskUsageDisplayRendererComponent
} from './router/controls/medtdevice-disk-usage-display-renderer/medtdevice-disk-usage-display-renderer.component';
import {
    MEDTDeviceOnlineRendererComponent
} from './router/controls/medtdevice-online-renderer/medtdevice-online-renderer.component';
import {MEDTDeviceRendererComponent} from './router/controls/medtdevice-renderer/medtdevice-renderer.component';
import {
    MEDTDeviceSysStatusRendererComponent
} from './router/controls/medtdevice-sys-status-renderer/medtdevice-sys-status-renderer.component';
import {
    MEDTDeviceTypeRendererComponent
} from './router/controls/medtdevice-type-renderer/medtdevice-type-renderer.component';
import {
    MEDTSimProviderRendererComponent
} from './router/controls/medtsim-provider-renderer/medtsim-provider-renderer.component';
import {MEDTSimRendererComponent} from './router/controls/medtsim-renderer/medtsim-renderer.component';
import {MEDTSimStatusDisplayComponent} from './router/controls/medtsim-status-display/medtsim-status-display.component';
import {
    MEDTSimStatusRendererComponent
} from './router/controls/medtsim-status-renderer/medtsim-status-renderer.component';
import {SimDisplayComponent} from './router/controls/sim-display/sim-display.component';
import {SimFieldComponent} from './router/controls/sim-field/sim-field.component';
import {SimPickerComponent} from './router/controls/sim-picker/sim-picker.component';
import {SimProviderDisplayComponent} from './router/controls/sim-provider-display/sim-provider-display.component';
import {SimProviderFieldComponent} from './router/controls/sim-provider-field/sim-provider-field.component';
import {SimProviderSelectorComponent} from './router/controls/sim-provider-selector/sim-provider-selector.component';
import {
    RouterRebootConfirmDialogComponent
} from './router/router-reboot-confirm-dialog/router-reboot-confirm-dialog.component';
import {RouterComponent} from './router/router.component';
import {RouterService} from './router/services/router.service';
import {SimEditorComponent} from './router/sim-editor/sim-editor.component';
import {TabDeviceTypesComponent} from './router/tab-device-types/tab-device-types.component';
import {TabDevicesComponent} from './router/tab-devices/tab-devices.component';
import {TabLegacyRouterStatusComponent} from './router/tab-legacy-router-status/tab-legacy-router-status.component';
import {TabSimsComponent} from './router/tab-sims/tab-sims.component';
import {
    MEDTSondeCellRendererComponent
} from './sonden/controls/medtsonde-cell-renderer/medtsonde-cell-renderer.component';
import {
    PeilungenFilterDialogComponent
} from './sonden/controls/peilungen-filter-dialog/peilungen-filter-dialog.component';
import {
    SondeDashboardDisplayComponent
} from './sonden/controls/sonde-dashboard-display/sonde-dashboard-display.component';
import {SondeDisplayComponent} from './sonden/controls/sonde-display/sonde-display.component';
import {DiagrammBackgroundComponent} from './sonden/peilungen-diagramm/diagramm-background.component';
import {DiagrammMarkerComponent} from './sonden/peilungen-diagramm/diagramm-marker.component';
import {PeilungenDiagrammComponent} from './sonden/peilungen-diagramm/peilungen-diagramm.component';
import {PeilungenPopupComponent} from './sonden/peilungen-popup/peilungen-popup.component';
import {SondeService} from './sonden/services/sonde.service';
import {SondeEditorComponent} from './sonden/sonde-editor/sonde-editor.component';
import {SondenComponent} from './sonden/sonden.component';
import {TabPeilungenComponent} from './sonden/tab-peilungen/tab-peilungen.component';
import {TabSondenComponent} from './sonden/tab-sonden/tab-sonden.component';
import {CheckDisplayComponent} from './sys-health/controls/check-display/check-display.component';
import {CheckNameDisplayComponent} from './sys-health/controls/check-name-display/check-name-display.component';
import {
    CheckServiceTypeDisplayComponent
} from './sys-health/controls/check-service-type-display/check-service-type-display.component';
import {CheckStatusDisplayComponent} from './sys-health/controls/check-status-display/check-status-display.component';
import {
    FileSystemSpaceDisplayComponent
} from './sys-health/controls/file-system-space-display/file-system-space-display.component';
import {MachineDisplayComponent} from './sys-health/controls/machine-display/machine-display.component';
import {
    MEDTCheckNameRendererComponent
} from './sys-health/controls/medtcheck-name-renderer/medtcheck-name-renderer.component';
import {
    MEDTCheckParamRendererComponent
} from './sys-health/controls/medtcheck-param-renderer/medtcheck-param-renderer.component';
import {MEDTCheckRendererComponent} from './sys-health/controls/medtcheck-renderer/medtcheck-renderer.component';
import {
    MEDTCheckServiceTypeRendererComponent
} from './sys-health/controls/medtcheck-service-type-renderer/medtcheck-service-type-renderer.component';
import {
    MEDTCheckStatusRendererComponent
} from './sys-health/controls/medtcheck-status-renderer/medtcheck-status-renderer.component';
import {
    MEDTFileSystemSpaceRendererComponent
} from './sys-health/controls/medtfile-system-space-renderer/medtfile-system-space-renderer.component';
import {MEDTMachineRendererComponent} from './sys-health/controls/medtmachine-renderer/medtmachine-renderer.component';
import {
    SysHealthBoxlisteTabComponent
} from './sys-health/controls/sys-health-boxliste-tab/sys-health-boxliste-tab.component';
import {
    SysHealthBoxlisteV4TabComponent
} from './sys-health/controls/sys-health-boxliste-v4-tab/sys-health-boxliste-v4-tab.component';
import {
    SysHealthChecksListComponent
} from './sys-health/controls/sys-health-checks-list/sys-health-checks-list.component';
import {
    SysHealthDashboardBodiesCountComponent
} from './sys-health/controls/sys-health-dashboard-bodies-count/sys-health-dashboard-bodies-count.component';
import {
    SysHealthDatabaseEditDialogComponent
} from './sys-health/controls/sys-health-database-edit-dialog/sys-health-database-edit-dialog.component';
import {
    SysHealthDockerEditDialogComponent
} from './sys-health/controls/sys-health-docker-edit-dialog/sys-health-docker-edit-dialog.component';
import {
    SysHealthHistoryDisplayComponent
} from './sys-health/controls/sys-health-history-display/sys-health-history-display.component';
import {
    SysHealthHistoryTabComponent
} from './sys-health/controls/sys-health-history-tab/sys-health-history-tab.component';
import {
    SysHealthLebenszeichenDisplayComponent
} from './sys-health/controls/sys-health-lebenszeichen-display/sys-health-lebenszeichen-display.component';
import {
    SysHealthLebenszeichenTabComponent
} from './sys-health/controls/sys-health-lebenszeichen-tab/sys-health-lebenszeichen-tab.component';
import {
    SysHealthLinuxMachineTabComponent
} from './sys-health/controls/sys-health-linux-machine-tab/sys-health-linux-machine-tab.component';
import {
    SysHealthProblemeTabComponent
} from './sys-health/controls/sys-health-probleme-tab/sys-health-probleme-tab.component';
import {
    SysHealthRouterStatusDisplayComponent
} from './sys-health/controls/sys-health-router-status-display/sys-health-router-status-display.component';
import {
    SysHealthRouterStatusTabComponent
} from './sys-health/controls/sys-health-router-status-tab/sys-health-router-status-tab.component';
import {
    SysHealthSystemDserviceEditDialogComponent
} from './sys-health/controls/sys-health-system-dservice-edit-dialog/sys-health-system-dservice-edit-dialog.component';
import {
    SysHealthTestPingCPUDisplayComponent
} from './sys-health/controls/sys-health-test-ping-cpudisplay/sys-health-test-ping-cpudisplay.component';
import {
    SysHealthTestPingCPURendererComponent
} from './sys-health/controls/sys-health-test-ping-cpurenderer/sys-health-test-ping-cpurenderer.component';
import {
    SysHealthTestPingDeviceDetailsPopupComponent
} from './sys-health/controls/sys-health-test-ping-device-details-popup/sys-health-test-ping-device-details-popup.component';
import {
    SysHealthTestPingDeviceDisplayComponent
} from './sys-health/controls/sys-health-test-ping-device-display/sys-health-test-ping-device-display.component';
import {
    SysHealthTestPingDeviceRendererComponent
} from './sys-health/controls/sys-health-test-ping-device-renderer/sys-health-test-ping-device-renderer.component';
import {
    SysHealthTestPingFileSystemDisplayComponent
} from './sys-health/controls/sys-health-test-ping-file-system-display/sys-health-test-ping-file-system-display.component';
import {
    SysHealthTestPingFileSystemRendererComponent
} from './sys-health/controls/sys-health-test-ping-file-system-renderer/sys-health-test-ping-file-system-renderer.component';
import {
    SysHealthTestPingMemoryDisplayComponent
} from './sys-health/controls/sys-health-test-ping-memory-display/sys-health-test-ping-memory-display.component';
import {
    SysHealthTestPingMemoryRendererComponent
} from './sys-health/controls/sys-health-test-ping-memory-renderer/sys-health-test-ping-memory-renderer.component';
import {
    SysHealthTestPingModemDisplayComponent
} from './sys-health/controls/sys-health-test-ping-modem-display/sys-health-test-ping-modem-display.component';
import {
    SysHealthTestPingModemRendererComponent
} from './sys-health/controls/sys-health-test-ping-modem-renderer/sys-health-test-ping-modem-renderer.component';
import {
    SysHealthTestPingSystemDisplayComponent
} from './sys-health/controls/sys-health-test-ping-system-display/sys-health-test-ping-system-display.component';
import {
    SysHealthTestPingSystemRendererComponent
} from './sys-health/controls/sys-health-test-ping-system-renderer/sys-health-test-ping-system-renderer.component';
import {
    SysHealthTestPingsDisplayComponent
} from './sys-health/controls/sys-health-test-pings-display/sys-health-test-pings-display.component';
import {
    SysHealthTestPingsTabComponent
} from './sys-health/controls/sys-health-test-pings-tab/sys-health-test-pings-tab.component';
import {
    SysHealthWindowsMachineTabComponent
} from './sys-health/controls/sys-health-windows-machine-tab/sys-health-windows-machine-tab.component';
import {
    SysHealthWindowsServiceEditDialogComponent
} from './sys-health/controls/sys-health-windows-service-edit-dialog/sys-health-windows-service-edit-dialog.component';
import {LinuxMachinesResolver} from './sys-health/services/linux-machines-resolver';
import {MachinesResolver} from './sys-health/services/machines-resolver';
import {SysHealthService} from './sys-health/services/sys-health.service';
import {WindowsMachinesResolver} from './sys-health/services/windows-machines-resolver';
import {SysHealthBoxlisteComponent} from './sys-health/sys-health-boxliste/sys-health-boxliste.component';
import {
    SysHealthLebenszeichenComponent
} from './sys-health/sys-health-lebenszeichen/sys-health-lebenszeichen.component';
import {SysHealthRouterStatusComponent} from './sys-health/sys-health-router-status/sys-health-router-status.component';
import {SysHealthTestPingsComponent} from './sys-health/sys-health-test-pings/sys-health-test-pings.component';
import {SysHealthComponent} from './sys-health/sys-health.component';
import {ContactEditorComponent} from './telefonanlage/contacts/contact-editor/contact-editor.component';
import {ContactSelectorComponent} from './telefonanlage/contacts/contact-selector/contact-selector.component';
import {ContactsComponent} from './telefonanlage/contacts/contacts.component';
import {TelefonanlageService} from './telefonanlage/services/telefonanlage.service';
import {TelefonanlageComponent} from './telefonanlage/telefonanlage.component';
import {VerbindungsuebersichtComponent} from './telefonanlage/verbindungsuebersicht/verbindungsuebersicht.component';
import {ShowReadablePipe} from './tools/show-readable.pipe';
import {SplitTwoPipe} from './tools/split-two.pipe';
import {SysHealthDHCPStatusComponent} from './sys-health/sys-health-dhcpstatus/sys-health-dhcpstatus.component';
import {TabDHCPLeasesComponent} from './sys-health/sys-health-dhcpstatus/tab-dhcpleases/tab-dhcpleases.component';
import {PasswordDialogComponent} from './benutzer/password-dialog/password-dialog.component';
import {ZSRPackagedDisplayComponent} from './mitschnitt/controls/zsrpackaged-display/zsrpackaged-display.component';
import {SchrankeComponent} from './telefonanlage/schranke/schranke.component';
import {LastCallsComponent} from './telefonanlage/last-calls/last-calls.component';
import {PreiseComponent} from './clearing/preise/preise.component';
import {
    TabPreisuebermittlungComponent
} from './clearing/preise/tab-preisuebermittlung/tab-preisuebermittlung.component';
import {TabMwstkorrekturComponent} from './clearing/preise/tab-mwstkorrektur/tab-mwstkorrektur.component';
import {NebenstelleRendererComponent} from './clearing/preise/nebenstelle-renderer/nebenstelle-renderer.component';
import {
    GesendetePreiseRendererComponent
} from './clearing/preise/gesendete-preise-renderer/gesendete-preise-renderer.component';
import {TransferRendererComponent} from './clearing/preise/transfer-renderer/transfer-renderer.component';
import {ER3PackagedDisplayComponent} from './mitschnitt/controls/er3-packaged-display/er3-packaged-display.component';
import {
    DresserPackagedDisplayComponent
} from './mitschnitt/controls/dresser-packaged-display/dresser-packaged-display.component';
import {WebfuelComponent} from './webfuel/webfuel.component';
import {VehicleTrackerComponent} from './webfuel/vehicle-tracker/vehicle-tracker.component';
import {TabVehicleTrackerComponent} from './webfuel/vehicle-tracker/tab-vehicle-tracker/tab-vehicle-tracker.component';
import {
    TabTrackerSMSCommandsComponent
} from './webfuel/vehicle-tracker/tab-tracker-smscommands/tab-tracker-smscommands.component';
import {TenantsComponent} from './webfuel/tenants/tenants.component';
import {TabTenantsComponent} from './webfuel/tenants/tab-tenants/tab-tenants.component';
import {MoveVehiclePopupComponent} from './webfuel/vehicle-tracker/move-vehicle-popup/move-vehicle-popup.component';
import {TenantSelectorComponent} from './webfuel/tenants/tenant-selector/tenant-selector.component';
import {
    VehicleEditorPopupComponent
} from './webfuel/vehicle-tracker/vehicle-editor-popup/vehicle-editor-popup.component';
import {VehiclesComponent} from './webfuel/vehicles/vehicles.component';
import {
    ModifyTrackerPopupComponent
} from './webfuel/vehicle-tracker/modify-tracker-popup/modify-tracker-popup.component';
import {RemoteBackupComponent} from './webfuel/remote-backup/remote-backup.component';
import {TabRBCustomersComponent} from './webfuel/remote-backup/tab-rbcustomers/tab-rbcustomers.component';
import {TabRBPlansComponent} from './webfuel/remote-backup/tab-rbplans/tab-rbplans.component';
import {TabRBTerminalsComponent} from './webfuel/remote-backup/tab-rbterminals/tab-rbterminals.component';
import {
    SysHealthDiskusageEditDialogComponent
} from './sys-health/controls/sys-health-diskusage-edit-dialog/sys-health-diskusage-edit-dialog.component';
import {SplitExtensionPipe} from './telefonanlage/split-extension.pipe';
import {IpBlocklisteComponent} from './meconnect/ip-blockliste/ip-blockliste.component';
import {NetzDessauComponent} from './meconnect/netz-dessau/netz-dessau.component';
import {NetzM2MComponent} from './meconnect/netz-m2m/netz-m2m.component';
import {IPAddressesComponent} from './meconnect/ipaddresses/ipaddresses.component';
import {Gateway1Component} from './meconnect/gateway1/gateway1.component';
import {BaustelleComponent} from './common-controls/baustelle/baustelle.component';
import {FaConfig, FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BlocklistEditorComponent} from './meconnect/ip-blockliste/blocklist-editor/blocklist-editor.component';
import {TNAAddressesComponent} from './meconnect/ipaddresses/tnaaddresses/tnaaddresses.component';
import {TNAFirewallsComponent} from './meconnect/ipaddresses/tnafirewalls/tnafirewalls.component';
import {TNAGroupsComponent} from './meconnect/ipaddresses/tnagroups/tnagroups.component';
import {TNANetworksComponent} from './meconnect/ipaddresses/tnanetworks/tnanetworks.component';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {IpAddressEditorComponent} from './meconnect/ipaddresses/ip-address-editor/ip-address-editor.component';
import {CheckPingEditorComponent} from './meconnect/ipaddresses/check-ping-editor/check-ping-editor.component';
import {FirewallEditorComponent} from './meconnect/ipaddresses/firewall-editor/firewall-editor.component';
import {FirewallSelectorComponent} from './meconnect/ipaddresses/firewall-selector/firewall-selector.component';
import {FirewallFieldComponent} from './meconnect/ipaddresses/firewall-field/firewall-field.component';
import {GruppeEditorComponent} from './meconnect/ipaddresses/gruppe-editor/gruppe-editor.component';
import {RemoveNetmaskPipe} from './meconnect/ipaddresses/remove-netmask.pipe';
import {NozzleReadersComponent} from './webfuel/nozzle-readers/nozzle-readers.component';
import {TabNozzleReadersComponent} from './webfuel/nozzle-readers/tab-nozzle-readers/tab-nozzle-readers.component';
import {VPN2Component} from "./meconnect/vpn2/vpn2.component";
import {VPN1Component} from "./meconnect/vpn1/vpn1.component";
import {TabVPN1Component} from "./meconnect/vpn1/tab-vpn1/tab-vpn1.component";
import {TabIPsecComponent} from "./meconnect/vpn1/tab-ipsec/tab-ipsec.component";
import {TabWireguardComponent} from "./meconnect/vpn1/tab-wireguard/tab-wireguard.component";
import {
    TabWireguardInterfaceComponent
} from "./meconnect/vpn1/tab-wireguard/tab-wireguard-interface/tab-wireguard-interface.component";
import {
    TabWireguardClientComponent
} from "./meconnect/vpn1/tab-wireguard/tab-wireguard-client/tab-wireguard-client.component";
import {
    WireguardClientEditorComponent
} from "./meconnect/vpn1/tab-wireguard/tab-wireguard-client/wireguard-client-editor/wireguard-client-editor.component";
import {
    WireguardInterfacePickerComponent
} from "./meconnect/vpn1/controls/wireguard-interface-picker/wireguard-interface-picker.component";
import {
    WireguardInterfaceFieldComponent
} from "./meconnect/vpn1/controls/wireguard-interface-field/wireguard-interface-field.component";
import {
    WireguardClientIPEditorComponent
} from "./meconnect/vpn1/tab-wireguard/tab-wireguard-client/wireguard-client-ip-editor/wireguard-client-ip-editor.component";
import {
    WireguardInterfaceEditorComponent
} from "./meconnect/vpn1/tab-wireguard/tab-wireguard-interface/wireguard-interface-editor/wireguard-interface-editor.component";
import {TerminalComponent} from "./webfuel/terminal/terminal.component";
import {
    TabTerminalMalfunctioningComponent
} from "./webfuel/terminal/tab-terminal-malfunctioning/tab-terminal-malfunctioning.component";
import {TerminalTypeSelectorComponent} from "./webfuel/terminal/terminal-type-selector/terminal-type-selector.component";
import {
    TabTerminalEditorComponent
} from "./webfuel/terminal/tab-terminal/tab-terminal-editor/tab-terminal-editor.component";
import {TabTerminalComponent} from "./webfuel/terminal/tab-terminal/tab-terminal.component";
import {
    InventoryTypeSelectorComponent
} from "./webfuel/terminal/inventory-type-selector/inventory-type-selector.component";
import {TabTAJobsComponent} from "./webfuel/terminal/tab-ta-jobs/tab-ta-jobs.component";
import {
    TabMissingTerminalTransactionsComponent
} from "./webfuel/terminal/tab-missing-terminal-transactions/tab-missing-terminal-transactions.component";
import {TabCoreModulesComponent} from "./webfuel/tenants/tab-core-modules/tab-core-modules.component";
import {
    TabCoreModulesEditorComponent
} from "./webfuel/tenants/tab-core-modules/tab-core-modules-editor/tab-core-modules-editor.component";
import {TabTenantHostsComponent} from "./webfuel/tenants/tab-tenant-hosts/tab-tenant-hosts.component";
import {
    TabTenantHostEditorComponent
} from "./webfuel/tenants/tab-tenant-hosts/tab-tenant-hosts-editor/tab-tenant-host-editor.component";
import {TabTenantsEditorComponent} from "./webfuel/tenants/tab-tenants/tab-tenants-editor/tab-tenants-editor.component";
import {
    TabTenantsEditorInfosComponent
} from "./webfuel/tenants/tab-tenants/tab-tenants-editor/tab-tenants-editor-infos/tab-tenants-editor-infos.component";
import {
    TabTenantsEditorModulesComponent
} from "./webfuel/tenants/tab-tenants/tab-tenants-editor/tab-tenants-editor-modules/tab-tenants-editor-modules.component";
import {
    TabTenantsEditorSettingsComponent
} from "./webfuel/tenants/tab-tenants/tab-tenants-editor/tab-tenants-editor-settings/tab-tenants-editor-settings.component";
import { DokulinkComponent } from './tools/dokulink/dokulink.component';
import { TrackerMitschnittPopupComponent } from './webfuel/vehicle-tracker/tracker-mitschnitt-popup/tracker-mitschnitt-popup.component';
import { TrackerMitschnittEntryDisplayComponent } from './webfuel/vehicle-tracker/tracker-mitschnitt-popup/tracker-mitschnitt-entry-display/tracker-mitschnitt-entry-display.component';
import {
    TabPositionNeedsProcessRnComponent
} from "./webfuel/terminal/tab-position-needs-process-rn/tab-position-needs-process-rn.component";
import {
    TabTransaktionNeedRecalcRnComponent
} from "./webfuel/terminal/tab-transaktion-need-recalc-rn/tab-transaktion-need-recalc-rn.component";
import {TabApplicationsComponent} from "./webfuel/applications/tab-applications/tab-applications.component";
import {
    TabApplicationsEditorComponent
} from "./webfuel/applications/tab-applications/tab-applications-editor/tab-applications-editor.component";
import {
    TabApplicationsEditorInfosComponent
} from "./webfuel/applications/tab-applications/tab-applications-editor/tab-applications-editor-infos/tab-applications-editor-infos.component";
import {
    TabApplicationsEditorIPAddressesComponent
} from "./webfuel/applications/tab-applications/tab-applications-editor/tab-applications-editor-ip-addresses/tab-applications-editor-ip-addresses.component";
import {ApplicationsComponent} from "./webfuel/applications/applications.component";
import {ImportsComponent} from "./webfuel/imports/imports.component";
import {TabImportsJobsComponent} from "./webfuel/imports/tab-imports-jobs/tab-imports-jobs.component";

const components = [
    AppComponent,
    NavComponent,
    IndexComponent,
    SidebarComponent,
    SidebarTogglerComponent,
    RouterComponent,
    MitschnittComponent,
    TabDevicesComponent,
    MEDTDeviceTypeRendererComponent,
    DeviceDisplayComponent,
    DeviceTypeDisplayComponent,
    SimDisplayComponent,
    MEDTSimRendererComponent,
    TabMitschnittDevicesComponent,
    MEDTDeviceRendererComponent,
    TabMitschnittStreamsComponent,
    MitschnittgeraetDisplayComponent,
    MEDTMitschnittgeraetRendererComponent,
    MitschnittStreamDetailsPopupComponent,
    MEDTMitschnittstreamPaketDatenRendererComponent,
    TabSimsComponent,
    SimProviderDisplayComponent,
    MEDTSimProviderRendererComponent,
    MitschnittstreamFilterDialogComponent,
    MedtcustomRenderer1Component,
    MitschnittgeraetPickerComponent,
    DresserDisplayComponent,
    LoginComponent,
    TabDeviceTypesComponent,
    MEDTDeviceOnlineRendererComponent,
    DeviceOnlineDisplayComponent,
    DeviceDiskUsageDisplayComponent,
    MEDTDeviceDiskUsageDisplayRendererComponent,
    MEDTDeviceSysStatusRendererComponent,
    SondenComponent,
    TabSondenComponent,
    TabPeilungenComponent,
    MEDTSondeCellRendererComponent,
    SondeDisplayComponent,
    TabLegacyRouterStatusComponent,
    SysHealthComponent,
    CheckStatusDisplayComponent,
    MEDTCheckStatusRendererComponent,
    AbrechnungComponent,
    TabAbrechnungDtsComponent,
    AbrechnungDtsDetailsComponent,
    AbrechnungDtsPrintPreviewComponent,
    SysHealthChecksListComponent,
    SysHealthWindowsMachineTabComponent,
    SysHealthWindowsServiceEditDialogComponent,
    SysHealthLinuxMachineTabComponent,
    SysHealthSystemDserviceEditDialogComponent,
    SysHealthProblemeTabComponent,
    MEDTMachineRendererComponent,
    MachineDisplayComponent,
    UserNameDisplayComponent,
    SysHealthDockerEditDialogComponent,
    MEDTCheckParamRendererComponent,
    MEDTCheckServiceTypeRendererComponent,
    CheckServiceTypeDisplayComponent,
    SysHealthHistoryTabComponent,
    SysHealthHistoryDisplayComponent,
    MEDTCheckRendererComponent,
    CheckDisplayComponent,
    MEDTCheckNameRendererComponent,
    CheckNameDisplayComponent,
    TabAbrechnungGreenlineComponent,
    AbrechnungGreenlineDetailsComponent,
    AbrechnungGreenlinePrintPreviewComponent,
    SysHealthDatabaseEditDialogComponent,
    SysHealthLebenszeichenTabComponent,
    SysHealthLebenszeichenDisplayComponent,
    SysHealthDashboardBodiesCountComponent,
    DashboardInfoBlockComponent,
    SondeDashboardDisplayComponent,
    BenutzerComponent,
    TabBenutzerComponent,
    PeilungenPopupComponent,
    PeilungenDiagrammComponent,
    DiagrammBackgroundComponent,
    DiagrammMarkerComponent,
    PeilungenFilterDialogComponent,
    SondeEditorComponent,
    DeviceFieldComponent,
    DeviceSelectorComponent,
    DeviceSelectorWithLabelComponent,
    RemoteLogComponent,
    TabNozzleReaderLogsComponent,
    NozzleReaderLogFilterPopupComponent,
    RouterRebootConfirmDialogComponent,
    SimEditorComponent,
    SimProviderSelectorComponent,
    SimProviderFieldComponent,
    RemoteLogParamsRendererComponent,
    RemoteLogTimeRendererComponent,
    SideBarLinkComponent,
    SideBarGroupComponent,
    SysHealthLebenszeichenComponent,
    TabAbrechnungTKTankstellenComponent,
    AbrechnungTKTankstellenDetailsComponent,
    AbrechnungTKTankstellenPrintPreviewComponent,
    TabAbrechnungRolfesComponent,
    AbrechnungRolfesDetailsComponent,
    AbrechnungRolfesPrintPreviewComponent,
    TabAbrechnungPickelmannComponent,
    AbrechnungPickelmannDetailsComponent,
    AbrechnungPickelmannPrintPreviewComponent,
    TabAbrechnungVaroComponent,
    AbrechnungVaroDetailsComponent,
    AbrechnungVaroPrintPreviewComponent,
    TabAbrechnungHoyerComponent,
    AbrechnungHoyerDetailsComponent,
    AbrechnungHoyerPrintPreviewComponent,
    AbrechnungVaroRechnungsserverDetailsComponent,
    AbrechnungVaroRechnungsserverPrintPreviewComponent,
    TabAbrechnungVaroRechnungsserverComponent,
    TabAbrechnungGlukoseportalComponent,
    AbrechnungGlukoseportalDetailsComponent,
    AbrechnungGlukoseportalPrintPreviewComponent,
    SysHealthTestPingsComponent,
    SysHealthTestPingsTabComponent,
    SysHealthTestPingsDisplayComponent,
    MEDTFileSystemSpaceRendererComponent,
    FileSystemSpaceDisplayComponent,
    SysHealthTestPingDeviceDisplayComponent,
    SysHealthTestPingDeviceRendererComponent,
    SysHealthTestPingSystemDisplayComponent,
    SysHealthTestPingSystemRendererComponent,
    SysHealthTestPingCPUDisplayComponent,
    SysHealthTestPingCPURendererComponent,
    SysHealthTestPingFileSystemDisplayComponent,
    SysHealthTestPingFileSystemRendererComponent,
    SysHealthTestPingModemRendererComponent,
    SysHealthTestPingModemDisplayComponent,
    SysHealthTestPingMemoryDisplayComponent,
    SysHealthTestPingMemoryRendererComponent,
    SysHealthTestPingDeviceDetailsPopupComponent,
    SysHealthRouterStatusComponent,
    SysHealthRouterStatusTabComponent,
    SysHealthRouterStatusDisplayComponent,
    MEDTSimStatusRendererComponent,
    MEDTSimStatusDisplayComponent,
    TelefonanlageComponent,
    ContactsComponent,
    VerbindungsuebersichtComponent,
    ContactSelectorComponent,
    ContactEditorComponent,
    ClearingComponent,
    SysHealthBoxlisteComponent,
    SysHealthBoxlisteTabComponent,
    SysHealthBoxlisteV4TabComponent,
    GenericDisplayComponent,
    ZSRDisplayComponent,
    MEConnectComponent,
    CSDServerComponent,
    TabCSDClientsComponent,
    TabCSDBridgesComponent,
    OpenVPNTelefonanlageComponent,
    OpenVPNLinuxserverComponent,
    ClientEditorComponent,
    CSDServerClientTypePickerComponent,
    CSDServerClientTypeFieldComponent,
    BridgeEditorComponent,
    MitschnittDeviceEditorComponent,
    UserEditorComponent,
    CSDServerClientPickerComponent,
    CSDServerClientFieldComponent,
    SimPickerComponent,
    SimFieldComponent,
    ATCommandToolComponent,
    DokumentationComponent,
    WireguardInterfacePickerComponent,
    WireguardInterfaceFieldComponent,
    WireguardClientIPEditorComponent,
    WireguardInterfaceEditorComponent,
    TerminalComponent,
    TabTerminalMalfunctioningComponent,
    TerminalTypeSelectorComponent,
    TabTerminalEditorComponent,
    TabTerminalComponent,
    InventoryTypeSelectorComponent,
    TabTAJobsComponent,
    TabMissingTerminalTransactionsComponent,
    TabCoreModulesComponent,
    TabCoreModulesEditorComponent,
    TabTenantHostsComponent,
    TabTenantHostEditorComponent,
    TabTenantsEditorComponent,
    TabTenantsEditorInfosComponent,
    TabTenantsEditorModulesComponent,
    TabTenantsEditorSettingsComponent,
    TabCoreModulesComponent,
    TabCoreModulesEditorComponent,
    TabTenantHostsComponent,
    TabTenantHostEditorComponent,
    TabTenantsEditorComponent,
    TabTenantsEditorInfosComponent,
    TabTenantsEditorModulesComponent,
    TabTenantsEditorSettingsComponent,
    TabPositionNeedsProcessRnComponent,
    TabTransaktionNeedRecalcRnComponent,
    ApplicationsComponent,
    TabApplicationsComponent,
    TabApplicationsEditorComponent,
    TabApplicationsEditorInfosComponent,
    TabApplicationsEditorIPAddressesComponent,
    ImportsComponent,
    TabImportsJobsComponent,
];
const other = [IsLoggedInDirective, SplitTwoPipe, ShowReadablePipe];

@NgModule({
    declarations: [
        ...components,
        ...other,
        SysHealthDHCPStatusComponent,
        TabDHCPLeasesComponent,
        PasswordDialogComponent,
        ZSRPackagedDisplayComponent,
        SchrankeComponent,
        LastCallsComponent,
        PreiseComponent,
        TabPreisuebermittlungComponent,
        TabMwstkorrekturComponent,
        NebenstelleRendererComponent,
        GesendetePreiseRendererComponent,
        TransferRendererComponent,
        ER3PackagedDisplayComponent,
        DresserPackagedDisplayComponent,
        WebfuelComponent,
        VehicleTrackerComponent,
        TabVehicleTrackerComponent,
        TabTrackerSMSCommandsComponent,
        TenantsComponent,
        TabTenantsComponent,
        MoveVehiclePopupComponent,
        TenantSelectorComponent,
        VehicleEditorPopupComponent,
        VehiclesComponent,
        ModifyTrackerPopupComponent,
        RemoteBackupComponent,
        TabRBCustomersComponent,
        TabRBPlansComponent,
        TabRBTerminalsComponent,
        SysHealthDiskusageEditDialogComponent,
        SplitExtensionPipe,
        IpBlocklisteComponent,
        NetzDessauComponent,
        NetzM2MComponent,
        IPAddressesComponent,
        VPN1Component,
        TabVPN1Component,
        TabIPsecComponent,
        TabWireguardComponent,
        TabWireguardInterfaceComponent,
        TabWireguardClientComponent,
        WireguardClientEditorComponent,
        VPN2Component,
        Gateway1Component,
        BaustelleComponent,
        BlocklistEditorComponent,
        TNANetworksComponent,
        TNAAddressesComponent,
        TNAGroupsComponent,
        TNAFirewallsComponent,
        IpAddressEditorComponent,
        CheckPingEditorComponent,
        FirewallEditorComponent,
        FirewallSelectorComponent,
        FirewallFieldComponent,
        GruppeEditorComponent,
        RemoveNetmaskPipe,
        NozzleReadersComponent,
        TabNozzleReadersComponent,
        DokulinkComponent,
        TrackerMitschnittPopupComponent,
        TrackerMitschnittEntryDisplayComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        NgbModule,

        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            closeButton: true
        } as GlobalConfig),

        MaennlCommonsModule.forRoot(HttpClientInterceptorConfig),
        L10nTranslationModule.forRoot(l10nConfig, {
            translationLoader: HttpTranslationLoader
        }),
        L10nIntlModule,
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: environment.production
        }),
        MaennlCommonsModule,
        MarkdownModule.forRoot(),
        LMarkdownEditorModule,

        FontAwesomeModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: MEAppInjector.initInjector,
            deps: [Injector],
            multi: true
        },
        {
            provide: ErrorHandler,
            useClass: MEErrorHandler
        },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        RouterService,
        MitschnittService,
        ME_HTTP_CLIENT_INTERCEPTOR,
        BenutzerService,
        LoggedInGuard,
        SondeService,
        SysHealthService,
        AbrechnungDtsService,
        SysInfoService,
        WindowsMachinesResolver,
        LinuxMachinesResolver,
        MachinesResolver,
        AbrechnungService,
        NozzleReaderRemoteLogService,
        AbrechnungTKTankstellenService,
        AbrechnungRolfesService,
        AbrechnungPickelmannService,
        AbrechnungVaroService,
        AbrechnungHoyerService,
        AbrechnungVaroRechnungsserverService,
        AbrechnungGlukoseportalService,
        TelefonanlageService,
        CSDServerService,
        {
            provide: APP_INITIALIZER,
            useFactory: initL10n,
            deps: [L10nLoader],
            multi: true
        },
        DokumentationService
    ],
    bootstrap: [AppComponent],
    exports: [...components]
})
export class AppModule {
    constructor(
        pgConfig: NgbPaginationConfig,
        library: FaIconLibrary,
        faConfig: FaConfig
    ) {
        library.addIconPacks(fas, far, fal);
        faConfig.fixedWidth = true;

        moment.locale('de');
        pgConfig.size = 'sm';
        pgConfig.boundaryLinks = true;
        pgConfig.ellipses = true;
        pgConfig.maxSize = 5;
    }
}
