import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  MEGenericModalComponent,
  MEPubSubService,
  MERichError,
  MESubscriptionHelper,
} from '../../../maennl-commons';
import { TrackerMitschnittPopupOptions } from './tracker-mitschnitt-popup-options';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TrackerMitschnittService } from '../commons/tracker-mitschnitt.service';
import { TrackerMitschnittList } from '../commons/tracker-mitschnitt.list';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-tracker-mitschnitt-popup',
  templateUrl: './tracker-mitschnitt-popup.component.html',
  styleUrls: ['./tracker-mitschnitt-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerMitschnittPopupComponent
  extends MEGenericModalComponent<TrackerMitschnittPopupOptions>
  implements OnInit, OnDestroy
{
  entity: TrackerMitschnittPopupOptions = null;
  public packets: TrackerMitschnittList = new TrackerMitschnittList();

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public cd: ChangeDetectorRef,
    public toastr: ToastrService,
    public trackerMitschnittService: TrackerMitschnittService,
    public benutzerService: BenutzerService
  ) {
    super(activeModal, pubsub);
    this.packets.size = 10;
  }

  public initEntity(
    src: TrackerMitschnittPopupOptions
  ): TrackerMitschnittPopupOptions {
    const f = new TrackerMitschnittPopupOptions();
    if (src !== null && src !== undefined) {
      f.copyFrom(src);
    }
    return f;
  }

  @ViewChild('tableContainer', { static: false }) tableContainer: ElementRef =
    null;

  override ngOnInit() {
    super.ngOnInit();
    this.entity.cd = this.cd;

    MESubscriptionHelper.add(
      this,
      this.packets.onUpdateRequired.subscribe((list: TrackerMitschnittList) => {
        this.trackerMitschnittService
          .list(
            this.entity.tracker.id,
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter
          )
          .subscribe({
            next: l => {
              list.populateFromListResult(l);
              if (
                this.tableContainer !== null &&
                this.tableContainer !== undefined
              ) {
                this.tableContainer.nativeElement.scrollTop = 0;
              }
              this.cd.markForCheck();
            },
            error: () => {
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste der verfügbaren Mitschnittpakete konnte nicht geladen werden'
              );
            },
          });
      }, undefined)
    );
  }

  override ngOnDestroy() {
    this.packets.release();
    MESubscriptionHelper.release(this);
    super.ngOnDestroy();
  }
}
