import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IContact } from './icontact';
import { IRufnummer } from './irufnummer';
import { Rufnummer } from './rufnummer';

export class Contact extends MEGenericEntity<IContact> implements IContact {
  public displayText: string = '';
  public firma: string = '';
  public id: number = null;
  public kundennummer: string = '';
  public nachname: string = '';
  public rufnummern: Rufnummer[] = [];
  public vorname: string = '';
  public isIntern = false;
  public sortRang = 100;

  static fromResult(entity: IContact, createIfNull: boolean = false): Contact {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Contact();
      }
      return null;
    }

    const instance = new Contact();
    return instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('displayText')
        .addTextField('firma')
        .addTextField('kundennummer')
        .addTextField('nachname')
        .addTextField('vorname')
        .addBooleanField('isIntern')
        .addLongField('sortRang')
        .addConverterField(
          'rufnummern',
          new MEGenericEntityConverterParams<Rufnummer[]>(
            (value: IRufnummer[]) => {
              if (value === null || value === undefined) {
                return null;
              }
              let n: Rufnummer[] = [];
              if (Array.isArray(value)) {
                value.forEach((ir: IRufnummer) => {
                  n.push(Rufnummer.fromResult(ir));
                });
              }
              return n;
            }
          )
        )
    );
  }

  constructor() {
    super();
  }
}
