<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'credit-card-front']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Clearing"
></me-area-heading>

<me-card>
    <button routerLink="/clearing/preise" class="btn btn-sm btn-primary">Preise</button>
</me-card>
