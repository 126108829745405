<div class="modal-header">
    <h4 class="modal-title">Schranke</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="text-center" (click)="updateImg()">
        <img [src]="imgurl" *ngIf="isValidImgUrl()" width="700" alt="Kamerabild"/>
    </div>
</div>
<div class="modal-footer d-flex justify-content-between">
    <div>
        <button type="button" class="btn btn-primary" (click)="openGate()">
            <i class="fa far fa-door-open"></i> Schranke öffnen
        </button>
        <button type="button" class="btn btn-secondary" (click)="ringOff()">
            <i class="fa far fa-phone-slash"></i> Ruf beenden
        </button>
    </div>
    <div>
        <button type="button" class="btn btn-secondary" (click)="cancel()">
            <i class="icofont icofont-close"></i> Schließen
        </button>
    </div>
</div>
