import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { MEConverterTool } from '../../maennl-commons/tools';
import { INozzleReaderLogEntry } from './inozzle-reader-log-entry';
import { NozzleReaderLogEntry } from './nozzle-reader-log-entry';
import { SimpleNozzleReaderLogEntryFilter } from './simple-nozzle-reader-log-entry-filter';

export class NozzleReaderLogEntryList extends MERemoteList<NozzleReaderLogEntryList> {
  __classname = 'CheckList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<INozzleReaderLogEntry>
  ): NozzleReaderLogEntryList {
    const newList = new NozzleReaderLogEntryList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleNozzleReaderLogEntryFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'zeitpunkt',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return NozzleReaderLogEntry.fromResult(entity);
  }

  doConvertFilterField(k: string, input: any): any {
    if (k === 'zeitpunkt' || k === 'received') {
      if (Array.isArray(input)) {
        const r: Date[] = [];
        (input as Array<any>).forEach((item) => {
          if (item === null || item === undefined) {
            r.push(null);
          } else {
            r.push(MEConverterTool.toDate(item));
          }
        });
        return r;
      }
      return null;
    }
    return super.doConvertFilterField(k, input);
  }
}
