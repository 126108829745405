import {
    AfterViewInit,
    Component, Input,
    OnInit, ViewChild,
} from "@angular/core";
import { METabCardComponent, METool} from "../../../maennl-commons";
import {TabWireguardClientComponent} from "./tab-wireguard-client/tab-wireguard-client.component";
import {TabWireguardInterfaceComponent} from "./tab-wireguard-interface/tab-wireguard-interface.component";

type TTabType =
    | null
    | TabWireguardClientComponent
    | TabWireguardInterfaceComponent;

@Component({
    selector: 'app-tab-wireguard',
    templateUrl: './tab-wireguard.component.html',
    styleUrls: ['./tab-wireguard.component.scss'],
})
export class TabWireguardComponent
    implements OnInit, AfterViewInit {
    @ViewChild(METabCardComponent, {static: false}) tabs: METabCardComponent;
    @ViewChild('wireguardInterfaceTab', {static: false}) ka: TabWireguardInterfaceComponent;

    private _currentTab: TTabType = null;
    private _tabActive = false;

    public get tabActive(): boolean {
        return this._tabActive;
    }

    @Input()
    public set tabActive(value: boolean) {
        if (this._tabActive !== value) {
            this._tabActive = value;
            if (this._tabActive) {
                //nothing here yet
            }
        }
    }

    public get currentTab(): TTabType {
        return this._currentTab
    }

    public set currentTab(value:TTabType){
        if(this._currentTab!==value){
            if(!METool.isNullOrUndefined(this._currentTab)){
                this._currentTab.onDeactivate();
            }
            this._currentTab=value;
            if(!METool.isNullOrUndefined(this._currentTab)){
                this._currentTab.onActivate();
            }
        }
    }


    constructor() {}

    ngOnInit(): void {}

    public ngAfterViewInit() {
        this.currentTab=this.ka;
    }

    onActivate(){
        this.tabActive=true;
    }

    onDeactivate(){
        this.tabActive=false;
    }

    activateTab(s:string){
        const found =false;
        if (!found) {
            console.log(s);
        }
    }

    deactivateTab(s:string){
        const found =false;
        if (!found) {
            console.log(s);
        }
    }

    public activate(tab:TTabType){
        console.log(tab);
        this.currentTab=tab;
    }

}