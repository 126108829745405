import { MERemoteList } from '../../maennl-commons/mecommon-list';
import { IMEListResult } from '../../maennl-commons/mecommon-list';
import { ITestPing } from './itest-ping';
import { SimpleTestPingFilter } from './simple-test-ping-filter';
import { TestPing } from './test-ping';

export class TestPingList extends MERemoteList<TestPing> {
  __classname = 'TestPingList';
  __instance = '';

  static fromListResult(res: IMEListResult<ITestPing>): TestPingList {
    const newList = new TestPingList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleTestPingFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'zeitpunkt',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return TestPing.fromResult(entity);
  }
}
