import { Component, Injector, OnInit } from '@angular/core';
import {
  MEConverterTool,
  MEDataTableCellRendererComponent,
  MEDataTableColumnComponent,
  MEDataTableLocalizedCellRenderer,
  MEDataTableRow,
  METool
} from '../../../maennl-commons';
import { IPreisSendung } from '../commons/ipreis-sendung';

interface IPreisInfo {
  produkt: string;
  preis: number;
  pos: number;
}

@Component({
  selector: 'app-gesendete-preise-renderer',
  templateUrl: './gesendete-preise-renderer.component.html',
  styleUrls: ['./gesendete-preise-renderer.component.scss']
})
export class GesendetePreiseRendererComponent extends MEDataTableLocalizedCellRenderer {
  public cell: any = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<IPreisSendung> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }

  get preise(): IPreisInfo[] {
    const r: IPreisInfo[] = [];

    if (
      !METool.isNullOrUndefined(this.row) &&
      !METool.isNullOrUndefined(this.row.data)
    ) {
      for (let i = 1; i < 12; i++) {
        const k = MEConverterTool.right_string('00' + i, 2);
        if (this.row.data['produkt_pos_' + k] > 0) {
          r.push({
            pos: this.row.data['produkt_pos_' + k],
            produkt: METool.isEmpty(this.row.data['produkt_name_' + k])
              ? 'keine Sorte!'
              : (this.row.data['produkt_name_' + k] + '').trim(),
            preis: this.row.data['produkt_preis_' + k] / 1000
          });
        }
      }
    }

    return r;
  }
}
