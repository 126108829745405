import { Component, Input, OnInit } from '@angular/core';
import { SimProvider } from '../../common/sim-provider';

@Component({
  selector: 'app-sim-provider-display',
  template: `
    <div *ngIf="isValid()" title="Netzwerk: {{ value.network }}">
      {{ value.name }}
    </div>
  `,
  styles: [
    `
      :host {
        display: inline-block;
      }
    `
  ]
})
export class SimProviderDisplayComponent implements OnInit {
  @Input() public value: SimProvider = null;

  constructor() {}

  ngOnInit() {}

  isValid() {
    return this.value !== null && this.value !== undefined;
  }
}
