import { Component, Input } from '@angular/core';
import { IDevice } from '../../common/idevice';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';
import { RouterService } from '../../services/router.service';

@Component({
  selector: 'app-device-online-display',
  template: `
    <div
      *ngIf="routerService.isMEConnectRouter(device)"
      title="letztes Lebenszeichen {{
        fDate(device.lastSeen, 'DD.MM.YYYY HH:mm:ss', true)
      }}"
    >
      <i
        class="icofont icofont-wifi-router"
        [class.text-success]="device.online"
        [class.text-danger]="!device.online"
      ></i>
      <span *ngIf="!device.online"
        >Tunnel offline<br />
        <small
          >seit {{ fDate(device.lastSeen, 'DD.MM.YYYY HH:mm:ss', true) }}</small
        >
      </span>
      <span *ngIf="device.online"
        >Tunnel online<br />
        <small *ngIf="device.remoteAccessPort > 0">
          Port: {{ device.remoteAccessPort }}</small
        >
      </span>
    </div>
    <div
      *ngIf="routerService.isMEConnectRouterV2(device)"
      title="letztes Lebenszeichen {{
        fDate(device.lastSeen, 'DD.MM.YYYY HH:mm:ss', true)
      }}"
    >
      <i
        class="icofont icofont-wifi-router"
        [class.text-success]="device.online"
        [class.text-danger]="!device.online"
      ></i>
      <span *ngIf="!device.online"
        >Tunnel offline<br />
        <small
          >seit {{ fDate(device.lastSeen, 'DD.MM.YYYY HH:mm:ss', true) }}</small
        >
      </span>
      <span *ngIf="device.online"
        >Tunnel online<br />
        <small *ngIf="device.remoteAccessPort > 0">
          Port: {{ device.remoteAccessPort }}</small
        >
      </span>
    </div>
    <div
      *ngIf="
        !routerService.isMEConnectRouterV2(device) &&
        !routerService.isMEConnectRouter(device) &&
        device.lastSeen !== null &&
        device.lastSeen !== undefined
      "
      title="letztes Lebenszeichen {{
        fDate(device.lastSeen, 'DD.MM.YYYY HH:mm:ss', true)
      }}"
    >
      zuletzt: {{ fDate(device.lastSeen, 'DD.MM.YYYY HH:mm:ss', true) }}
    </div>
  `
})
export class DeviceOnlineDisplayComponent extends MELocalizedComponent {
  @Input() device: IDevice = null;

  constructor(public routerService: RouterService) {
    super();
  }
}
