import { IMEListResult, MERemoteList } from '../../../maennl-commons';
import { ITenant } from './itenant';
import { SimpleTenantFilter } from './simple-tenant-filter';
import { Tenant } from './tenant';

export class TenantList extends MERemoteList<Tenant> {
  __classname = 'TenantList';
  __instance = '';

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleTenantFilter();
    this.initList(autorun, timeout);
  }

  static fromListResult(res: IMEListResult<ITenant>): TenantList {
    const newList = new TenantList();
    newList.populateFromListResult(res);
    return newList;
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'info',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return Tenant.fromResult(entity);
  }
}
