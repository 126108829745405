import { MEConverterTool } from '../../maennl-commons';
import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { CDRCall } from './cdrcall';
import { Contact } from './contact';
import { ICDRCall } from './icdrcall';
import { SimpleCDRCallFilter } from './simple-cdrcall-filter';

export class CDRCallList extends MERemoteList<CDRCall> {
  __classname = 'CDRCallList';
  __instance = '';

  static fromListResult(res: IMEListResult<ICDRCall>): CDRCallList {
    const newList = new CDRCallList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleCDRCallFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'callDate',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return CDRCall.fromResult(entity);
  }

  public doConvertFilterField(k: string, input: any): any {
    if (k === 'intern_contact') {
      return Contact.fromResult(input);
    }
    if (k === 'date_from') {
      return MEConverterTool.toDate(input);
    }
    if (k === 'date_until') {
      return MEConverterTool.toDate(input);
    }
    return super.doConvertFilterField(k, input);
  }
}
