import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {METabCardComponent, METool} from "../../maennl-commons";
import {TabTerminalMalfunctioningComponent} from "./tab-terminal-malfunctioning/tab-terminal-malfunctioning.component";
import {TabTerminalComponent} from "./tab-terminal/tab-terminal.component";
import {TabTAJobsComponent} from "./tab-ta-jobs/tab-ta-jobs.component";
import {
    TabMissingTerminalTransactionsComponent
} from "./tab-missing-terminal-transactions/tab-missing-terminal-transactions.component";
import {
    TabPositionNeedsProcessRnComponent
} from "./tab-position-needs-process-rn/tab-position-needs-process-rn.component";
import {
    TabTransaktionNeedRecalcRnComponent
} from "./tab-transaktion-need-recalc-rn/tab-transaktion-need-recalc-rn.component";

type TTabType =
    |null
    | TabTerminalMalfunctioningComponent
    | TabTerminalComponent
    | TabTAJobsComponent
    | TabMissingTerminalTransactionsComponent
    | TabPositionNeedsProcessRnComponent
    | TabTransaktionNeedRecalcRnComponent;
@Component({
    selector: 'app-terminal',
    templateUrl: './terminal.component.html',
    styleUrls: ['./terminal.component.scss']
})
export class TerminalComponent implements OnInit, AfterViewInit {
    @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;
    @ViewChild('terminalTab', { static: false }) ka: TabTerminalComponent;

    private _currentTab: TTabType = null;

    public get currentTab(): TTabType {
        return this._currentTab
    }

    public set currentTab(value: TTabType) {
        if (this._currentTab !== value) {
            if (!METool.isNullOrUndefined(this._currentTab)) {
                this._currentTab.onDeactivate();
            }
            this._currentTab = value;
            if (!METool.isNullOrUndefined(this._currentTab)) {
                this._currentTab.onActivate();
            }
        }
    }

    ngAfterViewInit() {
        this.currentTab = this.ka;
    }

    constructor() {
    }

    ngOnInit() {
    }

    activateTab(s:string) {
        const found = false;

        if (!found) {
            console.log(s);
        }
    }

    deactivateTab(s:string) {
        const found = false;

        if (!found) {
            console.log(s);
        }
    }

    public activate(tab: TTabType) {
        console.log(tab);
        this.currentTab = tab;
    }
}