import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { SysHealthTestPingsTabComponent } from '../controls/sys-health-test-pings-tab/sys-health-test-pings-tab.component';

@Component({
  selector: 'app-sys-health-test-pings',
  templateUrl: './sys-health-test-pings.component.html',
  styleUrls: ['./sys-health-test-pings.component.scss']
})
export class SysHealthTestPingsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(SysHealthTestPingsTabComponent, { static: false })
  testpingsTab: SysHealthTestPingsTabComponent;

  constructor() {}

  ngOnInit() {
    if (this.testpingsTab !== null && this.testpingsTab !== undefined) {
      this.testpingsTab.onActivate();
    }
  }

  ngOnDestroy(): void {
    if (this.testpingsTab !== null && this.testpingsTab !== undefined) {
      this.testpingsTab.onDeactivate();
    }
  }

  ngAfterViewInit(): void {
    if (this.testpingsTab !== null && this.testpingsTab !== undefined) {
      this.testpingsTab.onActivate();
    }
  }
}
