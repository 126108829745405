import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MEResultMetaSort } from './result-meta-sort';

@Component({
  selector: 'me-sort-buttons',
  styles: [
    `
      :host {
        font-size: 0.8em;
      }

      i {
        cursor: pointer;
      }

      i.marked {
        color: inherit;
      }

      i.unmarked {
        color: #eeeeee;
      }

      i:hover {
        color: #5bc0de;
      }
    `
  ],
  template: `
    <i
      class="icofont icofont-square-up"
      [class.unmarked]="direction !== 'asc'"
      [class.marked]="direction === 'asc'"
      (click)="up($event)"
    ></i>
    <i
      class="icofont icofont-square-down"
      [class.unmarked]="direction !== 'desc'"
      [class.marked]="direction === 'desc'"
      (click)="down($event)"
    ></i>
  `
})
export class MESortButtonsComponent {
  @Input() public field = '';
  @Input() public idx = 0;

  @Input()
  set orders(o: MEResultMetaSort[]) {
    this.direction = '';
    o.forEach((it: MEResultMetaSort, index) => {
      if (it.field === this.field) {
        this.idx = index;
        this.direction = it.direction;
      }
    });
  }

  public direction = '';
  @Output() public onClick = new EventEmitter<MEResultMetaSort>();

  constructor() {
    this.direction = '';
  }

  public up(event: MouseEvent) {
    this.direction = 'asc';
    this.onClick.emit(
      new MEResultMetaSort(this.field, this.direction, this.idx, event.shiftKey)
    );
  }

  public down(event: MouseEvent) {
    this.direction = 'desc';
    this.onClick.emit(
      new MEResultMetaSort(this.field, this.direction, this.idx, event.shiftKey)
    );
  }
}
