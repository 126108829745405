import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit, TemplateRef, ViewChild, ViewContainerRef,
    ViewEncapsulation
} from "@angular/core";
import {
    MEConfirmDialogComponent,
    MEDataTableRowSelected,
    MEGenericModalComponent,
    MELocalizedComponent,
    MERowActionParams,
    MESubscriptionHelper, noop
} from "../../../../maennl-commons";
import {WireguardClientService} from "../../commons/wireguard-client.service";
import {BenutzerService} from "../../../../benutzer/services/benutzer.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {WireguardClientList} from "../../commons/wireguard-client.list";
import {WireguardClient} from "../../commons/wireguard-client.class";
import {WireguardClientEditorComponent} from "./wireguard-client-editor/wireguard-client-editor.component";
import {WireguardClientImpl} from "../../commons/wireguard-client.impl";
import {WireguardClientIPEditorComponent} from "./wireguard-client-ip-editor/wireguard-client-ip-editor.component";

@Component({
    selector: 'app-tab-wireguard-client',
    templateUrl: './tab-wireguard-client.component.html',
    styleUrls: ['./tab-wireguard-client.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabWireguardClientComponent
    extends MELocalizedComponent
    implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('detailTabs', {read: ViewContainerRef}) detailsRef: ViewContainerRef;
    @ViewChild('details', {read: TemplateRef}) detailRef: TemplateRef<any>;
    @Input() id=''
    clientListe=new WireguardClientList();

    private _tabActive=false;
    public get tabActive():boolean{
        return this._tabActive
    }

    @Input()
    public set tabActive(value:boolean){
        if(this._tabActive!==value){
            this._tabActive=value;
            this.cd.markForCheck();
            if (this._tabActive){
                this.clientListe.start();
            }
        }
    }

    constructor(
        public cd:ChangeDetectorRef,
        public clientService: WireguardClientService,
        public benutzerService: BenutzerService,
        public modalService: NgbModal,
        public toastr: ToastrService
    ) {
        super()
    }

    private _current:WireguardClient=null;

    get current():WireguardClient{
        return this._current;
    }

    set current(value:WireguardClient){
        if(
            this._current!==value ||
            (this._current!==null &&
                this._current!==undefined&&
                value!==null &&
                value!==undefined&&
            this.current.id!==value.id)
        ) {
            this._current=value;
            this.cd.markForCheck();
        }
    }

    ngOnInit():void {
        MESubscriptionHelper.add(
            this,
            this.clientListe.onUpdateRequired.subscribe(()=>{
                this.cd.markForCheck();
                if(this.clientListe.loading){
                    return;
                }
                this.clientListe.loading=true;
                this.clientService.list(
                    this.clientListe.size,
                    this.clientListe.calcOffset(),
                    this.clientListe.order,
                    this.clientListe.simpleFilter,
                    this.clientListe.getQuery()
                )
                    .subscribe({
                        next:l=>{
                            this.clientListe.doPopulateFromListResult(l);
                            this.clientListe.loading=false;
                            this.cd.markForCheck();
                        },
                        error:() =>{
                            this.clientListe.clear();
                            this.clientListe.loading=false;
                        }
                    });
                this.cd.markForCheck();
            })
        )
    }

    onActivate(){
        this.tabActive=true;
    }

    onDeactivate(){
        this.tabActive=false;
    }

    ngOnDestroy() {
        this.clientListe.release();
        MESubscriptionHelper.release(this);
    }

    public ngAfterViewInit() {
    }

    removeClient($event: MERowActionParams<WireguardClient>){
        if(this.clientService.get($event.row.data.id)!==null&&this.clientService.get($event.row.data.id)!==undefined) {
           MEConfirmDialogComponent.display(
               this.modalService,
               "WG Client Löschung bestätigen",
               "Soll der WireGuard Client wirklich unwiderruflich gelöscht werden?",
               'Ja, jetzt löschen',
               'icofont icofont-delete'
           ).then(()=> {
               this.clientService.get($event.row.data.id).subscribe((e:WireguardClient)=>{
                   if(e.id!==null&&e.id!==undefined&&e.id>0) {
                       this.clientService.delete(e.id).subscribe(()=>{
                           this.clientListe.start();
                       });
                   }
               });
           }, () =>{
               this.toastr.error(
                   'Die Löschung des WireGuard Clients wurde abgebrochen.'
               )
           })
        }

    }

    addEntry(){
        WireguardClientEditorComponent.open(
            this.modalService,
            WireguardClientEditorComponent,
            null,
            MEGenericModalComponent.SIZE_MEDIUM,
            null
        ).then(()=>{
            this.clientListe.start();
        },noop());
    }

    editEntry($event: MERowActionParams<WireguardClient>){
        this.clientService.get($event.row.data.id).subscribe((e:WireguardClient)=>{
            if(e!==null&&e!==undefined&&e.id>0){
                WireguardClientEditorComponent.open(
                    this.modalService,
                    WireguardClientEditorComponent,
                    e,
                    MEGenericModalComponent.SIZE_MEDIUM,
                    null
                ).then(()=>{
                    this.clientListe.start();
                }, noop());
            }
        });
    }

    onRowSelected($event: MEDataTableRowSelected<WireguardClient>){
        if(
            $event===null||
            $event===undefined||
            $event.row===null||
            $event.row===undefined||
            $event.row.data===null||
            $event.row.data===undefined
        ) {
            this.current=null;
            return;
        }
        this.current = WireguardClientImpl.fromResult($event.row.data);
        if (this.detailsRef !== null && this.detailsRef !== undefined) {
            this.detailsRef.clear();
            this.detailsRef.createEmbeddedView(this.detailRef);
        }
    }

    refresh_current() {
        this.clientService.get(this.current.id).subscribe((e:WireguardClient)=>{
            for(let i=0;i<this.clientListe.data.length;i++){
                if(this.clientListe.data[i].id===e.id){
                    this.clientListe.update(i,e);
                }
            }
            if (this.current.id ===e.id){
                this.current=WireguardClientImpl.fromResult(e);
            }
            this.cd.markForCheck();
        })
    }

    removeIP(index:number){
        if(
            this.current===null||
            this.current===undefined||
            this.current.allowed_ips===null||
            this.current.allowed_ips===undefined||
            this.current.allowed_ips.length<index
        ) {
            this.toastr.error('Die IP konnte nicht entfernt werden!');
            return;
        }
        MEConfirmDialogComponent.display(
            this.modalService,
            "IP Löschung bestätigen",
            "Soll die IP wirklich unwiderruflich vom Client entfernt werden?",
            "Ja, jetzt entfernen",
            'icofont icofont-delete'
        ).then(()=>{
            this.current.allowed_ips.splice(index,1);
            this.clientService.store(this.current).subscribe({
                next:r =>{
                    if(r.success){
                        this.refresh_current();
                    } else {
                        this.toastr.error(
                            'Das Entfernen der IP ist fehlgeschlagen!'
                        );
                    }
                    this.cd.markForCheck();
                },
                error:()=>{
                    this.toastr.error('Das Entfernen der IP ist fehlgeschlagen!');
                }
            });
        }, ()=>{
            this.toastr.error('Das Entfernen der IP wurde abgebrochen!');
        })

    }

    addIP(){
        this.clientService.get(this.current.id).subscribe((e:WireguardClient)=>{
            if(e!==null&&e!==undefined&&e.id>0){
                WireguardClientIPEditorComponent.open(
                    this.modalService,
                    WireguardClientIPEditorComponent,
                    e,
                    MEGenericModalComponent.SIZE_MEDIUM,
                    null
                ).then(()=>{
                    this.refresh_current();
                    this.cd.markForCheck();
                }, noop());
            }
        });
    }
}