import { MEGenericFilter } from '../../maennl-commons/filters';
import { IMachine } from './imachine';
import { MachineType } from './machine-type';

export class SimpleMachineFilter extends MEGenericFilter<IMachine> {
  machineType: MachineType = null;

  public encodeField(fieldname: string): string {
    if (fieldname === 'machineType') {
      if (this.machineType !== null && this.machineType !== undefined) {
        return (
          'filter.' +
          encodeURIComponent(fieldname) +
          '=' +
          encodeURIComponent('' + this.machineType.id)
        );
      } else {
        return '';
      }
    }
    return super.encodeField(fieldname);
  }
}
