import { MEGenericFilter } from '../../maennl-commons/filters/megeneric-filter';
import { IDevice } from '../../router/common/idevice';
import { IMitschnittgeraet } from './imitschnittgeraet';
import { Mitschnittgeraet } from './mitschnittgeraet';

export class SimpleMitschnittgeraetFilter
  extends MEGenericFilter<Mitschnittgeraet>
  implements IMitschnittgeraet
{
  id: number = null;
  label: string = null;
  device: IDevice = null;
  enabled: boolean = null;
  deviceId: string = null;
  lastConnection: any = null;
  lastConnectionId: number = null;
  protocol: string = null;
}
