import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { MEDataTableLocalizedCellRenderer } from '../../maennl-commons/data-table/renderer';
import { MEDataTableColumnComponent } from '../../maennl-commons/data-table/columns';
import { MEDataTableRow } from '../../maennl-commons/data-table/rows';

@Component({
  selector: 'app-remote-log-time-renderer',
  template: `
    <div *ngIf="isValid()">
      <div class="text-nowrap">
        {{ fDate(row.data.zeitpunkt, 'YYYY-MM-DD HH:mm:ss', true) }}
      </div>
      <div class="text-nowrap received">
        <em>E: {{ fDate(row.data.received, 'YYYY-MM-DD HH:mm:ss', true) }}</em>
      </div>
    </div>
  `,
  styles: [
    `
      .received {
        font-size: 70%;
      }

      .d-flex > div {
        padding: 3px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RemoteLogTimeRendererComponent extends MEDataTableLocalizedCellRenderer {
  public cell: any = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<any> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }

  isValid() {
    return (
      this.row !== null &&
      this.row !== undefined &&
      this.row.data !== null &&
      this.row.data !== undefined
    );
  }
}
