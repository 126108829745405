import { MEGenericFilter } from '../../maennl-commons/filters';
import { Device } from '../../router/common/device';
import { INozzleReaderLogEntry } from './inozzle-reader-log-entry';
import * as moment from 'moment';

export class SimpleNozzleReaderLogEntryFilter extends MEGenericFilter<INozzleReaderLogEntry> {
  zeitpunkt: Date[] = null;
  received: Date[] = null;
  vorgang: string = null;
  device: Device = null;
  kfzkennzeichen: string = null;
  message: string = null;
  vin: string = null;
  mac: string = null;
  fahrzeug: string = null;

  public encodeField(fieldname: string): string {
    if (fieldname === 'zeitpunkt') {
      if (this.zeitpunkt != null && this.zeitpunkt.length > 1) {
        let r = 'filter.' + encodeURIComponent(fieldname) + '=';
        if (this.zeitpunkt[0] != null) {
          r = r + encodeURIComponent(moment(this.zeitpunkt[0]).toISOString());
        }
        r = r + ',';
        if (this.zeitpunkt[1] != null) {
          r = r + encodeURIComponent(moment(this.zeitpunkt[1]).toISOString());
        }
        return r;
      }
    }
    if (fieldname === 'received') {
      if (this.received != null && this.received.length > 1) {
        let r = 'filter.' + encodeURIComponent(fieldname) + '=';
        if (this.received[0] != null) {
          r = r + encodeURIComponent(moment(this.received[0]).toISOString());
        }
        r = r + ',';
        if (this.received[1] != null) {
          r = r + encodeURIComponent(moment(this.received[1]).toISOString());
        }
        return r;
      }
    }
    if (fieldname === 'vorgang') {
      if (this.vorgang !== null && this.vorgang.trim() !== '') {
        let r = 'filter.' + encodeURIComponent(fieldname) + '=';
        r = r + encodeURIComponent(this.vorgang);
        return r;
      }
    }
    if (fieldname === 'device') {
      if (this.device !== null && this.device !== undefined) {
        return (
          'filter.' +
          encodeURIComponent(fieldname) +
          '=' +
          encodeURIComponent('' + this.device.id)
        );
      } else {
        return '';
      }
    }

    if (fieldname === 'kennzeichen') {
      if (this.kfzkennzeichen !== null && this.kfzkennzeichen !== undefined) {
        return (
          'filter.' +
          encodeURIComponent(fieldname) +
          '=' +
          encodeURIComponent('' + this.kfzkennzeichen)
        );
      } else {
        return '';
      }
    }
    if (fieldname === 'message') {
      if (this.message !== null && this.message !== undefined) {
        return (
          'filter.' +
          encodeURIComponent(fieldname) +
          '=' +
          encodeURIComponent('' + this.message)
        );
      } else {
        return '';
      }
    }
    if (fieldname === 'vin') {
      if (this.vin !== null && this.vin !== undefined) {
        return (
          'filter.' +
          encodeURIComponent(fieldname) +
          '=' +
          encodeURIComponent('' + this.vin)
        );
      } else {
        return '';
      }
    }
    if (fieldname === 'mac') {
      if (this.mac !== null && this.mac !== undefined) {
        return (
          'filter.' +
          encodeURIComponent(fieldname) +
          '=' +
          encodeURIComponent('' + this.mac)
        );
      } else {
        return '';
      }
    }
    if (fieldname === 'fahrzeug') {
      if (this.fahrzeug !== null && this.fahrzeug !== undefined) {
        return (
          'filter.' +
          encodeURIComponent(fieldname) +
          '=' +
          encodeURIComponent('' + this.fahrzeug)
        );
      } else {
        return '';
      }
    }

    return super.encodeField(fieldname);
  }
}
