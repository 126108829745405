import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DeviceTypeList } from '../common/device-type-list';
import { RouterService } from '../services/router.service';
import { MERichError } from '../../maennl-commons/tools';
import { MESubscriptionHelper } from '../../maennl-commons/services/mesubscription-helper';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-tab-device-types',
  templateUrl: './tab-device-types.component.html',
  styleUrls: ['./tab-device-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabDeviceTypesComponent implements OnInit, OnDestroy {
  __classname = 'TabDeviceTypesComponent';
  __instance = '';

  public devicetypes: DeviceTypeList = new DeviceTypeList();

  private tabActive = false;

  constructor(
    public routerService: RouterService,
    public cd: ChangeDetectorRef,
    public benutzerService: BenutzerService
  ) {}

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.devicetypes.onUpdateRequired.subscribe((list: DeviceTypeList) => {
        this.routerService
          .listDeviceTypes(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
            },
            (e) => {
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste der verfügbaren Geräte konnte nicht geladen werden'
              );
            }
          );
      }, undefined)
    );
    if (this.tabActive) {
      this.devicetypes.start();
    }
  }

  tabActivated() {
    this.tabActive = true;
    this.devicetypes.start();
  }

  tabDeactivated() {
    this.tabActive = false;
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.devicetypes.release();
  }
}
