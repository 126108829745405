import { Directive, Input } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';
import { IMEOption } from '../../common/imeoption';

@Directive({
  selector: 'me-select-field-option'
})
export class MESelectFieldOptionDirective implements IMEOption {
  @Input()
  icon: string;

  @Input()
  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.key = MESelectFieldOptionDirective.genKey(v);
    }
  }

  get value() {
    return this._value;
  }

  public _value: any = null;
  public key = '';
  @Input() public label = '';

  static genKey(value: any) {
    return Md5.hashStr(JSON.stringify(value)).toString();
  }

  constructor() {}
}
