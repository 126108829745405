<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'list']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Verbindungsübersicht"
></me-area-heading>

<me-card id="filtersettings" [title]="'Filtereinstellungen'" icon="far fa-filter"
         extraBodyClass="small"
         [extraBodyStyles]="{'padding-bottom.px': 5,'padding-top.px':5}">
    <div class="d-flex justify-content-between">
        <me-option class="w-20 me-5"
                   [title]="'Kontakt'"
                   icon="far fa-id-card"
        >
            <app-contact-selector [small]="true" [(value)]="verbindungen.simpleFilter.contact"></app-contact-selector>
        </me-option>
        <me-option class="w-20 me-5"
                   [title]="'interner Kontakt'"
                   icon="far fa-id-card"
        >
            <app-contact-selector [showInternOnly]="true"
                                  [small]="true"
                                  [nullable]="true"
                                  [(value)]="verbindungen.simpleFilter.intern_contact"></app-contact-selector>
        </me-option>
        <me-option title="Zeitbereich" icon="far fa-clock" class="me-5">
            <div class="d-flex">
                <me-date-time-field class="me-3" label="von" [labelwidth]="2" [size]="'sm'"
                                    [(value)]="verbindungen.simpleFilter.date_from"
                ></me-date-time-field>
                <me-date-time-field label="bis" [labelwidth]="2" [size]="'sm'"
                                    [(value)]="verbindungen.simpleFilter.date_until"
                ></me-date-time-field>
                <div>
                    <div class="dropdown">
                        <button class="btn btn-sm btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Presets
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" (click)="selectPreset(0)">aktueller Monat</a>
                            <a class="dropdown-item" (click)="selectPreset(1)">Vormonat</a>
                        </div>
                    </div>
                </div>
            </div>
        </me-option>

        <me-option title="Aktionen" icon="far fa-bolt">
            <button class="btn btn-sm btn-secondary" (click)="verbindungen.start()">Aktualisieren</button>
        </me-option>
    </div>
</me-card>
<br/>
<me-card title="Report">
    <me-data-table
            *ngIf="verbindungen!==null && verbindungen!==undefined"
            [rowActionsAsButtons]="true"
            [tableActionsAsButtons]="true"
            [data]="verbindungen.asDataTableRows()"
            [selectable]="false"
            actionsicon="icofont icofont-navigation-menu"
            #verbindungenTable
            [footerVisible]="verbindungen.pageCount()>1"
            [footer2Visible]="true"
            [isLoading]="loading"
            [extraRowClasses]="rowClassGetter"

    >

        <ng-template #zp let-row="row">
            <ng-container *ngFor="let row of typed(row)">
                <div>
                    <div class="fw-bold">
                        {{ fDate(row.call_start, 'yyyy-MM-DD HH:mm:ss', true) }}
                    </div>
                    <div>
                        <ng-container *ngIf="row.call_type==='inbound'">
                            eingehender Anruf
                        </ng-container>
                        <ng-container *ngIf="row.call_type==='outbound'">
                            ausgehender Anruf
                        </ng-container>
                        <ng-container *ngIf="row.call_type==='internal'">
                            intern
                        </ng-container>
                        <ng-container
                                *ngIf="row.call_type!=='outbound' && row.call_type!=='inbound' && row.call_type!=='internal'">
                            {{ row.call_type }}
                        </ng-container>
                    </div>
                    <div *ngIf="row.failed_call">
                        <div class="badge bg-danger">fehlgeschlagen</div>
                    </div>
                    <div class="mt-2 small" title="interne Anruf-ID">
                        {{ row.call_id }}
                    </div>
                </div>
            </ng-container>
        </ng-template>
        <me-data-table-column [label]="'Zeitpunkt'" [field]="'call_start'" [template]="zp"></me-data-table-column>
        <ng-template #partySrc let-row="row">
            <ng-container *ngFor="let row of typed(row)">
                <div>
                    <div class="fw-bold">
                        {{ row.origin_cid }}
                    </div>
                    <div *ngIf="row.origin_name!=='' && row.origin_name!==row.origin_cid">
                        {{ row.origin_name }}
                    </div>
                    <div *ngIf="row.origin_name==='' || row.origin_name===row.origin_cid">
                        {{ rufnummer(row.origin_cid, null, null, null)}}
                    </div>
                </div>
            </ng-container>
        </ng-template>
        <me-data-table-column [label]="'Quelle'" [field]="'origin_cid'" [template]="partySrc"></me-data-table-column>
        <ng-template #partyDest let-row="row">
            <ng-container *ngFor="let row of typed(row)">
                <div>
                    <div class="fw-bold">
                        {{ row.destination }}
                    </div>
                    <div *ngIf="row.destination_name!=='' && row.destination_name!==row.destination">
                        {{ row.destination_name }}
                    </div>
                    <div *ngIf="row.destination_name==='' || row.destination_name===row.destination">
                        {{ rufnummer(row.destination, null, null, null)}}
                    </div>
                </div>
            </ng-container>
        </ng-template>

        <me-data-table-column [label]="'Ziel'" [field]="'destination'" [template]="partyDest"></me-data-table-column>

        <ng-template #nbst let-row="row">

            <ng-container *ngFor="let row of typed(row)">
                <div>
                    <ng-container *ngFor="let ex of row.call_path;let idx=index">
                        <div class="badge"
                             [class.bg-info]="(ex | splitExtension:'type')?.startsWith('internal')"
                             [class.text-dark]="(ex | splitExtension:'type')?.startsWith('internal')"
                             [class.bg-primary]="(ex | splitExtension:'type')?.startsWith('external')"
                             [title]="((ex | splitExtension:'type')?.startsWith('internal'))?'interne Nebenstelle':(((ex | splitExtension:'type')?.startsWith('external'))?'externer Teilnehmer':'unbekannt')"
                        >
                            {{ ex | splitExtension:'extension' }}
                        </div>
                        <ng-container *ngIf="idx<(row.call_path.length-1)">
                            <div class="badge bg-secondary text-dark"
                                 *ngIf="ex.endsWith('-ATTENDEDTRANSFER') ">
                                <i class="far fa-arrow-right-arrow-left"
                                   title="Weiterleitung mit Ansage oder Konferenzvorbereitung"></i>
                            </div>
                            <div class="badge bg-secondary text-dark"
                                 *ngIf="ex.endsWith('-BLINDTRANSFER') ">
                                <i class="far fa-right-from-bracket"
                                   title="Weiterleitung ohne Ankündigung (Blindtransfer)"></i>
                            </div>
                            <div class="badge bg-secondary text-dark" *ngIf="ex.endsWith('-PICKUP')">
                                <i class="far fa-phone-arrow-up-right" title="PICKUP"></i>
                            </div>
                            <div class="badge bg-secondary text-dark"
                                 *ngIf="!ex.endsWith('-PICKUP') && !ex.endsWith('TRANSFER')">
                                <i class="far fa-right"></i>
                            </div>

                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </ng-template>

        <me-data-table-column [label]="'Teilnehmer'" [field]="'last_internal_cid'"
                              [template]="nbst"></me-data-table-column>

        <ng-template #dauer let-row="row">
            <ng-container *ngFor="let row of typed(row)">
                <div>
                    <div class="d-flex align-items-center justify-content-between">
                        <div>Dauer</div>
                        <div>
                            {{ fDuration(row.call_duration * 1000, false, false, true) }}
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                        <div>AE</div>
                        <div>
                            {{ fNum(row.einheiten, '1.0-0')}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-template>
        <ng-template #dauerFooter let-item="item" let-list="list" let-footer="footer">
            <div *ngIf="footer==='footer'">
                <div class="d-flex align-items-center justify-content-between">
                    <div>Dauer</div>
                    <div>
                        {{ fDuration(verbindungen.calcSum('call_duration') * 1000, false, false, true) }}
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <div>AE</div>
                    <div>
                        {{ fNum(verbindungen.calcSum('einheiten'), '1.0-0')}}
                    </div>
                </div>
            </div>
            <div *ngIf="footer==='footer2'">
                <div class="d-flex align-items-center justify-content-between">
                    <div>Dauer Total</div>
                    <div>
                        {{ fDuration(verbindungen?.extra['dauer'] * 1000, false, false, true) }}
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                    <div>AE Total</div>
                    <div>
                        {{ fNum(verbindungen?.extra['ae'], '1.0-0')}}
                    </div>
                </div>
            </div>

        </ng-template>
        <me-data-table-column [label]="'Abrechnung'" [field]="'call_duration'"
                              [template]="dauer"
                              [footerTemplate]="dauerFooter"
                              [component]="that"
                              [footerIsSum]="true"
                              [cfg]="{addon:'s',fdigits:0}"

                              [footer2Value]="footerTotalValue"

        ></me-data-table-column>


        <me-data-table-table-extra-header>
            <me-pagination
                    [total]="verbindungen.total"
                    [page]="verbindungen.page"
                    [perpage]="verbindungen.size"
                    (pageChange)="verbindungen.setPage($event)"
                    (pageCountChange)="verbindungen.processPageCountChangedEvent($event)"
                    [settingsService]="benutzerService"
                    [settingsid]="'verbindungen'"
            ></me-pagination>
        </me-data-table-table-extra-header>


        <me-data-table-action
                *ngIf="verbindungen.total>0"
                [label]="_('PDF-Download')"
                [icon]="'far fa-file-pdf'"
                (perform)="downloadReport(0)"
        ></me-data-table-action>


        <me-data-table-action
                *ngIf="verbindungen.total>0"
                [label]="_('PDF-Download mit Bemerkungen')"
                [icon]="'far fa-file-pdf'"
                (perform)="downloadReport(1)"
        ></me-data-table-action>

    </me-data-table>


</me-card>
