import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { METabCardComponent } from '../maennl-commons/metab-card';
import { TabSimsComponent } from './tab-sims/tab-sims.component';
import { TabDevicesComponent } from './tab-devices/tab-devices.component';
import { TabDeviceTypesComponent } from './tab-device-types/tab-device-types.component';

@Component({
  selector: 'app-router',
  templateUrl: './router.component.html',
  styleUrls: ['./router.component.scss']
})
export class RouterComponent implements OnInit, OnDestroy {
  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;

  @ViewChild(TabDevicesComponent, { static: false })
  devicesTab: TabDevicesComponent;
  @ViewChild(TabSimsComponent, { static: false }) simsTab: TabSimsComponent;
  @ViewChild(TabDeviceTypesComponent, { static: false })
  deviceTypesTab: TabDeviceTypesComponent;

  constructor() {}

  ngOnInit() {}

  activateTab(id: string) {
    if ('tab-devices' === id) {
      if (this.devicesTab !== null && this.devicesTab !== undefined) {
        this.devicesTab.tabActivated();
      }
    }
    if ('tab-simcards' === id) {
      if (this.simsTab !== null && this.simsTab !== undefined) {
        this.simsTab.tabActivated();
      }
    }
    if ('tab-device-types' === id) {
      if (this.deviceTypesTab !== null && this.deviceTypesTab !== undefined) {
        this.deviceTypesTab.tabActivated();
      }
    }
  }

  deactivateTab(id: string) {
    if ('tab-devices' === id) {
      if (this.devicesTab !== null && this.devicesTab !== undefined) {
        this.devicesTab.tabDeactivated();
      }
    }
    if ('tab-simcards' === id) {
      if (this.simsTab !== null && this.simsTab !== undefined) {
        this.simsTab.tabDeactivated();
      }
    }
    if ('tab-device-types' === id) {
      if (this.deviceTypesTab !== null && this.deviceTypesTab !== undefined) {
        this.deviceTypesTab.tabDeactivated();
      }
    }
  }

  ngOnDestroy(): void {}
}
