<ng-template #ahIcon>
    <i class="me-icon me-icon-mitschnitt"></i>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Mitschnitt-System"
></me-area-heading>

<me-tab-card>
    <me-pane title="Geräte"
             id="tab-devices"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
    >
        <app-tab-mitschnitt-devices></app-tab-mitschnitt-devices>
    </me-pane>
    <me-pane title="Datenströme"
             id="tab-streams"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
    >
        <app-tab-mitschnitt-streams></app-tab-mitschnitt-streams>
    </me-pane>
</me-tab-card>