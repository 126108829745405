<me-data-table
        *ngIf="verlauf!==null && verlauf!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="verlauf.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        [extraRowClasses]="rowClassGetter"
        #verlaufTable
>

    <me-data-table-column [label]="'Gerät'"
                          field="@row~0"
                          [renderer]="deviceRenderer"
    ></me-data-table-column>

    <me-data-table-column [label]="'System'"
                          field="@row~1"
                          [renderer]="systemRenderer"
    ></me-data-table-column>

    <me-data-table-column [label]="'CPU'"
                          field="@row~2"
                          [renderer]="cpuRenderer"
    ></me-data-table-column>

    <me-data-table-column [label]="'Dateisystem'"
                          field="@row~3"
                          [renderer]="filesystemRenderer"
    ></me-data-table-column>
    <me-data-table-column [label]="'Modem'"
                          field="@row~4"
                          [renderer]="modemRenderer"
    ></me-data-table-column>

    <me-data-table-rowaction
            *ngIf="!detailMode"
            [title]="'Details'"
            (perform)="showDetails($event)"
            [icon]="'far fa-info-square'"
    ></me-data-table-rowaction>



    <me-data-table-table-extra-header>
        <me-pagination
                [settingsid]="getSettingsId()"
                [settingsService]="benutzerService"
                [pagecounts]="[10,20,50,100,500,1000]"
                [total]="verlauf.total"
                [page]="verlauf.page"
                [perpage]="verlauf.size"
                (pageChange)="verlauf.setPage($event)"
                (pageCountChange)="verlauf.processPageCountChangedEvent($event)"
                [showSearch]="true"
                (queryChange)="verlauf.processQueryChangedEvent($event)"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
