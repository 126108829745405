<ng-container #ipaddresses [ngTemplateOutlet]="ipaddressestemp">
    <ng-template #ipaddressestemp>
        <me-text-field-icon-with-output-event
                [label]="'IP-Adresse'"
                [(value)]="inputValue"
                (iconClick)="this.add_ip_address(inputValue)"
                [icon_title]="'IP-Adresse hinzufügen'"
                [icon]="'fa fa-fw fa-plus-square has-cursor-pointer'"
        ></me-text-field-icon-with-output-event>
        <ng-container *ngIf="entity.ip_addresses !== null && entity.ip_addresses!==undefined && entity.ip_addresses.length > 0">
            <ng-container *ngFor="let ip of entity.ip_addresses; index as i; trackBy:trackByFn">
                <ng-container *ngIf="ip !== undefined && ip !== null && ip.trim() !== ''&& ip.trim() !== 'null'">
                    <me-text-field-icon-with-output-event
                            [label]="'IP-Adresse'"
                            [disabled]="true"
                            [(value)]="entity.ip_addresses[i]"
                            (iconClick)="this.remove_ip_address(ip)"
                            [icon_title]="'IP-Adresse entfernen'"
                            [icon]="'far fa-trash has-cursor-pointer'"
                    ></me-text-field-icon-with-output-event>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-template>
</ng-container>