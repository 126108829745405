<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'money-bill']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Preisübermittlung"
></me-area-heading>

<me-tab-card>
    <me-pane id="tab-automatenpreise"
             title="Automaten-Preise"
             (activated)="activate(automatenPreiseTab)"
    >
        <app-tab-preisuebermittlung #automatenPreiseTab></app-tab-preisuebermittlung>
    </me-pane>
    <me-pane id="tab-mwstkorrektur"
             title="MwSt.-Korrektur"
             (activated)="activate(mwstKorrekturTab)">
        <app-tab-mwstkorrektur #mwstKorrekturTab></app-tab-mwstkorrektur>
    </me-pane>
</me-tab-card>
