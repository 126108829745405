<div class="modal-header">
    <h5 class="modal-title">Gerät neustarten</h5>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <dl class="row">
        <dt class="col-4">Bezeichnung</dt>
        <dd class="col-8">{{ entity.device.bezeichnung }}</dd>
        <dt class="col-4">Geräte-Typ</dt>
        <dd class="col-8">{{ entity.device.deviceType.label }}</dd>
        <dt class="col-4">Geräte-ID</dt>
        <dd class="col-8">{{ entity.device.machine_id }}</dd>
        <dt class="col-4">Seriennummer</dt>
        <dd class="col-8">{{ entity.device.seriennummer }}</dd>
    </dl>
    <div>
        Soll dieser Router bzw. dieses Gerät wirklich rebootet werden?
        Der Reboot-Vorgang unterbricht alle über dieses Gerät laufenden
        Verbindungen und dauert je nach Gerät ca. 2 min.
    </div>
    <div class="d-flex justify-content-start m-2 alert alert-warning">
        <me-boolean-field [(value)]="entity.hardReset" labelWidth="0" label=""></me-boolean-field>
        <div class="ms-2 p-1">
            Hardreset ausführen (sofern technisch möglich,
            hierbei wird die Stromverbindung für einige Sekunden unterbrochen)
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Abbrechen
    </button>
    <button type="button" class="btn btn-primary" (click)="ok()">
        <i class="icofont icofont-refresh"></i>
        Neustart durchführen
    </button>
</div>
