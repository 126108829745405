import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import {
  IMEListResult,
  MEApiTool,
  MEResultMetaSort
} from '../../../maennl-commons';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import { IRBCustomer } from './irbcustomer';
import { IMESimpleFilter } from '../../../maennl-commons/filters';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RBCustomerService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public list(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IRBCustomer> = null,
    queryString = ''
  ): Observable<IMEListResult<IRBCustomer>> {
    const uri = MEApiTool.buildListURL(
      '/api/webfuel/remote-backup/customers',
      max,
      offset,
      sort,
      filter,
      queryString
    );
    return this.http
      .get<IMEListResult<IRBCustomer>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }

  public get_pdf(id: number): Observable<Blob> {
    let uri = MEApiTool.buildActionURL(
      '/api/webfuel/remote-backup/customers',
      id,
      '_pdf'
    );
    return this.http
      .get<Blob>(
        uri,
        this.loginService.getRequestOptions(false, true, true, 'blob')
      )
      .pipe(take(1));
  }
}
