import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MERichError, noop } from '../../maennl-commons/tools';
import { SimList } from '../common/sim-list';
import { RouterService } from '../services/router.service';
import { MEDTSimProviderRendererComponent } from '../controls/medtsim-provider-renderer/medtsim-provider-renderer.component';
import { IMEListResult } from '../../maennl-commons/mecommon-list';
import { ISim } from '../common/isim';
import { MESubscriptionHelper } from '../../maennl-commons/services/mesubscription-helper';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { SimEditorComponent } from '../sim-editor/sim-editor.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Sim } from '../common/sim';
import { MERowActionParams } from '../../maennl-commons/data-table/actions';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-tab-sims',
  templateUrl: './tab-sims.component.html',
  styleUrls: ['./tab-sims.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabSimsComponent implements OnInit, OnDestroy {
  __classname = 'TabSimsComponent';
  __instance = '';

  public providerRenderer = MEDTSimProviderRendererComponent;

  public sims: SimList = new SimList();
  private tabActive = false;

  constructor(
    public routerService: RouterService,
    public cd: ChangeDetectorRef,
    public modalService: NgbModal,
    public benutzerService: BenutzerService
  ) {}

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.sims.onUpdateRequired.subscribe(
        (list: SimList) => {
          this.routerService
            .listSims(
              list.size,
              list.calcOffset(),
              list.order,
              list.simpleFilter,
              list.getQuery()
            )
            .subscribe(
              (l: IMEListResult<ISim>) => {
                list.populateFromListResult(l);
                this.cd.markForCheck();
              },
              () => {
                MERichError.throw(
                  'Fehler beim Datenabruf',
                  'Die Liste der verfügbaren SIM-Karten konnte nicht geladen werden'
                );
              }
            );
        },
        () => {}
      )
    );
    if (this.tabActive) {
      this.sims.start();
    }
  }

  tabActivated() {
    this.tabActive = true;
    this.sims.start();
  }

  tabDeactivated() {
    this.tabActive = false;
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.sims.release();
  }

  addSim() {
    SimEditorComponent.open(
      this.modalService,
      SimEditorComponent,
      null,
      MEGenericModalComponent.SIZE_MEDIUM
    ).then(
      (r: any) => {
        this.sims.reload();
      },
      () => {
        noop();
      }
    );
  }

  editSim($event: MERowActionParams<Sim>) {
    this.routerService.getSim($event.row.data.id).subscribe(
      (s: ISim) => {
        SimEditorComponent.open(
          this.modalService,
          SimEditorComponent,
          s,
          MEGenericModalComponent.SIZE_MEDIUM
        ).then(
          (r: any) => {
            this.sims.reload();
          },
          () => {
            noop();
          }
        );
      },
      (e) => {
        MERichError.throw(
          'Fehler!',
          'Zu dieser SIM-Karte konnten keine Informationen ermittelt werden.'
        );
      }
    );
  }
}
