export type TValidItem = (item: any) => boolean;

export class METool {
  public static isNullOrUndefined(o?: any) {
    return o === null || o === undefined;
  }

  public static isNaN(val?: any) {
    if (METool.isNullOrUndefined(val)) {
      return true;
    }
    return isNaN(parseFloat('' + val));
  }

  public static isDefined(o?: any) {
    return !METool.isNullOrUndefined(o) && o !== '';
  }

  public static isEmpty(o?: any) {
    return METool.isNullOrUndefined(o) || ('' + o).trim() === '';
  }

  static isValidNumber: TValidItem = (item: any) => {
    return !(
      METool.isNullOrUndefined(item) ||
      METool.isNaN(item) ||
      METool.isEmpty(item)
    );
  };

  static isValidString: TValidItem = (item: any) => {
    let tr = typeof 'test';
    let ti = typeof item;
    return !(
      METool.isNullOrUndefined(item) ||
      METool.isEmpty(item) ||
      tr !== ti
    );
  };
}
