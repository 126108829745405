import {MEDataTableRowSelected, MELocalizedComponent, MESubscriptionHelper} from "../../../maennl-commons";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit, TemplateRef, ViewChild, ViewContainerRef,
    ViewEncapsulation
} from "@angular/core";
import {IPSecInfoList} from "../commons/ipsec-info.list";
import {IPSecInfoChildList} from "../commons/ipsec-info-child.list";
import {ToastrService} from "ngx-toastr";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {IPSecInfoService} from "../commons/ipsec-info.service";
import {TNullableIPSecInfo} from "../commons/ipsec-info.types";
import {IPSecInfo} from "../commons/ipsec-info.class";
import {IPSecInfoImpl} from "../commons/ipsec-info.impl";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";

@Component({
    selector: 'app-tab-ipsec',
    templateUrl: './tab-ipsec.component.html',
    styleUrls: ['./tab-ipsec.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabIPsecComponent extends MELocalizedComponent
    implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('detailTabs', {read: ViewContainerRef}) detailsRef: ViewContainerRef;
    @ViewChild('details', {read: TemplateRef}) detailRef: TemplateRef<any>;
    @Input() id = ''
    liste = new IPSecInfoList();
    ipSecChildInfo = new IPSecInfoChildList();


    constructor(
        public cd: ChangeDetectorRef,
        public service: IPSecInfoService,
        public benutzerService: BenutzerService,
        public modalService: NgbModal,
        public toastr: ToastrService
    ) {
        super();
    }

    private _current: TNullableIPSecInfo = null;

    private _tabActive = false;

    public get tabActive(): boolean {
        return this._tabActive;
    }

    @Input()
    public set tabActive(value: boolean) {
        if (this._tabActive !== value) {
            this._tabActive = value;
            this.cd.markForCheck();
            if (this._tabActive) {
                this.liste.start();
            }
        }
    }

    get current(): TNullableIPSecInfo {
        return this._current;
    }

    set current(value: TNullableIPSecInfo) {
        if (
            this._current !== value ||
            (this._current !== null &&
                value !== null &&
                this._current != undefined &&
                value !== undefined &&
                this.current.unique_id !== value.unique_id)
        ) {
            this._current = value;
            this.cd.markForCheck();
        }
    }

    ngOnInit(): void {
        MESubscriptionHelper.add(
            this,
            this.liste.onUpdateRequired.subscribe(() => {
                this.cd.markForCheck();
                if (this.liste.loading) {
                    return
                }
                this.liste.loading = true;
                this.service
                    .list(
                        this.liste.size,
                        this.liste.calcOffset(),
                        this.liste.order,
                        this.liste.simpleFilter,
                        this.liste.getQuery()
                    )
                    .subscribe({
                        next: l => {
                            this.liste.doPopulateFromListResult(l);
                            this.liste.loading = false;
                            this.cd.markForCheck()
                        },
                        error: () => {
                            this.liste.clear();
                            this.liste.loading = false;
                        },
                    });
                this.cd.markForCheck();
            })
        );
    }

    onActivate() {
        this.tabActive = true;
    }

    onDeactivate() {
        this.tabActive=false;
    }

    ngOnDestroy(): void {
        this.liste.release();
        this.ipSecChildInfo.release();
        MESubscriptionHelper.release(this);
    }

    onRowSelected($event: MEDataTableRowSelected<IPSecInfo>) {
        if (
            $event === null ||
            $event === undefined ||
            $event.row === null ||
            $event.row === undefined ||
            $event.row.data === null ||
            $event.row.data === undefined
        ) {
            this.current = null;
            return;
        }
        this.current = IPSecInfoImpl.fromResult($event.row.data);
        if (this.detailsRef !== null && this.detailsRef !== undefined) {
            this.detailsRef.clear();
            this.detailsRef.createEmbeddedView(this.detailRef);
        }
    }

    public ngAfterViewInit(): void {
    }
}