<div class="modal-header">
  <h4 class="modal-title">Docker-Container</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <me-text-field [label]="_('Server')" [value]="machine.bezeichnung" [disabled]="true"></me-text-field>
  <me-text-field [label]="_('Info')" [(value)]="entity.info"></me-text-field>
  <me-text-field [label]="_('Container-Name')" [(value)]="entity.params.container"></me-text-field>
  <me-num-field [label]="_('Check-Interval (s)')" [(value)]="entity.waitBetween"></me-num-field>
  <me-boolean-field [label]="_('Test aktiviert')" [(value)]="entity.checksEnabled"></me-boolean-field>
  <me-boolean-field [label]="_('bei Fehler nicht alarmieren')" [(value)]="entity.noAlarm"></me-boolean-field>

</div>
<div class="modal-footer d-flex justify-content-between">
  <div>
    <button type="button" class="btn btn-danger" (click)="delete()" *ngIf="entity.id!==null && entity.id!==undefined">
      <i class="icofont icofont-trash"></i> Löschen
    </button>
  </div>
  <div>
    <button type="button" class="btn btn-secondary" (click)="cancel()">
      <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
      <i class="icofont icofont-save"></i> Speichern
    </button>
  </div>
</div>
