import { Component, Input, OnInit } from '@angular/core';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';
import { TestPing } from '../../common/test-ping';
import { MEConverterTool } from '../../../maennl-commons/tools';

@Component({
  selector: 'app-sys-health-test-ping-modem-display',
  templateUrl: './sys-health-test-ping-modem-display.component.html',
  styleUrls: ['./sys-health-test-ping-modem-display.component.scss']
})
export class SysHealthTestPingModemDisplayComponent
  extends MELocalizedComponent
  implements OnInit
{
  @Input()
  testping: TestPing = null;

  get modemState(): string {
    if (!this.hasModem()) {
      return 'unbekannt';
    }

    switch (this.testping.modemState) {
      case -1:
        return 'Fehler!';
      case 0:
        return 'unbekannt';

      case 1:
        return 'Initialisierung';
      case 2:
        return 'blockiert';
      case 3:
        return 'deaktiviert';
      case 4:
        return 'wird deaktiviert';
      case 5:
        return 'wird aktiviert';
      case 6:
        return 'aktiviert';
      case 7:
        return 'Netzsuche';
      case 8:
        return 'eingebucht';
      case 9:
        return 'Verbindung wird getrennt';
      case 10:
        return 'Verbindung wird aufgebaut';
      case 11:
        return 'verbunden';
    }
    return 'unbekannt';
  }

  get accesstechnologies() {
    if (!this.hasModem()) {
      return 'kein Modem!';
    }
    switch (this.testping.accessTechnologies) {
      case 0:
        return 'unbekannt';
      case 0xffffffff:
        return 'alle verfügbaren';
      default: {
        const t = [];
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 0)) {
          t.push('pots');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 1)) {
          t.push('gsm');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 2)) {
          t.push('gsm-c');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 3)) {
          t.push('gprs');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 4)) {
          t.push('edge');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 5)) {
          t.push('umts');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 6)) {
          t.push('hsdpa');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 7)) {
          t.push('hsupa');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 8)) {
          t.push('hspa');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 9)) {
          t.push('hspa+');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 10)) {
          t.push('1xrtt');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 11)) {
          t.push('evdo_0');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 12)) {
          t.push('evdo_a');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 13)) {
          t.push('evdo_b');
        }
        if (MEConverterTool.isBitSet(this.testping.accessTechnologies, 14)) {
          t.push('lte');
        }
        return t.join(', ');
      }
    }
  }

  get status() {
    switch (this.testping.regState) {
      case 0:
        return 'IDLE';

      case 1:
        return 'HOME';

      case 2:
        return 'SEARCHING';

      case 3:
        return 'DENIED';

      case 4:
        return 'UNKNOWN';

      case 5:
        return 'ROAMING';
    }
    return 'unbekannt';
  }

  constructor() {
    super();
  }

  ngOnInit() {}

  hasModem() {
    if (this.testping === null || this.testping === undefined) {
      return false;
    }
    if (this.testping.signal === 0 && this.testping.networkStrength === 1000) {
      return false;
    }
    if (this.testping.modemState < 1) {
      return false;
    }
    return true;
  }
}
