import { IMwstKorrektur } from './imwst-korrektur';
import { MEGenericEntity } from 'src/app/maennl-commons/megeneric-entity/megeneric-entity';
import { MEGenericEntityFields } from 'src/app/maennl-commons/megeneric-entity/megeneric-entity-field';

export class MwstKorrektur
  extends MEGenericEntity<IMwstKorrektur>
  implements IMwstKorrektur
{
  id = 0;
  ip_address = '';
  last_run: Date = null;
  nebenstelle_name = '';
  nebenstelle_nummer = 0;
  next_run: Date = null;
  terminal_addr = 0;
  versuche = 0;

  static fromResult(
    entity: IMwstKorrektur,
    createIfNull: boolean = false
  ): MwstKorrektur {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new MwstKorrektur();
      }
      return null;
    }

    const instance = new MwstKorrektur();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('nebenstelle_name')
        .addLongField('nebenstelle_nummer')
        .addTextField('ip_address')
        .addDateField('last_run')
        .addDateField('next_run')
        .addLongField('terminal_addr')
        .addLongField('versuche')
    );
    return instance;
  }
}
