import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {IPSecInfoChild} from "./ipsec-info-child.class";
import {IIPSecInfoChild} from "./ipsec-info-child.interface";

export class IPSecInfoChildList extends MERemoteList<IPSecInfoChild> {
    __classname: string = 'IPSecInfoChildList';
    __instance = '';

    static fromListResult(res: IMEListResult<IIPSecInfoChild>):IPSecInfoChildList{
        const newList= new IPSecInfoChildList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun: boolean = false, timeout: number = 400) {
        super();
        this.initList(autorun, timeout);
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'name',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true) {
        super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    }
}