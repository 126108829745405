import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  MEGenericModalComponent,
  MELocalizedComponent,
  MERichError, MERowActionParams,
  MESubscriptionHelper,
  METool, noop
} from '../../../maennl-commons';
import { ToastrService } from 'ngx-toastr';
import { TenantList } from '../commons/tenant-list';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import { TenantService } from '../commons/tenant.service';
import { Tenant } from '../commons/tenant';
import {TabTenantsEditorComponent} from "./tab-tenants-editor/tab-tenants-editor.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TNullableNumber} from "../../../maennl-commons/tools/types";

@Component({
  selector: 'app-tab-tenants',
  templateUrl: './tab-tenants.component.html',
  styleUrls: ['./tab-tenants.component.scss']
})
export class TabTenantsComponent
  extends MELocalizedComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  liste = new TenantList(false, 400);

  constructor(
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    public modalService: NgbModal,
    public benutzerService: BenutzerService,
    public tenantsService: TenantService
  ) {
    super();
  }

  private _tabActive = false;

  public get tabActive(): boolean {
    return this._tabActive;
  }

  @Input()
  public set tabActive(value: boolean) {
    if (this._tabActive !== value) {
      this._tabActive = value;
      this.cd.markForCheck();
      if (this._tabActive) {
        this.liste.start();
      }
    }
  }

  ngOnInit(): void {
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe((list: TenantList) => {
        if (this.tabActive) {
          this.tenantsService
            .list(
              list.size,
              list.calcOffset(),
              list.order,
              list.simpleFilter,
              list.getQuery()
            )
            .subscribe({
              next: (l) => {
                list.populateFromListResult(l);
                this.cd.markForCheck();
              },
              error: (e) => {
                console.log(e);
                MERichError.throw(
                  'Fehler beim Datenabruf',
                  'Die Liste der Webfuel.de-Mandanten konnte nicht abgerufen werden.'
                );
              }
            });
        }
      })
    );
  }

  onActivate() {
    this.tabActive = true;
    console.log('activated');
  }

  onDeactivate() {
    this.tabActive = false;
    console.log('deactivated');
  }

  public ngAfterViewInit(): void {}

  public ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.liste.release();
  }

  typed(data: any): Tenant[] {
    if (METool.isNullOrUndefined(data)) {
      return [];
    }
    return [data as Tenant];
  }

  createTAAccessUser(id: number) {
    this.tenantsService.create_ta_access(id).subscribe((r) => {
      if (r.success) {
        this.toastr.success('Der TA-Access Benutzer wurde angelegt');
        this.liste.reload();
      } else {
        MERichError.throw(
          'Anlage fehlgeschlagen',
          'Der TA-Access Benutzer konnte nicht angelegt werden'
        );
      }
    });
  }

  addEntry() {
    TabTenantsEditorComponent.open(
        this.modalService,
        TabTenantsEditorComponent,
        null,
        MEGenericModalComponent.SIZE_MEDIUM,
        null
    ).then(()=> {
      this.liste.start()
    }, noop());

  }

  editTenant($event:MERowActionParams<Tenant>){
    this.tenantsService.get($event.row.data.id).subscribe((t)=>{
        if(t!==null&&t!==undefined&&t.id>0){
          TabTenantsEditorComponent.open(
              this.modalService,
              TabTenantsEditorComponent,
              t,
              MEGenericModalComponent.SIZE_MEDIUM,
              null
          ).then(()=>{
            this.liste.start()
          },noop());
        }
    });
    this.refresh_liste($event.row.data.id);
  }

  refresh_liste(id: TNullableNumber){
    this.tenantsService.get(id).subscribe((t)=>{
      for(let i=0; i<this.liste.data.length;i++) {
        if(this.liste.data[i].id === t.id) {
          this.liste.update(i, t);
          break;
        }
      }
      this.cd.markForCheck();
    });
  }
}
