import { MEListOptionComponent } from '../../melist-option/melist-option.component';
import { MEDataTableRowActionDirective } from '../actions';

export class MEDataTableRow<T> {
  private _data: T = null;

  get data(): T {
    return this._data;
  }

  set data(value: T) {
    this._data = value;
    this._data['@row'] = value;
  }

  public renderers: any = {};
  public _editmode = false;
  public disabledActions: any[] = [];
  public hiddenActions: any[] = [];
  public _selected = false;

  constructor(data: T, selected = false, edit = false, renderers: any = {}) {
    this._data = data;
    this._selected = selected;
    this._editmode = edit;
    this.renderers = renderers;
  }

  get selected() {
    return this._selected;
  }

  get showEditor() {
    return this._editmode;
  }

  public isActionDisabled(
    action: MEDataTableRowActionDirective | MEListOptionComponent
  ): boolean {
    const idx = this.disabledActions.indexOf(action, 0);
    return idx > -1;
  }

  public disableAction(
    action: MEDataTableRowActionDirective | MEListOptionComponent
  ) {
    if (!this.isActionDisabled(action)) {
      this.disabledActions.push(action);
    }
  }

  public enableAction(
    action: MEDataTableRowActionDirective | MEListOptionComponent
  ) {
    const idx = this.disabledActions.indexOf(action, 0);
    if (idx > -1) {
      this.disabledActions.splice(idx, 1);
    }
  }

  public select() {
    this._selected = true;
  }

  public unselect() {
    this._selected = false;
  }

  public isSelected(): boolean {
    return this._selected;
  }

  public toggleSelect() {
    this._selected = !this._selected;
  }

  public toogleEditor() {
    this._editmode = !this._editmode;
  }

  public displayEditor() {
    this._editmode = true;
  }

  public hideEditor() {
    this._editmode = false;
  }

  public asJSON() {
    return JSON.stringify({ s: this._selected, d: this._data });
  }

  public isActionHidden(
    action: MEDataTableRowActionDirective | MEListOptionComponent
  ): boolean {
    if (this.hiddenActions.length < 1) {
      return false;
    }
    const idx = this.hiddenActions.indexOf(action, 0);
    return idx > -1;
  }

  public hideAction(
    action: MEDataTableRowActionDirective | MEListOptionComponent
  ) {
    if (!this.isActionHidden(action)) {
      this.hiddenActions.push(action);
    }
  }

  public showAction(
    action: MEDataTableRowActionDirective | MEListOptionComponent
  ) {
    const idx = this.hiddenActions.indexOf(action, 0);
    if (idx > -1) {
      this.hiddenActions.splice(idx, 1);
    }
  }
}
