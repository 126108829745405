<div class="sidebar-nav d-flex flex-column">
    <div class="nav-container">
        <div *appIsLoggedIn>

            <app-side-bar-link title="Übersicht"
                               [link]="'index'"
                               [icon]="'fa-fw icofont icofont-dashboard-web'"
                               [label]="'Übersicht'">
            </app-side-bar-link>

            <app-side-bar-group title="Status"
                                [label]="'Status'"
                                [groupOpen]="false"
                                [icon]="'far fa-heartbeat'"
                                link="status/systemstatus"
                                *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_SYSHEALTH'])"

            >
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_SYSHEALTH'])"
                        [link]="'status/systemstatus'"
                        [icon]="'far fa-heartbeat'"
                        [label]="'Systemstatus'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_SYSHEALTH'])"
                        [link]="'status/lebenszeichen'"
                        [icon]="'far fa-heartbeat'"
                        [label]="'Lebenszeichen'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_SYSHEALTH'])"
                        [link]="'status/testpings'"
                        [icon]="'far fa-heartbeat'"
                        [label]="'Test-Pings (neu)'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_SYSHEALTH'])"
                        [link]="'status/routerstatus'"
                        [icon]="'far fa-heartbeat'"
                        [label]="'Router/Sim-Status'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_SYSHEALTH'])"
                        [link]="'status/boxlisten'"
                        [icon]="'far fa-heartbeat'"
                        [label]="'Boxlisten'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_SYSHEALTH'])"
                        [link]="'status/dhcp'"
                        [icon]="'far fa-heartbeat'"
                        [label]="'DHCP-Status (Linux-Server)'">
                </app-side-bar-link>
            </app-side-bar-group>

            <app-side-bar-group title="Telefonanlage"
                                [label]="'Telefonanlage'"
                                [groupOpen]="false"
                                [icon]="'far fa-phone'"
                                link="telefonanlage"
                                *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_TELEFONANLAGE'])"
            >
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_TELEFONANLAGE'])"
                        [link]="'telefonanlage/contacts'"
                        [icon]="'far fa-address-book'"
                        [label]="'Kontakte'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_TELEFONANLAGE'])"
                        [link]="'telefonanlage/verbindungen'"
                        [icon]="'far fa-list'"
                        [label]="'Verbindungsübersicht'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_TELEFONANLAGE'])"
                        [link]="'telefonanlage/last_calls'"
                        [icon]="'far fa-list'"
                        [label]="'Anrufliste'">
                </app-side-bar-link>
            </app-side-bar-group>

            <app-side-bar-group title="Netzwerk/ME Connect"
                                [label]="'Netzwerk/ME Connect'"
                                [groupOpen]="false"
                                [icon]="'fad fa-chart-network'"
                                link="meconnect"
                                *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_MECONNECT'])"
            >
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_MECONNECT'])"
                        [link]="'meconnect/ip-blocklist'"
                        [icon]="'far fa-firewall'"
                        [label]="'IP-Blockliste'">
                </app-side-bar-link>

                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_MECONNECT'])"
                        [link]="'meconnect/ip-addresses'"
                        [icon]="'far fa-network-wired'"
                        [label]="'IP-Adressen & Netzwerke'">
                </app-side-bar-link>

                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_MECONNECT'])"
                        [link]="'meconnect/netz-dessau'"
                        [icon]="'far fa-network-wired'"
                        [label]="'Dessau'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_MECONNECT'])"
                        [link]="'meconnect/netz-m2m'"
                        [icon]="'far fa-signal'"
                        [label]="'M2M'">
                </app-side-bar-link>

                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_MECONNECT'])"
                        [link]="'meconnect/vpn1'"
                        [icon]="'far fa-server'"
                        [label]="'vpn1'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_MECONNECT'])"
                        [link]="'meconnect/vpn2'"
                        [icon]="'far fa-server'"
                        [label]="'vpn2'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_MECONNECT'])"
                        [link]="'meconnect/gateway1'"
                        [icon]="'far fa-server'"
                        [label]="'gateway1'">
                </app-side-bar-link>

                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_MECONNECT'])"
                        [link]="'meconnect/csd'"
                        [icon]="'far fa-phone-rotary'"
                        [label]="'CSD Server'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_MECONNECT'])"
                        [link]="'meconnect/ovpn-telefon'"
                        [icon]="'far fa-shield'"
                        [label]="'VPN Telefon'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_MECONNECT'])"
                        [link]="'meconnect/ovpn-linuxserver'"
                        [icon]="'far fa-shield'"
                        [label]="'VPN Linuxserver'">
                </app-side-bar-link>
            </app-side-bar-group>

            <app-side-bar-link title="Remote-Logs"
                               *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_ROUTER','ROLE_REMOTE_LOG','ROLE_MITSCHNITT'])"
                               [link]="'remote-logs'"
                               [icon]="'far fa-file-exclamation'"
                               [label]="'entfernte Logfiles'">
            </app-side-bar-link>

            <app-side-bar-link title="Abrechnung"
                               *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_ABRECHNUNG'])"
                               [link]="'abrechnung'"
                               [icon]="'far fa-money-bill'"
                               [label]="'Abrechnung'">
            </app-side-bar-link>

            <app-side-bar-group title="webfuel"
                                [label]="'webfuel'"
                                [groupOpen]="false"
                                [icon]="'fad fa-cloud'"
                                link="webfuel"
                                *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_WEBFUEL','ROLE_CLEARING'])"
            >
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_WEBFUEL'])"
                        [link]="'webfuel/remotebackup'"
                        [icon]="'far fa-box-archive'"
                        [label]="'Remote-Backup'">
                </app-side-bar-link>
                <hr *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_WEBFUEL'])"/>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_WEBFUEL'])"
                        [link]="'webfuel/tenants'"
                        [icon]="'far fa-user-tie'"
                        [label]="'Mandanten'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_WEBFUEL'])"
                        [link]="'webfuel/tracker'"
                        [icon]="'far fa-circle-location-arrow'"
                        [label]="'Fahrzeug-Tracker'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_WEBFUEL'])"
                        [link]="'webfuel/nozzle-readers'"
                        [icon]="'far fa-circle-location-arrow'"
                        [label]="'Nozzle-Reader'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_WEBFUEL'])"
                        [link]="'webfuel/terminal'"
                        [icon]="'me-icon me-icon-terminal'"
                        [label]="'Terminal'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_WEBFUEL'])"
                        [link]="'webfuel/applications'"
                        [icon]="'fad fa-cloud'"
                        [label]="'Anwendungen'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_WEBFUEL','ROLE_CLEARING'])"
                        [link]="'clearing/preise'"
                        [icon]="'far fa-money-bill'"
                        [label]="'Preisübermittlung (TS)'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_WEBFUEL'])"
                        [link]="'webfuel/imports'"
                        [icon]="'far fa-server'"
                        [label]="'Imports'">
                </app-side-bar-link>
            </app-side-bar-group>

            <app-side-bar-group title="Dokumentation"
                                [label]="'Dokumentation'"
                                [groupOpen]="false"
                                [icon]="'fad fa-books'"
                                link="dokumentation"
            >
                <app-side-bar-link
                        [link]="'dokumentation'"
                        [icon]="'far fa-book'"
                        [label]="'Startseite'">
                </app-side-bar-link>
                <app-side-bar-link
                        [link]="'dokumentation/inhaltsverzeichnis'"
                        [icon]="'far fa-book'"
                        [label]="'Inhaltsverzeichnis'">
                </app-side-bar-link>
                <app-side-bar-link
                        [link]="'dokumentation/markdown'"
                        [icon]="'fab fa-markdown'"
                        [label]="'Syntaxbeschreibung'">
                </app-side-bar-link>
                <app-side-bar-link
                        *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN'])"
                        [link]="'dokumentation/changelog'"
                        [icon]="'far fa-user-hard-hat'"
                        [label]="'Changelog'">
                </app-side-bar-link>
            </app-side-bar-group>

            <app-side-bar-link title="Mitschnitt"
                               *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_MITSCHNITT'])"
                               [link]="'mitschnitt'"
                               [icon]="'me-icon me-icon-mitschnitt'"
                               [label]="'Mitschnitt'">
            </app-side-bar-link>

            <app-side-bar-link title="Geräte"
                               *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_ROUTER'])"
                               [link]="'router'"
                               [icon]="'icofont icofont-wifi-router'"
                               [label]="'Geräte'">
            </app-side-bar-link>

            <app-side-bar-link title="Sonden"
                               *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN','ROLE_SONDEN'])"
                               [link]="'sonden'"
                               [icon]="'far fa-eye'"
                               [label]="'Sonden'">
            </app-side-bar-link>

            <app-side-bar-link title="Benutzerverwaltung"
                               *ngIf="benutzerService.hasOneOfRoles(['ROLE_ADMIN'])"
                               [link]="'benutzer'"
                               [icon]="'fa-fw icofont icofont-user'"
                               [label]="'Benutzerverwaltung'">
            </app-side-bar-link>


        </div>
    </div>
</div>
