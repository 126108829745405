import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields,
  METool
} from '../../../maennl-commons';
import { IRBTerminal } from './irbterminal';
import { RBCustomer } from './rbcustomer';

export class RBTerminal
  extends MEGenericEntity<IRBTerminal>
  implements IRBTerminal
{
  boxnummer: number = null;
  customer: number = null;
  customerPOJO: RBCustomer = null;
  hardware_version: number = null;
  id: number = null;
  ip: string = null;
  kundennummer: number = null;
  last_booking: Date = null;
  last_received: Date = null;
  num_bookings: number = null;
  port: number = null;
  terminal_addr: number = null;
  display_text: string = null;

  constructor() {
    super();
  }

  static fromResult(
    entity: IRBTerminal,
    createIfNull: boolean = false
  ): RBTerminal {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new RBTerminal();
      }
      return null;
    }
    const instance = new RBTerminal();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addLongField('customer')
        .addLongField('boxnummer')
        .addLongField('kundennummer')
        .addLongField('terminal_addr')
        .addLongField('hardware:version')
        .addTextField('display_text')
        .addTextField('ip')
        .addLongField('port')
        .addLongField('num_bookings')
        .addDateField('last_booking')
        .addDateField('last_received')
        .addConverterField(
          'customerPOJO',
          new MEGenericEntityConverterParams<RBCustomer>((value: any) => {
            return RBCustomer.fromResult(value);
          })
        ),
      (e: RBTerminal) => {
        if (!METool.isEmpty(e.ip)) {
          e.ip = ('' + e.ip).replace('/32', '');
        }
      }
    );
    return instance;
  }
}
