import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clearing',
  templateUrl: './clearing.component.html',
  styleUrls: ['./clearing.component.scss']
})
export class ClearingComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
