import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { MEDataTableLocalizedCellRenderer } from '../../maennl-commons/data-table/renderer';
import { MEDataTableColumnComponent } from '../../maennl-commons/data-table/columns';
import { MEDataTableRow } from '../../maennl-commons/data-table/rows';

@Component({
  selector: 'app-remote-log-params-renderer',
  template: `
    <div *ngIf="isUHFLesung()">
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-25  small"><em>Kunde</em></div>
        <div class="w-25  small"><em>Fahrzeug</em></div>
        <div class="w-25  small "><em>MAC</em></div>
        <div class="w-25  small text-end"><em>Spannung</em></div>
      </div>
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-25">{{ p('kunde') }}</div>
        <div class="w-25">
          {{ p('fahrzeug') }}
          <button
            class="btn btn-sm"
            *ngIf="showfilter()"
            (click)="setFilter('fahrzeug', p('fahrzeug'))"
            title="Nach {{ p('fahrzeug') }} filtern"
          >
            <i class="far fa-filter"></i>
          </button>
        </div>
        <div class="w-25">
          {{ p('mac') }}
          <button
            class="btn btn-sm"
            *ngIf="showfilter()"
            (click)="setFilter('mac', p('mac'))"
            title="Nach {{ p('mac') }} filtern"
          >
            <i class="far fa-filter"></i>
          </button>
        </div>
        <div class="w-25 text-end" [ngClass]="spannungClasses(p('spannung'))">
          {{ fNum(p('spannung') / 100, '1.2-2') }} V
        </div>
      </div>
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-20  small"><em>Kennzeichen</em></div>
        <div class="w-20  small"><em>Boxkennung</em></div>
        <div class="w-20  small"><em>km-Stand</em></div>
        <div class="w-20  small"><em>wh-Stand</em></div>
        <div class="w-20  small"><em>#Lesung</em></div>
      </div>
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-20">
          {{ p('kennzeichen') }}
          <button
            class="btn btn-sm"
            *ngIf="showfilter()"
            (click)="setFilter('kennzeichen', p('kennzeichen'))"
            title="Nach {{ p('kennzeichen') }} filtern"
          >
            <i class="far fa-filter"></i>
          </button>
        </div>
        <div class="w-20">{{ p('kennung') }}</div>
        <div class="w-20 ">{{ fNum(p('km'), '1.0-0') }}</div>
        <div class="w-20 ">{{ fNum(p('wh'), '1.0-0') }}</div>
        <div class="w-20 ">{{ fNum(p('lesung'), '1.0-0') }}</div>
      </div>
    </div>
    <div *ngIf="isErrorMessage()">
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-60  small"><em>Details</em></div>
        <div class="w-20  small "><em>MAC</em></div>
        <div class="w-20  small text-end"><em>Spannung</em></div>
      </div>
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-60">
          {{ p('fahrzeug') }}
          <button
            class="btn btn-sm"
            *ngIf="showfilter()"
            (click)="setFilter('fahrzeug', p('fahrzeug'))"
            title="Nach {{ p('fahrzeug') }} filtern"
          >
            <i class="far fa-filter"></i>
          </button>
        </div>
        <div class="w-20">
          {{ p('mac') }}
          <button
            class="btn btn-sm"
            *ngIf="showfilter()"
            (click)="setFilter('mac', p('mac'))"
            title="Nach {{ p('mac') }} filtern"
          >
            <i class="far fa-filter"></i>
          </button>
        </div>
        <div class="w-20 text-end" [ngClass]="spannungClasses(p('spannung'))">
          {{ fNum(p('spannung') / 100, '1.2-2') }} V
        </div>
      </div>
    </div>
    <div *ngIf="isFreigabe()">
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-40  small"><em>Säule</em></div>
        <div class="w-40  small"><em>Zapfpunkt</em></div>
        <div class="w-20  small "><em></em></div>
      </div>
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-40">{{ p('saeule') }}</div>
        <div class="w-40">{{ p('zapfpunkt') }}</div>
        <div class="w-20"></div>
      </div>
    </div>
    <div *ngIf="isFZErkennung()">
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-25  small"><em>Kunde</em></div>
        <div class="w-25  small"><em>Fahrzeug</em></div>
        <div class="w-25  small "><em>km-Stand</em></div>
        <div class="w-25  small"><em>Kennzeichen</em></div>
      </div>
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-25">{{ p('kunde') }}</div>
        <div class="w-25">
          {{ p('fahrzeug') }}
          <button
            class="btn btn-sm"
            *ngIf="showfilter()"
            (click)="setFilter('fahrzeug', p('fahrzeug'))"
            title="Nach {{ p('fahrzeug') }} filtern"
          >
            <i class="far fa-filter"></i>
          </button>
        </div>
        <div class="w-25 ">{{ fNum(p('km'), '1-0.0') }}</div>
        <div class="w-25">
          {{ p('kennzeichen') }}
          <button
            class="btn btn-sm"
            *ngIf="showfilter()"
            (click)="setFilter('kennzeichen', p('kennzeichen'))"
            title="Nach {{ p('kennzeichen') }} filtern"
          >
            <i class="far fa-filter"></i>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-40  small"><em>VIN</em></div>
        <div class="w-40  small"><em>OHC</em></div>
        <div class="w-20  small "><em></em></div>
      </div>
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-40">
          {{ p('vin') }}
          <button
            class="btn btn-sm"
            *ngIf="showfilter()"
            (click)="setFilter('vin', p('vin'))"
            title="Nach {{ p('vin') }} filtern"
          >
            <i class="far fa-filter"></i>
          </button>
        </div>
        <div class="w-40">{{ p('ohc') }}</div>
        <div class="w-20"></div>
      </div>
    </div>
    <div *ngIf="isModulStart()">
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-100  small"><em>Version</em></div>
      </div>
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-100">{{ p('version') }}</div>
      </div>
    </div>
    <div *ngIf="isGeneralLogline()">
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-100  small"><em>Eintrag</em></div>
      </div>
      <div class="d-flex justify-content-between text-nowrap">
        <div class="w-100">{{ p('line') }}</div>
      </div>
    </div>
  `,
  styles: [
    `
      .d-flex > div {
        padding: 3px;
      }

      .btn-sm {
        font-size: 7pt;
        padding: 0;
        margin: 0;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RemoteLogParamsRendererComponent extends MEDataTableLocalizedCellRenderer {
  public cell: any = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<any> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }

  isUHFLesung() {
    if (
      this.row !== null &&
      this.row !== undefined &&
      this.row.data !== null &&
      this.row.data !== undefined
    ) {
      return this.row.data.type === 1;
    }
    return false;
  }

  isErrorMessage() {
    if (
      this.row !== null &&
      this.row !== undefined &&
      this.row.data !== null &&
      this.row.data !== undefined
    ) {
      return this.row.data.type === 254;
    }
    return false;
  }

  isFZErkennung() {
    if (
      this.row !== null &&
      this.row !== undefined &&
      this.row.data !== null &&
      this.row.data !== undefined
    ) {
      return this.row.data.type === 4;
    }
    return false;
  }

  isFreigabe() {
    if (
      this.row !== null &&
      this.row !== undefined &&
      this.row.data !== null &&
      this.row.data !== undefined
    ) {
      return this.row.data.type === 2;
    }
    return false;
  }

  isModulStart() {
    if (
      this.row !== null &&
      this.row !== undefined &&
      this.row.data !== null &&
      this.row.data !== undefined
    ) {
      return this.row.data.type === 6;
    }
    return false;
  }

  isGeneralLogline() {
    if (
      this.row !== null &&
      this.row !== undefined &&
      this.row.data !== null &&
      this.row.data !== undefined
    ) {
      return this.row.data.type === 7;
    }
    return false;
  }

  p(f: string): any {
    if (this.cell !== null && this.cell !== undefined) {
      return this.cell[f];
    }
    return null;
  }

  spannungClasses(s: string) {
    if (parseFloat(s) < 300) {
      return 'bg-danger text-warning font-weight-bold';
    }
    if (parseFloat(s) < 320) {
      return 'bg-warning';
    }
  }

  showfilter(): boolean {
    if (this.column === null || this.column === undefined) {
      return false;
    }
    return this.column.showFilterButton;
  }

  setFilter(field: string, value: any) {
    this.column.sendButtonClicked('remote-log-params-renderer', 'filter', {
      field: field,
      value: value
    });
  }
}
