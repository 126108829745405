<div
  class="d-flex align-items-start justify-content-between"
  *ngIf="liste !== null && liste !== undefined">
  <div class="flex-fill groups-table">
    <me-data-table
      #gruppenTable
      [rowActionsAsButtons]="true"
      [tableActionsAsButtons]="true"
      [data]="liste.asDataTableRows()"
      [selectable]="true"
      [singleSelect]="true"
      [hideCheckboxes]="true"
      actionsicon="icofont icofont-navigation-menu">
      <me-data-table-column
        [label]="'ID'"
        [field]="'id'"></me-data-table-column>
      <me-data-table-column
        [label]="'Code'"
        [field]="'code'"></me-data-table-column>
      <me-data-table-column
        [label]="'Bezeichnung'"
        [field]="'name'"></me-data-table-column>
      <me-data-table-column
        [label]="'Beschreibung'"
        [field]="'description'"></me-data-table-column>

      <me-data-table-action
        label="Eintrag hinzufügen"
        [icon]="'far fa-plus'"
        (perform)="addEntry()"></me-data-table-action>

      <me-data-table-rowaction
        (perform)="editEntry($event)"
        icon="far fa-pencil"
        label="Bearbeiten"
        title="Eintrag bearbeiten"></me-data-table-rowaction>

      <me-data-table-rowaction
        (perform)="removeEntry($event)"
        icon="far fa-trash"
        label="Löschen"
        title="Eintrag löschen"></me-data-table-rowaction>

      <me-data-table-table-extra-header>
        <me-pagination
          [total]="liste.total"
          [page]="liste.page"
          [perpage]="liste.size"
          (pageChange)="liste.setPage($event)"
          (pageCountChange)="liste.processPageCountChangedEvent($event)"
          (queryChange)="liste.processQueryChangedEvent($event)"
          settingsid="netzwerk-addresses"
          [settingsService]="benutzerService"
          [showSearch]="true"></me-pagination>
      </me-data-table-table-extra-header>
    </me-data-table>
  </div>
</div>
