import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { METabCardComponent, METool } from '../../maennl-commons';
import { TabVehicleTrackerComponent } from './tab-vehicle-tracker/tab-vehicle-tracker.component';
import { TabTrackerSMSCommandsComponent } from './tab-tracker-smscommands/tab-tracker-smscommands.component';

type TTabType =
  | null
  | TabVehicleTrackerComponent
  | TabTrackerSMSCommandsComponent;

@Component({
  selector: 'app-vehicle-tracker',
  templateUrl: './vehicle-tracker.component.html',
  styleUrls: ['./vehicle-tracker.component.scss']
})
export class VehicleTrackerComponent implements OnInit, AfterViewInit {
  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;
  @ViewChild('trackerTab', { static: false }) ka: TabVehicleTrackerComponent;

  private _currentTab: TTabType = null;

  public get currentTab(): TTabType {
    return this._currentTab;
  }

  public set currentTab(value: TTabType) {
    if (this._currentTab !== value) {
      if (!METool.isNullOrUndefined(this._currentTab)) {
        this._currentTab.onDeactivate();
      }
      this._currentTab = value;
      if (!METool.isNullOrUndefined(this._currentTab)) {
        this._currentTab.onActivate();
      }
    }
  }

  ngAfterViewInit(): void {
    this.currentTab = this.ka;
  }

  constructor() {}

  ngOnInit() {}

  activateTab(s: string) {
    const found = false;

    if (!found) {
      console.log(s);
    }
  }

  deactivateTab(s: string) {
    const found = false;

    if (!found) {
      console.log(s);
    }
  }

  public activate(tab: TTabType) {
    console.log(tab);
    this.currentTab = tab;
  }
}
