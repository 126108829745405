import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { AbrechnungVaroRechnungsserver } from './abrechnung-varo-rechnungsserver';
import { IAbrechnungVaroRechnungsserver } from './iabrechnung-varo-rechnungsserver';
import { SimpleAbrechnungVaroRechnungsserverFilter } from './simple-abrechnung-varo-rechnungsserver-filter';

export class AbrechnungVaroRechnungsserverList extends MERemoteList<AbrechnungVaroRechnungsserver> {
  __classname = 'AbrechnungVaroRechnungsserverList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IAbrechnungVaroRechnungsserver>
  ): AbrechnungVaroRechnungsserverList {
    const newList = new AbrechnungVaroRechnungsserverList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleAbrechnungVaroRechnungsserverFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'periode',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return AbrechnungVaroRechnungsserver.fromResult(entity);
  }
}
