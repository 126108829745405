import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import {
  MELocalizedComponent,
  MERichError,
  MESubscriptionHelper,
  METool,
} from '../../../maennl-commons';
import { NozzleReader } from '../commons/nozzle-reader';
import { NozzleReaderList } from '../commons/nozzle-reader.list';
import { NozzleReaderService } from '../commons/nozzle-reader.service';

@Component({
  selector: 'app-tab-nozzle-readers',
  templateUrl: './tab-nozzle-readers.component.html',
  styleUrls: ['./tab-nozzle-readers.component.scss'],
})
export class TabNozzleReadersComponent
  extends MELocalizedComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  liste = new NozzleReaderList(false, 400);

  constructor(
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    public benutzerService: BenutzerService,
    public nozzleReaderService: NozzleReaderService,
    public modalService: NgbModal
  ) {
    super();
  }

  private _tabActive = false;

  public get tabActive(): boolean {
    return this._tabActive;
  }

  @Input()
  public set tabActive(value: boolean) {
    if (this._tabActive !== value) {
      this._tabActive = value;
      this.cd.markForCheck();
      if (this._tabActive) {
        this.liste.start();
      }
    }
  }

  ngOnInit(): void {
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe((list: NozzleReaderList) => {
        if (this.tabActive) {
          this.nozzleReaderService
            .list(
              list.size,
              list.calcOffset(),
              list.order,
              list.simpleFilter,
              list.getQuery()
            )
            .subscribe({
              next: l => {
                list.populateFromListResult(l);
                this.cd.markForCheck();
              },
              error: e => {
                console.log(e);
                MERichError.throw(
                  'Fehler beim Datenabruf',
                  'Die Liste der Nozzle-Reader konnte nicht abgerufen werden.'
                );
              },
            });
        }
      })
    );
  }

  onActivate() {
    this.tabActive = true;
    console.log('activated');
  }

  onDeactivate() {
    this.tabActive = false;
    console.log('deactivated');
  }

  public ngAfterViewInit(): void {}

  public ngOnDestroy(): void {
    this.liste.release();
    MESubscriptionHelper.release(this);
  }

  typed(n: any): NozzleReader[] {
    if (METool.isNullOrUndefined(n)) {
      return [];
    }
    return [n as NozzleReader];
  }
}
