import { MEDataTableSortChanged } from '../../maennl-commons/data-table/events';
import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { IRouterStatus } from './irouter-status';
import { RouterStatus } from './router-status';
import { SimpleRouterStatusFilter } from './simple-router-status-filter';

export class RouterStatusList extends MERemoteList<RouterStatus> {
  __classname = 'RouterStatusList';
  __instance = '';

  static fromListResult(res: IMEListResult<IRouterStatus>): RouterStatusList {
    const newList = new RouterStatusList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleRouterStatusFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'zeitpunkt',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return RouterStatus.fromResult(entity);
  }
}
