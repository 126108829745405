import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IPeilung } from './ipeilung';
import { ISonde } from './isonde';
import { Sonde } from './sonde';

export class Peilung extends MEGenericEntity<IPeilung> implements IPeilung {
  __classname = 'Peilung';

  id = 0;
  sonde: Sonde = null;
  errorCode1 = 0;
  errorCode2 = 0;
  stand1 = 0;
  stand2 = 0;
  temperaturErrorCode = 0;
  temperatur = 0;
  received: Date = null;
  zeitpunkt: Date = null;
  temperaturDetails: number[] = [];

  get cstand1() {
    if (
      this.sonde !== null &&
      this.sonde !== undefined &&
      this.sonde.inverseMode
    ) {
      return this.sonde.maxHoehe - this.stand1;
    }
    return this.stand1;
  }

  get cstand2() {
    if (
      this.sonde !== null &&
      this.sonde !== undefined &&
      !this.sonde.hasSensor2
    ) {
      return null;
    }
    if (
      this.sonde !== null &&
      this.sonde !== undefined &&
      this.sonde.inverseMode
    ) {
      return this.sonde.maxHoehe - this.stand2;
    }
    return this.stand2;
  }

  static fromResult(entity: IPeilung, createIfNull: boolean = false): Peilung {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Peilung();
      }
      return null;
    }

    const instance = new Peilung();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addConverterField(
          'sonde',
          new MEGenericEntityConverterParams<Sonde>((value: any) => {
            return Sonde.fromResult(value);
          })
        )
        .addLongField('errorCode1')
        .addLongField('errorCode2')
        .addDecimalField('stand1')
        .addDecimalField('stand2')
        .addLongField('temperaturErrorCode')
        .addDecimalField('temperatur')
        .addDateField('received')
        .addDateField('zeitpunkt')
        .addDecimalsField('temperaturDetails')
    );
    return instance;
  }

  constructor() {
    super();
  }
}
