import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Tenant} from "../../../commons/tenant";

@Component({
    selector: 'app-tab-tenants-editor-infos',
    templateUrl: './tab-tenants-editor-infos.component.html',
    styleUrls: ['./tab-tenants-editor-infos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabTenantsEditorInfosComponent
    implements OnInit,OnDestroy {
    @Input()entity: Tenant=null;
    @Input()isAdd: boolean=false;

    private _tabActive = false;

    constructor(
        public cd:ChangeDetectorRef
    ) {
    }

    public get tabActive():boolean {
        return this._tabActive;
    }

    @Input()
    public set tabActive(value: boolean){
        if(this._tabActive!==value){
            this._tabActive = value;
            this.cd.markForCheck();
        }
    }

    onActivate(){
        this.tabActive=true;
    }

    onDeactivate() {
        this.tabActive=false;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }
}