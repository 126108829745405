import { IMEListResult, MERemoteList } from '../../maennl-commons';
import { ISim } from './isim';
import { Sim } from './sim';
import { SimpleSimFilter } from './simple-sim-filter';

export class SimList extends MERemoteList<Sim> {
  __classname = 'SimList';
  __instance = '';

  static fromListResult(res: IMEListResult<ISim>): SimList {
    const newList = new SimList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleSimFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'label',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }
}
