import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Applications} from "../../../commons/applications.class";

@Component({
    selector: 'app-tab-applications-editor-infos',
    templateUrl: './tab-applications-editor-infos.component.html',
    styleUrls: ['./tab-applications-editor-infos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabApplicationsEditorInfosComponent
    implements OnInit, OnDestroy {
    @Input()entity: Applications = null;
    @Input()isAdd: boolean = false;

    private _tabActive = false;

    constructor(
        public cd: ChangeDetectorRef
    ) {}

    public get tabActive(): boolean {
        return this._tabActive;
    }

    @Input()
    public set tabActive(value: boolean) {
        if(this._tabActive!==value) {
            this._tabActive = value;
            this.cd.markForCheck();
        }
    }

    onActivate(){
        this.tabActive=true;
    }

    onDeactivate() {
        this.tabActive=false;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

}