<me-data-table
        *ngIf="liste!==null && liste!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="liste.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        (onSortChanged)="liste.processSortChangedEvent($event)"
        #clientsTable
>
    <me-data-table-column [label]="'Code'"
                          [field]="'code'"
                          [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column [label]="'Bezeichnung'"
                          [field]="'display_text'"
                          [sortable]="true"
    ></me-data-table-column>

    <me-data-table-table-extra-header>
        <me-pagination
                [total]="liste.total"
                [page]="liste.page"
                [perpage]="liste.size"
                (pageChange)="liste.setPage($event)"
                (pageCountChange)="liste.processPageCountChangedEvent($event)"
                (queryChange)="liste.processQueryChangedEvent($event)"
                settingsid="webfuel-remotebackup-plans"
                [settingsService]="benutzerService"
                [showSearch]="true"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
