import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { MELocalizedComponent } from './maennl-commons/localized-component';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app',
  template: `
    <app-navigation></app-navigation>

    <main id="wrapper">
      <app-sidebar id="sidebar-wrapper" *appIsLoggedIn></app-sidebar>

      <div id="page-content-wrapper">
        <div toastContainer></div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </main>
  `,
  styles: [
    `
      :host main {
        margin-top: 42px;
        display: flex;
      }

      #wrapper {
        padding-left: 0;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
      }

      #wrapper.toggled {
        padding-left: 250px;
      }

      #sidebar-wrapper {
        z-index: 1000;
        position: fixed;
        left: 250px;
        width: 0;
        height: 100%;
        margin-left: -250px;
        overflow-y: auto;
        background: #fefefe;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
      }

      #wrapper.toggled #sidebar-wrapper {
        width: 250px;
      }

      #page-content-wrapper {
        width: 100%;
        position: absolute;
        padding: 15px;
      }

      #wrapper.toggled #page-content-wrapper {
        position: absolute;
        margin-right: -250px;
      }

      @media (min-width: 768px) {
        #wrapper {
          padding-left: 250px;
        }

        #wrapper.toggled {
          padding-left: 0;
        }

        #sidebar-wrapper {
          width: 250px;
        }

        #wrapper.toggled #sidebar-wrapper {
          width: 0;
        }

        #page-content-wrapper {
          padding: 20px;
          position: relative;
        }

        #wrapper.toggled #page-content-wrapper {
          position: relative;
          margin-right: 0;
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends MELocalizedComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: false })
  toastContainer: ToastContainerDirective;

  constructor(
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.toastr.overlayContainer = this.toastContainer;
    this.cd.markForCheck();
  }
}
