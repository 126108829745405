<div
        class="d-flex align-items-start justify-content-between"
        *ngIf="benutzer!==null && benutzer!==undefined">
    <div class="flex-fill address-table">
        <me-data-table
            #benutzerTable

            [rowActionsAsButtons]="true"
            [tableActionsAsButtons]="true"
            [data]="benutzer.asDataTableRows()"
            [selectable]="true"
            [singleSelect]="true"
            [extraRowClasses]="rowClassGetter"
            [hideCheckboxes]="true"
            actionsicon="icofont icofont-navigation-menu"
            (rowSelected)="onRowSelected($event)"
        >
            <me-data-table-column [label]="'ID'" [field]="'id'"></me-data-table-column>
            <me-data-table-column [label]="'Benutzerkennung'" [field]="'username'"></me-data-table-column>
            <me-data-table-column [label]="'Name'" [field]="'fullname'"></me-data-table-column>
            <me-data-table-column [label]="'gesperrt?'" [field]="'enabled'"
                                  [type]="'notBoolean'"></me-data-table-column>
            <me-data-table-column [label]="'letzter Login'" [field]="'lastLogin'" type="date"
                                  [cfg]="{format:'YYYY-MM-DD HH:mm'}"></me-data-table-column>
            <me-data-table-column [label]="'zuletzt gesehen'" [field]="'lastSeen'" type="date"
                                  [cfg]="{format:'YYYY-MM-DD HH:mm'}"></me-data-table-column>
            <me-data-table-rowaction
                    [title]="'Benutzer bearbeiten'"
                    [label]="'Bearbeiten'"
                    (perform)="editUser($event)"
                    [icon]="'far fa-edit'"
            ></me-data-table-rowaction>

            <me-data-table-table-extra-header>
                <me-pagination
                        [total]="benutzer.total"
                        [page]="benutzer.page"
                        [perpage]="benutzer.size"
                        (pageChange)="benutzer.setPage($event)"
                        (pageCountChange)="benutzer.processPageCountChangedEvent($event)"
                        settingsid="benutzer"
                        [settingsService]="benutzerService"
                ></me-pagination>
            </me-data-table-table-extra-header>
        </me-data-table>
    </div>
    <ng-container *ngIf="benutzerTable.hasSelectedRows()">
        <div class="ml-2 ms-2 ps-2 pl-2" style="min-width: 450px">
            <me-tab-card #detailTabs>
                <me-pane id="subtab-authorities" title="Rechte">
                    <div
                            *ngFor="let ath of authorities.data"
                            class="d-flex justify-content-between align-items-center">
                        <div>
                            <ng-container *ngIf="current.is_in_authority(ath.authority)">
                                <i
                                        class="fa fa-fw fa-check-square-o has-cursor-pointer"
                                        title="aus der Gruppe entfernen"
                                        (click)="remove_from_authority(ath)"></i>
                            </ng-container>
                            <ng-container *ngIf="!current.is_in_authority(ath.authority)">
                                <i
                                        class="fa fa-fw fa-square-o has-cursor-pointer"
                                        title="zur Gruppe hinzufügen"
                                        (click)="add_to_authority(ath)"></i>
                            </ng-container>
                        </div>
                        <div class="flex-fill ms-2 ml-2">
                            {{ ath.authority }}
                        </div>
                    </div>
                </me-pane>
            </me-tab-card>
        </div>
    </ng-container>
</div>