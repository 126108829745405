import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'meremovezerodecimalplaces' })
export class MERemoveZeroDecimalPlacesPipe implements PipeTransform {
  public transform(value: string): string {
    const r = /^(.+)[,.]0+$/i;
    const result = r.exec(value);
    if (result != null) {
      return result[1];
    }
    return value;
  }
}
