import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { MELocalizedComponent } from '../localized-component';
import { MEPubSubService, MESubscriptionHelper } from '../services';
import { MEGenericModalComponent } from '../megeneric-modal';

@Component({
  selector: 'me-confirm-dialog',
  template: `
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" [innerHTML]="message"></div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> {{ cancellabel }}
      </button>
      <button type="button" class="btn btn-primary" (click)="ok()">
        <i class="{{ okIcon }}"></i>
        {{ oklabel }}
      </button>
    </div>
  `
})
export class MEConfirmDialogComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy
{
  __classname = 'MEConfirmDialogComponent';
  __instance = '';

  @Input() public title = '';
  @Input() public message = '';
  @Input() public oklabel = '';
  @Input() public cancellabel = '';
  @Input() public okIcon = 'icofont icofont-check';

  static display(
    modalService: NgbModal,
    title: string,
    message: string,
    oklabel: string = 'OK',
    okIcon: string = 'icofont icofont-check',
    size: any = MEGenericModalComponent.SIZE_MEDIUM
  ): Promise<any> {
    const o: NgbModalOptions = {
      size:
        size === MEGenericModalComponent.SIZE_SMALL
          ? 'sm'
          : size === MEGenericModalComponent.SIZE_LARGE
          ? 'lg'
          : undefined,
      backdrop: 'static',
      keyboard: false
    };
    const r: NgbModalRef = modalService.open(MEConfirmDialogComponent, o);
    (r.componentInstance as MEConfirmDialogComponent).title = title;
    (r.componentInstance as MEConfirmDialogComponent).message = message;
    (r.componentInstance as MEConfirmDialogComponent).oklabel = oklabel;
    (r.componentInstance as MEConfirmDialogComponent).okIcon = okIcon;

    return r.result;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService
  ) {
    super();
    this.oklabel = this._('OK');
    this.cancellabel = this._('Abbrechen');
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.pubsub.loginError.subscribe(() => {
        this.cancel();
      }, undefined)
    );
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
  }

  cancel() {
    this.activeModal.dismiss('cancel');
  }

  ok() {
    this.activeModal.close('ok');
  }
}
