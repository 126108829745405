import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {
    MEConfirmDialogComponent,
    MELocalizedComponent,
    MERowActionParams,
    MESubscriptionHelper
} from "../../../maennl-commons";
import {TAJobsList} from "../commons/ta-jobs.list";
import {TAJobsService, tajobtyp} from "../commons/ta-jobs.service";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {TAJobs} from "../commons/ta-jobs.class";
import {TNullableNumber} from "../../../maennl-commons/tools/types";

@Component({
    selector: 'app-tab-ta-jobs',
    templateUrl: './tab-ta-jobs.component.html',
    styleUrls: ['./tab-ta-jobs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabTAJobsComponent
    extends MELocalizedComponent
    implements OnInit,OnDestroy,AfterViewInit{
    @Input() id ='';
    taJobsListe=new TAJobsList();

    private _tabActive = false;

    public get tabActive():boolean {
        return this._tabActive
    }

    @Input()
    public set tabActive(value:boolean) {
        if(this._tabActive!==value) {
            this._tabActive = value;
            this.cd.markForCheck();
            if(this._tabActive) {
                this.taJobsListe.start();
            }
        }
    }

    constructor(
        public cd:ChangeDetectorRef,
        public taJobsService: TAJobsService,
        public benutzerService: BenutzerService,
        public modalService: NgbModal,
        public toastr: ToastrService,
    ) {
        super();
    }

    public jobinfo(
        value: TNullableNumber
    ):string {
        if(value===null || value===undefined||value<0||value>7){
            return 'unbekannt';
        }
        const job=value;
        if (tajobtyp.has(job)) {
            return tajobtyp.get(job);
        }
        return 'unbekannt';
    }

    ngOnInit() {
        MESubscriptionHelper.add(
            this,
            this.taJobsListe.onUpdateRequired.subscribe(()=>{
                this.cd.markForCheck();
                if(this.taJobsListe.loading){
                    return;
                }
                this.taJobsListe.loading=true;
                this.taJobsService.list(
                    this.taJobsListe.size,
                    this.taJobsListe.calcOffset(),
                    this.taJobsListe.order,
                    this.taJobsListe.simpleFilter,
                    this.taJobsListe.getQuery()
                )
                    .subscribe({
                        next: l =>{
                            this.taJobsListe.doPopulateFromListResult(l);
                            this.taJobsListe.loading=false;
                            this.cd.markForCheck();
                        },
                        error: () =>{
                            this.taJobsListe.clear();
                            this.taJobsListe.loading=false;
                        }
                    });
                this.cd.markForCheck();
            })
        );
    }

    onActivate() {
        this.tabActive=true;
    }

    onDeactivate() {
        this.tabActive=false;
    }

    ngOnDestroy() {
        this.taJobsListe.release();
        MESubscriptionHelper.release(this);
    }

    ngAfterViewInit(){}

    removeTAJobs($event: MERowActionParams<TAJobs>){
        if(this.taJobsService.get($event.row.data.id)!==null&&this.taJobsService.get($event.row.data.id)!==undefined) {
            MEConfirmDialogComponent.display(
                this.modalService,
                "TA-Job Löschung bestätigen",
                "Soll der TA-Job("+$event.row.data.id+") wirklich unwiederruflich gelöscht werden?",
                'Ja, jetzt löschen',
                'icofont icofont-delete',
            ).then(() => {
                this.taJobsService.get($event.row.data.id).subscribe((t) => {
                    if(t.id!==null&&t.id!==undefined&&t.id>0) {
                        this.taJobsService.delete(t.id).subscribe((r) => {
                            if(r.success) {
                                this.toastr.info(
                                    'Der TA-Job wurde erfolgreich gelöscht.'
                                );
                                this.taJobsListe.start();
                            }
                        });
                    }
                });
            }, () => {
                this.toastr.error(
                    'Die Löschung des TA-Jobs wurde abgebrochen.'
                )
            })
        }
    }
}