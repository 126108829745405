import { Component, Input } from '@angular/core';
import { Check } from '../../common/check';

@Component({
  selector: 'app-check-name-display',
  template: `
    {{ getInfo() }}<br />
    <small>{{ getName() }}</small>
  `
})
export class CheckNameDisplayComponent {
  @Input() check: Check = null;

  constructor() {
    this.check = null;
  }

  getName() {
    if (this.check === null || this.check === undefined) {
      return '';
    }
    if (
      this.check.serviceType === null ||
      this.check.serviceType === undefined
    ) {
      return '';
    }
    if (
      this.check.serviceType.code === 'systemd' ||
      this.check.serviceType.code === 'service'
    ) {
      if (this.check.params === null || this.check.params === undefined) {
        return '';
      }
      if (this.check.params.hasOwnProperty('service')) {
        return this.check.params.service;
      }
      return '';
    }
    if (this.check.serviceType.code === 'docker') {
      if (this.check.params === null || this.check.params === undefined) {
        return '';
      }
      if (this.check.params.hasOwnProperty('container')) {
        return this.check.params.container;
      }
      return '';
    }
    return '';
  }

  getInfo() {
    if (this.check === null || this.check === undefined) {
      return '';
    }
    return this.check.info;
  }
}
