import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IMachine } from './imachine';
import { IMachineType } from './imachine-type';
import { MachineType } from './machine-type';

export class Machine extends MEGenericEntity<IMachine> implements IMachine {
  id = 0;
  code = '';
  bezeichnung = '';
  loginName = '';
  loginPw = '';
  host = '';
  machineType: IMachineType = null;
  hasDatabase = false;
  hasDocker = false;

  static fromResult(entity: IMachine, createIfNull: boolean = false): Machine {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Machine();
      }
      return null;
    }

    const instance = new Machine();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addConverterField(
          'machineType',
          new MEGenericEntityConverterParams<MachineType>((value: any) => {
            return MachineType.fromResult(value);
          })
        )
        .addTextField('code')
        .addTextField('bezeichnung')
        .addTextField('loginName')
        .addTextField('loginPw')
        .addTextField('host')
        .addBooleanField('hasDocker')
        .addBooleanField('hasDatabase')
    );
    return instance;
  }

  constructor(id = 0) {
    super();
    this.id = id;
  }
}
