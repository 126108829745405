import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { IMachine } from './imachine';
import { Machine } from './machine';
import { SimpleMachineFilter } from './simple-machine-filter';

export class MachineList extends MERemoteList<Machine> {
  __classname = 'MachineList';
  __instance = '';

  static fromListResult(res: IMEListResult<IMachine>): MachineList {
    const newList = new MachineList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleMachineFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'info',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return Machine.fromResult(entity);
  }
}
