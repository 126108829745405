import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { MEConverterTool } from '../../maennl-commons/tools';
import { IPeilung } from './ipeilung';
import { Peilung } from './peilung';
import { SimplePeilungFilter } from './simple-peilung-filter';

export class PeilungList extends MERemoteList<Peilung> {
  __classname = 'PeilungList';
  __instance = '';

  static fromListResult(res: IMEListResult<IPeilung>): PeilungList {
    const newList = new PeilungList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimplePeilungFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'zeitpunkt',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return Peilung.fromResult(entity);
  }

  doConvertFilterField(k: string, input: any): any {
    if (k === 'zeitpunkt') {
      if (Array.isArray(input)) {
        const r: Date[] = [];
        (input as Array<any>).forEach((item) => {
          if (item === null || item === undefined) {
            r.push(null);
          } else {
            r.push(MEConverterTool.toDate(item));
          }
        });
        return r;
      }
      return null;
    }
    return super.doConvertFilterField(k, input);
  }
}
