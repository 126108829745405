<ng-template #ahIcon>
    <fa-icon [icon]="['fas', 'user-tie']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="webfuel Mandanten"
></me-area-heading>

<me-tab-card
    #rootTabCard
>

    <me-pane id="tab-tenants"
             title="Mandanten"
             (activated)="activate(tenantsTab)"
    >
        <app-tab-tenants #tenantsTab></app-tab-tenants>
    </me-pane>
    <me-pane id="tab-core-modules"
             title="Module"
             (activated)="activate(cmTab)">
        <app-tab-core-modules #cmTab></app-tab-core-modules>
    </me-pane>
    <me-pane id="tab-tenant-hosts"
             title="Hosts"
             (activated)="activate(thTab)">
        <app-tab-tenant-hosts #thTab></app-tab-tenant-hosts>
    </me-pane>
</me-tab-card>
