<ng-container
  *ngIf="
    entity !== undefined &&
    entity !== null &&
    entity.trace_data_obj !== undefined &&
    entity.trace_data_obj !== null
  ">
  <ng-container [ngSwitch]="entity.trace_data_obj.cmd">
    <ng-container *ngSwitchCase="1">
      <div>
        <span class="badge bg-info text-dark">Records</span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <span class="badge bg-info text-dark">DeviceConfigResponse</span>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <span class="badge bg-info text-dark">DeviceVersionResponse</span>
    </ng-container>
    <ng-container *ngSwitchCase="7">
      <span class="badge bg-info text-dark">SMS Response</span>
    </ng-container>
    <ng-container *ngSwitchCase="68">
      <div>
        <span class="badge bg-info text-dark">ExtendedRecords</span>
      </div>
      <div
        class="d-flex align-items-stretch justify-content-start flex-wrap"
        *ngFor="let entry of entity.trace_data_obj.items">
        <ng-container
          *ngIf="entry.position !== undefined && entry.position !== null">
          <div class="extended-record">
            <div class="title">Position</div>
            <div class="content">
              <table>
                <tr>
                  <td class="fw-bold">Latitude:</td>
                  <td class="text-end">{{ entry.position.latitude }}</td>
                  <td class="fw-bold">Longitude:</td>
                  <td class="text-end">{{ entry.position.longitude }}</td>
                  <td class="fw-bold">Altitude:</td>
                  <td class="text-end">{{ entry.position.altitude }}</td>
                </tr>
                <tr>
                  <td class="fw-bold"># Satelliten:</td>
                  <td class="text-end">{{ entry.position.satellites }}</td>
                  <td class="fw-bold">Drehwinkel:</td>
                  <td class="text-end">{{ entry.position.angle }}</td>
                  <td class="fw-bold">Speed:</td>
                  <td class="text-end">{{ entry.position.speed }}</td>
                </tr>
                <tr>
                  <td class="fw-bold">Trip-ID (wf):</td>
                  <td class="text-end">
                    {{ entry.position.trip }}
                  </td>
                  <td class="fw-bold">ZP Aufzeichnung:</td>
                  <td colspan="3" class="text-end">
                    {{ entry.position.zp_utc }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            entry.extended_records !== undefined &&
            entry.extended_records !== null
          ">
          <div
            class="extended-record"
            *ngFor="let rec of entry.extended_records.data">
            <div class="title" title="Data-ID {{ rec.data_id }}">
              <ng-container [ngSwitch]="rec.data_id">
                <ng-container *ngSwitchCase="5"> Zündsignal</ng-container>
                <ng-container *ngSwitchCase="29"> Spannung</ng-container>
                <ng-container *ngSwitchCase="65"> km-Stand</ng-container>
                <ng-container *ngSwitchCase="580">
                  Betriebsstunden
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <ng-container [ngSwitch]="rec.type">
                    <ng-container *ngSwitchCase="'unknown_1b'"
                      >unbekannter 1-Byte-Wert {{ rec.data_id }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'unknown_2b'"
                      >unbekannter 2-Byte-Wert {{ rec.data_id }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'unknown_4b'"
                      >unbekannter 4-Byte-Wert {{ rec.data_id }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'unknown_8b'"
                      >unbekannter 8-Byte-Wert {{ rec.data_id }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      Unbekannt: {{ rec.type }} / Data-ID: {{ rec.data_id }}
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
            <div class="content">
              <ng-container [ngSwitch]="rec.data_id">
                <ng-container *ngSwitchCase="5">
                  <ng-container *ngIf="rec.enabled">erkannt</ng-container>
                  <ng-container *ngIf="!rec.enabled"
                    >nicht erkannt
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="29">
                  {{ fNum(rec.spannung, "1.0-2") }} V
                </ng-container>
                <ng-container *ngSwitchCase="65">
                  {{ fNum(rec.wert / 1000, "1.0-3") }} km
                </ng-container>
                <ng-container *ngSwitchCase="580">
                  {{ fNum(rec.wert, "1.0-0") }} sec <br />
                  {{ fNum(rec.wert / 3600, "1.0-3") }} h <br />
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ rec.value | json }}
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="100">
      <span class="badge bg-info text-dark">Ack</span>
    </ng-container>
    <ng-container *ngSwitchCase="102">
      <span class="badge bg-info text-dark">DeviceConfigData</span>
    </ng-container>
    <ng-container *ngSwitchCase="103">
      <span class="badge bg-info text-dark">DeviceVersionRequest</span>
    </ng-container>
    <ng-container *ngSwitchCase="108">
      <span class="badge bg-info text-dark">SMS Request</span>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <span class="badge bg-info text-dark"
        >Unbekannter Befehl: {{ entity.trace_data_obj.cmd }}</span
      >
    </ng-container>
  </ng-container>
</ng-container>
