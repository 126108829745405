import { IMEListResult, MERemoteList } from '../../../maennl-commons';
import { BlocklistV4 } from './blocklist-v4.class';
import { BlocklistV4Filter } from './blocklist-v4.filter';
import { BlocklistV4Impl } from './blocklist-v4.impl';
import { IBlocklistV4 } from './blocklist-v4.interface';

export class BlocklistV4List extends MERemoteList<BlocklistV4> {
  __classname: string = 'BlocklistV4List';
  __instance = '';

  static fromListResult(res: IMEListResult<IBlocklistV4>): BlocklistV4List {
    const newList = new BlocklistV4List();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new BlocklistV4Filter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'ip',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return BlocklistV4Impl.fromResult(entity);
  }
}
