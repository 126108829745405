import {
  Component,
  ComponentFactoryResolver,
  Injector,
  ViewContainerRef
} from '@angular/core';
import { MEDataTableCellRendererComponent } from '../../../maennl-commons/data-table/renderer';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';

@Component({
  selector: 'app-medtfile-system-space-renderer',
  template: `
    <app-file-system-space-display
      [total]="total"
      [free]="free"
    ></app-file-system-space-display>
  `,
  styleUrls: ['./medtfile-system-space-renderer.component.scss']
})
export class MEDTFileSystemSpaceRendererComponent extends MEDataTableCellRendererComponent {
  public cell: boolean = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<any> = null;

  total = 0;
  free = 0;

  constructor(
    public resolver: ComponentFactoryResolver,
    public _view: ViewContainerRef,
    public injector: Injector
  ) {
    super(resolver, _view);
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');

    if (this.row !== null && this.row !== undefined) {
      if (this.column.cfg !== null && this.column.cfg !== undefined) {
        if (
          this.column.cfg.totalColumn !== null &&
          this.column.cfg.totalColumn !== undefined
        ) {
          this.total = this.row.data[this.column.cfg.totalColumn];
        }

        if (
          this.column.cfg.freeColumn !== null &&
          this.column.cfg.freeColumn !== undefined
        ) {
          this.free = this.row.data[this.column.cfg.freeColumn];
        }
      }
    }
  }
}
