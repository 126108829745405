import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import {
  MEActionResponse,
  MEDataTableRow,
  MEDataTableRowSelected,
  MEGenericModalComponent,
  MELocalizedComponent,
  MERowActionParams,
  MESubscriptionHelper,
  TMEDTGetExtraRowClassesCallback,
  noop,
} from '../../../maennl-commons';
import { CheckPingEditorComponent } from '../check-ping-editor/check-ping-editor.component';
import { CheckPing } from '../commons/check-ping.class';
import { CheckPingList } from '../commons/check-ping.list';
import { CheckPingService } from '../commons/check-ping.service';
import { GruppeList } from '../commons/gruppe.list';
import { GruppeService } from '../commons/gruppe.service';
import { TGruppe } from '../commons/gruppe.types';
import { NetworkAddress } from '../commons/network-address.class';
import { NetworkAddressFilter } from '../commons/network-address.filter';
import { NetworkAddressImpl } from '../commons/network-address.impl';
import { NetworkAddressList } from '../commons/network-address.list';
import { NetworkAddressService } from '../commons/network-address.service';
import { TNullableNetworkAddress } from '../commons/network-address.types';
import { IpAddressEditorComponent } from '../ip-address-editor/ip-address-editor.component';

@Component({
  selector: 'app-tna-addresses',
  templateUrl: './tnaaddresses.component.html',
  styleUrls: ['./tnaaddresses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TNAAddressesComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy
{
  @Input() id = '';
  liste = new NetworkAddressList();
  checks = new CheckPingList();
  gruppen = new GruppeList();

  constructor(
    public cd: ChangeDetectorRef,
    public service: NetworkAddressService,
    public cpService: CheckPingService,
    public grpService: GruppeService,
    public benutzerService: BenutzerService,
    public modalService: NgbModal,
    public toastr: ToastrService
  ) {
    super();
  }

  private _current: TNullableNetworkAddress = null;

  get current(): TNullableNetworkAddress {
    return this._current;
  }

  set current(value: TNullableNetworkAddress) {
    if (
      this._current !== value ||
      (this._current !== null &&
        value !== null &&
        this._current != undefined &&
        value !== undefined &&
        this.current.id !== value.id)
    ) {
      if (this.checks !== null && this.checks !== undefined) {
        this.checks.clear();
      }
      this._current = value;
      this.cd.markForCheck();
      if (this.checks !== null && this.checks !== undefined) {
        this.checks.start();
      }
    }
  }

  rowClassGetter: TMEDTGetExtraRowClassesCallback<NetworkAddress> = (
    row: MEDataTableRow<NetworkAddress>
  ) => {
    if (
      row !== null &&
      row !== undefined &&
      row.data !== null &&
      row.data !== undefined &&
      row.data.checks_successful !== null &&
      row.data.checks_successful !== undefined &&
      row.data.checks_num_total !== null &&
      row.data.checks_num_total !== undefined
    ) {
      if (!row.data.checks_successful && row.data.checks_num_total > 0) {
        return 'bg-danger text-light';
      }
    }
    return '';
  };

  rowClassGetter2: TMEDTGetExtraRowClassesCallback<CheckPing> = (
    row: MEDataTableRow<CheckPing>
  ) => {
    if (
      row !== null &&
      row !== undefined &&
      row.data !== null &&
      row.data !== undefined &&
      row.data.is_ok !== null &&
      row.data.is_ok !== undefined &&
      row.data.check_enabled !== null &&
      row.data.check_enabled !== undefined
    ) {
      if (!row.data.is_ok && row.data.check_enabled) {
        return 'bg-danger text-light';
      }
      if (!row.data.check_enabled) {
        return 'text-gray';
      }
    }
    return '';
  };

  ngOnInit(): void {
    this.cd.markForCheck();
    this.onActivate();
  }

  onActivate() {
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe(() => {
        this.cd.markForCheck();
        if (this.liste.loading) {
          return;
        }
        this.liste.loading = true;
        (this.liste.simpleFilter as NetworkAddressFilter).display_mode =
          'hosts';
        this.service
          .list(
            this.liste.size,
            this.liste.calcOffset(),
            this.liste.order,
            this.liste.simpleFilter,
            this.liste.getQuery()
          )
          .subscribe({
            next: l => {
              this.liste.doPopulateFromListResult(l);
              this.liste.loading = false;
              this.cd.markForCheck();
            },
            error: () => {
              this.liste.clear();
              this.liste.loading = false;
            },
          });
        this.cd.markForCheck();
      })
    );
    this.liste.start();

    MESubscriptionHelper.add(
      this,
      this.checks.onUpdateRequired.subscribe(() => {
        if (this.current === null || this.current === undefined) {
          this.checks.clear();
          this.cd.markForCheck();
          return;
        }
        if (this.checks.loading) {
          return;
        }
        this.checks.loading = true;
        console.log('Starte...');
        this.cpService
          .list(
            this.current.id,
            this.checks.size,
            this.checks.calcOffset(),
            this.checks.order,
            this.checks.simpleFilter,
            this.checks.getQuery()
          )
          .subscribe({
            next: l => {
              console.log('Laden abgeschlossen...');
              this.checks.loading = false;
              this.checks.doPopulateFromListResult(l);
              this.cd.markForCheck();
            },
            error: () => {
              this.checks.clear();
            },
          });
        this.cd.markForCheck();
      })
    );

    MESubscriptionHelper.add(
      this,
      this.gruppen.onUpdateRequired.subscribe(() => {
        if (this.gruppen.loading) {
          return;
        }
        this.gruppen.loading = true;
        this.gruppen.size = 99999;
        this.gruppen.offset = 0;
        this.grpService
          .list(
            this.gruppen.size,
            this.gruppen.calcOffset(),
            this.gruppen.order,
            this.gruppen.simpleFilter,
            this.gruppen.getQuery()
          )
          .subscribe({
            next: l => {
              this.gruppen.loading = false;
              this.gruppen.doPopulateFromListResult(l);
              this.cd.markForCheck();
            },
            error: () => {
              this.gruppen.clear();
            },
          });
        this.cd.markForCheck();
      })
    );
    this.gruppen.start();
    this.cd.markForCheck();
  }

  onDeactivate() {
    this.liste.disableAutoReload();
    this.checks.disableAutoReload();
  }

  ngOnDestroy(): void {
    this.liste.release();
    this.checks.release();
    this.gruppen.release();
    MESubscriptionHelper.release(this);

    super.ngOnDestroy();
  }

  addEntry() {
    IpAddressEditorComponent.open(
      this.modalService,
      IpAddressEditorComponent,
      null,
      MEGenericModalComponent.SIZE_MEDIUM,
      null
    ).then(() => {
      this.liste.start();
    }, noop);
  }

  editEntry($event: MERowActionParams<NetworkAddress>) {
    this.service.get($event.row.data.id).subscribe((e: NetworkAddress) => {
      if (e !== null && e !== undefined && e.id > 0) {
        IpAddressEditorComponent.open(
          this.modalService,
          IpAddressEditorComponent,
          e,
          MEGenericModalComponent.SIZE_MEDIUM,
          null
        ).then(() => {
          this.liste.start();
        }, noop);
      }
    });
  }

  removeEntry($event: MERowActionParams<NetworkAddress>) {
    this.service.get($event.row.data.id).subscribe((e: NetworkAddress) => {
      if (e.id !== null && e.id !== undefined && e.id > 0) {
        this.service.delete(e.id).subscribe(() => {
          this.liste.start();
        });
      }
    });
  }

  onRowSelected($event: MEDataTableRowSelected<NetworkAddress>) {
    if (
      $event === null ||
      $event === undefined ||
      $event.row === null ||
      $event.row === undefined ||
      $event.row.data === null ||
      $event.row.data === undefined
    ) {
      this.current = null;
      return;
    }
    this.current = NetworkAddressImpl.fromResult($event.row.data);
  }

  asChecks(row: MEDataTableRow<CheckPing>): CheckPing[] {
    if (
      row === null ||
      row === undefined ||
      row.data === null ||
      row.data === undefined
    ) {
      return [];
    }
    return [row.data];
  }

  addCheckEntry() {
    if (this.current === null || this.current === undefined) {
      return;
    }
    const c = new CheckPing();
    c.address = this.current.id;
    c.firewall = 1;
    c.id = 0;
    CheckPingEditorComponent.open(
      this.modalService,
      CheckPingEditorComponent,
      c,
      MEGenericModalComponent.SIZE_SMALL,
      null
    ).then(() => {
      this.checks.start();
    }, noop);
  }

  editCheckEntry($event: MERowActionParams<CheckPing>) {
    if (this.current === null || this.current === undefined) {
      return;
    }

    this.cpService
      .get(this.current.id, $event.row.data.id)
      .subscribe((c: CheckPing) => {
        CheckPingEditorComponent.open(
          this.modalService,
          CheckPingEditorComponent,
          c,
          MEGenericModalComponent.SIZE_SMALL,
          null
        ).then(() => {
          this.checks.start();
        }, noop);
      });
  }

  removeCheckEntry($event: MERowActionParams<CheckPing>) {
    if (this.current === null || this.current === undefined) {
      return;
    }

    this.cpService
      .get(this.current.id, $event.row.data.id)
      .subscribe((e: CheckPing) => {
        if (e.id !== null && e.id !== undefined && e.id > 0) {
          this.cpService.delete(this.current.id, e.id).subscribe(() => {
            this.checks.start();
          });
        }
      });
  }

  runCheckEntry($event: MERowActionParams<CheckPing>) {
    if (this.current === null || this.current === undefined) {
      return;
    }

    this.cpService.run(this.current.id, $event.row.data.id).subscribe({
      next: (e: MEActionResponse) => {
        if (e.success) {
          this.toastr.success('Der Test wird in Kürze durchgeführt...');
        } else {
          this.toastr.error(
            'Die Ausführung des Tests konnte nicht angefordert werden!'
          );
        }
      },
      error: () => {
        this.toastr.error(
          'Die Ausführung des Tests konnte nicht angefordert werden!'
        );
      },
    });
  }

  refresh_current() {
    this.service.get(this.current.id).subscribe((e: NetworkAddress) => {
      for (let i = 0; i < this.liste.data.length; i++) {
        if (this.liste.data[i].id === e.id) {
          console.log('found as idx ' + `$i`);
          this.liste.update(i, e);
        }
      }
      if (this.current.id === e.id) {
        this.current = e;
      }

      this.cd.markForCheck();
    });
  }

  add_to_group(grp: TGruppe) {
    if (
      this.current === null ||
      this.current === undefined ||
      grp === null ||
      grp === undefined
    ) {
      return;
    }
    this.service.add_to_group(this.current.id, grp.id).subscribe({
      next: r => {
        if (r.success) {
          this.current.add_group(grp);
          this.refresh_current();
        } else {
          this.toastr.error(
            'Das Hinzufügen zu einer Gruppe ist fehlgeschlagen!'
          );
        }
        this.cd.markForCheck();
      },
      error: () => {
        this.toastr.error('Das Hinzufügen zu einer Gruppe ist fehlgeschlagen!');
      },
    });
  }

  remove_from_group(grp: TGruppe) {
    if (
      this.current === null ||
      this.current === undefined ||
      grp === null ||
      grp === undefined
    ) {
      return;
    }
    this.service.remove_from_group(this.current.id, grp.id).subscribe({
      next: r => {
        if (r.success) {
          this.current.remove_group(grp);
          this.refresh_current();
        } else {
          this.toastr.error(
            'Das Entfernen aus einer Gruppe ist fehlgeschlagen!'
          );
        }
        this.cd.markForCheck();
      },
      error: () => {
        this.toastr.error('Das Entfernen aus einer Gruppe ist fehlgeschlagen!');
      },
    });
  }

  asAddress(row: MEDataTableRow<NetworkAddress>): NetworkAddress[] {
    if (
      row === null ||
      row === undefined ||
      row.data === null ||
      row.data === undefined
    ) {
      return [];
    }
    return [row.data];
  }
}
