import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {
    MEHighlitePipe,
    MELocalizedComponent,
    MESelectComponent,
    MESubscriptionHelper,
    METool,
    TMESelectItemFormatterCallback, TMESelectItemMatcherCallback, TMESelectItemTextCallback
} from "../../../../maennl-commons";
import {WireguardInterface} from "../../commons/wireguard-interface.class";
import {WireguardInterfaceList} from "../../commons/wireguard-interface.list";
import {WireguardInterfaceService} from "../../commons/wireguard-interface.service";
import {TNullableNumber} from "../../../../maennl-commons/tools/types";
import {WireguardInterfaceImpl} from "../../commons/wireguard-interface.impl";

@Component({
    selector: 'app-wireguard-interface-picker',
    template: `
        <me-select
                [asMatrix]="false"
                [idField]="'id'"
                [items]="interfaceListe.data"
                [placeholder]="placeholder"
                [small]="small"
                [value]="value"
                (onSearch)="doSearch($event)"
                (onSelect)="doSelect($event)"
                [nullable]="nullable"
                [onFormat]="itemFormat"
                [onMatch]="itemMatcher"
                [onGetText]="itemText"
                [disabled]="disabled"
        ></me-select>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WireguardInterfacePickerComponent
    extends MELocalizedComponent
    implements OnInit, OnDestroy, AfterViewInit {
    __classname = 'WireguardInterfacePickerComponent';
    __instance = '';

    @Input() disabled = false;
    @Input() nullable = false;
    @Input() placeholder = 'Interface auswählen';
    @Input() small = false;

    public _value: WireguardInterface = null;

    @Output() valueChange = new EventEmitter<WireguardInterface>();
    @Output() value_idChange= new EventEmitter<TNullableNumber>();

    public interfaceListe = new WireguardInterfaceList();

    @ViewChild(MESelectComponent, {static: false}) meSelect: MESelectComponent;

    @Input()
    set value(v: WireguardInterface) {
        if (this._value !== v) {
            this._value = v;
            if (this.meSelect !== null && this.meSelect !== undefined) {
                this.meSelect.value = v;
                this.meSelect.markForCheck();
            }
            this.valueChange.emit(v);
            this.value_idChange.emit(this.value_id);
            this.cd.markForCheck()
        }
    }

    get value(): WireguardInterface {
        return this._value;
    }

    @Input()
    set value_id(v:TNullableNumber){
        if(v===null||v===undefined||isNaN(v)){
            this.value=null;
            return;
        }
        if (this.value_id ===v){
            return;
        }
        this.service.get(v).subscribe({
            next:(v)=>{
                this.value=WireguardInterfaceImpl.fromResult(v);
            }, error: (e) =>{
                this.value=null;
            }
        })
    }

    get value_id():TNullableNumber{
        if(this.value===null||this.value===undefined){
            return null;
        }
        return this._value.id;
    }

    ngAfterViewInit() {
        if (this.meSelect !== null && this.meSelect !== undefined) {
            this.meSelect.onFormat = this.itemFormat;
            this.meSelect.onGetText = this.itemText;
            this.meSelect.onMatch = this.itemMatcher;
            this.interfaceListe.start();
        }
        console.log(this.meSelect);
    }

    constructor(
        public service: WireguardInterfaceService,
        public cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        MESubscriptionHelper.add(
            this,
            this.interfaceListe.onUpdateRequired.subscribe((list: WireguardInterfaceList) => {
                this.service.list(
                    list.size,
                    list.calcOffset(),
                    list.order,
                    list.simpleFilter,
                    list.getQuery()
                )
                    .subscribe({
                        next: l => {
                            list.doPopulateFromListResult(l);
                            if (!METool.isNullOrUndefined(this.meSelect)) {
                                this.meSelect.markForCheck();
                            }
                        },
                        error: () => {
                            list.clear();
                        }
                    });
                this.cd.markForCheck();
            })
        );
    }

    doSearch(value) {
        this.interfaceListe.searchString = value;
        this.interfaceListe.reload();
    }

    doSelect(value: WireguardInterface) {
        this._value = value;
        this.valueChange.emit(value);
        this.cd.markForCheck();
    }

    public itemFormat: TMESelectItemFormatterCallback<WireguardInterface> = (
        item: WireguardInterface,
        search: string,
        forList: boolean
    ): string => {
        if (item === null || item === undefined) {
            return '';
        }

        return (
            MEHighlitePipe.transformString('' + item.info, search) +
            (METool.isEmpty(item.display_name)
                ? '' : '<span class="badge bg-secondary text-dark">' +
                MEHighlitePipe.transformString('' + item.display_name, search) +
                '</span>')
        );
    };

    public itemText: TMESelectItemTextCallback<WireguardInterface> = (
        item: WireguardInterface
    ) => {
        if (item === null || item == undefined) {
            return '';
        }
        return (
            item.info + (METool.isEmpty(item.display_name) ? '' : ' [' + item.display_name + ']')
        );
    };

    public itemMatcher: TMESelectItemMatcherCallback<WireguardInterface> = (
        item: WireguardInterface,
        search: string
    ) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.info + ' ' + item.id + ' ' + item.display_name + ' ' + item.public_key;
        return x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
    }

    ngOnDestroy() {
        MESubscriptionHelper.release(this);
        this.interfaceListe.release();
        super.ngOnDestroy();
    }
}