import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { ISimProvider } from './isim-provider';
import { SimProvider } from './sim-provider';
import { SimpleSimProviderFilter } from './simple-sim-provider-filter';

export class SimProviderList extends MERemoteList<SimProvider> {
  __classname = 'SimProviderList';
  __instance = '';

  static fromListResult(res: IMEListResult<ISimProvider>): SimProviderList {
    const newList = new SimProviderList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleSimProviderFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'label',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }
}
