import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../../maennl-commons';
import { ITrackerOptions } from '../../vehicle-tracker/commons/itracker-options';
import { IVehicle } from './ivehicle';

export class Vehicle extends MEGenericEntity<IVehicle> implements IVehicle {
  art: number = null;
  tenant: number = null;
  counter_source: number = null;
  customer_id: number = null;
  date_created: Date = null;
  display_text: string = null;
  id: number = null;
  km_stand_metres: number = null;
  kostenstelle: number = null;
  last_counter_change: Date = null;
  last_counter_update: Date = null;
  last_updated: Date = null;
  notes: string = null;
  nummer: number = null;
  plate: string = null;
  settings_text: string = null;
  tankserver_id: number = null;
  tracker_text: string = null;
  vin: string = null;
  working_hours_seconds: number = null;

  constructor() {
    super();
  }

  static fromResult(entity: IVehicle, createIfNull: boolean = false): Vehicle {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Vehicle();
      }
      return null;
    }

    const instance = new Vehicle();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()

        .addLongField('id')
        .addLongField('tenant')
        .addLongField('nummer')
        .addLongField('tankserver_id')
        .addLongField('art')
        .addLongField('counter_source')
        .addLongField('customer_id')
        .addLongField('kostenstelle')
        .addLongField('km_stand_metres')
        .addLongField('working_hours_seconds')
        .addTextField('display_text')
        .addTextField('plate')
        .addTextField('vin')
        .addTextField('notes')
        .addTextField('settings_text')
        .addTextField('tracker_text')
        .addDateField('date_created')
        .addDateField('last_updated')
        .addDateField('last_counter_update')
        .addDateField('last_counter_change'),

      (e) => {
        let x = e as Vehicle;
        x.tracker_obj = JSON.parse(x.tracker_text) as ITrackerOptions;
      }
    );
    return instance;
  }

  tracker_obj: ITrackerOptions = null;
}
