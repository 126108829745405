import {InventoryType} from "./inventory-type.class";
import {MEGenericEntityFields} from "../../../maennl-commons";
import {TInventoryType, TNullableInventoryType} from "./inventory-type.types";

export class InventoryTypeImpl {
    static fromResult(
        entity: TInventoryType,
        createifNull=false
    ): TNullableInventoryType{
        if(entity===null||entity===undefined){
            if(createifNull){
                return new InventoryType()
            }
            return null
        }

        const instance= new InventoryType();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongFields([
                    'id',
                ])
                .addTextFields([
                    'code',
                    'display_text',
                ])
        );
        return instance;
    }
}