import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { MEPubSubService } from '../../maennl-commons/services';
import { MERichError } from '../../maennl-commons/tools';
import { Mitschnittstream } from '../common/mitschnittstream';
import { MitschnittstreamPaketList } from '../common/mitschnittstream-paket-list';
import { MedtcustomRenderer1Component } from '../controls/medtcustom-renderer1/medtcustom-renderer1.component';
import { MEDTMitschnittstreamPaketDatenRendererComponent } from '../controls/medtmitschnittstream-paket-daten-renderer/medtmitschnittstream-paket-daten-renderer.component';
import { MitschnittService } from '../services/mitschnitt.service';
import { MESubscriptionHelper } from '../../maennl-commons/services';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-mitschnitt-stream-details-popup',
  templateUrl: './mitschnitt-stream-details-popup.component.html',
  styleUrls: ['./mitschnitt-stream-details-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MitschnittStreamDetailsPopupComponent
  extends MEGenericModalComponent<Mitschnittstream>
  implements OnInit, OnDestroy
{
  __classname = 'MitschnittStreamDetailsPopupComponent';
  __instance = '';

  public dataRenderer = MEDTMitschnittstreamPaketDatenRendererComponent;
  public customRenderer1 = MedtcustomRenderer1Component;

  @ViewChild('tableContainer', { static: false }) tableContainer: ElementRef =
    null;

  public packets: MitschnittstreamPaketList = new MitschnittstreamPaketList();
  private _flip_sides = false;

  get flip_sides(): boolean {
    return this._flip_sides;
  }

  set flip_sides(value: boolean) {
    if (this._flip_sides !== value) {
      this._flip_sides = value;
      this.packets.reload();
      this.cd.markForCheck();
    }
  }

  public initEntity(src: Mitschnittstream): Mitschnittstream {
    return Mitschnittstream.fromResult(src, true);
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public mitschnittService: MitschnittService,
    public cd: ChangeDetectorRef,
    public benutzerService: BenutzerService
  ) {
    super(activeModal, pubsub);
    this.packets.size = 100;
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.packets.onUpdateRequired.subscribe(
        (list: MitschnittstreamPaketList) => {
          this.mitschnittService
            .listPackets(
              this.entity,
              list.size,
              list.calcOffset(),
              list.order,
              list.simpleFilter
            )
            .subscribe(
              (l) => {
                list.populateFromListResult(l);
                if (
                  this.tableContainer !== null &&
                  this.tableContainer !== undefined
                ) {
                  this.tableContainer.nativeElement.scrollTop = 0;
                }
                this.cd.markForCheck();
              },
              () => {
                MERichError.throw(
                  'Fehler beim Datenabruf',
                  'Die Liste der verfügbaren Datenpakete konnte nicht geladen werden'
                );
              }
            );
        },
        undefined
      )
    );

    this.packets.start();
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.packets.release();
    super.ngOnDestroy();
  }
}
