import { Component, Input, OnInit } from '@angular/core';
import { IDevice } from '../../common/idevice';

@Component({
  selector: 'app-device-display',
  template: `
    <div *ngIf="isValid()" title="{{ value?.deviceType?.label }}">
      {{ value.bezeichnung }}
    </div>
  `
})
export class DeviceDisplayComponent implements OnInit {
  @Input() public value: IDevice = null;

  constructor() {}

  ngOnInit() {}

  isValid() {
    return this.value !== null && this.value !== undefined;
  }
}
