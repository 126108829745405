import { Injectable } from '@angular/core';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import { HttpClient } from '@angular/common/http';
import {
  IGenericObject,
  IMEActionResponse,
  IMEListResult,
  MEActionResponse,
  MEApiTool,
  MEResultMetaSort
} from '../../../maennl-commons';
import { Observable, map, take } from 'rxjs';
import { IVehicle } from './ivehicle';
import { IMESimpleFilter } from '../../../maennl-commons/filters';
import { Vehicle } from './vehicle';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public list(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IVehicle> = null,
    queryString = ''
  ): Observable<IMEListResult<IVehicle>> {
    const uri = MEApiTool.buildListURL(
      '/api/webfuel/customers/vehicles',
      max,
      offset,
      sort,
      filter,
      queryString
    );
    return this.http
      .get<IMEListResult<IVehicle>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  get(id: number): Observable<Vehicle> {
    const uri = MEApiTool.buildEntityURL('/api/webfuel/customers/vehicles', id);
    return this.http
      .get<IVehicle>(uri, this.loginService.getRequestOptions())
      .pipe(
        map((r) => {
          return Vehicle.fromResult(r);
        }),
        take(1)
      );
  }

  patch(id: number, data: IGenericObject): Observable<MEActionResponse> {
    const uri = MEApiTool.buildEntityURL('/api/webfuel/customers/vehicles', id);
    return this.http
      .patch<IMEActionResponse>(
        uri,
        data,
        this.loginService.getRequestOptions()
      )
      .pipe(
        map((r) => {
          return MEActionResponse.fromRawActionResponse(r);
        }),
        take(1)
      );
  }
}
