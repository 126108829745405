import {HttpClient} from "@angular/common/http";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {IMEActionResponse, IMEListResult, MEActionResponse, MEApiTool, MEResultMetaSort} from "../../../maennl-commons";
import {IMESimpleFilter} from "../../../maennl-commons/filters";
import {IWireguardInterface} from "./wireguard-interface.interface";
import {Observable, of} from "rxjs";
import {map, take} from "rxjs/operators";
import {WireguardInterface} from "./wireguard-interface.class";
import {TNullableNumber} from "../../../maennl-commons/tools/types";
import {WireguardInterfaceImpl} from "./wireguard-interface.impl";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class WireguardInterfaceService {
    constructor(public http: HttpClient, public loginService: BenutzerService) {
    }

    public list(
        max = 50,
        offset = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<IWireguardInterface> = null,
        queryString = ''
    ): Observable<IMEListResult<IWireguardInterface>> {
        const uri = MEApiTool.buildListURL(
            'api/meconnect/netzwerk/wireguard',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<IWireguardInterface>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public store(entity: WireguardInterface): Observable<MEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            'api/meconnect/netzwerk/wireguard',
            entity.id
        );

        if (entity.id === null || entity.id === undefined || entity.id < 1) {
            return this.http
                .post<IMEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r => {
                        return MEActionResponse.fromRawActionResponse(r);
                    }),
                    take(1)
                );
        } else {
            return this.http
                .put<IMEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r => {
                        return MEActionResponse.fromRawActionResponse(r);
                    }),
                    take(1)
                );
        }
    }

    public get(id:TNullableNumber):Observable<WireguardInterface>{
        if(id===null||id===undefined||id<1){
            return of(null);
        }
        const uri=MEApiTool.buildEntityURL(
            'api/meconnect/netzwerk/wireguard',
            id
        );

        return this.http
            .get<IWireguardInterface>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r=>{
                    return WireguardInterfaceImpl.fromResult(r);
                }),
                take(1)
            );
    }

    public delete(id:TNullableNumber):Observable<MEActionResponse>{
        if(id===null||id===undefined||id<1){
            return of(MEActionResponse.error('id is null or undefined'));
        }
        const uri=MEApiTool.buildEntityURL(
            'api/meconnect/netzwerk/wireguard',
            id
        );
        return this.http
            .delete<IMEActionResponse>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r=>{
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }
}