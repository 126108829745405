import { IVehicleTracker } from './ivehicle-tracker';
import { SimpleVehicleTrackerFilter } from './simple-vehicle-tracker-filter';
import { VehicleTracker } from './vehicle-tracker';
import { IMEListResult, MERemoteList } from 'src/app/maennl-commons';

export class VehicleTrackerList extends MERemoteList<VehicleTracker> {
  __classname = 'VehicleTrackerList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IVehicleTracker>
  ): VehicleTrackerList {
    const newList = new VehicleTrackerList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleVehicleTrackerFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'tracker_identification',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return VehicleTracker.fromResult(entity);
  }
}
