import { Component, Injector } from '@angular/core';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer';
import { IServiceType } from '../../common/iservice-type';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';
import { Check } from '../../common/check';

@Component({
  selector: 'app-medtcheck-service-type-renderer',
  template: `
    <app-check-service-type-display
      *ngIf="isCellValid()"
      [value]="cell"
    ></app-check-service-type-display>
  `
})
export class MEDTCheckServiceTypeRendererComponent extends MEDataTableCommonCellRenderer {
  public cell: IServiceType = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<Check> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }
}
