import { Device } from '../common/device';

export class RebootDialogOptions {
  public device: Device = null;
  public hardReset = false;

  constructor(device: Device, hardReset: boolean) {
    this.device = device;
    this.hardReset = hardReset;
  }
}
