import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { ContactListEntry } from './contact-list-entry';
import { IContactListEntry } from './icontact-list-entry';
import { SimpleContactListEntryFilter } from './simple-contact-list-entry-filter';

export class ContactListEntryList extends MERemoteList<ContactListEntry> {
  __classname = 'ContactListEntryList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IContactListEntry>
  ): ContactListEntryList {
    const newList = new ContactListEntryList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleContactListEntryFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'displayText',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return ContactListEntry.fromResult(entity);
  }
}
