<div class="d-flex justify-content-lg-start dashboard-items flex-wrap">
  <app-sonde-dashboard-display
    *ngIf="false && userService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_SONDEN'])"
    [sondeId]="3"></app-sonde-dashboard-display>
  <app-sys-health-dashboard-bodies-count
    *ngIf="
      userService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_SYSHEALTH'])
    "></app-sys-health-dashboard-bodies-count>
  <ng-container
    *ngIf="userService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_MECONNECT'])">
    <ng-template #cpValue let-v="value">
      <ng-container *ngFor="let i of as_cp_result(v)">
        <div class="text-end text-end text-light">
          <div style="font-weight: bold; font-size: 22px">
            {{ i._address.address | removeNetmask }}
          </div>
          <div style="font-size: 10px">
            seit: {{ fDate(i.status_change, "YYYY-MM-DD HH:mm:ss", true) }}
          </div>
          <div style="font-size: 10px">
            {{ i.check_result }}
          </div>
        </div>
      </ng-container>
    </ng-template>
    <ng-container *ngFor="let nwa of nwaddrlist.data; trackBy: nwTrackBy">
      <ng-container *ngFor="let cp of nwa._checks; trackBy: cpTrackBy">
        <ng-container *ngIf="!cp.is_ok && cp.check_enabled">
          <dashboard-info-block
            [title]="nwa.info"
            [subtext]="cp?._firewall?.name"
            [notransform]="true"
            [value]="as_check(cp, nwa)"
            [valueTemplate]="cpValue"
            icon="far fa-network-wired"
            [classes]="'red'"></dashboard-info-block>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container
    *ngIf="userService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_SYSHEALTH'])">
    <ng-template #duValue let-v="value">
      <ng-container *ngFor="let i of as_du_result(v)">
        <div class="text-end text-end">
          <div style="font-weight: bold; font-size: 22px">
            {{
              fNum(
                i.percent_free >= 0 ? i.percent_free : 100 - i.percent_used,
                0
              )
            }}
            %
          </div>
          <div style="font-size: 9px">
            {{ fNum(i.size / 1024, 2) }} GB total,
            {{ fNum(i.used / 1024, 2) }} GB belegt<br />
            <strong>{{ fNum(i.avail / 1024, 2) }} GB frei</strong>
          </div>
          <div
            style="font-size: 9px"
            *ngIf="
              i.mountpoint_display !== null &&
              i.mountpoint_display !== undefined &&
              i.mountpoint_display !== ''
            ">
            eingebunden als {{ i.mountpoint_display }}
          </div>
          <div
            style="font-size: 9px"
            *ngIf="
              i.mountpoint_display === null ||
              i.mountpoint_display === undefined ||
              i.mountpoint_display === ''
            ">
            eingebunden als {{ i.mountpoint }}
          </div>
        </div>
      </ng-container>
    </ng-template>
    <ng-container *ngFor="let du of du_list.data; trackBy: duTrackBy">
      <ng-container *ngIf="du.is_valid_result()">
        <ng-container *ngFor="let i of du.du_result()">
          <ng-container
            *ngIf="
              i.message === '' || i.message === null || i.message === undefined
            ">
            <dashboard-info-block
              [title]="du.machine.bezeichnung"
              [subtext]="i.device"
              [valueTemplate]="duValue"
              [notransform]="true"
              [value]="i"
              icon="far fa-hard-drive"
              [classes]="
                du.result.code !== 'ok'
                  ? 'red'
                  : i.percent_used > 90
                  ? 'red'
                  : i.percent_used > 80
                  ? 'orange'
                  : i.percent_used > 70
                  ? 'yellow'
                  : 'green'
              "></dashboard-info-block>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
