import {
  MEGenericEntity,
  MEGenericEntityConverterParams
} from '../../maennl-commons/megeneric-entity';
import { MEGenericEntityFields } from '../../maennl-commons/megeneric-entity';
import { MEConverterTool } from '../../maennl-commons/tools';
import { Device } from '../../router/common/device';
import { IDevice } from '../../router/common/idevice';
import { IMitschnittgeraet } from './imitschnittgeraet';

export class Mitschnittgeraet
  extends MEGenericEntity<IMitschnittgeraet>
  implements IMitschnittgeraet
{
  deviceId = '';
  lastConnection: Date = null;
  lastConnectionId = -1;
  id = 0;
  label = '';
  device: Device = null;
  enabled = false;
  protocol = 'unknown';

  static fromResult(
    entity: IMitschnittgeraet,
    createIfNull: boolean = false
  ): Mitschnittgeraet {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Mitschnittgeraet();
      }
      return null;
    }

    const instance: Mitschnittgeraet = new Mitschnittgeraet();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addField('id', 'long')
        .addField('label', 'string')
        .addField('deviceId', 'string')
        .addField('lastConnection', 'date')
        .addField('lastConnectionId', 'long')
        .addTextField('protocol')
        .addField(
          'device',
          'converter',
          new MEGenericEntityConverterParams<Device>((value: any) => {
            return Device.fromResult(value);
          })
        )
    );
    return instance;
  }

  constructor(
    id: number = 0,
    label: string = '',
    device: IDevice = null,
    enabled: boolean = false,
    deviceId: string = '',
    lastConnection: any = null,
    lastConnectionId: number = -1
  ) {
    super();

    this.id = id;
    this.label = label;
    this.device = Device.fromResult(device);
    this.enabled = MEConverterTool.toBoolean(enabled);
    this.deviceId = deviceId;
    this.lastConnection = MEConverterTool.toDate(lastConnection);
    this.lastConnectionId = lastConnectionId;
  }
}
