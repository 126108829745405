import {TCoreModules, TNullableCoreModules} from "./core-modules.types";
import {CoreModules} from "./core-modules.class";
import {MEGenericEntityFields} from "../../../maennl-commons";

export class CoreModulesImpl {
    static fromResult(
        entity: TCoreModules,
        createifnull: boolean=false
    ): TNullableCoreModules{
        if(entity===null||entity===undefined){
            if(createifnull){
                return new CoreModules;
            }
            return null;
        }
        const instance = new CoreModules();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongFields([
                    'id'
                ])
                .addTextFields([
                    'code',
                    'description',
                    'display_text',
                ])
                .addBooleanFields([
                    'hidden',
                ])
        );
        return instance;
    }
}