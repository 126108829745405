import {
    MEGenericEntity,
    MEGenericEntityConverterParams,
    MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import {IBenutzer} from './ibenutzer';
import {AuthorityImpl} from './authority.impl';
import {IAuthority} from './authority.interface';
import {TAuthority, TNullableAuthorityList} from './authority.types';
import {
    TBenutzer,
    TNullableBenutzer,
} from './benutzer.types';
import {TNullableBoolean, TNullableNumber, TNullableString} from "../../maennl-commons/tools/types";


export class Benutzer extends MEGenericEntity<IBenutzer> implements IBenutzer {
    email: TNullableString = null;
    accountExpired: TNullableBoolean = null;
    permissions: TNullableString[] = [];
    passwordExpired: TNullableBoolean = null;
    enabled: TNullableBoolean = null;
    accountLocked: TNullableBoolean = null;
    lastLogin: Date;
    dateCreated: Date;
    lastUpdated: Date;
    username: TNullableString = '';
    fullname: TNullableString = '';
    id: TNullableNumber = 0;
    lastPasswordChange: Date = null;
    lastSeen: Date = null;
    telegramUsername: TNullableString = '';
    settings: any = {};
    password: TNullableString = '';

    newPW = '';
    newPW2 = '';

    static fromResult(
        entity: TBenutzer,
        createIfNull: boolean = false
    ): TNullableBenutzer {
        if (entity === null || entity === undefined) {
            if (createIfNull) {
                return new Benutzer();
            }
            return null;
        }

        const instance = new Benutzer();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongField('id')
                .addTextField('username')
                .addTextField('email')
                .addStringsField('permissions', true)
                .addTextField('fullname')
                .addTextField('telegramUsername')
                .addDateField('dateCreated')
                .addDateField('lastUpdated')
                .addDateField('lastLogin')
                .addDateField('lastPasswordChange')
                .addDateField('lastSeen')
                .addBooleanField('accountLocked')
                .addBooleanField('accountExpired')
                .addBooleanField('passwordExpired')
                .addBooleanField('enabled')
                .addConverterField(
                    'settings',
                    new MEGenericEntityConverterParams<any>((value: any) => {
                        return value === null || value === undefined ? {} : {...value};
                    })
                )
        );

        return instance;
    }

    constructor() {
        super();
    }

    is_in_authority(id: TNullableString): boolean {
        if (
            this.permissions === null ||
            this.permissions === undefined ||
            id === null ||
            id === undefined ||
            !Array.isArray(this.permissions) ||
            id === ''
        ) {
            return false;
        }
        return this.permissions.indexOf(id) >= 0
    }
}
