import {PositionNeedsProcessRN} from "./position_needs_process_rn.class";
import {TNullablePositionNeedsProcessRN} from "./position_needs_process_rn.types";
import {MEGenericEntityFields} from "../../../maennl-commons";

export class PositionNeedsProcessRNImpl {

    static fromResult(
        entity:PositionNeedsProcessRN,
        createifNull = false
    ): TNullablePositionNeedsProcessRN {
        if(entity===null||entity===undefined){
            if(createifNull) {
                new PositionNeedsProcessRN();
            }
            return null;
        }

        const instance = new PositionNeedsProcessRN();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongFields([
                    'id',
                    'position_id',
                    'tenant',
                    'version',
                    'rn'
                ])
                .addTextFields([
                    'changed',
                    '_tenant_info',
                    '_station_info',
                    '_terminal_info'
                ])
                .addDateFields([
                    'queued'
                ])
        );
        return instance;
    }
}