import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {
    IMEActionResponse,
    IMEListResult,
    MEActionResponse,
    MEApiTool,
    MEResultMetaSort,
    METool
} from "../../../maennl-commons";
import {IMESimpleFilter} from "../../../maennl-commons/filters";
import {Applications} from "./applications.class";
import {IApplications} from "./applications.interface";
import {map, Observable, of, take} from "rxjs";
import {TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";
import {ApplicationsImpl} from "./applications.impl";

@Injectable({
    providedIn: 'root'
})
export class ApplicationsService {

    constructor(
        public http: HttpClient,
        public loginService: BenutzerService
    ){}

    public list(
        max = 50,
        offset = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<Applications> = null,
        queryString = ''
    ): Observable<IMEListResult<IApplications>> {

        const uri = MEApiTool.buildListURL(
            'api/webfuel/applications/applications',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<IApplications>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public get(id: TNullableNumber): Observable<Applications> {
        if (id === null || id === undefined || id < 1) {
            return of(null);
        }
        const uri = MEApiTool.buildEntityURL(
            'api/webfuel/applications/applications',
            id
        );
        return this.http
            .get<IApplications>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return ApplicationsImpl.fromResult(r);
                }),
                take(1)
            );
    }

    public store(entity: Applications): Observable<MEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            'api/webfuel/applications/applications',
            entity.id
        )

        if (entity.id === null || entity.id === undefined || entity.id < 1) {
            return this.http
                .post<MEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r => {
                        return MEActionResponse.fromRawActionResponse(r);
                    }),
                    take(1)
                );
        } else {
            return this.http
                .put<MEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r => {
                        return MEActionResponse.fromRawActionResponse(r);
                    }),
                    take(1)
                );
        }
    }

    public delete(id:TNullableNumber):Observable<MEActionResponse> {
        if (METool.isNullOrUndefined(id) || id < 1) {
            return of(MEActionResponse.error('id is null or undefined'));
        }

        const uri = MEApiTool.buildEntityURL(
            'api/webfuel/applications/applications',
            id
        );

        return this.http
            .delete<MEActionResponse>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }

    remove_ip_address(
        id: TNullableNumber,
        ip_address: TNullableString
    ): Observable<MEActionResponse> {
        if (
            METool.isNullOrUndefined(id) ||
            id < 1 ||
            METool.isNullOrUndefined(ip_address) ||
            ip_address.trim() === ''
        ) {
            return of(MEActionResponse.error('id is null or undefined'));
        }

        const uri = MEApiTool.buildActionURL(
            'api/webfuel/applications/applications',
            id,
            '_removeIPAddress'
        );

        return this.http
            .post<IMEActionResponse>(
                uri,
                {ip_address: ip_address},
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }

    add_ip_address(
        id: TNullableNumber,
        ip_address: TNullableString
    ): Observable<MEActionResponse> {
        if (
            METool.isNullOrUndefined(id) ||
            id < 1 ||
            METool.isNullOrUndefined(ip_address) ||
            ip_address.trim() === ''
        ) {
            return of(MEActionResponse.error('id is null or undefined'));
        }

        const uri = MEApiTool.buildActionURL(
            'api/webfuel/applications/applications',
            id,
            '_addIPAddress'
        );

        return this.http
            .post<IMEActionResponse>(
                uri,
                {ip_address: ip_address},
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }
}