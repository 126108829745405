export class Point {
  x: number;
  y: number;
  mark = false;
  color = '#000000';
  label = '';
  value = '';
  opacity = 1;
  hidden = false;

  constructor(x: number = 0, y: number = 0, mark = false, hide = false) {
    this.x = x;
    this.y = y;
    this.mark = mark;
    this.hidden = hide;
  }

  public getPair(prefix = '', suffix = ''): string {
    return (prefix + this.x + ',' + this.y + suffix).trim();
  }
}
