import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { BenutzerService } from '../../benutzer/services/benutzer.service';
import { IMESimpleFilter } from '../../maennl-commons/filters';
import { IMEListResult } from '../../maennl-commons/mecommon-list';
import { MEResultMetaSort } from '../../maennl-commons/mesort-buttons';
import { IMEActionResponse, MEApiTool } from '../../maennl-commons/network';
import { Contact } from '../common/contact';
import { ICDRCall } from '../common/icdrcall';
import { IContact } from '../common/icontact';
import { IContactListEntry } from '../common/icontact-list-entry';

export const knownNumbers = new Map<string, string>();
knownNumbers.set('034021645110', 'DM (AMT)');
knownNumbers.set('034021645111', 'KM (AMT)');
knownNumbers.set('034021645113', 'STM (AMT)');
knownNumbers.set('034021645120', 'UE (AMT)');
knownNumbers.set('034021645130', 'RP (AMT)');
knownNumbers.set('034021645133', 'MS (AMT)');
knownNumbers.set('034021645131', 'AD (AMT)');
knownNumbers.set('034021645132', 'ELabor (AMT)');
knownNumbers.set('034021645140', 'EN (AMT)');
knownNumbers.set('034021645141', 'MSC (AMT)');
knownNumbers.set('10', 'DM');
knownNumbers.set('11', 'KM');
knownNumbers.set('13', 'STM');
knownNumbers.set('20', 'UE');
knownNumbers.set('30', 'RP');
knownNumbers.set('33', 'MS');
knownNumbers.set('31', 'AD');
knownNumbers.set('32', 'ELabor');
knownNumbers.set('40', 'EN');
knownNumbers.set('41', 'MSC');
knownNumbers.set('42', 'MSC Remote');
knownNumbers.set('21', 'Zentrale DECT');
knownNumbers.set('22', 'DM DECT');
knownNumbers.set('23', 'UE DECT');
knownNumbers.set('24', 'Uwe Samietz');
knownNumbers.set('26', 'Schrankenruf');
knownNumbers.set('27', 'Küche DECT');
knownNumbers.set('50', 'Notruftelefon (=>MSC Handy)');
knownNumbers.set('51', 'Controlpanel');
knownNumbers.set('52', 'UE extern(=>UE Privat)');
knownNumbers.set('65', 'Analogwandler Port 1');
knownNumbers.set('66', 'Analogwandler Port 2');
knownNumbers.set('70', 'Werkstatt (EG)');
knownNumbers.set('90', 'Schranke Sprechanlage');
knownNumbers.set('94', 'Schranke Öffner');
knownNumbers.set('95', 'Klingel');
knownNumbers.set('96', 'Klingel');
knownNumbers.set('100', 'Zentrale');
knownNumbers.set('101', 'Fax');
knownNumbers.set('8800', 'ISDN Fax');
knownNumbers.set('8802', 'Fax UE');
knownNumbers.set('8803', 'Fax Pool');
knownNumbers.set('1200', 'Zentrale Warteschlange');
knownNumbers.set('1000', 'Alle Nebenstellen');
knownNumbers.set('1001', 'Flottenkarten (EN/MSC)');
knownNumbers.set('1002', 'E-Labor');
knownNumbers.set('1003', 'Büro DM+STM');
knownNumbers.set('1004', 'Programmierung (MS/RP)');
knownNumbers.set('1005', 'DM Rufgruppe');
knownNumbers.set('1006', 'UE Rufgruppe');
knownNumbers.set('1007', 'Klingelgruppe');
knownNumbers.set('1008', 'MSC Rufgruppe');
knownNumbers.set('017645896914', 'ME Dietrich Funk');
knownNumbers.set('017657651593', 'ME Janek Funk');
knownNumbers.set('017657778767', 'MSC Funk');

@Injectable({
  providedIn: 'root',
})
export class TelefonanlageService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public listContactsWithNumbers(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IContactListEntry> = null,
    queryString: string = null
  ): Observable<IMEListResult<IContactListEntry>> {
    const uri = MEApiTool.buildListURL(
      '/api/telefonanlage/contacts-with-numbers',
      max,
      offset,
      sort,
      filter,
      queryString
    );
    return this.http
      .get<IMEListResult<IContactListEntry>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }

  public listContacts(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IContactListEntry> = null,
    queryString: string = null
  ): Observable<IMEListResult<IContact>> {
    const uri = MEApiTool.buildListURL(
      '/api/telefonanlage/contacts',
      max,
      offset,
      sort,
      filter,
      queryString
    );
    return this.http
      .get<IMEListResult<IContact>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public listVerbindungen(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<ICDRCall> = null,
    queryString: string = null
  ): Observable<IMEListResult<ICDRCall>> {
    const uri = MEApiTool.buildListURL(
      '/api/telefonanlage/verbindungen',
      max,
      offset,
      sort,
      filter,
      queryString
    );
    return this.http
      .get<IMEListResult<ICDRCall>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public downloadVerbindungen(
    filter: IMESimpleFilter<ICDRCall> = null,
    queryString: string = null,
    mode = 0
  ): Observable<Blob> {
    const uri = MEApiTool.buildListURL(
      '/api/telefonanlage/verbindungen.pdf',
      1,
      0,
      [],
      filter,
      queryString,
      {
        mode: mode,
      }
    );
    return this.http
      .get<Blob>(
        uri,
        this.loginService.getRequestOptions(false, true, true, 'blob')
      )
      .pipe(take(1));
  }

  public getContact(
    contactId: number,
    includeNumbers: boolean
  ): Observable<IContact> {
    const uri = MEApiTool.buildEntityURL(
      '/api/telefonanlage/contacts',
      contactId,
      { includeNumbers: includeNumbers }
    );
    return this.http
      .get<IContact>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public store(entity: Contact): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildEntityURL(
      '/api/telefonanlage/contacts',
      entity.id
    );

    if (entity.id === null || entity.id === undefined || entity.id < 1) {
      return this.http
        .post<IMEActionResponse>(
          uri,
          entity,
          this.loginService.getRequestOptions()
        )
        .pipe(take(1));
    } else {
      return this.http
        .put<IMEActionResponse>(
          uri,
          entity,
          this.loginService.getRequestOptions()
        )
        .pipe(take(1));
    }
  }

  public openGate(): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL(
      '/api/telefonanlage',
      null,
      '_openGate'
    );
    return this.http
      .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public gateRingOff(): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL(
      '/api/telefonanlage',
      null,
      '_gateRingOff'
    );
    return this.http
      .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public listLastCalls(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IContactListEntry> = null,
    queryString: string = null
  ): Observable<IMEListResult<ICDRCall>> {
    const uri = MEApiTool.buildListURL(
      '/api/telefonanlage/last_calls',
      max,
      offset,
      sort,
      filter,
      queryString
    );
    return this.http
      .get<IMEListResult<ICDRCall>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  reprocessCalls(): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL(
      '/api/telefonanlage',
      null,
      '_reprocessCalls'
    );
    return this.http
      .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public startEmergencyCall(): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL(
      '/api/telefonanlage',
      null,
      '_emergencyCall'
    );
    return this.http
      .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
      .pipe(take(1));
  }
}
