<div class="form-group row">
  <label
    for="{{ id }}"
    class="col-{{ labelWidth }} col-form-label"
    *ngIf="labelWidth > 0">
    {{ label }}
  </label>
  <div class="col-{{ 12 - labelWidth }}">
    <div
      class=""
      [class.input-group]="hasAddon()"
      [class.input-group-sm]="hasAddon() && small">
      <app-firewall-selector
        [value]="value"
        (valueChange)="update($event)"
        [nullable]="nullable"
        [small]="small"></app-firewall-selector>
      <span
        class="input-group-btn addon"
        *ngFor="let ai of addonIcons; let idx = index">
        <button
          class="btn btn-secondary addon-btn-{{ idx }}"
          [class.btn-sm]="small"
          type="button"
          title="{{ _addOnLabels[idx] }}"
          (click)="aClick(idx)">
          <i class="{{ ai }}"></i>
        </button>
      </span>
    </div>
  </div>
</div>
