<div
        class="d-flex align-items-start justify-content-between"
        *ngIf="cmListe !== null && cmListe !== undefined">
    <div class="flex-fill">
        <me-data-table
            #wgInterfaceTable
            [rowActionsAsButtons]="true"
            [tableActionsAsButtons]="true"
            [data]="cmListe.asDataTableRows()"
            [selectable]="false"
            [singleSelect]="false"
            [hideCheckboxes]="true"
            actionsicon="icofont icofont-navigation-menu">
            <me-data-table-column
                    [label]="'ID'"
                    [field]="'id'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Code'"
                    [field]="'code'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Info'"
                    [field]="'display_text'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Beschreibung'"
                    [field]="'description'"></me-data-table-column>
            <me-data-table-column
                    [label]="'versteckt'"
                    [field]="'hidden'"
                    type="boolean"
            ></me-data-table-column>

            <me-data-table-action
                    label="Eintrag hinzufügen"
                    [icon]="'far fa-plus'"
                    (perform)="addEntry()"
            ></me-data-table-action>

            <me-data-table-rowaction
                    (perform)="editCoreModules($event)"
                    icon="far fa-pencil"
                    label="Bearbeiten"
                    title="Eintrag bearbeiten"
            ></me-data-table-rowaction>

            <me-data-table-rowaction
                    (perform)="removeEntry($event)"
                    icon="far fa-trash"
                    label="Löschen"
                    title="Eintrag löschen"
            ></me-data-table-rowaction>

            <me-data-table-table-extra-header>
                <me-pagination
                        [total]="cmListe.total"
                        [page]="cmListe.page"
                        [perpage]="cmListe.size"
                        (pageChange)="cmListe.setPage($event)"
                        (pageCountChange)="cmListe.processPageCountChangedEvent($event)"
                        (queryChange)="cmListe.processQueryChangedEvent($event)"
                        settingsid="core-modules-info"
                        [settingsService]="benutzerService"
                        [showSearch]="false"></me-pagination>
            </me-data-table-table-extra-header>
        </me-data-table>
    </div>
</div>