import { Component, Input } from '@angular/core';
import { IDevice } from '../../common/idevice';
import { RouterService } from '../../services/router.service';

@Component({
  selector: 'app-device-disk-usage-display',
  template: `
    <div
      *ngIf="routerService.isMEConnectRouter(device)"
      title="{{ routerService.getDiskAvailablePercent(device) }}% verfügbar"
      [class.is-online]="device.online"
    >
      <ngb-progressbar
        max="100"
        [value]="routerService.getDiskUsedPercent(device)"
        [type]="routerService.getDiskWarnState(device)"
      ></ngb-progressbar>
    </div>
    <div
      *ngIf="routerService.isMEConnectRouterV2(device)"
      title="{{ routerService.getDiskAvailablePercent(device) }}% verfügbar"
      [class.is-online]="device.online"
    >
      <ngb-progressbar
        max="100"
        [value]="routerService.getDiskUsedPercent(device)"
        [type]="routerService.getDiskWarnState(device)"
      ></ngb-progressbar>
    </div>
  `
})
export class DeviceDiskUsageDisplayComponent {
  @Input() device: IDevice = null;

  constructor(public routerService: RouterService) {
    //
  }
}
