import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import { METool } from '../tools';

@Component({
  selector: 'me-area-heading',
  styles: [
    `
      :host {
        margin: 10px 0 25px 0;
        display: block;
      }

      :host h1 {
        font-size: 1.3em !important;
        font-weight: bold;
        text-transform: uppercase;
        color: #333333;
        margin: 0;
      }

      :host h1 > i {
        color: #999999;
      }

      :host h2 {
        font-size: 0.8em !important;
        font-weight: bold;
        color: #333333;
        margin: 5px 0 3px 0;
      }

      :host h2 > i {
        color: #999999;
      }
    `
  ],
  template: `
    <div class="d-flex justify-content-between">
      <div style="min-width: 30%">
        <h2 *ngIf="hasToptitle()">
          {{ toptitle }}
        </h2>
        <h1 class="d-flex justify-content-start align-items-center">
          <span class="me-1" *ngIf="hasIcon()">
            <ng-container *ngTemplateOutlet="icon"></ng-container>
          </span>
          <span *ngIf="!editable">{{ title }}</span>
          <input
            class="form-control"
            *ngIf="editable"
            placeholder="Titel"
            [value]="title"
            (change)="titleChange($event)"
          />
        </h1>
        <h2 *ngIf="hasSubtitle() || editable">
          <span *ngIf="!editable">{{ subtitle }}</span>
          <input
            class="form-control form-control-sm"
            placeholder="Sub-Titel"
            *ngIf="editable"
            [value]="subtitle"
            (change)="subTitleChange($event)"
          />
        </h2>
      </div>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MEAreaHeadingComponent implements OnInit {
  private _icon: TemplateRef<any> | null = null;
  private _subtitle = '';
  private _title = '';
  private _toptitle = '';

  public iconClass = '';

  private _editable = false;

  public get editable(): boolean {
    return this._editable;
  }

  @Output() titleChanged = new EventEmitter<string>();
  @Output() subTitleChanged = new EventEmitter<string>();

  @Input()
  public set editable(value: boolean) {
    this._editable = value;
    this.cd.markForCheck();
  }

  public get icon(): TemplateRef<any> | null {
    return this._icon;
  }

  @Input()
  public set icon(value: TemplateRef<any> | null) {
    if (this._icon !== value) {
      this._icon = value;
      this.cd.markForCheck();
    }
  }

  public get subtitle(): string {
    return this._subtitle;
  }

  @Input()
  public set subtitle(value: string) {
    if (this._subtitle !== value) {
      this._subtitle = value;
      this.cd.markForCheck();
    }
  }

  public get title(): string {
    return this._title;
  }

  @Input()
  public set title(value: string) {
    if (this._title !== value) {
      this._title = value;
      this.cd.markForCheck();
    }
  }

  public get toptitle(): string {
    return this._toptitle;
  }

  @Input()
  public set toptitle(value: string) {
    if (this._toptitle !== value) {
      this._toptitle = value;
      this.cd.markForCheck();
    }
  }

  public ngOnInit() {}

  public hasSubtitle(): boolean {
    if (this._subtitle === undefined) {
      return false;
    }
    if (this._subtitle === null) {
      return false;
    }
    return this._subtitle !== '';
  }

  public hasToptitle(): boolean {
    if (METool.isNullOrUndefined(this._toptitle)) {
      return false;
    }
    return this._toptitle.trim() !== '';
  }

  constructor(public cd: ChangeDetectorRef) {}

  public hasIcon() {
    return this._icon !== null && this._icon !== undefined;
  }

  public titleChange($event: Event) {
    this.titleChanged.emit(($event.target as HTMLInputElement).value);
  }

  public subTitleChange($event: Event) {
    this.subTitleChanged.emit(($event.target as HTMLInputElement).value);
  }
}
