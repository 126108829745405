<div class="modal-header">
    <h4 class="modal-title">Streams filtern</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <me-tab-set>
        <me-pane title="Stream">

            <p>
                Hier kann die Stream-ID eingeschränkt werden, möglich sind
                einzelne IDs, Aufzählungen oder Bereiche:
                1,2,14-85
            </p>

            <me-text-field [label]="'Stream-ID'" [(value)]="entity.connection_id">

            </me-text-field>
        </me-pane>
        <me-pane title="Gerät">
            <app-mitschnittgeraet-picker [(value)]="entity.mitschnitt_id"></app-mitschnittgeraet-picker>
        </me-pane>
        <me-pane title="Verbindungs-Zeitpunkt">
            <me-date-time-range-selector [(value)]="entity.connected"></me-date-time-range-selector>
        </me-pane>
        <me-pane title="Paket-Empfang">
            <me-date-time-range-selector [(value)]="entity.received"></me-date-time-range-selector>
        </me-pane>
    </me-tab-set>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="clearOk()">
        <i class="icofont icofont-eraser"></i> Filter leeren
    </button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="ok()">
        <i class="icofont icofont-filter"></i> Filter setzen
    </button>
</div>
