import { MEDataTableSortChanged } from '../data-table/events';
import { MENewPageCountEvent } from '../mepagination/menew-page-count-event';
import { MEResultMetaSort } from '../mesort-buttons';
import { MESubscriptionHelper } from '../services';
import { MEKitchenTimer } from '../timers';
import { noop } from '../tools';
import { IMEListResult } from './imelist-result';
import { MECommonList } from './mecommon-list';
import { EventEmitter } from '@angular/core';

export abstract class MERemoteList<T> extends MECommonList<T> {
  loading = false;
  public onUpdateRequired: EventEmitter<MERemoteList<T>> = new EventEmitter();
  protected timer: MEKitchenTimer = new MEKitchenTimer();
  protected reloadTimer: MEKitchenTimer = null;

  protected constructor() {
    super();
  }

  public initList(autorun = false, timeout = 400) {
    this.timer.setTimeout(timeout);

    MESubscriptionHelper.add(
      this,
      this.timer.subscribe(() => {
        this.doListUpdate();
      }, noop)
    );
    if (autorun) {
      this.timer.fireWhenNotBefore();
    }
  }

  public disableAutoReload() {
    this.enableAutoReload(-1);
  }

  public enableAutoReload(timeout = 60000) {
    if (this.reloadTimer !== null && this.reloadTimer !== undefined) {
      if (timeout < 1) {
        this.reloadTimer.stop();
        this.reloadTimer = null;
      } else {
        this.reloadTimer.setTimeout(timeout);
        this.reloadTimer.rewind();
      }
    }
    if (timeout > 0) {
      this.reloadTimer = new MEKitchenTimer(timeout);
      MESubscriptionHelper.add(
        this,
        this.reloadTimer.subscribe(() => {
          this.start();
          if (this.reloadTimer !== null && this.reloadTimer !== undefined) {
            this.reloadTimer.rewind();
          }
        }, noop)
      );
      this.reloadTimer.start();
    }
  }

  public wasStartedBefore(): boolean {
    return this.timer === null || this.timer === undefined
      ? false
      : this.timer.wasFired();
  }

  public setTimeout(timeout: number) {
    this.timer.setTimeout(timeout);
  }

  start() {
    this.timer.start();
    if (this.reloadTimer !== null && this.reloadTimer !== undefined) {
      this.reloadTimer.rewind();
    }
  }

  reload() {
    this.timer.start(true);
    if (this.reloadTimer !== null && this.reloadTimer !== undefined) {
      this.reloadTimer.rewind();
    }
  }

  doListUpdate() {
    //this.loading = true;
    this.onUpdateRequired.emit(this);
    if (this.reloadTimer !== null && this.reloadTimer !== undefined) {
      this.reloadTimer.rewind();
    }
  }

  setPage(page: number) {
    if (page > this.pageCount()) {
      page = this.pageCount();
    }
    this.page = page;
    this.start();
    return this;
  }

  setWindowSize(size: number) {
    this.size = size;
    this.start();
    return this;
  }

  processPageCountChangedEvent(e: MENewPageCountEvent | number) {
    if (e instanceof MENewPageCountEvent) {
      this.setWindowSize(e.itemsPerPage);
      this.setPage(e.currentPage);
    } else {
      this.setWindowSize(e);
    }
  }

  processSortChangedEvent(e: MEDataTableSortChanged) {
    if (this.order === null || this.order === undefined) {
      this.order = [];
    }
    if (e.add) {
      this.order.push(new MEResultMetaSort(e.field, e.direction));
    } else {
      this.order = [new MEResultMetaSort(e.field, e.direction)];
    }
    this.start();
  }

  processQueryChangedEvent(e: string) {
    this.searchString = e;
    this.start();
  }

  release() {
    MESubscriptionHelper.release(this);
    super.release();
  }

  doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'id',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ) {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    this.loading = false;
  }

  clear() {
    this.loading = false;
    super.clear();
  }
}
