<me-data-table
        *ngIf="verlauf!==null && verlauf!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="verlauf.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        #verlaufTable
>

    <me-data-table-column
            *ngIf="displayCheckColumn"
            [label]="'Test'"
            [field]="'check'"
            [renderer]="checkNameDisplayR"
    ></me-data-table-column>
    <me-data-table-column
            *ngIf="displayMachineColumn"
            [label]="'Host'"
            [field]="'machine'"
            [renderer]="machineR"
    ></me-data-table-column>
    <me-data-table-column
            *ngIf="displayServiceTypeColumn"
            [label]="'Typ'"
            [field]="'serviceType'"
            [renderer]="serviceTypeR"
    ></me-data-table-column>
    <me-data-table-column
            [label]="'Zeitpunkt'"
            [field]="'zeitpunkt'"
            type="date"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>
    <me-data-table-column
            *ngFor="let c of extraColumns"
            [renderer]="c.renderer"
            [label]="c.label"
            [field]="c.field"
            [cfg]="c.cfg"
            [editable]="c.editable"
            [filterable]="c.filterable"
            [editor]="c.editor"
            [sortable]="c.sortable"
            [title]="c.title"
            [titleField]="c.titleField"
            [type]="c.type"
            [xtraClasses]="c.xtraClasses"
            [visible]="c.visible"
    ></me-data-table-column>
    <me-data-table-column [label]="'Status'" field="result" [renderer]="statusRenderer"
    ></me-data-table-column>
    <me-data-table-column [label]="'Nachricht'" field="message"
    ></me-data-table-column>
    <me-data-table-table-extra-header>
        <me-pagination
                [total]="verlauf.total"
                [page]="verlauf.page"
                [perpage]="verlauf.size"
                (pageChange)="verlauf.setPage($event)"
                (pageCountChange)="verlauf.processPageCountChangedEvent($event)"
                [showSearch]="true"
                (queryChange)="verlauf.processQueryChangedEvent($event)"
                settingsid="syshealth-history"
                [settingsService]="benutzerService"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>

