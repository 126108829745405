import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { MERichError } from './merich-error';
import { MEConverterTool } from './meconverter-tool';

@Injectable()
export class MEErrorHandler extends ErrorHandler {
  errorShown = {
    locale: false
  };

  constructor(private injector: Injector) {
    super();
  }

  public handleError(error: any): void {
    const toastr = <ToastrService>this.injector.get(ToastrService);
    let title = 'interner Fehler';
    if (toastr !== null && toastr !== undefined) {
      let message = '';
      if (error instanceof HttpErrorResponse) {
        if ((<HttpErrorResponse>error).status === 504) {
          message =
            'Daten konnten nicht abgerufen werden. Ein Backendserver ist momentan nicht erreichbar oder steht nicht zur Verfügung.';
          if ((<HttpErrorResponse>error).url.indexOf('locales.json') > 0) {
            message = null;
            if (!this.errorShown.locale) {
              this.errorShown.locale = true;
              message =
                'Die Sprachdateien konnten nicht geladen werden. Es steht somit nur Deutsch als Sprache zur Verfügung.';
            }
          } else if (
            (<HttpErrorResponse>error).url.indexOf('resources/sysinfo') > 0
          ) {
            message = null;
          } else if (
            (<HttpErrorResponse>error).url.indexOf('resources/online') > 0
          ) {
            message = null;
          }
        }
      } else if (error instanceof MERichError) {
        message = (error as MERichError).message;
        title = (error as MERichError).title;
      } else if (
        typeof error === 'object' &&
        Object.keys(error).indexOf('rejection') >= 0 &&
        typeof error['rejection'] === 'object' &&
        Object.keys(error['rejection']).indexOf('status') >= 0
      ) {
        if (parseInt('' + error['rejection']['status'], 10) === 504) {
          message =
            'Daten konnten nicht abgerufen werden. Ein Backendserver ist momentan nicht erreichbar oder steht nicht zur Verfügung.';
        } else {
          message = error['rejection']['message'];
        }
      } else {
        // console.log( MEConverterTool.toJSON(error));

        message = '' + error;
        if (
          message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') >= 0
        ) {
          message = null;
        } else {
          message = message.replace(/^Error: /, '').trim();
          if (message.indexOf('RichErrorMessage') > 0) {
            const h = JSON.parse(message);
            title = h['title'];
            message = h['message'];
          }
        }
      }
      if (message != null) {
        if (message !== '') {
          setTimeout(() => {
            toastr.error(message, title, { disableTimeOut: true });
          });
          // return;
        }
      }
    }

    super.handleError(error);
  }
}
