import { Component, Injector } from '@angular/core';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer';
import { IMachine } from '../../common/imachine';
import { Machine } from '../../common/machine';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';

@Component({
  selector: 'app-medtmachine-renderer',
  template: `
    <app-machine-display
      *ngIf="isCellValid()"
      [value]="cell"
      [title]="getTitle()"
    ></app-machine-display>
  `
})
export class MEDTMachineRendererComponent extends MEDataTableCommonCellRenderer {
  public cell: IMachine = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<Machine> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }

  getTitle() {
    if (this.row !== null && this.row !== undefined) {
      return ('' + this.row.data.code).replace('"', "'");
    }
  }
}
