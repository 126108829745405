import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  MELocalizedComponent,
  MERichError,
  MESubscriptionHelper,
  METool
} from '../../../maennl-commons';
import { RBPlanList } from '../commons/rbplan-list';
import { RBPlanService } from '../commons/rbplan.service';
import { RBPlan } from '../commons/rbplan';
import { ToastrService } from 'ngx-toastr';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-tab-rbplans',
  templateUrl: './tab-rbplans.component.html',
  styleUrls: ['./tab-rbplans.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabRBPlansComponent
  extends MELocalizedComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  liste = new RBPlanList(false, 400);

  constructor(
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    public benutzerService: BenutzerService,
    public rbPlanService: RBPlanService
  ) {
    super();
  }

  private _tabActive = false;

  public get tabActive(): boolean {
    return this._tabActive;
  }

  @Input()
  public set tabActive(value: boolean) {
    if (this._tabActive !== value) {
      this._tabActive = value;
      this.cd.markForCheck();
      if (this._tabActive) {
        this.liste.start();
      }
    }
  }

  ngOnInit(): void {
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe((list: RBPlanList) => {
        if (this.tabActive) {
          this.rbPlanService
            .list(
              list.size,
              list.calcOffset(),
              list.order,
              list.simpleFilter,
              list.getQuery()
            )
            .subscribe({
              next: (l) => {
                list.populateFromListResult(l);
                this.cd.markForCheck();
              },
              error: (e) => {
                console.log(e);
                MERichError.throw(
                  'Fehler beim Datenabruf',
                  'Die Liste der Webfuel.de-Remote-Backup-Abonnements konnte nicht abgerufen werden.'
                );
              }
            });
        }
      })
    );
  }

  onActivate() {
    this.tabActive = true;
    console.log('activated');
  }

  onDeactivate() {
    this.tabActive = false;
    console.log('deactivated');
  }

  public ngAfterViewInit(): void {}

  public ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
  }

  typed(data: any): RBPlan[] {
    if (METool.isNullOrUndefined(data)) {
      return [];
    }
    return [data as RBPlan];
  }
}
