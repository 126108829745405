import { MEListOptionComponent } from '../../melist-option/melist-option.component';
import { MEDataTableRow } from '../rows/medata-table-row';
import { MEDataTableRowActionDirective } from './medata-table-row-action';

export class MERowActionParams<T> {
  public row: MEDataTableRow<T> = null;
  public action: MEDataTableRowActionDirective | MEListOptionComponent = null;

  constructor(
    row: MEDataTableRow<T>,
    action: MEDataTableRowActionDirective | MEListOptionComponent
  ) {
    this.row = row;
    this.action = action;
  }
}
