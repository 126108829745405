import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {
    MEConfirmDialogComponent,
    MEGenericModalComponent,
    MELocalizedComponent,
    MERowActionParams,
    MESubscriptionHelper, METool, noop
} from "../../../maennl-commons";
import {CoreModulesList} from "../commons/core-modules.list";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {CoreModulesService} from "../commons/core-modules.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {CoreModules} from "../commons/core-modules.class";
import {TabCoreModulesEditorComponent} from "./tab-core-modules-editor/tab-core-modules-editor.component";

@Component({
    selector: 'app-tab-core-modules',
    templateUrl: './tab-core-modules.component.html',
    styleUrls: ['./tab-core-modules.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation:ViewEncapsulation.None,
})
export class TabCoreModulesComponent
    extends MELocalizedComponent
    implements OnInit,OnDestroy,AfterViewInit{

    @Input() id='';
    cmListe= new CoreModulesList();

    private _tabActive = false;

    constructor(
        public cd:ChangeDetectorRef,
        public cmService: CoreModulesService,
        public benutzerService: BenutzerService,
        public modalService: NgbModal,
        public toastr: ToastrService,
    ) {
        super()
    }

    public get tabActive(): boolean {
        return this._tabActive;
    }

    @Input()
    public set tabActive(value: boolean){
        if(this._tabActive!==value) {
            this._tabActive = value;
            this.cd.markForCheck();
            if(this._tabActive) {
                this.cmListe.start();
            }
        }
    }

    ngOnInit() {
        MESubscriptionHelper.add(
            this,
            this.cmListe.onUpdateRequired.subscribe(()=>{
                this.cd.markForCheck();
                if(this.cmListe.loading){
                    return;
                }
                this.cmListe.loading=true;
                this.cmService.list(
                    this.cmListe.size,
                    this.cmListe.calcOffset(),
                    this.cmListe.order,
                    this.cmListe.simpleFilter,
                    this.cmListe.getQuery()
                )
                    .subscribe( {
                        next:l=>{
                            this.cmListe.doPopulateFromListResult(l);
                            this.cmListe.loading=false;
                            this.cd.markForCheck();
                        },
                        error:() =>{
                            this.cmListe.clear();
                            this.cmListe.loading=false;
                        }
                    });
                this.cd.markForCheck();
            })
        );
    }

    onActivate(){
        this.tabActive=true;
    }

    onDeactivate(){
        this.tabActive=false;
    }

    ngOnDestroy() {
        this.cmListe.release();
        MESubscriptionHelper.release(this);
    }

    ngAfterViewInit(){}

    addEntry(){
        TabCoreModulesEditorComponent.open(
            this.modalService,
            TabCoreModulesEditorComponent,
            null,
            MEGenericModalComponent.SIZE_MEDIUM,
            null
        ).then(()=>{
            this.cmListe.start()
        },noop());
    }

    editCoreModules($event:MERowActionParams<CoreModules>) {
        this.cmService.get($event.row.data.id).subscribe((cm) =>{
            if(cm!==null&&cm!==undefined&&cm.id>0){
                TabCoreModulesEditorComponent.open(
                    this.modalService,
                    TabCoreModulesEditorComponent,
                    cm,
                    MEGenericModalComponent.SIZE_MEDIUM,
                    null
                ).then(() => {
                    this.cmListe.start();
                },noop());
            }
        });
    }

    removeEntry($event:MERowActionParams<CoreModules>) {
        if(!METool.isNullOrUndefined(this.cmService.get($event.row.data.id))) {
            MEConfirmDialogComponent.display(
                this.modalService,
                "Modul Löschung bestätigen",
                "Soll das Modul wirklich unwiderruflich gelöscht werden?",
                "Ja, jetzt löschen",
                "icofont icofont-delete"
            ).then(()=>{
                this.cmService.get($event.row.data.id).subscribe((e:CoreModules)=>{
                    if(e.id!==null&&e.id!==undefined&&e.id>0) {
                        this.cmService.delete(e.id).subscribe(()=>{
                            this.cmListe.start();
                        });
                    }
                });
            })
        }
    }
}