import {MEGenericEntity} from "../../../maennl-commons";
import {IMissingTerminalTransactions} from "./missing-terminal-transactions.interface";
import {TAPIDate, TNullableBoolean, TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";

export class MissingTerminalTransactions
    extends MEGenericEntity<IMissingTerminalTransactions>
    implements IMissingTerminalTransactions {

    _in_progress: TNullableBoolean=null;
    _station_display_text: TNullableString=null;
    _tenant_info: TNullableString=null;
    _terminal_display_text: TNullableString=null;
    belegnummer: TNullableNumber=null;
    id: TNullableNumber=null;
    next_try: TAPIDate=null;
    queued: TAPIDate=null;
    tenant: TNullableNumber=null;
    terminal_id: TNullableNumber=null;
    tries: TNullableNumber=null;
    }