import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { METool } from '../../../maennl-commons/tools';
import { ZsrChunk } from '../zsrdisplay/zsr-chunk';

@Component({
  selector: 'app-zsrpackaged-display',
  templateUrl: './zsrpackaged-display.component.html',
  styleUrls: ['./zsrpackaged-display.component.scss']
})
export class ZSRPackagedDisplayComponent implements OnInit {
  chunks: ZsrChunk[] = [];

  origin = [];
  public showOrigin = false;

  @Input()
  set data(v: string) {
    this.chunks = [];
    if (!METool.isNullOrUndefined(v)) {
      const bytes: string[] = v.match(/.{2}/g);
      this.origin = bytes;

      if (bytes[0] === '40' && bytes.length > 6) {
        // Datenpaket
        let currentChunk = new ZsrChunk();
        currentChunk.knownDirection = parseInt(bytes[1], 16);
        let stxFound = false;
        let pos = 6;
        let hasFF = false;
        while (pos < bytes.length - 1) {
          pos++;
          let oneByte = bytes[pos];
          let numByte = parseInt(oneByte, 16);
          switch (numByte) {
            case 0x02:
              if (!stxFound) {
                if (currentChunk.hasBytes()) {
                  this.chunks.push(currentChunk);
                  currentChunk = new ZsrChunk();
                  currentChunk.knownDirection = parseInt(bytes[1], 16);
                }
                hasFF = false;
                stxFound = true;
              }
              currentChunk.add(numByte);
              break;
            case 0x03:
              currentChunk.add(numByte);
              if (stxFound) {
                if (currentChunk.hasFillbytes()) {
                  // Fillbyte nach ETX kopieren
                  pos++;
                  if (pos < bytes.length) {
                    oneByte = bytes[pos];
                    numByte = parseInt(oneByte, 16);
                    if (numByte === 0xff) {
                      currentChunk.add(numByte);
                    } else {
                      pos--;
                    }
                  } else {
                    pos--;
                  }
                }
                // CRC kopieren
                pos++;
                if (pos < bytes.length) {
                  oneByte = bytes[pos];
                  numByte = parseInt(oneByte, 16);
                  currentChunk.add(numByte);
                } else {
                  pos--;
                }
                if (currentChunk.hasFillbytes()) {
                  // Fillbyte nach CRC kopieren
                  pos++;
                  if (pos < bytes.length) {
                    oneByte = bytes[pos];
                    numByte = parseInt(oneByte, 16);
                    if (numByte === 0xff) {
                      currentChunk.add(numByte);
                    } else {
                      pos--;
                    }
                  } else {
                    pos--;
                  }
                }

                this.chunks.push(currentChunk);
                currentChunk = new ZsrChunk();
                currentChunk.knownDirection = parseInt(bytes[1], 16);
                stxFound = false;
              }
              break;
            default:
              currentChunk.add(numByte);
          }
        }
        if (currentChunk.hasBytes()) {
          this.chunks.push(currentChunk);
        }
      } else {
        const chunk = new ZsrChunk();
        chunk.knownDirection = 3;
        let pos = -1;
        while (pos < bytes.length - 1) {
          pos++;
          const oneByte = bytes[pos];
          const numByte = parseInt(oneByte, 16);
          chunk.add(numByte);
        }
        this.chunks.push(chunk);
      }
    }
    this.cd.markForCheck();
  }

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit(): void {}
}
