<div *ngIf="isRowValid()">
    <ng-container *ngFor="let p of into_typed()">
        <div *ngIf="p.transfers_pending || p.transfers===null || p.transfers===undefined || p.transfers.length<1 || (p.transfers.length===1 && p.transfers[0].is_master) || p.has_pending_transfers()">
            {{ fDate(p.datum_transfer, 'YYYY-MM-DD HH:mm:ss', true) }}
        </div>
        <div *ngIf="p.transfers_pending">
            <fa-icon [icon]="['far', 'spinner']"></fa-icon>
            Details werden abgerufen
        </div>
        <div *ngIf="!p.transfers_pending && p.transfers!==null && p.transfers!==undefined && p.transfers.length>0  && !(p.transfers.length===1 && p.transfers[0].is_master)"
             style="font-size: 10pt;">
            <div *ngFor="let t of p.transfers" class="d-flex justify-content-between"
                 [class.bg-secondary]="t.is_master"
                 [class.text-dark]="t.is_master"
            >
                <div class="me-1 me-1">{{t.nebenstelle_name}} ({{t.nebenstelle_nummer}})</div>
                <div *ngIf="t.zeitpunkt!==null && t.zeitpunkt!==undefined">{{fDate(t.zeitpunkt, 'YYYY-MM-DD HH:mm:ss', true)}}</div>
                <div *ngIf="t.zeitpunkt===null || t.zeitpunkt===undefined"><strong>Übertragung ausstehend</strong></div>
            </div>
        </div>
    </ng-container>
</div>
