import { Component, Input, OnInit } from '@angular/core';
import { IMachine } from '../../common/imachine';

@Component({
  selector: 'app-machine-display',
  template: ` <div *ngIf="isValid()" title="{{ title }}">{{ getInfo() }}</div> `
})
export class MachineDisplayComponent implements OnInit {
  @Input() public value: IMachine = null;
  @Input() public title = '';

  constructor() {}

  ngOnInit() {}

  isValid() {
    return this.value !== null && this.value !== undefined;
  }

  getInfo() {
    let ret = '';
    if (this.isValid()) {
      if (
        this.value.bezeichnung !== null &&
        this.value.bezeichnung !== undefined
      ) {
        ret = this.value.bezeichnung.trim();
      }
      if (ret === '') {
        if (this.value.code !== null && this.value.code !== undefined) {
          ret = this.value.code.trim();
        }
      }
    }
    return ret;
  }
}
