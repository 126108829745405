import { IMEListResult } from '../../maennl-commons/mecommon-list/imelist-result';
import { MERemoteList } from '../../maennl-commons/mecommon-list/meremote-list';
import { IMitschnittgeraet } from './imitschnittgeraet';
import { Mitschnittgeraet } from './mitschnittgeraet';
import { SimpleMitschnittgeraetFilter } from './simple-mitschnittgeraet-filter';

export class MitschnittgeraetList extends MERemoteList<Mitschnittgeraet> {
  __classname = 'MitschnittgeraetList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IMitschnittgeraet>
  ): MitschnittgeraetList {
    const newList = new MitschnittgeraetList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleMitschnittgeraetFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'label',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }
}
