import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TabTenantsComponent } from './tab-tenants/tab-tenants.component';
import { METabCardComponent, METool } from '../../maennl-commons';
import {TabCoreModulesComponent} from "./tab-core-modules/tab-core-modules.component";
import {TabTenantHostsComponent} from "./tab-tenant-hosts/tab-tenant-hosts.component";

type TTabType = null
    | TabTenantsComponent
    | TabCoreModulesComponent
    | TabTenantHostsComponent;

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss']
})
export class TenantsComponent implements OnInit, AfterViewInit {
  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;
  @ViewChild('tenantsTab', { static: false }) ka: TabTenantsComponent;

  private _currentTab: TTabType = null;

  public get currentTab(): TTabType {
    return this._currentTab;
  }

  public set currentTab(value: TTabType) {
    if (this._currentTab !== value) {
      if (!METool.isNullOrUndefined(this._currentTab)) {
        this._currentTab.onDeactivate();
      }
      this._currentTab = value;
      if (!METool.isNullOrUndefined(this._currentTab)) {
        this._currentTab.onActivate();
      }
    }
  }

  ngAfterViewInit(): void {
    this.currentTab = this.ka;
  }

  constructor() {}

  ngOnInit() {}

  activateTab(s: string) {
    const found = false;

    if (!found) {
      console.log(s);
    }
  }

  deactivateTab(s: string) {
    const found = false;

    if (!found) {
      console.log(s);
    }
  }

  public activate(tab: TTabType) {
    console.log(tab);
    this.currentTab = tab;
  }
}
