import {HttpClient} from "@angular/common/http";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {Injectable} from "@angular/core";
import {IIPSecInfo} from "./ipsec-info.interface";
import {Observable} from "rxjs";
import {IMEListResult, MEApiTool, MEResultMetaSort} from "../../../maennl-commons";
import {IMESimpleFilter} from "../../../maennl-commons/filters";
import {take} from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class IPSecInfoService {
    constructor(public http: HttpClient, public loginService: BenutzerService) {
    }

    public list(
        max = 50,
        offset = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<IIPSecInfo> = null,
        queryString = ''
    ): Observable<IMEListResult<IIPSecInfo>> {
        const uri=MEApiTool.buildListURL(
            '/api/meconnect/netzwerk/ipsecinfo',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<IIPSecInfo>>(
                uri,
                this.loginService.getRequestOptions()
            ).pipe(take(1));
    }
}