import {TIPSecInfoChild, TNullableIPSecInfoChild} from "./ipsec-info-child.types";
import {IPSecInfoChild} from "./ipsec-info-child.class";
import {MEGenericEntityFields} from "../../../maennl-commons";
import {TNullableNumber} from "../../../maennl-commons/tools/types";


export class IPSecInfoChildImpl{

    static fromResult(entity:TIPSecInfoChild,createIfNull=false):TNullableIPSecInfoChild{
        if(entity===null||entity===undefined){
            if (createIfNull){
                return new IPSecInfoChild();
            }
            return null;
        }

        const instance= new IPSecInfoChild();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addTextFields([
                    'dh_group','encr_alg','if_id_in','if_id_out','integ_alg','local_ts','mode','name','protocol',
                    'remote_ts','spi_in','spi_out','state'
                ])
                .addBooleanField('encap')
                .addLongFields([
                    'bytes_in','bytes_out','encr_keysize','install_time','life_time','packets_in','packets_out',
                    'rekey_time','req_id','unique_id','use_in','use_out'
                ])
        );
        return instance;
    }
}