import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MEGenericModalComponent } from '../../../maennl-commons/megeneric-modal';
import { MERichError, noop } from '../../../maennl-commons/tools';
import { ISim } from '../../common/isim';
import { Sim } from '../../common/sim';
import { RouterService } from '../../services/router.service';
import { SimEditorComponent } from '../../sim-editor/sim-editor.component';

@Component({
  selector: 'app-sim-display',
  template: `
    <div
      *ngIf="isValid()"
      title="Nummer {{ value.msidsn }} / IMSI: {{ value.imsi }}"
    >
      {{ value.label }}
      <button class="btn btn-sm" *ngIf="editable" (click)="editSim()">
        <i class="icofont icofont-ui-edit"></i>
      </button>
    </div>
  `,
  styles: [
    `
      :host button {
        font-size: 65%;
        margin: 0 0 0 1px;
        padding: 0;
      }
    `
  ]
})
export class SimDisplayComponent implements OnInit {
  @Input() public value: Sim = null;
  @Input() editable = true;

  constructor(
    public routerService: RouterService,
    public modalService: NgbModal
  ) {}

  ngOnInit() {}

  isValid() {
    return this.value !== null && this.value !== undefined;
  }

  public editSim() {
    this.routerService.getSim(this.value.id).subscribe(
      (s: ISim) => {
        SimEditorComponent.open(
          this.modalService,
          SimEditorComponent,
          s,
          MEGenericModalComponent.SIZE_MEDIUM
        ).then(
          (r: any) => {
            noop();
          },
          () => {
            noop();
          }
        );
      },
      (e) => {
        MERichError.throw(
          'Fehler!',
          'Zu dieser SIM-Karte konnten keine Informationen ermittelt werden.'
        );
      }
    );
  }
}
