import {MEGenericEntity} from "../../../maennl-commons";
import {ITerminalType} from "./terminal-type.interface";
import {TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";

export class TerminalType
    extends MEGenericEntity<ITerminalType>
    implements ITerminalType {

    id: TNullableNumber=null;
    code: TNullableString=null;
    display_text: TNullableString=null;
}