import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { TrackerMitschnitt } from '../../commons/tracker-mitschnitt.class';
import { MELocalizedComponent } from '../../../../maennl-commons';

@Component({
  selector: 'app-tracker-mitschnitt-entry-display',
  templateUrl: './tracker-mitschnitt-entry-display.component.html',
  styleUrls: ['./tracker-mitschnitt-entry-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerMitschnittEntryDisplayComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy
{
  private _entity: TrackerMitschnitt;

  get entity(): TrackerMitschnitt {
    return this._entity;
  }

  @Input()
  set entity(value: TrackerMitschnitt) {
    this._entity = value;
    console.log(this.entity.trace_data_obj);
    this.cd.markForCheck();
  }

  constructor(public cd: ChangeDetectorRef) {
    super();
  }
}
