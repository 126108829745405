import { Component, OnInit, ViewChild } from '@angular/core';
import { METabCardComponent } from '../../../maennl-commons/metab-card';
import { TabBenutzerComponent } from '../tab-benutzer/tab-benutzer.component';

@Component({
  selector: 'app-benutzer',
  templateUrl: './benutzer.component.html',
  styleUrls: ['./benutzer.component.scss']
})
export class BenutzerComponent implements OnInit {
  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;

  @ViewChild(TabBenutzerComponent, { static: false })
  usersTab: TabBenutzerComponent;

  constructor() {}

  ngOnInit() {}

  activateTab(id: string) {
    if ('tab-benutzer' === id) {
      if (this.usersTab !== null && this.usersTab !== undefined) {
        this.usersTab.onActivate();
      }
    }
  }

  deactivateTab(id: string) {
    if ('tab-benutzer' === id) {
      if (this.usersTab !== null && this.usersTab !== undefined) {
        this.usersTab.onDeactivate();
      }
    }
  }
}
