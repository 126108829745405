import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {TAJobs} from "./ta-jobs.class";
import {TAJobsFilter} from "./ta-jobs.filter";
import {TAJobsImpl} from "./ta-jobs.impl";
import {ITAJobs} from "./ta-jobs.interface";

export class TAJobsList extends MERemoteList<TAJobs>{
    __classname:string='TAJobsList';
    __instance = '';

    static fromListResult(
        res:IMEListResult<ITAJobs>
    ):TAJobsList{
        const newList= new TAJobsList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun: boolean = false, timeout: number = 400){
        super();
        this.simpleFilter = new TAJobsFilter();
        this.initList(autorun,timeout);
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'date_created',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true
    ) {
        super.doPopulateFromListResult(res,defaultSort,defaultOrder,clearFilter);
    }

    convertEntity(entity: any): any {
        return TAJobsImpl.fromResult(entity);
    }
}