import { IMEResultMetaSort } from './imeresult-meta-sort';

export class MEResultMetaSort implements IMEResultMetaSort {
  public field = '';
  public direction = '';

  add = false;
  idx = 0;

  constructor(
    field: string = '',
    direction: string = '',
    idx = 0,
    add = false
  ) {
    this.field = field;
    this.direction = direction;
    this.idx = idx;
    this.add = add;
  }
}
