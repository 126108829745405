import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { CheckHistoryList } from '../../common/check-history-list';
import { SysHealthService } from '../../services/sys-health.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Machine } from '../../common/machine';
import { Check } from '../../common/check';
import { MERichError, noop } from '../../../maennl-commons/tools';
import { CheckHistory } from '../../common/check-history';
import {
  MEDataTableRow,
  TMEDTGetExtraRowClassesCallback
} from '../../../maennl-commons/data-table/rows';
import {
  MEDTExtraColumn,
  MEDataTableComponent
} from '../../../maennl-commons/data-table';
import { MEDTMachineRendererComponent } from '../medtmachine-renderer/medtmachine-renderer.component';
import { MEDTCheckStatusRendererComponent } from '../medtcheck-status-renderer/medtcheck-status-renderer.component';
import { MEDTCheckRendererComponent } from '../medtcheck-renderer/medtcheck-renderer.component';
import { MEDTCheckNameRendererComponent } from '../medtcheck-name-renderer/medtcheck-name-renderer.component';
import { MEDTCheckServiceTypeRendererComponent } from '../medtcheck-service-type-renderer/medtcheck-service-type-renderer.component';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-sys-health-history-display',
  templateUrl: './sys-health-history-display.component.html',
  styleUrls: ['./sys-health-history-display.component.scss']
})
export class SysHealthHistoryDisplayComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'SysHealthHistoryDisplayComponent';
  __instance = '';

  machineR = MEDTMachineRendererComponent;
  statusRenderer = MEDTCheckStatusRendererComponent;
  checkR = MEDTCheckRendererComponent;
  checkNameDisplayR = MEDTCheckNameRendererComponent;
  serviceTypeR = MEDTCheckServiceTypeRendererComponent;

  @Input() machine: Machine = null;
  @Input() check: Check = null;
  @Input() extraColumns: MEDTExtraColumn[] = [];
  @Input() displayMachineColumn = false;
  @Input() displayCheckColumn = true;
  @Input() displayServiceTypeColumn = true;

  @ViewChild(MEDataTableComponent, { static: false }) dt = null;

  public verlauf: CheckHistoryList = new CheckHistoryList();

  constructor(
    public healthService: SysHealthService,
    public toastr: ToastrService,
    protected cd: ChangeDetectorRef,
    public modalService: NgbModal,
    public benutzerService: BenutzerService
  ) {}

  rowClassGetter: TMEDTGetExtraRowClassesCallback<CheckHistory> = (
    row: MEDataTableRow<CheckHistory>,
    idx
  ) => {
    if (
      row !== null &&
      row !== undefined &&
      row.data !== null &&
      row.data !== undefined &&
      row.data.result !== null &&
      row.data.result !== undefined &&
      row.data.result.code !== null &&
      row.data.result.code !== undefined
    ) {
      return 'check-' + row.data.result.code.trim();
    }
    return '';
  };

  ngAfterViewInit() {
    if (this.dt !== null) {
      this.dt.extraRowClasses = this.rowClassGetter;
    }
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.verlauf.onUpdateRequired.subscribe((list: CheckHistoryList) => {
        this.healthService
          .getCheckHistory(
            this.check,
            this.machine,
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.searchString
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
            },
            (e) => {
              console.log(e);
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste des verfügbaren Verlaufs konnte nicht abgerufen werden'
              );
            }
          );
      }, undefined)
    );

    if (this.dt !== null) {
      this.dt.extraRowClasses = this.rowClassGetter;
    }
  }

  onActivate() {
    this.verlauf.start();
  }

  onDeactivate() {
    noop();
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.verlauf.release();
  }
}
