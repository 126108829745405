import {
  MEGenericEntity,
  MEGenericEntityFields,
} from '../../../maennl-commons';
import { ITraceData, ITrackerMitschnitt } from './itracker-mitschnitt';

export class TrackerMitschnitt
  extends MEGenericEntity<ITrackerMitschnitt>
  implements ITrackerMitschnitt
{
  id: number = null;
  imei: string = null;
  ip: string = null;
  tenant: number = null;
  trace_data: string = null;
  vehicle: number = null;
  zeitpunkt: Date = null;

  trace_data_obj: ITraceData = null;

  static fromResult(
    entity: ITrackerMitschnitt,
    createIfNull = false
  ): TrackerMitschnitt {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new TrackerMitschnitt();
      }
      return null;
    }

    const instance = new TrackerMitschnitt();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addLongField('tenant')
        .addLongField('vehicle')
        .addDateField('zeitpunkt')
        .addTextField('imei')
        .addTextField('ip')
        .addTextField('trace_data'),
      e => {
        const x = e as TrackerMitschnitt;
        x.trace_data_obj = JSON.parse(x.trace_data) as ITraceData;
      }
    );
    return instance;
  }
}
