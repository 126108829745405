import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { guiVersion } from '../app.version';
import { PasswordDialogComponent } from '../benutzer/password-dialog/password-dialog.component';
import { BenutzerService } from '../benutzer/services/benutzer.service';
import {
  IMEActionResponse,
  MEActionResponse,
  MEGenericModalComponent,
  MELocalizedComponent,
  MEPubSubService,
  MESubscriptionHelper,
  METool,
  MEVersionTool,
  noop
} from '../maennl-commons';
import { SchrankeComponent } from '../telefonanlage/schranke/schranke.component';
import { SysInfoService } from './sys-info.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NavComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy
{
  __classname = 'NavComponent';
  __instance = '';

  updateAvailable = false;
  developmentMode = false;
  private _onlineMode = null;
  appVersion = '';
  isLoggedIn = false;
  guiVersion = guiVersion;

  private requiredVersion: any;

  constructor(
    protected cd: ChangeDetectorRef,
    public swUpdate: SwUpdate,
    public sysinfoService: SysInfoService,
    public toastr: ToastrService,
    public pubsub: MEPubSubService,
    public benutzerService: BenutzerService,
    public router: Router,
    public modalService: NgbModal
  ) {
    super();
  }

  get onlineMode(): boolean {
    return this._onlineMode;
  }

  set onlineMode(value: boolean) {
    if (value !== this.onlineMode) {
      if (value) {
        if (this._onlineMode != null) {
          this.toastr.success(this.t('Die Anwendung ist nun wieder online.'));
        }
        this._onlineMode = value;
        if (this.swUpdate !== null && this.swUpdate !== undefined) {
          if (this.swUpdate.isEnabled) {
            this.swUpdate.checkForUpdate().then(() => {
              this.cd.markForCheck();

              // noop
            });
          }
        }

        this.isLoggedIn = this.benutzerService.isLoggedIn();
      } else {
        this._onlineMode = value;
        this.toastr.warning(
          this.t(
            'Die Anwendung ist nun offline. ' +
              'Alle angezeigten Informationen stammen aus dem Cache. ' +
              'Ab jetzt vorgenommene Änderungen werden nicht aktiv!'
          ),
          this.t('Offline-Modus aktiviert'),
          {
            timeOut: 15000
          }
        );
      }

      this.cd.markForCheck();
    }
  }

  checkGui(requiredVersion: string) {
    if (MEVersionTool.isSameOrBetterByString(guiVersion, requiredVersion)) {
      return;
    }

    if (this.swUpdate !== null && this.swUpdate !== undefined) {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate().then(noop);
        return;
      }
    }
    this.toastr.warning(
      this.t(
        'Die Anwendung ist veraltet. Bitte löschen Sie ggf. den Cache und laden Sie die komplette Seite neu.'
      ),
      this.t('Anwendung veraltet'),
      {
        timeOut: 15000
      }
    );
  }

  // noinspection JSUnusedGlobalSymbols
  updateSysInfo() {
    this.sysinfoService.loadSysInfo().subscribe((s: IMEActionResponse) => {
      const r = MEActionResponse.fromRawActionResponse(s);
      if (r.success) {
        this.developmentMode = r.params['developmentMode'];
        this.appVersion = r.params['appVersion'];
        this.checkGui(r.params['guiRequired']);
      }
      this.cd.markForCheck();
    });
  }

  ngOnInit(): void {
    MESubscriptionHelper.add(
      this,
      this.pubsub.loginChange.subscribe(() => {
        if (this.onlineMode) {
          this.isLoggedIn = this.benutzerService.isLoggedIn();
          this.cd.markForCheck();
        }
      })
    );

    if (this.swUpdate.isEnabled) {
      MESubscriptionHelper.add(
        this,
        interval(6 * 60 * 60).subscribe(() => this.swUpdate.checkForUpdate())
      );
    }

    MESubscriptionHelper.add(
      this,
      this.pubsub.onlineStateChange.subscribe((s: boolean) => {
        console.log('Onlinestatus gewechselt');
        this.onlineMode = s;
      })
    );

    MESubscriptionHelper.add(
      this,
      this.pubsub.sysInfoChange.subscribe((si: any) => {
        const k = Object.keys(si);
        this.developmentMode =
          k.indexOf('developmentMode') >= 0 ? si['developmentMode'] : false;
        this.appVersion = k.indexOf('appVersion') >= 0 ? si['appVersion'] : '';
        if (k.indexOf('guiRequired') >= 0) {
          this.requiredVersion = si['guiRequired'];
          this.checkGui(si['guiRequired']);
        }
        this.cd.markForCheck();
      })
    );

    this.onlineMode = this.sysinfoService.isOnline();
    this.sysinfoService.fetchSysInfo();

    if (this.swUpdate !== null && this.swUpdate !== undefined) {
      if (this.swUpdate.isEnabled) {
        MESubscriptionHelper.add(
          this,
          this.swUpdate.versionUpdates.subscribe((s: VersionEvent) => {
            console.debug(s);
            if (s.type === 'VERSION_READY') {
              this.updateAvailable = true;
              this.cd.markForCheck();
            }
          })
        );
      }
    }

    this.isLoggedIn = this.benutzerService.isLoggedIn();

    this.cd.markForCheck();
  }

  ngOnDestroy() {
    MESubscriptionHelper.release(this);

    super.ngOnDestroy();
  }

  doUpdate() {
    if (this.swUpdate !== null && this.swUpdate !== undefined) {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.activateUpdate().catch(undefined);
      }
    }
    window.location.reload();
  }

  doLogout() {
    this.isLoggedIn = false;
    this.benutzerService.doLogout(true);
    this.router.navigate(['login']).catch(() => {
      this.router.navigateByUrl('/').catch(undefined);
    });
  }

  updateProfile() {
    // TODO: Implement me!
  }

  changePassword() {
    PasswordDialogComponent.open(
      this.modalService,
      PasswordDialogComponent,
      null,
      MEGenericModalComponent.SIZE_SMALL
    ).then(
      () => {
        this.doLogout();
      },
      () => {}
    );
  }

  public schranke() {
    if (this.isLoggedIn) {
      SchrankeComponent.open(this.modalService, SchrankeComponent).then(
        noop,
        noop
      );
    }
  }

  swRefresh() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const o = this;
    o.toastr.info(
      'Es wird versucht, die Systemkomponenten neu zu initialisieren...',
      'Anwendung wird neu initialisiert'
    );
    if ('serviceWorker' in window.navigator) {
      try {
        if (
          !METool.isNullOrUndefined(window.navigator.serviceWorker.controller)
        ) {
          o.toastr.info(
            'Sende Befehl zum Löschen des Caches an die Dienstkomponente',
            'Anwendung wird neu initialisiert'
          );
          window.navigator.serviceWorker.controller.postMessage(
            'cmd-clear-cache'
          );
        }
        setTimeout(() => {
          o.toastr.info(
            'Sende Befehl zum Entfernen der Dienstkomponente',
            'Anwendung wird neu initialisiert'
          );
          window.navigator.serviceWorker
            .getRegistration('/sw-custom.js')
            .then((sr: ServiceWorkerRegistration) => {
              this.benutzerService.doLogout(false);
              localStorage.clear();
              sessionStorage.clear();
              if (!METool.isNullOrUndefined(sr)) {
                if (!METool.isNullOrUndefined(sr.unregister)) {
                  sr.unregister().then(function (s: boolean) {
                    if (s) {
                      o.toastr.success(
                        'Erfolg.',
                        'Anwendung wird neu initialisiert'
                      );
                    } else {
                      o.toastr.error(
                        'Fehlgeschlagen.',
                        'Anwendung wird neu initialisiert'
                      );
                    }
                    setTimeout(() => {
                      window.location.reload();
                    }, 1500);
                  });
                } else {
                  setTimeout(() => {
                    window.location.reload();
                  }, 1500);
                }
              } else {
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              }
            });
          navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.forEach((registration) => {
              if (!METool.isNullOrUndefined(registration.unregister)) {
                registration.unregister().finally();
              }
            });
          });
        }, 1500);
      } catch (e) {
        console.log(e);
      }
    } else {
      setTimeout(() => {
        this.benutzerService.doLogout(false);
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
      }, 1500);
    }
  }
}
