import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SysInfoService } from '../sys-info.service';
import { MEPubSubService } from '../../maennl-commons/services';

@Component({
  selector: 'app-side-bar-group',
  template: `
    <div class="group">
      <a class="link" (click)="toggleGroup()" *ngIf="navUsesGroups()">
        <i
          class="fa-fw far"
          [class.fa-caret-down]="!groupOpen"
          [class.fa-caret-right]="groupOpen"
        ></i>
        {{ label }}
      </a>
      <div
        class="nav-block"
        [class.group-content]="navUsesGroups()"
        *ngIf="!navUsesGroups() || groupOpen"
      >
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./side-bar-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarGroupComponent implements OnInit, OnDestroy {
  private _groupOpen = false;

  private _link = '/';
  private _icon = '';
  private _label = '';
  private _prefix = '';
  private readonly msub: Subscription;

  get groupOpen(): boolean {
    return this._groupOpen;
  }

  @Input()
  set groupOpen(value: boolean) {
    if (this._groupOpen !== value) {
      this._groupOpen = value;
      this.cd.markForCheck();
    }
  }

  get prefix(): string {
    return this._prefix;
  }

  @Input()
  set prefix(value: string) {
    if (this.prefix !== value) {
      this._prefix = value;
      this.cd.markForCheck();
      this.checkRoute();
    }
  }

  get link(): string {
    return this._link;
  }

  @Input()
  set link(value: string) {
    this._link = value;
    this.cd.markForCheck();
  }

  get icon(): string {
    return this._icon;
  }

  @Input()
  set icon(value: string) {
    this._icon = value;
    this.cd.markForCheck();
  }

  get label(): string {
    return this._label;
  }

  @Input()
  set label(value: string) {
    this._label = value;
    this.cd.markForCheck();
  }

  constructor(
    public cd: ChangeDetectorRef,
    public router: Router,
    public route: ActivatedRoute,
    public sysInfo: SysInfoService,
    public pubsub: MEPubSubService
  ) {
    this.msub = this.router.events.subscribe((x) => {
      if (x instanceof NavigationEnd) {
        this.checkRoute(x.urlAfterRedirects);
      }
    });
  }

  ngOnInit() {
    this.checkRoute();
  }

  checkRoute(url: string = '') {
    if (
      this._prefix === null ||
      this._prefix === undefined ||
      this._prefix.trim() === ''
    ) {
      return;
    }

    if (this.route === null || this.route === undefined) {
      return;
    }

    if (url === '') {
      url = ('' + window.location.hash).replace('#', '').trim();
    }
    if (url.toLocaleLowerCase().startsWith(this.prefix.toLocaleLowerCase())) {
      this.groupOpen = true;
    }
  }

  toggleGroup() {
    this._groupOpen = !this._groupOpen;
    this.cd.markForCheck();
    if (this._groupOpen) {
      if (
        this._link !== null &&
        this._link !== undefined &&
        this._link.trim() !== ''
      ) {
        this.router.navigate([this._link]).finally();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.msub !== null && this.msub !== undefined) {
      this.msub.unsubscribe();
    }
  }

  navUsesGroups() {
    return true;
  }
}
