import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {IMEListResult, MEApiTool, MEResultMetaSort} from "../../../maennl-commons";
import {IMESimpleFilter} from "../../../maennl-commons/filters";
import {ITenantSettings} from "./tenant-settings.interface";
import {Observable, take} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class TenantSettingsService{

    constructor(
        public http:HttpClient,
        public loginService:BenutzerService
    ) {
    }

    public list(
        max=50,
        offset=0,
        sort: MEResultMetaSort[]=[],
        filter: IMESimpleFilter<ITenantSettings>=null,
        queryString=''
    ):Observable<IMEListResult<ITenantSettings>>{
        const uri = MEApiTool.buildListURL(
            '/api/webfuel/core/tenantsettings',
            max,
            offset,
            sort,
            filter,
            queryString
        );

        return this.http
            .get<IMEListResult<ITenantSettings>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }
}