import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  MEDataTableComponent,
  MEDataTableRow,
  MERowActionParams,
  TMEDTGetExtraRowClassesCallback
} from '../../../maennl-commons/data-table';
import { TestPingList } from '../../common/test-ping-list';
import { SysHealthService } from '../../services/sys-health.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';
import { TestPing } from '../../common/test-ping';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { MERichError, noop } from '../../../maennl-commons/tools';
import { MEDTFileSystemSpaceRendererComponent } from '../medtfile-system-space-renderer/medtfile-system-space-renderer.component';
import { SysHealthTestPingDeviceRendererComponent } from '../sys-health-test-ping-device-renderer/sys-health-test-ping-device-renderer.component';
import { SysHealthTestPingSystemRendererComponent } from '../sys-health-test-ping-system-renderer/sys-health-test-ping-system-renderer.component';
import { SysHealthTestPingCPURendererComponent } from '../sys-health-test-ping-cpurenderer/sys-health-test-ping-cpurenderer.component';
import { SysHealthTestPingFileSystemRendererComponent } from '../sys-health-test-ping-file-system-renderer/sys-health-test-ping-file-system-renderer.component';
import { SysHealthTestPingModemRendererComponent } from '../sys-health-test-ping-modem-renderer/sys-health-test-ping-modem-renderer.component';
import { SysHealthTestPingDeviceDetailsPopupComponent } from '../sys-health-test-ping-device-details-popup/sys-health-test-ping-device-details-popup.component';
import { MEGenericModalComponent } from '../../../maennl-commons/megeneric-modal';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-sys-health-test-pings-display',
  templateUrl: './sys-health-test-pings-display.component.html',
  styleUrls: ['./sys-health-test-pings-display.component.scss']
})
export class SysHealthTestPingsDisplayComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'SysHealthTestPingsDisplayComponent';
  __instance = '';

  deviceRenderer = SysHealthTestPingDeviceRendererComponent;
  systemRenderer = SysHealthTestPingSystemRendererComponent;
  cpuRenderer = SysHealthTestPingCPURendererComponent;
  filesystemRenderer = SysHealthTestPingFileSystemRendererComponent;
  modemRenderer = SysHealthTestPingModemRendererComponent;

  @Input()
  detailMode = false;

  @Input()
  device = 0;

  @Input()
  autostart = false;

  @ViewChild(MEDataTableComponent, { static: false }) dt = null;

  public verlauf: TestPingList = new TestPingList();

  fileSystemSpaceRenderer = MEDTFileSystemSpaceRendererComponent;

  constructor(
    public healthService: SysHealthService,
    public toastr: ToastrService,
    protected cd: ChangeDetectorRef,
    public modalService: NgbModal,
    public benutzerService: BenutzerService
  ) {
    super();
  }

  rowClassGetter: TMEDTGetExtraRowClassesCallback<TestPing> = (
    row: MEDataTableRow<TestPing>,
    idx
  ) => {
    if (
      row !== null &&
      row !== undefined &&
      row.data !== null &&
      row.data !== undefined &&
      row.data.zeitpunkt !== null &&
      row.data.zeitpunkt !== undefined
    ) {
      if (!this.detailMode) {
        const jetzt = new Date();
        if (
          Math.abs(jetzt.getTime() - row.data.zeitpunkt.getTime()) >
          12 * 60 * 60 * 1000
        ) {
          return 'row-danger';
        }
      }
    }
    return '';
  };

  ngAfterViewInit() {
    if (this.dt !== null) {
      this.dt.extraRowClasses = this.rowClassGetter;
    }
    if (this.autostart) {
      this.onActivate();
    }
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.verlauf.onUpdateRequired.subscribe((list: TestPingList) => {
        list.simpleFilter.device = this.device;
        list.simpleFilter.showDetails = this.detailMode;

        this.healthService
          .getTestPings(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.searchString
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
            },
            (e) => {
              console.log(e);
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste des verfügbaren Testpings konnte nicht abgerufen werden'
              );
            }
          );
      }, undefined)
    );

    if (this.dt !== null) {
      this.dt.extraRowClasses = this.rowClassGetter;
    }
  }

  onActivate() {
    this.verlauf.start();
  }

  onDeactivate() {
    noop();
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.verlauf.release();
  }

  showDetails($event: MERowActionParams<TestPing>) {
    if ($event.row.data.id !== null && $event.row.data.id !== undefined) {
      SysHealthTestPingDeviceDetailsPopupComponent.open(
        this.modalService,
        SysHealthTestPingDeviceDetailsPopupComponent,
        $event.row.data,
        MEGenericModalComponent.SIZE_LARGE
      ).then(() => {
        this.verlauf.start();
      }, noop);
    }
  }

  public getSettingsId() {
    if (this.detailMode) {
      return 'syshealth-test-pings-details';
    }
    return 'syshealth-test-pings';
  }
}
