import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'me-option',
  template: `
    <div class="me-option">
      <div class="me-option-header" *ngIf="hasHeader()">
        <i [ngClass]="icon" *ngIf="hasIcon()"></i>
        {{ title }}
      </div>
      <div
        class="me-option-body"
        [ngClass]="extraBodyClass"
        [ngStyle]="extraBodyStyles"
      >
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      .me-option {
        padding: 2px;
      }

      .me-option-header {
        font-size: 0.8em;
        border-bottom: 1px solid silver;
        margin-bottom: 2px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MEOptionComponent implements OnInit {
  private _title = '';
  private _icon: any = '';

  private _extraBodyClass: any = '';
  private _extraBodyStyles: any = {};

  public get extraBodyStyles(): any {
    return this._extraBodyStyles;
  }

  @Input()
  public set extraBodyStyles(value: any) {
    this._extraBodyStyles = value;
    this.cd.markForCheck();
  }

  public get extraBodyClass(): any {
    return this._extraBodyClass;
  }

  @Input()
  public set extraBodyClass(value: any) {
    this._extraBodyClass = value;
    this.cd.markForCheck();
  }

  public get icon(): string {
    return this._icon;
  }

  @Input()
  public set icon(value: string) {
    this._icon = value;
    this.cd.markForCheck();
  }

  public get title(): string {
    return this._title;
  }

  @Input()
  public set title(value: string) {
    this._title = value;
    this.cd.markForCheck();
  }

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit() {}

  public hasHeader() {
    if (this._title === null || this._title === undefined) {
      return false;
    }
    return this._title.trim() !== '';
  }

  public hasIcon() {
    if (this._icon === null || this._icon === undefined) {
      return false;
    }
    if (Array.isArray(this._icon)) {
      return this._icon.length > 0;
    }
    return this._icon.trim() !== '';
  }
}
