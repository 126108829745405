import { IMEListResult, MERemoteList } from '../../../maennl-commons';
import { IRBTerminal } from './irbterminal';
import { RBTerminal } from './rbterminal';
import { SimpleRBTerminalFilter } from './simple-rbterminal-filter';

export class RBTerminalList extends MERemoteList<RBTerminal> {
  __classname = 'RBCustomerList';
  __instance = '';

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleRBTerminalFilter();
    this.initList(autorun, timeout);
  }

  static fromListResult(res: IMEListResult<IRBTerminal>): RBTerminalList {
    const newList = new RBTerminalList();
    newList.populateFromListResult(res);
    return newList;
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'display_text',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return RBTerminal.fromResult(entity);
  }
}
