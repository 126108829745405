<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'books']"></fa-icon>
</ng-template>
<me-area-heading
        *ngIf="currentDokument!==null && currentDokument!==undefined"
        [icon]="ahIcon"
        [title]="currentDokument.title"
        [subtitle]="currentDokument.subtitle"
        [toptitle]="'Dokumentation'"
        [editable]="editMode"
        (titleChanged)="currentDokument.title=$event"
        (subTitleChanged)="currentDokument.subtitle=$event"
>
    <div class="btn-group" role="group">
        <button type="button" class="btn btn-secondary"
                *ngIf="!editMode"
                (click)="createDokument()"
        >
            <fa-icon [icon]="['far', 'file-plus']"></fa-icon>
            Dokument anlegen
        </button>
        <button type="button"
                class="btn btn-secondary" *ngIf="!editMode && canEdit()"
                (click)="editDokument()"
        >
            <fa-icon [icon]="['far', 'edit']"></fa-icon>
            Dokument bearbeiten
        </button>
        <button type="button" class="btn btn-danger"
                *ngIf="editMode && currentDokument.slug!=='' && currentDokument.id>0"
                (click)="deleteDokument()"
        >
            <fa-icon [icon]="['far', 'trash']"></fa-icon>
            Seite löschen
        </button>
        <button type="button" class="btn btn-secondary"
                *ngIf="editMode"
                (click)="cancelEdit()"
        >
            <fa-icon [icon]="['far', 'window-close']"></fa-icon>
            Abbrechen
        </button>
        <button type="button"
                class="btn btn-primary"
                *ngIf="editMode"
                (click)="storeDocument()"
                [disabled]="!canSave()"
                [class.disabled]="!canSave()"
        >
            <i class="far fa-save"></i> Änderungen speichern
        </button>
    </div>
</me-area-heading>

<me-card>
    <markdown
            *ngIf="currentDokument!==null && currentDokument!==undefined && !editMode"
            [data]="currentDokument.content" lineNumbers ngPreserveWhitespaces></markdown>

    <md-editor name="Content"
               *ngIf="editMode"
               [(ngModel)]="currentDokument.content"
               [mode]="editormode"
               [options]="editorOptions"
               required
               [height]="'500px'"
    ></md-editor>

    <div *ngIf="editMode">
        <h2>Optionen</h2>
        <me-boolean-field [(value)]="currentDokument.editable"
                          [label]="'Dokument kann von anderen verändert werden'"
        ></me-boolean-field>
        <me-text-field [label]="'Slug'" [(value)]="currentDokument.slug"></me-text-field>
    </div>
</me-card>
