import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { AbrechnungRolfes } from './abrechnung-rolfes';
import { IAbrechnungRolfes } from './iabrechnung-rolfes';
import { SimpleAbrechnungRolfesFilter } from './simple-abrechnung-rolfes-filter';

export class AbrechnungRolfesList extends MERemoteList<AbrechnungRolfes> {
  __classname = 'AbrechnungRolfesList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IAbrechnungRolfes>
  ): AbrechnungRolfesList {
    const newList = new AbrechnungRolfesList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleAbrechnungRolfesFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'periode',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return AbrechnungRolfes.fromResult(entity);
  }
}
