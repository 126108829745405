import {MissingTerminalTransactions} from "./missing-terminal-transactions.class";
import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {MissingTerminalTransactionsFilter} from "./missing-terminal-transactions.filter";
import {MissingTerminalTransactionsImpl} from "./missing-terminal-transactions.impl";
import {IMissingTerminalTransactions} from "./missing-terminal-transactions.interface";

export class MissingTerminalTransactionsList extends MERemoteList<MissingTerminalTransactions>{
    __classname:string='MissingTerminalTransactionsList';
    __instance:string='';

    static fromListResult(
        res:IMEListResult<IMissingTerminalTransactions>
    ):MissingTerminalTransactionsList{
        const newList= new MissingTerminalTransactionsList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun:boolean=false,timeout:number=400){
        super();
        this.simpleFilter = new MissingTerminalTransactionsFilter();
        this.initList(autorun,timeout);
    }

    public doPopulateFromListResult(
        res:IMEListResult<any>,
        defaultSort:string='id',
        defaultOrder:string='asc',
        clearFilter: boolean=true
    ) {
        super.doPopulateFromListResult(res,defaultSort,defaultOrder,clearFilter);
    }

    convertEntity(entity: any): any {
        return MissingTerminalTransactionsImpl.fromResult(entity);
    }
}