import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs';
import {BenutzerService} from '../../../benutzer/services/benutzer.service';
import {IMESimpleFilter} from '../../../maennl-commons/filters';
import {IMEListResult} from '../../../maennl-commons';
import {MEResultMetaSort} from '../../../maennl-commons';
import {IMEActionResponse, MEApiTool} from '../../../maennl-commons';
import {CSDServerBridge} from '../commons/csdserver-bridge';
import {CSDServerClient} from '../commons/csdserver-client';
import {ICSDServerBridge} from '../commons/icsdserver-bridge';
import {ICSDServerClient} from '../commons/icsdserver-client';
import {ICSDServerClientType} from '../commons/icsdserver-client-type';

@Injectable({
    providedIn: 'root'
})
export class CSDServerService {
    constructor(public http: HttpClient, public loginService: BenutzerService) {
    }

    public listClients(
        max: number = 50,
        offset: number = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<ICSDServerClient> = null,
        queryString = ''
    ): Observable<IMEListResult<ICSDServerClient>> {
        const uri = MEApiTool.buildListURL(
            '/api/meconnect/csd/clients',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<ICSDServerClient>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public listClientTypes(
        max: number = 50,
        offset: number = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<ICSDServerClientType> = null,
        queryString = ''
    ): Observable<IMEListResult<ICSDServerClientType>> {
        const uri = MEApiTool.buildListURL(
            '/api/meconnect/csd/client-types',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<ICSDServerClientType>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public listBridges(
        max: number = 50,
        offset: number = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<ICSDServerBridge> = null,
        queryString = ''
    ): Observable<IMEListResult<ICSDServerBridge>> {
        const uri = MEApiTool.buildListURL(
            '/api/meconnect/csd/bridges',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<ICSDServerBridge>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public storeClient(entity: CSDServerClient): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            '/api/meconnect/csd/clients',
            entity.id
        );

        if (entity.id === null || entity.id === undefined || entity.id < 1) {
            return this.http
                .post<IMEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(take(1));
        }
        return this.http
            .put<IMEActionResponse>(
                uri,
                entity,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public storeBridge(entity: CSDServerBridge): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            '/api/meconnect/csd/bridges',
            entity.id
        );

        if (entity.id === null || entity.id === undefined || entity.id < 1) {
            return this.http
                .post<IMEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(take(1));
        }
        return this.http
            .put<IMEActionResponse>(
                uri,
                entity,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public loadClient(id: number): Observable<CSDServerClient> {
        const uri = MEApiTool.buildEntityURL('/api/meconnect/csd/clients', id);
        return this.http
            .get<ICSDServerClient>(uri, this.loginService.getRequestOptions())
            .pipe(
                map((c) => {
                    return CSDServerClient.fromResult(c);
                }),
                take(1)
            );
    }

    public removeClient(entity: CSDServerClient): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            '/api/meconnect/csd/clients',
            entity.id
        );
        return this.http
            .delete<IMEActionResponse>(uri, this.loginService.getRequestOptions())
            .pipe(take(1));
    }

    public loadBridge(id: number): Observable<CSDServerBridge> {
        const uri = MEApiTool.buildEntityURL('/api/meconnect/csd/bridges', id);
        return this.http
            .get<ICSDServerBridge>(uri, this.loginService.getRequestOptions())
            .pipe(
                map((c) => {
                    return CSDServerBridge.fromResult(c);
                }),
                take(1)
            );
    }

    public removeBridge(entity: CSDServerBridge): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            '/api/meconnect/csd/bridges',
            entity.id
        );
        return this.http
            .delete<IMEActionResponse>(uri, this.loginService.getRequestOptions())
            .pipe(take(1));
    }

    public syncSims(): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildActionURL(
            '/api/meconnect/csd/clients',
            null,
            '_sync_sims'
        );
        return this.http
            .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
            .pipe(take(1));
    }

    public connectClient(entity: CSDServerClient): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildActionURL(
            '/api/meconnect/csd/clients',
            entity.id,
            '_connect'
        );
        return this.http
            .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
            .pipe(take(1));
    }

    public disconnectClient(
        entity: CSDServerClient
    ): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildActionURL(
            '/api/meconnect/csd/clients',
            entity.id,
            '_disconnect'
        );
        return this.http
            .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
            .pipe(take(1));
    }

    public checkConnection(
        entity: CSDServerClient
    ): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildActionURL(
            '/api/meconnect/csd/clients',
            entity.id,
            '_is_connected'
        );
        return this.http
            .get<IMEActionResponse>(uri, this.loginService.getRequestOptions())
            .pipe(take(1));
    }

    public cutConnection(
        entity: CSDServerBridge
    ): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildActionURL(
            '/api/meconnect/csd/bridges',
            entity.id,
            '_disconnect'
        );

        return this.http.post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions()).pipe(take(1));
    }

    public runCommand(
        entity: CSDServerClient,
        cmd: string
    ): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildActionURL(
            '/api/meconnect/csd/clients',
            entity.id,
            '_run_at_command'
        );
        return this.http
            .post<IMEActionResponse>(
                uri,
                {
                    command: cmd
                },
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public clearBridgeOnlineState(
        entity: CSDServerBridge
    ): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildActionURL(
            '/api/meconnect/csd/bridges',
            entity.id,
            '_clear_online'
        );
        return this.http
            .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
            .pipe(take(1));
    }
}
