import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-device-field',
  templateUrl: './device-field.component.html',
  styleUrls: ['./device-field.component.scss']
})
export class DeviceFieldComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
