import { MEGenericEntity } from '../../maennl-commons/megeneric-entity/megeneric-entity';
import { MEGenericEntityFields } from '../../maennl-commons/megeneric-entity/megeneric-entity-field';
import { ISimProvider } from './isim-provider';

export class SimProvider
  extends MEGenericEntity<ISimProvider>
  implements ISimProvider
{
  id = 0;
  name = '';
  network = '';

  static fromResult(
    entity: ISimProvider,
    createIfNull: boolean = false
  ): SimProvider {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new SimProvider();
      }
      return null;
    }

    const instance: SimProvider = new SimProvider();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addField('id', 'long')
        .addField('name', 'string')
        .addField('network', 'string')
    );
    return instance;
  }

  constructor(id: number = 0, name: string = '', network: string = '') {
    super();
    this.id = id;
    this.name = name;
    this.network = network;
  }
}
