<div class="modal-header">
    <h4 class="modal-title">Brücke bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">

    <app-csdserver-client-field [label]="'Client 1 (Quelle, i.d.R. VCom)'"
                                [(value)]="entity.client1"
                                [disabled]="entity.id>0"
                                [nullable]="false"></app-csdserver-client-field>
    <app-csdserver-client-field [label]="'Client 2 (Ziel)'" [(value)]="entity.client2"></app-csdserver-client-field>
    <me-text-field [label]="'Login-Kennung'" [(value)]="entity.login"
                   [addonicon]="['far fa-calculator']"
                   [addonlabel]="['berechnen']"
                   [enableAddon]="true"
                   [disabled]="entity.id>0"
                   (addonClick)="addonClick('login',$event)"
    ></me-text-field>
    <me-text-field [label]="'Info'" [(value)]="entity.info"></me-text-field>
    <me-num-field [label]="'Puffergröße'" [(value)]="entity.bufferSize"></me-num-field>
    <me-num-field [label]="'Timeout (s)'" [(value)]="entity.timeout"></me-num-field>
    <me-boolean-field [label]="'Brücke aktiviert'" [(value)]="entity.enabled"></me-boolean-field>
    <me-boolean-field [label]="'Trennen beim Verlassen'" [(value)]="entity.releaseOnLeave"></me-boolean-field>
    <me-boolean-field [label]="'BUSY wenn Ziel offline'" [(value)]="entity.failIfNotOnline"></me-boolean-field>
    <me-text-field [label]="'Baudrate für CONNECT'" [(value)]="entity.sendPseudoBaudrate"></me-text-field>
    <me-num-field [label]="'Wartezeit für Antworten (ms)'" [(value)]="entity.delayForResponse"></me-num-field>
    <me-num-field [label]="'Wartezeit für CONNECT (ms)'" [(value)]="entity.delayForConnect"></me-num-field>
    <me-select-field [label]="'Zeilenende (AT-Modus)'" [value]="entity.lineEndings" (valueChange)="entity.lineEndings=$event">
        <me-select-field-option   [label]="'in LF einbetten'" [value]="'LF'"></me-select-field-option>
        <me-select-field-option [label]="'in CR einbetten'" [value]="'CR'"></me-select-field-option>
        <me-select-field-option [label]="'in CRLF einbetten'" [value]="'CRLF'"></me-select-field-option>
        <me-select-field-option [label]="'in LFCR einbetten'" [value]="'LFCR'"></me-select-field-option>
        <me-select-field-option [label]="'LF'" [value]="'LF2'"></me-select-field-option>
        <me-select-field-option [label]="'CR'" [value]="'CR2'"></me-select-field-option>
        <me-select-field-option [label]="'CRLF'" [value]="'CRLF2'"></me-select-field-option>
        <me-select-field-option [label]="'LFCR'" [value]="'LFCR2'"></me-select-field-option>
    </me-select-field>
</div>
<div class="modal-footer d-flex justify-content-between">
    <div>
        <button type="button" class="btn btn-danger" (click)="delete()" *ngIf="entity.id>0">
            <i class="icofont icofont-delete"></i> Löschen
        </button>
        <button type="button" class="btn btn-info" (click)="set_offline()" *ngIf="entity.id>0 && entity.online">
            <i class="fw far fa-globe-europe"></i> Online-Status löschen
        </button>
    </div>
    <div>
        <button type="button" class="btn btn-secondary" (click)="cancel()">
            <i class="icofont icofont-close"></i> Schließen
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="!canSave()">
            <i class="icofont icofont-save"></i> Speichern
        </button>
    </div>
</div>
