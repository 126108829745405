import {TerminalType} from "./terminal-type.class";
import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {TerminalTypeImpl} from "./terminal-type.impl";
import {SimpleTerminalTypeFilter} from "./terminal-type.filter";
import {ITerminalType} from "./terminal-type.interface";

export class TerminalTypeList extends MERemoteList<TerminalType>{
    __classname:string = 'TerminalTypeList';
    __instance = '';

    static fromListResult(
        res:IMEListResult<ITerminalType>
    ):TerminalTypeList{
        const newList=new TerminalTypeList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun: boolean=false,timeout:number=400) {
        super();
        this.simpleFilter = new SimpleTerminalTypeFilter();
        this.initList(autorun,timeout);
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'code',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true
    ) {
        super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    }

    convertEntity(entity: any): any {
        return TerminalTypeImpl.fromResult(entity);
    }
}