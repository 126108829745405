<div class="modal-header">
    <h4 class="modal-title">Tracker {{entity.tracker.ip}} verschieben</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="table-responsive">
        <table class="w-100">
            <tr>
                <th scope="row" class="pe-2 pe-2 me-2 me-2">Mandant</th>
                <td>
                    <app-tenant-selector [(value_id)]="entity.tenant"></app-tenant-selector>
                </td>
            </tr>
            <tr>
                <th scope="row" class="pe-2 pe-2 me-2 me-2">Optionen</th>
                <td>
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="me-2 me-2 pt-2">
                            <me-boolean-field [(value)]="entity.keep_offsets" title=""
                                              labelWidth="0"></me-boolean-field>
                        </div>
                        <div>
                            Offsets beibehalten
                        </div>
                    </div>
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="me-2 me-2 pt-2">
                            <me-boolean-field [(value)]="entity.start_with_zero" title=""
                                              labelWidth="0"></me-boolean-field>
                        </div>
                        <div>Offsets einstellen, um Zählung bei 0 zu starten</div>
                    </div>
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="me-2 me-2 pt-2">
                            <me-boolean-field [(value)]="entity.create_vehicle" title=""
                                              labelWidth="0"></me-boolean-field>
                        </div>
                        <div>immer ein neues Fahrzeug anlegen</div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="ok()" [disabled]="!can_move()">
        <i class="far fa-floppy-disk"></i> Tracker jetzt verschieben
    </button>
</div>
