import { MEGenericFilter } from '../../maennl-commons/filters';
import { ITestPing } from './itest-ping';

export class SimpleTestPingFilter extends MEGenericFilter<ITestPing> {
  showDetails: boolean = null;
  device: number = null;

  public encodeField(fieldname: string): string {
    if (fieldname === 'showDetails') {
      if (this.showDetails !== null && this.showDetails !== undefined) {
        return (
          'filter.' +
          encodeURIComponent(fieldname) +
          '=' +
          encodeURIComponent('' + this.showDetails)
        );
      } else {
        return '';
      }
    }
    if (fieldname === 'device') {
      if (
        this.device !== null &&
        this.device !== undefined &&
        this.device > 0
      ) {
        return (
          'filter.' +
          encodeURIComponent(fieldname) +
          '=' +
          encodeURIComponent('' + this.device)
        );
      } else {
        return '';
      }
    }
    return super.encodeField(fieldname);
  }
}
