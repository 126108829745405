import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IServiceType } from './iservice-type';

export class ServiceType
  extends MEGenericEntity<IServiceType>
  implements IServiceType
{
  id = 0;
  code = '';
  info = '';

  static fromResult(
    entity: IServiceType,
    createIfNull: boolean = false
  ): ServiceType {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new ServiceType();
      }
      return null;
    }

    const instance = new ServiceType();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('code')
        .addTextField('info')
    );
    return instance;
  }

  static windowsService(): ServiceType {
    return new ServiceType(1, 'service', 'Windows-Dienst');
  }

  static systemDService(): ServiceType {
    return new ServiceType(2, 'systemd', 'SystemD-Dienst');
  }

  static docker(): ServiceType {
    return new ServiceType(6, 'docker', 'Docker-Container');
  }

  static database(): ServiceType {
    return new ServiceType(7, 'database', 'Datenbank');
  }

  static diskusage(): ServiceType {
    return new ServiceType(8, 'diskusage', 'Festplattenbelegung');
  }

  constructor(id = 0, code = '', info = '') {
    super();
    this.id = id;
    this.code = code;
    this.info = info;
  }
}
