import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AbrechnungComponent} from './abrechnung/abrechnung.component';
import {BenutzerComponent} from './benutzer/controls/benutzer/benutzer.component';
import {LoginComponent} from './benutzer/controls/login/login.component';
import {LoggedInGuard} from './benutzer/services/logged-in-guard';
import {ClearingComponent} from './clearing/clearing.component';
import {PreiseComponent} from './clearing/preise/preise.component';
import {DokumentationComponent} from './dokumentation/dokumentation.component';
import {IndexComponent} from './index/index.component';
import {CSDServerComponent} from './meconnect/csdserver/csdserver.component';
import {Gateway1Component} from './meconnect/gateway1/gateway1.component';
import {IpBlocklisteComponent} from './meconnect/ip-blockliste/ip-blockliste.component';
import {IPAddressesComponent} from './meconnect/ipaddresses/ipaddresses.component';
import {MEConnectComponent} from './meconnect/meconnect.component';
import {NetzDessauComponent} from './meconnect/netz-dessau/netz-dessau.component';
import {NetzM2MComponent} from './meconnect/netz-m2m/netz-m2m.component';
import {OpenVPNLinuxserverComponent} from './meconnect/open-vpnlinuxserver/open-vpnlinuxserver.component';
import {OpenVPNTelefonanlageComponent} from './meconnect/open-vpntelefonanlage/open-vpntelefonanlage.component';
import {MitschnittComponent} from './mitschnitt/mitschnitt.component';
import {RemoteLogComponent} from './remote-log/remote-log.component';
import {RouterComponent} from './router/router.component';
import {SondenComponent} from './sonden/sonden.component';
import {MachinesResolver} from './sys-health/services/machines-resolver';
import {SysHealthBoxlisteComponent} from './sys-health/sys-health-boxliste/sys-health-boxliste.component';
import {SysHealthDHCPStatusComponent} from './sys-health/sys-health-dhcpstatus/sys-health-dhcpstatus.component';
import {
    SysHealthLebenszeichenComponent
} from './sys-health/sys-health-lebenszeichen/sys-health-lebenszeichen.component';
import {SysHealthRouterStatusComponent} from './sys-health/sys-health-router-status/sys-health-router-status.component';
import {SysHealthTestPingsComponent} from './sys-health/sys-health-test-pings/sys-health-test-pings.component';
import {SysHealthComponent} from './sys-health/sys-health.component';
import {ContactsComponent} from './telefonanlage/contacts/contacts.component';
import {LastCallsComponent} from './telefonanlage/last-calls/last-calls.component';
import {TelefonanlageComponent} from './telefonanlage/telefonanlage.component';
import {VerbindungsuebersichtComponent} from './telefonanlage/verbindungsuebersicht/verbindungsuebersicht.component';
import {NozzleReadersComponent} from './webfuel/nozzle-readers/nozzle-readers.component';
import {RemoteBackupComponent} from './webfuel/remote-backup/remote-backup.component';
import {TenantsComponent} from './webfuel/tenants/tenants.component';
import {VehicleTrackerComponent} from './webfuel/vehicle-tracker/vehicle-tracker.component';
import {WebfuelComponent} from './webfuel/webfuel.component';
import {VPN2Component} from "./meconnect/vpn2/vpn2.component";
import {VPN1Component} from "./meconnect/vpn1/vpn1.component";
import {TerminalComponent} from "./webfuel/terminal/terminal.component";
import {ApplicationsComponent} from "./webfuel/applications/applications.component";
import {ImportsComponent} from "./webfuel/imports/imports.component";

const routes: Routes = [
    {
        path: 'dashboard',
        redirectTo: 'index',
    },
    {
        path: 'index',
        component: IndexComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'router',
        component: RouterComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'sonden',
        component: SondenComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'mitschnitt',
        component: MitschnittComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'status/systemstatus',
        component: SysHealthComponent,
        canActivate: [LoggedInGuard],
        resolve: {
            machineList: MachinesResolver,
        },
    },
    {
        path: 'status/lebenszeichen',
        component: SysHealthLebenszeichenComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'status/testpings',
        component: SysHealthTestPingsComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'status/routerstatus',
        component: SysHealthRouterStatusComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'status/boxlisten',
        component: SysHealthBoxlisteComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'status/dhcp',
        component: SysHealthDHCPStatusComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'remote-logs',
        component: RemoteLogComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'abrechnung',
        component: AbrechnungComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'benutzer',
        component: BenutzerComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'telefonanlage/contacts',
        component: ContactsComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'telefonanlage/verbindungen',
        component: VerbindungsuebersichtComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'telefonanlage/last_calls',
        component: LastCallsComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'telefonanlage',
        component: TelefonanlageComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'clearing/preise',
        component: PreiseComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'clearing',
        component: ClearingComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'meconnect',
        component: MEConnectComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'meconnect/netz-dessau',
        component: NetzDessauComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'meconnect/netz-m2m',
        component: NetzM2MComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'meconnect/ip-addresses',
        component: IPAddressesComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'meconnect/vpn1',
        component: VPN1Component,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'meconnect/vpn2',
        component: VPN2Component,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'meconnect/gateway1',
        component: Gateway1Component,
        canActivate: [LoggedInGuard],
    },

    {
        path: 'meconnect/ip-blocklist',
        component: IpBlocklisteComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'meconnect/csd',
        component: CSDServerComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'meconnect/ovpn-telefon',
        component: OpenVPNTelefonanlageComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'meconnect/ovpn-linuxserver',
        component: OpenVPNLinuxserverComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'webfuel',
        component: WebfuelComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'webfuel/remotebackup',
        component: RemoteBackupComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'webfuel/tenants',
        component: TenantsComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'webfuel/tracker',
        component: VehicleTrackerComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'webfuel/nozzle-readers',
        component: NozzleReadersComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'webfuel/terminal',
        component: TerminalComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'webfuel/applications',
        component: ApplicationsComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'webfuel/imports',
        component: ImportsComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'dokumentation',
        component: DokumentationComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: 'dokumentation/:slug',
        component: DokumentationComponent,
        canActivate: [LoggedInGuard],
    },
    {
        path: '',
        redirectTo: 'index',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
