import { Injectable } from '@angular/core';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import { HttpClient } from '@angular/common/http';
import {
  IMEListResult,
  MEApiTool,
  MEResultMetaSort,
} from '../../../maennl-commons';
import { IMESimpleFilter } from '../../../maennl-commons/filters';
import { TrackerMitschnitt } from './tracker-mitschnitt.class';
import { ITrackerMitschnitt } from './itracker-mitschnitt';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TrackerMitschnittService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public list(
    tracker: number,
    max = 50,
    offset = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<TrackerMitschnitt> = null,
    queryString = ''
  ): Observable<IMEListResult<ITrackerMitschnitt>> {
    const base =
      MEApiTool.buildEntityURL('/api/webfuel/tracker/tracker', tracker) +
      '/mitschnitt/';

    const uri = MEApiTool.buildListURL(
      base,
      max,
      offset,
      sort,
      filter,
      queryString
    );
    return this.http
      .get<IMEListResult<ITrackerMitschnitt>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }
}
