import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BenutzerService } from '../../benutzer/services/benutzer.service';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import {
  MEPubSubService,
  MESubscriptionHelper
} from '../../maennl-commons/services';
import { METool } from '../../maennl-commons/tools';
import { TelefonanlageService } from '../services/telefonanlage.service';

@Component({
  selector: 'app-schranke',
  templateUrl: './schranke.component.html',
  styleUrls: ['./schranke.component.scss']
})
export class SchrankeComponent
  extends MEGenericModalComponent<any>
  implements OnInit, OnDestroy
{
  entity: any = null;
  isLoggedIn = false;
  is_open = false;
  public imgurl: string = null;

  public initEntity(src: any): any {
    return src;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public cd: ChangeDetectorRef,
    public loginService: BenutzerService,
    public telefonanlageService: TelefonanlageService,
    public toastr: ToastrService
  ) {
    super(activeModal, pubsub);
  }

  checkLogin() {
    this.isLoggedIn = this.loginService.isLoggedIn();
    if (!this.isLoggedIn) {
      this.activeModal.dismiss();
    }
    this.cd.markForCheck();
  }

  updateImg() {
    this.checkLogin();
    if (this.isLoggedIn) {
      this.imgurl =
        '/assets/telefonanlage/app/schranke-cam/K1.jpg?dummy=' +
        new Date().getTime();
      setTimeout(() => {
        if (this.is_open) {
          this.updateImg();
        }
      }, 2500);
    }
  }

  public ngOnInit() {
    this.is_open = true;
    super.ngOnInit();
    MESubscriptionHelper.add(
      this,
      this.pubsub.loginChange.subscribe(this.checkLogin, this.checkLogin)
    );

    this.updateImg();
  }

  public ngOnDestroy() {
    this.is_open = false;
    MESubscriptionHelper.release(this);
    super.ngOnDestroy();
  }

  public isValidImgUrl() {
    return !METool.isEmpty(this.imgurl);
  }

  public openGate() {
    this.telefonanlageService.openGate().subscribe(
      () => {
        this.toastr.info('Die Schranke wird geöffnet.');
        this.ringOff();
      },
      () => {}
    );
  }

  public ringOff() {
    this.telefonanlageService.gateRingOff().subscribe(
      () => {
        this.toastr.info('Der Schrankenruf wird beendet');
      },
      () => {}
    );
  }
}
