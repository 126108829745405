import { AppComponent } from './app/app.component';
import { AppModule } from './app/app.module';
import { MEAppInjector } from './app/maennl-commons/tools/meapp-injector';
import { environment } from './environments/environment';
import { ComponentRef, NgModuleRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

declare let loadingComplete: boolean;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    preserveWhitespaces: true
  })
  .then((appRef: NgModuleRef<AppModule>) => {
    MEAppInjector.init(appRef.injector);

    loadingComplete = true;
    if ('serviceWorker' in navigator) {
      try {
        if (environment.production) {
          navigator.serviceWorker.register('/sw-custom.js').finally();
        }
      } catch (ignored) {}
    }
  })
  .catch((err) => {
    return;
  });
