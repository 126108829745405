<div class="modal-header">
    <h4 class="modal-title" *ngIf="isAdd()">Eintrag anlegen</h4>
    <h4 class="modal-title" *ngIf="isEdit">Eintrag bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <me-text-field [label]="'IP-Adresse'" [(value)]="newIP"></me-text-field>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i> Daten speichern
    </button>
</div>