import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {ImportsJobs} from "./imports-jobs.class";
import {IImportsJobs} from "./imports-jobs.interface";
import {ImportsJobsFilter} from "./imports-jobs.filter";
import {ImportsJobsImpl} from "./imports-jobs.impl";

export class ImportsJobsList extends MERemoteList<ImportsJobs>{
    __classname:string='ImportsJobsList';
    __instance:string='';

    static fromListResult(
        res:IMEListResult<IImportsJobs>
    ):ImportsJobsList {
        const newList = new ImportsJobsList ();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun:boolean=false, timeout:number=400) {
        super();
        this.simpleFilter = new ImportsJobsFilter();
        this.initList(autorun,timeout);
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'id',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true
    ) {
        super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    }

    convertEntity(entity: any): any {
        return ImportsJobsImpl.fromResult(entity);
    }
}