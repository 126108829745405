<ng-template #ahIcon>
    <fa-icon [icon]="['fas', 'cloud']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="webfuel"
></me-area-heading>

<me-card>
    <h4>Backuplösung</h4>
    <button routerLink="/webfuel/remotebackup" class="btn btn-sm btn-primary m-3">Remote-Backup</button>
    <h4>webfuel-Cloud</h4>
    <button routerLink="/webfuel/tenants" class="btn btn-sm btn-primary m-3">Mandanten</button>
    <button routerLink="/webfuel/tracker" class="btn btn-sm btn-primary m-3">Fahrzeug-Tracker</button>
    <button routerLink="/webfuel/nozzle-readers" class="btn btn-sm btn-primary m-3">Nozzle-Reader</button>
    <button routerLink="/webfuel/terminal" class="btn btn-sm btn-primary m-3">Terminals</button>
    <button routerLink="/webfuel/applications" class="btn btn-sm btn-primary m-3">Anwendungen</button>
    <button routerLink="/webfuel/imports" class="btn btn-sm btn-primary m-3">Importe</button>
</me-card>
