import { IMEListResult, MERemoteList } from '../../../maennl-commons';
import { Gruppe } from './gruppe.class';
import { GruppeFilter } from './gruppe.filter';
import { GruppeImpl } from './gruppe.impl';
import { IGruppe } from './gruppe.interface';
import { TGruppe, TNullableGruppe } from './gruppe.types';

export class GruppeList extends MERemoteList<Gruppe> {
  __classname = 'GruppeList';
  __instance = '';

  static fromListResult(res: IMEListResult<IGruppe>): GruppeList {
    const newList = new GruppeList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun = false, timeout = 400) {
    super();
    this.simpleFilter = new GruppeFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<TGruppe>,
    defaultSort = 'name',
    defaultOrder = 'asc',
    clearFilter = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: TGruppe): TNullableGruppe {
    return GruppeImpl.fromResult(entity);
  }
}
