<me-data-table
        *ngIf="verlauf!==null && verlauf!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="verlauf.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        [extraRowClasses]="rowClassGetter"
        (mouseOver)="updateLegende($event)"
        #verlaufTable
>

    <me-data-table-rowaction
            [icon]="'far fa-tombstone'"
            [label]="'Entfernen'"
            [title]="'Das Gerät gibt es nicht mehr, alle Einträge entfernen'"
            (perform)="removeDevice($event)"
    ></me-data-table-rowaction>

    <me-data-table-column
            [label]="'Zeitpunkt'"
            [field]="'zeitpunkt'"
            type="date"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>
    <me-data-table-column [label]="'IP-Adresse'" field="ip"
    ></me-data-table-column>
    <me-data-table-column [label]="'Gerät'" field="devInfo"
    ></me-data-table-column>
    <me-data-table-column [label]="'Aktion'" field="lastActionDescriptive"
    ></me-data-table-column>
    <me-data-table-table-extra-header>
        <me-pagination
                settingsid="syshealth-lebenszeichen"
                [settingsService]="benutzerService"
                [pagecounts]="[10,20,50,100,500,1000]"
                [total]="verlauf.total"
                [page]="verlauf.page"
                [perpage]="verlauf.size"
                (pageChange)="verlauf.setPage($event)"
                (pageCountChange)="verlauf.processPageCountChangedEvent($event)"
                [showSearch]="true"
                (queryChange)="verlauf.processQueryChangedEvent($event)"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
<div class="d-flex justify-content-between">
    <div class="lebenszeichen-fresh border-dark p-2 border text-nowrap" title="frisch">
        <i class="far fa-clock"></i> &lt; {{ fDuration(freshUntil) }}
    </div>
    <div class="border-dark p-2 border text-nowrap" title="ok">
        {{ fDuration(freshUntil) }} &lt; <i class="far fa-clock"></i> &lt; {{ fDuration(oldAfter) }}
    </div>
    <div class="lebenszeichen-old border-dark p-2 border text-nowrap" title="alt">
        {{ fDuration(oldAfter) }} &lt; <i class="far fa-clock"></i> &lt; {{ fDuration(veryOldAfter) }}
    </div>
    <div class="lebenszeichen-very-old border-dark p-2 border text-nowrap" title="sehr alt">
        {{ fDuration(veryOldAfter) }} &lt; <i class="far fa-clock"></i> &lt; {{ fDuration(tooOldAfter) }}
    </div>
    <div class="lebenszeichen-too-old border-dark p-2 border text-nowrap" title="zu alt">
        {{ fDuration(tooOldAfter) }} &lt; <i class="far fa-clock"></i>
    </div>
</div>
