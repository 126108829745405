import {
  MEGenericEntityConverterParams,
  MEGenericEntityFields,
} from '../../../maennl-commons';
import { CheckPing } from './check-ping.class';
import { TCheckPing, TNullableCheckPing } from './check-ping.types';
import { FirewallImpl } from './firewall.impl';
import { TNullableFirewall } from './firewall.types';
import { NetworkAddressImpl } from './network-address.impl';
import { TNullableNetworkAddress } from './network-address.types';

export class CheckPingImpl {
  static fromResult(
    entity: TCheckPing,
    createIfNull = false
  ): TNullableCheckPing {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new CheckPing();
      }
      return null;
    }

    const instance = new CheckPing();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongFields([
          'id',
          'address',
          'firewall',
          'check_interval_secs',
          'error_count',
          'check_tries',
          'check_delay',
          'payload_size',
        ])
        .addTextFields(['check_result'])
        .addBooleanFields(['check_enabled', 'is_ok'])
        .addDateFields(['last_check', 'next_check', 'status_change'])
        .addConverterField(
          '_address',
          new MEGenericEntityConverterParams<TNullableNetworkAddress>(
            (value: TNullableNetworkAddress) => {
              return NetworkAddressImpl.fromResult(value, false);
            }
          )
        )
        .addConverterField(
          '_firewall',
          new MEGenericEntityConverterParams<TNullableFirewall>(
            (value: TNullableFirewall) => {
              if (value === null || value === undefined) {
                return null;
              }
              if (typeof value === 'string') {
                return FirewallImpl.fromResult(
                  JSON.parse(<string>(<unknown>value)) as TNullableFirewall,
                  false
                );
              }
              return FirewallImpl.fromResult(value, false);
            }
          )
        ),
      (e: CheckPing) => {}
    );

    return instance;
  }
}
