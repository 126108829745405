import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { Check } from './check';
import { CheckResult } from './check-result';
import { ICheckHistory } from './icheck-history';
import { Machine } from './machine';
import { ServiceType } from './service-type';

export class CheckHistory
  extends MEGenericEntity<ICheckHistory>
  implements ICheckHistory
{
  check: Check = null;
  id = 0;
  result: CheckResult = null;
  resultInfo = '';
  zeitpunkt: Date = null;
  message = '';

  machine: Machine = null;
  serviceType: ServiceType = null;

  static fromResult(
    entity: ICheckHistory,
    createIfNull: boolean = false
  ): CheckHistory {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new CheckHistory();
      }
      return null;
    }
    const instance = new CheckHistory();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addConverterField(
          'check',
          new MEGenericEntityConverterParams<Check>((value: any) => {
            return Check.fromResult(value);
          })
        )
        .addDateField('zeitpunkt')
        .addConverterField(
          'result',
          new MEGenericEntityConverterParams<CheckResult>((value: any) => {
            return CheckResult.fromResult(value);
          })
        )
        .addTextField('resultInfo')
        .addTextField('message'),
      (e: CheckHistory) => {
        e.machine = null;
        if (e.check !== null && e.check !== undefined) {
          e.machine = e.check.machine;
          e.serviceType = e.check.serviceType;
        }
      }
    );

    return instance;
  }

  constructor() {
    super();
  }
}
