import { MEGenericFilter } from '../../../maennl-commons/filters';
import { METool } from '../../../maennl-commons/tools';
import { CSDServerClient } from './csdserver-client';

export class SimpleCSDServerClientFilter extends MEGenericFilter<CSDServerClient> {
  type: string = null;

  public encodeField(fieldname: string): string {
    if (fieldname === 'type') {
      if (!METool.isEmpty(this.type)) {
        return (
          'filter.' +
          encodeURIComponent(fieldname) +
          '=' +
          encodeURIComponent('' + this.type)
        );
      }
    }
    return super.encodeField(fieldname);
  }
}
