import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { DHCPLeaseList } from '../../common/dhcplease-list';
import { SysHealthService } from '../../services/sys-health.service';

@Component({
  selector: 'app-tab-dhcpleases',
  templateUrl: './tab-dhcpleases.component.html',
  styleUrls: ['./tab-dhcpleases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabDHCPLeasesComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy
{
  @Input() id = 'TabDHCPLeasesComponent';

  leases: DHCPLeaseList = new DHCPLeaseList();

  onActivate() {
    this.leases.start();
  }

  constructor(
    public cd: ChangeDetectorRef,
    public sysHealthService: SysHealthService,
    public benutzerService: BenutzerService
  ) {
    super();
  }

  public ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.leases.onUpdateRequired.subscribe((list: DHCPLeaseList) => {
        this.sysHealthService
          .getDHCPLeases(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.getQuery()
          )
          .subscribe((l) => {
            list.populateFromListResult(l);
            this.cd.markForCheck();
          });
      })
    );
  }

  public ngOnDestroy() {
    MESubscriptionHelper.release(this);
  }

  onDeactivate() {}
}
