import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BenutzerService } from '../../benutzer/services/benutzer.service';
import {
  MEDataTableRow,
  MEGenericModalComponent,
  MERowActionParams,
  MESubscriptionHelper,
  TMEDTGetExtraRowClassesCallback,
  noop,
} from '../../maennl-commons';
import { BlocklistEditorComponent } from './blocklist-editor/blocklist-editor.component';
import { BlocklistV4 } from './commons/blocklist-v4.class';
import { BlocklistV4List } from './commons/blocklist-v4.list';
import { BlocklistV4Service } from './commons/blocklist-v4.service';

@Component({
  selector: 'app-ip-blockliste',
  templateUrl: './ip-blockliste.component.html',
  styleUrls: ['./ip-blockliste.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IpBlocklisteComponent implements OnInit, OnDestroy {
  rowClassGetter: TMEDTGetExtraRowClassesCallback<BlocklistV4> = (
    row: MEDataTableRow<BlocklistV4>
  ) => {
    if (
      row !== null &&
      row !== undefined &&
      row.data !== null &&
      row.data !== undefined &&
      row.data.is_valid !== null &&
      row.data.is_valid !== undefined
    ) {
      if (!row.data.is_valid) {
        return 'bg-danger text-light';
      }
    }
    return '';
  };

  liste = new BlocklistV4List();

  constructor(
    public cd: ChangeDetectorRef,
    public service: BlocklistV4Service,
    public benutzerService: BenutzerService,
    public modalService: NgbModal
  ) {}

  ngOnInit(): void {
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe(() => {
        this.service
          .list(
            this.liste.size,
            this.liste.calcOffset(),
            this.liste.order,
            this.liste.simpleFilter,
            this.liste.getQuery()
          )
          .subscribe(res => {
            this.liste.doPopulateFromListResult(res);
            this.cd.markForCheck();
          });
      })
    );

    this.liste.start();
  }

  ngOnDestroy(): void {
    this.liste.release();
    MESubscriptionHelper.release(this);
  }

  removeIp($event: MERowActionParams<BlocklistV4>) {
    this.service.get($event.row.data.id).subscribe((e: BlocklistV4) => {
      if (e.id !== null && e.id !== undefined && e.id > 0) {
        this.service.delete(e.id).subscribe(() => {
          this.liste.start();
        });
      }
    });
  }

  addEntry() {
    BlocklistEditorComponent.open(
      this.modalService,
      BlocklistEditorComponent,
      null,
      MEGenericModalComponent.SIZE_MEDIUM,
      null
    ).then(() => {
      this.liste.start();
    }, noop);
  }

  editEntry($event: MERowActionParams<BlocklistV4>) {
    this.service.get($event.row.data.id).subscribe((e: BlocklistV4) => {
      if (e !== null && e !== undefined && e.id > 0) {
        BlocklistEditorComponent.open(
          this.modalService,
          BlocklistEditorComponent,
          e,
          MEGenericModalComponent.SIZE_MEDIUM,
          null
        ).then(() => {
          this.liste.start();
        }, noop);
      }
    });
  }
}
