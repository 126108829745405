import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import {
  MEGenericModalComponent,
  MEPubSubService,
} from '../../../maennl-commons';
import { Gruppe } from '../commons/gruppe.class';
import { GruppeImpl } from '../commons/gruppe.impl';
import { GruppeService } from '../commons/gruppe.service';
import { TNullableGruppe } from '../commons/gruppe.types';

@Component({
  selector: 'app-gruppe-editor',
  templateUrl: './gruppe-editor.component.html',
  styleUrls: ['./gruppe-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GruppeEditorComponent
  extends MEGenericModalComponent<Gruppe>
  implements OnInit, OnDestroy
{
  entity: TNullableGruppe = null;

  public initEntity(src: TNullableGruppe): TNullableGruppe {
    return GruppeImpl.fromResult(src, true);
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public service: GruppeService,
    public toastr: ToastrService,
    public cd: ChangeDetectorRef
  ) {
    super(activeModal, pubsub);
  }

  ngOnInit(): void {
    this.cd.markForCheck();
  }

  save() {
    this.service.store(this.entity).subscribe({
      next: r => {
        if (r.success) {
          this.ok();
        } else {
          this.toastr.error('Fehler beim Speichern der Daten');
        }
      },
      error: () => {
        this.toastr.error('Fehler beim Speichern der Daten');
      },
    });
  }
}
