import { IMEListResult, MERemoteList } from '../../../maennl-commons';
import { Firewall } from './firewall.class';
import { FirewallFilter } from './firewall.filter';
import { FirewallImpl } from './firewall.impl';
import { IFirewall } from './firewall.interface';
import { TFirewall, TNullableFirewall } from './firewall.types';

export class FirewallList extends MERemoteList<Firewall> {
  __classname = 'FirewallList';
  __instance = '';

  static fromListResult(res: IMEListResult<IFirewall>): FirewallList {
    const newList = new FirewallList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun = false, timeout = 400) {
    super();
    this.simpleFilter = new FirewallFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<TFirewall>,
    defaultSort = 'name',
    defaultOrder = 'asc',
    clearFilter = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: TFirewall): TNullableFirewall {
    return FirewallImpl.fromResult(entity);
  }
}
