import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { MEPubSubService } from '../../maennl-commons/services';
import { MERichError, METool } from '../../maennl-commons/tools';
import { BenutzerService } from '../services/benutzer.service';

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss']
})
export class PasswordDialogComponent
  extends MEGenericModalComponent<any>
  implements OnInit, OnDestroy
{
  entity: any = null;

  pw1 = '';
  pw2 = '';

  public initEntity(src: any): any {
    return src;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public cd: ChangeDetectorRef,
    public benutzerService: BenutzerService,
    public toastr: ToastrService
  ) {
    super(activeModal, pubsub);
  }

  public canSave() {
    if (METool.isEmpty(this.pw1) || METool.isEmpty(('' + this.pw1).trim())) {
      return false;
    }
    return this.pw1 === this.pw2;
  }

  public changePw() {
    if (this.canSave()) {
      this.benutzerService.updateCurrentPassword(this.pw1).subscribe(
        (r) => {
          if (r.success) {
            this.toastr.success('Bitte neu einlogen', 'Kennwort geändert');
            this.activeModal.close();
          } else {
            MERichError.throw('Fehler', 'Fehler beim Ändern des Kennworts');
          }
        },
        () => {
          MERichError.throw('Fehler', 'Fehler beim Ändern des Kennworts');
        }
      );
    }
  }
}
