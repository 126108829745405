import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showReadable'
})
export class ShowReadablePipe implements PipeTransform {
  transform(value: string): any {
    let r = '';
    const c = value.match(/.{2}/g);
    for (const n of c) {
      const ch = parseInt(n, 16);
      if (ch < 31) {
        r = r + '.';
      } else {
        r = r + String.fromCharCode(ch);
      }
    }
    return r;
  }
}
