import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {MEAddOnClickEvent} from "../events";
import {Md5} from "ts-md5";
import {noop} from "../../tools";

@Component({
    selector: 'me-option-field',
    template: `
        <div class="form-group row mb-1">
            <label
                    for="{{ id }}"
                    class="col-form-label"
                    [ngClass]="'col-' + labelWidth"
                    *ngIf="labelWidth > 0"
            >
                {{ label }}
            </label>
            <div [ngClass]="'col-' + (12 - labelWidth)">
                <div
                        class="form-control"
                        [class.input-group]="hasAddon()"
                        [class.input-group-sm]="hasAddon() && small"
                >
                    <div
                            [class.form-control-sm]="small"
                            id="{{ id }}"
                            (input)="update($event)"
                            class="me-option-body"
                            [ngClass]="extraBodyClass"
                            [ngStyle]="extraBodyStyles"
                    >
                        <ng-content></ng-content>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            :host .addon button:first-child {
                border-left: none;
                padding: 0 5px;
            }

            .addon {
            }
        `
    ]
})
export class MEOptionFieldComponent implements OnInit {

    private _extraBodyClass: any = '';
    private _extraBodyStyles: any = {};
    public _value: string | number = '';
    public _icons: string[] = [];

    @Input()
    public type = 'text';

    @Input() cols = 80;
    @Input() rows = 3;

    @Input() label = 'Textfeld';

    _addOnLabels: string[] = [];

    @Input() enableAddon = false;
    @Input() id = '';
    @Input() labelWidth = 3;
    @Input() disabled = false;
    @Input() small = false;

    @Output() valueChange = new EventEmitter<string | number>();
    @Output() addonClick = new EventEmitter<MEAddOnClickEvent>();


    @Input()
    set value(v: string | number) {
        if (this._value !== v) {
            this._value = v;
            if (!this.disabled) {
                this.valueChange.emit(v);
            }
        }
    }

    get value(): string | number {
        return this._value;
    }

    public get extraBodyStyles(): any {
        return this._extraBodyStyles;
    }

    @Input()
    public set extraBodyStyles(value: any) {
        this._extraBodyStyles = value;
        this.cd.markForCheck();
    }

    public get extraBodyClass(): any {
        return this._extraBodyClass;
    }

    @Input()
    public set extraBodyClass(value: any) {
        this._extraBodyClass = value;
        this.cd.markForCheck();
    }

    constructor(public cd: ChangeDetectorRef) {
        noop();
    }

    ngOnInit() {
        if (this.id === '') {
            this.id =
                'tf' +
                Md5.hashStr(
                    'optionfield-' + this.label + Math.random() + '-' + Math.random()
                );
        }
    }

    update(event) {
        if (!this.disabled) {
            this.value = event.target.value;
        }
    }

    hasAddon(): boolean {
        return !this.disabled && this.enableAddon && this._icons.length > 0;
    }

    get addonIcons(): any[] {
        if (this.hasAddon()) {
            return this._icons;
        }
        return [];
    }

    aClick(i?: any) {
        const e: MEAddOnClickEvent = new MEAddOnClickEvent();
        e.component = this;
        e.addon = i;
        this.addonClick.emit(e);
    }
}