import { IGenericObject, METool } from '../../../maennl-commons';
import { Vehicle } from '../../vehicles/commons/vehicle';
import { VehicleTracker } from '../commons/vehicle-tracker';
import { ChangeDetectorRef } from '@angular/core';

interface IOptions extends IGenericObject {
  tracker: VehicleTracker;
  vehicle: Vehicle;
  vehicle_id: number;
  tenant: number;
  km_stand_metres: number;
  working_hours_seconds: number;
  plate: string;
  vin: string;
  display_text: string;
  nummer: number;
}

export class VehicleEditorOptions {
  data: IOptions = {
    tracker: null,
    vehicle: null,
    vehicle_id: 0,
    tenant: 0,
    km_stand_metres: 0,
    working_hours_seconds: 0,
    nummer: 0,
    vin: '',
    display_text: '',
    plate: ''
  };

  private _cd: ChangeDetectorRef = null;

  get cd(): ChangeDetectorRef {
    return this._cd;
  }

  set cd(value: ChangeDetectorRef) {
    this._cd = value;
  }

  get tracker() {
    return this.data.tracker;
  }

  set tracker(v: VehicleTracker) {
    this.data.tracker = v;
    if (!METool.isNullOrUndefined(v)) {
      this.vehicle_id = v.id;
      this.tenant = v.tenant;
      this.display_text = v.vehicle_display_text;
      this.nummer = v.vehicle_nummer;
      this.vin = v.vehicle_vin;
      this.plate = v.vehicle_plate;
      this.km_stand_metres = v.vehicle_km_stand_metres;
      this.working_hours_seconds = v.vehicle_working_hours_seconds;
    }
    this.mfc();
  }

  get vehicle() {
    return this.data.vehicle;
  }

  set vehicle(v: Vehicle) {
    this.data.vehicle = v;
    if (!METool.isNullOrUndefined(v)) {
      this.vehicle_id = v.id;
      this.tenant = v.tenant;
      this.display_text = v.display_text;
      this.nummer = v.nummer;
      this.vin = v.vin;
      this.plate = v.plate;
      this.km_stand_metres = v.km_stand_metres;
      this.working_hours_seconds = v.working_hours_seconds;
    }
    this.mfc();
  }

  get vehicle_id() {
    return this.data.vehicle_id;
  }

  set vehicle_id(v) {
    this.data.vehicle_id = v;
    this.mfc();
  }

  get tenant() {
    return this.data.tenant;
  }

  set tenant(v) {
    this.data.tenant = v;
    this.mfc();
  }

  get km_stand_metres() {
    return this.data.km_stand_metres;
  }

  set km_stand_metres(v) {
    this.data.km_stand_metres = v;
    this.mfc();
  }

  get working_hours_seconds() {
    return this.data.working_hours_seconds;
  }

  set working_hours_seconds(v) {
    this.data.working_hours_seconds = v;
    this.mfc();
  }

  get nummer() {
    return this.data.nummer;
  }

  set nummer(v) {
    this.data.nummer = v;
    this.mfc();
  }

  get vin() {
    return this.data.vin;
  }

  set vin(v) {
    this.data.vin = v;
    this.mfc();
  }

  get display_text() {
    return this.data.display_text;
  }

  set display_text(v) {
    this.data.display_text = v;
    this.mfc();
  }

  get plate() {
    return this.data.plate;
  }

  set plate(v) {
    this.data.plate = v;
    this.mfc();
  }

  mfc() {
    if (this.cd !== null && this.cd !== undefined) {
      this.cd.markForCheck();
    }
  }

  clear(): void {
    this.data.tracker = null;
    this.data.vehicle_id = 0;
    this.data.tenant = null;
    this.data.working_hours_seconds = 0;
    this.data.vin = '';
    this.data.plate = '';
    this.data.nummer = 0;
    this.data.display_text = '';
    this.data.km_stand_metres = 0;
    this.mfc();
  }

  copyFrom(src: VehicleEditorOptions) {
    this.clear();
    Object.keys(src.data).forEach((k) => {
      this.data[k] = src.data[k];
    });
    this.mfc();
  }
}
