<div
  class="d-flex align-items-start justify-content-between"
  *ngIf="liste !== null && liste !== undefined">
  <div class="flex-fill address-table">
    <me-data-table
      #addressTable
      [rowActionsAsButtons]="true"
      [tableActionsAsButtons]="true"
      [data]="liste.asDataTableRows()"
      [selectable]="true"
      [singleSelect]="true"
      [extraRowClasses]="rowClassGetter"
      [hideCheckboxes]="true"
      actionsicon="icofont icofont-navigation-menu"
      (rowSelected)="onRowSelected($event)">
      <me-data-table-column
        [label]="'ID'"
        [field]="'id'"></me-data-table-column>
      <me-data-table-column
        [label]="'IP-Adresse'"
        [field]="'address'"></me-data-table-column>
      <me-data-table-column
        [label]="'Info'"
        [field]="'info'"></me-data-table-column>
      <me-data-table-column
        [label]="'aktiv'"
        [field]="'enabled'"
        type="Boolean"></me-data-table-column>

      <ng-template #addrFamily let-row="row">
        <ng-container *ngFor="let nwa of asAddress(row)">
          <ng-container
            *ngIf="nwa.addr_family === 4 || nwa.addr_family === '4'">
            <span class="badge bg-gray text-light">IPv4</span>
          </ng-container>
          <ng-container
            *ngIf="nwa.addr_family === 6 || nwa.addr_family === '6'">
            <span class="badge bg-gray text-light">IPv6</span>
          </ng-container>
        </ng-container>
      </ng-template>
      <me-data-table-column
        [label]="'Adress-Familie'"
        [field]="'addr_family'"
        [template]="addrFamily"></me-data-table-column>

      <ng-template #m2m let-row="row">
        <ng-container *ngFor="let nwa of asAddress(row)">
          <ng-container *ngIf="nwa.is_m2m">
            <span class="badge bg-gray text-light" *ngIf="nwa.is_maennl_apn"
              >maennl</span
            >
            <span class="badge bg-gray text-light" *ngIf="nwa.is_maennlex_apn"
              >maennlex</span
            >
          </ng-container>
          <ng-container *ngIf="!nwa.is_m2m">
            <span class="text-muted">--</span>
          </ng-container>
        </ng-container>
      </ng-template>

      <me-data-table-column
        [label]="'M2M-Status'"
        [field]="'is_m2m'"
        [template]="m2m"></me-data-table-column>

      <ng-template #checksTemplate let-row="row">
        <ng-container *ngFor="let nwa of asAddress(row)">
          <ng-container *ngIf="nwa.checks_num_total < 1">
            <span class="text-muted">--</span>
          </ng-container>
          <ng-container *ngIf="nwa.checks_num_total > 0">
            <span title="Erfolgreiche Tests">{{
              fNum(nwa?.checks_num_ok, 0)
            }}</span
            >/
            <span title="Anzahl Tests gesamt">{{
              fNum(nwa?.checks_num_total, 0)
            }}</span>
            =
            <span title="Erfolgsrate"
              >{{ fNum(nwa?.checks_success_ratio, 0) }}%</span
            >
          </ng-container>
        </ng-container>
      </ng-template>

      <me-data-table-column
        [label]="'Test-Status'"
        [field]="'checks_num_total'"
        [template]="checksTemplate"></me-data-table-column>

      <ng-template #gruppenTemplate let-row="row">
        <ng-container *ngFor="let nwa of asAddress(row)">
          <ng-container *ngIf="nwa._groups.length < 1">
            <span class="text-muted">--</span>
          </ng-container>
          <ng-container *ngIf="nwa._groups.length > 0">
            <ng-container *ngFor="let g of nwa._groups">
              <span class="badge bg-gray text-light" [title]="g.description">{{
                g.name
              }}</span>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
      <me-data-table-column
        [label]="'Gruppen'"
        [field]="'_groups'"
        [template]="gruppenTemplate"></me-data-table-column>

      <me-data-table-action
        label="Eintrag hinzufügen"
        [icon]="'far fa-plus'"
        (perform)="addEntry()"></me-data-table-action>

      <me-data-table-rowaction
        (perform)="editEntry($event)"
        icon="far fa-pencil"
        label="Bearbeiten"
        title="Eintrag bearbeiten"></me-data-table-rowaction>

      <me-data-table-rowaction
        (perform)="removeEntry($event)"
        icon="far fa-trash"
        label="Löschen"
        title="Eintrag löschen"></me-data-table-rowaction>

      <me-data-table-table-extra-header>
        <me-pagination
          [total]="liste.total"
          [page]="liste.page"
          [perpage]="liste.size"
          (pageChange)="liste.setPage($event)"
          (pageCountChange)="liste.processPageCountChangedEvent($event)"
          (queryChange)="liste.processQueryChangedEvent($event)"
          settingsid="netzwerk-addresses"
          [settingsService]="benutzerService"
          [showSearch]="true"></me-pagination>
      </me-data-table-table-extra-header>
    </me-data-table>
  </div>
  <ng-container *ngIf="addressTable.hasSelectedRows()">
    <div class="ml-2 ms-2 ps-2 pl-2" style="min-width: 450px">
      <me-tab-card #detailTabs>
        <me-pane id="subtab-checks" title="Checks (PING)">
          <div style="min-width: 400px">
            <me-data-table
              #checksTable
              [rowActionsAsButtons]="true"
              [tableActionsAsButtons]="true"
              [data]="checks.asDataTableRows()"
              [selectable]="false"
              [singleSelect]="false"
              [extraRowClasses]="rowClassGetter2"
              [hideCheckboxes]="true"
              actionsicon="icofont icofont-navigation-menu">
              <ng-template #firewallTemplate let-row="row">
                <ng-container *ngFor="let ch of asChecks(row)">
                  {{ ch?._firewall?.name }}
                </ng-container>
              </ng-template>

              <ng-template #statusRenderer let-p="p" let-row="row">
                <ng-container *ngFor="let ch of asChecks(row)">
                  <div>
                    <span
                      title="Firewall-Host"
                      class="badge bg-grey text-light"
                      >{{ ch?._firewall?.name }}</span
                    >
                  </div>
                  <div *ngIf="!ch.check_enabled">Test deaktiviert</div>
                  <div *ngIf="ch.check_enabled">
                    <div>
                      <span
                        class="badge bg-success text-light"
                        *ngIf="ch.is_ok">
                        <i class="far mr-2 me-2 fa-check-circle"></i>
                        erfolgreich
                      </span>
                      <span
                        class="badge bg-danger text-light border border-dark"
                        *ngIf="!ch.is_ok">
                        <i class="far mr-2 me-2 fa-circle"></i> fehlgeschlagen
                      </span>
                    </div>
                    <div>
                      seit:
                      {{ fDate(ch.status_change, "YYYY-MM-DD HH:mm:ss", true) }}
                    </div>
                    <div title="letztes Ergebnis">
                      Meldung:
                      {{ ch.check_result }}
                    </div>
                  </div>
                </ng-container>
              </ng-template>
              <me-data-table-column
                [label]="'Status'"
                [field]="'status_change'"
                [template]="statusRenderer"></me-data-table-column>

              <me-data-table-action
                label="Eintrag hinzufügen"
                [icon]="'far fa-plus'"
                (perform)="addCheckEntry()"></me-data-table-action>

              <me-data-table-rowaction
                (perform)="runCheckEntry($event)"
                icon="far fa-play"
                label="Test ausführen"
                title="Test ausführen"></me-data-table-rowaction>

              <me-data-table-rowaction
                (perform)="editCheckEntry($event)"
                icon="far fa-pencil"
                label="Bearbeiten"
                title="Eintrag bearbeiten"></me-data-table-rowaction>

              <me-data-table-rowaction
                (perform)="removeCheckEntry($event)"
                icon="far fa-trash"
                label="Löschen"
                title="Eintrag löschen"></me-data-table-rowaction>

              <me-data-table-table-extra-header>
                <me-pagination
                  [total]="checks.total"
                  [page]="checks.page"
                  [perpage]="checks.size"
                  (pageChange)="checks.setPage($event)"
                  (pageCountChange)="
                    checks.processPageCountChangedEvent($event)
                  "
                  (queryChange)="checks.processQueryChangedEvent($event)"
                  settingsid="netzwerk-addresses-checks"
                  [settingsService]="benutzerService"
                  [showSearch]="false"></me-pagination>
              </me-data-table-table-extra-header>
            </me-data-table>
          </div>
        </me-pane>
        <me-pane id="subtab-groups" title="Gruppen">
          <div
            *ngFor="let grp of gruppen.data"
            class="d-flex justify-content-between align-items-center">
            <div>
              <ng-container *ngIf="current.is_in_group(grp.id)">
                <i
                  class="fa fa-fw fa-check-square-o has-cursor-pointer"
                  title="aus der Gruppe entfernen"
                  (click)="remove_from_group(grp)"></i>
              </ng-container>
              <ng-container *ngIf="!current.is_in_group(grp.id)">
                <i
                  class="fa fa-fw fa-square-o has-cursor-pointer"
                  title="zur Gruppe hinzufügen"
                  (click)="add_to_group(grp)"></i>
              </ng-container>
            </div>
            <div class="flex-fill ms-2 ml-2" [title]="grp.description">
              {{ grp.name }}
            </div>
          </div>
        </me-pane>
      </me-tab-card>
    </div>
  </ng-container>
</div>
