import { Component, Input, OnInit } from '@angular/core';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';

@Component({
  selector: 'app-file-system-space-display',
  template: `
    <ngb-progressbar
      max="100"
      [title]="getTitle()"
      [value]="getPercentUsed()"
      [type]="getDiskWarnState()"
    ></ngb-progressbar>
  `,
  styleUrls: ['./file-system-space-display.component.scss']
})
export class FileSystemSpaceDisplayComponent
  extends MELocalizedComponent
  implements OnInit
{
  @Input() total = 0;
  @Input() free = 0;

  constructor() {
    super();
  }

  ngOnInit() {}

  getPercentUsed() {
    if (this.total === null) {
      return 0;
    }
    if (this.total === 0) {
      return 0;
    }
    if (this.free === 0) {
      return 100;
    }

    return Math.floor(((this.total - this.free) / this.total) * 100.0);
  }

  public getDiskWarnState(): string {
    const x = this.getPercentUsed();
    if (x > 85) {
      return 'danger';
    }
    if (x > 75) {
      return 'warning';
    }
    if (x > 50) {
      return 'info';
    }
    return 'success';
  }

  public getTitle(): string {
    return this._('{{ n }}% von {{m}} belegt, noch {{f}} verfügbar', {
      n: this.getPercentUsed(),
      m: this.bytes(this.total * 1024 * 1024),
      f: this.bytes(this.free * 1024 * 1024)
    });
  }
}
