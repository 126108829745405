import { Injectable } from '@angular/core';
import { BenutzerService } from '../../benutzer/services/benutzer.service';
import { HttpClient } from '@angular/common/http';
import { IMEActionResponse, MEApiTool } from '../../maennl-commons/network';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AbrechnungService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public recalcAbrechnungen(): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL('/api/abrechnungen', null, '_recalc');
    return this.http
      .post<IMEActionResponse>(uri, null, this.loginService.getRequestOptions())
      .pipe(take(1));
  }
}
