<ng-template #ahIcon>
    <me-icon [class]="'me-icon me-icon-terminal'"></me-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Terminal"
></me-area-heading>

<me-tab-card #rootTabCard>
    <me-pane id="tab-terminals"
             title="Terminals"
             (activated)="activate(terminalTab)">
        <app-tab-terminal #terminalTab></app-tab-terminal>
    </me-pane>
    <me-pane id="tab-terminal-malfunctioning"
             title="Fehlerhafte Terminals"
             (activated)="activate(terminalMalfunctioningTab)">
        <app-tab-terminal-malfunctioning #terminalMalfunctioningTab></app-tab-terminal-malfunctioning>
    </me-pane>
    <me-pane id="tab-ta-jobs"
             title="TA-Jobs"
             (activated)="activate(taJobsTab)">
        <app-tab-ta-jobs #taJobsTab></app-tab-ta-jobs>
    </me-pane>
    <me-pane id="tab-missing-terminal-transactions"
             title="Fehlende Transaktionen"
             (activated)="activate(mttTab)">
        <app-tab-missing-terminal-transactions #mttTab></app-tab-missing-terminal-transactions>
    </me-pane>
    <me-pane id="tab-position-needs-process"
             title="Ausstehende Prozesse Positionen"
             (activated)="activate(pnprnTab)">
        <app-tab-position-needs-process-rn #pnprnTab></app-tab-position-needs-process-rn>
    </me-pane>
    <me-pane id="tab-transaktion-need-recalc"
             title="Transaktion zu berechnen"
             (activated)="activate(tnrrnTab)">
        <app-tab-transaktion-need-recalc-rn #tnrrnTab></app-tab-transaktion-need-recalc-rn>
    </me-pane>

</me-tab-card>