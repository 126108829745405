import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Output,
  QueryList,
} from '@angular/core';

import { MEPaneComponent } from './mepane.component';
import { METabCardExtraHeaderComponent } from './metab-card-extra-header.component';

@Component({
  selector: 'me-tab-card',
  styles: [
    `
      .nav-item a {
        cursor: pointer;
      }
    `,
  ],
  template: `
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item" *ngFor="let p of panes">
            <a
              class="nav-link"
              [class.disabled]="p.disabled"
              [class.active]="p.active"
              (click)="selectByClick(p)">
              <i class="{{ p.icon }}" *ngIf="p.hasIcon()"></i>
              {{ p.title }}
            </a>
          </li>
        </ul>
        <div *ngIf="hasExtraHeader()" class="headerActionContainer">
          <ng-content select="me-tab-card-extra-header"></ng-content>
        </div>
      </div>
      <div class="card-body tab-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class METabCardComponent implements AfterContentInit, AfterViewChecked {
  @ContentChildren(METabCardExtraHeaderComponent)
  extraActionContainers: QueryList<METabCardExtraHeaderComponent>;

  @ContentChildren(MEPaneComponent) public cpanes: QueryList<MEPaneComponent>;
  @Output() public tabSelected: EventEmitter<MEPaneComponent> =
    new EventEmitter<MEPaneComponent>();
  public currentPane: MEPaneComponent;
  public panes: MEPaneComponent[] = [];

  constructor(protected cd: ChangeDetectorRef) {}

  public ngAfterContentInit(): void {
    this.panes = [];
    this.cpanes.forEach((p: MEPaneComponent) => {
      this.panes.push(p);
    });
  }

  public select(pane: MEPaneComponent, deferEvent = 0) {
    console.log('select: ' + pane.id);
    this.currentPane = pane;
    this.panes.forEach((p: MEPaneComponent) => {
      const h = p.forceEvents;
      if (p.active) {
        p.forceEvents = true;
      }
      p.active = p === pane;
      p.forceEvents = h;
    });
    this.cd.markForCheck();
    if (deferEvent > 0) {
      setTimeout(() => {
        this.tabSelected.emit(pane);
      }, deferEvent);
    } else {
      this.tabSelected.emit(pane);
    }
  }

  public selectById(id: string, deferEvent = 0): void {
    console.log('selectById: ' + id);
    if (
      this.currentPane !== null &&
      this.currentPane !== undefined &&
      this.currentPane.id === id
    ) {
      // Pane ist schon ausgewählt
      return;
    }
    this.panes.forEach(pane => {
      if (pane.id === id) {
        this.select(pane, deferEvent);
      }
    });
  }

  ngAfterViewChecked(): void {
    if (
      (this.currentPane === null || this.currentPane === undefined) &&
      this.panes.length > 0
    ) {
      this.select(this.panes[0]);
    }
  }

  selectByClick(p: MEPaneComponent) {
    if (p !== this.currentPane) {
      this.select(p);
    }
  }

  hasExtraHeader() {
    return (
      this.extraActionContainers !== null &&
      this.extraActionContainers !== undefined &&
      this.extraActionContainers.length > 0
    );
  }
}
