import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {WireguardInterface} from "./wireguard-interface.class";
import {IWireguardInterface} from "./wireguard-interface.interface";
import {WireguardInterfaceImpl} from "./wireguard-interface.impl";

export class WireguardInterfaceList extends MERemoteList<WireguardInterface> {
    __instance='';

    static fromListResult(
        res:IMEListResult<IWireguardInterface>
    ): WireguardInterfaceList {
        const newList= new WireguardInterfaceList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun:boolean=false,timeout:number=400){
        super();
        this.initList(autorun,timeout);
    }

    public doPopulateFromListResult(
        res:IMEListResult<any>,
        defaultSort:string='display_name',
        defaultOrder:string='asc',
        clearFilter:boolean=true
    ){
        super.doPopulateFromListResult(res,defaultSort,defaultOrder,clearFilter);
    }

    convertEntity(entity:any):any {
        return WireguardInterfaceImpl.fromResult(entity)
    }
}