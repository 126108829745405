import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { BenutzerService } from '../../services/benutzer.service';
import { MEPubSubService } from '../../../maennl-commons/services';
import { noop } from '../../../maennl-commons/tools';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { SysInfoService } from '../../../nav/sys-info.service';

@Component({
  selector: 'app-username-display',
  template: `
    <span [class.offline]="offline" [class.not-logged-in]="!loggedIn">
      {{ uname }}
    </span>
  `,
  styles: [
    `
      :host .not-logged-in {
        display: block;
        padding-right: 9px;
      }

      :host .offline {
        padding-top: 9px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserNameDisplayComponent implements OnInit, OnDestroy {
  __classname = 'UserNameDisplayComponent';
  __instance = '';

  uname = '';
  offline = false;
  loggedIn = false;

  constructor(
    public loginService: BenutzerService,
    public pubSub: MEPubSubService,
    public sysInfoService: SysInfoService,
    private cd: ChangeDetectorRef
  ) {}

  updateUname() {
    this.uname = 'nicht eingeloggt';
    if (this.loginService !== undefined && this.loginService !== null) {
      if (this.loginService.isLoggedIn()) {
        this.uname = this.loginService.getFullname();
        if (this.uname === '') {
          this.uname = this.loginService.getUsername();
        }
      }
      this.loggedIn = this.loginService.isLoggedIn();
    }
    if (this.cd !== null && this.cd !== undefined) {
      this.cd.markForCheck();
    }
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.pubSub.loginChange.subscribe(() => {
        this.loggedIn = this.loginService.isLoggedIn();
        this.updateUname();
        if (this.cd !== null && this.cd !== undefined) {
          this.cd.markForCheck();
        }
      }, noop)
    );
    MESubscriptionHelper.add(
      this,
      this.pubSub.onlineStateChange.subscribe((s) => {
        this.offline = !s;
        if (this.cd !== null && this.cd !== undefined) {
          this.cd.markForCheck();
        }
      }, noop)
    );

    this.offline = !this.sysInfoService.isOnline();
    this.loggedIn = this.loginService.isLoggedIn();
    this.updateUname();
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
  }
}
