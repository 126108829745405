import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../../maennl-commons';
import { IRBCustomer } from './irbcustomer';
import { RBPlan } from './rbplan';

export class RBCustomer
  extends MEGenericEntity<IRBCustomer>
  implements IRBCustomer
{
  display_text: string = null;
  gap_filler_enabled: boolean = null;
  id: number = null;
  last_booking: Date = null;
  last_received: Date = null;
  num_bookings: number = null;
  plan: number = null;
  planPOJO: RBPlan = null;
  projekt: number = null;
  login: string = null;
  key: string = null;

  constructor() {
    super();
  }

  static fromResult(
    entity: IRBCustomer,
    createIfNull: boolean = false
  ): RBCustomer {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new RBCustomer();
      }
      return null;
    }
    const instance = new RBCustomer();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addLongField('projekt')
        .addTextField('display_text')
        .addLongField('plan')
        .addBooleanField('gap_filler_enabled')
        .addLongField('num_bookings')
        .addDateField('last_booking')
        .addDateField('last_received')
        .addTextField('login')
        .addTextField('key')
        .addConverterField(
          'planPOJO',
          new MEGenericEntityConverterParams<RBPlan>((value: any) => {
            return RBPlan.fromResult(value);
          })
        ),
      (e) => {}
    );
    return instance;
  }
}
