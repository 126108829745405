import { Component, OnInit } from '@angular/core';
import { Check } from '../../common/check';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Machine } from '../../common/machine';
import { MEPubSubService } from '../../../maennl-commons/services';
import { ServiceType } from '../../common/service-type';
import { MEGenericModalComponent } from '../../../maennl-commons/megeneric-modal';
import { SysHealthService } from '../../services/sys-health.service';
import { MERichError } from '../../../maennl-commons/tools';
import { IMEActionResponse } from '../../../maennl-commons/network';
import { MEConfirmDialogComponent } from '../../../maennl-commons/meconfirm-dialog';
import { ToastrService } from 'ngx-toastr';
import {
  check_check_params,
  default_check_params
} from '../../common/check-params';

@Component({
  selector: 'app-sys-health-system-dservice-edit-dialog',
  templateUrl: './sys-health-system-dservice-edit-dialog.component.html',
  styleUrls: ['./sys-health-system-dservice-edit-dialog.component.scss']
})
export class SysHealthSystemDserviceEditDialogComponent
  extends MEGenericModalComponent<Check>
  implements OnInit
{
  public machine = new Machine(0);
  public entity: Check = new Check();

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public syshealthService: SysHealthService,
    public modalService: NgbModal,
    public toastr: ToastrService
  ) {
    super(activeModal, pubsub);
  }

  public initEntity(src: Check): Check {
    this.machine = Machine.fromResult(this.meta.machine);
    const e = Check.fromResult(src, true);
    e.serviceType = ServiceType.systemDService();
    e.machine = this.machine;
    if (e.params === null || e.params === undefined) {
      e.params = default_check_params();
    }
    check_check_params(e.params);

    if (
      e.waitBetween === null ||
      e.waitBetween === undefined ||
      e.waitBetween < 1
    ) {
      e.waitBetween = 120;
    }
    return e;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  save() {
    this.syshealthService.storeCheck(this.entity).subscribe(
      (r: IMEActionResponse) => {
        if (r.success) {
          this.ok();
        } else {
          MERichError.throw(
            'Fehler beim Speichern',
            'Der Dienst konnte nicht gespeichert werden'
          );
        }
      },
      () => {
        MERichError.throw(
          'Fehler beim Speichern',
          'Der Dienst konnte nicht gespeichert werden'
        );
      }
    );
  }

  delete() {
    if (
      this.entity.id === null ||
      this.entity.id === undefined ||
      this.entity.id < 1
    ) {
      return;
    }
    MEConfirmDialogComponent.display(
      this.modalService,
      'Überprüfung löschen',
      'Soll der Test ' + this.entity.info + ' wirklich gelöscht werden?',
      'Ja, jetzt löschen',
      'icofont icofont-trash',
      MEGenericModalComponent.SIZE_SMALL
    ).then(() => {
      this.syshealthService.deleteCheck(this.entity).subscribe(
        () => {
          this.ok();
        },
        () => {
          MERichError.throw(
            'Fehler beim Löschen ',
            'Beim Löschen des Tests ' +
              this.entity.info +
              ' ' +
              'ist ein Fehler aufgetreten.'
          );
        }
      );
    });
  }
}
