<div class="modal-header">
    <h4 class="modal-title">Peilungen der Sonde {{ sondeBezeichnung }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">

    <div class="d-flex justify-content-between">
        <div class="d-flex justify-content-start align-items-center">
            <div class="btn-group-sm btn-group btn-group-toggle"  data-bs-toggle="buttons">
                <label class="btn btn-secondary" [class.active]="displaymode==0" (click)="setMode(0)">
                    <input type="radio" name="options" id="option1" autocomplete="off" checked>
                    <i class="far fa-table"></i> {{_('Tabelle')}}
                </label>
                <label class="btn btn-secondary" [class.active]="displaymode==1" (click)="setMode(1)">
                    <input type="radio" name="options" id="option3" autocomplete="off">
                    <i class="far fa-chart-line"></i> {{_('Grafik')}}
                </label>
            </div>
            <button class="btn btn-sm ms-2"
                    [class.btn-primary]="peilungen.simpleFilter!==null && peilungen.simpleFilter!==undefined && peilungen.simpleFilter.hasValues()"
                    [class.btn-secondary]="peilungen.simpleFilter===null || peilungen.simpleFilter===undefined || !peilungen.simpleFilter.hasValues()"
                    (click)="setFilter()"
            ><i class="icofont icofont-filter"></i> Filter setzen
            </button>
            <div class="ms-2" *ngIf="total>=0">{{total}} Werte
                <span
                    *ngIf="peilungen.simpleFilter!==null && peilungen.simpleFilter!==undefined && peilungen.simpleFilter.hasValues()">
                    im Filterbereich</span><span *ngIf="dataLoading">, Daten werden geladen...</span>
            </div>


        </div>
        <div>
            <me-pagination
                    *ngIf="displaymode===0"
                    [total]="peilungen.total"
                    [page]="peilungen.page"
                    [perpage]="peilungen.size"
                    (pageChange)="peilungen.setPage($event)"
                    (pageCountChange)="peilungen.processPageCountChangedEvent($event)"
                    settingsid="peilungen-popup"
                    [settingsService]="benutzerService"
            ></me-pagination>
        </div>
    </div>


    <div *ngIf="displaymode==0">
        <me-data-table
                [rowActionsAsButtons]="false"
                [tableActionsAsButtons]="true"
                [data]="peilungen.asDataTableRows()"
                [selectable]="false"
                actionsicon="icofont icofont-navigation-menu"
        >
            <me-data-table-column [label]="'Sonde'"
                                  [field]="'sonde'"
                                  [renderer]="sondeRenderer"

            ></me-data-table-column>
            <me-data-table-column [label]="'Zeitpunkt'" field="zeitpunkt" type="date"
                                  [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
            ></me-data-table-column>
            <me-data-table-column label="Stand 1" field="cstand1" [formatter]="standFormatter"
                                  ></me-data-table-column>
            <me-data-table-column label="Stand 2" field="cstand2" [formatter]="standFormatter"
                                  *ngIf="entity.hasSensor2"
            ></me-data-table-column>
            <me-data-table-column label="Temperatur" field="temperatur" type="decimal"
                                  [cfg]="{fdigits:0}"
                                  *ngIf="entity.hasTemperaturSensor"
            ></me-data-table-column>


        </me-data-table>
    </div>
    <div *ngIf="displaymode==1" class="chart">
        <app-peilungen-diagramm
            #diagramm
            [sonde]="entity"
            [sondenData]="peilungen"
            (needsData)="diagrammNeedsData($event)"
            (zoom)="diagrammZoomed($event)"
        ></app-peilungen-diagramm>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
</div>
