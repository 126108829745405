import { Injectable } from '@angular/core';
import { MELoginErrorEventEmitter } from './melogin-error-event-emitter';
import { MERequestEventEmitter } from './merequest-event-emitter';
import { MEResponseEventEmitter } from './meresponse-event-emitter';
import { MEMessageChangeEventEmitter } from './memessage-change-event-emitter';
import { MELoginChangeEventEmitter } from './melogin-change-event-emitter';
import { MEStateChangeEmitter } from './mestate-change-emitter';
import { MEGenericEventEmitter } from './megeneric-event-emitter';

@Injectable()
export class MEPubSubService {
  beforeRequest: MERequestEventEmitter = new MERequestEventEmitter();
  afterRequest: MEResponseEventEmitter = new MEResponseEventEmitter();
  loginError: MELoginErrorEventEmitter = new MELoginErrorEventEmitter();
  messageChange: MEMessageChangeEventEmitter =
    new MEMessageChangeEventEmitter();
  loginChange: MELoginChangeEventEmitter = new MELoginChangeEventEmitter();
  adminSystemMessageChange: MEMessageChangeEventEmitter =
    new MEMessageChangeEventEmitter();
  loginMessageChange: MEMessageChangeEventEmitter =
    new MEMessageChangeEventEmitter();
  developmentModeChange: MEStateChangeEmitter = new MEStateChangeEmitter();
  sysInfoChange: MEGenericEventEmitter = new MEGenericEventEmitter();
  onlineStateChange: MEStateChangeEmitter = new MEStateChangeEmitter();
}
