import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map, Observable, of} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {IMESimpleFilter} from '../../maennl-commons/filters';
import {IMEListResult} from '../../maennl-commons/mecommon-list';
import {
    IMESettingsService,
    TMESettingsServiceGetPageSizeCallback,
    TMESettingsServiceGetSettingCallback,
    TMESettingsServicePutPageSizeCallback,
    TMESettingsServicePutSettingCallback
} from '../../maennl-commons/mepagination/ime-settings-service';
import {MEResultMetaSort} from '../../maennl-commons/mesort-buttons';
import {IMEActionResponse, MEActionResponse, MEApiTool} from '../../maennl-commons/network';
import {MEPubSubService} from '../../maennl-commons/services';
import {MEConverterTool, METool, noop} from '../../maennl-commons/tools';
import {Benutzer} from '../common/benutzer';
import {IBenutzer} from '../common/ibenutzer';
import {ILoginResult} from '../common/ilogin-result';
import {appToken} from '../../app.version';
import {TNullableNumber} from "../../maennl-commons/tools/types";

@Injectable()
export class BenutzerService implements IMESettingsService {
    public currentLogin: ILoginResult = null;
    public currentUser: Benutzer = null;

    constructor(public http: HttpClient, public pubSub: MEPubSubService) {
    }

    public isLoggedIn(): boolean {
        return this.currentLogin != null;
    }

    public hasRole(role: string): boolean {
        if (this.isLoggedIn()) {
            role = role.toUpperCase();
            for (const r of this.currentLogin.roles) {
                if (r === role) {
                    return true;
                }
            }
            return false;
        }
        return role === 'ROLE_ANONYMOUS';
    }

    public hasOneOfRoles(roles: string[]): boolean {
        if (this.isLoggedIn()) {
            let found = false;
            roles.forEach((role) => {
                role = role.toUpperCase();
                for (const r of this.currentLogin.roles) {
                    if (r === role) {
                        found = true;
                    }
                }
            });
            return found;
        }
        return roles.indexOf('ROLE_ANONYMOUS') >= 0;
    }

    public isAdmin(): boolean {
        return this.hasRole('ROLE_ADMIN');
    }

    public getUsername(): string {
        if (this.isLoggedIn()) {
            if (this.currentUser !== null && this.currentUser !== undefined) {
                return this.currentUser.username;
            }
        }
        return null;
    }

    public getFullname(): string {
        if (this.isLoggedIn()) {
            if (this.currentUser !== null && this.currentUser !== undefined) {
                return this.currentUser.fullname;
            }
        }
        return null;
    }

    public doLogout(removeRememberMe = false) {
        sessionStorage.removeItem('auth_token');
        localStorage.removeItem('refresh_token');
        sessionStorage.removeItem('token_expires');

        if (removeRememberMe) {
            localStorage.removeItem('rememberme');
        }

        this.currentUser = null;
        this.pubSub.loginChange.emit(this.currentUser);
    }

    public getToken(autoRefresh = true): string {
        if (this.isLoggedIn()) {
            const restLaufzeit =
                (parseInt(sessionStorage.getItem('token_expires'), 10) -
                    new Date().getTime()) /
                1000.0;
            console.debug('Restlaufzeit: ' + restLaufzeit);
            if (autoRefresh && restLaufzeit < 1000) {
                if (this.hasRefreshToken()) {
                    console.debug('Aktualisiere Login-Token');
                    this.refreshLoginToken();
                }
            }
            return sessionStorage.getItem('auth_token');
        }
        return '';
    }

    public getRequestOptions(
        addJSON = true,
        includeToken = true,
        autoRefresh = true,
        responseType = null
    ) {
        const options = {
            headers: {}
        };

        if (responseType !== null) {
            options['responseType'] = responseType;
        }

        if (addJSON) {
            options.headers['Content-Type'] = 'application/json';
        }
        if (this.isLoggedIn() && includeToken) {
            options.headers['Authorization'] = 'Bearer ' + this.getToken(autoRefresh);
            options.headers['X-Auth-Token'] = this.getToken(autoRefresh);
        }

        options.headers['X-App-Token'] = appToken;
        return options;
    }

    public doLogin(
        benutzer: Benutzer,
        rememberMe: boolean
    ): Observable<ILoginResult> {
        this.doLogout();
        if (benutzer === null || benutzer === undefined) {
            return null;
        }
        return this.http
            .post<ILoginResult>(
                '/access/login',
                JSON.stringify({
                    username: benutzer.username,
                    password: benutzer.password
                }),
                this.getRequestOptions()
            )
            .pipe(
                tap(
                    (res: ILoginResult) => {
                        this.currentLogin = res;
                        this.currentUser = null;
                        sessionStorage.setItem('auth_token', res.access_token || '');
                        localStorage.setItem('refresh_token', res.refresh_token || '');
                        const d = new Date();
                        sessionStorage.setItem(
                            'token_expires',
                            '' + (d.getTime() + parseInt('' + res.expires_in, 10) * 1000)
                        );
                        if (rememberMe) {
                            localStorage.setItem(
                                'rememberme',
                                MEConverterTool.crypt(JSON.stringify(benutzer))
                            );
                        } else {
                            localStorage.removeItem('rememberme');
                        }
                        this.getCurrentUser().subscribe(
                            (u: IBenutzer) => {
                                this.currentUser = Benutzer.fromResult(u);
                                this.pubSub.loginChange.emit();
                            },
                            () => {
                                this.doLogout();
                            }
                        );
                    },
                    () => {
                        this.currentUser = null;
                        this.currentLogin = null;
                        this.pubSub.loginError.emit();
                        this.pubSub.loginChange.emit();
                    }
                ),
                take(1)
            );
    }

    public refreshLoginTokenLogin(): Observable<ILoginResult> {
        const t = localStorage.getItem('refresh_token');
        const o = this.getRequestOptions(false, false, false);
        o.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        o['observer'] = 'response';
        const body = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', t);

        return this.http.post<ILoginResult>('/access/refresh', body, o).pipe(
            tap(
                (res: ILoginResult) => {
                    this.currentLogin = res;
                    this.currentUser = null;
                    sessionStorage.setItem('auth_token', res.access_token || '');
                    localStorage.setItem('refresh_token', res.refresh_token || '');
                    const d = new Date();
                    sessionStorage.setItem(
                        'token_expires',
                        '' + (d.getTime() + parseInt('' + res.expires_in, 10) * 1000)
                    );
                    this.getCurrentUser().subscribe(
                        (u: IBenutzer) => {
                            this.currentUser = Benutzer.fromResult(u);
                            this.pubSub.loginChange.emit();
                        },
                        () => {
                            this.doLogout();
                        }
                    );
                },
                () => {
                    this.currentUser = null;
                    this.currentLogin = null;
                    this.pubSub.loginError.emit();
                    this.pubSub.loginChange.emit();
                }
            ),
            take(1)
        );
    }

    public refreshLoginToken(): void {
        const t = localStorage.getItem('refresh_token');
        const o = this.getRequestOptions(false, false, false);
        o.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        o['observer'] = 'response';
        const body = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', t);

        this.http
            .post<ILoginResult>('/access/refresh', body, o)
            .pipe(take(1))
            .subscribe(
                (tokenR) => {
                    if (!METool.isNullOrUndefined(tokenR)) {
                        if (
                            METool.isDefined(tokenR.access_token) &&
                            !METool.isEmpty(tokenR.access_token)
                        ) {
                            this.currentLogin = tokenR;
                            if (tokenR.username !== this.currentUser.username) {
                                this.currentUser = null;
                            }
                            sessionStorage.setItem('auth_token', tokenR.access_token || '');
                            localStorage.setItem('refresh_token', tokenR.refresh_token || '');
                            const d = new Date();
                            sessionStorage.setItem(
                                'token_expires',
                                '' + (d.getTime() + parseInt('' + tokenR.expires_in, 10) * 1000)
                            );

                            if (METool.isNullOrUndefined(this.currentUser)) {
                                this.getCurrentUser(false).subscribe(
                                    (u: IBenutzer) => {
                                        this.currentUser = Benutzer.fromResult(u);
                                        this.pubSub.loginChange.emit();
                                    },
                                    () => {
                                        this.doLogout();
                                    }
                                );
                            }

                            console.debug('Token aktualisiert');
                        }
                    }
                },
                () => {
                    console.debug('Fehler beim Aktualisieren des Tokens');
                }
            );
    }

    public hasRememberedToken() {
        const a = localStorage.getItem('rememberme');
        return !(a === null || a === undefined || a === '');
    }

    public hasRefreshToken() {
        const a = localStorage.getItem('refresh_token');
        return !(a === null || a === undefined || a === '');
    }

    public rememberedLogin(): Observable<ILoginResult> {
        let benutzer: Benutzer = null;

        try {
            const json = JSON.parse(
                MEConverterTool.decrypt(localStorage.getItem('rememberme'))
            );
            benutzer = Benutzer.fromResult(json);
        } catch (e) {
            console.log(e);
        }

        return this.doLogin(benutzer, true);
    }

    public getCurrentUser(refresh = true): Observable<IBenutzer> {
        return this.http
            .get<IBenutzer>(
                '/api/access/currentUser/',
                this.getRequestOptions(true, true, refresh)
            )
            .pipe(take(1));
    }

    list(
        max = 0,
        offset = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<IBenutzer> = null,
        searchQuery = ''
    ): Observable<IMEListResult<IBenutzer>> {
        const uri = MEApiTool.buildListURL(
            '/api/benutzer',
            max,
            offset,
            sort,
            filter,
            searchQuery
        );
        return this.http
            .get<IMEListResult<IBenutzer>>(uri, this.getRequestOptions())
            .pipe(take(1));
    }

    updateUserSetting(key: string, value: any) {
        if (this.currentUser === null || this.currentUser === undefined) {
            return;
        }
        if (
            this.currentUser.settings === null ||
            this.currentUser.settings === undefined
        ) {
            this.currentUser.settings = {};
        }
        this.currentUser.settings[key] = value;
        this.http
            .patch<IMESettingsService>(
                '/api/access/currentUser/settings',
                JSON.stringify({
                    settingid: '' + key,
                    value: value
                }),
                this.getRequestOptions()
            )
            .pipe(take(1))
            .subscribe(() => {
                noop();
            });
    }

    public getUserSetting: TMESettingsServiceGetSettingCallback = (
        key: string,
        defValue: any = null
    ): any => {
        if (this.currentUser === null || this.currentUser === undefined) {
            return defValue;
        }
        if (
            this.currentUser.settings !== null &&
            this.currentUser.settings !== undefined
        ) {
            if (Object.keys(this.currentUser.settings).indexOf(key) >= 0) {
                return this.currentUser.settings[key];
            }
        }
        return defValue;
    };

    public getPageSize: TMESettingsServiceGetPageSizeCallback = (
        key: string,
        defValue: number = 10
    ): number => {
        let def = parseInt(
            '' + this.getUserSetting('default_perPage', defValue),
            10
        );
        if (isNaN(def)) {
            def = 10;
        }

        let r = parseInt('' + this.getUserSetting(key + '_page_size', def), 10);
        if (isNaN(r)) {
            r = def;
        }
        return r;
    };

    public setPageSize: TMESettingsServicePutPageSizeCallback = (
        key: string,
        value: number
    ) => {
        this.updateUserSetting(key + '_page_size', value);
    };

    public setUserSetting: TMESettingsServicePutSettingCallback = (
        key: string,
        value: any
    ) => {
        this.updateUserSetting(key, value);
    };

    public updateCurrentPassword(pw: string): Observable<IMEActionResponse> {
        if (this.currentUser === null || this.currentUser === undefined) {
            return;
        }
        return this.http
            .put<IMEActionResponse>(
                '/api/access/currentUser/password',
                JSON.stringify({
                    value: pw
                }),
                this.getRequestOptions()
            )
            .pipe(take(1));
    }

    canEditWebfuelTracker() {
        if (this.isAdmin()) {
            return true;
        }
        return this.hasRole('ROLE_WEBFUEL_TRACKER_WRITABLE');
    }

    public loadUser(id: TNullableNumber): Observable<IBenutzer> {
        if (id === null || id === undefined || id < 1) {
            return of(null);
        }
        const uri = MEApiTool.buildEntityURL('/api/benutzer/', id);
        return this.http
            .get<IBenutzer>(uri, this.getRequestOptions())
            .pipe(
                map((c) => {
                    return Benutzer.fromResult(c);
                }),
                take(1)
            );
    }

    public storeUser(entity: Benutzer): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            '/api/benutzer/',
            entity.id
        );

        if (entity.id === null || entity.id === undefined || entity.id < 1) {
            return this.http
                .post<IMEActionResponse>(
                    uri,
                    entity,
                    this.getRequestOptions()
                )
                .pipe(take(1));
        }
        return this.http
            .put<IMEActionResponse>(
                uri,
                entity,
                this.getRequestOptions()
            )
            .pipe(take(1));
    }

    public removeUser(entity: Benutzer): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            '/api/benutzer/',
            entity.id
        );
        return this.http
            .delete<IMEActionResponse>(uri, this.getRequestOptions())
            .pipe(take(1));
    }

    add_to_authority(
        id: TNullableNumber,
        authority_id: TNullableNumber
    ): Observable<MEActionResponse> {
        if (
            id === null ||
            id === undefined ||
            id < 1 ||
            authority_id === null ||
            authority_id === undefined ||
            authority_id < 1
        ) {
            return of(MEActionResponse.error('id is null or undefined'));
        }

        const uri = MEApiTool.buildActionURL(
            '/api/benutzer/',
            id,
            '_addToGroup'
        );

        return this.http
            .post<IMEActionResponse>(
                uri,
                {authority_id: authority_id},
                this.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }

    remove_from_authority(
        id: TNullableNumber,
        authority_id: TNullableNumber
    ): Observable<MEActionResponse> {
        if (
            id === null ||
            id === undefined ||
            id < 1 ||
            authority_id === null ||
            authority_id === undefined ||
            authority_id < 1
        ) {
            return of(MEActionResponse.error('id is null or undefined'));
        }

        const uri = MEApiTool.buildActionURL(
            '/api/benutzer/',
            id,
            '_removeFromAuthority'
        );

        return this.http
            .post<IMEActionResponse>(
                uri,
                {authority_id: authority_id},
                this.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }
}
