import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { AbrechnungPickelmann } from './abrechnung-pickelmann';
import { IAbrechnungPickelmann } from './iabrechnung-pickelmann';
import { SimpleAbrechnungPickelmannFilter } from './simple-abrechnung-pickelmann-filter';

export class AbrechnungPickelmannList extends MERemoteList<AbrechnungPickelmann> {
  __classname = 'AbrechnungPickelmannList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IAbrechnungPickelmann>
  ): AbrechnungPickelmannList {
    const newList = new AbrechnungPickelmannList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleAbrechnungPickelmannFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'periode',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return AbrechnungPickelmann.fromResult(entity);
  }
}
