import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { MERowActionParams } from './merow-action-params';
import { MEDataTableRow } from '../rows/medata-table-row';
import { TMERowActionAvailableCheckCallback } from './types';

@Directive({
  selector: 'me-data-table-rowaction'
})
export class MEDataTableRowActionDirective {
  @Input() public cmd = 'cmd';
  private _hidden = false;
  @Input() public icon = '';
  @Input() public label = 'Befehl';
  @Input() public separator = false;
  @Input() public showLabel = false;
  @Input() public title = '';
  @Output() public checkEnabled = new EventEmitter<MERowActionParams<any>>();
  @Output() public perform = new EventEmitter<MERowActionParams<any>>();

  @Input() public availableCheck: TMERowActionAvailableCheckCallback<any> =
    null;

  get hidden(): boolean {
    if (this._hidden) {
      return true;
    }
    return this.isHidden(null);
  }

  @Input()
  set hidden(value: boolean) {
    this._hidden = value;
  }

  public isHidden(row: MEDataTableRow<any> = null): boolean {
    if (row !== null && row !== undefined) {
      if (this.availableCheck !== null && this.availableCheck !== undefined) {
        return !this.availableCheck(row);
      }
      return row.isActionHidden(this);
    }
    return this.hidden;
  }

  public performAction(row: MEDataTableRow<any>) {
    if (row !== null && row !== undefined) {
      if (!row.isActionDisabled(this)) {
        this.perform.emit(new MERowActionParams<any>(row, this));
      }
    }
  }

  public hasIcon(): boolean {
    return this.icon !== undefined && this.icon !== null && this.icon !== '';
  }

  public checkIfEnabled(row: MEDataTableRow<any>) {
    this.checkEnabled.emit(new MERowActionParams<any>(row, this));
  }

  public isSeparator(): boolean {
    return this.separator;
  }
}
