import { MEConverterTool } from '../../../maennl-commons';

export class ER3Chunk {
  bytes: number[] = [];
  hexBytes: string[] = [];
  translated: string[] = [];

  knownDirection = 0;

  public display_mode = 3; // 0 = raw, 1 = translated, 2=formatted, 3=aufbereitet

  flip_sides = false;

  public add(numByte: number) {
    this.bytes.push(numByte);
    this.hexBytes.push(
      MEConverterTool.right_string('000' + numByte.toString(16), 2)
    );
    switch (numByte) {
      case 0xaa:
        this.translated.push('00');
        break;
      case 0x6a:
        this.translated.push('01');
        break;
      case 0x9a:
        this.translated.push('02');
        break;
      case 0x5a:
        this.translated.push('03');
        break;
      case 0xa6:
        this.translated.push('04');
        break;
      case 0x66:
        this.translated.push('05');
        break;
      case 0x96:
        this.translated.push('06');
        break;
      case 0x56:
        this.translated.push('07');
        break;
      case 0xa9:
        this.translated.push('08');
        break;
      case 0x69:
        this.translated.push('09');
        break;
      case 0x99:
        this.translated.push('0a');
        break;
      case 0x59:
        this.translated.push('0b');
        break;
      case 0xa5:
        this.translated.push('0c');
        break;
      case 0x65:
        this.translated.push('0d');
        break;
      case 0x95:
        this.translated.push('0e');
        break;
      case 0x55:
        this.translated.push('0f');
        break;
      case 0x00:
        this.translated.push('pp');
        break;
      default:
        if (numByte < 1 || numByte > 20) {
          this.translated.push('ff');
        }
    }
  }

  public hasBytes() {
    return this.bytes.length > 0;
  }

  isLeft(): boolean {
    return this.guessDirection() === 'left';
  }

  isRight(): boolean {
    return this.guessDirection() === 'right';
  }

  isCenter(): boolean {
    return this.guessDirection() === 'center';
  }

  public guessDirection(flip_sides = null) {
    if (flip_sides === null) {
      flip_sides = this.flip_sides;
    }
    this.flip_sides = flip_sides;
    if (this.knownDirection === 2 || this.knownDirection === 0x32) {
      if (this.flip_sides) {
        return 'right';
      }
      return 'left';
    }
    if (this.knownDirection === 1 || this.knownDirection === 0x31) {
      if (this.flip_sides) {
        return 'left';
      }
      return 'right';
    }
    if (this.knownDirection === 3 || this.knownDirection === 0x33) {
      return 'center';
    }
    /*
        if (this.getType().startsWith('master')){
             if (this.flip_sides) {
                return 'right';
            }
            return 'left';
        }
        if (this.getType().startsWith('endpoint')){
            if (this.flip_sides) {
                return 'left';
            }
            return 'right';
        }
*/
    return 'center';
  }

  public getType() {
    if (this.isController()) {
      if (this.cmd() === '01' && this.dataBytes().length > 9) {
        return 'master-vorwahl';
      }
      if (this.cmd() === '04') {
        return 'master-anforderung-tankdaten';
      }
      if (this.cmd() === '07') {
        return 'master-freigabe-ohne-null';
      }
      if (this.cmd() === '09') {
        return 'master-autarksummen-löschen';
      }
      if (this.cmd() === '00') {
        return 'master-freigabe';
      }
      if (this.cmd() === '03' && this.dataBytes().length > 0) {
        return 'master-anforderung-summen';
      }
      if (this.cmd() === '02' && this.dataBytes().length > 4) {
        return 'master-grundpreisverstellung';
      }
      if (this.cmd() === '05') {
        return 'master-sperren';
      }
      if (this.cmd() === '06' && this.dataBytes().length > 0) {
        return 'master-zapfsaeulen-beleuchtung';
      }
      if (
        this.cmd() === '' &&
        this.dataBytes().length < 1 &&
        this.prePulseBytes().length === 1
      ) {
        return 'master-quittung';
      }
    }

    if (this.isEndpoint()) {
      if (this.cmd() === '01' && this.dataBytes().length > 12) {
        return 'endpoint-tankdaten';
      }
      if (this.cmd() === '08' && this.dataBytes().length > 1) {
        return 'endpoint-zapfhahn-ziehen';
      }
      if (this.cmd() === '00' && this.dataBytes().length > 1) {
        return 'endpoint-qualitätsvorwahl';
      }
      if (this.cmd() === '09' && this.dataBytes().length > 1) {
        return 'endpoint-zapfhahn-stecken';
      }
      if (this.cmd() === '03' && this.dataBytes().length > 10) {
        return 'endpoint-summen';
      }
      if (
        this.cmd() === '' &&
        this.dataBytes().length < 1 &&
        this.prePulseBytes().length === 1
      ) {
        return 'endpoint-quittung';
      }
      if (this.cmd() === '02' && this.dataBytes().length > 8) {
        return 'endpoint-zwischensummen-autark';
      }
    }
    return 'dummy';
  }

  public isDummy() {
    return this.getType() === 'dummy';
  }

  public getTitle() {
    switch (this.getType()) {
      case 'endpoint-tankdaten':
        return 'Tankdaten';
      case 'endpoint-zapfhahn-ziehen':
        return 'Pistole ziehen';
      case 'endpoint-qualitätsvorwahl':
        return 'Qualitätsvorwahl';
      case 'endpoint-zapfhahn-stecken':
        return 'Pistole stecken';
      case 'endpoint-summen':
        return 'Summenzähler';
      case 'endpoint-quittung':
        return 'Quittung';
      case 'endpoint-zwischensummen-autark':
        return 'Zwischensummen autark';
      case 'master-vorwahl':
        return 'Vorwahl & Grundpreis';
      case 'master-anforderung-tankdaten':
        return 'Anforderung Tankdaten';
      case 'master-freigabe-ohne-null':
        return 'Freigabe ohne Nullstellung';
      case 'master-autarksummen-löschen':
        return 'Autarksummen löschen';
      case 'master-freigabe':
        return 'Freigabe';
      case 'master-anforderung-summen':
        return 'Anfrage Summenzähler';
      case 'master-grundpreisverstellung':
        return 'Grundpreisverstellung';
      case 'master-sperren':
        return 'Sperren';
      case 'master-zapfsaeulen-beleuchtung':
        return 'Zapfsäulenbeleuchtung';
      case 'master-quittung':
        return 'Quittung';
      default:
        return 'unbekannte/nicht näher definierte Daten';
    }
  }

  public prePulseBytes(): string[] {
    const pp = [];
    let p = 0;
    while (this.translated[p] === 'pp' && p < this.translated.length) {
      pp.push('00');
      p++;
    }
    if (pp.length < 1) {
      if (
        this.translated.length < this.hexBytes.length &&
        this.bytes[this.bytes.length - 1] > 1 &&
        this.bytes[this.bytes.length - 1] < 20
      ) {
        pp.push('00');
      }
    }
    return pp;
  }

  public pplen(): number {
    if (
      this.translated.length < this.hexBytes.length &&
      this.bytes[this.bytes.length - 1] > 1 &&
      this.bytes[this.bytes.length - 1] < 20
    ) {
      return this.bytes[this.bytes.length - 1];
    }
    return 0;
  }

  public cmdBytes(): string[] {
    if (this.cmd() === '') {
      return [];
    }
    return [this.cmd()];
  }

  public cmd(): string {
    let p = 0;
    while (this.translated[p] === 'pp' && p < this.translated.length) {
      p++;
    }
    if (p < this.translated.length) {
      return this.translated[p];
    }
    return '';
  }

  public dataBytes() {
    let p = 0;
    while (this.translated[p] === 'pp' && p < this.translated.length) {
      p++;
    }
    p++;
    const daten = [];
    if (this.translated.length > 1) {
      for (let idx = p; idx < this.translated.length; idx++) {
        daten.push(MEConverterTool.right_string('0' + this.translated[idx], 1));
      }
    }
    return daten;
  }

  isController() {
    return this.isLeft(); // || (this.flip_sides && this.isRight())
  }

  isEndpoint() {
    return this.isRight(); // || (this.flip_sides && this.isLeft())
  }

  isComplete() {
    if (this.isController()) {
      if (this.cmd() === '01' && this.dataBytes().length > 10) {
        return true;
      }
      if (this.cmd() === '04') {
        return true;
      }
      if (this.cmd() === '07') {
        return true;
      }
      if (this.cmd() === '09') {
        return true;
      }
      if (this.cmd() === '00') {
        return true;
      }
      if (this.cmd() === '03' && this.dataBytes().length > 0) {
        return true;
      }
      if (this.cmd() === '02' && this.dataBytes().length > 4) {
        return true;
      }
      if (this.cmd() === '05') {
        return true;
      }
      if (this.cmd() === '06' && this.dataBytes().length > 0) {
        return true;
      }
      if (
        this.cmd() === '' &&
        this.dataBytes().length < 1 &&
        this.prePulseBytes().length === 1
      ) {
        return true;
      }
    }
    if (this.isEndpoint()) {
      if (this.cmd() === '01' && this.dataBytes().length > 12) {
        return true;
      }
      if (this.cmd() === '08' && this.dataBytes().length > 1) {
        return true;
      }
      if (this.cmd() === '00' && this.dataBytes().length > 1) {
        return true;
      }
      if (this.cmd() === '09' && this.dataBytes().length > 1) {
        return true;
      }
      if (this.cmd() === '03' && this.dataBytes().length > 10) {
        return true;
      }
      if (
        this.cmd() === '' &&
        this.dataBytes().length < 1 &&
        this.prePulseBytes().length === 1
      ) {
        return true;
      }
      if (this.cmd() === '02' && this.dataBytes().length > 8) {
        return true;
      }
    }

    return false;
  }

  menge() {
    if (this.getType() === 'endpoint-tankdaten') {
      const db = this.dataBytes();
      let h = '';
      for (let i = 0; i < 5; i++) {
        h = h + '' + parseInt(db[i], 10);
      }
      return parseInt(h, 10) / 100;
    }
    if (this.getType() === 'endpoint-summen') {
      const db = this.dataBytes();
      let h = '';
      for (let i = 0; i < 10; i++) {
        h = h + '' + parseInt(db[i], 10);
      }
      return parseInt(h, 10) / 100;
    }
    if (this.getType() === 'endpoint-zwischensummen-autark') {
      const db = this.dataBytes();
      let h = '';
      for (let i = 0; i < 8; i++) {
        h = h + '' + parseInt(db[i], 10);
      }
      return parseInt(h, 10) / 100;
    }
    if (this.getType() === 'master-vorwahl') {
      const db = this.dataBytes();
      let h = '';
      for (let i = 1; i < 6; i++) {
        h = h + '' + parseInt(db[i], 10);
      }
      return parseInt(h, 10) / 100;
    }

    return 0;
  }

  preis() {
    if (this.getType() === 'endpoint-tankdaten') {
      const db = this.dataBytes();
      let h = '';
      for (let i = 5; i < 10; i++) {
        h = h + '' + parseInt(db[i], 10);
      }
      return parseInt(h, 10) / 100;
    }
    if (this.getType() === 'master-vorwahl') {
      const db = this.dataBytes();
      let h = '';
      for (let i = 6; i < 10; i++) {
        h = h + '' + parseInt(db[i], 10);
      }
      return parseInt(h, 10) / 1000;
    }
    if (this.getType() === 'master-grundpreisverstellung') {
      const db = this.dataBytes();
      let h = '';
      for (let i = 1; i < 5; i++) {
        h = h + '' + parseInt(db[i], 10);
      }
      return parseInt(h, 10) / 1000;
    }

    return 0;
  }

  qualitaet() {
    if (this.getType() === 'endpoint-tankdaten') {
      const db = this.dataBytes();
      return db[10];
    }
    if (
      this.getType() === 'endpoint-zapfhahn-ziehen' ||
      this.getType() === 'endpoint-qualitätsvorwahl' ||
      this.getType() === 'endpoint-zapfhahn-stecken' ||
      this.getType() === 'master-grundpreisverstellung'
    ) {
      const db = this.dataBytes();
      return db[0];
    }
    if (this.getType() === 'endpoint-zwischensummen-autark') {
      const db = this.dataBytes();
      return db[8];
    }

    return 0;
  }

  numerateur_zehner() {
    if (this.getType() === 'endpoint-tankdaten') {
      const db = this.dataBytes();
      return db[11];
    }
    return 0;
  }
  numerateur_einer() {
    if (this.getType() === 'endpoint-tankdaten') {
      const db = this.dataBytes();
      return db[12];
    }
    return 0;
  }

  fehler_code() {
    if (
      this.getType() === 'endpoint-zapfhahn-ziehen' ||
      this.getType() === 'endpoint-qualitätsvorwahl' ||
      this.getType() === 'endpoint-zapfhahn-stecken'
    ) {
      const db = this.dataBytes();
      return db[1];
    }
    return 0;
  }

  qd() {
    if (this.getType() === 'endpoint-summen') {
      const db = this.dataBytes();
      return parseInt(db[10], 10);
    }
    if (this.getType() === 'master-anforderung-summen') {
      const db = this.dataBytes();
      return parseInt(db[0], 10);
    }

    return 0;
  }

  steuerzeichen() {
    if (this.getType() === 'master-vorwahl') {
      const db = this.dataBytes();
      return db[0];
    }
    return '0';
  }

  an() {
    if (this.getType() === 'master-zapfsaeulen-beleuchtung') {
      const db = this.dataBytes();
      return parseInt(db[0], 10) === 1;
    }
    return false;
  }
}
