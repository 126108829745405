import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MEAddOnClickEvent } from '../../../maennl-commons/forms/events';
import { MEGenericModalComponent } from '../../../maennl-commons/megeneric-modal';
import { MEActionResponse } from '../../../maennl-commons/network';
import {
  MEPubSubService,
  MESubscriptionHelper
} from '../../../maennl-commons/services';
import { MEKitchenTimer } from '../../../maennl-commons/timers';
import { MERichError, noop } from '../../../maennl-commons/tools';
import { CSDServerClient } from '../commons/csdserver-client';
import { ICSDServerClient } from '../commons/icsdserver-client';
import { CSDServerService } from '../services/csdserver.service';

class Cmd {
  public label = '';
  public cmd = '';
  public info = '';

  constructor(label: string, cmd: string, info: string = '') {
    this.label = label;
    this.cmd = cmd;
    this.info = info;
  }
}

class Config {
  public label = '';
  public get_cmd = '';
  public set_cmd = '';
  public value = '';
  public pattern: RegExp = new RegExp('(.*)', 'i');

  constructor(
    label: string,
    get_cmd: string,
    set_cmd: string,
    value: string,
    pattern: any
  ) {
    this.label = label;
    this.get_cmd = get_cmd;
    this.set_cmd = set_cmd;
    this.value = value;
    this.pattern = new RegExp(pattern, 'i');
  }
}

@Component({
  selector: 'app-atcommand-tool',
  templateUrl: './atcommand-tool.component.html',
  styleUrls: ['./atcommand-tool.component.scss']
})
export class ATCommandToolComponent
  extends MEGenericModalComponent<CSDServerClient>
  implements OnInit, OnDestroy
{
  __classname = 'ATCommandToolComponent';

  commandos: Cmd[] = [
    new Cmd('Software-Reset', 'AT+Z'),
    new Cmd('Hardware-Reset', 'AT+R'),
    new Cmd('RSSI-Wert', 'AT+CSQ?'),
    new Cmd('Version', 'AT+VER?'),
    new Cmd('Seriennummer', 'AT+SN?'),
    new Cmd('ICCID', 'AT+ICCID?'),
    new Cmd('IMEI', 'AT+IMEI?'),
    new Cmd('IP-Adresse', 'AT+CIP?'),
    new Cmd('Position', 'AT+LBS?'),
    new Cmd(
      'aktuellen Netzmodus abfragen',
      'AT+NETMODE?',
      '0: kein Netz, 1: GSM, 2:CDMA, 3:TDSCDME, 4:WCDNA, 5: EVDO, 6: LTE, 7: TDDLTE, 8: FDDLTE'
    ),
    new Cmd('Sysinfo', 'AT+SYSINFO?'),
    new Cmd('APN abfragen', 'AT+APN?'),
    new Cmd('APN setzen', 'AT+APN=maennl.ic.t-mobile.de,m2m,sim,2'),
    new Cmd('Socket A abrufen', 'AT+SOCKA?'),
    new Cmd('Socket A setzen', 'AT+SOCKA='),
    new Cmd('Socket B abrufen', 'AT+SOCKB?'),
    new Cmd('Socket B setzen', 'AT+SOCKB='),
    new Cmd('Socket C abrufen', 'AT+SOCKC?'),
    new Cmd('Socket C setzen', 'AT+SOCKC='),
    new Cmd('Socket D abrufen', 'AT+SOCKD?'),
    new Cmd('Socket D setzen', 'AT+SOCKD='),
    new Cmd('UART Timeout abrufen', 'AT+UARTFT?'),
    new Cmd('UART Timeout setzen', 'AT+UARTFT=100'),
    new Cmd('UART Puffergröße abrufen', 'AT+UARTFL?'),
    new Cmd('UART Puffergröße setzen', 'AT+UARTFL=100'),
    new Cmd('Default-Settings Restore', 'AT+RELD'),
    new Cmd('Default-Settings löschen', 'AT+CLEAR'),
    new Cmd('aktuelle Einstellungen als Default setzen', 'AT+CFGTF'),
    new Cmd('SMS versenden', 'AT+CISMSSEND=nummer,Text')
  ];

  g781: Config[] = [
    new Config('APN', 'AT+APN?', 'AT+APN={{value}}', '', '.*[+]APN:(.+)$'),
    new Config(
      'UART Timeout',
      'AT+UARTFT?',
      'AT+UARTFT={{value}}',
      '',
      '.*[+]UARTFT:(.*)$'
    ),
    new Config(
      'UART Puffer',
      'AT+UARTFL?',
      'AT+UARTFL={{value}}',
      '',
      '.*[+]UARTFL:(.*)$'
    ),
    new Config(
      'Socket A',
      'AT+SOCKA?',
      'AT+SOCKA={{value}}',
      '',
      '.*[+]SOCKA:(.*)$'
    ),
    new Config(
      'Socket A aktiv',
      'AT+SOCKAEN?',
      'AT+SOCKAEN={{value}}',
      '',
      '.*[+]SOCKAEN:(.*)$'
    ),
    new Config(
      'Socket B',
      'AT+SOCKB?',
      'AT+SOCKB={{value}}',
      '',
      '.*[+]SOCKB:(.*)$'
    ),
    new Config(
      'Socket B aktiv',
      'AT+SOCKBEN?',
      'AT+SOCKBEN={{value}}',
      '',
      '.*[+]SOCKBEN:(.*)$'
    ),
    new Config(
      'Socket C',
      'AT+SOCKC?',
      'AT+SOCKC={{value}}',
      '',
      '.*[+]SOCKC:(.*)$'
    ),
    new Config(
      'Socket C aktiv',
      'AT+SOCKCEN?',
      'AT+SOCKCEN={{value}}',
      '',
      '.*[+]SOCKCEN:(.*)$'
    ),
    new Config(
      'Socket D',
      'AT+SOCKD?',
      'AT+SOCKD={{value}}',
      '',
      '.*[+]SOCKD:(.*)$'
    ),
    new Config(
      'Socket D aktiv',
      'AT+SOCKDEN?',
      'AT+SOCKDEN={{value}}',
      '',
      '.*[+]SOCKDEN:(.*)$'
    ),
    new Config(
      'SystemID Typ',
      'AT+REGTP?',
      'AT+REGTP={{value}}',
      '',
      '.*[+]REGTP:(.*)$'
    ),
    new Config(
      'SystemID Wert',
      'AT+REGDT?',
      'AT+REGDT={{value}}',
      '',
      '.*[+]REGDT:(.*)$'
    ),
    new Config(
      'SystemID Zeitpunkt',
      'AT+REGSND?',
      'AT+REGSND={{value}}',
      '',
      '.*[+]REGSND:(.*)$'
    ),
    new Config(
      'SystemID Aktiv',
      'AT+REGEN?',
      'AT+REGEN={{value}}',
      '',
      '.*[+]REGEN:(.*)$'
    ),
    new Config(
      'Workmode',
      'AT+WKMOD?',
      'AT+WKMOD={{value}}',
      '',
      '.*[+]WKMOD:(.+)$'
    ),
    new Config(
      'CommandPW',
      'AT+CMDPW?',
      'AT+CMDPW={{value}}',
      '',
      '.*[+]CMDPW:(.*)$'
    ),
    new Config(
      'Welcome-Message',
      'AT+STMSG?',
      'AT+STMSG={{value}}',
      '',
      '.*[+]STMSG:(.*)$'
    ),
    new Config(
      'SIM-Pin (G781)',
      'AT+CPIN?',
      'AT+CPIN={{value}}',
      '',
      '.*[+]CPIN:(.*)$'
    ),
    new Config(
      'SIM-Pin (G786)',
      'AT+UCPIN?',
      'AT+UCPIN={{value}}',
      '',
      '.*[+]UCPIN:(.*)$'
    ),
    new Config(
      'automatische Reboot-Zeit',
      'AT+RSTIM?',
      'AT+RSTIM={{value}}',
      '',
      '.*[+]RSTIM:(.*)$'
    ),
    new Config(
      'UART Einstellungen',
      'AT+UART?',
      'AT+UART={{value}}',
      '',
      '.*[+]UART:(.*)$'
    ),
    new Config(
      'Auswahl Serieller Portal',
      'AT+CMDPT?',
      'AT+CMDPT={{value}}',
      '',
      '.*[+]CMDPT:(.*)$'
    ),
    new Config(
      'Socket A Reconnect-Time',
      'AT+SOCKATO?',
      'AT+SOCKATO={{value}}',
      '',
      '.*[+]SOCKATO:(.*)$'
    ),
    new Config(
      'Socket B Reconnect-Time',
      'AT+SOCKBTO?',
      'AT+SOCKBTO={{value}}',
      '',
      '.*[+]SOCKBTO:(.*)$'
    ),
    new Config(
      'Socket C Reconnect-Time',
      'AT+SOCKCTO?',
      'AT+SOCKCTO={{value}}',
      '',
      '.*[+]SOCKCTO:(.*)$'
    ),
    new Config(
      'Socket D Reconnect-Time',
      'AT+SOCKDTO?',
      'AT+SOCKDTO={{value}}',
      '',
      '.*[+]SOCKDTO:(.*)$'
    ),
    new Config(
      'max. Socket-Verbindungen bis Reboot',
      'AT+SOCKRSTIM?',
      'AT+SOCKRSTIM={{value}}',
      '',
      '.*[+]SOCKRSTIM:(.*)$'
    ),
    new Config(
      'MODBUS aktivieren',
      'AT+MODBUSEN?',
      'AT+MODBUSEN={{value}}',
      '',
      '.*[+]MODBUSEN:(.*)$'
    ),
    new Config(
      'Heartbeat einschalten',
      'AT+HEARTEN?',
      'AT+HEARTEN={{value}}',
      '',
      '.*[+]HEARTEN:(.*)$'
    ),
    new Config(
      'Heartbeat-Daten (HEX)',
      'AT+HEARTDT?',
      'AT+HEARTDT={{value}}',
      '',
      '.*[+]HEARTDT:(.*)$'
    ),
    new Config(
      'Heartbeat-Richtung',
      'AT+HEARTSND?',
      'AT+HEARTSND={{value}}',
      '',
      '.*[+]HEARTSND:(.*)$'
    ),
    new Config(
      'Heartbeat Interval',
      'AT+HEARTTM?',
      'AT+HEARTTM={{value}}',
      '',
      '.*[+]HEARTTM:(.*)$'
    ),
    new Config(
      'SMS-Ziel/Filter Nummer',
      'AT+DSTNUM?',
      'AT+DSTNUM={{value}}',
      '',
      '.*[+]DSTNUM:(.*)$'
    ),
    new Config(
      'SMS-Filter aktiv',
      'AT+SMSFLT?',
      'AT+SMSFLT={{value}}',
      '',
      '.*[+]SMSFLT:(.*)$'
    )
  ];

  command = 'AT';
  verlauf = '';
  btnDisbled = false;

  private _connected = false;

  private timer: MEKitchenTimer = new MEKitchenTimer(5000);

  public get connected(): boolean {
    return this._connected;
  }

  public set connected(value: boolean) {
    if (this._connected !== value) {
      this._connected = value;
      this.cd.markForCheck();
    }
  }

  public initEntity(src: ICSDServerClient): CSDServerClient {
    return CSDServerClient.fromResult(src, true);
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public csdServerService: CSDServerService,
    public modalService: NgbModal,
    public cd: ChangeDetectorRef
  ) {
    super(activeModal, pubsub);
  }

  public connect() {
    this.csdServerService.connectClient(this.entity).subscribe(
      (r) => {
        if (r.success) {
          this.connected = true;
        } else {
          const a = MEActionResponse.fromRawActionResponse(r);
          MERichError.throw(
            'Verbindung fehlgeschlagen',
            'Verbindung zum Gerät fehlgeschlagen: ' + a.params['message']
          );
          this.connected = false;
        }
        this.timer.start();
      },
      () => {
        MERichError.throw(
          'Verbindung fehlgeschlagen',
          'Verbindung zum Gerät fehlgeschlagen'
        );
        this.connected = false;
      }
    );
  }

  public disconnect() {
    this.csdServerService.disconnectClient(this.entity).subscribe(
      (r) => {
        if (r.success) {
          this.connected = false;
        } else {
          const a = MEActionResponse.fromRawActionResponse(r);
          MERichError.throw(
            'Trennen fehlgeschlagen',
            'Trennen der Verbindung zum Gerät fehlgeschlagen: ' +
              a.params['message']
          );
        }
        this.timer.start();
      },
      () => {
        MERichError.throw(
          'Trennen fehlgeschlagen',
          'Trennen der Verbindung zum Gerät fehlgeschlagen'
        );
      }
    );
  }

  public ngOnInit() {
    super.ngOnInit();

    MESubscriptionHelper.add(
      this,

      this.timer.subscribe(() => {
        this.csdServerService.checkConnection(this.entity).subscribe((r) => {
          this.connected = r.success;
          this.timer.start();
        });
      }, noop)
    );

    this.timer.fire();
  }

  public ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    super.ngOnDestroy();
  }

  public run() {
    if (!this.btnDisbled) {
      this.runCmd(this.command);
    }
  }

  public runCmd(cmd: string) {
    this.btnDisbled = true;
    this.verlauf = (this.verlauf + '\n\n\nFühre Befehl aus:\n' + cmd).trim();
    this.cd.markForCheck();
    this.csdServerService
      .runCommand(this.entity, this.entity.commandPassword + cmd)
      .subscribe((ar) => {
        const r = MEActionResponse.fromRawActionResponse(ar);
        if (r.success) {
          this.verlauf = (
            this.verlauf +
            '\nErgebnis:\n' +
            r.params.result
          ).trim();
        } else {
          this.verlauf = (
            this.verlauf +
            '\nFehlgeschlagen. Antwort:\n' +
            r.params.message
          ).trim();
        }
        this.cd.markForCheck();
        this.timer.start(true);
        this.btnDisbled = false;
      });
  }

  public tfAction(c: Config, $event: MEAddOnClickEvent) {
    if (c === null) {
      return;
    }
    if ($event.addon === 0) {
      this.btnDisbled = true;
      this.verlauf = (
        this.verlauf +
        '\n\n\nFühre Befehl aus:\n' +
        c.get_cmd
      ).trim();
      this.cd.markForCheck();
      this.csdServerService
        .runCommand(this.entity, this.entity.commandPassword + c.get_cmd)
        .subscribe((ar) => {
          const r = MEActionResponse.fromRawActionResponse(ar);
          if (r.success) {
            this.verlauf = (
              this.verlauf +
              '\nErgebnis:\n' +
              r.params.result
            ).trim();
            c.value = r.params.result;
            const rs = c.pattern.exec(r.params.result);
            if (rs) {
              c.value = rs[1];
            }
          } else {
            this.verlauf = (
              this.verlauf +
              '\nFehlgeschlagen. Antwort:\n' +
              r.params.message
            ).trim();
          }
          this.cd.markForCheck();
          this.timer.start(true);
          this.btnDisbled = false;
        });
      this.cd.markForCheck();
    }
    if ($event.addon === 1) {
      this.btnDisbled = true;
      const cmd = c.set_cmd.replace('{{value}}', c.value.trim());
      this.verlauf = (this.verlauf + '\n\n\nFühre Befehl aus:\n' + cmd).trim();
      this.cd.markForCheck();
      this.csdServerService
        .runCommand(this.entity, this.entity.commandPassword + cmd)
        .subscribe((ar) => {
          const r = MEActionResponse.fromRawActionResponse(ar);
          if (r.success) {
            this.verlauf = (
              this.verlauf +
              '\nErgebnis:\n' +
              r.params.result
            ).trim();
          } else {
            this.verlauf = (
              this.verlauf +
              '\nFehlgeschlagen. Antwort:\n' +
              r.params.message
            ).trim();
          }
          this.cd.markForCheck();
          this.timer.start(true);
          this.btnDisbled = false;
        });
      this.cd.markForCheck();
    }
  }

  public loadConfig(cfg: Config[], idx = 0) {
    if (idx < cfg.length) {
      this.btnDisbled = true;
      this.verlauf = (
        this.verlauf +
        '\n\n\nFühre Befehl aus:\n' +
        cfg[idx].get_cmd
      ).trim();
      this.cd.markForCheck();
      this.csdServerService
        .runCommand(this.entity, this.entity.commandPassword + cfg[idx].get_cmd)
        .subscribe((ar) => {
          const r = MEActionResponse.fromRawActionResponse(ar);
          if (r.success) {
            this.verlauf = (
              this.verlauf +
              '\nErgebnis:\n' +
              r.params.result
            ).trim();
            cfg[idx].value = r.params.result;
            const rs = cfg[idx].pattern.exec(r.params.result);
            if (rs) {
              cfg[idx].value = rs[1];
            }
            this.loadConfig(cfg, idx + 1);
          } else {
            this.verlauf = (
              this.verlauf +
              '\nFehlgeschlagen. Antwort:\n' +
              r.params.message
            ).trim();
          }
          this.cd.markForCheck();
          this.btnDisbled = false;
        });
      this.cd.markForCheck();
    } else {
      this.timer.start(true);
    }
  }

  public saveConfig(cfg: Config[], idx = 0) {
    if (idx < cfg.length) {
      if (
        cfg[idx].value === '' ||
        cfg[idx].value.startsWith('+ERR') ||
        cfg[idx].value === 'undefined'
      ) {
        this.saveConfig(cfg, idx + 1);
      } else {
        this.btnDisbled = true;
        const cmd = cfg[idx].set_cmd.replace(
          '{{value}}',
          cfg[idx].value.trim()
        );

        this.verlauf = (
          this.verlauf +
          '\n\n\nFühre Befehl aus:\n' +
          cmd
        ).trim();
        this.cd.markForCheck();
        this.csdServerService
          .runCommand(this.entity, this.entity.commandPassword + cmd)
          .subscribe((ar) => {
            const r = MEActionResponse.fromRawActionResponse(ar);
            if (r.success) {
              this.verlauf = (
                this.verlauf +
                '\nErgebnis:\n' +
                r.params.result
              ).trim();
              this.saveConfig(cfg, idx + 1);
            } else {
              this.verlauf = (
                this.verlauf +
                '\nFehlgeschlagen. Antwort:\n' +
                r.params.message
              ).trim();
            }
            this.cd.markForCheck();
            this.btnDisbled = false;
          });
        this.cd.markForCheck();
      }
    } else {
      this.timer.start(true);
    }
  }

  public setValue(what: string, idx: number, wert: string) {
    if (what === 'g781') {
      this.g781[idx].value = wert;
      this.cd.markForCheck();
    }
  }
}
