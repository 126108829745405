<div class="modal-header">
    <h4 class="modal-title">Streamdetails {{ entity.connectionId }} @
        <app-mitschnittgeraet-display [value]="entity.device"></app-mitschnittgeraet-display>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
<div class="modal-body">

    <table class="table">
        <thead>
        <tr>
            <th>Gerätekennung</th>
            <th>Verbunden</th>
            <th>erstes Datenpaket</th>
            <th>letztes Datenpaket</th>
            <th>Pakete</th>
            <th>Gesamtlänge</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>{{ entity.machine }}</td>
            <td>{{ fDate(entity.connected, 'YYYY-MM-DD HH:mm:ss', true) }}</td>
            <td>{{ fDate(entity.minReceived, 'YYYY-MM-DD HH:mm:ss', true)  }}</td>
            <td>{{ fDate(entity.maxReceived, 'YYYY-MM-DD HH:mm:ss', true)  }}</td>
            <td>{{ entity.numPackets }}</td>
            <td>{{ entity.streamLength | mebytes:3:true }}</td>
        </tr>
        </tbody>
    </table>

    <div class="tablecontainer" #tableContainer>
        <me-data-table
                [rowActionsAsButtons]="true"
                [tableActionsAsButtons]="true"
                [data]="packets.asDataTableRows()"
                [selectable]="false"
        >
            <me-data-table-column [label]="'Paket'" [field]="'packetId'" type="long"></me-data-table-column>
            <me-data-table-column [label]="'empfangen/Länge'"
                                  field="received"
                                  [renderer]="customRenderer1"
            ></me-data-table-column>
            <me-data-table-column [label]="'Daten'" [field]="'hexdata'" [renderer]="dataRenderer"
                                  [cfg]="{flip_sides: flip_sides}"
            ></me-data-table-column>

        </me-data-table>
    </div>
    <div class="row">
        <div class="col-6"></div>
        <div class="col-6 text-right">
            <me-pagination
                    [total]="packets.total"
                    [page]="packets.page"
                    [perpage]="packets.size"
                    (pageChange)="packets.setPage($event)"
                    (pageCountChange)="packets.processPageCountChangedEvent($event)"
                    settingsid="mitschnitt-stream-details"
                    [settingsService]="benutzerService"
            ></me-pagination>
        </div>
    </div>


</div>
<div class="modal-footer d-flex justify-content-between">
    <div class="flex-fill d-flex justify-content-start align-items-center">
        <me-boolean-field [flip]="true" [labelWidth]="0" [(value)]="flip_sides" label=""></me-boolean-field>
        <div class="ms-1">Seiten tauschen</div>
    </div>
    <div>
        <button type="button" class="btn btn-secondary" (click)="cancel()">
            <i class="icofont icofont-close"></i> Schließen
        </button>
    </div>
</div>
