import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MESubscriptionHelper } from '../../maennl-commons/services';
import { NozzleReaderLogEntryList } from '../common/nozzle-reader-log-entry-list';
import { NozzleReaderRemoteLogService } from '../services/nozzle-reader-remote-log.service';
import { NozzleReaderLogEntry } from '../common/nozzle-reader-log-entry';
import {
  MEDataTableColumnComponent,
  MEDataTableComponent,
  MEDataTableRow,
  TMEDTGetExtraRowClassesCallback
} from '../../maennl-commons/data-table';
import { MERichError, noop } from '../../maennl-commons/tools';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MEDTDeviceRendererComponent } from '../../router/controls/medtdevice-renderer/medtdevice-renderer.component';
import {
  TMECellClassGetter,
  TMECellRendererValueFormatter
} from '../../maennl-commons/data-table/renderer/types';
import { MELocalizedComponent } from '../../maennl-commons/localized-component';
import { NozzleReaderLogFilterPopupComponent } from './nozzle-reader-log-filter-popup/nozzle-reader-log-filter-popup.component';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { SimpleNozzleReaderLogEntryFilter } from '../common/simple-nozzle-reader-log-entry-filter';
import * as FileSaver from 'file-saver';
import { RemoteLogParamsRendererComponent } from '../remote-log-params-renderer/remote-log-params-renderer.component';
import { RemoteLogTimeRendererComponent } from '../remote-log-time-renderer/remote-log-time-renderer.component';
import { RouterService } from '../../router/services/router.service';
import { MEResultMetaSort } from '../../maennl-commons/mesort-buttons';
import { MEButtonClickEvent } from '../../maennl-commons/data-table/events/me-button-click-event';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-tab-nozzle-reader-logs',
  templateUrl: './tab-nozzle-reader-logs.component.html',
  styleUrls: ['./tab-nozzle-reader-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabNozzleReaderLogsComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'TabNozzleReaderLogsComponent';
  __instance = '';

  paramsR = RemoteLogParamsRendererComponent;
  timeR = RemoteLogTimeRendererComponent;

  @Input() id = 'RemoteLogNozzleReaderLogsTab';
  tabActive = false;
  @ViewChild(MEDataTableComponent, { static: false }) dt = null;
  public deviceRenderer = MEDTDeviceRendererComponent;

  public logs: NozzleReaderLogEntryList = new NozzleReaderLogEntryList();

  grau = false;

  public uhfParamsFormatter: TMECellRendererValueFormatter<NozzleReaderLogEntry> =
    (
      value,
      column: MEDataTableColumnComponent,
      row: MEDataTableRow<NozzleReaderLogEntry>
    ) => {
      if (row.data === null || row.data === undefined) {
        return '';
      }
      if (row.data.params === null || row.data.params === undefined) {
        return '';
      }

      if (column.id === 'column-spannung') {
        const s = row.data.params.spannung;

        if (s === null || s === undefined) {
          return '';
        }

        return this.fNum(parseFloat('' + s) / 100.0, '1.2-2') + ' V';
      } else if (column.id === 'column-kunde') {
        const s = row.data.params.kunde;

        if (s === null || s === undefined) {
          return '';
        }
        return s.trim();
      } else if (column.id === 'column-fahrzeug') {
        const s = row.data.params.fahrzeug;

        if (s === null || s === undefined) {
          return '';
        }
        return s.trim();
      } else if (column.id === 'column-mac') {
        const s = row.data.params.mac;

        if (s === null || s === undefined) {
          return '';
        }
        return s.trim();
      }
      return value;
    };

  spannungClassGetter: TMECellClassGetter<NozzleReaderLogEntry> = (
    value,
    column: MEDataTableColumnComponent,
    row: MEDataTableRow<NozzleReaderLogEntry>
  ) => {
    if (row === null || column === null) {
      return '';
    }
    if (column.id === 'column-spannung') {
      if (row.data === null || row.data.params === null) {
        return '';
      }

      const s = row.data.params.spannung;

      if (s === null || s === undefined) {
        return '';
      }
      if (parseFloat(s) < 300) {
        return 'bg-danger text-warning font-weight-bold';
      }
      if (parseFloat(s) < 320) {
        return 'bg-warning';
      }
      return '';
    }
    return '';
  };

  constructor(
    public cd: ChangeDetectorRef,
    public toastr: ToastrService,
    public modalService: NgbModal,
    public logservice: NozzleReaderRemoteLogService,
    public routerService: RouterService,
    public benutzerService: BenutzerService
  ) {
    super();
  }

  rowClassGetter: TMEDTGetExtraRowClassesCallback<NozzleReaderLogEntry> = (
    row: MEDataTableRow<NozzleReaderLogEntry>,
    idx,
    tabledata
  ) => {
    if (
      row !== null &&
      row !== undefined &&
      row.data !== null &&
      row.data !== undefined &&
      row.data.zeitpunkt !== null &&
      row.data.zeitpunkt !== undefined
    ) {
      const classes: string[] = [];
      classes.push('table-row-' + idx);

      if (idx === 0) {
        this.grau = true;
      } else if (tabledata === null || !Array.isArray(tabledata)) {
        this.grau = !this.grau;
      } else {
        const vgl = (tabledata[idx - 1] as MEDataTableRow<NozzleReaderLogEntry>)
          .data;
        if (vgl === null) {
          this.grau = !this.grau;
        } else {
          if (
            vgl.vorgang !== row.data.vorgang ||
            vgl.device.id !== row.data.device.id
          ) {
            this.grau = !this.grau;
          } else if (row.data.type === 4) {
            this.grau = !this.grau;
          }
        }
      }
      if (row.data.type === 254) {
        classes.push('bg-redlight');
        classes.push('text-black');
      } else {
        classes.push('vg-' + (this.grau ? 0 : 1));
      }
      return classes.join(' ');
    }
  };

  ngAfterViewInit() {
    if (this.dt !== null && this.dt !== undefined) {
      this.dt.extraRowClasses = this.rowClassGetter;
    }
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.logs.onUpdateRequired.subscribe((list: NozzleReaderLogEntryList) => {
        this.logservice
          .loadLogs(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.searchString
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
            },
            (e) => {
              console.log(e);
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste des verfügbaren Logeinträge konnte nicht abgerufen werden'
              );
            }
          );
      }, undefined)
    );

    if (this.dt !== null && this.dt !== undefined) {
      this.dt.extraRowClasses = this.rowClassGetter;
    }
  }

  ngOnDestroy(): void {
    this.onDeactivate();
    MESubscriptionHelper.release(this);
    this.logs.release();
  }

  onActivate() {
    this.tabActive = true;
    this.logs.start();
  }

  onDeactivate() {
    this.tabActive = false;
  }

  updateFilter() {
    NozzleReaderLogFilterPopupComponent.open(
      this.modalService,
      NozzleReaderLogFilterPopupComponent,
      this.logs.simpleFilter,
      MEGenericModalComponent.SIZE_SMALL
    ).then(
      (f: SimpleNozzleReaderLogEntryFilter) => {
        this.logs.simpleFilter.copyFrom(f);
        this.logs.reload();
        this.cd.markForCheck();
      },
      () => {}
    );
  }

  getFilterButtonClasses() {
    if (
      this.logs.simpleFilter !== null &&
      this.logs.simpleFilter !== undefined
    ) {
      if (this.logs.simpleFilter.hasValues()) {
        return 'btn btn-primary';
      }
    }
    return 'btn btn-secondary';
  }

  private realDlEntries(type: string, exporttype: number) {
    this.toastr
      .info(
        this._('Download wird angefordert. Bitte einen Moment Geduld...'),
        this._('Aktion wird ausgeführt...'),
        {
          timeOut: 15000
        }
      )
      .onShown.subscribe(() => {
        this.logservice
          .exportData(
            type,
            this.logs.simpleFilter,
            this.logs.getQuery(),
            exporttype,
            this.logs.order
          )
          .subscribe(
            (response: Blob) => {
              FileSaver.saveAs(response, this._('nozzle-reader-log.' + type));
            },
            (e) => {
              this.toastr.error(
                this._(
                  'Die Liste der Logeinträge konnte nicht exportiert werden.'
                )
              );
            }
          );
      });
  }

  downloadEntries(format: string, exporttype = 0) {
    this.realDlEntries(format, exporttype);
  }

  tickleMellendorf() {
    this.routerService.tickleDevice(73).subscribe(() => {
      this.toastr.success('Nozzle-Reader Mellendorf wird jetzt gekitzelt...');
      this.logs.start();
    }, noop);
  }

  selectSort(number: number) {
    switch (number) {
      case 1:
        this.logs.order = [
          new MEResultMetaSort('kfzkennzeichen', 'ASC'),
          new MEResultMetaSort('nr.zeitpunkt', 'ASC'),
          new MEResultMetaSort('nr.received', 'ASC')
        ];
        break;
      case 2:
        this.logs.order = [
          new MEResultMetaSort('nr.vorgang', 'ASC'),
          new MEResultMetaSort('nr.zeitpunkt', 'ASC'),
          new MEResultMetaSort('nr.received', 'ASC')
        ];
        break;
      case 3:
        this.logs.order = [
          new MEResultMetaSort('nr.vorgang', 'DESC'),
          new MEResultMetaSort('nr.zeitpunkt', 'ASC'),
          new MEResultMetaSort('nr.received', 'ASC')
        ];
        break;
      case 4:
        this.logs.order = [new MEResultMetaSort('nr.zeitpunkt', 'ASC')];
        break;
      case 5:
        this.logs.order = [new MEResultMetaSort('nr.zeitpunkt', 'DESC')];
        break;
      default:
        this.logs.order = [
          new MEResultMetaSort('nr.vorgang', 'DESC'),
          new MEResultMetaSort('nr.zeitpunkt', 'ASC'),
          new MEResultMetaSort('nr.received', 'ASC')
        ];
    }
    this.logs.reload();
  }

  onButtonClick(ev: MEButtonClickEvent) {
    if (ev.group === 'remote-log-params-renderer') {
      if (ev.button === 'filter') {
        if (ev.params.field === 'vin') {
          (this.logs.simpleFilter as SimpleNozzleReaderLogEntryFilter).vin =
            ev.params.value;
        }
        if (ev.params.field === 'kennzeichen') {
          (
            this.logs.simpleFilter as SimpleNozzleReaderLogEntryFilter
          ).kfzkennzeichen = ev.params.value;
        }
        if (ev.params.field === 'mac') {
          (this.logs.simpleFilter as SimpleNozzleReaderLogEntryFilter).mac =
            ev.params.value;
        }
        if (ev.params.field === 'fahrzeug') {
          (
            this.logs.simpleFilter as SimpleNozzleReaderLogEntryFilter
          ).fahrzeug = ev.params.value;
        }

        this.logs.setPage(1);
        this.logs.reload();
      }
    } else if (ev.group === 'message-column') {
      if (ev.button === 'filter') {
        (this.logs.simpleFilter as SimpleNozzleReaderLogEntryFilter).message =
          ev.params.value;
        this.logs.setPage(1);
        this.logs.reload();
      }
    } else if (ev.group === 'device-column') {
      if (ev.button === 'filter') {
        (this.logs.simpleFilter as SimpleNozzleReaderLogEntryFilter).device =
          ev.params.value;
        this.logs.setPage(1);
        this.logs.reload();
      }
    } else {
      console.log(ev);
    }
  }

  public selectFilter(what: number) {
    switch (what) {
      case 1:
        {
          (this.logs.simpleFilter as SimpleNozzleReaderLogEntryFilter).message =
            'Fehlermeldung';
          this.selectSort(5);
        }
        break;
      default:
        this.logs.setPage(1);
    }
    this.logs.setPage(1);
    this.logs.reload();
  }
}
