import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { PeilungList } from '../common/peilung-list';
import { SondeService } from '../services/sonde.service';
import { MERichError } from '../../maennl-commons/tools';
import { MEDTSondeCellRendererComponent } from '../controls/medtsonde-cell-renderer/medtsonde-cell-renderer.component';
import { ToastrService } from 'ngx-toastr';
import { MESubscriptionHelper } from '../../maennl-commons/services';
import { TMECellRendererValueFormatter } from '../../maennl-commons/data-table/renderer/types';
import { Peilung } from '../common/peilung';
import { MELocalizedComponent } from '../../maennl-commons/localized-component';
import { MEDataTableRow } from '../../maennl-commons/data-table/rows';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-tab-peilungen',
  templateUrl: './tab-peilungen.component.html',
  styleUrls: ['./tab-peilungen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabPeilungenComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy
{
  __classname = 'TabPeilungenComponent';
  __instance = '';

  public sondeRenderer = MEDTSondeCellRendererComponent;

  public peilungen: PeilungList = new PeilungList();

  private tabActive = false;

  public standFormatter: TMECellRendererValueFormatter<Peilung> = (
    value,
    column,
    row: MEDataTableRow<Peilung>
  ) => {
    if (value === null || value === undefined) {
      return '';
    }
    if (column.field === 'cstand1' || column.field === 'cstand2') {
      return (
        this.fNum(value, '1.1-1') +
        ' ' +
        (row as MEDataTableRow<Peilung>).data.sonde.einheit
      ).trim();
    }
    return value;
  };

  constructor(
    public sondeService: SondeService,
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    public benutzerService: BenutzerService
  ) {
    super();
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.peilungen.onUpdateRequired.subscribe((list: PeilungList) => {
        this.sondeService
          .listPeilungen(
            null,
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
            },
            (e) => {
              console.log(e);
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste der verfügbaren Peilungen konnte nicht geladen werden'
              );
            }
          );
      }, undefined)
    );
    if (this.tabActive) {
      this.peilungen.start();
    }
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.peilungen.release();
  }

  tabActivated() {
    this.tabActive = true;
    this.peilungen.start();
  }

  tabDeactivated() {
    this.tabActive = false;
  }
}
