import { Injectable } from '@angular/core';
import { MEKitchenTimer } from '../maennl-commons/timers';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BenutzerService } from '../benutzer/services/benutzer.service';
import { IMEActionResponse, MEActionResponse } from '../maennl-commons/network';
import { Observable, TimeoutError } from 'rxjs';
import { take, tap, timeout } from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import {
  MEPubSubService,
  MESubscriptionHelper
} from '../maennl-commons/services';
import { noop } from '../maennl-commons/tools';

@Injectable()
export class SysInfoService {
  __classname = 'SysInfoService';
  __instance = '';

  private onlineTimer: MEKitchenTimer = null;
  private sysInfoTimer: MEKitchenTimer = null;
  private _isOnline = true;
  private _developmentMode = false;
  private _onlineFailed = 0;
  private _loginMessage: string = null;
  private _adminSystemMessage: string = null;
  private _sysInfo: any = {};
  private _sysInfoHash = '';
  private _httpHost = '';
  private onlineCheckCount = 0;

  get httpHost(): string {
    return this._httpHost;
  }

  set httpHost(value: string) {
    this._httpHost = value;
  }

  get developmentMode(): boolean {
    return this._developmentMode;
  }

  set developmentMode(value: boolean) {
    if (value !== this._developmentMode) {
      this._developmentMode = value;
      this.pubsub.developmentModeChange.emit(value);
    }
  }

  get sysInfo(): any {
    return this._sysInfo;
  }

  set sysInfo(value: any) {
    if (value === null || value === undefined) {
      value = {};
    }
    let h = 'sysInfo';
    Object.keys(value).forEach((k: string) => {
      h = h + '\n' + k + ':' + JSON.stringify(value[k]);
    });
    const h1 = Md5.hashStr(h) as string;
    if (h1 !== this._sysInfoHash) {
      this._sysInfo = value;
      this._sysInfoHash = h1;
      this.pubsub.sysInfoChange.emit(this.sysInfo);
    }
  }

  get loginMessage(): string {
    return this._loginMessage === null ? '' : this._loginMessage;
  }

  set loginMessage(value: string) {
    if (value !== this._loginMessage) {
      this._loginMessage = value;
      this.pubsub.loginMessageChange.emit(this._loginMessage);
    }
  }

  get adminSystemMessage(): string {
    return this._adminSystemMessage === null ? '' : this._adminSystemMessage;
  }

  set adminSystemMessage(value: string) {
    if (value !== this._adminSystemMessage) {
      this._adminSystemMessage = value;
      this.pubsub.adminSystemMessageChange.emit(this._adminSystemMessage);
    }
  }

  constructor(
    public httpClient: HttpClient,
    public loginService: BenutzerService,
    public pubsub: MEPubSubService
  ) {
    this.onlineTimer = new MEKitchenTimer(10000);
    MESubscriptionHelper.add(
      this,
      this.onlineTimer.subscribe(() => {
        this.doOnlineCheck();
      }, undefined)
    );
    this.sysInfoTimer = new MEKitchenTimer(60000);
    MESubscriptionHelper.add(
      this,
      this.sysInfoTimer.subscribe(() => {
        this.fetchSysInfo();
      }, undefined)
    );
    this.doOnlineCheck();
    this.fetchSysInfo();
  }

  isDevelopmentMode() {
    return this.developmentMode;
  }

  doOnlineCheck() {
    this.checkOnline().subscribe(
      (r: IMEActionResponse) => {
        this._onlineFailed = 0;
        if (r.success) {
          if (!this._isOnline) {
            this._isOnline = true;
            this.pubsub.onlineStateChange.emit(true);
            this.onlineTimer.setTimeout(30000);
          }
        } else {
          if (this._isOnline) {
            this._isOnline = false;
            this.pubsub.onlineStateChange.emit(false);
            this.onlineTimer.setTimeout(10000);
          }
        }
        this.onlineTimer.start();
      },
      (e) => {
        if (
          e !== null &&
          e !== undefined &&
          (e instanceof TimeoutError ||
            e.name === 'TimeoutError' ||
            e instanceof HttpErrorResponse ||
            e.status === 0)
        ) {
          try {
            this.pubsub.afterRequest.emit('afterRequestEvent');
          } catch (ignored) {}
        } else {
          console.log(e);
        }
        if (this._isOnline) {
          this._onlineFailed = this._onlineFailed + 1;
          if (this._onlineFailed > 2) {
            this._isOnline = false;
            this.pubsub.onlineStateChange.emit(false);
          }
          this.onlineTimer.setTimeout(5000);
        }
        this.onlineTimer.start();
      }
    );
  }

  loadSysInfo(): Observable<IMEActionResponse> {
    this.onlineCheckCount++;
    return this.httpClient
      .get<IMEActionResponse>(
        '/resources/sysinfo',
        this.loginService.getRequestOptions(
          true,
          this.onlineCheckCount % 25 === 0
        )
      )
      .pipe(
        tap((s: IMEActionResponse) => {
          const r = MEActionResponse.fromRawActionResponse(s);
          if (r.success) {
            this.developmentMode = r.params['developmentMode'];
            this.adminSystemMessage = r.params['adminSystemMessage'];
            this.loginMessage = r.params['loginSystemMessage'];
            this._httpHost = r.params['_httpHost'];
            this.sysInfo = r.params;
          }
          this.sysInfoTimer.start();
        }),
        take(1)
      );
  }

  checkOnline(): Observable<IMEActionResponse> {
    this.onlineCheckCount++;
    return this.httpClient
      .get<IMEActionResponse>(
        '/resources/online',
        this.loginService.getRequestOptions(
          true,
          this.onlineCheckCount % 5 === 0
        )
      )
      .pipe(
        timeout(2000),
        tap((r: IMEActionResponse) => {
          this.online = r.success;
        }),
        take(1)
      );
  }

  isOnline() {
    return this._isOnline;
  }

  set online(value: boolean) {
    if (value !== this._isOnline) {
      this.pubsub.onlineStateChange.emit(value);
      if (value) {
        this.fetchSysInfo();
      }
    }
    this._isOnline = value;
  }

  public fetchSysInfo(force = false) {
    this.loadSysInfo().subscribe(() => {
      if (force) {
        this.pubsub.sysInfoChange.emit(this.sysInfo);
      }
      this.sysInfoTimer.start();
    }, noop);
  }
}
