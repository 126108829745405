import { IMESimpleFilter } from '../filters';
import { MEResultMetaSort } from '../mesort-buttons';
import { MEConverterTool, METool } from '../tools';

export class MEApiTool {
  static buildListURL(
    endpoint: string,
    max: number = 10,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<any>,
    queryString = '',
    params: any = null
  ): string {
    let url = endpoint;
    if (
      max !== null &&
      max !== undefined &&
      '' + max !== '' &&
      !isNaN(parseInt('' + max, 10))
    ) {
      url = url.concat(url.indexOf('?') < 0 ? '?' : '&');
      url = url.concat('max=' + encodeURIComponent('' + max));
    }
    if (
      offset !== null &&
      offset !== undefined &&
      '' + offset !== '' &&
      !isNaN(parseInt('' + offset, 10))
    ) {
      url = url.concat(url.indexOf('?') < 0 ? '?' : '&');
      url = url.concat('offset=' + encodeURIComponent('' + offset));
    }

    if (filter !== null && filter !== undefined) {
      if (filter.hasValues()) {
        url = url.concat(url.indexOf('?') < 0 ? '?' : '&');
        url = url + filter.asURL();
      }
    }

    if (
      sort !== null &&
      sort !== undefined &&
      Array.isArray(sort) &&
      sort.length > 0
    ) {
      let p = 0;
      sort.forEach((it) => {
        url = url.concat(url.indexOf('?') < 0 ? '?' : '&');
        url = url.concat(
          'sort.' + p + '=' + encodeURIComponent(it.field + '|' + it.direction)
        );
        p++;
      });
    }

    if (
      queryString !== '' &&
      queryString !== null &&
      queryString !== undefined
    ) {
      url = url.concat(url.indexOf('?') < 0 ? '?' : '&');
      url = url.concat('q=' + encodeURIComponent('' + queryString));
    }

    if (params !== null && params !== undefined) {
      Object.keys(params).forEach((v) => {
        url = url.concat(url.indexOf('?') < 0 ? '?' : '&');
        url = url.concat(
          encodeURIComponent('' + v) + '=' + encodeURIComponent('' + params[v])
        );
      });
    }

    return url;
  }

  static buildActionURL(
    endpoint: string,
    id: any,
    action: string,
    params: any = null
  ): string {
    let url = endpoint;
    if (!url.endsWith('/')) {
      url = url.concat('/');
    }
    if (id !== null && id !== undefined) {
      url = url.concat(encodeURIComponent('' + id));
    }
    if (!url.endsWith('/')) {
      url = url.concat('/');
    }
    url = url.concat(encodeURIComponent(action));
    if (params !== null && params !== undefined) {
      Object.keys(params).forEach((v) => {
        url = url.concat(url.indexOf('?') < 0 ? '?' : '&');
        url = url.concat(
          encodeURIComponent('' + v) + '=' + encodeURIComponent('' + params[v])
        );
      });
    }
    return url;
  }

  static buildEntityURL(endpoint: string, id: any, params: any = null): string {
    let url = endpoint;
    if (id !== null && id !== undefined && (METool.isNaN(id) || id > 0)) {
      if (!url.endsWith('/')) {
        url = url.concat('/');
      }
      url = url.concat(encodeURIComponent('' + id));
    }
    if (params !== null && params !== undefined) {
      Object.keys(params).forEach((v) => {
        url = url.concat(url.indexOf('?') < 0 ? '?' : '&');
        url = url.concat(
          encodeURIComponent('' + v) + '=' + encodeURIComponent('' + params[v])
        );
      });
    }
    return url;
  }

  static buildEndpointURL(endpoint: string, params: any = null): string {
    let url = endpoint;

    if (params !== null && params !== undefined) {
      Object.keys(params).forEach((v) => {
        url = url.concat(url.indexOf('?') < 0 ? '?' : '&');
        url = url.concat(
          encodeURIComponent('' + v) + '=' + encodeURIComponent('' + params[v])
        );
      });
    }
    return url;
  }
}
