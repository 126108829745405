import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MEResultMetaSort } from '../../maennl-commons';
import { IAbrechnungGreenline } from '../common/iabrechnung-greenline';
import { IMEActionResponse, MEApiTool } from '../../maennl-commons';
import { IMEListResult } from '../../maennl-commons';
import { HttpClient } from '@angular/common/http';
import { BenutzerService } from '../../benutzer/services/benutzer.service';
import { IMESimpleFilter } from '../../maennl-commons/filters';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AbrechnungGreenlineService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public listAbrechnungen(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IAbrechnungGreenline> = null
  ): Observable<IMEListResult<IAbrechnungGreenline>> {
    const uri = MEApiTool.buildListURL(
      '/api/abrechnungen/greenline',
      max,
      offset,
      sort,
      filter
    );
    return this.http
      .get<IMEListResult<IAbrechnungGreenline>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }

  public recalcAbrechnung(
    abrechnung: IAbrechnungGreenline
  ): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL(
      '/api/abrechnungen/greenline',
      abrechnung.id,
      '_recalc'
    );
    return this.http
      .post<IMEActionResponse>(uri, null, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public getAbrechnung(id: number): Observable<IAbrechnungGreenline> {
    const uri = MEApiTool.buildEntityURL('/api/abrechnungen/greenline', id);
    return this.http
      .get<IAbrechnungGreenline>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public markAbrechnungCleared(
    abrechnung: IAbrechnungGreenline
  ): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL(
      '/api/abrechnungen/greenline',
      abrechnung.id,
      '_mark_as_cleared'
    );
    return this.http
      .post<IMEActionResponse>(uri, null, this.loginService.getRequestOptions())
      .pipe(take(1));
  }
}
