export class MEButtonClickEvent {
  private _group = 'any';
  private _button = 'any';
  private _params: any = null;

  constructor(group: string, button: string, params: any) {
    this._group = group;
    this._button = button;
    this._params = params;
  }

  get group(): string {
    return this._group;
  }

  set group(value: string) {
    this._group = value;
  }

  get button(): string {
    return this._button;
  }

  set button(value: string) {
    this._button = value;
  }

  get params(): any {
    return this._params;
  }

  set params(value: any) {
    this._params = value;
  }
}
