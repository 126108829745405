<me-card>
    <app-sys-health-checks-list [showAddAction]="false"
                                [machine]="null"
                                [servicetype]="null"
                                [problemsOnly]="true"
                                [displayMachineColumn]="true"
                                [displayServiceTypeColumn]="true"
                                [autoReloadInterval]="60000"
                                (onServiceEditAdd)="addEditDienst($event)"
    ></app-sys-health-checks-list>
</me-card>