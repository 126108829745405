<div class="text-center"
     style="position:fixed;top:45px;left: 0;right:0;bottom:0;height: 100%;display: flex;align-items: center;justify-content: center;">
    <div class="card logincard">
        <h4 class="card-header">Login erforderlich</h4>
        <div class="card-body">

            <div class="card-text">


                <div class="alert alert-warning text-start" *ngIf="hasLoginMessage()">
                    <span [innerHTML]='_(loginMessage)'></span>
                </div>

                <div class="alert alert-info text-start">
                    Um fortzufahren, müssen Sie sich zunächst am System anmelden.
                </div>
                <div class="alert alert-danger text-start" *ngIf="showError">
                    Mit dieser Benutzername/Kennwort-Kombination ist kein Login möglich. <br/>
                    <strong *ngIf="benutzer.username==='admin'">
                        Der Benutzer 'admin' kann nicht mehr verwendet werden. Stattdessen
                        bitte mit dem Email-Kürzel einloggen
                    </strong>
                </div>
                <form #loginForm="ngForm">
                    <div>
                        <div class="form-group mb-1">
                            <div class="input-group" title='Benutzername'>

                                    <span class="input-group-text">
                                    <i class="icofont icofont-ui-user"></i>
                                    </span>
                                <!--suppress JSCheckFunctionSignatures -->
                                <input
                                        aria-describedby="user-addon1"
                                        type="text"
                                        class="form-control"
                                        id="userNameInput"
                                        placeholder='Benutzername ...'
                                        [(ngModel)]="benutzer.username"
                                        name="username"
                                        (keypress)="unamekp($event)"
                                        (change)="showError=false"
                                >
                            </div>
                        </div>
                        <div class="form-group mb-1">

                            <div class="input-group" title='Kennwort'>

                                    <span class="input-group-text">
                                    <i class="icofont icofont-ui-password"></i>
                                    </span>
                                <!--suppress JSCheckFunctionSignatures -->
                                <input type="password" class="form-control" aria-describedby="pw-addon1"
                                       id="passwordInput"
                                       placeholder='Kennwort...'
                                       [(ngModel)]="benutzer.password"
                                       name="password"
                                       (keypress)="loginkp($event)"
                                       (change)="showError=false"
                                       #pwinput
                                >
                            </div>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="rememberme" [(ngModel)]="rememberme"
                                   id="rememberme">
                            <label class="form-check-label" for="rememberme">
                                {{ _("beim nächsten Aufruf automatisch einloggen")}}
                            </label>
                        </div>
                        <div class="text-end">
                            <button type="button" class="btn btn-primary" [disabled]="submitted" (click)="doLogin()">
                                <i class="icofont icofont-login"></i> Anmelden
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>

