import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Md5 } from 'ts-md5';
import { MEAddOnClickEvent } from '../events';

@Component({
  selector: 'me-generic-field',
  styles: [
    `
      :host .addon button:first-child {
        border-left: none;
        padding: 0 5px;
      }
    `
  ],
  template: `
    <div class="form-group row mb-1">
      <label
        for="{{ id }}"
        class="col-{{ labelwidth }} col-form-label"
        *ngIf="labelwidth > 0"
      >
        {{ label }}
      </label>
      <div class="col-{{ 12 - labelwidth }}">
        <div
          class=""
          [class.input-group]="hasAddon()"
          [class.input-group-sm]="hasAddon() && small"
        >
          <ng-content></ng-content>
          <span
            class="input-group-btn addon"
            *ngFor="let ai of addonIcons; let idx = index"
          >
            <button
              class="btn btn-secondary addon-btn-{{ idx }}"
              [class.btn-sm]="small"
              type="button"
              title="{{ _addOnLabels[idx] }}"
              (click)="aClick(idx)"
            >
              <i class="{{ ai }}"></i>
            </button>
          </span>
        </div>
      </div>
    </div>
  `
})
export class MEGenericFieldComponent implements OnInit {
  public _value = 0;
  public _icons: string[] = [];

  @Input() label = 'Generic-Feld';
  @Input() small = false;
  @Input() tabindex: number = null;

  _addOnLabels: string[] = [];

  @Input() enableaddon = false;
  @Input() private _id = '';
  @Input() labelwidth = 3;
  @Input() disabled = false;

  @Output() addonClick = new EventEmitter<MEAddOnClickEvent>();

  public get id(): string {
    if (this._id === '') {
      this._id =
        'tf' +
        Md5.hashStr(
          'me-genericfield-' + this.label + Math.random() + '-' + Math.random()
        );
    }
    return this._id;
  }

  @Input()
  public set id(value: string) {
    this._id = value;
  }

  @Input('addonicon')
  set icon(v: any) {
    this._icons = [];
    if (Array.isArray(v)) {
      v.forEach((i) => {
        this._icons.push(i);
      });
    } else {
      this._icons.push(v);
    }
    while (this._addOnLabels.length < this._icons.length) {
      this._addOnLabels.push('');
    }
  }

  get icon(): any {
    if (this._icons.length < 1) {
      return '';
    } else if (this._icons.length < 2) {
      return this._icons[0];
    }
    return this._icons;
  }

  @Input()
  set addonlabel(v: any) {
    this._addOnLabels = [];
    if (Array.isArray(v)) {
      v.forEach((i) => {
        this._addOnLabels.push(i);
      });
    } else {
      this._addOnLabels.push(v);
    }
    while (this._addOnLabels.length < this._icons.length) {
      this._addOnLabels.push('');
    }
  }

  get addonIcons(): any[] {
    if (this.hasAddon()) {
      return this._icons;
    }
    return [];
  }

  constructor() {}

  ngOnInit() {}

  hasAddon(): boolean {
    return !this.disabled && this.enableaddon && this._icons.length > 0;
  }

  aClick(i?: any) {
    const e: MEAddOnClickEvent = new MEAddOnClickEvent();
    e.component = this;
    e.addon = i;
    this.addonClick.emit(e);
  }
}
