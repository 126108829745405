<ul>
    <li *ngFor="let pg of pages" [class.currentPage]="pg==currentpage" [class.page]="(pg>0) && (pg!=currentpage)">
        <a class="pg" (click)="gopage(currentpage)" *ngIf="pg == -1">
            ...
        </a>
        <a class="pg" (click)="gopage(1)" *ngIf="pg == -2" title="{{_('an den Anfang der Liste springen')}}">
            <i class="icofont icofont-double-left"></i>
        </a>
        <a class="pg" (click)="gopage(currentpage-limitPages)" *ngIf="pg == -3"
           title="{{ limitPages }} {{_('Seiten zurück')}}">
            <i class="icofont icofont-simple-left"></i>
        </a>
        <a class="pg" (click)="gopage(currentpage+limitPages)" *ngIf="pg == -4"
           title="{{ limitPages }} {{_('Seiten vor')}}">
            <i class="icofont icofont-simple-right"></i>
        </a>
        <a class="pg" (click)="gopage(totalPages)" *ngIf="pg == -5" title="{{_('an das Ende der Liste springen')}}">
            <i class="icofont icofont-double-right"></i>
        </a>
        <a class="pg" (click)="gopage(pg)" *ngIf="pg>0" title="{{ _(gotoPageNofM, {n:pg,m:totalPages}) }}">
            {{ pg }}
        </a>
    </li>
    <li>
        <div class="dropdown" title="{{_('Anzahl der angezeigten Elemente pro Seite ändern')}}">
            <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                {{ itemsPerPage }}
            </button>
            <div class="dropdown-menu dropdown-menu-end">
                <a *ngFor="let o of pagecounts" class="dropdown-item" title="{{ o }} {{_('Einträge pro Seite')}}"
                   (click)="pagesChanged(o)">{{ o }}</a>
            </div>
        </div>
    </li>
    <li *ngIf="showCount" style="display:flex; align-items: center">
        <ng-container *ngIf="currentpage*itemsPerPage<totalItems">
            {{ currentpage * itemsPerPage - itemsPerPage + 1 }} - {{ currentpage * itemsPerPage }} von {{ totalItems }}
        </ng-container>
        <ng-container *ngIf="currentpage*itemsPerPage>totalItems">
            {{ currentpage * itemsPerPage - itemsPerPage + 1 }} - {{ totalItems }} von {{ totalItems }}
        </ng-container>
    </li>
    <li *ngIf="showSearch && queryFieldVisible">
        <!--suppress JSCheckFunctionSignatures -->
        <input class="form-control form-control-sm searchQuery"
               (keyup)="editSearch($event)"
               (change)="qChange($event)"
               (emptied)="qSet('')"
               [value]="query"
               placeholder="{{_('Suche nach...')}}"
               title="{{_('Suchanfrage hier eingeben')}}"
        >
    </li>
    <li *ngIf="showSearch">
        <a class="pg" (click)="toggleQuery()" title="{{ _('Suchfeld einblenden') }}">
            <i class="icofont icofont-ui-search"></i>
        </a>
    </li>
    <li>
        <a class="pg" (click)="gopage(currentpage)" title="{{_('aktuelle Ansicht neu laden')}}">
            <i class="icofont icofont-refresh"></i>
        </a>
    </li>
</ul>
