import {Injectable} from '@angular/core';
import {ITenant} from './itenant';
import {
    IMEActionResponse,
    IMEListResult, MEActionResponse,
    MEApiTool,
    MEResultMetaSort, METool
} from '../../../maennl-commons';
import {Observable, map, take, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BenutzerService} from '../../../benutzer/services/benutzer.service';
import {IMESimpleFilter} from '../../../maennl-commons/filters';
import {Tenant} from './tenant';
import {TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";

@Injectable({
    providedIn: 'root'
})
export class TenantService {
    constructor(public http: HttpClient, public loginService: BenutzerService) {
    }

    public list(
        max: number = 50,
        offset: number = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<ITenant> = null,
        queryString = ''
    ): Observable<IMEListResult<ITenant>> {
        const uri = MEApiTool.buildListURL(
            '/api/webfuel/core/tenants',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<ITenant>>(uri, this.loginService.getRequestOptions())
            .pipe(take(1));
    }

    public create_ta_access(tenant_id: number): Observable<IMEActionResponse> {
        const uri = MEApiTool.buildActionURL(
            '/api/webfuel/core/tenants',
            tenant_id,
            '_create_ta_access_user'
        );
        return this.http
            .post<IMEActionResponse>(uri, {}, this.loginService.getRequestOptions())
            .pipe(take(1));
    }

    public get(tenant_id: number): Observable<Tenant> {
        const uri = MEApiTool.buildEntityURL(
            '/api/webfuel/core/tenants',
            tenant_id
        );
        return this.http
            .get<ITenant>(uri, this.loginService.getRequestOptions())
            .pipe(
                map((t) => {
                    return Tenant.fromResult(t);
                }),
                take(1)
            );
    }

    public store(entity: ITenant): Observable<MEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            '/api/webfuel/core/tenants',
            entity.id
        );

        if (METool.isNullOrUndefined(entity.id) || entity.id < 1) {
            return this.http
                .post<IMEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r => {
                        return MEActionResponse.fromRawActionResponse(r);
                    }),
                    take(1)
                );
        } else {
            return this.http
                .put<IMEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r => {
                        return MEActionResponse.fromRawActionResponse(r);
                    }),
                    take(1)
                );
        }
    }

    add_module(
        id: TNullableNumber,
        module_code: TNullableString
    ): Observable<MEActionResponse> {
        if (
            METool.isNullOrUndefined(id) ||
            id < 1 ||
            METool.isNullOrUndefined(module_code) ||
            module_code.trim() === ''
        ) {
            return of(MEActionResponse.error('id is null or undefined'));
        }

        const uri = MEApiTool.buildActionURL(
            '/api/webfuel/core/tenants',
            id,
            '_addModule'
        );

        return this.http
            .post<IMEActionResponse>(
                uri,
                {module_code: module_code},
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }

    add_setting(
        tenant_id: TNullableNumber,
        name: string,
        value: string
    ): Observable<MEActionResponse> {
        if (
            METool.isNullOrUndefined(tenant_id) ||
            tenant_id < 1 ||
            METool.isNullOrUndefined(name) ||
            name.trim() === '' ||
            METool.isNullOrUndefined(value) ||
            value.trim() === ''
        ) {
            return of(MEActionResponse.error('setting or id is invalid'))
        }

        const uri = MEApiTool.buildActionURL(
            '/api/webfuel/core/tenants',
            tenant_id,
            '_addSetting'
        );

        return this.http
            .post<IMEActionResponse>(
                uri,
                {name: name, value: value},
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }

    remove_module(
        id: TNullableNumber,
        module_code: TNullableString
    ): Observable<MEActionResponse> {
        if (
            METool.isNullOrUndefined(id) ||
            id < 1 ||
            METool.isNullOrUndefined(module_code) ||
            module_code.trim() === ''
        ) {
            return of(MEActionResponse.error('id is null or undefined'));
        }

        const uri = MEApiTool.buildActionURL(
            '/api/webfuel/core/tenants',
            id,
            '_removeModule'
        );

        return this.http
            .post<IMEActionResponse>(
                uri,
                {module_code: module_code},
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }

    remove_setting(
        tenant_id: TNullableNumber,
        setting: string,
    ) {
        if (
            METool.isNullOrUndefined(tenant_id) ||
            tenant_id < 1 ||
            METool.isNullOrUndefined(setting) ||
            setting.trim() === ''
        ) {
            return of(MEActionResponse.error('setting or id is invalid'))
        }

        const uri = MEApiTool.buildActionURL(
            '/api/webfuel/core/tenants',
            tenant_id,
            '_removeSetting'
        );

        return this.http
            .post<IMEActionResponse>(
                uri,
                {name: setting, value: ''},
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }

    edit_setting(
        tenant_id: TNullableNumber,
        setting: string,
        value: string
    ) {
        if (
            METool.isNullOrUndefined(tenant_id) ||
            tenant_id < 1 ||
            METool.isNullOrUndefined(setting) ||
            setting.trim() === '' ||
            METool.isNullOrUndefined(value) ||
            value.trim() === ''
        ) {
            return of(MEActionResponse.error('setting or id is invalid'))
        }

        const uri = MEApiTool.buildActionURL(
            '/api/webfuel/core/tenants',
            tenant_id,
            '_editSetting'
        );

        return this.http
            .post<IMEActionResponse>(
                uri,
                {name: setting, value: value},
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }
}
