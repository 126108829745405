import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import { MERowActionParams } from '../../../maennl-commons/data-table/actions';
import { MELocalizedComponent } from '../../../maennl-commons/localized-component';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { MERichError, noop } from '../../../maennl-commons/tools';
import { RouterService } from '../../../router/services/router.service';
import { SimEditorComponent } from '../../../router/sim-editor/sim-editor.component';
import { ATCommandToolComponent } from '../atcommand-tool/atcommand-tool.component';
import { ClientEditorComponent } from '../client-editor/client-editor.component';
import { CSDServerClient } from '../commons/csdserver-client';
import { CSDServerClientList } from '../commons/csdserver-client-list';
import { SimpleCSDServerClientFilter } from '../commons/simple-csdserver-client-filter';
import { CSDServerService } from '../services/csdserver.service';

@Component({
  selector: 'app-tab-csdclients',
  templateUrl: './tab-csdclients.component.html',
  styleUrls: ['./tab-csdclients.component.scss']
})
export class TabCSDClientsComponent
  extends MELocalizedComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  __classname = 'TabCSDClientsComponent';
  __instance = '';
  public clients: CSDServerClientList = new CSDServerClientList(false, 400);

  private _type = 'dtu';

  private _tabActive = false;

  private _sim_visible = false;

  private _machine_id_visible = true;

  public get machine_id_visible(): boolean {
    return this._machine_id_visible;
  }

  @Input()
  public set machine_id_visible(value: boolean) {
    if (this._machine_id_visible !== value) {
      this._machine_id_visible = value;
      this.cd.markForCheck();
    }
  }

  public get sim_visible(): boolean {
    return this._sim_visible;
  }

  @Input()
  public set sim_visible(value: boolean) {
    if (this._sim_visible !== value) {
      this._sim_visible = value;
      this.cd.markForCheck();
    }
  }

  public get type(): string {
    return this._type;
  }

  @Input()
  public set type(value: string) {
    if (this._type !== value) {
      this._type = value;
      this.cd.markForCheck();
      if (this._tabActive) {
        this.clients.start();
      }
    }
  }

  public get tabActive(): boolean {
    return this._tabActive;
  }

  @Input()
  public set tabActive(value: boolean) {
    if (this._tabActive !== value) {
      this._tabActive = value;
      this.cd.markForCheck();
      if (this._tabActive) {
        this.clients.start();
      }
    }
  }

  constructor(
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    public modalService: NgbModal,
    public benutzerService: BenutzerService,
    public csdServerService: CSDServerService,
    public routerService: RouterService
  ) {
    super();
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.clients.onUpdateRequired.subscribe((list: CSDServerClientList) => {
        if (this._tabActive) {
          (list.simpleFilter as SimpleCSDServerClientFilter).type = this._type;
          this.csdServerService
            .listClients(
              list.size,
              list.calcOffset(),
              list.order,
              list.simpleFilter,
              list.getQuery()
            )
            .subscribe(
              (l) => {
                list.populateFromListResult(l);
                this.cd.markForCheck();
              },
              (e) => {
                console.log(e);
                MERichError.throw(
                  'Fehler beim Datenabruf',
                  'Die Liste der Clients konnte nicht abgerufen werden.'
                );
              }
            );
        }
      }, undefined)
    );
  }

  onActivate() {
    this.tabActive = true;
    console.log('activated');
  }

  onDeactivate() {
    this.tabActive = false;
    console.log('deactivated');
  }

  public ngAfterViewInit(): void {}

  public ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
  }

  public addClient() {
    ClientEditorComponent.open(
      this.modalService,
      ClientEditorComponent,
      null
    ).then(() => {
      this.clients.reload();
    }, noop);
  }

  public editClient($event: MERowActionParams<CSDServerClient>) {
    this.csdServerService.loadClient($event.row.data.id).subscribe(
      (cl) => {
        ClientEditorComponent.open(
          this.modalService,
          ClientEditorComponent,
          cl
        ).then(() => {
          this.clients.reload();
        }, noop);
      },
      () => {
        MERichError.throw(
          'Fehler beim Datenabruf',
          'Der gewählte Client konnte nicht vom Server abgerufen werden'
        );
      }
    );
  }

  public showSim() {
    return this.sim_visible;
  }

  public editSim(simId: any) {
    SimEditorComponent.editSim(
      simId,
      this.routerService,
      this.modalService
    ).then(() => {
      this.clients.reload();
      this.csdServerService.syncSims().subscribe(noop, noop);
    }, noop);
  }

  public atTool($event: MERowActionParams<any>) {
    this.csdServerService.loadClient($event.row.data.id).subscribe(
      (cl) => {
        ATCommandToolComponent.open(
          this.modalService,
          ATCommandToolComponent,
          cl
        ).then(() => {
          this.clients.reload();
        }, noop);
      },
      () => {
        MERichError.throw(
          'Fehler beim Datenabruf',
          'Der gewählte Client konnte nicht vom Server abgerufen werden'
        );
      }
    );
  }
}
