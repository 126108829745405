import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild
} from '@angular/core';
import {TabPreisuebermittlungComponent} from './tab-preisuebermittlung/tab-preisuebermittlung.component';
import {TabMwstkorrekturComponent} from './tab-mwstkorrektur/tab-mwstkorrektur.component';
import {METabCardComponent} from '../../maennl-commons';

@Component({
    selector: 'app-preise',
    templateUrl: './preise.component.html',
    styleUrls: ['./preise.component.scss']
})
export class PreiseComponent implements OnInit, AfterViewInit {
    @ViewChild(METabCardComponent, {static: false}) tabs: METabCardComponent;

    @ViewChild('automatenPreiseTab') automatenPreiseTab = null;

    private _currentTab:
        | TabPreisuebermittlungComponent
        | TabMwstkorrekturComponent = null;

    get currentTab(): TabPreisuebermittlungComponent | TabMwstkorrekturComponent {
        return this._currentTab;
    }

    set currentTab(
        value: TabPreisuebermittlungComponent | TabMwstkorrekturComponent
    ) {
        if (this._currentTab !== value) {
            if (this._currentTab !== null) {
                this._currentTab.onDeactivate();
            }
            this._currentTab = value;
            if (this._currentTab !== null) {
                this._currentTab.onActivate();
            }
        }
    }

    constructor(public cd: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.currentTab = this.automatenPreiseTab;
    }

    ngOnInit(): void {
    }

    activateTab(s: string) {
        const found = false;

        if (!found) {
            console.log(s);
        }
    }

    deactivateTab(s: string) {
        const found = false;

        if (!found) {
            console.log(s);
        }
    }

    public activate(
        tab: TabPreisuebermittlungComponent | TabMwstkorrekturComponent
    ) {
        console.log(tab);
        this.currentTab = tab;
    }
}
