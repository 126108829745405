import {TransaktionNeedRecalcRN} from "./transaktion_need_recalc_rn.class";
import {TNullableTransaktionNeedRecalcRN} from "./transaktion_need_recalc_rn.types";
import {MEGenericEntityFields} from "../../../maennl-commons";

export class TransaktionNeedRecalcRNImpl {

    static fromResult(
        entity: TransaktionNeedRecalcRN,
        createifNull = false
    ): TNullableTransaktionNeedRecalcRN {
        if(entity===null||entity===undefined){
            if(createifNull){
                new TransaktionNeedRecalcRN();
            }
            return null
        }

        const instance = new TransaktionNeedRecalcRN();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongFields([
                    'id',
                    'transaktion_id',
                    'tenant',
                    'version',
                    'rn'
                ])
                .addTextFields([
                    '_tenant_info',
                    '_station_info',
                    '_terminal_info'
                ])
                .addDateFields([
                    'queued'
                ])
        );
        return instance;
    }
}