import {PositionNeedsProcessRN} from "./position_needs_process_rn.class";
import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {PositionNeedsProcessRNFilter} from "./position_needs_process_rn.filter";
import {IPositionNeedsProcessRN} from "./position_needs_process_rn.interface";

export class PositionNeedsProcessRNList extends MERemoteList<PositionNeedsProcessRN>{
    __classname:string='PositionNeedsProcessRNList';
    __instance:string='';

    static fromListResult(
        res:IMEListResult<IPositionNeedsProcessRN>
    ):PositionNeedsProcessRNList{
        const newList= new PositionNeedsProcessRNList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun:boolean=false, timeout:number=400){
        super();
        this.simpleFilter = new PositionNeedsProcessRNFilter();
        this.initList(autorun, timeout);
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'id',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true
    ) {
        super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    }

    convertEntity(entity: any): any {
        return super.convertEntity(entity);
    }
}