import { IMEListResult, MERemoteList } from '../../../maennl-commons';
import { IMwstKorrektur } from './imwst-korrektur';
import { MwstKorrektur } from './mwst-korrektur';
import { SimpleMwstKorrekturFilter } from './simple-mwst-korrektur-filter';

export class MwstKorrekturList extends MERemoteList<MwstKorrektur> {
  __classname = 'MwstKorrekturList';
  __instance = '';

  static fromListResult(res: IMEListResult<IMwstKorrektur>): MwstKorrekturList {
    const newList = new MwstKorrekturList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleMwstKorrekturFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'nebenstelle_name',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return MwstKorrektur.fromResult(entity);
  }
}
