import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit, TemplateRef, ViewChild, ViewContainerRef,
    ViewEncapsulation
} from "@angular/core";
import {
    MEConfirmDialogComponent, MEDataTableRowSelected, MEGenericModalComponent,
    MELocalizedComponent,
    MERowActionParams,
    MESubscriptionHelper, noop
} from "../../../../maennl-commons";
import {WireguardInterfaceList} from "../../commons/wireguard-interface.list";
import {WireguardInterfaceService} from "../../commons/wireguard-interface.service";
import {BenutzerService} from "../../../../benutzer/services/benutzer.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {WireguardInterface} from "../../commons/wireguard-interface.class";
import {WireguardInterfaceImpl} from "../../commons/wireguard-interface.impl";
import {WireguardInterfaceEditorComponent} from "./wireguard-interface-editor/wireguard-interface-editor.component";

@Component({
    selector: 'app-tab-wireguard-interface',
    templateUrl: './tab-wireguard-interface.component.html',
    styleUrls: ['./tab-wireguard-interface.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabWireguardInterfaceComponent
    extends MELocalizedComponent
    implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('detailTabs', {read: ViewContainerRef}) detailsRef: ViewContainerRef;
    @ViewChild('details', {read: TemplateRef}) detailRef: TemplateRef<any>;
    @Input() id = ''
    interfaceListe = new WireguardInterfaceList();

    private _tabActive = false;

    private _current: WireguardInterface = null;

    get current(): WireguardInterface {
        return this._current;
    }

    set current(value: WireguardInterface) {
        if (
            this._current !== value ||
            (this._current !== null &&
                this._current !== undefined &&
                value !== null &&
                value !== undefined &&
                this.current.id !== value.id)
        ) {
            this._current = value;
            this.cd.markForCheck();
        }
    }

    public get tabActive(): boolean {
        return this._tabActive
    }

    @Input()
    public set tabActive(value: boolean) {
        if (this._tabActive !== value) {
            this._tabActive = value;
            this.cd.markForCheck();
            if (this._tabActive) {
                this.interfaceListe.start();
            }
        }
    }

    constructor(
        public cd: ChangeDetectorRef,
        public interfaceService: WireguardInterfaceService,
        public benutzerService: BenutzerService,
        public modalService: NgbModal,
        public toastr: ToastrService
    ) {
        super()
    }

    ngOnInit(): void {
        MESubscriptionHelper.add(
            this,
            this.interfaceListe.onUpdateRequired.subscribe(() => {
                this.cd.markForCheck();
                if (this.interfaceListe.loading) {
                    return;
                }
                this.interfaceListe.loading = true;
                this.interfaceService.list(
                    this.interfaceListe.size,
                    this.interfaceListe.calcOffset(),
                    this.interfaceListe.order,
                    this.interfaceListe.simpleFilter,
                    this.interfaceListe.getQuery()
                )
                    .subscribe({
                        next: l => {
                            this.interfaceListe.doPopulateFromListResult(l);
                            this.interfaceListe.loading = false;
                            this.cd.markForCheck();
                        },
                        error: () => {
                            this.interfaceListe.clear();
                            this.interfaceListe.loading = false;
                        }
                    });
                this.cd.markForCheck();
            })
        );
    }

    onActivate() {
        this.tabActive = true;
    }

    onDeactivate() {
        this.tabActive = false;
    }

    ngOnDestroy() {
        this.interfaceListe.release();
        MESubscriptionHelper.release(this);
    }

    public ngAfterViewInit() {
    }

    removeInterface($event: MERowActionParams<WireguardInterface>) {
        if (this.interfaceService.get($event.row.data.id) !== null && this.interfaceService.get($event.row.data.id) !== undefined) {
            MEConfirmDialogComponent.display(
                this.modalService,
                "WG Interface Löschung bestätigen",
                "Soll das WireGuard Interface wirklich unwiederruflich gelöscht werden?",
                'Ja, jetzt löschen',
                'icofont icofont-delete'
            ).then(() => {
                this.interfaceService.get($event.row.data.id).subscribe((i: WireguardInterface) => {
                    if (i.id !== null && i.id !== undefined && i.id > 0) {
                        this.interfaceService.delete(i.id).subscribe((r) => {
                            if (r.success) {
                                this.toastr.info(
                                    'Das WireGuard Interface wurde erfolgreich gelöscht.'
                                )
                                this.interfaceListe.start();
                            }
                        });
                    }
                });
            }, () => {
                this.toastr.error(
                    'Die Löschung des WireGuard Interfaces wurde abgebrochen.'
                )
            })
        }
    }

    addInterface(){
        WireguardInterfaceEditorComponent.open(
            this.modalService,
            WireguardInterfaceEditorComponent,
            null,
            MEGenericModalComponent.SIZE_MEDIUM,
            null
        ).then(()=>{
            this.interfaceListe.start();
        },noop());
    }

    editInterface($event:MERowActionParams<WireguardInterface>){
        this.interfaceService.get($event.row.data.id).subscribe((i:WireguardInterface)=>{
            if(i!==null&&i!==undefined&&i.id>0){
                WireguardInterfaceEditorComponent.open(
                    this.modalService,
                    WireguardInterfaceEditorComponent,
                    i,
                    MEGenericModalComponent.SIZE_MEDIUM,
                    null
                ).then(()=>{
                    this.interfaceListe.start();
                },noop());
            }
        });
    }

    onRowSelected($event:MEDataTableRowSelected<WireguardInterface>) {
        if(
            $event===null||
            $event===undefined||
            $event.row===null||
            $event.row===undefined||
            $event.row.data===null||
            $event.row.data===undefined
        ) {
            this.current=null;
            return;
        }
        this.current=WireguardInterfaceImpl.fromResult($event.row.data);
        if (this.detailRef!==null&&this.detailsRef!==undefined) {
            this.detailsRef.clear();
            this.detailsRef.createEmbeddedView(this.detailRef);
        }
    }

    refresh_current() {
        this.interfaceService.get(this.current.id).subscribe((e:WireguardInterface)=>{
            for(let i=0;i<this.interfaceListe.data.length;i++){
                if(this.interfaceListe.data[i].id===e.id){
                    this.interfaceListe.update(i,e);
                }
            }
            if (this.current.id===e.id){
                this.current=WireguardInterfaceImpl.fromResult(e);
            }
            this.cd.markForCheck();
        })
    }

    removeClient(index:number){
        if(
            this.current===null||
            this.current===undefined||
            this.current._clients===null||
            this.current._clients===undefined||
            this.current._clients.length<index
        ) {
            this.toastr.error(
                'Der Client konnte nicht entfernt werden!'
            )
            return;
        }
        MEConfirmDialogComponent.display(
            this.modalService,
            "Client Löschung bestätigen",
            "Soll der Client wirklich unwirderruflich gelöscht werden?",
            'Ja, jetzt löschen',
            'icofont icofont-delete'
        ).then(()=>{
            this.current._clients.splice(index,1);
            this.interfaceService.store(this.current).subscribe({
                next:r=>{
                    if(r.success){
                        this.refresh_current();
                    } else {
                        this.toastr.error(
                            'Das löschen des Clients ist fehlgeschlagen'
                        );
                    }
                },
                error:()=>{
                    this.toastr.error(
                        'Das löschen des Clients ist fehlgeschlagen'
                    );
                }
            });
        },()=>{
            this.toastr.error(
                'Das löschen des Clients ist fehlgeschlagen'
            );
        })
    }

}