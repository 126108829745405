import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { METabCardComponent, METool } from '../../maennl-commons';
import { TabNozzleReadersComponent } from './tab-nozzle-readers/tab-nozzle-readers.component';

type TTabType = null | TabNozzleReadersComponent;

@Component({
  selector: 'app-nozzle-readers',
  templateUrl: './nozzle-readers.component.html',
  styleUrls: ['./nozzle-readers.component.scss'],
})
export class NozzleReadersComponent implements OnInit, AfterViewInit {
  @ViewChild(METabCardComponent, { static: false }) tabs: METabCardComponent;
  @ViewChild('readersTab', { static: false }) ka: TabNozzleReadersComponent;

  private _currentTab: TTabType = null;

  public get currentTab(): TTabType {
    return this._currentTab;
  }

  public set currentTab(value: TTabType) {
    if (this._currentTab !== value) {
      if (!METool.isNullOrUndefined(this._currentTab)) {
        this._currentTab.onDeactivate();
      }
      this._currentTab = value;
      if (!METool.isNullOrUndefined(this._currentTab)) {
        this._currentTab.onActivate();
      }
    }
  }

  ngAfterViewInit(): void {
    this.currentTab = this.ka;
  }

  constructor() {}

  ngOnInit() {}

  activateTab(s: string) {
    const found = false;

    if (!found) {
      console.log(s);
    }
  }

  deactivateTab(s: string) {
    const found = false;

    if (!found) {
      console.log(s);
    }
  }

  public activate(tab: TTabType) {
    console.log(tab);
    this.currentTab = tab;
  }
}
