import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, map, of, take} from 'rxjs';
import {BenutzerService} from './benutzer.service';
import {
    IMEActionResponse,
    IMEListResult,
    MEActionResponse,
    MEApiTool,
    MEResultMetaSort,
} from '../../maennl-commons';
import {IMESimpleFilter} from '../../maennl-commons/filters';
import {TNullableNumber} from '../../maennl-commons/tools/types';
import {Authority} from '../common/authority.class';
import {AuthorityImpl} from '../common/authority.impl';
import {IAuthority} from '../common/authority.interface';

@Injectable({
    providedIn: 'root',
})
export class AuthorityService {
    constructor(public http: HttpClient, public loginService: BenutzerService) {
    }

    public list(
        max = 50,
        offset = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<IAuthority> = null,
        queryString = ''
    ): Observable<IMEListResult<IAuthority>> {
        const uri = MEApiTool.buildListURL(
            '/api/authorities/',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<IAuthority>>(uri, this.loginService.getRequestOptions())
            .pipe(take(1));
    }

    public store(entity: Authority): Observable<MEActionResponse> {
        const uri = MEApiTool.buildEntityURL(
            '/api/authorities/',
            entity.id
        );

        if (entity.id === null || entity.id === undefined || entity.id < 1) {
            return this.http
                .post<IMEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r => {
                        return MEActionResponse.fromRawActionResponse(r);
                    }),
                    take(1)
                );
        } else {
            return this.http
                .put<IMEActionResponse>(
                    uri,
                    entity,
                    this.loginService.getRequestOptions()
                )
                .pipe(
                    map(r => {
                        return MEActionResponse.fromRawActionResponse(r);
                    }),
                    take(1)
                );
        }
    }

    public get(id: TNullableNumber): Observable<Authority> {
        if (id === null || id === undefined || id < 1) {
            return of(null);
        }
        const uri = MEApiTool.buildEntityURL('/api/authorities/', id);

        return this.http
            .get<IAuthority>(uri, this.loginService.getRequestOptions())
            .pipe(
                map(r => {
                    return AuthorityImpl.fromResult(r);
                }),
                take(1)
            );
    }

    public delete(id: TNullableNumber): Observable<MEActionResponse> {
        if (id === null || id === undefined || id < 1) {
            return of(MEActionResponse.error('id is null or undefined'));
        }
        const uri = MEApiTool.buildEntityURL('/api/authorities/', id);

        return this.http
            .delete<IMEActionResponse>(uri, this.loginService.getRequestOptions())
            .pipe(
                map(r => {
                    return MEActionResponse.fromRawActionResponse(r);
                }),
                take(1)
            );
    }
}
