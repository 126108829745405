import { Component, Injector } from '@angular/core';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';

@Component({
  selector: 'app-medtcheck-name-renderer',
  template: `
    <app-check-name-display
      *ngIf="isCellValid()"
      [check]="cell"
    ></app-check-name-display>
  `
})
export class MEDTCheckNameRendererComponent extends MEDataTableCommonCellRenderer {
  public cell: any = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<any> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');

    if (this.cell !== null && this.cell !== undefined) {
      if (!this.cell.hasOwnProperty('__classname__')) {
        this.cell = this.row.data;
      } else if (this.cell.__classname__ !== 'check') {
        this.cell = this.row.data;
      }
    }
  }
}
