import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-side-bar-link',
  template: `
    <a routerLink="{{ link }}" routerLinkActive="active">
      <i [ngClass]="icon"></i> {{ label }}
    </a>
  `,
  styleUrls: ['./side-bar-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarLinkComponent implements OnInit {
  private _link = '/';
  private _icon = '';
  private _label = '';

  get link(): string {
    return this._link;
  }

  @Input()
  set link(value: string) {
    this._link = value;
    this.cd.markForCheck();
  }

  get icon(): string {
    return this._icon;
  }

  @Input()
  set icon(value: string) {
    this._icon = value;
    this.cd.markForCheck();
  }

  get label(): string {
    return this._label;
  }

  @Input()
  set label(value: string) {
    this._label = value;
    this.cd.markForCheck();
  }

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit() {}
}
