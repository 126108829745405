import { Injectable } from '@angular/core';
import { IMEListResult } from '../../maennl-commons/mecommon-list';
import { MEResultMetaSort } from '../../maennl-commons/mesort-buttons';
import { IMEActionResponse, MEApiTool } from '../../maennl-commons/network';
import { IDevice } from '../common/idevice';
import { ISim } from '../common/isim';
import { HttpClient } from '@angular/common/http';
import { IMESimpleFilter } from '../../maennl-commons/filters';
import { BenutzerService } from '../../benutzer/services/benutzer.service';
import { IDeviceType } from '../common/idevice-type';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Device } from '../common/device';
import { SimProvider } from '../common/sim-provider';
import { ISimProvider } from '../common/isim-provider';
import { Sim } from '../common/sim';

@Injectable()
export class RouterService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public listDevices(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IDevice> = null,
    searchQuery = ''
  ): Observable<IMEListResult<IDevice>> {
    const uri = MEApiTool.buildListURL(
      '/api/router/devices',
      max,
      offset,
      sort,
      filter,
      searchQuery
    );
    return this.http
      .get<IMEListResult<IDevice>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public rebootDevice(
    device: IDevice,
    hardReset = false
  ): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL(
      '/api/router/devices',
      device.id,
      '_reboot',
      { hardReset: hardReset }
    );
    return this.http
      .post<IMEActionResponse>(uri, null, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public tickleDevice(device: IDevice | number): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL(
      '/api/router/devices',
      isNaN(parseInt('' + device, 10))
        ? (device as IDevice).id
        : parseInt('' + device, 10),
      '_tickle'
    );
    return this.http
      .post<IMEActionResponse>(uri, null, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public listSims(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<ISim> = null,
    queryString: string = ''
  ): Observable<IMEListResult<ISim>> {
    const uri = MEApiTool.buildListURL(
      '/api/router/sim',
      max,
      offset,
      sort,
      filter,
      queryString
    );
    return this.http
      .get<IMEListResult<ISim>>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  public listDeviceTypes(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IDeviceType> = null
  ): Observable<IMEListResult<IDeviceType>> {
    const uri = MEApiTool.buildListURL(
      '/api/router/device_types',
      max,
      offset,
      sort,
      filter
    );
    return this.http
      .get<IMEListResult<IDeviceType>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }

  public getDeviceCode(device: IDevice): string {
    if (device === null || device === undefined) {
      return 'unknown';
    }
    if (device.deviceType === null || device.deviceType === undefined) {
      return 'unknown';
    }

    if (
      device.deviceType.code === null ||
      device.deviceType.code === undefined
    ) {
      return 'unknown';
    }

    return device.deviceType.code.toLowerCase();
  }

  public isMEConnectRouter(device: IDevice): boolean {
    return this.getDeviceCode(device) === 'meconnectrouter';
  }

  public isMEConnectRouterV2(device: IDevice): boolean {
    return this.getDeviceCode(device) === 'meconnectrouterv2';
  }

  public isChinaRouter(device: IDevice): boolean {
    return this.getDeviceCode(device) === 'chinarouter';
  }

  public isNozzleReader(device: IDevice): boolean {
    return this.getDeviceCode(device) === 'nozzlereader';
  }

  public getDiskAvailablePercent(device: IDevice) {
    if (device === null || device === undefined) {
      return 0;
    }
    if (device.diskAvailable === 0) {
      return 0;
    }

    if (device.diskCapacity === 0) {
      return 0;
    }

    return Math.floor((device.diskAvailable / device.diskCapacity) * 100.0);
  }

  public getDiskUsedPercent(device: IDevice) {
    if (device === null || device === undefined) {
      return 0;
    }
    return 100 - this.getDiskAvailablePercent(device);
  }

  public getDiskWarnState(device: IDevice): string {
    if (device === null || device === undefined) {
      return 'none';
    }
    const x = this.getDiskUsedPercent(device);
    if (x > 85) {
      return 'danger';
    }
    if (x > 75) {
      return 'warning';
    }
    if (x > 50) {
      return 'info';
    }
    return 'success';
  }

  public canReboot(device: Device) {
    if (device === null || device === undefined) {
      return false;
    }
    if (this.isChinaRouter(device)) {
      return true;
    }
    if (this.isMEConnectRouter(device)) {
      return true;
    }
    if (this.isMEConnectRouterV2(device)) {
      return true;
    }
    if (this.isNozzleReader(device)) {
      if (device.sim !== null && device.sim !== undefined) {
        if (device.sim.directAccess) {
          if (
            device.username !== null &&
            device.username !== undefined &&
            device.username !== ''
          ) {
            return true;
          }
        }
      }
    }

    return false;
  }

  listProviders(
    max: number = 10,
    offset: number = 0,
    sort: MEResultMetaSort[] = null,
    filter: IMESimpleFilter<SimProvider> = null,
    query: string = ''
  ): Observable<IMEListResult<ISimProvider>> {
    const uri = MEApiTool.buildListURL(
      '/api/router/sim_provider',
      max,
      offset,
      sort,
      filter,
      query
    );
    return this.http
      .get<IMEListResult<ISimProvider>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }

  storeSim(sim: Sim): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildEntityURL('/api/router/sim', sim.id);

    if (sim.id === null || sim.id === undefined || sim.id < 1) {
      return this.http
        .post<IMEActionResponse>(
          uri,
          sim,
          this.loginService.getRequestOptions()
        )
        .pipe(take(1));
    }
    return this.http
      .put<IMEActionResponse>(uri, sim, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  getSim(simId: number): Observable<ISim> {
    const uri = MEApiTool.buildEntityURL('/api/router/sim', simId);

    return this.http
      .get<ISim>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  reindexall(): Observable<IMEActionResponse> {
    const uri = MEApiTool.buildActionURL('/api/router', null, '_reIndexAll');

    return this.http
      .get<IMEActionResponse>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }

  importTS() {
    const uri = MEApiTool.buildActionURL('/api/router', null, '_importTS');

    return this.http
      .get<IMEActionResponse>(uri, this.loginService.getRequestOptions())
      .pipe(take(1));
  }
}
