import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {
    MEHighlitePipe,
    MELocalizedComponent,
    MESelectComponent, MESubscriptionHelper,
    METool,
    TMESelectItemFormatterCallback, TMESelectItemMatcherCallback, TMESelectItemTextCallback,
    noop
} from "../../../maennl-commons";
import {InventoryType} from "../commons/inventory-type.class";
import {TNullableNumber} from "../../../maennl-commons/tools/types";
import {InventoryTypeList} from "../commons/inventory-type.list";
import {InventoryTypeService} from "../commons/inventory-type.service";

@Component({
    selector: 'app-inventory-type-selector',
    template: `
        <div class="form-group row mb-1">
        <label
                for="'id'"
                class="col-form-label"
                [ngClass]="'col-3'"
        >
            Bestandtyp:
        </label>
        <div [ngClass]="'col-9'">
        <me-select
                [asMatrix]="false"
                [idField]="'id'"
                [items]="liste.data"
                [placeholder]="placeholder"
                [small]="small"
                (onSearch)="doSearch($event)"
                (onSelect)="doSelect($event)"
                [nullable]="nullable"
                [onFormat]="itemFormat"
                [onGetText]="itemText"
                [onMatch]="itemMatcher"
        ></me-select>
        </div></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryTypeSelectorComponent
    extends MELocalizedComponent
    implements OnInit, OnDestroy, AfterViewInit {
    __classname='InventoryTypeSelectorComponent';
    __instance='';
    @Input() nullable=true;
    @Input() placeholder = 'Inventory Typ auswählen';
    @Input() small=false;
    @Input() filterQuery='';

    public _value:InventoryType=null;

    @Output() valueChange=new EventEmitter<InventoryType>();
    @Output() value_idChange= new EventEmitter<TNullableNumber>();

    public liste = new InventoryTypeList();
    @ViewChild(MESelectComponent, {static: false}) meSelect: MESelectComponent;


    constructor(
        public inventoryTypeService: InventoryTypeService,
        public cd: ChangeDetectorRef
    ) {
        super();
    }

    @Input()
    set value(v:InventoryType) {
        if (this._value!==v){
            this._value=v;
            if(this.meSelect!==null&&this.meSelect!==undefined){
                this.meSelect.value=v;
            }
            this.valueChange.emit(v);
            this.value_idChange.emit(this.value_id);
        }
    }

    get value():InventoryType {
        return this._value;
    }

    get value_id():TNullableNumber {
        if(METool.isNullOrUndefined(this.value)) {
            return null;
        }
        return this.value.id;
    }

    @Input()
    set value_id(v:TNullableNumber) {
        if(METool.isNullOrUndefined(v)) {
            this.value=null;
            return;
        }
        if(v===this.value_id) {
            return;
        }
        if(
            !METool.isNullOrUndefined(this.liste)&&
            Array.isArray(this.liste.data)
        ) {
            for (let i=0;i<this.liste.data.length;i++){
                if(this.liste.data[i].id===v){
                    this.value=this.liste.data[i];
                    return;
                }
            }
        }
        this.inventoryTypeService.get(v).subscribe({
            next:(t)=>{
                if(t!==null){
                    this.liste.data.push(t);
                }
                this.value=t;
            },
            error:()=>{
                this.value=null;
            }
        });
    }

    public itemFormat: TMESelectItemFormatterCallback<InventoryType> =(
        item: InventoryType,
        search: string,
        forList: boolean
    ) => {
        if(item===null||item===undefined) {
            return ''
        }
        return MEHighlitePipe.transformString(''+item.display_text,search);
    }

    public itemMatcher: TMESelectItemMatcherCallback<InventoryType> =(
        item:InventoryType,
        search: string
    ) =>{
        if(!search) {
            return true;
        }
        if(search.trim()===''){
            return true;
        }
        if(!item) {
            return false;
        }

        const x = item.display_text+' '+item.id+' '+item.code;

        return x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase())>=0;
    }

    public itemText: TMESelectItemTextCallback<InventoryType> = (item: InventoryType) =>{
        if(item===null||item===undefined) {
            return '';
        }
        return item.display_text;
    }

    doSearch(value) {
        if(value===null||value===undefined) {
            value='';
        }

        this.liste.simpleFilter.clear();
        this.liste.searchString2=(''+this.filterQuery).trim();
        this.liste.searchString=(''+value).trim();
        this.liste.reload();
    }

    doSelect(value: InventoryType){
        this._value=value;
        this.valueChange.emit(value);
        this.value_idChange.emit(this.value_id);
    }

    ngAfterViewInit() {
        if(this.meSelect!==null&&this.meSelect!==undefined) {
            this.meSelect.onFormat = this.itemFormat;
            this.meSelect.onGetText = this.itemText;
            this.meSelect.onMatch = this.itemMatcher;
            this.meSelect.value = this.value;
        }
        this.liste.start();
    }

    ngOnInit() {
        this.liste.setWindowSize(150);
        MESubscriptionHelper.add(
            this,
            this.liste.onUpdateRequired.subscribe((list:InventoryTypeList) => {
                list.searchString2= (''+this.filterQuery).trim();
                this.inventoryTypeService
                    .list(
                        list.size,
                        list.calcOffset(),
                        list.order,
                        list.simpleFilter,
                        list.getQuery()
                    )
                    .subscribe((l) =>{
                        list.doPopulateFromListResult(l);
                        this.cd.markForCheck();
                    });
            }, noop())
        );

        if (this.meSelect!==null&&this.meSelect!==undefined) {
            this.meSelect.onFormat = this.itemFormat;
            this.meSelect.onGetText = this.itemText;
            this.meSelect.onMatch= this.itemMatcher;
        }
        this.liste.start();
    }

    ngOnDestroy() {
        MESubscriptionHelper.release(this);
        this.liste.release();
        super.ngOnDestroy();
    }
}