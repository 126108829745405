import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {MELocalizedComponent} from '../../../maennl-commons/localized-component';
import {MESubscriptionHelper} from '../../../maennl-commons/services';
import {Device} from '../../common/device';
import {DeviceList} from '../../common/device-list';
import {
    MESelectComponent,
    TMESelectItemFormatterCallback,
    TMESelectItemMatcherCallback,
    TMESelectItemTextCallback
} from '../../../maennl-commons/forms/components/me-select';
import {RouterService} from '../../services/router.service';
import {MEHighlitePipe} from '../../../maennl-commons/pipes';
import {MEConverterTool} from '../../../maennl-commons/tools';
import {Md5} from "ts-md5";

@Component({
    selector: 'app-device-selector-w-lbl',
    template: `
        <div class="form-group row mb-1">
            <label
                    for="{{id}}"
                    class="col-form-label"
                    [ngClass]="'col-' + labelWidth"
                    *ngIf="labelWidth > 0"
            >
                {{ label }}
            </label>
            <div [ngClass]="'col-' + (12 - labelWidth)">
                <me-select
                        [asMatrix]="false"
                        [idField]="'id'"
                        [items]="devices.data"
                        [placeholder]="placeholder"
                        [small]="small"
                        (onSearch)="doSearch($event)"
                        (onSelect)="doSelect($event)"
                        [nullable]="nullable"
                        [onFormat]="itemFormat"
                        [onGetText]="itemText"
                        [onMatch]="itemMatcher"
                ></me-select>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeviceSelectorWithLabelComponent
    extends MELocalizedComponent
    implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    set value(v: Device) {
        if (this._value !== v) {
            this._value = v;
            if (this.meSelect !== null && this.meSelect !== undefined) {
                this.meSelect.value = v;
            }
            this.valueChange.emit(v);
        }
    }

    get value(): Device {
        return this._value;
    }

    __classname = 'DeviceSelectorWithLabelComponent';
    __instance = '';

    @Input() nullable = true;
    @Input() placeholder = 'Router/Gateway-Device auswählen';
    @Input() small = false;
    @Input() labelWidth = 3;
    @Input() label = 'Textfeld';
    @Input() id = '';

    @Input() filterQuery = '';

    public _value: Device = null;

    @Output() valueChange = new EventEmitter<Device>();

    public devices: DeviceList = new DeviceList();

    @ViewChild(MESelectComponent, {static: false}) meSelect: MESelectComponent;

    ngAfterViewInit(): void {
        if (this.meSelect !== null && this.meSelect !== undefined) {
            this.meSelect.onFormat = this.itemFormat;
            this.meSelect.onGetText = this.itemText;
            this.meSelect.onMatch = this.itemMatcher;
            this.meSelect.value = this._value;
        }
        this.devices.start();
    }

    constructor(
        public routerService: RouterService,
        public cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        MESubscriptionHelper.add(
            this,
            this.devices.onUpdateRequired.subscribe((list: DeviceList) => {
                list.searchString2 = ('' + this.filterQuery).trim();
                this.routerService
                    .listDevices(
                        list.size,
                        list.calcOffset(),
                        list.order,
                        list.simpleFilter,
                        list.getQuery()
                    )
                    .subscribe((l) => {
                        list.populateFromListResult(l);
                        this.cd.markForCheck();
                    }, undefined);
            }, undefined)
        );

        if (this.meSelect !== null && this.meSelect !== undefined) {
            this.meSelect.onFormat = this.itemFormat;
            this.meSelect.onGetText = this.itemText;
            this.meSelect.onMatch = this.itemMatcher;
        }
        if (this.id === '') {
            this.id =
                'tf' +
                Md5.hashStr(
                    'textfield-' + this.label + Math.random() + '-' + Math.random()
                );
        }
        this.devices.start();
    }

    doSearch(value) {
        if (value === null || value === undefined) {
            value = '';
        }

        this.devices.simpleFilter.clear();
        this.devices.searchString2 = ('' + this.filterQuery).trim();
        this.devices.searchString = ('' + value).trim();
        this.devices.reload();
    }

    doSelect(value: Device) {
        this._value = value;
        this.valueChange.emit(value);
    }

    public itemFormat: TMESelectItemFormatterCallback<Device> = (
        item: Device,
        search,
        forList: boolean
    ) => {
        if (item === null || item === undefined) {
            return '';
        }
        return MEHighlitePipe.transformString('' + item.bezeichnung, search);
    };

    public itemText: TMESelectItemTextCallback<Device> = (item: Device) => {
        if (item === null || item === undefined) {
            return '';
        }
        return item.bezeichnung;
    };

    public itemMatcher: TMESelectItemMatcherCallback<Device> = (
        item: Device,
        search
    ) => {
        if (!search) {
            return true;
        }
        if (search.trim() === '') {
            return true;
        }
        if (!item) {
            return false;
        }
        const x = item.bezeichnung + ' ' + item.id + ' ' + item.machine_id;

        return x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
    };

    ngOnDestroy(): void {
        MESubscriptionHelper.release(this);
        this.devices.release();
        super.ngOnDestroy();
    }
}
