import { MEDataTableRow } from '../data-table/rows/medata-table-row';
import { IMESimpleFilter } from '../filters/simple-filter';
import { MEResultMetaSort } from '../mesort-buttons/result-meta-sort';
import { METool } from '../tools';
import { IMEList } from './imelist';
import { IMEListResult } from './imelist-result';

export abstract class MECommonList<T> implements IMEList<T> {
  simpleFilter: IMESimpleFilter<T>;

  public _data: T[] = [];

  public total = 0;
  public offset = 0;
  public size = 10;
  public page = 0;
  public order: MEResultMetaSort[] = [];
  public dataTable: MEDataTableRow<T>[] = [];
  public searchString = '';
  public searchString2 = '';
  public extra: any = {};

  public clearData() {
    this._data = [];
    this.dataTable = [];
  }

  public clear() {
    this.total = 0;
    this.offset = 0;
    this.size = 10;
    this.page = 0;
    this.extra = {};
    this.order = [];
    this.clearData();
  }

  get length(): number {
    if (
      this._data === null ||
      this._data === undefined ||
      !Array.isArray(this._data)
    ) {
      return 0;
    }
    return this._data.length;
  }

  set data(v: T[]) {
    this.clearData();

    v.forEach(x => {
      this.add(x);
    });
  }

  get data(): T[] {
    return this._data;
  }

  public calcOffset(): number {
    return Math.max(0, this.size * (this.page - 1));
  }

  public asDataTableRows(): MEDataTableRow<T>[] {
    return this.dataTable;
  }

  public add(e: T) {
    this._data.push(e);
    this.dataTable.push(new MEDataTableRow<T>(e));
  }

  public update(idx: number, e: T) {
    if (this._data.length > idx) {
      this._data[idx] = e;
    }
    if (this.dataTable.length > idx) {
      if (this.dataTable[idx] === null || this.dataTable[idx] === undefined) {
        this.dataTable[idx] = new MEDataTableRow<T>(e);
      } else {
        this.dataTable[idx].data = e;
      }
    }
  }

  public copyFrom(l: MECommonList<T>) {
    this.clear();

    l.data.forEach(e => {
      this.add(e);
    });

    this.total = l.total;
    this.offset = l.offset;
    this.size = l.size;
    this.page = l.page;

    this.order = l.order;
    this.extra = l.extra;
  }

  public get(index: number): T {
    if (
      index < 0 ||
      this._data === null ||
      this._data === undefined ||
      !Array.isArray(this._data)
    ) {
      return null;
    }
    if (index >= this._data.length) {
      return null;
    }
    return this._data[index];
  }

  public getEntry(index: number): any {
    if (
      index < 0 ||
      this._data === null ||
      this._data === undefined ||
      !Array.isArray(this._data)
    ) {
      return null;
    }
    if (index >= this._data.length) {
      return null;
    }
    return this._data[index];
  }

  public first(): T {
    return this.get(0);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort = 'id',
    defaultOrder = 'asc',
    clearFilter = true
  ) {
    this.clear();
    if (clearFilter) {
      if (this.simpleFilter !== null && this.simpleFilter !== undefined) {
        this.simpleFilter.clear();
      }
    }

    if (res !== null && res !== undefined) {
      if (res.meta) {
        this.total = res.meta.total || 0;
        this.offset = res.meta.offset || 0;
        this.size = res.meta.size || 0;
        this.page = res.meta.page || 0;
        if (res.meta.order) {
          this.order = [];
          for (const x of res.meta.order) {
            this.order.push(
              new MEResultMetaSort(
                x.field || defaultSort,
                x.direction || defaultOrder
              )
            );
          }
        }
        this.extra = res.meta.extra || {};
      }
      if (res.data) {
        for (const row of res.data) {
          this.add(this.convertEntity(row));
        }
      }
      if (
        res.simpleFilter !== null &&
        res.simpleFilter !== undefined &&
        this.simpleFilter !== null &&
        this.simpleFilter !== undefined
      ) {
        for (const k of Object.keys(this.simpleFilter)) {
          this.simpleFilter[k] = this.doConvertFilterField(
            k,
            res.simpleFilter[k]
          );
        }
      }
      if (res.searchString) {
        this.searchString = res.searchString;
      }
    }
  }

  public doConvertFilterField(k: string, input: any): any {
    return input !== undefined ? input : null;
  }

  public populateFromListResult(
    res: IMEListResult<any>,
    clearFilter = true
  ): void {
    this.doPopulateFromListResult(res, 'nummer', 'asc', clearFilter);
  }

  public convertEntity(entity: any): any {
    return entity;
  }

  public release() {
    this.clear();
    this.clearData();
  }

  getQuery() {
    const parts: string[] = [];
    if (
      this.searchString !== null &&
      this.searchString !== undefined &&
      this.searchString.trim() !== ''
    ) {
      parts.push('(' + this.searchString + ')');
    }
    if (
      this.searchString2 !== null &&
      this.searchString2 !== undefined &&
      this.searchString2.trim() !== ''
    ) {
      parts.push('(' + this.searchString2 + ')');
    }
    return parts.join(' AND ');
  }

  public pageCount() {
    if (this.total < 1) {
      return 0;
    }
    if (this.size < 1) {
      return 0;
    }
    return (
      Math.floor(this.total / this.size) + (this.total % this.size > 0 ? 1 : 0)
    );
  }

  calcSum(field: string): number {
    let sum = 0;
    this.data.map(v => {
      if (METool.isValidNumber(v[field])) {
        sum += v[field];
      }
    });
    return sum;
  }
}
