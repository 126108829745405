import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MEDTCheckStatusRendererComponent } from '../medtcheck-status-renderer/medtcheck-status-renderer.component';
import { Machine } from '../../common/machine';
import {
  MEDTExtraColumn,
  MEDataTableComponent,
  MERowActionParams
} from '../../../maennl-commons/data-table';
import { MERichError, METool, noop } from '../../../maennl-commons/tools';
import { CheckList } from '../../common/check-list';
import { SysHealthService } from '../../services/sys-health.service';
import {
  MEDataTableRow,
  TMEDTGetExtraRowClassesCallback
} from '../../../maennl-commons/data-table/rows';
import { Check } from '../../common/check';
import { SimpleCheckFilter } from '../../common/simple-check-filter';
import { ServiceType } from '../../common/service-type';
import { MEDTMachineRendererComponent } from '../medtmachine-renderer/medtmachine-renderer.component';
import { MEConfirmDialogComponent } from '../../../maennl-commons/meconfirm-dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MEGenericModalComponent } from '../../../maennl-commons/megeneric-modal';
import { ToastrService } from 'ngx-toastr';
import { MEDTCheckServiceTypeRendererComponent } from '../medtcheck-service-type-renderer/medtcheck-service-type-renderer.component';
import { MEDTCheckNameRendererComponent } from '../medtcheck-name-renderer/medtcheck-name-renderer.component';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-sys-health-checks-list',
  templateUrl: './sys-health-checks-list.component.html',
  styleUrls: ['./sys-health-checks-list.component.scss']
})
export class SysHealthChecksListComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'SysHealthChecksListComponent';
  __instance = '';

  tabActive = false;

  machineR = MEDTMachineRendererComponent;
  serviceTypeR = MEDTCheckServiceTypeRendererComponent;
  checkNameDisplayR = MEDTCheckNameRendererComponent;

  @Input() displayServiceColumn = true;
  @Input() serviceColumnLabel = 'Dienst';
  @Input() displayMachineColumn = false;
  @Input() displayServiceTypeColumn = false;
  @Input() machine: Machine = new Machine(0);
  @Output() onServiceEditAdd: EventEmitter<Check> = new EventEmitter();
  @Input() showAddAction = true;
  @Input() problemsOnly = false;
  @Input() autoReloadInterval = 0;
  @Input() useExtendedInfo = true;
  @Input() public ergebnisTemplate: TemplateRef<any> = null;

  @Input() extraColumns: MEDTExtraColumn[] = [];

  _serviceType: ServiceType = null;

  @Input()
  set servicetype(v: ServiceType) {
    if (v !== (this.dienste.simpleFilter as SimpleCheckFilter).serviceType) {
      this._serviceType = v;
      (this.dienste.simpleFilter as SimpleCheckFilter).serviceType = v;
      this.cd.markForCheck();
      this.dienste.reload();
    }
  }

  public statusRenderer = MEDTCheckStatusRendererComponent;

  @ViewChild(MEDataTableComponent, { static: false }) dt = null;

  public dienste: CheckList = new CheckList();

  constructor(
    public healthService: SysHealthService,
    public toastr: ToastrService,
    protected cd: ChangeDetectorRef,
    public modalService: NgbModal,
    public benutzerService: BenutzerService
  ) {}

  diensteRowClassGetter: TMEDTGetExtraRowClassesCallback<Check> = (
    row: MEDataTableRow<Check>,
    idx
  ) => {
    if (
      row !== null &&
      row !== undefined &&
      row.data !== null &&
      row.data !== undefined &&
      row.data.result !== null &&
      row.data.result !== undefined &&
      row.data.result.code !== null &&
      row.data.result.code !== undefined
    ) {
      return 'check-' + row.data.result.code.trim();
    }
    return '';
  };

  ngAfterViewInit() {
    if (this.dt !== null) {
      this.dt.extraRowClasses = this.diensteRowClassGetter;
    }
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.dienste.onUpdateRequired.subscribe((list: CheckList) => {
        if (this.tabActive) {
          (this.dienste.simpleFilter as SimpleCheckFilter).serviceType =
            this._serviceType;
          if (this.machine !== null && this.machine !== undefined) {
            this.healthService
              .listChecksByMachine(
                this.machine,
                list.size,
                list.calcOffset(),
                list.order,
                list.simpleFilter,
                list.searchString,
                this.problemsOnly
              )
              .subscribe(
                (l) => {
                  list.populateFromListResult(l);
                  this.cd.markForCheck();
                },
                (e) => {
                  console.log(e);
                  MERichError.throw(
                    'Fehler beim Datenabruf',
                    'Die Liste der verfügbaren Dienste auf dem Host ' +
                      this.machine.bezeichnung
                  );
                }
              );
          } else if (this.machine === null) {
            this.healthService
              .listChecks(
                list.size,
                list.calcOffset(),
                list.order,
                list.simpleFilter,
                list.searchString,
                this.problemsOnly
              )
              .subscribe({
                next: (l) => {
                  list.populateFromListResult(l);
                  this.cd.markForCheck();
                },
                error: (e) => {
                  console.log(e);
                  MERichError.throw(
                    'Fehler beim Datenabruf',
                    'Die Liste der verfügbaren Dienste auf dem Host ' +
                      (this.machine !== null ? this.machine.bezeichnung : '')
                  );
                }
              });
          }
        }
      }, undefined)
    );

    if (this.dt !== null) {
      this.dt.extraRowClasses = this.diensteRowClassGetter;
    }
  }

  onActivate() {
    this.tabActive = true;
    if (this.machine !== undefined) {
      this.dienste.enableAutoReload(this.autoReloadInterval);
      this.dienste.start();
    }
  }

  onDeactivate() {
    this.tabActive = false;
    if (this.machine !== undefined) {
      this.dienste.enableAutoReload(0);
    }
  }

  addService() {
    this.onServiceEditAdd.emit(null);
  }

  editService($event: MERowActionParams<Check>) {
    this.onServiceEditAdd.emit($event.row.data);
  }

  refreshList() {
    if (this.dienste !== null && this.dienste !== undefined) {
      this.dienste.reload();
    }
  }

  reCheck($event: MERowActionParams<Check>) {
    this.toastr.info('Test wird durchgeführt');
    this.healthService.reCheck($event.row.data).subscribe(
      (r) => {
        this.refreshList();
        if (r.success) {
          this.toastr.success('Test wurde durchgeführt');
        } else {
          MERichError.throw('Fehler', 'Ein erneuter Test ist fehlgeschlagen.');
        }
      },
      () => {
        this.refreshList();
        MERichError.throw(
          'Fehler',
          'Ein erneuter Test konnte nicht in Auftrag gegeben werden.'
        );
      }
    );
  }

  restartService($event: MERowActionParams<Check>) {
    MEConfirmDialogComponent.display(
      this.modalService,
      'Dienst (neu)starten?',
      'Soll versucht werden, den Dienst ' +
        $event.row.data.params.service +
        ' (neu) zu starten?<br /><br />' +
        'Sollte der Dienst laufen, wird versucht, diesen zunächst sauber herunterzufahren. ' +
        'Insgesamt dauert der Vorgang mindestens 15 Sekunden',
      'Dienst (neu)starten',
      'far fa-play-circle',
      MEGenericModalComponent.SIZE_SMALL
    ).then(() => {
      this.toastr.info('Dienst-Restart wird angefordert');

      this.healthService.restartService($event.row.data).subscribe(
        (r) => {
          if (r.success) {
            this.toastr.success(
              'Dienst-Neustart wurde beauftragt. Ein erneuter Test wird durchgeführt...'
            );
            this.reCheck($event);
          } else {
            this.refreshList();
            MERichError.throw(
              'Fehler',
              'Der Dienst konnte nicht neu gestartet werden'
            );
          }
        },
        () => {
          this.refreshList();
          MERichError.throw(
            'Fehler',
            'Der Dienst konnte nicht neu gestartet werden'
          );
        }
      );
    }, noop);
  }

  killService($event: MERowActionParams<Check>) {
    MEConfirmDialogComponent.display(
      this.modalService,
      'Dienst-Prozess abbrechen?',
      'Soll der Dienst-Prozess ' +
        $event.row.data.params.processname +
        ' des Dienstes ' +
        $event.row.data.params.service +
        ' wirklich abgebrochen werden?<br /><br />' +
        'Hierbei wird keine Rücksicht auf etwaigen Datenverlust genommen!',
      'Prozess töten',
      'icofont icofont-skull-danger',
      MEGenericModalComponent.SIZE_SMALL
    ).then(() => {
      this.toastr.info('Dienst-Abbruch wird angefordert');
      this.healthService.killService($event.row.data).subscribe(
        (r) => {
          if (r.success) {
            this.toastr.success(
              'Dienst-Abbruch wurde beauftragt. Ein erneuter Test wird durchgeführt...'
            );
            this.reCheck($event);
          } else {
            this.refreshList();
            MERichError.throw(
              'Fehler',
              'Der Dienst-Prozess konnte nicht abgebrochen werden'
            );
          }
        },
        () => {
          this.refreshList();
          MERichError.throw(
            'Fehler',
            'Der Dienst-Prozess konnte nicht abgebrochen werden'
          );
        }
      );
    }, noop);
  }

  checkAction(what: string, row: MERowActionParams<Check>) {
    let disable = false;

    let serviceName: string = null;
    let processName: string = null;
    let containerName: string = null;
    if (row.row.data.params !== null) {
      if (row.row.data.params.processname !== undefined) {
        if (row.row.data.params.processname !== null) {
          if (row.row.data.params.processname !== '') {
            processName = ('' + row.row.data.params.processname).trim();
          }
        }
      }
      if (row.row.data.params.service !== undefined) {
        if (row.row.data.params.service !== null) {
          if (row.row.data.params.service !== '') {
            serviceName = ('' + row.row.data.params.service).trim();
          }
        }
      }
      if (row.row.data.params.container !== undefined) {
        if (row.row.data.params.container !== null) {
          if (row.row.data.params.container !== '') {
            containerName = ('' + row.row.data.params.container).trim();
          }
        }
      }
    }

    if ('' === serviceName) {
      serviceName = null;
    }

    if ('' === processName) {
      processName = null;
    }
    if ('' === containerName) {
      containerName = null;
    }

    const serviceType = row.row.data.serviceType.code;

    if (what === 'killService') {
      if (serviceType !== 'docker' && processName === null) {
        disable = true;
      }
      if (serviceType === 'docker' && containerName === null) {
        disable = true;
      }
    }
    if (what === 'restartService') {
      if (serviceType !== 'docker' && serviceName === null) {
        disable = true;
      }
      if (serviceType === 'docker' && containerName === null) {
        disable = true;
      }
    }
    if (disable) {
      row.row.disableAction(row.action);
      row.row.hideAction(row.action);
    } else {
      row.row.enableAction(row.action);
      row.row.showAction(row.action);
    }
  }

  ngOnDestroy(): void {
    this.tabActive = false;
    MESubscriptionHelper.release(this);
    this.dienste.release();
  }

  hasErgebnisTemplate(): boolean {
    return !METool.isNullOrUndefined(this.ergebnisTemplate);
  }
}
