import {MEGenericEntity, MELocalizedComponent} from "../../../maennl-commons";
import {IIPSecInfo} from "./ipsec-info.interface";
import {TNullableBoolean, TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";
import {TNullableIPSecInfoChildList} from "./ipsec-info-child.types";

export class IPSecInfo
    extends MEGenericEntity<IIPSecInfo>
    implements IIPSecInfo {
    child_length: TNullableNumber=null;
    child_sas: TNullableIPSecInfoChildList=null;
    dh_group: TNullableString=null;
    encr_alg: TNullableString=null;
    encr_keysize: TNullableNumber=null;
    established: TNullableNumber=null;
    if_id_in: TNullableString=null;
    if_id_out: TNullableString=null;
    initiator: TNullableString=null;
    initiator_spi: TNullableString=null;
    integ_alg: TNullableString=null;
    local_host: TNullableString=null;
    local_id: TNullableString=null;
    local_port: TNullableNumber=null;
    name: TNullableString=null;
    nat_any: TNullableBoolean=null;
    nat_remote: TNullableBoolean=null;
    prf_alg: TNullableString=null;
    reauth_time: TNullableNumber=null;
    remote_host: TNullableString=null;
    remote_id: TNullableString=null;
    remote_port: TNullableNumber=null;
    responder_spi: TNullableString=null;
    state: TNullableString=null;
    unique_id: TNullableNumber=null;
    version: TNullableNumber=null;
}