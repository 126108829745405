import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { METool } from '../../maennl-commons/tools';
import { ISim } from './isim';
import { ISimProvider } from './isim-provider';
import { SimProvider } from './sim-provider';

export class Sim extends MEGenericEntity<ISim> implements ISim {
  id: number = null;
  label = '';
  simProvider: SimProvider = new SimProvider();
  imsi = '';
  msidsn = '';
  ip = '';
  tarif = '';
  inklTaffic = 0;
  directAccess = false;
  gesperrt = false;
  info = '';

  static fromResult(entity: ISim, createIfNull: boolean = false): Sim {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Sim();
      }
      return null;
    }

    const instance: Sim = new Sim();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addField('id', 'long')
        .addField('label', 'string')
        .addField(
          'simProvider',
          'converter',
          new MEGenericEntityConverterParams<SimProvider>((value: any) => {
            return SimProvider.fromResult(value);
          })
        )
        .addField('imsi', 'string')
        .addField('msidsn', 'string')
        .addField('ip', 'string')
        .addField('tarif', 'string')
        .addField('inklTraffic', 'long')
        .addBooleanField('gesperrt')
        .addTextField('info'),
      (e: Sim) => {
        if (METool.isNullOrUndefined(e.imsi) || e.imsi === 'null') {
          e.imsi = '';
        }
        if (METool.isNullOrUndefined(e.msidsn) || e.msidsn === 'null') {
          e.msidsn = '';
        }
        if (METool.isNullOrUndefined(e.ip) || e.ip === 'null') {
          e.ip = '';
        }
      }
    );
    return instance;
  }

  constructor(
    id: number = 0,
    label: string = '',
    simProvider: ISimProvider = null,
    imsi: string = '',
    msidsn: string = '',
    ip: string = '',
    tarif: string = '',
    inklTaffic: number = 0
  ) {
    super();
    this.id = id;
    this.label = label;
    this.simProvider = SimProvider.fromResult(simProvider);
    this.imsi = imsi;
    this.msidsn = msidsn;
    this.ip = ip;
    this.tarif = tarif;
    this.inklTaffic = inklTaffic;
  }
}
