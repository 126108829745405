import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { DeviceType } from './device-type';
import { IDeviceType } from './idevice-type';
import { SimpleDeviceTypeFilter } from './simple-device-type-filter';

export class DeviceTypeList extends MERemoteList<DeviceType> {
  __classname = 'DeviceTypeList';
  __instance = '';

  static fromListResult(res: IMEListResult<IDeviceType>): DeviceTypeList {
    const newList = new DeviceTypeList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleDeviceTypeFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'bezeichnung',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }
}
