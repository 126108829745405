import { Component, Injector } from '@angular/core';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns';
import { Check } from '../../common/check';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows';

@Component({
  selector: 'app-medtcheck-param-renderer',
  template: `{{ value }}`
})
export class MEDTCheckParamRendererComponent extends MEDataTableCommonCellRenderer {
  public cell: any = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<Check> = null;

  public value = '';

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');

    this.value = '';

    if (
      this.cell !== null &&
      this.cell !== undefined &&
      this.column !== null &&
      this.column !== undefined &&
      this.column.cfg !== null &&
      this.column.cfg !== undefined &&
      this.column.cfg.item !== null &&
      this.column.cfg.item !== undefined &&
      this.column.cfg.item !== ''
    ) {
      const item = ('' + this.column.cfg.item).trim();
      this.value = '';
      Object.keys(this.cell).forEach((v) => {
        if (v.match(item)) {
          this.value = this.cell[item];
        }
      });
    } else {
      this.value = JSON.stringify(this.cell);
    }
  }
}
