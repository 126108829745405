import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';
import { MEConverterTool } from '../../tools';
import { MEAddOnClickEvent } from '../events';

@Component({
  selector: 'me-num-field',
  styles: [
    `
      :host .addon button:first-child {
        border-left: none;
        padding: 0 5px;
      }
    `,
  ],
  template: `
    <div class="form-group row mb-1">
      <label
        for="{{ id }}"
        class="col-{{ labelwidth }} col-form-label"
        *ngIf="labelwidth > 0">
        {{ label }}
      </label>
      <div class="col-{{ 12 - labelwidth }}">
        <div
          class=""
          [class.input-group]="hasAddon()"
          [class.input-group-sm]="hasAddon() && small">
          <input
            class="form-control"
            [class.form-control-sm]="small"
            type="number"
            id="{{ id }}"
            [value]="value"
            (input)="update($event)"
            placeholder="{{ label }}"
            [attr.disabled]="disabled ? true : null"
            [class.disabled]="disabled"
            [tabIndex]="tabindex" />
          <span
            class="input-group-btn addon"
            *ngFor="let ai of addonIcons; let idx = index">
            <button
              class="btn btn-secondary addon-btn-{{ idx }}"
              [class.btn-sm]="small"
              type="button"
              title="{{ _addOnLabels[idx] }}"
              (click)="aClick(idx)">
              <i class="{{ ai }}"></i>
            </button>
          </span>
        </div>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class MENumFieldComponent implements OnInit {
  @Input('addonicon')
  set icon(v: any) {
    this._icons = [];
    if (Array.isArray(v)) {
      v.forEach(i => {
        this._icons.push(i);
      });
    } else {
      this._icons.push(v);
    }
    while (this._addOnLabels.length < this._icons.length) {
      this._addOnLabels.push('');
    }
  }

  get icon(): any {
    if (this._icons.length < 1) {
      return '';
    } else if (this._icons.length < 2) {
      return this._icons[0];
    }
    return this._icons;
  }

  @Input()
  set addonlabel(v: any) {
    this._addOnLabels = [];
    if (Array.isArray(v)) {
      v.forEach(i => {
        this._addOnLabels.push(i);
      });
    } else {
      this._addOnLabels.push(v);
    }
    while (this._addOnLabels.length < this._icons.length) {
      this._addOnLabels.push('');
    }
  }

  @Input()
  set value(v: number) {
    this.setValue(v);
  }

  get value(): number {
    return this._value;
  }

  get addonIcons(): any[] {
    if (this.hasAddon()) {
      return this._icons;
    }
    return [];
  }

  public _value = 0;
  public _icons: string[] = [];

  @Input() label = 'Num-Feld';
  @Input() small = false;
  @Input() tabindex: number = null;

  _addOnLabels: string[] = [];

  @Input() enableaddon = false;
  @Input() id = '';
  @Input() labelwidth = 3;
  @Input() disabled = false;

  @Output() valueChange = new EventEmitter<number>();
  @Output() addonClick = new EventEmitter<MEAddOnClickEvent>();

  public setValue(v: number) {
    v = MEConverterTool.toNumber(v);
    if (this._value !== v) {
      this._value = v;
      if (!this.disabled) {
        this.valueChange.emit(v);
      }
    }
  }

  constructor() {}

  ngOnInit() {
    if (this.id === '') {
      this.id =
        'tf' +
        Md5.hashStr(
          'numfield-' + this.label + Math.random() + '-' + Math.random()
        );
    }
  }

  update(event) {
    if (!this.disabled) {
      this.setValue(event.target.value);
    }
  }

  hasAddon(): boolean {
    return !this.disabled && this.enableaddon && this._icons.length > 0;
  }

  aClick(i?: any) {
    const e: MEAddOnClickEvent = new MEAddOnClickEvent();
    e.component = this;
    e.addon = i;
    this.addonClick.emit(e);
  }
}
