import {MEGenericEntity} from "../../../maennl-commons";
import {IApplications} from "./applications.interface";
import {TNullableBoolean, TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";

export class Applications
    extends MEGenericEntity<IApplications>
    implements IApplications {

    app_token: TNullableString;
    code: TNullableString='';
    description: TNullableString='';
    display_text: TNullableString='';
    enabled: TNullableBoolean=true;
    id: TNullableNumber;
    ip_addresses: TNullableString[]=[];
    refresh_token_lifetime: TNullableString= '8 days';
}