import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    Input,
    OnDestroy,
    OnInit, Output, TemplateRef, ViewChild, ViewContainerRef,
    ViewEncapsulation
} from "@angular/core";
import {Tenant} from "../../../commons/tenant";
import {TenantService} from "../../../commons/tenant.service";
import {BenutzerService} from "../../../../../benutzer/services/benutzer.service";
import {CoreModulesList} from "../../../commons/core-modules.list";
import {MESubscriptionHelper} from "../../../../../maennl-commons";
import {CoreModulesService} from "../../../commons/core-modules.service";
import {TCoreModules} from "../../../commons/core-modules.types";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-tab-tenants-editor-modules',
    templateUrl: './tab-tenants-editor-modules.component.html',
    styleUrls: ['./tab-tenants-editor-modules.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabTenantsEditorModulesComponent
    implements OnInit,OnDestroy{
    @Input() entity: Tenant=null;
    @ViewChild('modules', {read: ViewContainerRef}) modulesRef:ViewContainerRef;
    @ViewChild('modulestemp', {read: TemplateRef}) modulRef:TemplateRef<any>;
    @Output() entityChanged = new EventEmitter<Tenant>();
    modulList:CoreModulesList=new CoreModulesList();

    private _tabActive=false;

    constructor(
        public cd:ChangeDetectorRef,
        public service: TenantService,
        public modulService: CoreModulesService,
        public benutzerService: BenutzerService,
        public toastr: ToastrService,
    ) {}

    public get tabActive():boolean {
        return this._tabActive;
    }

    @Input()
    public set tabActive(value: boolean){
        if(this._tabActive!==value){
            this._tabActive = value;
            this.cd.markForCheck();
        }
    }

    onActivate(){
        this.tabActive=true;
        MESubscriptionHelper.add(
            this,
            this.modulList.onUpdateRequired.subscribe(()=>{
                if(this.modulList.loading){
                    return;
                }
                this.modulList.loading=true;
                this.modulList.size=9999;
                this.modulList.offset=0;
                this.modulService
                    .list(
                        this.modulList.size,
                        this.modulList.calcOffset(),
                        this.modulList.order,
                        this.modulList.simpleFilter,
                        this.modulList.getQuery()
                    )
                    .subscribe({
                        next:l =>{
                            this.modulList.loading=false;
                            this.modulList.doPopulateFromListResult(l);
                            this.cd.markForCheck();
                        },
                        error:()=>{
                            this.modulList.clear();
                        },
                    });
                this.cd.markForCheck();
            })
        );
        this.modulList.start();
        this.cd.markForCheck();
    }

    onDeactivate() {
        this.tabActive=false;
        this.modulList.disableAutoReload();
    }

    ngOnInit() {
    }

    ngOnDestroy(){
        this.modulList.release();
        MESubscriptionHelper.release(this);
    }

    refresh_view() {
        if(this.modulesRef !== null && this.modulesRef !== undefined && this.modulRef!==null && this.modulRef!==undefined) {
            this.modulesRef.clear();
            this.modulesRef.createEmbeddedView(this.modulRef);
        }
    }

    add_module(module: TCoreModules) {
        if(
            this.entity === null||
            this.entity === undefined ||
            module === null||
            module === undefined
        ) {
            return;
        }
        this.service.add_module(this.entity.id, module.code).subscribe({
            next: r => {
                if(r.success) {
                    this.reloadEntity();
                    this.entityChangedHandler(this.entity);
                    this.refresh_view();
                } else {
                    this.toastr.error(
                        'Das Hinzufügen der Modul ist fehlgeschlagen!'
                    );
                }
                this.cd.markForCheck();
            },
            error: () =>{
                this.toastr.error('Das Hinzufügen der Modul ist fehlgeschlagen!');
            },
        });
    }

    remove_module(module: TCoreModules) {
        if(
            this.entity === null||
            this.entity === undefined ||
            module === null||
            module === undefined
        ) {
            return;
        }
        this.service.remove_module(this.entity.id, module.code).subscribe({
            next: r => {
                if(r.success) {
                    this.reloadEntity();
                    this.entityChangedHandler(this.entity);
                    this.refresh_view();
                } else {
                    this.toastr.error(
                        'Das Entfernen des Moduls ist fehlgeschlagen!'
                    );
                }
                this.cd.markForCheck();
            },
            error: () =>{
                this.toastr.error('Das Entfernen des Moduls ist fehlgeschlagen!');
            },
        });
    }

    entityChangedHandler(entity: Tenant) {
        this.entityChanged.emit(entity);
    }

    reloadEntity() {
        this.service.get(this.entity.id).subscribe((t: Tenant) =>{
            if(this.entity.id===t.id){
                this.entity=t;
            }
            this.cd.markForCheck();
        });
    }
}