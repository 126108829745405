import { MEConverterTool, METool } from '../../../maennl-commons';
import { Vehicle } from '../../vehicles/commons/vehicle';
import { ITrackerOptions } from '../commons/itracker-options';
import { VehicleTracker } from '../commons/vehicle-tracker';
import { ChangeDetectorRef } from '@angular/core';

interface IOptions extends ITrackerOptions {
  tracker: VehicleTracker;
  vehicle: Vehicle;
}

export class ModifyTrackerOptions {
  data: IOptions = {
    gps_recording_enabled: false,
    identification: '',
    last_address: '',
    last_odometer: 0,
    last_position: 0,
    last_seen: null,
    last_voltage: 0,
    last_voltage_zp: null,
    last_working_hours: 0,
    last_zuendung: false,
    offset_odometer: 0,
    offset_working_hours: 0,
    sms_password: '',
    sms_use_id: false,
    typ: 0,
    tracker: null,
    vehicle: null,
    mitschnitt_aktiv: false
  };

  private _cd: ChangeDetectorRef = null;

  get cd(): ChangeDetectorRef {
    return this._cd;
  }

  set cd(value: ChangeDetectorRef) {
    this._cd = value;
  }

  get tracker(): VehicleTracker {
    return this.data.tracker;
  }

  set tracker(value: VehicleTracker) {
    this.data.tracker = value;
    if (!METool.isNullOrUndefined(value)) {
      let o = value.tracker_obj;
      this.last_zuendung = o.last_zuendung;
      this.gps_recording_enabled = o.gps_recording_enabled;
      this.typ = o.typ;
      this.identification = o.identification;
      this.offset_working_hours = o.offset_working_hours;
      this.offset_odometer = o.offset_odometer;
      this.last_voltage = o.last_voltage;
      this.last_position = o.last_position;
      this.last_address = o.last_address;
      this.last_odometer = o.last_odometer;
      this.last_working_hours = o.last_working_hours;
      this.sms_password = o.sms_password;
      this.sms_use_id = o.sms_use_id;
      this.mitschnitt_aktiv=o.mitschnitt_aktiv;
    }
    this.mfc();
  }

  get vehicle() {
    return this.data.vehicle;
  }

  set vehicle(value) {
    this.data.vehicle = value;
    if (!METool.isNullOrUndefined(value)) {
      let o = value.tracker_obj;
      this.last_zuendung = o.last_zuendung;
      this.gps_recording_enabled = o.gps_recording_enabled;
      this.typ = o.typ;
      this.identification = o.identification;
      this.offset_working_hours = o.offset_working_hours;
      this.offset_odometer = o.offset_odometer;
      this.last_voltage = o.last_voltage;
      this.last_position = o.last_position;
      this.last_address = o.last_address;
      this.last_odometer = o.last_odometer;
      this.last_working_hours = o.last_working_hours;
      this.sms_password = o.sms_password;
      this.sms_use_id = o.sms_use_id;
    }
    this.mfc();
  }

  get gps_recording_enabled(): boolean {
    return this.data.gps_recording_enabled;
  }

  set gps_recording_enabled(value: boolean) {
    this.data.gps_recording_enabled = value;
    this.mfc();
  }
get mitschnitt_aktiv(): boolean {
    return this.data.mitschnitt_aktiv;
  }

  set mitschnitt_aktiv(value: boolean) {
    this.data.mitschnitt_aktiv = value;
    this.mfc();
  }
  get identification(): string {
    return this.data.identification;
  }

  set identification(value: string) {
    this.data.identification = value;
    this.mfc();
  }

  get last_address(): string {
    return this.data.last_address;
  }

  set last_address(value: string) {
    this.data.last_address = value;
    this.mfc();
  }

  get last_odometer(): number {
    return this.data.last_odometer;
  }

  set last_odometer(value: number) {
    this.data.last_odometer = value;
    this.mfc();
  }

  get last_position(): number {
    return this.data.last_position;
  }

  set last_position(value: number) {
    this.data.last_position = value;
    this.mfc();
  }

  get last_seen(): string | Date {
    return this.data.last_seen;
  }

  set last_seen(value: string | Date) {
    this.data.last_seen = value;
    this.mfc();
  }

  get last_voltage(): number {
    return this.data.last_voltage;
  }

  set last_voltage(value: number) {
    this.data.last_voltage = value;
    this.mfc();
  }

  get last_voltage_zp(): string | Date {
    return this.data.last_voltage_zp;
  }

  set last_voltage_zp(value: string | Date) {
    this.data.last_voltage_zp = value;
    this.mfc();
  }

  get last_working_hours(): number {
    return this.data.last_working_hours;
  }

  set last_working_hours(value: number) {
    this.data.last_working_hours = value;
    this.mfc();
  }

  get last_zuendung(): boolean {
    return this.data.last_zuendung;
  }

  set last_zuendung(value: boolean) {
    this.data.last_zuendung = value;
    this.mfc();
  }

  get offset_odometer(): number {
    return this.data.offset_odometer;
  }

  set offset_odometer(value: number) {
    this.data.offset_odometer = parseInt('' + value, 10);
    if (METool.isNaN(this.data.offset_odometer)) {
      this.data.offset_odometer = 0;
    }
    this.mfc();
  }

  get offset_working_hours(): number {
    return this.data.offset_working_hours;
  }

  set offset_working_hours(value: number) {
    this.data.offset_working_hours = parseInt('' + value, 10);
    if (METool.isNaN(this.data.offset_working_hours)) {
      this.data.offset_working_hours = 0;
    }
    this.mfc();
  }

  get sms_password(): string {
    return this.data.sms_password;
  }

  set sms_password(value: string) {
    this.data.sms_password = value;
    this.mfc();
  }

  get sms_use_id(): boolean {
    return this.data.sms_use_id;
  }

  set sms_use_id(value: boolean) {
    this.data.sms_use_id = value;
    this.mfc();
  }

  get typ(): number {
    return this.data.typ;
  }

  set typ(value: number) {
    this.data.typ = value;
    this.mfc();
  }

  mfc() {
    if (this.cd !== null && this.cd !== undefined) {
      this.cd.markForCheck();
    }
  }

  clear(): void {
    this.data.gps_recording_enabled = false;
    this.data.identification = '';
    this.data.last_address = '';
    this.data.last_odometer = 0;
    this.data.last_position = 0;
    this.data.last_seen = null;
    this.data.last_voltage = 0;
    this.data.last_voltage_zp = null;
    this.data.last_working_hours = 0;
    this.data.last_zuendung = false;
    this.data.offset_odometer = 0;
    this.data.offset_working_hours = 0;
    this.data.sms_password = '';
    this.data.sms_use_id = false;
    this.data.typ = 0;
    this.data.tracker = null;
    this.data.vehicle = null;
    this.mfc();
  }

  copyFrom(src: ModifyTrackerOptions) {
    this.clear();
    Object.keys(src.data).forEach((k) => {
      this.data[k] = src.data[k];
    });
    this.mfc();
  }
}
