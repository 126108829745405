import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'me-date-time-field',
  template: `
    <div class="form-group row mb-1">
      <label class="col-{{ labelwidth }} col-form-label" *ngIf="labelwidth > 0">
        {{ label }}
      </label>
      <div class="col-{{ 12 - labelwidth }}">
        <me-date-time-picker
          #rangeStartInput
          [value]="_value"
          (valueChange)="doSelect($event)"
          [inputVisible]="true"
          [disabled]="disabled"
          [panelRight]="false"
          [showFooter]="false"
          [hasSeconds]="hasSeconds"
          [dateOnly]="dateOnly"
          [timeOnly]="timeOnly"
          [name]="'filter1' + label"
          [size]="size"
          [displayFormat]="displayFormat"
          [nullable]="nullable"
        ></me-date-time-picker>
      </div>
    </div>
  `,
  styles: [
    `
      .form-group {
        margin-bottom: unset;
      }

      :host .addon button:first-child {
        border-left: none;
        padding-left: 5px;
        padding-right: 5px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MEDateTimeFieldComponent implements OnInit {
  @Input() size = null;

  public _value: Date = null;

  @Input() displayFormat = 'LLL';

  @Input() label = 'Datum';
  @Input() id = '';
  @Input() labelwidth = 3;

  @Input() dateOnly = false;
  @Input() timeOnly = false;
  @Input() disabled = false;
  @Input() nullable = true;
  @Input() hasSeconds = false;

  @Input()
  set value(v: Date) {
    if (this._value !== v) {
      this._value = v;
      this.valueChange.emit(v);
      this.cd.markForCheck();
    }
  }

  @Output() valueChange = new EventEmitter<Date>();

  get value(): Date {
    return this._value;
  }

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.id === '') {
      this.id =
        'tf' +
        Md5.hashStr(
          'medatetimefield-' + this.label + Math.random() + '-' + Math.random()
        );
    }
  }

  doSelect(date: Date) {
    this._value = date;
    this.valueChange.emit(date);
    this.cd.markForCheck();
  }
}
