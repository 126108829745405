<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'phone']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Telefonanlage"
></me-area-heading>

<me-card>
    <button routerLink="/telefonanlage/contacts" class="btn btn-sm btn-primary">Kontakte bearbeiten</button>
</me-card>