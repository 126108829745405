<div class="w-100">
    <div class="alert alert-info">
        Die hier aufgeführten Einträge sind aktuell aktiv, sollte ein Eintrag fehlen ist dieser offline.
    </div>
    <div
            class="d-flex align-items-start justify-content-between"
            *ngIf="liste !== null && liste !== undefined">
        <div class="flex-fill">
            <me-data-table
                #vpn1Table
                [rowActionsAsButtons]="true"
                [tableActionsAsButtons]="true"
                [data]="liste.asDataTableRows()"
                [selectable]="false"
                [singleSelect]="false"
                [hideCheckboxes]="true"
                actionsicon="icofont icofont-navigation-menu"
            >
                <me-data-table-column
                        [label]="'Name'"
                        [field]="'name'"></me-data-table-column>
                <me-data-table-column
                        [label]="'IP-Adresse'"
                        [field]="'real_address'"></me-data-table-column>
                <me-data-table-column
                        [label]="'virtuelle Adresse'"
                        [field]="'virtual_address'"></me-data-table-column>
                <ng-template #connectedsinceTemplate let-p="item" let-row="row">
                    {{fDate(p, 'medium')}}
                </ng-template>
                <me-data-table-column
                        [label]="'aktiv seit'"
                        [field]="'connected_since'"
                        [template]="connectedsinceTemplate"
                ></me-data-table-column>
                <ng-template #bytesTemplate let-p="item" let-row="row">
                    <div title="empfangen">
                        {{ p | mebytes }} <i class="far fa-arrow-to-left"></i>
                    </div>
                    <div title="gesendet">
                        {{ row.data.bytes_sent | mebytes }} <i class="far fa-arrow-from-left"></i>
                    </div>
                </ng-template>

                <me-data-table-column
                        [label]="'Traffic'"
                        [field]="'bytes_received'"
                        [template]="bytesTemplate"></me-data-table-column>

                <me-data-table-column
                        [label]="'Client-ID'"
                        [field]="'client_id'"></me-data-table-column>

                <me-data-table-table-extra-header>
                    <me-pagination
                            [total]="liste.total"
                            [page]="liste.page"
                            [perpage]="liste.size"
                            (pageChange)="liste.setPage($event)"
                            (pageCountChange)="liste.processPageCountChangedEvent($event)"
                            (queryChange)="liste.processQueryChangedEvent($event)"
                            settingsid="openvpn-status"
                            [settingsService]="benutzerService"
                            [showSearch]="true"></me-pagination>
                </me-data-table-table-extra-header>
            </me-data-table>
        </div>
    </div>
</div>