import { MEConverterTool } from '../tools';
import { TNullableBoolean, TNullableString } from '../tools/types';
import { IMEActionResponse } from './imeaction-response';

export class MEActionResponse implements IMEActionResponse {
  message: TNullableString = null;
  success: TNullableBoolean = false;
  params: any | null = {};

  static fromRawActionResponse(ra: IMEActionResponse): MEActionResponse {
    const a: MEActionResponse = new MEActionResponse();
    a.message = ra.message || '';
    a.success = MEConverterTool.toBoolean(ra.success || false);
    a.params = ra.params || {};
    return a;
  }

  static error(message: string) {
    let a = new MEActionResponse();
    a.message = message;
    a.success = false;
    a.params = {};
    return a;
  }
}
