import { MEGenericEntity } from '../../../maennl-commons';
import {
  TNullableNumber,
  TNullableString,
} from '../../../maennl-commons/tools/types';
import { IGruppe } from './gruppe.interface';
import { INetworkAddress } from './network-address.interface';

export class Gruppe extends MEGenericEntity<IGruppe> implements IGruppe {
  code: TNullableString = null;
  description: TNullableString = null;
  id: TNullableNumber = null;
  name: TNullableString = null;

  _members: INetworkAddress[] | null = null;

  constructor() {
    super();
  }
}
