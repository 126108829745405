<div
        class="d-flex align-items-start justify-content-between"
        *ngIf="thListe !== null && thListe !== undefined">
    <div class="flex-fill">
        <me-data-table
            #wgInterfaceTable
            [rowActionsAsButtons]="true"
            [tableActionsAsButtons]="true"
            [data]="thListe.asDataTableRows()"
            [selectable]="false"
            [singleSelect]="false"
            [hideCheckboxes]="true"
            actionsicon="icofont icofont-navigation-menu">
            <me-data-table-column
                    [label]="'ID'"
                    [field]="'id'"></me-data-table-column>
            <ng-template #tenant let-p="item" let-row="row">
                <div title="Mandant">
                    [{{p}}]{{row.data._tenant_info}}
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'Mandant'"
                    [field]="'tenant'"
                    [template]="tenant"></me-data-table-column>
            <ng-template #hostname let-p="item" let-row="row">
                <div title="Hostname">
                    <a href="//{{p}}" target="_blank">{{p}}</a>
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'URL'"
                    [field]="'hostname'"
                    [template]="hostname"></me-data-table-column>
            <me-data-table-column
                    [label]="'API Host?'"
                    [field]="'is_api_host'"
                    type="boolean"
            ></me-data-table-column>
            <me-data-table-column
                    [label]="'aktiv'"
                    [field]="'enabled'"
                    type="boolean"
            ></me-data-table-column>

            <me-data-table-action
                    label="Eintrag hinzufügen"
                    [icon]="'far fa-plus'"
                    (perform)="addEntry()"
            ></me-data-table-action>

            <me-data-table-rowaction
                    (perform)="editTenantHost($event)"
                    icon="far fa-pencil"
                    label="Bearbeiten"
                    title="Eintrag bearbeiten"
            ></me-data-table-rowaction>

            <me-data-table-rowaction
                    (perform)="removeEntry($event)"
                    icon="far fa-trash"
                    label="Löschen"
                    title="Eintrag löschen"
            ></me-data-table-rowaction>

            <me-data-table-table-extra-header>
                <me-pagination
                        [total]="thListe.total"
                        [page]="thListe.page"
                        [perpage]="thListe.size"
                        (pageChange)="thListe.setPage($event)"
                        (pageCountChange)="thListe.processPageCountChangedEvent($event)"
                        (queryChange)="thListe.processQueryChangedEvent($event)"
                        settingsid="tenant-hosts-info"
                        [settingsService]="benutzerService"
                        [showSearch]="false"></me-pagination>
            </me-data-table-table-extra-header>
        </me-data-table>
    </div>
</div>