import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {DateTime} from 'luxon';
import {ToastrService} from 'ngx-toastr';
import {BenutzerService} from '../../benutzer/services/benutzer.service';
import {MEDataTableColumnComponent} from '../../maennl-commons/data-table/columns';
import {
    MEDataTableRow,
    TMEDTGetExtraRowClassesCallback,
} from '../../maennl-commons/data-table/rows';
import {MELocalizedComponent} from '../../maennl-commons/localized-component';
import {MEConverterTool, METool} from '../../maennl-commons/tools';
import {CDRCall} from '../common/cdrcall';
import {CDRCallList} from '../common/cdrcall-list';
import {Contact} from '../common/contact';
import {IContact} from '../common/icontact';
import {IContactListEntry} from '../common/icontact-list-entry';
import {Rufnummer} from '../common/rufnummer';
import {SimpleCDRCallFilter} from '../common/simple-cdrcall-filter';
import {
    TelefonanlageService,
    knownNumbers,
} from '../services/telefonanlage.service';

@Component({
    selector: 'app-last-calls',
    templateUrl: './last-calls.component.html',
    styleUrls: ['./last-calls.component.scss'],
})
export class LastCallsComponent extends MELocalizedComponent implements OnInit {
    spam: Contact = new Contact();

    verbindungen = new CDRCallList().setWindowSize(20) as CDRCallList;
    that = this;
    loading = false;
    public rufnummer = (
        value: any,
        column: MEDataTableColumnComponent,
        row: MEDataTableRow<CDRCall>,
        what: String
    ) => {
        if (
            value === null ||
            value === undefined ||
            value === '' ||
            value === 'null'
        ) {
            return '';
        }
        const rn = '' + value;
        if (rn === 'null') {
            return '';
        }
        if (knownNumbers.has(rn)) {
            return knownNumbers.get(rn);
        }
        return rn;
    };
    rowClassGetter: TMEDTGetExtraRowClassesCallback<CDRCall> = (
        row: MEDataTableRow<CDRCall>,
        idx
    ) => {
        if (
            row !== null &&
            row !== undefined &&
            row.data !== null &&
            row.data !== undefined &&
            row.data.failed_call !== null &&
            row.data.failed_call !== undefined
        ) {
            if (row.data.failed_call) {
                return 'row-danger';
            }
        }
        return '';
    };

    constructor(
        public cd: ChangeDetectorRef,
        public telefonanlageService: TelefonanlageService,
        public benutzerService: BenutzerService,
        public toastr: ToastrService
    ) {
        super();
        this.that = this;
        this.telefonanlageService
            .listContactsWithNumbers(99999, 0, [], null, '')
            .subscribe(
                l => {
                    l.data.forEach((v: IContactListEntry, idx) => {
                        if (
                            METool.isDefined(v) &&
                            METool.isDefined(v.nummer) &&
                            !METool.isEmpty(v.nummer)
                        ) {
                            if (!knownNumbers.has(('' + v.nummer).trim())) {
                                let n = '';
                                if (!METool.isEmpty(v.displayText)) {
                                    n = n + ('' + v.displayText).trim() + ' / ';
                                }
                                n = n + ('' + v.info).trim();
                                knownNumbers.set(('' + v.nummer).trim(), n);
                            }
                        }
                    });
                },
                () => {
                }
            );
    }

    ngOnInit(): void {
        this.telefonanlageService.getContact(71, true).subscribe(n => {
            if (n !== null && n !== undefined) {
                this.spam = Contact.fromResult(n);
            }
        });
        this.verbindungen.onUpdateRequired.subscribe((list: CDRCallList) => {
            this.loading = true;
            this.cd.markForCheck();
            this.telefonanlageService
                .listLastCalls(
                    list.size,
                    list.calcOffset(),
                    list.order,
                    list.simpleFilter,
                    list.getQuery()
                )
                .subscribe(
                    l => {
                        if (l !== null && l !== undefined) {
                            list.doPopulateFromListResult(l, 'call_start', 'desc', false);
                        }
                        this.loading = false;
                        this.cd.markForCheck();
                        (this.verbindungen.simpleFilter as SimpleCDRCallFilter).date_until =
                            moment().endOf('day').toDate();
                    },
                    () => {
                        this.loading = false;
                        this.cd.markForCheck();
                    }
                );
        });

        this.selectPreset(-1);
    }

    typed(row: MEDataTableRow<CDRCall>): CDRCall[] {
        if (METool.isNullOrUndefined(row)) {
            return [];
        }
        return [row.data];
    }

    public selectPreset(p: number) {
        const now = moment();
        switch (p) {
            case 0:
                now.startOf('month');
                (this.verbindungen.simpleFilter as SimpleCDRCallFilter).date_from =
                    now.toDate();
                now.endOf('month');
                (this.verbindungen.simpleFilter as SimpleCDRCallFilter).date_until =
                    now.toDate();
                break;
            case 1:
                now.subtract(1, 'month');
                now.startOf('month');
                (this.verbindungen.simpleFilter as SimpleCDRCallFilter).date_from =
                    now.toDate();
                now.endOf('month');
                (this.verbindungen.simpleFilter as SimpleCDRCallFilter).date_until =
                    now.toDate();
                break;
            default:
                let s = moment();
                s.subtract(7, 'days');
                (this.verbindungen.simpleFilter as SimpleCDRCallFilter).date_from =
                    s.toDate();
                now.endOf('day');
                (this.verbindungen.simpleFilter as SimpleCDRCallFilter).date_until =
                    now.toDate();
        }
    }

    reprocessCalls() {
        this.toastr.info('Starte Verarbeitung der Anrufe der letzten 2 Monate');

        this.telefonanlageService.reprocessCalls().subscribe(() => {
            this.toastr.success(
                'Die Anrufe der letzten 2 Monate wurden neu verarbeitet.'
            );
            this.verbindungen.reload();
        });
    }

    isSpam(origin_cid: string) {
        let n = this.spam.rufnummern.find(r => {
            return r.nummer.trim() === origin_cid;
        });

        return n !== undefined && n !== null && n.nummer.trim() === origin_cid;
    }

    mark_as_spam(origin_cid: string) {
        if (
            origin_cid === null ||
            origin_cid === undefined ||
            origin_cid.trim() === ''
        ) {
            return;
        }
        if (
            window.confirm(
                'Soll ' + origin_cid + ' wirklich als SPAM markiert werden?'
            )
        ) {
            this.telefonanlageService.getContact(71, true).subscribe({
                next: (contact: IContact) => {
                    let c = Contact.fromResult(contact);
                    if (c === null || c === undefined) {
                        return;
                    }
                    c.rufnummern.push(
                        new Rufnummer(
                            null,
                            c.id,
                            'aufgenommen: ' + DateTime.now().toISO(),
                            origin_cid
                        )
                    );
                    this.telefonanlageService.store(c).subscribe({
                        next: r => {
                            if (r.success) {
                                this.spam = c;
                                this.toastr.success(origin_cid + ' als SPAM markiert');
                            } else {
                                this.toastr.error('Fehler beim Markieren als SPAM');
                            }
                        },
                        error: () => {
                            this.toastr.error('Fehler beim Markieren als SPAM');
                        },
                    });
                },
                error: () => {
                    this.toastr.error(
                        'Konnte SPAM-Kontakt nicht finden und aktualisieren'
                    );
                },
            });
        }
    }
}
