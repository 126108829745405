import {ITenantHosts} from "./tenant-hosts.interface";
import {MEGenericEntity} from "../../../maennl-commons";
import {TNullableBoolean, TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";

export class TenantHosts
    extends MEGenericEntity<ITenantHosts>
    implements ITenantHosts {

    id: TNullableNumber = null;
    tenant: TNullableNumber = null;
    hostname: TNullableString = null;
    rang: TNullableNumber = null;
    is_api_host: TNullableBoolean = null;
    enabled: TNullableBoolean = null;
    _tenant_info: TNullableString = null;
}