import {MissingTerminalTransactions} from "./missing-terminal-transactions.class";
import {TNullableMissingTerminalTransactions} from "./missing-terminal-transactions.types";
import {MEGenericEntityFields} from "../../../maennl-commons";

export class MissingTerminalTransactionsImpl {

    static fromResult(
        entity:MissingTerminalTransactions,
        createifNull=false
    ): TNullableMissingTerminalTransactions {
        if(entity===null||entity===undefined){
            if (createifNull) {
                new MissingTerminalTransactions();
            }
            return null;
        }

        const instance= new MissingTerminalTransactions();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongFields([
                    'id',
                    'tenant',
                    'terminal_id',
                    'belgenummer',
                    'tries'
                ])
                .addDateFields([
                    'queued',
                    'next_try',
                ])
                .addBooleanFields([
                    '_in_progress',
                ])
                .addTextFields([
                    '_station_display_text',
                    '_tenant_info',
                    '_terminal_display_text',
                ])
        );

        return instance;
    }
}