import { IGenericObject, METool } from '../../maennl-commons';
import { TNullableString } from '../../maennl-commons/tools/types';

export interface CheckParams extends IGenericObject {
  jdbc: TNullableString;
  query: TNullableString;
  compare: TNullableString;
  mountpoint: TNullableString;
  username: TNullableString;
  password: TNullableString;
  column: TNullableString;
  service: TNullableString;
  processname: TNullableString;
  container: TNullableString;
  path: TNullableString;
  warn_percent: TNullableString;
  ip: TNullableString;
}

export function default_check_params(): CheckParams {
  return {
    compare: '',
    jdbc: '',
    query: '',
    mountpoint: '',
    column: '',
    password: '',
    username: '',
    service: '',
    processname: '',
    container: '',
    path: '',
    warn_percent: '',
    ip: ''
  } as CheckParams;
}

export function check_check_params(params: CheckParams) {
  Object.keys(default_check_params()).forEach((k) => {
    if (METool.isNullOrUndefined(params[k])) {
      params[k] = '';
    }
    if ('' + params[k] === 'null') {
      params[k] = '';
    }
  });
}
