import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { CheckHistory } from './check-history';
import { ICheckHistory } from './icheck-history';
import { SimpleCheckHistoryFilter } from './simple-check-history-filter';

export class CheckHistoryList extends MERemoteList<CheckHistory> {
  __classname = 'CheckHistoryList';
  __instance = '';

  static fromListResult(res: IMEListResult<ICheckHistory>): CheckHistoryList {
    const newList = new CheckHistoryList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleCheckHistoryFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'zeitpunkt',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return CheckHistory.fromResult(entity);
  }
}
