import {TenantHosts} from "./tenant-hosts.class";
import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {SimpleTenantHostsFilter} from "./tenant-hosts.filter";
import {TenantHostsImpl} from "./tenant-hosts.impl";
import {ITenantHosts} from "./tenant-hosts.interface";

export class TenantHostsList
    extends MERemoteList<TenantHosts> {
    __classname: string = 'TenantHostsList';
    __instance = '';

    static fromListResult(
        res:IMEListResult<ITenantHosts>
    ): TenantHostsList {
        const newList= new TenantHostsList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun:boolean=false,timeout:number=400) {
        super();
        this.simpleFilter= new SimpleTenantHostsFilter();
        this.initList(autorun,timeout);
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'tenant',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true) {
        super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    }

    convertEntity(entity:any):any {
        return TenantHostsImpl.fromResult(entity);
    }
}