import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { ICheckResult } from './icheck-result';

export class CheckResult
  extends MEGenericEntity<ICheckResult>
  implements ICheckResult
{
  id = 0;
  code = '';
  info = '';

  static fromResult(
    entity: ICheckResult,
    createIfNull: boolean = false
  ): CheckResult {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new CheckResult();
      }
      return null;
    }

    const instance = new CheckResult();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('code')
        .addTextField('info')
    );
    return instance;
  }

  constructor() {
    super();
  }
}
