import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SysHealthChecksListComponent } from '../sys-health-checks-list/sys-health-checks-list.component';
import { SysHealthWindowsServiceEditDialogComponent } from '../sys-health-windows-service-edit-dialog/sys-health-windows-service-edit-dialog.component';
import { MEGenericModalComponent } from '../../../maennl-commons/megeneric-modal';
import { Check } from '../../common/check';
import { SysHealthSystemDserviceEditDialogComponent } from '../sys-health-system-dservice-edit-dialog/sys-health-system-dservice-edit-dialog.component';
import { noop } from '../../../maennl-commons/tools';
import { SysHealthDockerEditDialogComponent } from '../sys-health-docker-edit-dialog/sys-health-docker-edit-dialog.component';
import { SysHealthDatabaseEditDialogComponent } from '../sys-health-database-edit-dialog/sys-health-database-edit-dialog.component';

@Component({
  selector: 'app-sys-health-probleme-tab',
  templateUrl: './sys-health-probleme-tab.component.html',
  styleUrls: ['./sys-health-probleme-tab.component.scss']
})
export class SysHealthProblemeTabComponent implements OnInit, OnDestroy {
  __classname = 'SysHealthProblemeTabComponent';
  __instance = '';

  @Input() id = 'SysHealthProblemeTabComponent';
  @ViewChild(SysHealthChecksListComponent, { static: false }) dienstChecks =
    null;
  private tabActive = false;

  constructor(public modalService: NgbModal) {}

  ngOnInit() {
    noop();
  }

  ngOnDestroy(): void {
    this.onDeactivate();
    noop();
  }

  onActivate() {
    this.tabActive = true;
    if (this.dienstChecks !== null && this.dienstChecks !== undefined) {
      this.dienstChecks.onActivate();
    }
  }

  onDeactivate() {
    this.tabActive = false;
    if (this.dienstChecks !== null && this.dienstChecks !== undefined) {
      this.dienstChecks.onDeactivate();
    }
  }

  addEditDienst($event: Check = null) {
    if ($event.machine === null || $event.machine === undefined) {
      return;
    }
    if (
      $event.machine.machineType === null ||
      $event.machine.machineType === undefined
    ) {
      return;
    }
    if ($event.serviceType.code === 'service') {
      SysHealthWindowsServiceEditDialogComponent.open(
        this.modalService,
        SysHealthWindowsServiceEditDialogComponent,
        $event,
        MEGenericModalComponent.SIZE_MEDIUM,
        {
          machine: $event.machine
        }
      ).then(() => {
        if (this.dienstChecks !== null && this.dienstChecks !== undefined) {
          this.dienstChecks.refreshList();
        }
      }, noop);
    }
    if ($event.serviceType.code === 'systemd') {
      SysHealthSystemDserviceEditDialogComponent.open(
        this.modalService,
        SysHealthSystemDserviceEditDialogComponent,
        $event,
        MEGenericModalComponent.SIZE_MEDIUM,
        {
          machine: $event.machine
        }
      ).then(() => {
        if (this.dienstChecks !== null && this.dienstChecks !== undefined) {
          this.dienstChecks.refreshList();
        }
      }, noop);
    }
    if ($event.serviceType.code === 'docker') {
      SysHealthDockerEditDialogComponent.open(
        this.modalService,
        SysHealthDockerEditDialogComponent,
        $event,
        MEGenericModalComponent.SIZE_MEDIUM,
        {
          machine: $event.machine
        }
      ).then(() => {
        if (this.dienstChecks !== null && this.dienstChecks !== undefined) {
          this.dienstChecks.refreshList();
        }
      }, noop);
    }
    if ($event.serviceType.code === 'database') {
      SysHealthDatabaseEditDialogComponent.open(
        this.modalService,
        SysHealthDatabaseEditDialogComponent,
        $event,
        MEGenericModalComponent.SIZE_MEDIUM,
        {
          machine: $event.machine
        }
      ).then(() => {
        if (this.dienstChecks !== null && this.dienstChecks !== undefined) {
          this.dienstChecks.refreshList();
        }
      }, noop);
    }
  }
}
