import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';
import { Sonde } from '../common/sonde';
import { ISonde } from '../common/isonde';
import { SondeService } from '../services/sonde.service';
import { MEPubSubService, MESubscriptionHelper } from '../../maennl-commons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MEDTSondeCellRendererComponent } from '../controls/medtsonde-cell-renderer/medtsonde-cell-renderer.component';
import { PeilungList } from '../common/peilung-list';
import { TMECellRendererValueFormatter } from '../../maennl-commons/data-table/renderer/types';
import { Peilung } from '../common/peilung';
import { MEDataTableRow } from '../../maennl-commons/data-table/rows';
import { MERichError } from '../../maennl-commons/tools';
import { PeilungenDiagrammComponent } from '../peilungen-diagramm/peilungen-diagramm.component';
import { MEResultMetaSort } from '../../maennl-commons/mesort-buttons';
import { IMEListResult } from '../../maennl-commons/mecommon-list';
import { IPeilung } from '../common/ipeilung';
import { PeilungenFilterDialogComponent } from '../controls/peilungen-filter-dialog/peilungen-filter-dialog.component';
import { SimplePeilungFilter } from '../common/simple-peilung-filter';
import * as moment from 'moment';
import { DiagrammZoomInfo } from '../peilungen-diagramm/diagramm-zoom-info';
import { MEKitchenTimer } from '../../maennl-commons/timers';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-peilungen-popup',
  templateUrl: './peilungen-popup.component.html',
  styleUrls: ['./peilungen-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeilungenPopupComponent
  extends MEGenericModalComponent<Sonde>
  implements OnInit, OnDestroy
{
  public get dataLoading(): boolean {
    return this._dataLoading;
  }

  public set dataLoading(value: boolean) {
    this._dataLoading = value;
  }

  total = -1;

  get sondeBezeichnung(): string {
    return this.entity.getBezeichnung();
  }
  __classname = 'PeilungenPopupComponent';
  __instance = '';

  public sondeRenderer = MEDTSondeCellRendererComponent;
  diagramm: PeilungenDiagrammComponent = null;

  zoomTimer = new MEKitchenTimer();

  public peilungen: PeilungList = new PeilungList();
  public displaymode = 1;

  private tabActive = false;
  private _dataLoading = false;
  private stopLoading = false;
  public standFormatter: TMECellRendererValueFormatter<Peilung> = (
    value,
    column,
    row: MEDataTableRow<Peilung>
  ) => {
    if (value === null || value === undefined) {
      return '';
    }
    if (column.field === 'cstand1' || column.field === 'cstand2') {
      return (
        this.fNum(value, '1.1-1') +
        ' ' +
        (row as MEDataTableRow<Peilung>).data.sonde.einheit
      ).trim();
    }
    return value;
  };

  constructor(
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    public pubsub: MEPubSubService,
    public sondeService: SondeService,
    public benutzerService: BenutzerService,
    public cd: ChangeDetectorRef
  ) {
    super(activeModal, pubsub);
  }

  public initEntity(src: ISonde): Sonde {
    if (this.peilungen.simpleFilter === null) {
      this.peilungen.simpleFilter = new SimplePeilungFilter();
    }
    (this.peilungen.simpleFilter as SimplePeilungFilter).zeitpunkt = [
      moment().subtract(14, 'days').startOf('day').toDate(),
      null
    ];

    return Sonde.fromResult(src, true);
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.zoomTimer.subscribe(() => {
        if (this.displaymode === 1) {
          if (this._dataLoading) {
            this.stopLoading = true;
            this.zoomTimer.rewind();
          } else {
            this.buildChartData();
          }
        }
      })
    );
    MESubscriptionHelper.add(
      this,
      this.peilungen.onUpdateRequired.subscribe((list: PeilungList) => {
        this.sondeService
          .listPeilungen(
            this.entity,
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.total = l.meta.total;
              this.cd.markForCheck();
            },
            (e) => {
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste der verfügbaren Peilungen konnte nicht geladen werden'
              );
            }
          );
      }, undefined)
    );

    this.peilungen.start();
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.peilungen.release();
    super.ngOnDestroy();
  }

  setMode(number: number) {
    this.displaymode = number;
    this.cd.markForCheck();
  }

  diagrammNeedsData($event) {
    this.diagramm = $event;
    this.loadDataForChart();
  }

  loadDataForChart(pg = 0, init = true) {
    if (this.diagramm === null) {
      return;
    }
    if (init) {
      this.diagramm.clear();
    }
    if (this.displaymode !== 1) {
      return;
    }
    if (this.stopLoading) {
      this._dataLoading = false;
      return;
    }
    const batchSize = 500;
    this.sondeService
      .listPeilungen(
        this.entity,
        init ? 1 : batchSize,
        pg * batchSize,
        [new MEResultMetaSort('zeitpunkt', 'asc')],
        this.peilungen.simpleFilter,
        this.peilungen.getQuery()
      )
      .subscribe((l: IMEListResult<IPeilung>) => {
        if (this.stopLoading) {
          this._dataLoading = false;
          return;
        }
        if (l.meta.count > 0) {
          if (init) {
            if (l.meta.total > 15000) {
              // maximal 5000 Werte laden
              pg = (l.meta.total - 15000) / batchSize;
            }
            this.total = l.meta.total;
            this.cd.markForCheck();
            if (pg < 0) {
              pg = 0;
            }
            this._dataLoading = true;
            this.loadDataForChart(pg, false);
          } else {
            l.data.forEach((v: IPeilung) => {
              if (v != null) {
                if (!this.stopLoading) {
                  this.diagramm.addBuchung(v);
                }
              }
            });
            if (l.meta.count >= l.meta.size || l.meta.page < l.meta.pages) {
              if (!this.stopLoading) {
                this.loadDataForChart(pg + 1, false);
              } else {
                this._dataLoading = false;
              }
            } else {
              this._dataLoading = false;
            }
            this.diagramm.createSeries();
            this.cd.markForCheck();
          }
        }
      });
    this.cd.markForCheck();
  }

  buildChartData() {
    this.stopLoading = false;
    this.loadDataForChart();
  }

  setFilter() {
    PeilungenFilterDialogComponent.open(
      this.modalService,
      PeilungenFilterDialogComponent,
      this.peilungen.simpleFilter,
      MEGenericModalComponent.SIZE_SMALL
    ).then(
      (f: SimplePeilungFilter) => {
        (this.peilungen.simpleFilter as SimplePeilungFilter).zeitpunkt =
          f.zeitpunkt;
        if (this.displaymode === 0) {
          this.peilungen.reload();
        }
        if (this.displaymode === 1) {
          this.buildChartData();
        }
      },
      () => {}
    );
  }

  diagrammZoomed(zoom: DiagrammZoomInfo) {
    this.stopLoading = true;

    const range1 = [this.entity.firstPeilung, this.entity.lastPeilung];
    const range2 = [range1[0], range1[0]];
    if (
      this.peilungen.simpleFilter.zeitpunkt !== null &&
      this.peilungen.simpleFilter.zeitpunkt !== undefined &&
      Array.isArray(this.peilungen.simpleFilter.zeitpunkt) &&
      this.peilungen.simpleFilter.zeitpunkt.length > 1
    ) {
      range2[0] = this.peilungen.simpleFilter.zeitpunkt[0];
      range2[1] = this.peilungen.simpleFilter.zeitpunkt[1];
    }

    if (range1[0] === null || range1[0] === undefined) {
      range1[0] = new Date();
    }
    if (range1[1] === null || range1[1] === undefined) {
      range1[1] = new Date();
    }

    if (range2[0] === null || range2[0] === undefined) {
      range2[0] = range1[0];
    }
    if (range2[1] === null || range2[1] === undefined) {
      range2[1] = new Date();
      if (range1[1] > range2[1]) {
        range2[1] = range1[1];
      }
    }

    const size1 = Math.abs(moment(range1[1]).diff(range1[0]));
    let size2 = Math.abs(moment(range2[1]).diff(range2[0]));

    if (size1 === 0 || size2 === 0) {
      return;
    }

    if (size2 > size1) {
      size2 = size1;
    }

    let percent = (size2 / size1) * 100.0;

    if (zoom.zoomDirection === 'in') {
      percent = percent * 0.5;
    } else if (zoom.zoomDirection === 'out') {
      percent = percent * 1.5;
    }

    const newSize = (size1 * percent) / 100.0;

    const newRange = [new Date(), new Date()];

    if (zoom.centerAt === null) {
      const delta = (newSize - size2) / 2;
      newRange[0] = moment(range2[0]).subtract(delta, 'milliseconds').toDate();
      newRange[1] = moment(range2[1]).add(delta, 'milliseconds').toDate();
    } else {
      const p = moment(range2[0])
        .add(size2 * zoom.centerAt, 'milliseconds')
        .toDate();
      newRange[0] = moment(p)
        .subtract(newSize / 2, 'milliseconds')
        .toDate();
      newRange[1] = moment(p)
        .add(newSize / 2, 'milliseconds')
        .toDate();
    }
    if (newRange[1] > range1[1]) {
      newRange[0] = moment(newRange[0])
        .subtract(Math.abs(moment(newRange[1]).diff(range1[1])), 'milliseconds')
        .toDate();
      newRange[1] = range1[1];
    }
    if (newRange[0] < range1[0]) {
      newRange[1] = moment(newRange[1])
        .add(Math.abs(moment(newRange[0]).diff(range1[0])), 'milliseconds')
        .toDate();
      newRange[0] = range1[0];
    }
    if (newRange[0] < range1[0]) {
      newRange[0] = range1[0];
    }
    if (newRange[1] > range1[1]) {
      newRange[1] = range1[1];
    }

    newRange[0] = moment(newRange[0]).startOf('day').toDate();
    newRange[1] = moment(newRange[1]).endOf('day').toDate();

    this.peilungen.simpleFilter.zeitpunkt = newRange;
    this.zoomTimer.rewind();
  }
}
