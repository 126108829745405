import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, EventEmitter,
    Input,
    OnDestroy,
    OnInit, Output, TemplateRef,
    ViewChild, ViewContainerRef,
    ViewEncapsulation
} from "@angular/core";
import {Tenant} from "../../../commons/tenant";
import {TenantSettingsList} from "../../../commons/tenant-settings.list";
import {TenantService} from "../../../commons/tenant.service";
import {BenutzerService} from "../../../../../benutzer/services/benutzer.service";
import {ToastrService} from "ngx-toastr";
import {TenantSettingsService} from "../../../commons/tenant-settings.service";
import {MESubscriptionHelper} from "../../../../../maennl-commons";

@Component({
    selector: 'app-tab-tenants-editor-settings',
    templateUrl: './tab-tenants-editor-settings.component.html',
    styleUrls: ['./tab-tenants-editor-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TabTenantsEditorSettingsComponent
    implements OnInit, OnDestroy {
    @Input() entity: Tenant = null;
    @ViewChild('settings', {read: ViewContainerRef}) settingsRef: ViewContainerRef;
    @ViewChild('settingtemp', {read: TemplateRef}) settingRef: TemplateRef<any>;
    @Output() entityChanged = new EventEmitter<Tenant>();
    settingList: TenantSettingsList = new TenantSettingsList();
    entitySettings = null;
    inputName = '';
    inputValue = '';

    private _tabActive = false;

    constructor(
        public cd: ChangeDetectorRef,
        public service: TenantService,
        public settingService: TenantSettingsService,
        public benutzerService: BenutzerService,
        public toastr: ToastrService,
    ) {
    }

    public get tabActive(): boolean {
        return this._tabActive;
    }

    @Input()
    public set tabActive(value: boolean) {
        if (this._tabActive !== value) {
            this._tabActive = value;
            this.cd.markForCheck();
        }
    }

    onActivate() {
        this.tabActive = true;
        MESubscriptionHelper.add(
            this,
            this.settingList.onUpdateRequired.subscribe(() => {
                if (this.settingList.loading) {
                    return;
                }
                this.settingList.loading = true;
                this.settingService
                    .list(
                        this.settingList.size,
                        this.settingList.calcOffset(),
                        this.settingList.order,
                        this.settingList.simpleFilter,
                        this.settingList.getQuery()
                    )
                    .subscribe({
                        next: l => {
                            this.settingList.loading = false;
                            this.settingList.doPopulateFromListResult(l);
                            this.cd.markForCheck();
                        },
                        error: () => {
                            this.settingList.clear();
                        },
                    });
                this.cd.markForCheck();
            })
        );
        this.settingList.start();
        this.refresh_entitySettings();
        this.cd.markForCheck();
    }

    onDeactivate() {
        this.tabActive = false;
        this.settingList.disableAutoReload();
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.settingList.release();
        MESubscriptionHelper.release(this);
    }

    refresh_view() {
        if (this.settingsRef !== null && this.settingsRef !== undefined) {
            this.settingsRef.clear();
            this.settingsRef.createEmbeddedView(this.settingRef);
        }
    }

    add_setting(name: string, value: string) {
        if (
            this.entity === null ||
            this.entity === undefined ||
            name === null ||
            name === undefined ||
            name.trim() === '' ||
            value === null ||
            value === undefined ||
            value.trim() === ''
        ) {
            if(name.trim()===''){
                this.toastr.error('Der Name der Einstellung ist leer!');
            }
            if(value.trim()===''){
                this.toastr.error('Der Wert der Einstellung ist leer!');
            }
            return;
        }
        if (this.entity.settings.includes(name)) {
            this.edit_setting(name, value);
            return
        }
        this.service.add_setting(this.entity.id, name, value).subscribe({
            next: r => {
                if (r.success) {
                    this.reloadEntity();
                } else {
                    this.toastr.error(
                        'Das Hinzufügen der Einstellung ist fehlgeschlagen'
                    )
                }
            },
            error: () => {
                this.toastr.error('Das Hinzufügen der Einstellung ist fehlgeschlagen');
            }
        });
    }

    edit_setting(setting: string, value: string) {
        if (
            this.entity === null ||
            this.entity === undefined ||
            setting === null ||
            setting === undefined ||
            setting.trim() === '' ||
            value === null ||
            value === undefined ||
            value.trim() === ''
        ) {
            return;
        }
        this.service.edit_setting(this.entity.id, setting, value).subscribe({
            next: r => {
                if (r.success) {
                    this.reloadEntity();
                } else {
                    this.toastr.error(
                        'Das Hinzufügen der Einstellung ist fehlgeschlagen'
                    )
                }
            },
            error: () => {
                this.toastr.error('Das Hinzufügen der Einstellung ist fehlgeschlagen');
            }
        });
    }

    remove_setting(setting: string) {
        if (
            this.entity === null ||
            this.entity === undefined ||
            setting === null ||
            setting === undefined ||
            setting.trim() === ''
        ) {
            return;
        }
        this.service.remove_setting(this.entity.id, setting).subscribe({
            next: r => {
                if (r.success) {
                    this.reloadEntity();
                } else {
                    this.toastr.error(
                        'Das Entfernen der Einstellung ist fehlgeschlagen'
                    )
                }
            },
            error: () => {
                this.toastr.error('Das Entfernen der Einstellung ist fehlgeschlagen');
            }
        });
    }

    setting_in_list(settings: string) {
        if (
            this.entity === null ||
            this.entity === undefined ||
            this.entity.settings === null ||
            this.entity.settings === undefined ||
            this.entity.settings.trim() === ''
        ) {
            return false;
        }
        return this.settingList.data.map(it => it.name).includes(settings);
    }

    setting_in_entity(settings: string) {
        if (
            this.entity === null ||
            this.entity === undefined ||
            this.entity.settings === null ||
            this.entity.settings === undefined ||
            this.entity.settings.trim() === '{}'
        ) {
            return false;
        }
        return this.entitySettings[settings] !== undefined&&this.entitySettings[settings] !== null;
    }

    save() {
        this.entity.settings = JSON.stringify(this.entitySettings);
        this.service.store(this.entity).subscribe({
            next: (r) => {
                if (r.success) {
                    this.reloadEntity();
                } else {
                    this.toastr.error('Fehler beim Speichern der Daten');
                }
            },
            error: () => {
                this.toastr.error('Fehler beim Speichern der Daten');
            }
        });
    }

    entityChangedHandler(entity:Tenant){
        this.entityChanged.emit(entity);
    }

    reloadEntity() {
        this.service.get(this.entity.id).subscribe((t: Tenant) =>{
            if(this.entity.id===t.id){
                this.entity=t;
                this.entityChangedHandler(this.entity);
                this.refresh_entitySettings();
                this.refresh_view();
            }
            this.cd.markForCheck();
        });
    }

    refresh_entitySettings() {
        if(this.entity.settings === null || this.entity.settings === undefined||this.entity.settings.trim()==='') {
            this.entity.settings = '{}';
        }
        this.entitySettings=JSON.parse(this.entity.settings);
    }

    settingIsBoolean(key: string, value: string) {
        return key.trim()==='true'||key.trim()==='false'
    }

    trackByFn(index: number, item: any) {
        return index;
    }
}