<me-data-table
        *ngIf="liste!==null && liste!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="liste.asDataTableRows()"
        (onSortChanged)="liste.processSortChangedEvent($event)"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        [extraRowClasses]="rowClassGetter"
>

    <me-data-table-column
            [label]="'Box'"
            [field]="'boxName'" [sortable]="true"
    >
    </me-data-table-column>
    <me-data-table-column
            [label]="'Boxnummer'" [sortable]="true"

            [field]="'boxNummer'"></me-data-table-column>
    <me-data-table-column
            [label]="'Sperrliste Import'" [sortable]="true"

            [field]="'datumSperrlisteImport'"
            type="date"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>
    <me-data-table-column
            [label]="'Datum Sperrliste'" [sortable]="true"

            [field]="'datumSperrliste'"
            type="date"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>
    <me-data-table-column
            [label]="'Version Box'" [sortable]="true"

            [field]="'versionBox'"
    ></me-data-table-column>
    <me-data-table-column
            [label]="'Start Automat'" [sortable]="true"

            [field]="'automatStart'"
            type="date"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
            [classgetter]="cellClassGetter"
    ></me-data-table-column>
    <me-data-table-column
            [label]="'Version Automat'" [sortable]="true"

            [field]="'automatVersion'"
            [classgetter]="cellClassGetter"

    ></me-data-table-column>

    <me-data-table-table-extra-header>
        <me-pagination
                settingsid="syshealth-boxliste"
                [settingsService]="benutzerService"
                [pagecounts]="[10,20,50,100,500,1000]"
                [total]="liste.total"
                [page]="liste.page"
                [perpage]="liste.size"
                (pageChange)="liste.setPage($event)"
                (pageCountChange)="liste.processPageCountChangedEvent($event)"
                [showSearch]="true"
                (queryChange)="liste.processQueryChangedEvent($event)"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
