import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'me-icon',
  template: `
    <i
      class="{{ class }}"
      *ngIf="class !== null && class !== undefined && class !== ''"
    ></i>
    <ng-content></ng-content>
  `
})
export class MEIconComponent implements OnInit {
  @Input() class = null;

  constructor() {}

  ngOnInit() {}
}
