import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { BoxlisteListEntry } from './boxliste-list-entry';
import { IBoxlisteListEntry } from './iboxliste-list-entry';
import { SimpleBoxlisteListEntryFilter } from './simple-boxliste-list-entry-filter';

export class BoxlisteListEntryList extends MERemoteList<BoxlisteListEntry> {
  __classname = 'BoxlisteListEntryList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IBoxlisteListEntry>
  ): BoxlisteListEntryList {
    const newList = new BoxlisteListEntryList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleBoxlisteListEntryFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'boxNummer',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): BoxlisteListEntry {
    return BoxlisteListEntry.fromResult(entity);
  }
}
