import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../../maennl-commons';
import { IRBPlan } from './irbplan';

export class RBPlan extends MEGenericEntity<IRBPlan> implements IRBPlan {
  code: string = null;
  display_text: string = null;
  id: number = null;

  constructor() {
    super();
  }

  static fromResult(entity: IRBPlan, createIfNull: boolean = false): RBPlan {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new RBPlan();
      }
      return null;
    }

    const instance = new RBPlan();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('code')
        .addTextField('display_text'),
      (e) => {}
    );
    return instance;
  }
}
