<div class="d-flex justify-content-between flex-nowrap">
    <div class="controller">
    </div>
    <div class="chunks">
        <div class="d-flex justify-content-start flex-nowrap chunk" *ngFor="let chunk of chunks">
            <div class="me-5 w-50 text-start hexBytes d-flex flex-nowrap justify-content-start">
                <div class='byte' *ngFor="let b of chunk.hexBytes">
                    <div class="text-dark bg-secondary">{{ b }}</div>
                </div>
            </div>
            <div class="text-start ps-5 asciiBytes d-flex flex-nowrap justify-content-start">
                <div class='byte' *ngFor="let b of chunk.asciiBytes;let idx=index">
                    <div class="text-dark bg-warning" *ngIf="chunk.bytes[idx]<0">NaN</div>
                    <div class="text-light bg-info"
                          *ngIf="chunk.bytes[idx]>=0 && chunk.bytes[idx]<32">{{ byteName(chunk.bytes[idx]) }}</div>
                    <div class="text-dark bg-light" *ngIf="chunk.bytes[idx]>=32">{{ chunk.asciiBytes[idx] }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="endpunkt">
    </div>
</div>
