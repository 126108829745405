import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {Applications} from "./applications.class";
import { SimpleApplicationsFilter } from "./applications.filter";
import {ApplicationsImpl} from "./applications.impl";
import {IApplications} from "./applications.interface";

export class ApplicationsList
    extends MERemoteList<Applications> {
    __classname: string = 'ApplicationsList';
    __instance = '';

    static fromListResult(
        res:IMEListResult<IApplications>
    ): ApplicationsList {
        const newList = new ApplicationsList();
        newList.populateFromListResult(res);
        return newList;
    }

    constructor(autorun:boolean=false,timeout:number=400) {
        super();
        this.simpleFilter = new SimpleApplicationsFilter();
        this.initList(autorun,timeout);
    }

    public doPopulateFromListResult(
        res:IMEListResult<any>,
        defaultSort: string = 'code',
        defaultOrder: string = 'asc',
        clearFilter: boolean = true
    ) {
        super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
    }

    convertEntity(entity:any):any {
        return ApplicationsImpl.fromResult(entity);
    }
}