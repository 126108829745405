<div
        class="d-flex align-items-start justify-content-between"
        *ngIf="terminalListe !== null && terminalListe !== undefined">
    <div class="flex-fill">
        <me-data-table
            #wgInterfaceTable
            [rowActionsAsButtons]="true"
            [tableActionsAsButtons]="true"
            [data]="terminalListe.asDataTableRows()"
            [selectable]="false"
            [singleSelect]="false"
            [hideCheckboxes]="true"
            actionsicon="icofont icofont-navigation-menu">
            <me-data-table-column
                    [label]="'ID'"
                    [field]="'id'"></me-data-table-column>
            <ng-template #tenant let-p="item" let-row="row">
                <div title="Mandant">
                    [{{p}}]{{row.data._tenant_info}}
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'Mandant'"
                    [field]="'tenant'"
                    [template]="tenant"></me-data-table-column>
            <me-data-table-column
                    [label]="'Terminal Info'"
                    [field]="'display_text'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Standort'"
                    [field]="'_station_display_text'"></me-data-table-column>
            <ng-template #failure let-p="item" let-row="row">
                <div title="zeitpunkt">
                    {{fDate(row.data.error_state_change,'medium') }}
                </div>
                <div title="Fehlermeldung">
                    {{p}}
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'Fehler'"
                    [field]="'last_failure'"
                    [template]="failure"
            ></me-data-table-column>

            <me-data-table-rowaction
                    (perform)="resetLastFailure($event)"
                    label="Fehler resetten"
                    title="Fehler resetten"
                    icon="far fa-undo"
            ></me-data-table-rowaction>

            <me-data-table-table-extra-header>
                <me-pagination
                        [total]="terminalListe.total"
                        [page]="terminalListe.page"
                        [perpage]="terminalListe.size"
                        (pageChange)="terminalListe.setPage($event)"
                        (pageCountChange)="terminalListe.processPageCountChangedEvent($event)"
                        (queryChange)="terminalListe.processQueryChangedEvent($event)"
                        settingsid="terminal-malfunctioning"
                        [settingsService]="benutzerService"
                        [showSearch]="false"></me-pagination>
            </me-data-table-table-extra-header>
        </me-data-table>
    </div>
</div>