import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MEConfirmDialogComponent} from '../../../maennl-commons/meconfirm-dialog';
import {MEGenericModalComponent} from '../../../maennl-commons/megeneric-modal';
import {MEPubSubService} from '../../../maennl-commons/services';
import {MERichError, METool} from '../../../maennl-commons/tools';
import {Sim} from '../../../router/common/sim';
import {CSDServerClient} from '../commons/csdserver-client';
import {ICSDServerClient} from '../commons/icsdserver-client';
import {CSDServerService} from '../services/csdserver.service';

@Component({
    selector: 'app-client-editor',
    templateUrl: './client-editor.component.html',
    styleUrls: ['./client-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientEditorComponent
    extends MEGenericModalComponent<CSDServerClient>
    implements OnInit, OnDestroy {
    __classname = 'ClientsEditorComponent';

    public initEntity(src: ICSDServerClient): CSDServerClient {
        return CSDServerClient.fromResult(src, true);
    }

    constructor(
        public activeModal: NgbActiveModal,
        public pubsub: MEPubSubService,
        public csdServerService: CSDServerService,
        public modalService: NgbModal,
        public cd: ChangeDetectorRef
    ) {
        super(activeModal, pubsub);
    }

    public canSave() {
        if (METool.isNullOrUndefined(this.entity)) {
            return false;
        }

        if (METool.isEmpty(this.entity.label)) {
            return false;
        }

        if (METool.isNullOrUndefined(this.entity.typ)) {
            return false;
        }
        if (METool.isEmpty(this.entity.typ.label)) {
            return false;
        }

        if (
            this.entity.typ.label === 'dtu' &&
            METool.isEmpty(this.entity.machineId)
        ) {
            return false;
        }

        return true;
    }

    public save() {
        if (this.canSave()) {
            this.csdServerService.storeClient(this.entity).subscribe(
                (r) => {
                    if (r.success) {
                        this.activeModal.close(this.entity);
                    } else {
                        MERichError.throw(
                            'Fehler beim Speichern',
                            'Beim Speichern des Datensatzes ist ein Fehler aufgetreten.'
                        );
                    }
                },
                () => {
                    MERichError.throw(
                        'Fehler beim Speichern',
                        'Beim Speichern des Datensatzes ist ein Fehler aufgetreten.'
                    );
                }
            );
        }
    }

    public delete() {
        if (!METool.isNullOrUndefined(this.entity.id) && this.entity.id > 0) {
            MEConfirmDialogComponent.display(
                this.modalService,
                'Löschen bestätigen',
                'Sollen dieser Client sowie alle damit verbundenen Brücken wirklich unwiderruflich gelöscht werden?',
                'Ja, jetzt löschen',
                'icofont icofont-delete'
            ).then(() => {
                this.csdServerService.removeClient(this.entity).subscribe(
                    (r) => {
                        if (r.success) {
                            this.activeModal.close();
                        } else {
                            MERichError.throw(
                                'Fehler beim Löschen',
                                'Beim Löschen des Clients ist ein Fehler aufgetreten.'
                            );
                        }
                    },
                    () => {
                        MERichError.throw(
                            'Fehler beim Löschen',
                            'Beim Löschen des Clients ist ein Fehler aufgetreten.'
                        );
                    }
                );
            });
        }
    }

    public setSim(sim: Sim) {
        if (METool.isNullOrUndefined(this.entity)) {
            return;
        }

        if (METool.isNullOrUndefined(sim)) {
            this.entity.simId = 0;
            this.entity.simTitle = '';
            this.entity.simRufnummer = '';
        } else {
            this.entity.simId = sim.id;
            this.entity.simTitle = sim.label;
            this.entity.simRufnummer = sim.msidsn;
        }
        this.entity.sim = sim;
        this.cd.markForCheck();
    }
}
