import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { SysHealthTestPingsDisplayComponent } from '../sys-health-test-pings-display/sys-health-test-pings-display.component';
import { noop } from '../../../maennl-commons/tools';
import { MESubscriptionHelper } from '../../../maennl-commons/services';

@Component({
  selector: 'app-sys-health-test-pings-tab',
  templateUrl: './sys-health-test-pings-tab.component.html',
  styleUrls: ['./sys-health-test-pings-tab.component.scss']
})
export class SysHealthTestPingsTabComponent implements OnInit, OnDestroy {
  __classname = 'SysHealthTestPingsTabComponent';
  __instance = '';
  @Input() id = 'SysHealthTestPingsTab';
  tabActive = false;
  @ViewChild(SysHealthTestPingsDisplayComponent, { static: false }) disp = null;

  constructor(public cd: ChangeDetectorRef) {
    noop();
  }

  ngOnInit() {
    noop();
  }

  onDeactivate() {
    this.tabActive = false;
    if (this.disp !== null && this.disp !== undefined) {
      this.disp.onDeactivate();
    }
  }

  onActivate() {
    this.tabActive = true;
    if (this.disp !== null && this.disp !== undefined) {
      this.disp.onActivate();
    }
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.onDeactivate();
    MESubscriptionHelper.release(this);
  }
}
