import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {MELocalizedComponent, MESubscriptionHelper} from "../../../maennl-commons";
import {OpenVPNStatusList} from "../commons/openvpn-status.list";
import {OpenvpnStatusService} from "../commons/openvpn-status.service";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {ToastrService} from "ngx-toastr";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";


@Component({
    selector: 'app-tab-vpn1',
    templateUrl: './tab-vpn1.component.html',
    styleUrls: ['./tab-vpn1.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabVPN1Component
    extends MELocalizedComponent
    implements OnInit, OnDestroy, AfterViewInit {
    @Input() id = ''
    liste = new OpenVPNStatusList();

    constructor(
        public cd: ChangeDetectorRef,
        public service: OpenvpnStatusService,
        public benutzerService: BenutzerService,
        public modalService: NgbModal,
        public toastr: ToastrService
    ) {
        super()
    }
    private _tabActive = false;
    public get tabActive(): boolean {
        return this._tabActive;
    }

    @Input()
    public set tabActive(value: boolean) {
        if (this._tabActive !== value) {
            this._tabActive = value;
            this.cd.markForCheck();
            if (this._tabActive) {
               this.liste.start();
            }
        }
    }

    ngOnInit(): void {
        MESubscriptionHelper.add(
            this,
            this.liste.onUpdateRequired.subscribe(() => {
                this.cd.markForCheck();
                if (this.liste.loading) {
                    return;
                }
                this.liste.loading = true;
                this.service
                    .list(
                        this.liste.size,
                        this.liste.calcOffset(),
                        this.liste.order,
                        this.liste.simpleFilter,
                        this.liste.getQuery()
                    )
                    .subscribe({
                        next: l => {
                            this.liste.doPopulateFromListResult(l);
                            this.liste.loading = false;
                            this.cd.markForCheck();
                        },
                        error: () => {
                            this.liste.clear();
                            this.liste.loading = false;
                        }
                    });
                this.cd.markForCheck();
            })
        );
    }

    onActivate() {
        this.tabActive = true;
    }

    onDeactivate() {
        this.tabActive = false;
    }

    ngOnDestroy(): void {
        this.liste.release();
        MESubscriptionHelper.release(this);
    }

    public ngAfterViewInit(): void {
    }
}
