import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { MEConverterTool, METool } from '../../../maennl-commons';
import { ER3Chunk } from './er3-chunk';

@Component({
  selector: 'app-er3-packaged-display',
  templateUrl: './er3-packaged-display.component.html',
  styleUrls: ['./er3-packaged-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ER3PackagedDisplayComponent implements OnInit {
  chunks: ER3Chunk[] = [];
  origin = [];

  _data = '';

  private _flip_sides = true;

  public showOrigin = false;

  get flip_sides(): boolean {
    return this._flip_sides;
  }

  @Input()
  set flip_sides(value: boolean) {
    if (this._flip_sides !== value) {
      this._flip_sides = value;
      this.build();
      this.cd.markForCheck();
    }
  }

  constructor(public cd: ChangeDetectorRef) {}

  @Input()
  set data(v: string) {
    this._data = v;
    this.build();
  }

  build(): void {
    const v = this._data;
    this.chunks = [];
    if (!METool.isNullOrUndefined(v)) {
      const bytes: string[] = v.match(/.{2}/g);
      this.origin = bytes;

      if (bytes[0] === '40' && bytes.length > 6) {
        // Datenpaket
        let currentChunk = new ER3Chunk();
        currentChunk.knownDirection = parseInt(bytes[1], 16);
        currentChunk.flip_sides = this.flip_sides;
        let pos = 6;
        while (pos < bytes.length - 1) {
          pos++;
          const oneByte = bytes[pos];
          const numByte = parseInt(oneByte, 16);
          switch (numByte) {
            case 0xaa: // Freigabe
            case 0x6a: // Vorwahl/TD
            case 0x5a: // Summierzähler
            case 0xa6: // Anfrage Tankdaten
            case 0xa9: // ZV ziehen
            case 0x66: // sperren
            case 0x69: // zv stecken
              if (currentChunk.isComplete()) {
                this.chunks.push(currentChunk);
                currentChunk = new ER3Chunk();
                currentChunk.knownDirection = parseInt(bytes[1], 16);
                currentChunk.flip_sides = this.flip_sides;
              }
              currentChunk.add(numByte);
              break;
            default:
              currentChunk.add(numByte);
          }
        }
        if (currentChunk.hasBytes()) {
          this.chunks.push(currentChunk);
        }
      } else {
        const chunk = new ER3Chunk();
        chunk.knownDirection = 3;
        chunk.flip_sides = this.flip_sides;
        let pos = -1;
        while (pos < bytes.length - 1) {
          pos++;
          const oneByte = bytes[pos];
          const numByte = parseInt(oneByte, 16);
          chunk.add(numByte);
        }
        this.chunks.push(chunk);
      }
    }
    this.cd.markForCheck();
  }

  ngOnInit(): void {}

  fNum(num: number, nks: number) {
    return MEConverterTool.formatNum(num, nks);
  }

  is_bit_set(bf: any, bit: number) {
    return MEConverterTool.isBitSet(bf, bit);
  }
}
