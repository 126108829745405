import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ModifyTrackerOptions } from './modify-tracker-options';
import { VehicleTrackerService } from '../commons/vehicle-tracker.service';
import {
  MEConfirmDialogComponent,
  MEGenericModalComponent,
  MEPubSubService,
  METool
} from '../../../maennl-commons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { VehicleService } from '../../vehicles/commons/vehicle.service';

@Component({
  selector: 'app-modify-tracker-popup',
  templateUrl: './modify-tracker-popup.component.html',
  styleUrls: ['./modify-tracker-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModifyTrackerPopupComponent
  extends MEGenericModalComponent<ModifyTrackerOptions>
  implements OnInit, OnDestroy
{
  entity: ModifyTrackerOptions = null;

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public cd: ChangeDetectorRef,
    public toastr: ToastrService,
    public vehicleService: VehicleService,
    public vehicleTrackerService: VehicleTrackerService,
    public modalService: NgbModal
  ) {
    super(activeModal, pubsub);
  }

  public initEntity(src: ModifyTrackerOptions): ModifyTrackerOptions {
    const f = new ModifyTrackerOptions();
    if (src !== null && src !== undefined) {
      f.copyFrom(src);
    }
    return f;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.entity.cd = this.cd;
  }

  can_save() {
    if (METool.isNullOrUndefined(this.entity)) {
      return false;
    }
    if (METool.isNullOrUndefined(this.entity.tracker)) {
      return false;
    }
    if (METool.isNullOrUndefined(this.entity.tracker.id)) {
      return false;
    }

    if (METool.isEmpty(this.entity.identification)) {
      return false;
    }

    return true;
  }

  ok() {
    this.vehicleTrackerService
      .patch(this.entity.tracker.id, this.entity.data)
      .subscribe({
        next: (r) => {
          if (r.success) {
            this.toastr.success('Trackerdaten wurden gespeichert');
            super.ok();
          } else {
            this.toastr.error('Fehler beim Speichern der Trackerdaten');
          }
        },
        error: () => {
          this.toastr.error('Fehler beim Speichern der Trackerdaten');
        }
      });
  }

  remove() {
    MEConfirmDialogComponent.display(
      this.modalService,
      'Tracker löschen',
      'Soll dieser Tracker wirklich gelöscht werden? Sobald ein Tracker einen Datensatz sendet, wird dieser automatisch wieder angelegt. Das bestehende Fahrzeug bleibt trotz der Löschung im Mandanten erhalten.',
      'Ja, Tracker jetzt löschen',
      'far fa-trash'
    ).then(() => {
      this.vehicleTrackerService.delete(this.entity.tracker.id).subscribe({
        next: (r) => {
          if (r.success) {
            this.toastr.success('Tracker wurde gelöscht');
            this.cancel();
          } else {
            this.toastr.error('Fehler beim Löschen des Trackers');
          }
        },
        error: () => {
          this.toastr.error('Fehler beim Löschen des Trackers');
        }
      });
    });
  }
}
