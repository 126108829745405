import { Value } from './value';
import { DateTime } from 'luxon';

export class MEDiagrammTool {
  static cx(px: number, offset = 0) {
    return px + offset;
  }

  static cy(py: number, offset = 0, stageHeight = 500) {
    return stageHeight - (py + offset);
  }

  static calcX(
    point: Value,
    width: number,
    maxx: any = null,
    minx: any = null
  ) {
    if (maxx === minx) {
      return 0;
    }
    let dx = 0;
    let px = 0;

    if (point.pos instanceof Date) {
      dx = DateTime.fromJSDate(maxx).diff(
        DateTime.fromJSDate(minx)
      ).milliseconds;
      px = DateTime.fromJSDate(point.pos).diff(
        DateTime.fromJSDate(minx)
      ).milliseconds;
    } else {
      dx = parseFloat('' + maxx) - parseFloat('' + minx);
      px = parseFloat('' + point.pos) - parseFloat('' + minx);
    }
    if (dx === 0) {
      return 0;
    }
    return Math.floor((px * width) / dx);
  }

  static calcY(point: Value, height: number, max = 0, min = 0) {
    if (max === min) {
      return 0;
    }
    let dy = 0;
    let py = 0;
    dy = parseFloat('' + max) - parseFloat('' + min);
    py = parseFloat('' + point.value) - parseFloat('' + min);

    if (dy === 0) {
      return 0;
    }
    return Math.floor((py * height) / dy);
  }
}
