<div class="d-flex justify-content-between flex-nowrap">
    <div class="controller">
    </div>
    <div style="flex-grow: 100; width: 98%;">
        <ul class="chunks">
            <li *ngFor="let chunk of chunks" class="text-{{chunk.guessDirection(this.flip_sides)}} d-flex"
                [class.justify-content-start]="chunk.guessDirection(this.flip_sides)==='left'"
                [class.justify-content-center]="chunk.guessDirection(this.flip_sides)==='center'"
                [class.justify-content-end]="chunk.guessDirection(this.flip_sides)==='right'"
            >
                <div class="d-inline-flex chunk" [class.invalid-chunk]="!chunk.valid" [class.valid-chunk]="chunk.valid"
                     title="{{chunk.raw|splitTwo}}">
                    <div class="startcode" title="Startcode, Adresse: {{chunk.address }}" *ngIf="chunk.valid">{{
                        chunk.startcode
                        }}
                    </div>
                    <div class="sequenz" title="Sequenz, Position {{ chunk.position }}" *ngIf="chunk.valid">{{
                        chunk.sequenz
                        }}
                    </div>
                    <div class="commands" *ngIf="chunk.valid">
                        <ul>
                            <li *ngFor="let cmd of chunk.commands"
                                class="d-inline-flex cmd-container cmd-{{ cmd.getCommandClass() }}"
                                title="{{cmd.getCommandTyp()}}">
                                <div class="cmd">{{ cmd.cmd | splitTwo }}</div>
                                <div class="length">{{ cmd.length | splitTwo }}</div>
                                <div class="data" title="{{cmd.getDataTitle()}}">
                                    <span *ngFor="let b of cmd.getBytes()">{{b}}</span>
                                </div>
                            </li>
                        </ul>
                        <br style="clear: both"/>
                    </div>
                    <div class="raw-data" *ngIf="!chunk.valid" title="{{ chunk.raw | showReadable }}">
                        {{ chunk.raw | splitTwo }}
                        <br style="clear: both"/>
                    </div>
                    <div class="checksum" title="Checksumme" *ngIf="chunk.valid">{{ chunk.checksum | splitTwo }}</div>
                    <div class="end" title="Ende-Kennung" *ngIf="chunk.valid">{{ chunk.end | splitTwo }}</div>
                </div>
            </li>
        </ul>
    </div>
    <div class="endpunkt">
    </div>
</div>
