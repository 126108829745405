import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitTwo'
})
export class SplitTwoPipe implements PipeTransform {
  transform(value: string): any {
    const c: string[] = value.match(/.{1,2}/g);
    return c.join(' ');
  }
}
