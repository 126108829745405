import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  MEDataTableComponent,
  MERichError,
  MESubscriptionHelper
} from '../../../maennl-commons';
import { PreisSendungList } from '../commons/preis-sendung-list';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import { PreiseService } from '../services/preise.service';
import { NebenstelleRendererComponent } from '../nebenstelle-renderer/nebenstelle-renderer.component';
import { GesendetePreiseRendererComponent } from '../gesendete-preise-renderer/gesendete-preise-renderer.component';
import { PreistransferList } from '../commons/preistransfer-list';
import { TransferRendererComponent } from '../transfer-renderer/transfer-renderer.component';

@Component({
  selector: 'app-tab-preisuebermittlung',
  templateUrl: './tab-preisuebermittlung.component.html',
  styleUrls: ['./tab-preisuebermittlung.component.scss']
})
export class TabPreisuebermittlungComponent implements OnInit, OnDestroy {
  @ViewChild(MEDataTableComponent) tabelle: MEDataTableComponent;

  liste = new PreisSendungList();
  nebenstelleR = NebenstelleRendererComponent;
  preiseR = GesendetePreiseRendererComponent;
  transferR = TransferRendererComponent;

  private _tabActive = false;

  public get tabActive(): boolean {
    return this._tabActive;
  }

  @Input()
  public set tabActive(value: boolean) {
    if (this._tabActive !== value) {
      this._tabActive = value;
      this.cd.markForCheck();
      if (this._tabActive) {
        this.liste.start();
      }
    }
  }

  constructor(
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    public benutzerService: BenutzerService,
    public preiseService: PreiseService
  ) {}

  ngOnInit(): void {
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe((list: PreisSendungList) => {
        this.preiseService
          .listPreisSendungen(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.getQuery()
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
              list.data.forEach((v, idx) => {
                if (
                  v.datum_transfer !== null &&
                  v.datum_transfer !== undefined
                ) {
                  v.transfers_pending = true;
                  this.preiseService.listPreistransfers(v.id).subscribe(
                    (t) => {
                      const liste = PreistransferList.fromListResult(t);
                      v.transfers = liste.data;
                      v.transfers_pending = false;
                      this.cd.markForCheck();
                      if (this.tabelle !== null && this.tabelle !== undefined) {
                        this.tabelle.markForCheck();
                      }
                    },
                    () => {}
                  );
                } else {
                  v.transfers_pending = false;
                }
                this.cd.markForCheck();
              });
            },
            (e) => {
              console.log(e);
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste der Preis-Sendungen konnte nicht abgerufen werden.'
              );
            }
          );
      }, undefined)
    );
  }

  onActivate() {
    this.tabActive = true;
    console.log('activated');
  }

  onDeactivate() {
    this.tabActive = false;
    console.log('deactivated');
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
  }
}
