import { Pipe, PipeTransform } from '@angular/core';
import { MEConverterTool } from '../tools/meconverter-tool';

@Pipe({
  name: 'mebytes'
})
export class MEBytesPipe implements PipeTransform {
  public static transformBytes(
    bytes: number,
    decimals: number = 2,
    removeZeroDecimals: boolean = false
  ): any {
    return MEConverterTool.readableBytes(
      bytes,
      decimals,
      removeZeroDecimals
    ).replace('.', ',');
  }

  public transform(
    bytes: number,
    decimals: number = 2,
    removeZeroDecimals: boolean = false
  ): string {
    return MEBytesPipe.transformBytes(bytes, decimals, removeZeroDecimals);
  }
}
