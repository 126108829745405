import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {MEGenericModalComponent, MEPubSubService, METool} from "../../../../../maennl-commons";
import {WireguardClient} from "../../../commons/wireguard-client.class";
import {ToastrService} from "ngx-toastr";
import {WireguardClientService} from "../../../commons/wireguard-client.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {WireguardClientImpl} from "../../../commons/wireguard-client.impl";
import {WireguardInterface} from "../../../commons/wireguard-interface.class";

@Component({
    selector: 'app-wireguard-client-editor',
    templateUrl: './wireguard-client-editor.component.html',
    styleUrls: ['./wireguard-client-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WireguardClientEditorComponent
    extends MEGenericModalComponent<WireguardClient>
    implements OnInit,OnDestroy
{
    entity: WireguardClient = null;
    entityInterface:WireguardInterface=null;

    public initEntity(src: WireguardClient): WireguardClient {
        return WireguardClientImpl.fromResult(src,true);
    }


    constructor(
        public activeModal: NgbActiveModal,
        public pubsub: MEPubSubService,
        public service: WireguardClientService,
        public toastr: ToastrService,
        public cd: ChangeDetectorRef
    ) {
        super(activeModal, pubsub);
    }

    ngOnInit(){}

    save(){
        if(!METool.isNullOrUndefined(this.entityInterface)){
            this.entity.wg_interface=this.entityInterface.id;
        }
        this.service.store(this.entity).subscribe({
            next:(r)=>{
                if(r.success){
                    this.ok();
                } else {
                    this.toastr.error('Fehler beim Speichern der Daten');
                }
        },
        error:() =>{
           this.toastr.error('Fehler beim Speichern der Daten');
        }
        });
    }
}