import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-vpntelefonanlage',
  templateUrl: './open-vpntelefonanlage.component.html',
  styleUrls: ['./open-vpntelefonanlage.component.css']
})
export class OpenVPNTelefonanlageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
