import {
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { MEListOptionComponent } from '../../melist-option/melist-option.component';
import { MEConverterTool } from '../../tools';

@Component({
  selector: 'me-data-table-action',
  template: ''
})
export class MEDataTableActionComponent {
  private _id = '';

  public get id(): string {
    if (this._id === '' || this._id === null || this._id === undefined) {
      this._id = MEConverterTool.randomId();
    }
    return this._id;
  }

  @Input()
  public set id(value: string) {
    this._id = value;
  }

  @Input() public label = 'Befehl';
  @Input() public icon = '';
  @Input() title = '';
  @Input() type = '';

  @Output() public perform = new EventEmitter<any>();
  @ContentChildren(MEListOptionComponent)
  listOptions: QueryList<MEListOptionComponent>;

  private _classes = '';

  get classes(): string {
    if (this._classes === null || this._classes === undefined) {
      return '';
    }
    return this._classes;
  }

  @Input()
  set classes(value: string) {
    this._classes = value;
  }

  public performAction() {
    this.perform.emit(this);
  }

  public hasIcon(): boolean {
    return this.icon !== undefined && this.icon !== null && this.icon !== '';
  }

  isNormalAction() {
    return !this.isDropdown() && !this.isDropdownOptions();
  }

  isDropdown() {
    return this.type === 'dropdown';
  }
  isDropdownOptions() {
    return this.type === 'dropdown-options';
  }

  hasOptions() {
    return (
      this.listOptions !== null &&
      this.listOptions !== undefined &&
      this.listOptions.length > 0
    );
  }

  public getClasses(defClasses = 'nav-link') {
    if (this.classes === '') {
      return defClasses;
    }
    return this.classes;
  }
}
