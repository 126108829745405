import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Md5 } from 'ts-md5';
import { MEAddOnClickEvent } from '../../../maennl-commons/forms/events';
import { Sim } from '../../common/sim';

@Component({
  selector: 'app-sim-field',
  styles: [
    `
      :host .addon button:first-child {
        border-left: none;
        padding: 0 5px;
      }
    `
  ],
  template: `
    <me-generic-field
      [label]="label"
      [addonicon]="addonicon"
      [addonlabel]="addonlabel"
      [labelwidth]="labelwidth"
      [disabled]="disabled"
      [enableaddon]="enableaddon"
      [tabindex]="tabindex"
      [small]="small"
      (addonClick)="addOnClicked($event)"
    >
      <app-sim-picker
        [tabindex]="tabindex"
        [id]="id"
        [small]="small"
        [value]="value"
        (valueChange)="setValue($event)"
        [disabled]="disabled"
        [nullable]="nullable"
      ></app-sim-picker>
    </me-generic-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimFieldComponent implements OnInit {
  private _id = '';

  @Input() label = 'Sim auswählen';
  @Input() addonicon: any = [];
  @Input() addonlabel: any = [];
  @Input() labelwidth = 3;
  @Input() enableaddon = false;
  @Input() disabled = false;
  @Input() tabindex: number = null;
  @Input() small = false;
  @Input() nullable = true;

  @Output() addonClick = new EventEmitter<MEAddOnClickEvent>();
  @Output() valueChange = new EventEmitter<Sim>();

  private _value: Sim = null;

  public get value(): Sim {
    return this._value;
  }

  @Input()
  public set value(value: Sim) {
    this.setValue(value);
    this.cd.markForCheck();
  }

  setValue(value: Sim) {
    if (this._value !== value) {
      this._value = value;
      if (!this.disabled) {
        this.valueChange.emit(this._value);
      }
      this.cd.markForCheck();
    }
  }

  public get id(): string {
    if (this._id === '') {
      this._id =
        'tf' +
        Md5.hashStr('sim-' + this.label + Math.random() + '-' + Math.random());
    }
    return this._id;
  }

  @Input()
  public set id(value: string) {
    this._id = value;
  }

  public addOnClicked($event: MEAddOnClickEvent) {
    this.addonClick.emit($event);
  }

  constructor(public cd: ChangeDetectorRef) {}

  public ngOnInit(): void {}
}
