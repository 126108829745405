<me-data-table
        *ngIf="dienste!==null && dienste!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="dienste.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        #diensteTable

>

    <me-data-table-action
            *ngIf="showAddAction"
            [label]="'Hinzufügen'"
            (perform)="addService()"
    ></me-data-table-action>

    <me-data-table-column
            *ngIf="displayServiceTypeColumn"
            [label]="'Typ'"
            [field]="'serviceType'"
            [renderer]="serviceTypeR"
    ></me-data-table-column>
    <me-data-table-column
            *ngIf="displayMachineColumn"
            [label]="'Host'"
            [field]="'machine'"
            [renderer]="machineR"
    ></me-data-table-column>
    <me-data-table-column
            *ngIf="displayServiceColumn && !useExtendedInfo"
            [label]="serviceColumnLabel"
            [field]="'service'"
    ></me-data-table-column>
    <me-data-table-column
            *ngIf="!useExtendedInfo"
            [label]="'Info'" [field]="'info'"></me-data-table-column>
    <me-data-table-column
            *ngIf="useExtendedInfo"
            [label]="serviceColumnLabel"
            [field]="'info'"
            [renderer]="checkNameDisplayR"
    ></me-data-table-column>
    <me-data-table-column
            *ngFor="let c of extraColumns"
            [renderer]="c.renderer"
            [label]="c.label"
            [field]="c.field"
            [cfg]="c.cfg"
            [editable]="c.editable"
            [filterable]="c.filterable"
            [editor]="c.editor"
            [sortable]="c.sortable"
            [title]="c.title"
            [titleField]="c.titleField"
            [type]="c.type"
            [xtraClasses]="c.xtraClasses"
            [visible]="c.visible"
    ></me-data-table-column>
    <me-data-table-column [label]="'aktiv'" [field]="'checksEnabled'" [type]="'Boolean'"></me-data-table-column>
    <me-data-table-column [label]="'kein Alarm'" [field]="'noAlarm'" [type]="'Boolean'"></me-data-table-column>
    <me-data-table-column [label]="'letzter Check'"
                          [field]="'lastCheck'"
                          type="date"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>
    <me-data-table-column [label]="'Status'" field="result" [renderer]="statusRenderer"
    ></me-data-table-column>
    <me-data-table-column
            [label]="'Nachricht'"
            [field]="'message'"
    ></me-data-table-column>
    <me-data-table-column [label]="'Ergebnis'" [field]="'resultInfo'"
                          [template]="ergebnisTemplate" *ngIf="hasErgebnisTemplate()"
    >
    </me-data-table-column>
    <me-data-table-column [label]="'Status seit'"
                          [field]="'lastResultChange'"
                          type="date"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>
    <me-data-table-column [label]="'FC'" [title]="'Fehler-Anzahl (=Anzahl der fehlgeschlagenen Checks)'"
                          [field]="'failedCount'"></me-data-table-column>

    <me-data-table-rowaction [label]="'Edit'"
                             [title]="'Eintrag bearbeiten'"
                             (perform)="editService($event)"
                             [icon]="'icofont icofont-ui-edit'"
                             (checkEnabled)="checkAction('editService',$event)"
    ></me-data-table-rowaction>
    <me-data-table-rowaction [label]="'Dienst-Abbruch'"
                             [title]="'Dienst-Prozess töten'"
                             (perform)="killService($event)"
                             [icon]="'icofont icofont-skull-danger'"
                             (checkEnabled)="checkAction('killService',$event)"
    ></me-data-table-rowaction>
    <me-data-table-rowaction [label]="'Dienst-Neustart'"
                             [title]="'Dienst (neu) starten'"
                             (perform)="restartService($event)"
                             [icon]="'far fa-play-circle'"
                             (checkEnabled)="checkAction('restartService',$event)"
    ></me-data-table-rowaction>
    <me-data-table-rowaction [label]="'Test wiederholen'"
                             [title]="'Test wiederholen'"
                             (perform)="reCheck($event)"
                             [icon]="'fas fa-redo'"
    ></me-data-table-rowaction>

    <me-data-table-table-extra-header>
        <me-pagination
                [total]="dienste.total"
                [page]="dienste.page"
                [perpage]="dienste.size"
                (pageChange)="dienste.setPage($event)"
                (pageCountChange)="dienste.processPageCountChangedEvent($event)"
                [showSearch]="true"
                (queryChange)="dienste.processQueryChangedEvent($event)"
                settingsid="syshealth-checks-list"
                [settingsService]="benutzerService"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>

