import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { SysHealthRouterStatusDisplayComponent } from '../sys-health-router-status-display/sys-health-router-status-display.component';
import { noop } from '../../../maennl-commons/tools';
import { MESubscriptionHelper } from '../../../maennl-commons/services';

@Component({
  selector: 'app-sys-health-router-status-tab',
  templateUrl: './sys-health-router-status-tab.component.html',
  styleUrls: ['./sys-health-router-status-tab.component.scss']
})
export class SysHealthRouterStatusTabComponent implements OnInit, OnDestroy {
  __classname = 'SysHealthRouterStatusTabComponent';
  __instance = '';
  @Input() id = 'SysHealthRouterStatusTab';
  tabActive = false;
  @ViewChild(SysHealthRouterStatusDisplayComponent, { static: false }) disp =
    null;

  constructor(public cd: ChangeDetectorRef) {
    noop();
  }

  ngOnInit() {
    noop();
  }

  onDeactivate() {
    this.tabActive = false;
    if (this.disp !== null && this.disp !== undefined) {
      this.disp.onDeactivate();
    }
  }

  onActivate() {
    this.tabActive = true;
    if (this.disp !== null && this.disp !== undefined) {
      this.disp.onActivate();
    }
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.onDeactivate();
    MESubscriptionHelper.release(this);
  }
}
