import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  MEGenericModalComponent,
  MEPubSubService,
  METool
} from '../../../maennl-commons';
import { MoveVehicleOptions } from './move-vehicle-options';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleTrackerService } from '../commons/vehicle-tracker.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-move-vehicle-popup',
  templateUrl: './move-vehicle-popup.component.html',
  styleUrls: ['./move-vehicle-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoveVehiclePopupComponent
  extends MEGenericModalComponent<MoveVehicleOptions>
  implements OnInit, OnDestroy
{
  entity: MoveVehicleOptions = null;

  public initEntity(src: MoveVehicleOptions): MoveVehicleOptions {
    const f = new MoveVehicleOptions();
    if (src !== null && src !== undefined) {
      f.copyFrom(src);
    }
    return f;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public cd: ChangeDetectorRef,
    public vehicleTrackerService: VehicleTrackerService,
    public toastr: ToastrService
  ) {
    super(activeModal, pubsub);
  }

  ngOnInit() {
    super.ngOnInit();
    this.entity.cd = this.cd;
  }

  can_move() {
    if (METool.isNullOrUndefined(this.entity)) {
      return false;
    }
    if (METool.isNullOrUndefined(this.entity.tenant)) {
      return false;
    }
    if (METool.isNullOrUndefined(this.entity.tracker)) {
      return false;
    }

    if (this.entity.tenant < 1) {
      return false;
    }

    if (this.entity.tenant === this.entity.tracker.tenant) {
      return false;
    }

    return true;
  }

  ok() {
    this.vehicleTrackerService
      .moveTracker(this.entity.tracker.id, this.entity.data)
      .subscribe({
        next: (r) => {
          if (r.success) {
            this.toastr.success('Tracker wurde verschoben');
            super.ok();
          } else {
            this.toastr.error('Fehler beim Verschieben des Trackers');
          }
        },
        error: () => {
          this.toastr.error('Fehler beim Verschieben des Trackers');
        }
      });
  }
}
