import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { Benutzer } from './benutzer';
import { IBenutzer } from './ibenutzer';
import { SimpleBenutzerFilter } from './simple-benutzer-filter';

export class BenutzerList extends MERemoteList<Benutzer> {
  __classname = 'BenutzerList';
  __instance = '';

  static fromListResult(res: IMEListResult<IBenutzer>): BenutzerList {
    const newList = new BenutzerList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleBenutzerFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'periode',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return Benutzer.fromResult(entity);
  }
}
