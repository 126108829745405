import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MEPubSubService } from '../../maennl-commons/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AbrechnungVaroRechnungsserver } from '../common/abrechnung-varo-rechnungsserver';
import { MEGenericModalComponent } from '../../maennl-commons/megeneric-modal';

@Component({
  selector: 'app-abrechnung-varo-rechnungsserver-details',
  templateUrl: './abrechnung-varo-rechnungsserver-details.component.html',
  styleUrls: ['./abrechnung-varo-rechnungsserver-details.component.scss']
})
export class AbrechnungVaroRechnungsserverDetailsComponent
  extends MEGenericModalComponent<AbrechnungVaroRechnungsserver>
  implements OnInit, AfterViewInit
{
  public initEntity(
    src: AbrechnungVaroRechnungsserver
  ): AbrechnungVaroRechnungsserver {
    return AbrechnungVaroRechnungsserver.fromResult(src, true);
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService
  ) {
    super(activeModal, pubsub);
  }

  updatePreview() {
    const doc = (jQuery('#printpreview')[0] as HTMLIFrameElement).contentWindow
      .document;
    const $body = jQuery('html', doc);
    $body.html(
      '<html lang="de"><head><title>Abrechnung</title>' +
        '<body style="background-color: white!important;">&nbsp;</body></head>'
    );
    jQuery('head', $body).html(jQuery('head', window.document).html());
    jQuery('body', $body).html(jQuery('#previewData').html());
  }

  ngOnInit() {
    super.ngOnInit();
    this.updatePreview();
    setTimeout(() => {
      this.updatePreview();
    }, 1000);
  }

  ngAfterViewInit() {
    this.updatePreview();
  }

  print() {
    const t = document.title;
    this.updatePreview();
    document.title =
      'Anlage Abrechnung Varo Rechnungsserver Periode ' + this.entity.periode;
    window.frames['printpreview'].focus();
    window.frames['printpreview'].print();
    document.title = t;
  }
}
