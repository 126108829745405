export class MERichError {
  public __classname = 'MERichError';
  public __instance = '';

  public title = '';
  public message = '';

  static throw(title: string, message: string) {
    new MERichError(title, message).fire();
  }

  constructor(title: string, message: string) {
    this.title = title;
    this.message = message;
  }

  fire() {
    throw new Error(
      JSON.stringify({
        signature: 'RichErrorMessage',
        title: this.title,
        message: this.message
      })
    );
  }
}
