import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MELocalizedComponent } from '../../maennl-commons/localized-component';
import {
  MEDiagrammTool,
  Point,
  Serie,
  Value
} from '../../maennl-commons/svgtools';
import { DateTime } from 'luxon';
import { METool } from '../../maennl-commons/tools';
import { Sonde } from '../common/sonde';

@Component({
  selector: '[app-diagramm-marker]',
  template: `
    <svg:g *ngIf="isValid()">
      <svg:rect
        [attr.x]="mLeft"
        [attr.y]="area[0].y"
        [attr.width]="mRight - mLeft"
        [attr.height]="area[1].y - area[0].y"
        style="fill: #666666;"
        opacity="0.30"
      />
      <svg:rect
        *ngIf="hasTemperatur && tempArea.length > 1"
        [attr.x]="mLeft"
        [attr.y]="tempArea[0].y"
        [attr.width]="mRight - mLeft"
        [attr.height]="tempArea[1].y - tempArea[0].y"
        style="fill: #666666;"
        opacity="0.30"
      />
      <svg:g *ngIf="isValid() && points.length > 0">
        <svg:circle
          *ngFor="let p of points"
          [attr.cx]="p.x"
          [attr.cy]="p.y"
          r="4"
          fill="#FFFFFF"
          [attr.stroke]="p.color"
          style="stroke-width:2px"
        />
        <svg:g>
          <svg:rect
            [attr.x]="tTipLeft"
            [attr.y]="tTipTop"
            width="200"
            [attr.height]="tTipHeight"
            fill="#222222"
            opacity="0.9"
          />
          <svg:text
            [attr.x]="tTipLeft + 100"
            [attr.y]="tTipTop + 15"
            text-anchor="middle"
            style="font-style:normal;font-weight:bold;
                                font-size:12px;line-height:1.25;
                                font-family:sans-serif;letter-spacing:0px;
                                word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
          >
            {{ posLabel }}
          </svg:text>

          <svg:g *ngFor="let p of points; let idx = index">
            <svg:circle
              [attr.cx]="tTipLeft + 10"
              [attr.cy]="tTipTop + idx * 17 + 30"
              r="5"
              fill="#FFFFFF"
              [attr.stroke]="p.color"
              style="stroke-width:2px"
            />

            <svg:text
              [attr.x]="tTipLeft + 20"
              [attr.y]="tTipTop + idx * 17 + 35"
              text-anchor="start"
              style="font-style:normal;font-weight:bold;
                                font-size:10.58333302px;line-height:1.25;
                                font-family:sans-serif;letter-spacing:0px;
                                word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
            >
              {{ p.label }}
            </svg:text>
            <svg:text
              [attr.x]="tTipLeft + 190"
              [attr.y]="tTipTop + idx * 17 + 35"
              text-anchor="end"
              style="font-style:normal;font-weight:bold;
                                font-size:10.58333302px;line-height:1.25;
                                font-family:sans-serif;letter-spacing:0px;
                                word-spacing:0px;fill:#AAAAAA;fill-opacity:1;stroke:none;stroke-width:0.26458332"
            >
              {{ p.value }}
            </svg:text>
          </svg:g>
        </svg:g>
      </svg:g>
    </svg:g>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagrammMarkerComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy
{
  private _sonde: Sonde = null;

  @Input()
  set sonde(v: Sonde) {
    this._sonde = v;
    this.cd.markForCheck();
  }

  get einheit(): string {
    if (this._sonde === null || this._sonde === undefined) {
      return '';
    }
    return this._sonde.einheit;
  }

  @Input()
  yPos = 0;

  points: Point[] = [];

  get posLabel() {
    if (this._pos instanceof Date) {
      return (
        DateTime.fromJSDate(this._pos).toLocaleString(DateTime.DATE_MED) +
        ' ' +
        DateTime.fromJSDate(this._pos).toLocaleString(DateTime.TIME_24_SIMPLE)
      );
    }
    return '' + this._pos;
  }

  xpos = -100;

  get mLeft() {
    if (this.xpos < 0) {
      return -10000;
    }
    let x = this.xpos - 2;

    if (x < this._area[0].x) {
      x = this._area[0].x;
    }
    return x;
  }

  get tTipLeft() {
    if (this.xpos < 0) {
      return -10000;
    }
    if (this._area.length < 2) {
      return -10000;
    }

    const mitte = (this._area[0].x + this._area[1].x) / 2;

    if (this.xpos < mitte) {
      return this.mLeft + 20;
    }
    return this.mLeft - 222;
  }

  get tTipTop() {
    if (this.xpos < 0) {
      return -10000;
    }
    if (this._area.length < 2) {
      return -10000;
    }

    return this.yPos - this.tTipHeight / 2;
  }

  get tTipHeight() {
    return this.points.length * 17 + 25;
  }

  get mRight() {
    if (this.xpos < 0) {
      return -10000;
    }
    let x = this.xpos + 2;

    if (x > this._area[1].x) {
      x = this._area[1].x;
    }
    return x;
  }

  private _area: Point[] = [];
  private _tempArea: Point[] = [];
  private _hasTemperatur = false;
  private _series: Serie[] = [];

  private _pos: any = null;

  get pos(): any {
    return this._pos;
  }

  @Input()
  set pos(value: any) {
    this._pos = value;
    this.update();
  }

  get area(): Point[] {
    return this._area;
  }

  @Input()
  set area(value: Point[]) {
    this._area = value;
    this.update();
  }

  get tempArea(): Point[] {
    return this._tempArea;
  }

  @Input()
  set tempArea(value: Point[]) {
    this._tempArea = value;
    this.update();
  }

  get hasTemperatur(): boolean {
    return this._hasTemperatur;
  }

  @Input()
  set hasTemperatur(value: boolean) {
    this._hasTemperatur = value;
    this.update();
  }

  get series(): Serie[] {
    return this._series;
  }

  @Input()
  set series(value: Serie[]) {
    this._series = value;
    this.update();
  }

  constructor(public cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  isValid() {
    if (this._pos === null || this._pos === undefined) {
      return false;
    }

    if (
      this._series === null ||
      this._series === undefined ||
      !Array.isArray(this._series) ||
      this._series.length < 1
    ) {
      return false;
    }
    let found = false;
    this._series.forEach((s: Serie) => {
      s.values.forEach((p: Value) => {
        if (p.pos === this._pos) {
          found = true;
        }
      });
    });

    if (!found) {
      return false;
    }

    return true;
  }

  update() {
    if (!this.isValid()) {
      return;
    }
    this.xpos = -1111;
    this.points = [];
    this._series.forEach((s: Serie) => {
      s.values.forEach((p: Value) => {
        if (p.pos === this._pos) {
          this.xpos = MEDiagrammTool.cx(
            s.calcX(p, this._area[1].x - this._area[0].x),
            this._area[0].x
          );
          const pt = new Point(
            this.xpos,
            MEDiagrammTool.cy(s.calcY(p, s.dHeight), s.offsetY)
          );
          pt.color = s.color;
          pt.label = s.name;
          pt.value = p.value;

          if (s.id === 'temperatur') {
            pt.value = this.fNum(p.value, '1.0-0') + ' °';
          } else if (s.id === 'stand1') {
            if (METool.isDefined(this._sonde)) {
              pt.value =
                this.fNum(this._sonde.displayHeight(p.value), '1.1-1') +
                this.einheit;
            }
          } else if (s.id === 'stand2') {
            if (METool.isDefined(this._sonde)) {
              pt.value =
                this.fNum(this._sonde.displayHeight(p.value), '1.1-1') +
                this.einheit;
            }
          }
          this.points.push(pt);
        }
      });
    });

    this.cd.markForCheck();
  }
}
