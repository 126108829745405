import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {MELocalizedComponent, MESubscriptionHelper} from "../../maennl-commons";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-vpn2',
    templateUrl: './vpn2.component.html',
    styleUrls: ['./vpn2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class VPN2Component
    extends MELocalizedComponent
    implements OnInit, OnDestroy {

    private _tabActive = false;
    public get tabActive(): boolean {
        return this._tabActive;
    }

    @Input()
    public set tabActive(value: boolean) {
        if (this._tabActive !== value) {
            this._tabActive = value;
            this.cd.markForCheck();
            if (this._tabActive) {
                //  this.bridges.start();
            }
        }
    }

    constructor(public toastr: ToastrService, public cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
    }

    onActivate() {
        this.tabActive = true;
        console.log('activated');
    }

    onDeactivate() {
        this.tabActive = false;
        console.log('deactivated');
    }

    public ngAfterViewInit(): void {
    }

    public ngOnDestroy(): void {
        MESubscriptionHelper.release(this);
    }
}