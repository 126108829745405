import {
  IMEListResult,
  MERemoteList
} from '../../../maennl-commons/mecommon-list';
import { CSDServerClient } from './csdserver-client';
import { ICSDServerClient } from './icsdserver-client';
import { SimpleCSDServerClientFilter } from './simple-csdserver-client-filter';

export class CSDServerClientList extends MERemoteList<CSDServerClient> {
  __classname = 'CSDServerClientList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<ICSDServerClient>
  ): CSDServerClientList {
    const newList = new CSDServerClientList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleCSDServerClientFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'info',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return CSDServerClient.fromResult(entity);
  }
}
