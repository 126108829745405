import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MELocalizedComponent } from '../../maennl-commons/localized-component';
import { IPeilung } from '../common/ipeilung';
import {
  MEDiagrammTool,
  Point,
  Serie,
  Value
} from '../../maennl-commons/svgtools';
import { Sonde } from '../common/sonde';
import { Peilung } from '../common/peilung';
import * as moment from 'moment';
import { DiagrammZoomInfo } from './diagramm-zoom-info';

@Component({
  selector: 'app-peilungen-diagramm',
  template: `
    <svg
      viewBox="0 0 1600 500"
      width="100%"
      height="100%"
      style="border: 1px solid #EEE; margin: 5px 0;"
      xmlns:svg="http://www.w3.org/1999/html"
      shape-rendering="auto"
      #svg
      (mousemove)="mmove($event)"
      (mousewheel)="mwheel($event)"
      (click)="diagrammClick($event)"
      (mousedown)="mouseDown($event)"
      (mouseup)="mouseUp($event)"
    >
      <svg:g
        app-diagramm-background
        [area]="diagramArea"
        [sonde]="sonde"
        [tempArea]="diagramTempArea"
        [hasTemperatur]="hasTemperatur()"
        [hasStand1]="hasStand1()"
        [hasStand2]="hasStand2()"
        [minX]="minX"
        [maxX]="maxX"
        [series]="series"
        (serieClicked)="onSerieClick($event)"
        (zoom)="zoomNeeded($event)"
        [showRangeSelection]="showRangeSelection"
        [showRange]="showRange"
      />
      <svg:g *ngIf="isValid()">
        <svg:g
          *ngFor="let s of series"
          me-diagramm-line
          [visible]="s.visible"
          [pts]="s.points"
          [area]="s.area"
          [color]="s.color"
        />
        <svg:g
          *ngIf="showMarker"
          app-diagramm-marker
          [area]="diagramArea"
          [tempArea]="diagramTempArea"
          [hasTemperatur]="hasTemperatur()"
          [series]="series"
          [pos]="markerPos"
          [yPos]="cy"
          [sonde]="sonde"
        />
      </svg:g>
    </svg>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeilungenDiagrammComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private mdLocation: number = null;
  showRangeSelection = false;
  showRange: number[] = [];

  get sonde(): Sonde {
    return this._sonde;
  }

  @Input()
  set sonde(value: Sonde) {
    this._sonde = Sonde.fromResult(value);
  }

  @Input()
  set sondenData(value: IPeilung[]) {
    if (Array.isArray(value)) {
      let changed = value.length !== this._data.length;
      if (!changed) {
        for (let i = 0; i < this._data.length; i++) {
          if (this._data[i].id !== value[i].id) {
            changed = true;
          }
        }
      }
      if (changed) {
        this._data = [];
        value.forEach((sb: IPeilung) => {
          this._data.push(Peilung.fromResult(sb));
        });
        this.createSeries();
      }
    }
    this.cd.markForCheck();
  }

  get sondenData(): IPeilung[] {
    return this._data;
  }

  get series(): Serie[] {
    return this._series;
  }

  __classname = 'PeilungenDiagrammComponent';
  __instance = '';

  @ViewChild('svg', { static: false }) svg: ElementRef;
  sHeight = 500;
  sWidth = 1600;
  dWidth = 1200;
  offsetX = 100;
  offsetY = 50;
  dHeight = 400;
  cx = 0;
  cy = 0;
  minX: any = null;
  maxX: any = null;

  diagramArea: Point[];
  diagramTempArea: Point[];
  private referenzPunkt: SVGPoint;
  showMarker: boolean;
  markerPos: any = null;

  _idx = {
    stand1: -1,
    stand2: -1,
    temperatur: -1
  };

  private _series: Serie[] = [];

  _data: Peilung[] = [];
  private _sonde: Sonde = null;

  @Input() id = 'diagramm';

  @Input() width: number = null;
  @Input() height: number = null;

  @Output() needsData: EventEmitter<PeilungenDiagrammComponent> =
    new EventEmitter<PeilungenDiagrammComponent>();
  @Output() zoom: EventEmitter<DiagrammZoomInfo> =
    new EventEmitter<DiagrammZoomInfo>();

  constructor(public elem: ElementRef, public cd: ChangeDetectorRef) {
    super();
  }

  ngOnDestroy(): void {}

  addBuchung(sb: IPeilung) {
    this._data.push(Peilung.fromResult(sb));
  }

  createSeries() {
    this._series = [];
    this._idx.temperatur = -1;
    this._idx.stand1 = -1;
    this._idx.stand2 = -1;
    if (this.sonde === null || this.sonde === undefined) {
      return;
    }

    if (this.hasStand1()) {
      this._idx.stand1 =
        this._series.push(
          new Serie(
            'stand1',
            this.sonde.labelStand1,
            0,
            this.sonde.maxHoehe === null ? 2000 : this.sonde.maxHoehe,
            [],
            '#ab7a10',
            this.offsetY
          )
        ) - 1;
    }
    if (this.hasStand2()) {
      this._idx.stand2 =
        this._series.push(
          new Serie(
            'stand2',
            this.sonde.labelStand2,
            0,
            this.sonde.maxHoehe === null ? 2000 : this.sonde.maxHoehe,
            [],
            '#48a8ab',
            this.offsetY
          )
        ) - 1;
    }
    if (this.hasTemperatur()) {
      this._idx.temperatur =
        this._series.push(
          new Serie(
            'temperatur',
            'Temperatur',
            this.sonde.minTemperatur === null ? -30 : this.sonde.minTemperatur,
            this.sonde.maxTemperatur === null ? 80 : this.sonde.maxTemperatur,
            [],
            '#ab145a',
            360
          )
        ) - 1;
    }
    this._data.forEach((sb: Peilung) => {
      const xpunkt = moment(sb.zeitpunkt).startOf('minute').toDate();

      if (this.minX == null || xpunkt < this.minX) {
        this.minX = xpunkt;
      }
      if (this.maxX == null || xpunkt > this.maxX) {
        this.maxX = xpunkt;
      }

      if (this.hasStand1() && this._idx.stand1 >= 0) {
        this._series[this._idx.stand1].addValue(
          new Value(xpunkt as Date, sb.stand1)
        );
      }

      if (this.hasTemperatur() && this._idx.temperatur >= 0) {
        this._series[this._idx.temperatur].addValue(
          new Value(xpunkt as Date, sb.temperatur)
        );
      }

      if (this.hasStand2() && this._idx.stand2 >= 0) {
        this._series[this._idx.stand2].addValue(
          new Value(xpunkt as Date, sb.stand2)
        );
      }
    });
    this.buildDiagramm();
  }

  hasStand1() {
    return !(this._sonde === null || this._sonde === undefined);
  }

  hasStand2() {
    if (this._sonde === null || this._sonde === undefined) {
      return false;
    }
    return this._sonde.hasSensor2;
  }

  hasTemperatur() {
    if (this._sonde === null || this._sonde === undefined) {
      return false;
    }
    return this._sonde.hasTemperaturSensor;
  }

  ngOnInit() {
    this.start();
    this.initPoint();
  }

  clear() {
    this._data = [];
    this.minX = null;
    this.maxX = null;
    this._series = [];
    this.createSeries();
  }

  start() {
    this.clear();
    this.needsData.emit(this);
  }

  buildDiagramm() {
    this.dHeight = 400;
    if (this._sonde.hasTemperaturSensor) {
      this.dHeight = 260;
    }

    this.diagramArea = [
      new Point(
        MEDiagrammTool.cx(0, this.offsetX),
        MEDiagrammTool.cy(this.dHeight, this.offsetY, this.sHeight)
      ),
      new Point(
        MEDiagrammTool.cx(this.dWidth, this.offsetX),
        MEDiagrammTool.cy(0, this.offsetY, this.sHeight)
      )
    ];

    this.diagramTempArea = [
      new Point(
        MEDiagrammTool.cx(0, this.offsetX),
        MEDiagrammTool.cy(120, 360, this.sHeight)
      ),
      new Point(
        MEDiagrammTool.cx(this.dWidth, this.offsetX),
        MEDiagrammTool.cy(0, 360, this.sHeight)
      )
    ];

    this._series.forEach((sr: Serie, sidx: number) => {
      sr.area = this.diagramArea;
      sr.dHeight = this.dHeight;
      if (sr.name === 'Temperatur') {
        sr.area = this.diagramTempArea;
        sr.dHeight = 120;
      }

      sr.points = [];

      let oldy = 0;
      sr.values.forEach((pt: Value, idx) => {
        if (sr.name !== 'Temperatur') {
          if (idx > 0) {
            sr.points.push(
              new Point(
                MEDiagrammTool.cx(sr.calcX(pt, this.dWidth), this.offsetX),
                oldy
              )
            );
          }
        }
        oldy = MEDiagrammTool.cy(sr.calcY(pt, sr.dHeight), sr.offsetY);
        sr.points.push(
          new Point(
            MEDiagrammTool.cx(sr.calcX(pt, this.dWidth), this.offsetX),
            oldy,
            true
          )
        );
      });
    });
    this.cd.markForCheck();
  }

  isValid() {
    let r = this._series.length > 0;
    this._series.forEach((s: Serie) => {
      r = r && s.points.length > 0;
    });
    if (this.diagramArea.length < 2) {
      r = false;
    }
    return r;
  }

  initPoint() {
    if (this.svg !== null && this.svg !== undefined) {
      if (this.referenzPunkt === null || this.referenzPunkt === undefined) {
        this.referenzPunkt = this.svg.nativeElement.createSVGPoint();
      }
    }
  }

  ngAfterViewInit() {
    this.initPoint();
  }

  // Get point in global SVG space
  cursorPoint(evt) {
    if (this.referenzPunkt !== null && this.referenzPunkt !== undefined) {
      this.referenzPunkt.x = evt.clientX;
      this.referenzPunkt.y = evt.clientY;
      return this.referenzPunkt.matrixTransform(
        this.svg.nativeElement.getScreenCTM().inverse()
      );
    }
    return null;
  }

  mmove($event: MouseEvent) {
    if (!this.isValid()) {
      return;
    }
    this.initPoint();
    const loc = this.cursorPoint($event);

    if (loc === null) {
      return;
    }

    console.log('mm');

    if (this.mdLocation != null) {
      this.showRangeSelection = true;
      if (loc.x > this.mdLocation) {
        this.showRange = [this.mdLocation, loc.x];
      } else {
        this.showRange = [loc.x, this.mdLocation];
      }
    }

    this.cx = loc.x;
    this.cy = loc.y;
    this.showMarker = false;

    if (this._series.length > 0) {
      if (loc.x >= this.diagramArea[0].x && loc.x <= this.diagramArea[1].x) {
        if (loc.y >= this.diagramArea[0].y && loc.y <= this.diagramArea[1].y) {
          this.showMarker = true;
        }
        if (this.diagramTempArea.length > 0) {
          if (
            loc.y >= this.diagramTempArea[0].y &&
            loc.y <= this.diagramTempArea[1].y
          ) {
            this.showMarker = true;
          }
        }
      }

      const sr = this._series[0];
      this.markerPos = null;
      let mx = null;
      if (this.showMarker) {
        for (let i = 0; i < sr.values.length; i++) {
          const px = MEDiagrammTool.cx(
            sr.calcX(sr.values[i], this.dWidth),
            this.offsetX
          );
          if (mx === null || Math.abs(px - loc.x) < Math.abs(mx - loc.x)) {
            this.markerPos = sr.values[i].pos;
            mx = px;
          }
        }

        if (this.markerPos === null) {
          this.showMarker = false;
        } else {
          this.showMarker = true;
        }
      }
    }

    $event.preventDefault();
    this.cd.markForCheck();
  }

  onSerieClick($event: Serie) {
    $event.visible = !$event.visible;
    this.cd.markForCheck();
  }

  mwheel($event: WheelEvent) {
    this.initPoint();
    const loc = this.cursorPoint($event);

    if (loc !== null) {
      if (loc.x >= this.diagramArea[0].x && loc.x <= this.diagramArea[1].x) {
        const zi = new DiagrammZoomInfo();
        if ($event.deltaY > 0) {
          zi.zoomDirection = 'out';
        } else {
          zi.zoomDirection = 'in';
        }
        const w = this.diagramArea[1].x - this.diagramArea[0].x;
        const x = loc.x - this.diagramArea[0].x;
        if (w > 0) {
          zi.centerAt = x / w;
        }
        $event.preventDefault();
        this.zoom.emit(zi);
      }
    }
  }

  zoomNeeded($event: DiagrammZoomInfo) {
    this.zoom.emit($event);
  }

  diagrammClick($event) {
    this.initPoint();
    return;
    const loc = this.cursorPoint($event);

    if (loc !== null) {
      if (loc.x >= this.diagramArea[0].x && loc.x <= this.diagramArea[1].x) {
        const zi = new DiagrammZoomInfo();
        zi.zoomDirection = 'center';

        const w = this.diagramArea[1].x - this.diagramArea[0].x;
        const x = loc.x - this.diagramArea[0].x;
        if (w > 0) {
          zi.centerAt = x / w;
        }
        $event.preventDefault();
        this.zoom.emit(zi);
      }
    }
  }

  mouseDown($event: MouseEvent) {
    this.initPoint();
    const loc = this.cursorPoint($event);

    if (loc !== null) {
      if (loc.x >= this.diagramArea[0].x && loc.x <= this.diagramArea[1].x) {
        console.log('md');
        this.mdLocation = loc.x;
      }
    }
    this.mdLocation = null;
    this.cd.markForCheck();
  }

  mouseUp($event) {
    this.initPoint();
    const loc = this.cursorPoint($event);

    if (loc !== null) {
      if (loc.x >= this.diagramArea[0].x && loc.x <= this.diagramArea[1].x) {
        console.log('mu');
        const zi = new DiagrammZoomInfo();
        const w = this.diagramArea[1].x - this.diagramArea[0].x;
        const x = loc.x - this.diagramArea[0].x;
        if (w > 0) {
          if (
            this.mdLocation == null ||
            Math.abs(this.mdLocation - loc.x) < 5
          ) {
            zi.zoomDirection = 'center';
            zi.centerAt = x / w;
          } else {
            zi.zoomDirection = 'range';
            const x1 = this.mdLocation - this.diagramArea[0].x;
            if (x > x1) {
              zi.range = [x1 / x, x / w];
            } else {
              zi.range = [x / x, x1 / w];
            }
          }
        }
        $event.preventDefault();
        this.zoom.emit(zi);
      }
    }
    this.mdLocation = null;
    this.cd.markForCheck();
  }
}
