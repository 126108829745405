<me-data-table
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="sims.asDataTableRows()"
        [selectable]="false"
>
    <me-data-table-action
            label="Sim anlegen"
            (perform)="addSim()"
    ></me-data-table-action>

    <me-data-table-column [label]="'ID'" [field]="'id'" type="long"></me-data-table-column>
    <me-data-table-column [label]="'Bezeichnung'" [field]="'label'"></me-data-table-column>
    <me-data-table-column [label]="'Provider'"
                          [field]="'simProvider'"
                          [renderer]="providerRenderer"

    ></me-data-table-column>
    <me-data-table-column [label]="'IMSI'" [field]="'imsi'"></me-data-table-column>
    <me-data-table-column [label]="'MSIDSN'"
                          [field]="'msidsn'"
    ></me-data-table-column>
    <me-data-table-column [label]="'IP'"
                          [field]="'ip'"
    ></me-data-table-column>
    <me-data-table-column
            label="Karte gesperrt?"
            field="gesperrt"
            type="boolean"
    ></me-data-table-column>

    <me-data-table-rowaction
            label="Bearbeiten"
            icon="icofont icofont-ui-edit"
            title="Simkarte bearbeiten"
            (perform)="editSim($event)"

    >
    </me-data-table-rowaction>


    <me-data-table-table-extra-header>
        <me-pagination
                [total]="sims.total"
                [page]="sims.page"
                [perpage]="sims.size"
                (pageChange)="sims.setPage($event)"
                (pageCountChange)="sims.processPageCountChangedEvent($event)"
                (queryChange)="sims.processQueryChangedEvent($event)"
                settingsid="router-sims"
                [settingsService]="benutzerService"
                [showSearch]="true"
        ></me-pagination>

    </me-data-table-table-extra-header>

</me-data-table>



