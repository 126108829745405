import { IMEListResult } from '../../maennl-commons/mecommon-list';
import { MERemoteList } from '../../maennl-commons/mecommon-list';
import { MEConverterTool } from '../../maennl-commons/tools';
import { IMitschnittstream } from './imitschnittstream';
import { Mitschnittstream } from './mitschnittstream';
import { SimpleMitschnittstreamFilter } from './simple-mitschnittstream-filter';

export class MitschnittstreamList extends MERemoteList<Mitschnittstream> {
  __classname = 'MitschnittstreamList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IMitschnittstream>
  ): MitschnittstreamList {
    const newList = new MitschnittstreamList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleMitschnittstreamFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'connectionId',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  doConvertFilterField(k: string, input: any): any {
    if (k === 'connected' || k === 'received') {
      if (Array.isArray(input)) {
        const r: Date[] = [];
        (input as Array<any>).forEach((item) => {
          if (item === null || item === undefined) {
            r.push(null);
          } else {
            r.push(MEConverterTool.toDate(item));
          }
        });
        return r;
      }
      return null;
    }
    return super.doConvertFilterField(k, input);
  }
}
