import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { BoxlisteV4ListEntry } from './boxliste-v4-list-entry';
import { IBoxlisteV4ListEntry } from './iboxliste-v4-list-entry';
import { SimpleBoxlisteV4ListEntryFilter } from './simple-boxliste-v4-list-entry-filter';

export class BoxlisteV4ListEntryList extends MERemoteList<BoxlisteV4ListEntry> {
  __classname = 'BoxlisteV4ListEntryList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IBoxlisteV4ListEntry>
  ): BoxlisteV4ListEntryList {
    const newList = new BoxlisteV4ListEntryList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleBoxlisteV4ListEntryFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'boxNummer',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): BoxlisteV4ListEntry {
    return BoxlisteV4ListEntry.fromResult(entity);
  }
}
