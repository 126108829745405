export class DresserCommand {
    cmd = '';
    length = '';
    data = '';

    getCommandTyp(): string {
        if (this.cmd === '01') {
            return 'Status/Befehl';
        } else if (this.cmd === '02') {
            if (this.length === '08') {
                return 'Tankdaten';
            } else {
                return 'erlaubte Zapfpunkte';
            }
        } else if (this.cmd === '03') {
            return 'Zapfpunktstatus oder max. Menge';
        } else if (this.cmd === '04') {
            return 'max. Betrag';
        } else if (this.cmd === '05') {
            return 'Preisumstellung';
        }
    }

    getCommandClass(): string {
        if (this.cmd === '01') {
            return 'status';
        } else if (this.cmd === '02') {
            if (this.length === '08') {
                return 'tankdata';
            } else {
                return 'zapfpunkte';
            }
        } else if (this.cmd === '03') {
            return 'zpstatus';
        } else if (this.cmd === '04') {
            return 'betrag';
        } else if (this.cmd === '05') {
            return 'preisumstellung';
        }
    }

    getBytes() {
        return this.data.match(/.{2}/g);
    }

    getDataTitle() {
        if (this.cmd === '02') {
            if (this.length === '08') {
                const menge = parseInt(this.data.substr(0, 8), 10) / 100;
                const betrag = parseInt(this.data.substr(8, 8), 10) / 100;

                return 'Menge: ' + menge + 'L / Betrag: ' + betrag;
            } else {
                const p = this.data.match(/.{2}/g);
                const r: string[] = [];
                if (p !== null) {
                    p.forEach((x) => {
                        const ep = parseInt(x, 10);
                        r.push('' + ep);
                    });
                }
                return 'Erlaubte Zapfpunkte: ' + r.join(', ');
            }
        } else if (this.cmd === '03') {
            const ep = parseInt(this.data.substr(0, 6), 10) / 1000;
            const zp = this.data.substr(6, 2);
            let st = '';
            if (zp.substr(0, 1) === '1') {
                st = 'gezogen: ';
            } else {
                st = 'eingehangen: ';
            }
            st = st + zp.substr(1, 1);
            const mm = parseInt(this.data.substr(0, 8), 10) / 100;
            if (ep === 0) {
                return 'ZP-Status: ' + st;
            }
            return (
                'Einzelpreis: ' +
                ep +
                '/ ZP-Status: ' +
                st +
                ' ODER max Menge: ' +
                mm +
                'L'
            );
        } else if (this.cmd === '04') {
            const mm = parseInt(this.data.substr(0, 8), 10) / 100;
            return 'max. Betrag: ' + mm;
        } else if (this.cmd === '05') {
            const p = this.data.match(/.{6}/g);
            const r: string[] = [];
            let saule = 0;
            if (p !== null && p !== undefined) {
                p.forEach((x) => {
                    saule = saule + 1;
                    const ep = parseInt(x, 10) / 1000;
                    r.push('Zapfpunkt ' + saule + ': ' + ep);
                });
            }
            return r.join(' / ');
        } else if (this.cmd === '01') {
            if (this.data === '00') {
                return 'Befehl: Statusabfrage / Status: Pumpe nicht programmiert';
            } else if (this.data === '01') {
                return 'Status: Reset durchgeführt';
            } else if (this.data === '02') {
                return 'Status: Anfrage/Befehl ausgeführt';
            } else if (this.data === '03') {
                return 'Befehl: Identität zurückgeben';
            } else if (this.data === '04') {
                return 'Befehl: Tank-Vorganginformationen zurückgeben / Status: Tankvorgang läuft';
            } else if (this.data === '05') {
                return 'befehl: Reset / Tankvorgang abgeschlossen';
            } else if (this.data === '06') {
                return 'Befehl: Autorisieren / Status: maximale(r) Menge/Betrag erreicht';
            } else if (this.data === '07') {
                return 'Status: abgeschaltet';
            } else if (this.data === '08') {
                return 'Befehl: Stop';
            } else if (this.data === '0a') {
                return 'Befehl: abschalten';
            }
        }
        return '';
    }
}
