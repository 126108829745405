import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Route, Router } from '@angular/router';
import { BenutzerService } from '../benutzer/services/benutzer.service';
import { IGenericObject, MELocalizedComponent } from '../maennl-commons';
import { MEPubSubService } from '../maennl-commons/services';
import { MESubscriptionHelper } from '../maennl-commons/services/mesubscription-helper';
import { CheckPing } from '../meconnect/ipaddresses/commons/check-ping.class';
import { TNullableCheckPing } from '../meconnect/ipaddresses/commons/check-ping.types';
import { NetworkAddress } from '../meconnect/ipaddresses/commons/network-address.class';
import { NetworkAddressFilter } from '../meconnect/ipaddresses/commons/network-address.filter';
import { NetworkAddressList } from '../meconnect/ipaddresses/commons/network-address.list';
import { NetworkAddressService } from '../meconnect/ipaddresses/commons/network-address.service';
import { TNullableNetworkAddress } from '../meconnect/ipaddresses/commons/network-address.types';
import { SysInfoService } from '../nav/sys-info.service';
import { Check } from '../sys-health/common/check';
import { CheckList } from '../sys-health/common/check-list';
import { DUCheckResultInfo } from '../sys-health/common/du-check-result-info';
import { ServiceType } from '../sys-health/common/service-type';
import { SimpleCheckFilter } from '../sys-health/common/simple-check-filter';
import { SysHealthService } from '../sys-health/services/sys-health.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndexComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy
{
  __classname = 'IndexComponent';
  __instance = '';

  serverUrl: string;

  constructor(
    private router: Router,
    public sysInfoService: SysInfoService,
    public pubsub: MEPubSubService,
    public cd: ChangeDetectorRef,
    public userService: BenutzerService,
    public healthService: SysHealthService,
    public nwaddrService: NetworkAddressService
  ) {
    super();
  }

  public du_list: CheckList = new CheckList();
  public nwaddrlist = new NetworkAddressList();

  duTrackBy = (index: number, item: Check): number => {
    return item.id;
  };

  nwTrackBy = (index: number, item: NetworkAddress): number => {
    return item.id;
  };
  cpTrackBy = (index: number, item: CheckPing): number => {
    return item.id;
  };

  ngOnInit(): void {
    MESubscriptionHelper.add(
      this,
      this.pubsub.sysInfoChange.subscribe((s: IGenericObject) => {
        this.serverUrl = (s['httpHost'] as string) || '';
        this.cd.markForCheck();
      })
    );
    MESubscriptionHelper.add(
      this,
      this.du_list.onUpdateRequired.subscribe(list => {
        (list.simpleFilter as SimpleCheckFilter).serviceType =
          ServiceType.diskusage();
        this.healthService
          .listChecks(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.searchString,
            false
          )
          .subscribe(l => {
            list.populateFromListResult(l);
            this.cd.markForCheck();
          });
      })
    );
    MESubscriptionHelper.add(
      this,
      this.nwaddrlist.onUpdateRequired.subscribe(list => {
        console.log('nwaddrlist.update!');

        (list.simpleFilter as NetworkAddressFilter).display_mode = 'hosts';
        (list.simpleFilter as NetworkAddressFilter).problems_only = true;

        this.nwaddrService
          .list(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.searchString
          )
          .subscribe(l => {
            list.populateFromListResult(l);
            this.cd.markForCheck();
          });
      })
    );
    this.sysInfoService.fetchSysInfo(true);
    if (this.userService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_SYSHEALTH'])) {
      this.du_list.setWindowSize(1000);
      this.du_list.setPage(0);
      this.du_list.enableAutoReload(120000);
      this.du_list.start();
    }
    if (this.userService.hasOneOfRoles(['ROLE_ADMIN', 'ROLE_MECONNECT'])) {
      console.log('nwaddrlist.start!');
      this.nwaddrlist.setWindowSize(2500);
      this.nwaddrlist.setPage(0);
      this.nwaddrlist.enableAutoReload(120000);
      this.nwaddrlist.start();
    }
  }

  ngOnDestroy(): void {
    this.du_list.release();
    this.nwaddrlist.release();
    MESubscriptionHelper.release(this);
  }

  hasRoute(controllerName: string): boolean {
    return this.router.config.some((route: Route) => {
      if (route.path === controllerName) {
        return true;
      }
    });
  }

  as_du_result(v: DUCheckResultInfo | null): DUCheckResultInfo[] {
    if (v === null || v === undefined) {
      return [];
    }
    return [v];
  }

  as_nw_result(v: TNullableNetworkAddress): NetworkAddress[] {
    if (v === null || v === undefined) {
      return [];
    }
    return [v];
  }

  as_check(cp: CheckPing, nwa: NetworkAddress): CheckPing {
    cp._address = nwa;
    return cp;
  }

  as_cp_result(v: TNullableCheckPing): CheckPing[] {
    if (v === null || v === undefined) {
      return [];
    }
    return [v];
  }
}
