<div class="modal-header">
    <h4 class="modal-title" *ngIf="isAdd()">Eintrag anlegen</h4>
    <h4 class="modal-title" *ngIf="isEdit()">Eintrag bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <me-text-field [label]="'Name'" [(value)]="entity.display_name"></me-text-field>
    <me-text-field [label]="'Info'" [(value)]="entity.info"></me-text-field>
    <me-text-field [label]="'Public-Key'" [(value)]="entity.public_key"></me-text-field>
    <me-text-field [label]="'Private-Key'" [(value)]="entity.private_key"></me-text-field>
    <me-num-field [label]="'Port'" [(value)]="entity.port"></me-num-field>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i> Daten speichern
    </button>
</div>