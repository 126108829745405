<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'file-exclamation']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="entfernte Logfiles"
></me-area-heading>

<me-tab-card
    #rootTabCard
>
    <me-pane title="NozzleReader" id="tab-nozzlereader"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)">
        <app-tab-nozzle-reader-logs
                id="tab-nozzlereader-content"
        ></app-tab-nozzle-reader-logs>
    </me-pane>
</me-tab-card>