import {MEGenericEntity} from "../../../maennl-commons";
import {ITransaktionNeedRecalcRN} from "./transaktion_need_recalc_rn.interface";
import {TAPIDate, TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";

export class TransaktionNeedRecalcRN
    extends MEGenericEntity<ITransaktionNeedRecalcRN>
    implements ITransaktionNeedRecalcRN {

        id: TNullableNumber;
        transaktion_id: TNullableNumber;
        version: TNullableNumber;
        queued: TAPIDate;
        tenant: TNullableNumber;
        rn: TNullableNumber;
        _tenant_info: TNullableString;
        _station_info: TNullableString;
        _terminal_info: TNullableString;
    }