import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IMachineType } from './imachine-type';

export class MachineType
  extends MEGenericEntity<IMachineType>
  implements IMachineType
{
  id = 0;
  code = '';
  info = '';

  static fromResult(
    entity: IMachineType,
    createIfNull: boolean = false
  ): MachineType {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new MachineType();
      }
      return null;
    }

    const instance = new MachineType();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('code')
        .addTextField('info')
    );
    return instance;
  }

  static windowsMachine() {
    return MachineType.fromResult({
      id: 2,
      code: 'windows',
      info: 'Windows-System'
    });
  }

  static linuxMachine() {
    return MachineType.fromResult({
      id: 3,
      code: 'linux',
      info: 'Linux-System'
    });
  }

  constructor() {
    super();
  }
}
