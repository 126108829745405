import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  MESelectComponent,
  TMESelectItemFormatterCallback,
  TMESelectItemMatcherCallback,
  TMESelectItemTextCallback
} from '../../../../maennl-commons/forms/components/me-select';
import { MELocalizedComponent } from '../../../../maennl-commons/localized-component';
import { MEHighlitePipe } from '../../../../maennl-commons/pipes';
import { MESubscriptionHelper } from '../../../../maennl-commons/services';
import { METool } from '../../../../maennl-commons/tools';
import { CSDServerClientType } from '../../commons/csdserver-client-type';
import { CSDServerClientTypeList } from '../../commons/csdserver-client-type-list';
import { CSDServerService } from '../../services/csdserver.service';

@Component({
  selector: 'app-csdserver-client-type-picker',
  template: `
    <me-select
      [asMatrix]="false"
      [idField]="'id'"
      [items]="types.data"
      [placeholder]="placeholder"
      [small]="small"
      [value]="value"
      (onSearch)="doSearch($event)"
      (onSelect)="doSelect($event)"
      [nullable]="nullable"
      [onFormat]="itemFormat"
      [onMatch]="itemMatcher"
      [onGetText]="itemText"
      [disabled]="disabled"
    ></me-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CSDServerClientTypePickerComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'CSDServerClientTypePickerComponent';
  __instance = '';

  @Input() disabled = false;

  @Input() nullable = true;
  @Input() placeholder = 'Client-Typ auswählen';
  @Input() small = false;

  public _value: CSDServerClientType = null;

  @Output() valueChange = new EventEmitter<CSDServerClientType>();

  public types = new CSDServerClientTypeList();

  @ViewChild(MESelectComponent, { static: false }) meSelect: MESelectComponent;

  ngAfterViewInit(): void {
    if (this.meSelect !== null && this.meSelect !== undefined) {
      this.meSelect.onFormat = this.itemFormat;
      this.meSelect.onGetText = this.itemText;
      this.meSelect.onMatch = this.itemMatcher;
      this.types.start();
    }
  }

  @Input()
  set value(v: CSDServerClientType) {
    if (this._value !== v) {
      this._value = v;
      if (this.meSelect !== null && this.meSelect !== undefined) {
        this.meSelect.value = v;
        this.meSelect.markForCheck();
      }
      this.valueChange.emit(v);
      this.cd.markForCheck();
    }
  }

  get value(): CSDServerClientType {
    return this._value;
  }

  constructor(
    public csdServerService: CSDServerService,
    public cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.types.onUpdateRequired.subscribe((list: CSDServerClientTypeList) => {
        this.csdServerService
          .listClientTypes(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.getQuery()
          )
          .subscribe((l) => {
            list.populateFromListResult(l);
            if (!METool.isNullOrUndefined(this.meSelect)) {
              this.meSelect.markForCheck();
            }
          }, undefined);
      }, undefined)
    );
  }

  doSearch(value) {
    this.types.searchString = value;
    this.types.reload();
  }

  doSelect(value: CSDServerClientType) {
    this._value = value;
    this.valueChange.emit(value);
    this.cd.markForCheck();
  }

  public itemFormat: TMESelectItemFormatterCallback<CSDServerClientType> = (
    item: CSDServerClientType,
    search,
    forList: boolean
  ) => {
    if (item === null || item === undefined) {
      return '';
    }

    return (
      MEHighlitePipe.transformString('' + item.info, search) +
      '<span class="badge bg-secondary text-dark">' +
      MEHighlitePipe.transformString('' + item.label, search) +
      '</span>'
    );
  };

  public itemText: TMESelectItemTextCallback<CSDServerClientType> = (
    item: CSDServerClientType
  ) => {
    if (item === null || item === undefined) {
      return '';
    }
    return item.info + ' [' + item.label + ']';
  };

  public itemMatcher: TMESelectItemMatcherCallback<CSDServerClientType> = (
    item: CSDServerClientType,
    search
  ) => {
    if (!search) {
      return true;
    }
    if (search.trim() === '') {
      return true;
    }
    if (!item) {
      return false;
    }
    const x = item.info + ' ' + item.id + ' ' + item.label;

    return x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
  };

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.types.release();
    super.ngOnDestroy();
  }
}
