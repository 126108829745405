import { Subscription } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';

export class MESubscriptionHelper {
  static instanceId = 0;
  static subscriptions: any = {};

  static isModuleKnown(module: string) {
    try {
      return (
        Object.keys(MESubscriptionHelper.subscriptions).indexOf(module) >= 0
      );
    } catch (e) {}
    return false;
  }

  static checkModule(module: string) {
    try {
      if (!MESubscriptionHelper.isModuleKnown(module)) {
        MESubscriptionHelper.subscriptions[module] = [];
      }
      if (!Array.isArray(MESubscriptionHelper.subscriptions[module])) {
        MESubscriptionHelper.subscriptions[module] = [];
      }
    } catch (e) {}
  }

  static add(module: any, sub: Subscription) {
    try {
      if (typeof module !== 'string') {
        module = MESubscriptionHelper.newInstance(module);
      }
      MESubscriptionHelper.checkModule(module);
      MESubscriptionHelper.subscriptions[module].push(sub);
    } catch (e) {}
  }

  static remove(module: any, sub: Subscription) {
    try {
      if (typeof module !== 'string') {
        module = MESubscriptionHelper.newInstance(module);
      }
      MESubscriptionHelper.checkModule(module);
      const pos = (
        MESubscriptionHelper.subscriptions[module] as Array<Subscription>
      ).indexOf(sub);
      if (pos >= 0) {
        (
          MESubscriptionHelper.subscriptions[module][pos] as Subscription
        ).unsubscribe();
        (
          MESubscriptionHelper.subscriptions[module] as Array<Subscription>
        ).splice(pos, 1);
      }
    } catch (e) {}
  }

  static release(module: any) {
    try {
      if (typeof module !== 'string') {
        module = MESubscriptionHelper.newInstance(module);
      }
      MESubscriptionHelper.checkModule(module);
      while (
        (MESubscriptionHelper.subscriptions[module] as Array<Subscription>)
          .length > 0
      ) {
        (
          MESubscriptionHelper.subscriptions[module] as Array<Subscription>
        )[0].unsubscribe();
        (
          MESubscriptionHelper.subscriptions[module] as Array<Subscription>
        ).splice(0, 1);
      }
      Object.keys(MESubscriptionHelper.subscriptions).forEach((k: string) => {
        if (Array.isArray(MESubscriptionHelper.subscriptions[k])) {
          if (
            (MESubscriptionHelper.subscriptions[k] as Array<Subscription>)
              .length < 1
          ) {
            delete MESubscriptionHelper.subscriptions[k];
          }
        }
      });
    } catch (e) {}
  }

  static newInstance(prefix: any): string {
    if (
      typeof prefix === 'object' &&
      prefix.__instance !== null &&
      prefix.__instance !== undefined &&
      prefix.__instance !== ''
    ) {
      return prefix.__instance;
    }
    let src = '';
    if (
      typeof prefix === 'object' &&
      prefix.__classname !== null &&
      prefix.__classname !== undefined
    ) {
      src = '' + prefix.__classname;
    } else if (typeof prefix === 'object') {
      src = '' + Md5.hashStr(JSON.stringify(Object.keys(prefix)));
    } else {
      src = '' + prefix;
    }

    let instance = '';

    MESubscriptionHelper.instanceId++;
    if (src !== null && src !== undefined && src !== '') {
      instance = src + '_____' + MESubscriptionHelper.instanceId;
    } else {
      instance = '' + MESubscriptionHelper.instanceId;
    }

    if (
      typeof prefix === 'object' &&
      prefix.__instance !== null &&
      prefix.__instance !== undefined
    ) {
      prefix.__instance = instance;
    }
    return instance;
  }
}
