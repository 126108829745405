<me-data-table
        *ngIf="liste!==null && liste!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="liste.asDataTableRows()"
        (onSortChanged)="liste.processSortChangedEvent($event)"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        [extraRowClasses]="rowClassGetter"
>

    <me-data-table-column
            [label]="'Box'"
            [field]="'nebenstelleName'" [sortable]="true"
            [classgetter]="cellClassGetter"
    >
    </me-data-table-column>

    <me-data-table-column
            [label]="'Boxnummer'" [sortable]="true"
            [field]="'boxNummer'"
            [classgetter]="cellClassGetter"
    ></me-data-table-column>


    <me-data-table-column
            [label]="'Datum Serviceanfrage'" [sortable]="true"
            [field]="'datumServiceAnfrage'" [classgetter]="cellClassGetter"
            type="date"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>

    <me-data-table-column
            [label]="'Datum Tankung'" [sortable]="true"
            [field]="'datumTankung'"
            type="date"
            [classgetter]="cellClassGetter"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>

    <me-data-table-column
            [label]="'Datum Sondendaten'" [sortable]="true"
            [field]="'datumSondendaten'"
            type="date"
            [classgetter]="cellClassGetter"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>

    <me-data-table-column
            [label]="'Datum Kartenanfrage'" [sortable]="true"
            [field]="'datumKartenanfrage'"
            type="date"
            [classgetter]="cellClassGetter"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>

    <me-data-table-column
            [label]="'IP'" [sortable]="true"
            [field]="'ip'"
            [classgetter]="cellClassGetter"
    ></me-data-table-column>


    <me-data-table-column
            [label]="'IP Standzeit HS'" [sortable]="true"
            [field]="'threadStartDatumHS'"
            type="date"
            [classgetter]="cellClassGetter"
            [formatter]="standzeitFormatter"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>

    <me-data-table-column
            [label]="'IP Standzeit AR'" [sortable]="true"
            [field]="'threadStartDatumAR'"
            type="date"
            [formatter]="standzeitFormatter"
            [classgetter]="cellClassGetter"
            [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"
    ></me-data-table-column>

    <me-data-table-table-extra-header>
        <me-pagination
                settingsid="syshealth-boxliste"
                [settingsService]="benutzerService"
                [pagecounts]="[10,20,50,100,500,1000]"
                [total]="liste.total"
                [page]="liste.page"
                [perpage]="liste.size"
                (pageChange)="liste.setPage($event)"
                (pageCountChange)="liste.processPageCountChangedEvent($event)"
                [showSearch]="true"
                (queryChange)="liste.processQueryChangedEvent($event)"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
