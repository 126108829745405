import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'me-pane',
  template: `
    <div id="{{ id }}">
      <ng-content></ng-content>
    </div>
  `
})
export class MEPaneComponent implements OnInit {
  @Input() public title: string;
  @Input() public id: string = 'Pane' + 100000 * Math.random();
  @Input() public disabled = false;
  @Input() forceEvents = false;
  @Output() public activated: EventEmitter<MEPaneComponent> =
    new EventEmitter<MEPaneComponent>();
  @Output() public deactivated: EventEmitter<MEPaneComponent> =
    new EventEmitter<MEPaneComponent>();
  @Input() public icon = '';

  public _active = null;

  public set active(value: boolean) {
    if (this._active === null || this.forceEvents || this._active !== value) {
      this._active = value;
      if (value) {
        this.elementRef.nativeElement.style.display = 'block';
        this.activated.emit(this);
      } else {
        this.elementRef.nativeElement.style.display = 'none';
        this.deactivated.emit(this);
      }
    }
    if (this._active) {
      this.elementRef.nativeElement.style.display = 'block';
    } else {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }

  public get active(): boolean {
    return this._active === null ? false : this._active;
  }

  constructor(public elementRef: ElementRef) {
    this.elementRef.nativeElement.style.display = 'none';
  }

  public ngOnInit() {
    if (this.id === undefined || this.id === null || this.id === '') {
      this.id = this.title + ('.' + Math.random());
    }
  }

  public hasIcon() {
    return !(
      this.icon === null ||
      this.icon === undefined ||
      ('' + this.icon).trim() === ''
    );
  }
}
