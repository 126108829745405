import { IMEListResult } from '../../maennl-commons/mecommon-list/imelist-result';
import { MERemoteList } from '../../maennl-commons/mecommon-list/meremote-list';
import { IMitschnittstreamPaket } from './imitschnittstream-paket';
import { MitschnittstreamPaket } from './mitschnittstream-paket';
import { SimpleMitschnittstreamPaketFilter } from './simple-mitschnittstream-paket-filter';

export class MitschnittstreamPaketList extends MERemoteList<MitschnittstreamPaket> {
  __classname = 'MitschnittstreamPaketList';
  __instance = '';

  static fromListResult(
    res: IMEListResult<IMitschnittstreamPaket>
  ): MitschnittstreamPaketList {
    const newList = new MitschnittstreamPaketList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleMitschnittstreamPaketFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'packet_id',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }
}
