import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import {
  TMECellClassGetter,
  TMECellRendererValueFormatter
} from '../../../maennl-commons/data-table/renderer/types';
import {
  MEDataTableRow,
  TMEDTGetExtraRowClassesCallback
} from '../../../maennl-commons/data-table/rows';
import { MESubscriptionHelper } from '../../../maennl-commons/services';
import { MEConverterTool, MERichError } from '../../../maennl-commons/tools';
import { BoxlisteV4ListEntry } from '../../common/boxliste-v4-list-entry';
import { BoxlisteV4ListEntryList } from '../../common/boxliste-v4-list-entry-list';
import { SysHealthService } from '../../services/sys-health.service';
import * as moment from 'moment';

@Component({
  selector: 'app-sys-health-boxliste-v4-tab',
  templateUrl: './sys-health-boxliste-v4-tab.component.html',
  styleUrls: ['./sys-health-boxliste-v4-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SysHealthBoxlisteV4TabComponent implements OnInit, OnDestroy {
  __classname = 'SysHealthBoxlisteV4TabComponent';
  __instance = '';
  liste: BoxlisteV4ListEntryList = new BoxlisteV4ListEntryList();

  private _tabActive = false;

  public get tabActive(): boolean {
    return this._tabActive;
  }

  public set tabActive(value: boolean) {
    if (this._tabActive !== value) {
      this._tabActive = value;
      this.cd.markForCheck();
      if (this._tabActive) {
        this.liste.enableAutoReload();
        this.liste.start();
      } else {
        this.liste.disableAutoReload();
      }
    }
  }

  constructor(
    public cd: ChangeDetectorRef,
    public sysHealthService: SysHealthService,
    public benutzerService: BenutzerService
  ) {}

  rowClassGetter: TMEDTGetExtraRowClassesCallback<BoxlisteV4ListEntry> = (
    row: MEDataTableRow<BoxlisteV4ListEntry>,
    idx
  ) => {
    if (
      row !== null &&
      row !== undefined &&
      row.data !== null &&
      row.data !== undefined
    ) {
      const styles = [];

      const d = moment(row.data.datumServiceAnfrage);
      const now = moment();
      const duration = moment.duration(now.diff(d));

      if (row.data.statusSimId > 1) {
        styles.push('font-weight-bold');
        styles.push('font-italic');
      }

      return styles.join(' ');
    }
    return '';
  };

  public cellClassGetter: TMECellClassGetter<BoxlisteV4ListEntry> = (
    value,
    column,
    row
  ) => {
    if (
      row !== null &&
      row !== undefined &&
      row.data !== null &&
      row.data !== undefined
    ) {
      const styles = [];

      const now = moment();

      if (!column.field.startsWith('thread')) {
        const d = moment(row.data.datumServiceAnfrage);

        const duration = moment.duration(now.diff(d));

        if (duration.isValid() && duration.asMinutes() > 30) {
          styles.push('bg-danger');
          styles.push('text-white');
        }
      } else {
        const duration = moment.duration(now.diff(value));

        if (duration.isValid()) {
          const h = duration.asHours();

          if (h >= 24) {
            styles.push('bg-white');
            styles.push('text-black');
          } else if (h >= 12) {
            styles.push('bg-yellow');
            styles.push('text-black');
          } else if (h >= 1) {
            styles.push('bg-blue');
            styles.push('text-white');
          } else {
            styles.push('bg-danger');
            styles.push('text-white');
          }
        } else {
          if (row.data.statusSimId < 1) {
            styles.push('bg-danger');
            styles.push('text-white');
          }
        }
      }

      return styles.join(' ');
    }

    return '';
  };
  public standzeitFormatter: TMECellRendererValueFormatter<BoxlisteV4ListEntry> =
    (value, column, row, what) => {
      if (value !== null && value !== undefined) {
        const d = moment(value);
        if (d.isValid()) {
          const now = moment();
          const duration = moment.duration(now.diff(d));

          const m = duration.years() * 12 + duration.months();
          const days = duration.days();
          const hours = duration.hours();
          const minutes = duration.minutes();

          return (
            (m < 1 ? '' : MEConverterTool.formatNum(m, 0) + 'm ') +
            (days < 1
              ? ''
              : MEConverterTool.right_string(
                  '00' + MEConverterTool.formatNum(days, 0),
                  2
                ) + 'd ') +
            MEConverterTool.right_string(
              '00' + MEConverterTool.formatNum(hours, 0),
              2
            ) +
            ':' +
            MEConverterTool.right_string(
              '00' + MEConverterTool.formatNum(minutes, 0),
              2
            )
          );
        } else {
          if (row.data.statusSimId < 1) {
            return '!';
          }
        }
      } else {
        if (row.data.statusSimId < 1) {
          return '!';
        }
      }

      return '';
    };

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe((list) => {
        this.sysHealthService
          .getBoxlisteV4(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.getQuery()
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
            },

            (e) => {
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste des verfügbaren Boxen konnte nicht abgerufen werden'
              );
            }
          );
      })
    );
  }

  public onActivate() {
    this.tabActive = true;
  }

  public onDeactivate() {
    this.tabActive = false;
  }

  public ngOnDestroy(): void {
    this.liste.release();
    MESubscriptionHelper.release(this);
  }
}
