import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";

@Component({
    selector: 'app-dokulink',
    templateUrl: './dokulink.component.html',
    styleUrls: ['./dokulink.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DokulinkComponent implements OnInit, OnDestroy {

    constructor(public cd: ChangeDetectorRef, public router: Router) {
    }

    private _current_route = '';

    get current_route(): string {
        return this._current_route;
    }

    set current_route(value: string) {
        if (this._current_route !== value) {
            this._current_route = value;
            this.cd.markForCheck()
            console.log(this._current_route);
        }
    }

    open_doku() {
        let uri = 'http://172.38.2.11/dokumentationen.html'
        if(this.current_route.startsWith('/meconnect/csd')) {
            uri = 'http://172.38.2.11/csd.html'
        }
        window.open(uri, '_blank');
    }

    public ngOnInit(): void {
        this.router.events.subscribe((e) => {

            if (e instanceof NavigationEnd) {
                this.current_route = e.urlAfterRedirects;
            }
        })
        this.cd.markForCheck();
    }

    public ngOnDestroy(): void {

    }

}
