import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { MEPubSubService } from '../services';
import {
  MEHttpClientInterceptorConfig,
  ME_HTTP_CLIENT_INTERCEPTOR_CONFIG
} from './types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class MEHttpClientInterceptor implements HttpInterceptor {
  static provider() {
    return {
      provide: HTTP_INTERCEPTORS,
      useClass: MEHttpClientInterceptor,
      deps: [MEPubSubService, Router, ME_HTTP_CLIENT_INTERCEPTOR_CONFIG],
      multi: true
    };
  }

  constructor(
    public pubSub: MEPubSubService,
    public router: Router,
    @Inject(ME_HTTP_CLIENT_INTERCEPTOR_CONFIG)
    private cfg: MEHttpClientInterceptorConfig
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    try {
      this.pubSub.beforeRequest.emit('beforeRequestEvent');
    } catch (e) {
      //
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event.type === HttpEventType.Response) {
            try {
              this.pubSub.afterRequest.emit('afterRequestEvent');
            } catch (e) {
              //
            }
          }
        },
        (err: any) => {
          try {
            this.pubSub.afterRequest.emit('afterRequestEvent');
          } catch (e) {
            //
          }

          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
              try {
                this.pubSub.loginError.emit('loginErrorEvent');
              } catch (e) {
                //
              }
              try {
                this.router.navigate([this.cfg.loginRoute]).catch(() => {
                  this.router.navigateByUrl('/').catch(undefined);
                });
              } catch (e) {
                //
              }
            }
          }
        }
      )
    );
  }
}
