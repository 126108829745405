import {
    MEConverterTool,
    MEGenericEntity,
    MEGenericEntityFields,
    METool
} from '../../../maennl-commons';
import {ITenant} from './itenant';
import {CoreModules} from "./core-modules.class";
import {TNullableTenant, TTenant} from "./tenant.types";

export class Tenant extends MEGenericEntity<ITenant> implements ITenant {
    admin_message: string = "";
    app_name: string = "";
    betreiber_is_maennl: boolean = false;
    code: string = "";
    default_entry_point: string = "/";
    email_from: string = "noreply.@webfuel.de";
    enabled: boolean = false;
    hosts: string[] = null;
    id: number = null;
    info: string = "";
    logo: string = "default-96x96.svg";
    modules: string[] = null;
    projekt: number = 99999;
    short_name: string = "";
    ta_access_digest: string = null;
    ta_access_uid: number = null;
    ta_access_username: string = null;
    terminal_import_pattern: string = "not_used=not_used";
    version: number = null;
    settings: string = null;
    setup_complete: boolean = null;

    constructor() {
        super();
    }

    static fromResult(entity: TTenant, createIfNull: boolean = false): TNullableTenant {
        if (entity === null || entity === undefined) {
            if (createIfNull) {
                return new Tenant();
            }
            return null;
        }

        const instance = new Tenant();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongField('id')
                .addTextField('code')
                .addLongField('projekt')
                .addStringField('info')
                .addBooleanField('enabled')
                .addStringField('app_name')
                .addBooleanField('betreiber_is_maennl')
                .addTextField('default_entry_point')
                .addStringsField('modules')
                .addStringField('logo')
                .addLongField('version')
                .addStringField('terminal_import_pattern')
                .addStringField('admin_message')
                .addStringsField('hosts')
                .addStringField('email_from')
                .addStringField('short_name')
                .addLongField('ta_access_uid')
                .addStringField('ta_access_username')
                .addStringField('ta_access_digest')
                .addStringField('settings')
                .addBooleanField('setup_complete'),
            (e) => {
            }
        );
        return instance;
    }

    has_digest() {
        return !METool.isEmpty(this.ta_access_digest);
    }

    has_module(module: string) {
        if (
            this.modules === null ||
            this.modules === undefined ||
            module === null ||
            module === undefined ||
            !Array.isArray(this.modules) ||
            module === ''
        ) {
            return false
        }
        return this.modules.indexOf(module) >= 0;
    }

    setting_is_true(setting: string) {
        if(
            this.settings === null ||
            this.settings === undefined ||
            setting === null ||
            setting === undefined ||
            setting.trim() === '' ||
            setting.trim() === '{}'
        ) {
            return false;
        }
        if(!this.settings.includes(setting)){
            return false;
        }
        const settings = JSON.parse(this.settings);
        return settings[setting].toString().trim() === 'true' || settings[setting] === true;

    }

    get_setting(setting:string) {
        if(
            this.settings === null ||
            this.settings === undefined ||
            setting === null ||
            setting === undefined ||
            setting.trim() === ''
        ) {
            return null;
        }
        if(!this.settings.includes(setting)){
            return null;
        }
        const settings = MEConverterTool.toJSON(this.settings);
        return settings[setting];
    }
}
