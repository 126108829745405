<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'heartbeat']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Lebenzeichen"
></me-area-heading>

<me-card>
    <app-sys-health-lebenszeichen-tab
            [id]="'SH-tab-lebenszeichen'"
    ></app-sys-health-lebenszeichen-tab>
</me-card>

