<div class="row">
  <div class="col-4 text-nowrap">
    emmc/Root
  </div>
  <div class="col-8 text-nowrap pt-1">
    <app-file-system-space-display
      [free]="testping.freeSpaceRoot"
      [total]="testping.totalSpaceRoot"
    >
    </app-file-system-space-display>
  </div>
</div>
<div class="row">
  <div class="col-4 text-nowrap">
    SD-Card
  </div>
  <div class="col-8 text-nowrap" [class.pt-1]="testping.totalSpaceSDCard>0">
    <app-file-system-space-display
            *ngIf="testping.totalSpaceSDCard>0"
            [free]="testping.freeSpaceSDCard"
            [total]="testping.totalSpaceSDCard"
    >
    </app-file-system-space-display>
    <div *ngIf="testping.totalSpaceSDCard===0">
      Keine SD-Card erkannt
    </div>
  </div>
</div>
