import {MEGenericEntity} from "../../../maennl-commons";
import {TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";
import {IInventoryType} from "./inventory-type.interface";

export class InventoryType
    extends MEGenericEntity<IInventoryType>
    implements IInventoryType {

    id: TNullableNumber=null;
    code: TNullableString=null;
    display_text: TNullableString=null;

}