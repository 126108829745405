import {IMEListResult, MERemoteList} from "../../../maennl-commons";
import {WireguardClient} from "./wireguard-client.class";
import {IWireguardClient} from "./wireguard-client.interface";
import {WireguardClientImpl} from "./wireguard-client.impl";

export class WireguardClientList extends MERemoteList<WireguardClient> {
    __instance = '';

    static fromListResult(
        res: IMEListResult<IWireguardClient>
    ): WireguardClientList {
        const newList = new WireguardClientList();
        newList.doPopulateFromListResult(res);
        return newList;
    }

    constructor(autorun:boolean=false,timeout:number=400) {
        super();
        this.initList(autorun,timeout);
    }

    public doPopulateFromListResult(
        res: IMEListResult<any>,
        defaultSort: string = 'display_name',
        defaultOrder: string= 'asc',
        clearFilter: boolean=true
    ){
        super.doPopulateFromListResult(res,defaultSort,defaultOrder,clearFilter);
    }

    convertEntity(entity: any): any {
        return WireguardClientImpl.fromResult(entity);
    }
}