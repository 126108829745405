import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {CoreModules} from "../../commons/core-modules.class";
import {MEGenericModalComponent, MEPubSubService, METool} from "../../../../maennl-commons";
import {CoreModulesImpl} from "../../commons/core-modules.impl";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CoreModulesService} from "../../commons/core-modules.service";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-tab-core-modules-editor',
    templateUrl: './tab-core-modules-editor.component.html',
    styleUrls: ['./tab-core-modules-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabCoreModulesEditorComponent
    extends MEGenericModalComponent<CoreModules>
    implements OnInit,OnDestroy
{
    entity: CoreModules=null;

    public initEntity(src: CoreModules): CoreModules {
        return CoreModulesImpl.fromResult(src,true);
    }

    constructor(
        public activeModal: NgbActiveModal,
        public pubsub: MEPubSubService,
        public service: CoreModulesService,
        public toastr: ToastrService,
        public cd: ChangeDetectorRef
    ) {
        super(activeModal, pubsub);
    }

    ngOnInit() {}

    save() {
        if(this.entity.hidden!==true){
            this.entity.hidden=false;
        }
        this.service.store(this.entity).subscribe({
            next:(r)=>{
                if(r.success){
                    this.ok();
                } else {
                    this.toastr.error('Fehler beim Speichern der Daten');
                }
            },
            error:() =>{
                this.toastr.error('Fehler beim Speichern der Daten');
            }
        });
    }
}