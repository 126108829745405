import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {MEConfirmDialogComponent} from '../../maennl-commons/meconfirm-dialog';
import {MEGenericModalComponent} from '../../maennl-commons/megeneric-modal';
import {MEPubSubService} from '../../maennl-commons/services';
import {MERichError, METool} from '../../maennl-commons/tools';
import {Benutzer} from '../common/benutzer';
import {IBenutzer} from '../common/ibenutzer';
import {BenutzerService} from '../services/benutzer.service';

@Component({
    selector: 'app-benutzer-editor',
    templateUrl: './benutzer-editor.component.html',
    styleUrls: ['./benutzer-editor.component.scss']
})
export class UserEditorComponent
    extends MEGenericModalComponent<Benutzer>
    implements OnInit, OnDestroy {
    __classname = 'UserEditorComponent';

    public initEntity(src: IBenutzer): Benutzer {
        return Benutzer.fromResult(src, true);
    }

    constructor(
        public activeModal: NgbActiveModal,
        public pubsub: MEPubSubService,
        public benutzerService: BenutzerService,
        public modalService: NgbModal,
        public cd: ChangeDetectorRef,
        public toastr: ToastrService
    ) {
        super(activeModal, pubsub);
    }

    public canSave() {
        if (METool.isNullOrUndefined(this.entity)) {
            return false;
        }

        if (METool.isNullOrUndefined(this.entity.id)) {
            return false;
        }
        if (METool.isNullOrUndefined(this.entity.username) || METool.isEmpty(this.entity.username)) {
            return false;
        }
        return true;
    }

    public save() {
        if (this.canSave()) {
            this.benutzerService.storeUser(this.entity).subscribe(
                (r) => {
                    if (r.success) {
                        this.activeModal.close(this.entity);
                    } else {
                        MERichError.throw(
                            'Fehler beim Speichern',
                            'Beim Speichern des Datensatzes ist ein Fehler aufgetreten.'
                        );
                    }
                },
                () => {
                    MERichError.throw(
                        'Fehler beim Speichern',
                        'Beim Speichern des Datensatzes ist ein Fehler aufgetreten.'
                    );
                }
            );
        }
    }

    public delete() {
        if (!METool.isNullOrUndefined(this.entity.id) && this.entity.id > 0) {
            MEConfirmDialogComponent.display(
                this.modalService,
                'Löschen bestätigen',
                'Sollen das Gerät wirklich unwiderruflich gelöscht werden?',
                'Ja, jetzt löschen',
                'icofont icofont-delete'
            ).then(() => {
                this.benutzerService.removeUser(this.entity).subscribe(
                    (r) => {
                        if (r.success) {
                            this.activeModal.close();
                        } else {
                            MERichError.throw(
                                'Fehler beim Löschen',
                                'Beim Löschen des Clients ist ein Fehler aufgetreten.'
                            );
                        }
                    },
                    () => {
                        MERichError.throw(
                            'Fehler beim Löschen',
                            'Beim Löschen des Clients ist ein Fehler aufgetreten.'
                        );
                    }
                );
            });
        }
    }
}
