<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'heartbeat']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Router/SIM-Status"
></me-area-heading>

<me-card>
    <app-sys-health-router-status-tab
            [id]="'SH-tab-router-status'"
    ></app-sys-health-router-status-tab>
</me-card>
