import {
    MEGenericEntity,
    MEGenericEntityConverterParams,
    MEGenericEntityFields
} from '../../../maennl-commons';
import {MEConverterTool, METool} from '../../../maennl-commons';
import {Sim} from '../../../router/common/sim';
import {CSDServerClientType} from './csdserver-client-type';
import {ICSDServerClient} from './icsdserver-client';

export class CSDServerClient
    extends MEGenericEntity<ICSDServerClient>
    implements ICSDServerClient {
    public bytesReceived = 0;
    public bytesSent = 0;
    public id = 0;
    public info = '';
    public machineId = '';
    public typId = 0;
    public typInfo = '';
    public typLabel = '';

    public typ: CSDServerClientType = null;

    public escapeSequence = '+++';
    public lastConnected: Date = null;
    public lastSeen: Date = null;
    public online = false;
    public enabled = false;
    public traffic = 0;

    public imei = '';
    public label = '';
    public sim: Sim = null;
    public simId = 0;
    public simRufnummer = '';
    public simTitle = '';
    public lastIp = '';

    public connections = 0;
    public timeout = 60;
    public commandPassword = 'maennl#';
    public has_bridge = false;
    public contact_id = 0;

    static fromResult(
        entity: ICSDServerClient,
        createIfNull: boolean = false
    ): CSDServerClient {
        if (entity === null || entity === undefined) {
            if (createIfNull) {
                return new CSDServerClient();
            }
            return null;
        }

        const instance = new CSDServerClient();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongField('id')
                .addLongField('bytesReceived')
                .addLongField('bytesSent')
                .addTextField('info')
                .addTextField('machineId')
                .addLongField('typId')
                .addTextField('typLabel')
                .addTextField('typInfo')
                .addBooleanField('online')
                .addDateField('lastSeen')
                .addDateField('lastConnected')
                .addTextField('escapeSequence')
                .addBooleanField('enabled')
                .addTextField('imei')
                .addTextField('label')
                .addTextField('simTitle')
                .addTextField('simRufnummer')
                .addLongField('simId')
                .addTextField('lastIp')
                .addLongField('connections')
                .addLongField('timeout')
                .addTextField('commandPassword')
                .addLongField('traffic')
                .addConverterField(
                    'sim',
                    new MEGenericEntityConverterParams<Sim>((value: any) => {
                        return Sim.fromResult(value);
                    })
                )
                .addConverterField(
                    'typ',
                    new MEGenericEntityConverterParams<CSDServerClientType>(
                        (value: any) => {
                            return CSDServerClientType.fromResult(value);
                        }
                    )
                )
                .addBooleanField('has_bridge')
                .addLongField('contact_id'),
            (e: CSDServerClient) => {
                if (METool.isEmpty(e.label) || e.label === 'null') {
                    e.label = e.info;
                }
                if (METool.isNullOrUndefined(e.imei) || e.imei === 'null') {
                    e.imei = '';
                }
            }
        );
        return instance;
    }

    constructor() {
        super();
    }

    public get machineIdAsHex() {
        const m = '$' + this.machineId + '$';
        let n = '';
        for (let j = 0; j < m.length; j++) {
            n += MEConverterTool.char2Hex(m.charCodeAt(j));
        }
        return n;
    }

    public get login_part(): string {
        if (!METool.isEmpty(this.label)) {
            return this.label.trim();
        }
        if (!METool.isEmpty(this.simRufnummer)) {
            return this.simRufnummer.trim();
        }
        if (!METool.isEmpty(this.machineId)) {
            return this.machineId.trim();
        }
        return MEConverterTool.randomString(25);
    }
}
