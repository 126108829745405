import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-toggler',
  template: `
    <a (click)="toogleSideNav()">
      <i class="icofont {{ className }}"></i>
    </a>
  `,
  styles: [
    `
      :host {
        color: white;
        padding: 4px;
      }
    `
  ]
})
export class SidebarTogglerComponent implements OnInit {
  public className = 'icofont-navigation-menu';
  protected wrapper: any = null;

  constructor() {
    this.check();
  }

  ngOnInit() {
    this.check();
  }

  protected getWrapper() {
    if (this.wrapper === null || this.wrapper === undefined) {
      this.wrapper = jQuery('#wrapper');
    } else if (this.wrapper.length < 1) {
      this.wrapper = jQuery('#wrapper');
    }
    return this.wrapper;
  }

  check() {
    if (this.getWrapper().hasClass('toggled')) {
      this.className = 'icofont-navigation-menu';
    } else {
      this.className = 'icofont-close';
    }
  }

  toogleSideNav() {
    this.getWrapper().toggleClass('toggled');
    this.check();
  }
}
