import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    MEDataTableComponent,
    MEDataTableRow, MEDataTableRowSelected, MERowActionParams,
    TMEDTGetExtraRowClassesCallback
} from '../../../maennl-commons/data-table';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MESubscriptionHelper} from '../../../maennl-commons/services';
import {MERichError, noop} from '../../../maennl-commons/tools';
import {BenutzerList} from '../../common/benutzer-list';
import {BenutzerService} from '../../services/benutzer.service';
import {Benutzer} from '../../common/benutzer';
import {IBenutzer} from "../../common/ibenutzer";
import {UserEditorComponent} from "../../benutzer-editor/benutzer-editor.component";
import {AuthorityList} from "../../common/authority.list";
import {AuthorityService} from "../../services/authority.service";
import {TNullableBenutzer} from "../../common/benutzer.types";
import {TAuthority} from "../../common/authority.types";

@Component({
    selector: 'app-tab-benutzer',
    templateUrl: './tab-benutzer.component.html',
    styleUrls: ['./tab-benutzer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabBenutzerComponent implements OnInit, OnDestroy, AfterViewInit {

    __classname = 'TabBenutzerComponent';
    __instance = '';
    tabActive = false;
    @Input() id = '';
    public benutzer: BenutzerList = new BenutzerList();
    authorities = new AuthorityList();

    @ViewChild(MEDataTableComponent, {static: false}) dt = null;

    constructor(
        public toastr: ToastrService,
        public cd: ChangeDetectorRef,
        public benutzerService: BenutzerService,
        public athService: AuthorityService,
        public modalService: NgbModal
    ) {
    }

    private _current: TNullableBenutzer = null;

    get current(): TNullableBenutzer {
        return this._current;
    }

    set current(value: TNullableBenutzer) {
        if (
            this._current !== value ||
            (this._current !== null &&
                value !== null &&
                this._current != undefined &&
                value !== undefined &&
                this.current.id !== value.id)
        ) {
            this._current = value;
            this.cd.markForCheck();
        }
    }

    ngOnInit() {
        MESubscriptionHelper.add(
            this,
            this.benutzer.onUpdateRequired.subscribe((list: BenutzerList) => {
                this.benutzerService
                    .list(
                        list.size,
                        list.calcOffset(),
                        list.order,
                        list.simpleFilter,
                        list.getQuery()
                    )
                    .subscribe(
                        (l) => {
                            list.populateFromListResult(l);
                            this.cd.markForCheck();
                        },
                        (e) => {
                            console.log(e);
                            MERichError.throw(
                                'Fehler beim Datenabruf',
                                'Die Liste der verfügbaren Benutzer konnte nicht abgerufen werden.'
                            );
                        }
                    );
            }, undefined)
        );
    }

    ngAfterViewInit(): void {
        if (this.dt !== null) {
            this.dt.extraRowClasses = this.rowClassGetter;
        }
    }

    rowClassGetter: TMEDTGetExtraRowClassesCallback<Benutzer> = (
        row: MEDataTableRow<Benutzer>,
        idx
    ) => {
        return '';
    };

    onActivate() {
        console.log('Benutzer aktiviert!');
        this.tabActive = true;
        this.benutzer.start();
        MESubscriptionHelper.add(
            this,
            this.authorities.onUpdateRequired.subscribe(() => {
                if (this.authorities.loading) {
                    return;
                }
                this.authorities.loading = true;
                this.authorities.size = 99999;
                this.authorities.offset = 0;
                this.athService
                    .list(
                        this.authorities.size,
                        this.authorities.calcOffset(),
                        this.authorities.order,
                        this.authorities.simpleFilter,
                        this.authorities.getQuery()
                    )
                    .subscribe({
                        next: l => {
                            this.authorities.loading = false;
                            this.authorities.doPopulateFromListResult(l);
                            this.cd.markForCheck();
                        },
                        error: () => {
                            this.authorities.clear();
                        },
                    });
                this.cd.markForCheck();
            })
        );
        this.authorities.start();
        this.cd.markForCheck();
    }

    onDeactivate() {
        console.log('Benutzer deaktiviert!');
        this.tabActive = false;
        this.benutzer.disableAutoReload();
        this.authorities.disableAutoReload();
    }

    ngOnDestroy(): void {
        MESubscriptionHelper.release(this);
        this.benutzer.release();
        this.authorities.release();
    }

    onRowSelected($event: MEDataTableRowSelected<Benutzer>) {
        if (
            $event === null ||
            $event === undefined ||
            $event.row === null ||
            $event.row === undefined ||
            $event.row.data === null ||
            $event.row.data === undefined
        ) {
            this.current = null;
            return;
        }
        this.current = Benutzer.fromResult($event.row.data);
    }

    refresh_current() {
        this.benutzerService.loadUser(this.current.id).subscribe((e: Benutzer) => {
            for (let i = 0; i < this.benutzer.data.length; i++) {
                if (this.benutzer.data[i].id === e.id) {
                    console.log('found as idx ' + `$i`);
                    this.benutzer.update(i, e);
                }
            }
            if (this.current.id === e.id) {
                this.current = e;
            }

            this.cd.markForCheck();
        });
    }

    add_to_authority(ath: TAuthority) {
        if (
            this.current === null ||
            this.current === undefined ||
            ath === null ||
            ath === undefined
        ) {
            return;
        }
        this.benutzerService.add_to_authority(this.current.id, ath.id).subscribe({
            next: r => {
                if (r.success) {
                    this.refresh_current();
                } else {
                    this.toastr.error(
                        'Das Hinzufügen der Rechte ist fehlgeschlagen!'
                    );
                }
                this.cd.markForCheck();
            },
            error: () => {
                this.toastr.error('Das Hinzufügen der Rechte ist fehlgeschlagen!');
            },
        });
    }

    remove_from_authority(ath: TAuthority) {
        if (
            this.current === null ||
            this.current === undefined ||
            ath === null ||
            ath === undefined
        ) {
            return;
        }
        this.benutzerService.remove_from_authority(this.current.id, ath.id).subscribe({
            next: r => {
                if (r.success) {
                    this.refresh_current();
                } else {
                    this.toastr.error(
                        'Das Entfernen der Rechte ist fehlgeschlagen!'
                    );
                }
                this.cd.markForCheck();
            },
            error: () => {
                this.toastr.error('Das Entfernen der Rechte ist fehlgeschlagen!');
            },
        });
    }

    public editUser($event: MERowActionParams<IBenutzer>) {
        this.benutzerService.loadUser($event.row.data.id).subscribe(
            (u) => {
                UserEditorComponent.open(
                    this.modalService,
                    UserEditorComponent,
                    u
                ).then(() => {
                    this.benutzer.reload();
                }, noop);
            },
            () => {
                MERichError.throw(
                    'Fehler beim Datenabruf',
                    'Der gewählte Benutzer konnte nicht vom Server abgerufen werden'
                );
            }
        );
    }
}
