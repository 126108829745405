import {TApplications, TNullableApplications} from "./applications.types";
import {Applications} from "./applications.class";
import {MEGenericEntityFields} from "../../../maennl-commons";

export class ApplicationsImpl {

    static fromResult(
        entity: TApplications,
        createifnull:boolean=false
    ): TNullableApplications {
        if(entity===null||entity===undefined){
            if(createifnull){
                return new Applications();
            }
            return null
        }
        const instance = new Applications();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addTextFields([
                    'code',
                    'display_text',
                    'description',
                    'app_token',
                    'refresh_token_lifetime',
                ])
                .addStringsField('ip_addresses')
                .addBooleanField('enabled')
                .addLongField('id')
        );
        return instance;
    }
}