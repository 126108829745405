import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import { IMEOption } from '../forms/common';
import { MERowActionParams } from '../data-table/actions';
import { MEDataTableRow } from '../data-table/rows';

@Component({
  selector: 'me-list-option',
  template: ''
})
export class MEListOptionComponent implements IMEOption {
  @Input() label = '';
  @Input() value: any = '';
  @Input() icon = '';
  @Input() hidden = false;
  @Input() type = '';

  @Output() perform = new EventEmitter<any>();

  @Output() checkEnabled = new EventEmitter<MERowActionParams<any>>();

  @ContentChildren(MEListOptionComponent)
  listOptions: QueryList<MEListOptionComponent>;

  constructor() {}

  isHidden(row: MEDataTableRow<any> = null): boolean {
    if (row != null) {
      return row.isActionHidden(this);
    }
    return this.hidden;
  }

  performAction(row: MEDataTableRow<any> = null) {
    if (row === null || row === undefined) {
      this.perform.emit(this);
    } else if (!row.isActionDisabled(this)) {
      this.perform.emit(new MERowActionParams<any>(row, this));
    }
  }

  isNormalAction() {
    return (
      this.type === null ||
      this.type === undefined ||
      this.type === '' ||
      this.type === 'default'
    );
  }

  isSeparator() {
    return this.type === 'separator';
  }

  checkIfEnabled(row: MEDataTableRow<any>) {
    this.checkEnabled.emit(new MERowActionParams<any>(row, this));
  }

  hasIcon(): boolean {
    return this.icon !== undefined && this.icon !== null && this.icon !== '';
  }

  isDropdown() {
    return this.type === 'dropdown';
  }

  hasOptions() {
    return (
      this.listOptions !== null &&
      this.listOptions !== undefined &&
      this.listOptions.length > 0
    );
  }
}
