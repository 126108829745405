import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {IMEListResult, MEApiTool, MEResultMetaSort} from "../../../maennl-commons";
import {PositionNeedsProcessRNFilter} from "./position_needs_process_rn.filter";
import {IPositionNeedsProcessRN} from "./position_needs_process_rn.interface";
import {map, Observable, of, take} from "rxjs";
import {TNullableNumber} from "../../../maennl-commons/tools/types";
import {PositionNeedsProcessRN} from "./position_needs_process_rn.class";
import {PositionNeedsProcessRNImpl} from "./position_needs_process_rn.impl";
import {IMESimpleFilter} from "../../../maennl-commons/filters";

@Injectable({
    providedIn: 'root'
})
export class PositionNeedsProcessRNService{

    constructor(
        public http:HttpClient,
        public loginService:BenutzerService
    ) {
    }

    public list(
        max = 50,
        offset = 0,
        sort:MEResultMetaSort[]=[],
        filter:IMESimpleFilter<PositionNeedsProcessRNFilter>=null,
        queryString=''
    ): Observable<IMEListResult<IPositionNeedsProcessRN>>{
        const uri = MEApiTool.buildListURL(
            'api/webfuel/positionneedsprocessrn',
            max,
            offset,
            sort,
            filter,
            queryString
        );
        return this.http
            .get<IMEListResult<IPositionNeedsProcessRN>>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(take(1));
    }

    public get(id: TNullableNumber):Observable<PositionNeedsProcessRN>{
        if(id===null||id===undefined||id<1){
            return of(null);
        }
        const uri = MEApiTool.buildEntityURL(
            "api/webfuel/postionneedsprocessrn",
            id
        );
        return this.http
            .get<PositionNeedsProcessRN>(
                uri,
                this.loginService.getRequestOptions()
            )
            .pipe(
                map(r =>{
                    return PositionNeedsProcessRNImpl.fromResult(r);
                }),take(1)
            );
    }
}