import { MEGenericFilter } from '../../maennl-commons/filters';
import { DeviceType } from './device-type';
import { IDeviceType } from './idevice-type';

export class SimpleDeviceTypeFilter
  extends MEGenericFilter<DeviceType>
  implements IDeviceType
{
  code: string = null;
  id: number = null;
  label: string = null;
}
