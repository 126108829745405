import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  MESelectComponent,
  TMESelectItemFormatterCallback,
  TMESelectItemMatcherCallback,
  TMESelectItemTextCallback
} from '../../../../maennl-commons/forms/components/me-select';
import { MELocalizedComponent } from '../../../../maennl-commons/localized-component';
import { MEHighlitePipe } from '../../../../maennl-commons/pipes';
import { MESubscriptionHelper } from '../../../../maennl-commons/services';
import { METool } from '../../../../maennl-commons/tools';
import { CSDServerClient } from '../../commons/csdserver-client';
import { CSDServerClientList } from '../../commons/csdserver-client-list';
import { CSDServerService } from '../../services/csdserver.service';

@Component({
  selector: 'app-csdserver-client-picker',
  template: `
    <me-select
      [asMatrix]="false"
      [idField]="'id'"
      [items]="clients.data"
      [placeholder]="placeholder"
      [small]="small"
      [value]="value"
      (onSearch)="doSearch($event)"
      (onSelect)="doSelect($event)"
      [nullable]="nullable"
      [onFormat]="itemFormat"
      [onMatch]="itemMatcher"
      [onGetText]="itemText"
      [disabled]="disabled"
    ></me-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CSDServerClientPickerComponent
  extends MELocalizedComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  __classname = 'CSDServerClientPickerComponent';
  __instance = '';

  @Input() disabled = false;

  @Input() nullable = true;
  @Input() placeholder = 'Client auswählen';
  @Input() small = false;

  public _value: CSDServerClient = null;

  @Output() valueChange = new EventEmitter<CSDServerClient>();

  public clients = new CSDServerClientList();

  @ViewChild(MESelectComponent, { static: false }) meSelect: MESelectComponent;

  ngAfterViewInit(): void {
    if (this.meSelect !== null && this.meSelect !== undefined) {
      this.meSelect.onFormat = this.itemFormat;
      this.meSelect.onGetText = this.itemText;
      this.meSelect.onMatch = this.itemMatcher;
      this.clients.start();
    }
  }

  @Input()
  set value(v: CSDServerClient) {
    if (this._value !== v) {
      this._value = v;
      if (this.meSelect !== null && this.meSelect !== undefined) {
        this.meSelect.value = v;
        this.meSelect.markForCheck();
      }
      this.valueChange.emit(v);
      this.cd.markForCheck();
    }
  }

  get value(): CSDServerClient {
    return this._value;
  }

  constructor(
    public csdServerService: CSDServerService,
    public cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.clients.onUpdateRequired.subscribe((list: CSDServerClientList) => {
        this.csdServerService
          .listClients(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.getQuery()
          )
          .subscribe((l) => {
            list.populateFromListResult(l);
            if (!METool.isNullOrUndefined(this.meSelect)) {
              this.meSelect.markForCheck();
            }
          }, undefined);
      }, undefined)
    );
  }

  doSearch(value) {
    this.clients.searchString = value;
    this.clients.reload();
  }

  doSelect(value: CSDServerClient) {
    this._value = value;
    this.valueChange.emit(value);
    this.cd.markForCheck();
  }

  public itemFormat: TMESelectItemFormatterCallback<CSDServerClient> = (
    item: CSDServerClient,
    search,
    forList: boolean
  ) => {
    if (item === null || item === undefined) {
      return '';
    }

    return (
      MEHighlitePipe.transformString('' + item.info, search) +
      (METool.isEmpty(item.machineId)
        ? ''
        : '<span class="badge bg-secondary text-dark">' +
          MEHighlitePipe.transformString('' + item.machineId, search) +
          '</span>')
    );
  };

  public itemText: TMESelectItemTextCallback<CSDServerClient> = (
    item: CSDServerClient
  ) => {
    if (item === null || item === undefined) {
      return '';
    }
    return (
      item.info +
      (METool.isEmpty(item.machineId) ? '' : ' [' + item.machineId + ']')
    );
  };

  public itemMatcher: TMESelectItemMatcherCallback<CSDServerClient> = (
    item: CSDServerClient,
    search
  ) => {
    if (!search) {
      return true;
    }
    if (search.trim() === '') {
      return true;
    }
    if (!item) {
      return false;
    }
    const x =
      item.info + ' ' + item.id + ' ' + item.machineId + ' ' + item.typInfo;

    return x.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >= 0;
  };

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
    this.clients.release();
    super.ngOnDestroy();
  }
}
