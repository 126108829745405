import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Md5 } from 'ts-md5';
import { MEAddOnClickEvent, noop } from '../../../maennl-commons';
import { TNullableNumber } from '../../../maennl-commons/tools/types';
import { Firewall } from '../commons/firewall.class';
import { FirewallService } from '../commons/firewall.service';
import { TNullableFirewall } from '../commons/firewall.types';

@Component({
  selector: 'app-firewall-field',
  templateUrl: './firewall-field.component.html',
  styleUrls: ['./firewall-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FirewallFieldComponent implements OnInit {
  public _icons: string[] = [];
  @Input() nullable = true;
  @Input() label = 'Provider auswählen';
  _addOnLabels: string[] = [];
  @Input() enableAddon = false;
  @Input() id = '';
  @Input() labelWidth = 3;
  @Input() disabled = false;
  @Input() small = false;
  @Output() valueChange = new EventEmitter<Firewall>();
  @Output() valueIdChange = new EventEmitter<TNullableNumber>();
  @Output() addonClick = new EventEmitter<MEAddOnClickEvent>();

  constructor(public service: FirewallService) {
    noop();
  }

  public _value: TNullableFirewall = null;

  get value(): TNullableFirewall {
    return this._value;
  }

  @Input()
  set value(v: TNullableFirewall) {
    if (this._value !== v) {
      this._value = v;
      if (!this.disabled) {
        this.valueChange.emit(v);
        this.valueIdChange.emit(this.valueId);
      }
    }
  }

  get icon(): string[] | string {
    if (this._icons.length < 1) {
      return '';
    } else if (this._icons.length < 2) {
      return this._icons[0];
    }
    return this._icons;
  }

  @Input()
  set icon(v: string[] | string) {
    this._icons = [];
    if (Array.isArray(v)) {
      v.forEach(i => {
        this._icons.push(i);
      });
    } else {
      this._icons.push(v);
    }
    while (this._addOnLabels.length < this._icons.length) {
      this._addOnLabels.push('');
    }
  }

  @Input()
  set addonlabel(v: string[] | string) {
    this._addOnLabels = [];
    if (Array.isArray(v)) {
      v.forEach(i => {
        this._addOnLabels.push(i);
      });
    } else {
      this._addOnLabels.push(v);
    }
    while (this._addOnLabels.length < this._icons.length) {
      this._addOnLabels.push('');
    }
  }

  get valueId(): TNullableNumber {
    if (this.value === null || this.value === undefined) {
      return null;
    }
    return this.value.id;
  }

  @Input()
  set valueId(val: TNullableNumber) {
    if (val === null || val === undefined) {
      this.value = null;
      return;
    }
    if (val === this.valueId) {
      return;
    }
    this.service.get(val).subscribe({
      next: v => {
        this.value = v;
      },
      error: () => {
        this.value = null;
      },
    });
  }

  get addonIcons(): string[] {
    if (this.hasAddon()) {
      return this._icons;
    }
    return [];
  }

  ngOnInit() {
    if (this.id === '') {
      this.id =
        'tf' +
        Md5.hashStr(
          'firewall-' +
            this.label +
            `${Math.random()}` +
            '-' +
            `${Math.random()}`
        );
    }
  }

  update(event: Firewall) {
    if (!this.disabled) {
      this.value = event;
    }
  }

  hasAddon(): boolean {
    return !this.disabled && this.enableAddon && this._icons.length > 0;
  }

  aClick(i?: number) {
    const e: MEAddOnClickEvent = new MEAddOnClickEvent();
    e.component = this;
    e.addon = i;
    this.addonClick.emit(e);
  }
}
