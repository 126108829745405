import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {
    MEConfirmDialogComponent,
    MEGenericModalComponent,
    MELocalizedComponent,
    MERichError, MERowActionParams,
    MESubscriptionHelper,
    METool, noop
} from "../../../maennl-commons";
import {ApplicationsList} from "../commons/applications.list";
import {ToastrService} from "ngx-toastr";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {ApplicationsService} from "../commons/applications.service";
import {Applications} from "../commons/applications.class";
import {TNullableNumber} from "../../../maennl-commons/tools/types";
import {TabApplicationsEditorComponent} from "./tab-applications-editor/tab-applications-editor.component";

@Component({
    selector: 'app-tab-applications',
    templateUrl: './tab-applications.component.html',
    styleUrls: ['./tab-applications.component.scss']
})
export class TabApplicationsComponent
    extends MELocalizedComponent
    implements OnInit, AfterViewInit, OnDestroy {
    liste = new ApplicationsList(false, 400);

    constructor(
        public toastr: ToastrService,
        public cd: ChangeDetectorRef,
        public modalService: NgbModal,
        public benutzerService: BenutzerService,
        public applicationsService: ApplicationsService
    ) {
        super();
    }

    private _tabActive = false;

    public get tabActive(): boolean {
        return this._tabActive;
    }

    @Input()
    public set tabActive(value: boolean) {
        if (this._tabActive !== value) {
            this._tabActive = value;
            this.cd.markForCheck();
            if (this._tabActive) {
                this.liste.start();
            }
        }
    }

    ngOnInit(): void {
        MESubscriptionHelper.add(
            this,
            this.liste.onUpdateRequired.subscribe((list: ApplicationsList) => {
                if (this.tabActive) {
                    this.applicationsService
                        .list(
                            list.size,
                            list.calcOffset(),
                            list.order,
                            list.simpleFilter,
                            list.getQuery()
                        )
                        .subscribe({
                            next: (l) => {
                                list.populateFromListResult(l);
                                this.cd.markForCheck();
                            },
                            error: (e) => {
                                console.log(e);
                                MERichError.throw(
                                    'Fehler beim Datenabruf',
                                    'Die Liste der Webfuel Anwendungen konnte nicht abgerufen werden.'
                                );
                            }
                        });
                }
            })
        );
    }

    onActivate() {
        this.tabActive = true;
        console.log('activated');
    }

    onDeactivate() {
        this.tabActive = false;
        console.log('deactivated');
    }

    public ngAfterViewInit(): void {
    }

    public ngOnDestroy(): void {
        MESubscriptionHelper.release(this);
        this.liste.release();
    }

    typed(data: any): Applications[] {
        if (METool.isNullOrUndefined(data)) {
            return [];
        }
        return [data as Applications];
    }

    addEntry() {
        TabApplicationsEditorComponent.open(
            this.modalService,
            TabApplicationsEditorComponent,
            null,
            MEGenericModalComponent.SIZE_MEDIUM,
            null
        ).then(() => {
            this.liste.start()
        }, noop());
    }

    editEntry($event: MERowActionParams<Applications>) {
        this.applicationsService.get($event.row.data.id).subscribe((a) => {
            if (a !== null && a !== undefined && a.id > 0) {
                TabApplicationsEditorComponent.open(
                    this.modalService,
                    TabApplicationsEditorComponent,
                    a,
                    MEGenericModalComponent.SIZE_MEDIUM,
                    null
                ).then(() => {
                    this.liste.start()
                }, noop());
            }
        });
        this.refresh_liste($event.row.data.id);
    }

    refresh_liste(id: TNullableNumber) {
        this.applicationsService.get(id).subscribe((a) => {
            for (let i = 0; i < this.liste.data.length; i++) {
                if (this.liste.data[i].id === a.id) {
                    this.liste.update(i, a);
                    break;
                }
            }
            this.cd.markForCheck();
        });
    }

    removeEntry($event:MERowActionParams<Applications>) {
        if(!METool.isNullOrUndefined(this.applicationsService.get($event.row.data.id))) {
            MEConfirmDialogComponent.display(
                this.modalService,
                "Anwendungslöschung bestätigen",
                "Soll die Anwendung wirklich unwiderruflich gelöscht werden?",
                "Ja, jetzt löschen",
                "icofont icofont-delete"
            ).then(()=>{
                this.applicationsService.get($event.row.data.id).subscribe((a:Applications)=>{
                    if(a.id!==null&&a.id!==undefined&&a.id>0) {
                        this.applicationsService.delete(a.id).subscribe(()=>{
                            this.liste.start();
                        });
                    }
                });
            })
        }
    }
}