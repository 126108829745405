import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MERichError, MESubscriptionHelper } from '../../../maennl-commons';
import { ToastrService } from 'ngx-toastr';
import { MwstKorrekturList } from '../commons/mwst-korrektur-list';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import { PreiseService } from '../services/preise.service';
import { NebenstelleRendererComponent } from '../nebenstelle-renderer/nebenstelle-renderer.component';

@Component({
  selector: 'app-tab-mwstkorrektur',
  templateUrl: './tab-mwstkorrektur.component.html',
  styleUrls: ['./tab-mwstkorrektur.component.scss']
})
export class TabMwstkorrekturComponent implements OnInit, OnDestroy {
  liste = new MwstKorrekturList();

  nebenstelleR = NebenstelleRendererComponent;

  private _tabActive = false;

  public get tabActive(): boolean {
    return this._tabActive;
  }

  @Input()
  public set tabActive(value: boolean) {
    if (this._tabActive !== value) {
      this._tabActive = value;
      this.cd.markForCheck();
      if (this._tabActive) {
        this.liste.start();
      }
    }
  }

  constructor(
    public toastr: ToastrService,
    public cd: ChangeDetectorRef,
    public benutzerService: BenutzerService,
    public preiseService: PreiseService
  ) {}

  ngOnInit(): void {
    MESubscriptionHelper.add(
      this,
      this.liste.onUpdateRequired.subscribe((list: MwstKorrekturList) => {
        this.preiseService
          .listMwstKorrekturen(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter,
            list.getQuery()
          )
          .subscribe(
            (l) => {
              list.populateFromListResult(l);
              this.cd.markForCheck();
            },
            (e) => {
              console.log(e);
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste der MwStKorrekturen konnte nicht abgerufen werden.'
              );
            }
          );
      }, undefined)
    );
  }

  onActivate() {
    this.tabActive = true;
    console.log('activated');
  }

  onDeactivate() {
    this.tabActive = false;
    console.log('deactivated');
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
  }
}
