import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import {
  IGenericObject,
  IMEActionResponse,
  IMEListResult,
  MEActionResponse,
  MEApiTool,
  MEResultMetaSort
} from '../../../maennl-commons';
import { IVehicleTracker } from './ivehicle-tracker';
import { Observable } from 'rxjs';
import { IMESimpleFilter } from '../../../maennl-commons/filters';
import { map, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VehicleTrackerService {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public list(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IVehicleTracker> = null,
    queryString = ''
  ): Observable<IMEListResult<IVehicleTracker>> {
    const uri = MEApiTool.buildListURL(
      '/api/webfuel/tracker/tracker',
      max,
      offset,
      sort,
      filter,
      queryString
    );
    return this.http
      .get<IMEListResult<IVehicleTracker>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1));
  }

  moveTracker(
    id: number,
    options: IGenericObject
  ): Observable<MEActionResponse> {
    const uri = MEApiTool.buildActionURL(
      '/api/webfuel/tracker/tracker',
      id,
      '_move_tracker'
    );
    return this.http
      .post<IMEActionResponse>(
        uri,
        options,
        this.loginService.getRequestOptions()
      )
      .pipe(
        map((r) => {
          return MEActionResponse.fromRawActionResponse(r);
        }),
        take(1)
      );
  }

  patch(id: number, data: IGenericObject): Observable<MEActionResponse> {
    const uri = MEApiTool.buildEntityURL('/api/webfuel/tracker/tracker', id);
    return this.http
      .patch<IMEActionResponse>(
        uri,
        data,
        this.loginService.getRequestOptions()
      )
      .pipe(
        map((r) => {
          return MEActionResponse.fromRawActionResponse(r);
        }),
        take(1)
      );
  }

  delete(id: number): Observable<MEActionResponse> {
    const uri = MEApiTool.buildEntityURL('/api/webfuel/tracker/tracker', id);
    return this.http
      .delete<IMEActionResponse>(uri, this.loginService.getRequestOptions())
      .pipe(
        map((r) => {
          return MEActionResponse.fromRawActionResponse(r);
        }),
        take(1)
      );
  }
}
