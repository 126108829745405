import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-baustelle',
  templateUrl: './baustelle.component.html',
  styleUrls: ['./baustelle.component.scss']
})
export class BaustelleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
