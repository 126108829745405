import {TNullableTerminal, TTerminal} from "./terminal.types";
import {Terminal} from "./terminal.class";
import {MEGenericEntityFields} from "../../../maennl-commons";

export class TerminalImpl {

    static fromResult(
        entity: TTerminal,
        createifNull=false
    ): TNullableTerminal{
        if(entity===null||entity===undefined){
            if(createifNull){
                return new Terminal();
            }
            return null
        }

        const instance= new Terminal();
        instance.updateFromResult(
            entity,
            new MEGenericEntityFields()
                .addLongFields([
                    'id',
                    'station',
                    'boxnummer',
                    'inventory_type',
                    'nummer',
                    'tankserver_id',
                    'tenant',
                    'terminal_type',
                ])
                .addTextFields([
                    'display_text',
                    'identcode',
                    'last_failure',
                    'settings_text',
                ])
                .addDateFields([
                    'date_created',
                    'error_state_change',
                    'last_updated',
                ])
                .addBooleanFields([
                    'failed',
                ])
        );
        return instance;
    }
}