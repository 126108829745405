import { METool } from '../../maennl-commons';
import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields,
} from '../../maennl-commons/megeneric-entity';
import { CheckParams, check_check_params } from './check-params';
import { CheckResult } from './check-result';
import { DUCheckResultInfo } from './du-check-result-info';
import { ICheck } from './icheck';
import { Machine } from './machine';
import { ServiceType } from './service-type';

export class Check extends MEGenericEntity<ICheck> implements ICheck {
  public __classname__ = 'check';
  public _type = 'SysHealthCheck';

  resultInfo = '';
  id = 0;
  machine: Machine = null;
  serviceType: ServiceType = null;
  info = '';
  lastCheck: Date = null;
  checksEnabled = false;
  waitBetween = 120;
  result: CheckResult = null;
  params: CheckParams = null;
  dateCreated = new Date();
  failedCount = 0;
  lastResultChange: Date = null;
  lastUpdated: Date = null;
  previousResult: CheckResult = null;
  message = '';
  noAlarm = false;

  service: string = null;

  static fromResult(entity: ICheck, createIfNull: boolean = false): Check {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Check();
      }
      return null;
    }
    const instance = new Check();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addConverterField(
          'machine',
          new MEGenericEntityConverterParams<Machine>((value: any) => {
            return Machine.fromResult(value);
          })
        )
        .addConverterField(
          'serviceType',
          new MEGenericEntityConverterParams<ServiceType>((value: any) => {
            return ServiceType.fromResult(value);
          })
        )
        .addTextField('info')
        .addDateField('lastCheck')
        .addDateField('dateCreated')
        .addDateField('lastUpdated')
        .addDateField('lastResultChange')
        .addBooleanField('checksEnabled')
        .addBooleanField('noAlarm')
        .addLongField('waitBetween')
        .addLongField('failedCount')
        .addConverterField(
          'result',
          new MEGenericEntityConverterParams<CheckResult>((value: any) => {
            return CheckResult.fromResult(value);
          })
        )
        .addConverterField(
          'previousResult',
          new MEGenericEntityConverterParams<CheckResult>((value: any) => {
            return CheckResult.fromResult(value);
          })
        )
        .addConverterField(
          'params',
          new MEGenericEntityConverterParams<CheckParams>((value: any) => {
            let p = value as CheckParams;
            check_check_params(p);
            return p;
          })
        )
        .addTextField('resultInfo')
        .addTextField('message'),
      (e: Check) => {
        e.__classname__ = 'check';
        e._type = 'SysHealthCheck';
        e.detectService();
      }
    );

    return instance;
  }

  constructor() {
    super();
  }

  detectService() {
    this.service = 'Unbekannt';
    if (this.serviceType === null) {
      return;
    }
    if (this.serviceType.code === 'service') {
      if (
        this.params !== null &&
        this.params !== undefined &&
        this.params.service !== null &&
        this.params.service !== undefined
      ) {
        this.service = this.params.service;
      }
    }
    if (this.serviceType.code === 'systemd') {
      if (
        this.params !== null &&
        this.params !== undefined &&
        this.params.service !== null &&
        this.params.service !== undefined
      ) {
        this.service = this.params.service;
      }
    }
    if (this.serviceType.code === 'docker') {
      if (
        this.params !== null &&
        this.params !== undefined &&
        this.params.container !== null &&
        this.params.container !== undefined
      ) {
        this.service = this.params.container;
      }
    }
  }

  is_valid_result() {
    if (
      METool.isNullOrUndefined(this.resultInfo) ||
      METool.isEmpty(this.resultInfo)
    ) {
      return false;
    }

    try {
      let a = JSON.parse(this.resultInfo);
      return !METool.isNullOrUndefined(a) && a !== '';
    } catch (e) {
      return false;
    }
  }

  _duResultInfo: DUCheckResultInfo = null;

  du_result(): DUCheckResultInfo[] {
    if (!METool.isNullOrUndefined(this._duResultInfo)) {
      return [this._duResultInfo];
    }
    if (
      METool.isNullOrUndefined(this.resultInfo) ||
      METool.isEmpty(this.resultInfo)
    ) {
      return [];
    }
    try {
      let a = JSON.parse(this.resultInfo);
      if (!METool.isNullOrUndefined(a) && a !== '') {
        this._duResultInfo = a as DUCheckResultInfo;
        return [this._duResultInfo];
      }
    } catch (e) {
      this._duResultInfo = null;
    }
    return [];
  }
}
