<ng-template #ahIcon>
    <fa-icon [icon]="['far', 'server']"></fa-icon>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Imports"
></me-area-heading>

<me-tab-card #rootTabCard>
    <me-pane id="tab-imports-jobs"
             title="ausstehende Jobs"
             (activated)="activate(importsJobsTab)">
        <app-tab-imports-jobs #importsJobsTab></app-tab-imports-jobs>
    </me-pane>
</me-tab-card>