import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {TenantHosts} from "../../commons/tenant-hosts.class";
import {MEGenericModalComponent, MEPubSubService} from "../../../../maennl-commons";
import {TenantHostsImpl} from "../../commons/tenant-hosts.impl";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TenantHostsService} from "../../commons/tenant-hosts.service";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-tab-tenant-hosts-editor',
    templateUrl: './tab-tenant-host-editor.component.html',
    styleUrls: ['./tab-tenant-host-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabTenantHostEditorComponent
    extends MEGenericModalComponent<TenantHosts>
    implements OnInit,OnDestroy
{
    entity: TenantHosts=null;

    public initEntity(src: TenantHosts): TenantHosts{
        return TenantHostsImpl.fromResult(src,true);
    }

    constructor(
        public activeModal: NgbActiveModal,
        public pubsub: MEPubSubService,
        public service: TenantHostsService,
        public toastr: ToastrService,
        public cd: ChangeDetectorRef
    ) {
        super(activeModal, pubsub);
    }

    ngOnInit() {}

    save() {
        this.service.store(this.entity).subscribe({
            next:(r)=>{
                if(r.success){
                    this.ok();
                } else {
                    this.toastr.error('Fehler beim Speichern der Daten');
                }
            },
            error:() =>{
                this.toastr.error('Fehler beim Speichern der Daten');
            }
        });
    }
}