import { IGenericObject } from '../../../maennl-commons';
import { VehicleTracker } from '../commons/vehicle-tracker';
import { ChangeDetectorRef } from '@angular/core';
import { Vehicle } from '../../vehicles/commons/vehicle';

interface IOptions extends IGenericObject {
  tracker: VehicleTracker;
  vehicle: Vehicle;
}

export class TrackerMitschnittPopupOptions {
  data: IOptions = {
    tracker: null,
    vehicle: null,
  };
  private _cd: ChangeDetectorRef = null;

  get cd(): ChangeDetectorRef {
    return this._cd;
  }

  set cd(value: ChangeDetectorRef) {
    this._cd = value;
  }

  get tracker() {
    return this.data.tracker;
  }

  set tracker(v: VehicleTracker) {
    this.data.tracker = v;
    this.mfc();
  }

  mfc() {
    if (this.cd !== null && this.cd !== undefined) {
      this.cd.markForCheck();
    }
  }

  copyFrom(src: TrackerMitschnittPopupOptions) {
    this.clear();
    Object.keys(src.data).forEach(k => {
      this.data[k] = src.data[k];
    });
    this.mfc();
  }

  private clear() {
    this.data.tracker = null;
    this.mfc();
  }
}
