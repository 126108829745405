import { Component, Injector } from '@angular/core';
import { MEDataTableColumnComponent } from '../../../maennl-commons/data-table/columns/me-data-table-column.component';
import { MEDataTableCommonCellRenderer } from '../../../maennl-commons/data-table/renderer/mecommon-cell-renderer';
import { MEDataTableRow } from '../../../maennl-commons/data-table/rows/medata-table-row';
import { Sim } from '../../common/sim';

@Component({
  selector: 'app-medtsim-renderer',
  template: `
    <app-sim-display *ngIf="isCellValid()" [value]="cell"></app-sim-display>
  `
})
export class MEDTSimRendererComponent extends MEDataTableCommonCellRenderer {
  public cell: Sim = null;
  public column: MEDataTableColumnComponent = null;
  public row: MEDataTableRow<any> = null;

  constructor(public injector: Injector) {
    super();
    // noinspection JSDeprecatedSymbols
    this.cell = this.injector.get('cell');
    // noinspection JSDeprecatedSymbols
    this.row = this.injector.get('row');
    // noinspection JSDeprecatedSymbols
    this.column = this.injector.get('column');
  }
}
