import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { ISonde } from './isonde';
import { SimpleSondeFilter } from './simple-sonde-filter';
import { Sonde } from './sonde';

export class SondeList extends MERemoteList<Sonde> {
  __classname = 'SondeList';
  __instance = '';

  static fromListResult(res: IMEListResult<ISonde>): SondeList {
    const newList = new SondeList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleSondeFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'bezeichnung',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }
}
