export class MEDataTableSortChanged {
  public field: string;
  public direction: string;
  public add = false;
  public idx = 0;

  constructor(field: string, direction: string, idx = 0, add = false) {
    this.field = field;
    this.direction = direction;
    this.add = add;
    this.idx = idx;
  }
}
