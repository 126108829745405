import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';

import {
  MEGenericModalComponent,
  MEPubSubService,
} from '../../../maennl-commons';
import { Firewall } from '../commons/firewall.class';
import { FirewallImpl } from '../commons/firewall.impl';
import { FirewallService } from '../commons/firewall.service';
import { TFirewall, TNullableFirewall } from '../commons/firewall.types';

@Component({
  selector: 'app-firewall-editor',
  templateUrl: './firewall-editor.component.html',
  styleUrls: ['./firewall-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirewallEditorComponent
  extends MEGenericModalComponent<Firewall>
  implements OnInit, OnDestroy
{
  entity: TNullableFirewall = null;

  public initEntity(src: TFirewall): TNullableFirewall {
    return FirewallImpl.fromResult(src, true);
  }

  constructor(
    public activeModal: NgbActiveModal,
    public pubsub: MEPubSubService,
    public service: FirewallService,
    public toastr: ToastrService,
    public cd: ChangeDetectorRef
  ) {
    super(activeModal, pubsub);
  }

  ngOnInit(): void {
    this.cd.markForCheck();
  }

  save() {
    this.service.store(this.entity).subscribe({
      next: r => {
        if (r.success) {
          this.ok();
        } else {
          this.toastr.error('Fehler beim Speichern der Daten');
        }
      },
      error: () => {
        this.toastr.error('Fehler beim Speichern der Daten');
      },
    });
  }
}
