<div *ngIf="hasToolbar()" class="table-toolbar d-flex justify-content-between">
  <div
    class="d-flex justify-content-start mb-1"
    *ngIf="
      tabledata !== null &&
      tabledata !== undefined &&
      cols.length > 0 &&
      hasTableActions() &&
      tableActionToolbar()
    ">
    <div *ngFor="let a of tableactions" class="d-inline-block me-1">
      <button
        *ngIf="a.isNormalAction()"
        [ngClass]="a.getClasses('btn btn-secondary')"
        (click)="a.performAction()"
        title="{{ a.title }}">
        <i [ngClass]="a.icon" *ngIf="a.hasIcon()"></i> {{ a.label }}
      </button>
      <div *ngIf="a.isDropdown() && a.hasOptions()">
        <div class="dropdown">
          <button
            class="btn btn-secondary dropdown-toggle"
            type="button"
            [attr.id]="'dropdownMenuButton' + a.id"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <i [ngClass]="a.icon" *ngIf="a.hasIcon()"></i> {{ a.label }}
          </button>
          <div
            class="dropdown-menu"
            [attr.aria-labelledby]="'dropdownMenuButton' + a.id">
            <a
              class="dropdown-item"
              *ngFor="let o of a.listOptions"
              (click)="o.performAction()">
              <i [ngClass]="o.icon" *ngIf="o.hasIcon()"></i> {{ o.label }}
            </a>
          </div>
        </div>
        <ng-content select="a.id"></ng-content>
      </div>
      <div class="btn-group" *ngIf="a.isDropdownOptions() && a.hasOptions()">
        <button
          type="button"
          [ngClass]="a.getClasses('btn btn-secondary')"
          (click)="a.performAction()"
          [attr.id]="'anchorMenuButton' + a.id">
          <i [ngClass]="a.icon" *ngIf="a.hasIcon()"></i> {{ a.label }}
        </button>
        <button
          type="button"
          class="dropdown-toggle dropdown-toggle-split"
          [ngClass]="a.getClasses('btn btn-secondary')"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          [attr.id]="'dropdownMenuButton' + a.id">
          <span class="sr-only">Toggle Dropdown</span>
        </button>
        <div
          class="dropdown-menu"
          [attr.aria-labelledby]="'anchorMenuButton' + a.id">
          <a
            class="dropdown-item"
            *ngFor="let o of a.listOptions"
            (click)="o.performAction()">
            <i [ngClass]="o.icon" *ngIf="o.hasIcon()"></i> {{ o.label }}
          </a>
        </div>
      </div>
    </div>
    <div
      class=""
      *ngIf="
        extraTableActions !== null &&
        extraTableActions !== undefined &&
        extraTableActions.length > 0
      ">
      <ng-content select="me-data-table-extra-table-action"></ng-content>
    </div>
  </div>
  <div
    *ngIf="
      !(
        tabledata !== null &&
        tabledata !== undefined &&
        cols.length > 0 &&
        hasTableActions() &&
        tableActionToolbar()
      ) && hasTableActionContainers()
    ">
    &nbsp;
  </div>
  <div *ngIf="hasTableActionContainers()" class="tableActionContainer">
    <ng-content select="me-data-table-table-extra-header"></ng-content>
  </div>
</div>

<div
  class="table-container"
  *ngIf="tabledata !== null && tabledata !== undefined && cols.length > 0"
  [class.single-selected]="singleSelect && hasSelectedRows()"
  [ngStyle]="extraContainerStyle">
  <div [class.table-responsive]="responsive">
    <table class="table" [class.table-hover]="selectable">
      <thead>
        <tr class="dt-header-row">
          <th *ngIf="showFirstColumn()" class="select-box-column">
            <a
              (click)="selectAll()"
              *ngIf="selectable && !singleSelect && !hideCheckboxes">
              <fa-icon
                [icon]="['far', 'check-square']"
                *ngIf="!allRowsSelected()"></fa-icon>
              <fa-icon
                [icon]="['far', 'square']"
                *ngIf="allRowsSelected()"></fa-icon>
            </a>
          </th>
          <th
            *ngFor="let c of columns"
            [ngClass]="c.getColumnClasses('header')"
            [class.hidden]="!c.visible">
            {{ c.displayLabel() }}
            <div class="sort-buttons" *ngIf="c.isSortable()">
              <me-sort-buttons
                [field]="c.field"
                [orders]="orders"
                (onClick)="onSortChanged($event)"></me-sort-buttons>
            </div>
          </th>
          <th class="actions" *ngIf="hasActionsColumn()">
            <div
              class="dropdown"
              *ngIf="hasTableActions() && !tableActionToolbar()">
              <a
                class="menu-dots"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <fa-icon [icon]="actionsicon.replace('fa-', '')"></fa-icon>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <a
                  *ngFor="let a of tableactions"
                  class="dropdown-item"
                  [ngClass]="a.getClasses('dropdown-item')"
                  (click)="a.performAction()">
                  <i class="fa-fw {{ a.icon }}" *ngIf="a.hasIcon()"></i>
                  {{ a.label }}
                </a>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="isLoading">
        <tr class="dt-body-row">
          <td *ngIf="showFirstColumn()" class="select-box-column"></td>
          <td [colSpan]="columns.length">
            <div class="alert alert-info text-center">
              {{ _('Daten werden geladen...') }}
            </div>
          </td>
          <th class="actions" *ngIf="hasActionsColumn()">
            <i class="icofont icofont-filter"></i>
          </th>
        </tr>
      </tbody>
      <tbody *ngIf="!isLoading">
        <tr *ngIf="showInsertRow" class="dt-insert-row">
          <td *ngIf="showFirstColumn()" class="select-box-column">
            <i class="far fa-plus"></i>
          </td>
          <td
            *ngFor="let c of columns"
            class="{{ c.extraClasses('insert') }} column-{{
              c.id.toLowerCase()
            }} column-{{ c.field.toLowerCase() }}  {{
              c.type.toLowerCase()
            }}-column"
            [class.hidden]="!c.visible">
            <me-data-table-cell-editor
              [componentData]="
                c.getEditor(newEntity[c.field], self)
              "></me-data-table-cell-editor>
          </td>
          <td class="actions">
            <div class="btn-group btn-group-sm" role="group">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                (click)="saveNew()">
                <i class="icofont icofont-save"></i>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                (click)="cancelNew()">
                <i class="icofont icofont-close"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr
          *ngFor="let row of tabledata; let idx = index"
          class="dt-body-row {{ getExtraRowClasses(row, idx, tabledata) }}"
          [class.row-selectable]="selectable && !row.showEditor"
          [class.selected]="selectable && row.selected && !row.showEditor"
          (mouseover)="mouseOverRow($event, row)">
          <td *ngIf="showFirstColumn()" class="select-box-column">
            <a
              (click)="toggleSelect(row)"
              *ngIf="selectable && !row.showEditor && !hideCheckboxes">
              <fa-icon
                [icon]="['far', 'check-square']"
                *ngIf="row.selected"></fa-icon>
              <fa-icon
                [icon]="['far', 'square']"
                *ngIf="!row.selected"></fa-icon>
            </a>
            <i class="far fa-edit" *ngIf="row.showEditor"></i>
          </td>
          <td
            *ngFor="let c of columns"
            [ngClass]="c.getColumnClasses(row.data[c.realField], row)"
            (click)="toggleSelect(row)"
            [title]="c.displayTitle(row)">
            <div
              *ngIf="(!row.showEditor || !c.isEditable()) && c.hasFormatter()"
              [innerHTML]="
                c.formatter(row.data[c.realField], c, row, 'row') | MESaveHtml
              "></div>
            <div
              *ngIf="
                (!row.showEditor || !c.isEditable()) &&
                c.hasTemplate() &&
                !c.hasRenderer()
              ">
              <ng-template
                [ngTemplateOutlet]="c.template"
                [ngTemplateOutletContext]="{
                  item: row.data[c.realField],
                  row: row,
                  column: c,
                  list: tabledata,
                  idx: idx
                }"></ng-template>
            </div>
            <me-data-table-cell-renderer
              [componentData]="row.renderers[c.field]"
              *ngIf="
                (!row.showEditor || !c.isEditable()) &&
                !c.hasFormatter() &&
                !c.hasTemplate()
              "></me-data-table-cell-renderer>
            <me-data-table-cell-editor
              [componentData]="c.getEditor(row.data[c.realField], self, row)"
              *ngIf="
                row.showEditor && c.isEditable()
              "></me-data-table-cell-editor>
          </td>
          <th class="actions" *ngIf="hasActionsColumn()">
            <div
              class="dropdown"
              *ngIf="
                hasRowActions() && !row.showEditor && !rowActionsAsButtons
              ">
              <a
                class="menu-dots"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="fa-fw {{ actionsicon }}"></i>
              </a>

              <ul class="dropdown-menu dropdown-menu-end">
                <li
                  *ngFor="let a of rowactions"
                  class=""
                  [class.dropdown-item]="!a.isSeparator()"
                  [class.dropdown-divider]="a.isSeparator()"
                  [class.disabled]="row.isActionDisabled(a)"
                  [class.hide]="a.isHidden(row)">
                  <a
                    (click)="a.performAction(row)"
                    *ngIf="!a.isSeparator()"
                    title="{{ a.title }}">
                    <i class="fa-fw {{ a.icon }}" *ngIf="a.hasIcon()"></i>
                    {{ a.label }}
                  </a>
                </li>
              </ul>
            </div>
            <div
              class="btn-group btn-group-sm"
              role="group"
              *ngIf="hasRowActions() && !row.showEditor && rowActionsAsButtons">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                *ngFor="let a of rowactions"
                [class.hide]="a.isSeparator() || a.isHidden(row)"
                [class.disabled]="row.isActionDisabled(a)"
                (click)="a.performAction(row)"
                title="{{ a.title }}">
                <i
                  class="{{ a.icon }}"
                  *ngIf="a.hasIcon() && !a.isSeparator()"></i>
                <span *ngIf="a.showLabel && !a.isSeparator()">
                  {{ a.label }}</span
                >
              </button>
            </div>
            <div
              class="btn-group btn-group-sm"
              role="group"
              *ngIf="row.showEditor">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                (click)="saveRow(row)">
                <i class="icofont icofont-save"></i>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                (click)="cancelRow(row)">
                <i class="icofont icofont-close"></i>
              </button>
            </div>
          </th>
        </tr>
      </tbody>
      <tfoot *ngIf="!isLoading">
        <tr *ngIf="showFooter()" class="dt-footer-row">
          <th *ngIf="showFirstColumn()" class="select-box-column"></th>
          <th
            *ngFor="let c of columns"
            [ngClass]="c.getColumnClasses('footer')"
            [class.hidden]="!c.visible">
            <div
              *ngIf="c.hasFormatter() && !c.hasFooterTemplate()"
              [innerHTML]="
                c.formatter(
                  c.getFooterValue(c.realField, tabledata, 'footer'),
                  c,
                  null,
                  'footer'
                ) | MESaveHtml
              "></div>
            <me-data-table-cell-renderer
              [componentData]="
                c.getRenderer(
                  c.getFooterValue(c.realField, tabledata, 'footer'),
                  null
                )
              "
              *ngIf="
                !c.hasFormatter() && !c.hasFooterTemplate()
              "></me-data-table-cell-renderer>
            <div *ngIf="c.hasFooterTemplate()">
              <ng-template
                [ngTemplateOutlet]="c.footerTemplate"
                [ngTemplateOutletContext]="{
                  footer: 'footer',
                  column: c,
                  list: tabledata,
                  item: c.getFooterValue(c.realField, tabledata, 'footer')
                }"></ng-template>
            </div>
          </th>
          <th class="actions" *ngIf="hasActionsColumn()"></th>
        </tr>
        <tr *ngIf="showFooter2()" class="dt-footer-row2">
          <th *ngIf="showFirstColumn()" class="select-box-column"></th>
          <th
            *ngFor="let c of columns"
            [ngClass]="c.getColumnClasses('footer2')"
            [class.hidden]="!c.visible">
            <div
              *ngIf="c.hasFormatter() && !c.hasFooterTemplate()"
              [innerHTML]="
                c.formatter(
                  c.getFooterValue(c.realField, tabledata, 'footer2'),
                  c,
                  null,
                  'footer2'
                ) | MESaveHtml
              "></div>
            <me-data-table-cell-renderer
              [componentData]="
                c.getRenderer(
                  c.getFooterValue(c.realField, tabledata, 'footer2'),
                  null
                )
              "
              *ngIf="
                !c.hasFormatter() && !c.hasFooterTemplate()
              "></me-data-table-cell-renderer>
            <div *ngIf="c.hasFooterTemplate()">
              <ng-template
                [ngTemplateOutlet]="c.footerTemplate"
                [ngTemplateOutletContext]="{
                  footer: 'footer2',
                  column: c,
                  list: tabledata,
                  item: c.getFooterValue(c.realField, tabledata, 'footer')
                }"></ng-template>
            </div>
          </th>
          <th class="actions" *ngIf="hasActionsColumn()"></th>
        </tr>
        <tr *ngIf="isFilterable()" class="dt-filter-row">
          <th *ngIf="showFirstColumn()" class="select-box-column">
            <i class="icofont icofont-filter"></i>
          </th>
          <th
            *ngFor="let c of columns"
            [ngClass]="c.getColumnClasses('filter')"
            [class.hidden]="!c.visible">
            <me-data-table-cell-filter
              [componentData]="
                c.getFilter(filter, self)
              "></me-data-table-cell-filter>
          </th>
          <th class="actions" *ngIf="hasActionsColumn()">
            <i class="icofont icofont-filter"></i>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
