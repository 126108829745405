<div
        class="d-flex align-items-start justify-content-between"
        *ngIf="interfaceListe !== null && interfaceListe !== undefined">
    <div class="flex-fill">
        <me-data-table
            #wgInterfaceTable
            [rowActionsAsButtons]="true"
            [tableActionsAsButtons]="true"
            [data]="interfaceListe.asDataTableRows()"
            [selectable]="true"
            [singleSelect]="true"
            [hideCheckboxes]="true"
            actionsicon="icofont icofont-navigation-menu"
            (rowSelected)="onRowSelected($event)">
            <me-data-table-column
                    [label]="'ID'"
                    [field]="'id'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Name'"
                    [field]="'display_name'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Info'"
                    [field]="'info'"></me-data-table-column>
            <ng-template #keys let-p="item" let-row="row">
                <div title="public-key">
                    {{p}}
                </div>
                <div title="private-key">
                    {{row.data.private_key}}
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'Key-Pair'"
                    [field]="'public_key'"
            [template]="keys"></me-data-table-column>
            <me-data-table-column
                    [label]="'Port'"
                    [field]="'port'"
            ></me-data-table-column>
            <me-data-table-column
                    [label]="'Clients'"
                    [field]="'peers'"
            ></me-data-table-column>

            <me-data-table-action
                    label="Eintrag hinzufügen"
                    [icon]="'far fa-plus'"
                    (perform)="addInterface()"
            ></me-data-table-action>

            <me-data-table-rowaction
                    (perform)="editInterface($event)"
                    icon="far fa-pencil"
                    label="Bearbeiten"
                    title="Eintrag bearbeiten"
            ></me-data-table-rowaction>

            <me-data-table-rowaction
                    (perform)="removeInterface($event)"
                    icon="far fa-trash"
                    label="Löschen"
                    title="Eintrag löschen"
            ></me-data-table-rowaction>

            <me-data-table-table-extra-header>
                <me-pagination
                        [total]="interfaceListe.total"
                        [page]="interfaceListe.page"
                        [perpage]="interfaceListe.size"
                        (pageChange)="interfaceListe.setPage($event)"
                        (pageCountChange)="interfaceListe.processPageCountChangedEvent($event)"
                        (queryChange)="interfaceListe.processQueryChangedEvent($event)"
                        settingsid="wireguard-info"
                        [settingsService]="benutzerService"
                        [showSearch]="false"></me-pagination>
            </me-data-table-table-extra-header>
        </me-data-table>
    </div>
    <ng-container #detailTabs *ngIf="wgInterfaceTable.hasSelectedRows()" [ngTemplateOutlet]="details"></ng-container>
</div>
<ng-template #details>
    <ng-container *ngIf="current._clients.length>0">
        <div class="ml-2 ms-2 ps-2 pl-2" style="min-width: 450px">
            <me-tab-card>
                <me-pane id="subtab-wg-interface-clients-{{current.id}}"
                         title="Zugeordnete Clients">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="flex-fill ms-2 ml-2">
                            <ng-container *ngFor="let client of current._clients; index as i">
                                <div style="display: flex; flex-direction: row; padding-bottom: 5px">
                                    <div style="display: flex;justify-content: left;flex-basis: 80%">
                                        <span>{{client.display_name}}</span>
                                    </div>
                                    <div style="display: flex;justify-content: right;flex-basis: 20%">
                                        <button
                                                class="btn btn-sm btn-secondary"
                                                type="button"
                                                title="IP löschen"
                                                (click)="removeClient(i)">
                                            <i class="far fa-trash"></i>
                                        </button>
                                        <br>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </me-pane>
            </me-tab-card>
        </div>
    </ng-container>
</ng-template>