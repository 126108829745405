import { METool } from '../../../maennl-commons/tools';

export class GenericChunk {
  maxBytes = 16;

  hexBytes: string[] = [];
  asciiBytes: string[] = [];
  bytes: number[] = [];

  public add(hexByte: string) {
    this.hexBytes.push(hexByte);
    const c = parseInt(hexByte, 16);

    if (METool.isNaN(c)) {
      this.bytes.push(-1);
      this.asciiBytes.push('');
    } else {
      this.bytes.push(c);
      if (c > 31) {
        this.asciiBytes.push(String.fromCharCode(c));
      } else {
        this.asciiBytes.push('');
      }
    }
  }

  public isFull() {
    return this.bytes.length >= this.maxBytes;
  }

  public hasBytes() {
    return this.bytes.length > 0;
  }
}
