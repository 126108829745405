<div
        class="d-flex align-items-start justify-content-between"
        *ngIf="clientListe !== null && clientListe !== undefined">
    <div class="flex-fill">
        <me-data-table
            #wgClientTable
            [rowActionsAsButtons]="true"
            [tableActionsAsButtons]="true"
            [data]="clientListe.asDataTableRows()"
            [selectable]="true"
            [singleSelect]="true"
            [hideCheckboxes]="true"
            actionsicon="icofont icofont-navigation-menu"
            (rowSelected)="onRowSelected($event)">
            <me-data-table-column
                    [label]="'ID'"
                    [field]="'id'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Name'"
                    [field]="'display_name'"></me-data-table-column>
            <me-data-table-column
                    [label]="'Public-Key'"
                    [field]="'pub_key'"></me-data-table-column>
            <ng-template #traffic let-p="item" let-row="row">
                <div title="empfangen">
                    {{p!=null ? p.replace('i', ''):'0 B' }} <i class="far fa-arrow-to-left"></i>
                </div>
                <div title="gesendet">
                    {{row.data.bytes_out!=null? row.data.bytes_out.replace('i', ''):'0 B'}} <i class="far fa-arrow-from-left"></i>
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'Traffic'"
                    [field]="'bytes_in'"
                    [template]="traffic"></me-data-table-column>
            <me-data-table-column
                    [label]="'Interface'"
                    [field]="'wg_interface'"
            ></me-data-table-column>
            <me-data-table-column
                    [label]="'Endpunkt'"
                    [field]="'endpoint'"
            ></me-data-table-column>

            <me-data-table-action
                    label="Eintrag hinzufügen"
                    [icon]="'far fa-plus'"
                    (perform)="addEntry()"
            ></me-data-table-action>

            <me-data-table-rowaction
                    (perform)="editEntry($event)"
                    icon="far fa-pencil"
                    label="Bearbeiten"
                    title="Eintrag bearbeiten"
            ></me-data-table-rowaction>

            <me-data-table-rowaction
                    (perform)="removeClient($event)"
                    icon="far fa-trash"
                    label="Löschen"
                    title="Eintrag löschen"
            ></me-data-table-rowaction>

            <me-data-table-table-extra-header>
                <me-pagination
                        [total]="clientListe.total"
                        [page]="clientListe.page"
                        [perpage]="clientListe.size"
                        (pageChange)="clientListe.setPage($event)"
                        (pageCountChange)="clientListe.processPageCountChangedEvent($event)"
                        (queryChange)="clientListe.processQueryChangedEvent($event)"
                        settingsid="ipsec-info"
                        [settingsService]="benutzerService"
                        [showSearch]="false"></me-pagination>
            </me-data-table-table-extra-header>
        </me-data-table>
    </div>
    <ng-container #detailTabs *ngIf="wgClientTable.hasSelectedRows()" [ngTemplateOutlet]="details"></ng-container>
</div>
<ng-template #details>
    <ng-container *ngIf="current.allowed_ips.length>0">
        <div class="ml-2 ms-2 ps-2 pl-2" style="min-width: 450px">
            <me-tab-card>
                <me-pane id="subtab-wg-client-allowed-ips-{{current.id}}"
                         title="Freigegebene IP-Adressen">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="flex-fill ms-2 ml-2">
                            <button class="btn btn-secondary" title="" type="button" (click)="addIP()">
                                <i class="far fa-plus"></i>
                                IP Hinzufügen
                            </button>
                            <ng-container *ngFor="let ip of current.allowed_ips; index as i">
                                <div style="display: flex; flex-direction: row">
                                    <div style="display: flex;justify-content: left;flex-basis: 80%">
                                        <span>{{ip}}</span>
                                    </div>
                                    <div style="display: flex;justify-content: right;flex-basis: 20%">
                                        <button
                                                class="btn btn-sm btn-secondary"
                                                type="button"
                                                title="IP löschen"
                                                (click)="removeIP(i)">
                                            <i class="far fa-trash"></i>
                                        </button>
                                        <br>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </me-pane>
            </me-tab-card>
        </div>
    </ng-container>
</ng-template>