<me-data-table
        *ngIf="bridges!==null && bridges!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="bridges.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        (onSortChanged)="bridges.processSortChangedEvent($event)"
        #clientsTable
>
    <me-data-table-column [label]="'Login-Kennung'"
                          [field]="'login'"
                          [sortable]="true"
    ></me-data-table-column>
    <me-data-table-column [label]="'Info'"
                          [field]="'info'"
                          [sortable]="true"
    ></me-data-table-column>

    <ng-template #clientsTemplate let-p="item" let-row="row">
        <i class="far fa-arrow-alt-from-left"></i> {{ p }}
        <span *ngIf="row.data.client1simId>0"
              class="badge bg-secondary text-dark">
                {{ row.data.client1simTitle }}
            </span>
        <br/>
        <span *ngIf="row.data.client2Id>0">
        <i class="far fa-arrow-alt-to-right"></i> {{ row.data.client2Info }}
            <span *ngIf="row.data.client2simId>0"
                  class="badge bg-secondary text-dark">
                {{ row.data.client2simTitle }}
            </span>
        </span>
    </ng-template>

    <me-data-table-column [label]="'Clients'"
                          [field]="'client1Info'"
                          [template]="clientsTemplate"
                          [sortable]="true"
    ></me-data-table-column>
    <ng-template #bytesTemplate let-p="item" let-row="row">
        <div class="text-end">
            {{ p | mebytes }}
        </div>
    </ng-template>
    <me-data-table-column [label]="'übertragen'"
                          [field]="'bytesTransferred'"
                          [template]="bytesTemplate"
                          [sortable]="true"
    ></me-data-table-column>

    <me-data-table-column [label]="'aktiviert?'"
                          [field]="'enabled'"
                          type="Boolean"
                          [sortable]="true"
    ></me-data-table-column>


    <ng-template #lastUsedTemplate let-p="item" let-row="row">
        <span class="text-success" *ngIf="row.data.online">
        <i class="far fa-globe-europe"></i> online
        </span>
        <span class="text-danger" *ngIf="!row.data.online">
        <i class="far fa-globe-europe"></i> offline
        </span>
        <br/>
        {{ fDate(p, 'medium') }} <br/>
        {{ row.data.useCount }} Verb. bisher
    </ng-template>

    <me-data-table-column [label]="'zuletzt verbunden'"
                          [field]="'lastUsed'"
                          [template]="lastUsedTemplate"
                          [sortable]="true"
    ></me-data-table-column>

    <ng-container *ngIf="!qc_bridges">
        <me-data-table-action
                [title]="'Brücke anlegen'"
                [label]="'Hinzufügen'"
                (perform)="addBridge()"
                [icon]="'far fa-plus'"
        ></me-data-table-action>
    </ng-container>

    <me-data-table-rowaction
            [title]="'Brücke bearbeiten'"
            [label]="'Bearbeiten'"
            (perform)="editBridge($event)"
            [icon]="'far fa-edit'"
    ></me-data-table-rowaction>
    <me-data-table-rowaction
            [title]="'Verbindung trennen'"
            [label]="'Trennen'"
            (perform)="cutConnection($event)"
            [icon]="'fa-light fa-wifi-slash'"
    ></me-data-table-rowaction>

    <me-data-table-table-extra-header>
        <me-pagination
                [total]="bridges.total"
                [page]="bridges.page"
                [perpage]="bridges.size"
                (pageChange)="bridges.setPage($event)"
                (pageCountChange)="bridges.processPageCountChangedEvent($event)"
                (queryChange)="bridges.processQueryChangedEvent($event)"
                settingsid="meconnect-csd-bridges"
                [settingsService]="benutzerService"
                [showSearch]="true"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>
