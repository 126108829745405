import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { AbrechnungPosition } from './abrechnung-position';
import { IAbrechnungPosition } from './iabrechnung-position';
import { IAbrechnungTKTankstellen } from './iabrechnung-tktankstellen';

export class AbrechnungTKTankstellen
  extends MEGenericEntity<IAbrechnungTKTankstellen>
  implements IAbrechnungTKTankstellen
{
  menge = 0;
  mengeOtherOnTK = 0;
  mengeTKonOther = 0;
  mengeTKonTK = 0;
  stationen = 0;
  transaktionen = 0;
  transaktionenOtherOnTK = 0;
  transaktionenTK = 0;
  transaktionenTKonOther = 0;
  transaktionenTKonTK = 0;
  abgerechnet: Date = null;
  endDay = 31;
  id = 0;
  jahr = 2019;
  lastUpdated: Date = null;
  monat = 0;
  periode = '0-0';
  positionen: AbrechnungPosition[] = [];
  startDay = 1;
  summe = 0;

  constructor() {
    super();
  }

  static fromResult(
    entity: IAbrechnungTKTankstellen,
    createIfNull: boolean = false
  ): AbrechnungTKTankstellen {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new AbrechnungTKTankstellen();
      }
      return null;
    }

    const instance = new AbrechnungTKTankstellen();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('periode')
        .addLongField('jahr')
        .addLongField('monat')
        .addDecimalField('summe')
        .addDecimalField('menge')
        .addDecimalField('mengeTKonTK')
        .addDecimalField('mengeOtherOnTK')
        .addDecimalField('mengeTKonOther')
        .addLongField('transaktionen')
        .addLongField('transaktionenTK')
        .addLongField('transaktionenTKonOther')
        .addLongField('transaktionenTKonTK')
        .addLongField('transaktionenOtherOnTK')
        .addLongField('stationen')
        .addDateField('lastUpdated')
        .addLongField('startDay')
        .addLongField('endDay')
        .addDateField('abgerechnet')
        .addConverterField(
          'positionen',
          new MEGenericEntityConverterParams<AbrechnungPosition[]>(
            (value: any) => {
              const r: AbrechnungPosition[] = [];

              if (Array.isArray(value)) {
                value.forEach((e) => {
                  const p = AbrechnungPosition.fromResult(
                    e as IAbrechnungPosition
                  );
                  if (p !== null) {
                    r.push(p);
                  }
                });
              }
              return r;
            }
          )
        )
    );
    return instance;
  }
}
