import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { SysHealthLebenszeichenTabComponent } from '../controls/sys-health-lebenszeichen-tab/sys-health-lebenszeichen-tab.component';

@Component({
  selector: 'app-sys-health-lebenszeichen',
  templateUrl: './sys-health-lebenszeichen.component.html',
  styleUrls: ['./sys-health-lebenszeichen.component.scss']
})
export class SysHealthLebenszeichenComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(SysHealthLebenszeichenTabComponent, { static: false })
  lebenszeichenTab: SysHealthLebenszeichenTabComponent;

  constructor() {}

  ngOnInit() {
    if (this.lebenszeichenTab !== null && this.lebenszeichenTab !== undefined) {
      this.lebenszeichenTab.onActivate();
    }
  }

  ngOnDestroy(): void {
    if (this.lebenszeichenTab !== null && this.lebenszeichenTab !== undefined) {
      this.lebenszeichenTab.onDeactivate();
    }
  }

  ngAfterViewInit(): void {
    if (this.lebenszeichenTab !== null && this.lebenszeichenTab !== undefined) {
      this.lebenszeichenTab.onActivate();
    }
  }
}
