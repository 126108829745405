import { MEDataTableComponent } from '../data-table.component';

export class MEDataTableSaveRecord {
  public record: any;
  public dataTable: MEDataTableComponent;
  public isNew: boolean;

  constructor(record: any, dataTable: MEDataTableComponent, isNew: boolean) {
    this.record = record;
    this.dataTable = dataTable;
    this.isNew = isNew;
  }
}
