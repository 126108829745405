<div class="modal-header">
    <h4 class="modal-title" *ngIf="isAdd()">Kontakt anlegen</h4>
    <h4 class="modal-title" *ngIf="isEdit()">Kontakt bearbeiten</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">


    <me-tab-set>
        <me-pane [title]="_('Stammdaten')">
            <me-text-field label="Display-Text" [(value)]="entity.displayText"></me-text-field>
            <me-text-field label="Kundennummer" [(value)]="entity.kundennummer"></me-text-field>
            <me-text-field label="Firma" [(value)]="entity.firma"></me-text-field>
            <me-text-field label="Vorname" [(value)]="entity.vorname"></me-text-field>
            <me-text-field label="Nachname" [(value)]="entity.nachname"></me-text-field>
            <me-boolean-field label="interner Kontakt" [(value)]="entity.isIntern"></me-boolean-field>
            <me-num-field label="Sortier-Rang" [(value)]="entity.sortRang"></me-num-field>
        </me-pane>
        <me-pane [title]="_('Rufnummern')">
            <table class="table w-100">
                <thead>
                <tr>
                    <th class="w-50">
                        Info/Ansprechpartner
                    </th>
                    <th class="w-40">Rufnummer</th>
                    <th class="w-10">
                        &nbsp;
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let rn of entity.rufnummern;let idx=index" class="m-0">
                    <td class="w-50">
                        <me-text-field label="" labelWidth="0" [(value)]="rn.info"></me-text-field>
                    </td>
                    <td class="w-40">
                        <me-text-field label="" labelWidth="0" [(value)]="rn.nummer"></me-text-field>
                    </td>
                    <td class="w-10">
                        <button class="btn btn-secondary" (click)="removeNumber(idx)"><i class="far fa-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
                <button class="btn btn-primary" (click)="addNumber()">Nummer hinzufügen</button>
            </table>

        </me-pane>

    </me-tab-set>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">
        <i class="icofont icofont-close"></i> Schließen
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
        <i class="icofont icofont-save"></i> Daten speichern
    </button>
</div>
