import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MEHighlitePipe } from '../../../pipes';
import {
  TMESelectItemFormatterCallback,
  TMESelectItemMatcherCallback,
  TMESelectItemTextCallback
} from './types';

@Component({
  selector: 'me-select',
  templateUrl: './meselect.component.html',
  styleUrls: ['./meselect.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MESelectComponent implements AfterViewInit, OnInit {
  @Input()
  public set onFormat(callback: TMESelectItemFormatterCallback<any>) {
    this.doFormat = callback;
  }

  @Input()
  public set onGetText(callback: TMESelectItemTextCallback<any>) {
    this.getText = callback;
  }

  @Input()
  public set onMatch(callback: TMESelectItemMatcherCallback<any>) {
    this.doMatch = callback;
  }

  get value(): any {
    return this.selectedItem;
  }

  @Input()
  set value(v: any) {
    this.setValue(v);
  }

  @Input()
  public set items(value: any[]) {
    if (!value) {
      this._items = [];
      this._itemsShown = [];
      this._showValue = '';
    } else {
      this._items = value;
      if (value.length < 1) {
        this._showValue = '';
      }
      this.filterItems();
      this.value = this.selectedItem;
    }
  }

  @ViewChild('searchField', { static: false })
  searchField: ElementRef<HTMLInputElement>;
  @Input() nullable = true;
  @Output() onSelect = new EventEmitter<any>();
  @Output() onSearch = new EventEmitter<string>();
  @Output() onOpenDropDown = new EventEmitter<MESelectComponent>();
  @Input() asMatrix = false;
  @Input() small = false;
  @Input() disabled = false;
  @Input()
  public useTimeout = true;
  public filterTimeout: any = null;
  @Input()
  public idField: string = null;
  @Input()
  public placeholder = 'Eintrag auswählen...';
  public _itemsShown: Array<any> = [];
  public _showList = false;
  public _search = '';
  public selectedItem: any = null;
  public _showValue = '';
  public _inputVisible = false;
  @Input() nullLabel = '';

  public _items: Array<any> = [];

  constructor(public _eref: ElementRef, private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cd.markForCheck();
  }

  ngOnInit() {
    if (this.value === null || this.value === undefined) {
      this.search(this._search);
    }
  }

  public doFormat: TMESelectItemFormatterCallback<any> = (item, search) => {
    let t = '';
    if (typeof item === 'string') {
      t = item;
    } else if (typeof item === 'number') {
      t = item.toString();
    } else if (typeof item === 'boolean') {
      t = item.toString();
    } else if (item != null) {
      if (item.hasOwnProperty('text')) {
        t = item.text;
      } else if (item.hasOwnProperty('label')) {
        t = item.label;
      } else {
        t = JSON.stringify(item);
      }
    }
    return MEHighlitePipe.transformString(t, search);
  };

  public displayValue(forList: boolean): string {
    if (this.value === undefined || this.value === null || this.value === '') {
      if (
        this.nullLabel !== null &&
        this.nullLabel !== undefined &&
        this.nullLabel !== ''
      ) {
        return '<span class="placeholder">' + this.nullLabel + '</span>';
      }
      return '<span class="placeholder">' + this.placeholder + '</span>';
    }

    return this.doFormat(this.value, '', forList);
  }

  public format(item: any, search: string, forList: boolean): string {
    return this.doFormat(item, search, forList);
  }

  @HostListener('document:click', ['$event'])
  onOutsideClick(event: any) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this._showList = false;
      this.cd.markForCheck();
    }
  }

  public setValue(v: any) {
    if (v === null) {
      this.selectedItem = null;
      this._showValue = '';
    } else {
      const h = this.findByID(v);
      if (h !== null) {
        this.selectedItem = h;
        this._showValue = this.getText(h);
      } else {
        if (v !== this.selectedItem) {
          this.selectedItem = v;
        }
        this._showValue = this.getText(v);
      }
    }
    this.cd.markForCheck();
  }

  public search(value: string) {
    this._search = value;
    if (this.searchField !== null && this.searchField !== undefined) {
      this.searchField.nativeElement.value = value;
    }
    this.onkey({ target: this.searchField });
  }

  public onkey($event: any) {
    if (!this.disabled) {
      if (this.useTimeout) {
        if (this.filterTimeout != null) {
          clearTimeout(this.filterTimeout);
        }
        this.filterTimeout = setTimeout(() => {
          this.onSearch.emit(this._search);
          this.filterTimeout = null;
        }, 400);
      } else {
        if ($event.target !== null && $event.target !== undefined) {
          this.onSearch.emit($event.target.value);
        }
      }
      if ($event.target !== null && $event.target !== undefined) {
        this._search = $event.target.value;
      }
      this.selectedItem = null;
      // this.showDropdown();
      this.filterItems();
    }
    this.cd.markForCheck();
  }

  public select(item: any) {
    this._showList = false;
    if (!this.disabled) {
      this.setValue(item);
      this.onSelect.emit(item);
    }
  }

  toggleDropDown() {
    if (this._showList) {
      this._showList = false;
    } else {
      this.showDropdown();
      this._search = '';
      this.filterItems();
    }
  }

  showInput() {
    if (!this.disabled) {
      this._inputVisible = true;
      this.cd.markForCheck();
      setTimeout(() => {
        if (this.searchField !== null && this.searchField !== undefined) {
          this.searchField.nativeElement.focus();
        }
      }, 25);
    }
  }

  hideInput() {
    this._inputVisible = false;
    this.cd.markForCheck();
  }

  public findByID(i: any): any {
    if (i === null || i === undefined) {
      return null;
    }
    let r: any = null;
    if (this._items !== undefined) {
      if (this.idField !== undefined && this.idField !== null) {
        if (this.idField !== '') {
          if (i[this.idField] !== undefined && i[this.idField] !== null) {
            this._items.forEach((e) => {
              if (e[this.idField] !== undefined && e[this.idField] !== null) {
                if (e[this.idField] === i[this.idField]) {
                  r = e;
                }
              }
            });
          }
        } else {
          this._items.forEach((e) => {
            if (e === i) {
              r = e;
            }
          });
        }
      }
    }
    return r;
  }

  clear() {
    this.select(null);
  }

  gotFocus() {
    this.showDropdown();
  }

  public showDropdown() {
    if (!this._showList) {
      this._showList = true;
      this.onOpenDropDown.emit(this);
    }
    this.cd.markForCheck();
  }

  protected getText: TMESelectItemTextCallback<any> = (item) => {
    return this.doFormat(item, '', false);
  };

  protected doMatch: TMESelectItemMatcherCallback<any> = (item, search) => {
    if (!search) {
      return true;
    }
    if (search.trim() === '') {
      return true;
    }
    let i: any = this.getText(item);

    if (!i) {
      return false;
    }

    if (i === '') {
      return false;
    }

    i = <string>(<any>i);

    return (
      (' ' + i)
        .trim()
        .toLocaleLowerCase()
        .indexOf(search.toLocaleLowerCase()) >= 0
    );
  };

  protected filterItems() {
    this._itemsShown = [];
    if (
      this._items !== undefined &&
      this._items !== null &&
      this._items.length &&
      this._items.length > 0 &&
      this._items.forEach !== undefined
    ) {
      this._items.forEach((i) => {
        if (this.doMatch(i, this._search)) {
          this._itemsShown.push(i);
        }
      });
    }
    if (this._inputVisible) {
      if (this._itemsShown.length > 0) {
        this.showDropdown();
      }
    }
    this.cd.markForCheck();
  }

  select2(idx: number) {
    if (idx < this._itemsShown.length) {
      this.select(this._itemsShown[idx]);
    }
  }

  public markForCheck() {
    if (this.cd !== null && this.cd !== undefined) {
      this.cd.markForCheck();
    }
  }
}
