import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MEPubSubService,
  MESubscriptionHelper
} from '../../maennl-commons/services';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  __classname = 'SidebarComponent';
  __instance = '';

  isLoggedIn = false;

  constructor(
    public pubSub: MEPubSubService,
    public benutzerService: BenutzerService
  ) {}

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.pubSub.loginError.subscribe(() => {
        this.isLoggedIn = false;
      })
    );
    MESubscriptionHelper.add(
      this,
      this.pubSub.loginChange.subscribe(() => {
        this.isLoggedIn = this.benutzerService.isLoggedIn();
      })
    );
  }

  ngOnDestroy(): void {
    MESubscriptionHelper.release(this);
  }
}
