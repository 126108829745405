import { Component, Input, OnInit } from '@angular/core';
import { DresserChunk } from './dresser-chunk';
import { DresserCommand } from './dresser-command';

@Component({
  selector: 'app-dresser-display',
  templateUrl: './dresser-display.component.html',
  styleUrls: ['./dresser-display.component.css']
})
export class DresserDisplayComponent implements OnInit {
  chunks: DresserChunk[] = [];

  @Input()
  set data(value: string) {
    this.chunks = [];

    let bytes: string[] = value.match(/.{2}/g);
    const lines: string[] = [];
    let line = '';
    let lastByte = '';
    let mode = 0;
    let rest = 0;
    bytes.forEach((b: string) => {
      line = line + b;

      if (b === 'fa') {
        if (lastByte === '03') {
          mode = 6;
        }
      }
      lastByte = b;

      if (mode === 0) {
        mode = 1;
      } else if (mode === 1) {
        if (b.startsWith('3')) {
          mode = 2;
        } else {
          mode = 6;
        }
      } else if (mode === 2) {
        if (
          b === '01' ||
          b === '02' ||
          b === '03' ||
          b === '04' ||
          b === '05'
        ) {
          mode = 3;
        } else {
          rest = 2;
          mode = 5;
        }
      } else if (mode === 3) {
        rest = parseInt(b, 10);
        mode = 4;
      } else if (mode === 4) {
        rest = rest - 1;
        if (rest <= 0) {
          mode = 2;
        }
      } else if (mode === 5) {
        rest = rest - 1;
        if (rest <= 0) {
          mode = 6;
        }
      } else if (mode === 6) {
        if (b.toLowerCase() === 'fa') {
          lines.push(line.toLowerCase());
          line = '';
          mode = 0;
        }
      }
    });

    if (line !== '') {
      lines.push(line.toLowerCase());
    }

    lines.forEach((l) => {
      const dc: DresserChunk = new DresserChunk();
      dc.raw = l;
      dc.valid = false;
      if (l.endsWith('fa') && l.length > 12) {
        dc.valid = true;
        dc.checksum = l.substring(l.length - 8, l.length - 2);
        dc.end = 'fa';
        l = l.substring(0, l.length - 8);

        let xmode = 0;
        let cmd: DresserCommand = new DresserCommand();

        bytes = l.match(/.{2}/g);

        bytes.forEach((b) => {
          if (xmode === 0) {
            if (!b.startsWith('5')) {
              dc.valid = false;
              xmode = 5;
            } else {
              dc.startcode = b;
              dc.address = (parseInt(b, 16) - 0x50).toString(16);
              xmode = 1;
            }
          } else if (xmode === 1) {
            if (!b.startsWith('3')) {
              dc.valid = false;
              xmode = 5;
            } else {
              dc.sequenz = b;
              dc.position = (parseInt(b, 16) - 0x30).toString(10);
              if (parseInt(dc.position, 10) > 15) {
                dc.valid = false;
                xmode = 5;
              }
              if (parseInt(dc.position, 10) < 0) {
                dc.valid = false;
                xmode = 5;
              }
              xmode = 2;
            }
          } else if (xmode === 2) {
            cmd.cmd = b;
            xmode = 3;
          } else if (xmode === 3) {
            cmd.length = b;
            if (parseInt(b, 16) > 0) {
              xmode = 4;
            } else {
              dc.commands.push(cmd);
              cmd = new DresserCommand();
              xmode = 2;
            }
          } else if (xmode === 4) {
            cmd.data = cmd.data + b;
            if (cmd.data.length >= parseInt(cmd.length, 16) * 2) {
              dc.commands.push(cmd);
              cmd = new DresserCommand();
              xmode = 2;
            }
          }
        });
      }
      if (this.chunks.length > 0) {
        dc.lastChunk = this.chunks[this.chunks.length - 1];
      }
      this.chunks.push(dc);
    });
  }

  constructor() {}

  ngOnInit() {}
}
