<me-data-table
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="devices.asDataTableRows()"
        [selectable]="false"
>
    <me-data-table-column [label]="'ID'" [field]="'id'" type="long"></me-data-table-column>
    <me-data-table-column [label]="'Bezeichnung'" [field]="'label'"></me-data-table-column>
    <me-data-table-column [label]="'Router'"
                          [field]="'device'"
                          [renderer]="deviceRenderer"
    ></me-data-table-column>
    <me-data-table-column [label]="'Kennung'" [field]="'deviceId'"></me-data-table-column>
    <me-data-table-column [label]="'aktiv'" [field]="'enabled'" type="boolean"></me-data-table-column>
    <me-data-table-column [label]="'letzte Verbindung'" [field]="'lastConnection'" type="date"
                          [cfg]="{format:'YYYY-MM-DD HH:mm:ss'}"></me-data-table-column>
    <me-data-table-column [label]="'l. Verbindung#'" [field]="'lastConnectionId'" type="long"></me-data-table-column>
    <me-data-table-column [label]="'Protokoll'" [field]="'protocol'"></me-data-table-column>
    <me-data-table-rowaction
            [title]="'Gerät bearbeiten'"
            [label]="'Bearbeiten'"
            (perform)="editDevice($event)"
            [icon]="'far fa-edit'"
    ></me-data-table-rowaction>
</me-data-table>

<div class="row">
    <div class="col-6"></div>
    <div class="col-6 text-right">
        <me-pagination
                [total]="devices.total"
                [page]="devices.page"
                [perpage]="devices.size"
                (pageChange)="devices.setPage($event)"
                (pageCountChange)="devices.processPageCountChangedEvent($event)"
                settingsid="mitschnitt-devices"
                [settingsService]="benutzerService"
        ></me-pagination>
    </div>
</div>


