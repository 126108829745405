import {
  MEGenericEntity,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { IDHCPLease } from './idhcplease';

export class DHCPLease
  extends MEGenericEntity<IDHCPLease>
  implements IDHCPLease
{
  private __classname__ = 'DHCPLease';
  private _type = 'DHCPLease';

  public ip = '';
  public ends: Date = null;
  public hostname = '';
  public hostnameDns = '';
  public mac = '';
  public start: Date = null;
  public vendor = '';

  static fromResult(
    entity: IDHCPLease,
    createIfNull: boolean = false
  ): DHCPLease {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new DHCPLease();
      }
      return null;
    }
    const instance = new DHCPLease();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addTextField('ip')
        .addTextField('hostname')
        .addTextField('hostnameDns')
        .addTextField('mac')
        .addDateField('start')
        .addDateField('ends')
        .addTextField('vendor'),
      (e: DHCPLease) => {
        e.__classname__ = 'DHCPLease';
        e._type = 'DHCPLease';
      }
    );

    return instance;
  }

  constructor() {
    super();
  }
}
