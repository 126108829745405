import { HttpClient } from '@angular/common/http';
import { BenutzerService } from '../../../benutzer/services/benutzer.service';
import { Injectable } from '@angular/core';
import { IBlocklistV4 } from './blocklist-v4.interface';
import {
  IMEActionResponse,
  IMEListResult,
  MEActionResponse,
  MEApiTool,
  MEResultMetaSort
} from '../../../maennl-commons';
import { IMESimpleFilter } from '../../../maennl-commons/filters';
import { Observable, map, of, take } from 'rxjs';
import { BlocklistV4 } from './blocklist-v4.class';
import { TNullableNumber } from '../../../maennl-commons/tools/types';
import { BlocklistV4Impl } from './blocklist-v4.impl';

@Injectable({
  providedIn: 'root'
})
export class BlocklistV4Service {
  constructor(public http: HttpClient, public loginService: BenutzerService) {}

  public list(
    max: number = 50,
    offset: number = 0,
    sort: MEResultMetaSort[] = [],
    filter: IMESimpleFilter<IBlocklistV4> = null,
    queryString = ''
  ): Observable<IMEListResult<IBlocklistV4>> {
    const uri = MEApiTool.buildListURL(
      '/api/meconnect/netzwerk/blocklist/v4',
      max,
      offset,
      sort,
      filter,
      queryString
    );
    return this.http
      .get<IMEListResult<IBlocklistV4>>(
        uri,
        this.loginService.getRequestOptions()
      )
      .pipe(take(1)) as Observable<IMEListResult<IBlocklistV4>>;
  }

  public store(entity: BlocklistV4): Observable<MEActionResponse> {
    const uri = MEApiTool.buildEntityURL(
      '/api/meconnect/netzwerk/blocklist/v4',
      entity.id
    );

    if (entity.id === null || entity.id === undefined || entity.id < 1) {
      return this.http
        .post<IMEActionResponse>(
          uri,
          entity,
          this.loginService.getRequestOptions()
        )
        .pipe(
          map((r) => {
            return MEActionResponse.fromRawActionResponse(r);
          }),
          take(1)
        );
    } else {
      return this.http
        .put<IMEActionResponse>(
          uri,
          entity,
          this.loginService.getRequestOptions()
        )
        .pipe(
          map((r) => {
            return MEActionResponse.fromRawActionResponse(r);
          }),
          take(1)
        );
    }
  }

  public get(id: TNullableNumber): Observable<BlocklistV4> {
    if (id === null || id === undefined || id < 1) {
      return of(null);
    }
    const uri = MEApiTool.buildEntityURL(
      '/api/meconnect/netzwerk/blocklist/v4',
      id
    );

    return this.http
      .get<IBlocklistV4>(uri, this.loginService.getRequestOptions())
      .pipe(
        map((r) => {
          return BlocklistV4Impl.fromResult(r);
        }),
        take(1)
      );
  }

  public delete(id: TNullableNumber): Observable<MEActionResponse> {
    if (id === null || id === undefined || id < 1) {
      return of(MEActionResponse.error('id is null or undefined'));
    }
    const uri = MEApiTool.buildEntityURL(
      '/api/meconnect/netzwerk/blocklist/v4',
      id
    );

    return this.http
      .delete<IMEActionResponse>(uri, this.loginService.getRequestOptions())
      .pipe(
        map((r) => {
          return MEActionResponse.fromRawActionResponse(r);
        }),
        take(1)
      );
  }
}
