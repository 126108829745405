<ng-template #ahIcon>
    <i class="icofont icofont-wifi-router"></i>
</ng-template>
<me-area-heading
        [icon]="ahIcon"
        title="Router und sonstige Funkgeräte"
></me-area-heading>

<me-tab-card>
    <me-pane title="Geräte"
             id="tab-devices"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
    >
        <app-tab-devices></app-tab-devices>
    </me-pane>
    <me-pane title="SIM-Karten"
             id="tab-simcards"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
    >
        <app-tab-sims></app-tab-sims>
    </me-pane>
    <me-pane title="Geräte-Typen"
             id="tab-device-types"
             (activated)="activateTab($event.id)"
             (deactivated)="deactivateTab($event.id)"
    >
        <app-tab-device-types></app-tab-device-types>
    </me-pane>

</me-tab-card>