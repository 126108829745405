import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { MENewPageCountEvent } from './menew-page-count-event';
import { MELocalizedComponent } from '../localized-component';
import { IMESettingsService } from './ime-settings-service';

@Component({
  selector: 'me-pagination',
  templateUrl: './mepagination.component.html',
  styleUrls: ['./mepagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MEPaginationComponent
  extends MELocalizedComponent
  implements OnInit, AfterViewInit
{
  gotoPageNofM = 'zu Seite {{ n }} von {{ m }} springen';

  limitPages = 5;

  totalItems = 0;
  itemsPerPage = 10;
  @Input()
  pagecounts: number[] = [10, 20, 25, 50, 100];
  pages: number[] = [];
  currentpage = 1;
  totalPages = 0;
  @Input() queryFieldVisible = false;
  @Input() query = '';
  @Input() showSearch = false;
  @Input() showCount = false;

  private _settingsService: IMESettingsService = null;

  @Output() pageChange = new EventEmitter<number>();
  @Output() pageCountChange = new EventEmitter<MENewPageCountEvent>();
  @Output() queryChange = new EventEmitter<string>();

  private _settingsid = '';

  get settingsService(): IMESettingsService {
    return this._settingsService;
  }

  @Input()
  set settingsService(value: IMESettingsService) {
    if (this._settingsService !== value) {
      this._settingsService = value;
      this.loadSettings();
      this.cd.markForCheck();
    }
  }

  @Input()
  set perpage(value: number) {
    this.itemsPerPage = value;
    this.recalcPages();
  }

  get perpage(): number {
    return this.itemsPerPage;
  }

  @Input()
  set page(value: number) {
    this.currentpage = value;
    this.recalcPages();
  }

  @Input()
  set total(value: number) {
    this.totalItems = value;
    this.recalcPages();
  }

  get settingsid(): string {
    return this._settingsid;
  }

  @Input()
  set settingsid(value: string) {
    if (this._settingsid !== value) {
      this._settingsid = value;
      this.loadSettings();
    }
  }

  ngAfterViewInit(): void {
    this.loadSettings();
  }

  gopage(value) {
    console.log('goto page');
    this.currentpage = value;
    this.pageChange.emit(this.currentpage);
  }

  constructor(public cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.loadSettings();
  }

  pagesChanged(newPages: number) {
    if (newPages < 1) {
      newPages = 1;
    }
    const needsStore = this.itemsPerPage !== newPages;

    const pos = this.itemsPerPage * this.currentpage;
    this.itemsPerPage = newPages;
    this.currentpage = Math.floor(pos / newPages) + 1;
    this.recalcPages();
    const event = new MENewPageCountEvent();
    event.itemsPerPage = this.itemsPerPage;
    event.currentPage = this.currentpage;
    if (needsStore) {
      this.storeSettings();
    }
    this.pageCountChange.emit(event);
    this.cd.markForCheck();
  }

  recalcPages() {
    if (this.totalItems < 1 || this.itemsPerPage < 1) {
      this.totalPages = 0;
    } else if (this.totalItems % this.itemsPerPage === 0) {
      this.totalPages = Math.floor(this.totalItems / this.itemsPerPage);
    } else {
      this.totalPages = Math.floor(this.totalItems / this.itemsPerPage) + 1;
    }

    if (this.currentpage > this.totalPages) {
      this.currentpage = this.totalPages;
    }

    if (this.totalPages < 2) {
      this.currentpage = 1;
      this.pages = [1];
    } else if (this.totalPages < this.limitPages + 1) {
      this.pages = [];
      for (let i = 1; i < this.limitPages + 1; i++) {
        if (i <= this.totalPages) {
          this.pages.push(i);
        }
      }
    } else {
      this.pages = [];
      if (this.currentpage < (this.limitPages + 1) / 2) {
        for (let i = 1; i < this.limitPages + 1; i++) {
          if (i <= this.totalPages) {
            this.pages.push(i);
          }
        }
        this.pages.push(-4);
        this.pages.push(-5);
      } else if (
        this.currentpage >
        this.totalPages - (this.limitPages + 1) / 2
      ) {
        this.pages.push(-2);
        this.pages.push(-3);
        for (
          let i = this.totalPages - this.limitPages;
          i <= this.totalPages;
          i++
        ) {
          if (i <= this.totalPages) {
            this.pages.push(i);
          }
        }
      } else {
        this.pages.push(-2);
        this.pages.push(-3);
        for (
          let i = this.currentpage - (this.limitPages + 1) / 2;
          i <= this.currentpage + (this.limitPages + 1) / 2;
          i++
        ) {
          if (i <= this.totalPages) {
            this.pages.push(i);
          }
        }
        this.pages.push(-4);
        this.pages.push(-5);
      }
    }
    this.cd.markForCheck();
  }

  editSearch(event: KeyboardEvent) {
    let value = '';
    if (event.target !== undefined && event.target !== null) {
      value = (<HTMLInputElement>event.target).value;
    }
    if (event.key === 'Enter') {
      this.queryChange.emit(value);
    } else {
      this.queryChange.emit(value + event.key);
    }
    this.cd.markForCheck();
  }

  qChange(event: Event) {
    let value = '';
    if (event.target !== undefined && event.target !== null) {
      value = (<HTMLInputElement>event.target).value;
    }
    this.queryChange.emit(value);
    this.cd.markForCheck();
  }

  qSet(value = '') {
    this.queryChange.emit(value);
  }

  toggleQuery() {
    if (this.queryFieldVisible) {
      this.qSet('');
      this.queryFieldVisible = false;
    } else {
      this.queryFieldVisible = true;
    }
    this.cd.markForCheck();
  }

  private loadSettings() {
    if (this._settingsService !== null && this._settingsService !== undefined) {
      if (this._settingsid !== '') {
        const s = this._settingsService.getPageSize(
          this._settingsid,
          this.itemsPerPage
        );
        this.perpage = s;
        this.pagesChanged(s);
        this.cd.markForCheck();
      }
    }
  }

  private storeSettings() {
    if (this._settingsService !== null && this._settingsService !== undefined) {
      if (this._settingsid !== '') {
        this._settingsService.setPageSize(this._settingsid, this.itemsPerPage);
      }
    }
  }
}
