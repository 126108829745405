import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'me-card',
  template: `
    <div class="card">
      <h1 class="card-header" *ngIf="hasHeader()">
        <i [ngClass]="icon" *ngIf="hasIcon()"></i>
        {{ title }}
      </h1>
      <div
        class="card-body"
        [ngClass]="extraBodyClass"
        [ngStyle]="extraBodyStyles"
      >
        <div class="card-text">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .card-header {
        font-size: 1em;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MECardComponent implements OnInit {
  private _title = '';
  private _icon: any = '';
  private _extraBodyClass: any = '';
  private _extraBodyStyles: any = {};

  public get extraBodyStyles(): any {
    return this._extraBodyStyles;
  }

  @Input()
  public set extraBodyStyles(value: any) {
    this._extraBodyStyles = value;
    this.cd.markForCheck();
  }

  public get extraBodyClass(): any {
    return this._extraBodyClass;
  }

  @Input()
  public set extraBodyClass(value: any) {
    this._extraBodyClass = value;
    this.cd.markForCheck();
  }

  public get icon(): string {
    return this._icon;
  }

  @Input()
  public set icon(value: string) {
    this._icon = value;
    this.cd.markForCheck();
  }

  public get title(): string {
    return this._title;
  }

  @Input()
  public set title(value: string) {
    this._title = value;
    this.cd.markForCheck();
  }

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit() {}

  public hasHeader() {
    if (this._title === null || this._title === undefined) {
      return false;
    }
    return this._title.trim() !== '';
  }

  public hasIcon() {
    if (this._icon === null || this._icon === undefined) {
      return false;
    }
    if (Array.isArray(this._icon)) {
      return this._icon.length > 0;
    }
    return this._icon.trim() !== '';
  }
}
