import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MERowActionParams, METool } from '../../maennl-commons';
import { MEGenericModalComponent } from '../../maennl-commons';
import { MERichError } from '../../maennl-commons';
import { Mitschnittstream } from '../common/mitschnittstream';
import { MitschnittstreamList } from '../common/mitschnittstream-list';
import { MEDTMitschnittgeraetRendererComponent } from '../controls/medtmitschnittgeraet-renderer/medtmitschnittgeraet-renderer.component';
import { MitschnittStreamDetailsPopupComponent } from '../mitschnitt-stream-details-popup/mitschnitt-stream-details-popup.component';
import { MitschnittstreamFilterDialogComponent } from '../mitschnittstream-filter-dialog/mitschnittstream-filter-dialog.component';
import { MitschnittService } from '../services/mitschnitt.service';
import { SimpleMitschnittstreamFilter } from '../common/simple-mitschnittstream-filter';
import { MEKitchenTimer } from '../../maennl-commons';
import { MESubscriptionHelper } from '../../maennl-commons';
import { BenutzerService } from '../../benutzer/services/benutzer.service';

@Component({
  selector: 'app-tab-mitschnitt-streams',
  templateUrl: './tab-mitschnitt-streams.component.html',
  styleUrls: ['./tab-mitschnitt-streams.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabMitschnittStreamsComponent implements OnInit, OnDestroy {
  __classname = 'TabMitschnittStreamsComponent';
  __instance = '';

  public deviceRenderer = MEDTMitschnittgeraetRendererComponent;
  public updateHelper: MEKitchenTimer = new MEKitchenTimer(10000);
  public streams: MitschnittstreamList = new MitschnittstreamList();
  private streamPopupIsOpen = false;
  private tabActive = false;

  constructor(
    public mitschnittService: MitschnittService,
    public modalService: NgbModal,
    public cd: ChangeDetectorRef,
    public benutzerService: BenutzerService
  ) {}

  ngOnInit() {
    MESubscriptionHelper.add(
      this,
      this.streams.onUpdateRequired.subscribe((list: MitschnittstreamList) => {
        this.mitschnittService
          .listStreams(
            list.size,
            list.calcOffset(),
            list.order,
            list.simpleFilter
          )
          .subscribe(
            (l) => {
              if (!METool.isNullOrUndefined(l)) {
                list.populateFromListResult(l);
              }
              if (
                this.updateHelper !== null &&
                this.updateHelper !== undefined
              ) {
                this.updateHelper.start();
              }
              this.cd.markForCheck();
            },
            () => {
              MERichError.throw(
                'Fehler beim Datenabruf',
                'Die Liste der verfügbaren Datenströme konnte nicht geladen werden'
              );
            }
          );
      }, undefined)
    );

    MESubscriptionHelper.add(
      this,

      this.updateHelper.subscribe(() => {
        if (
          this.streams.page === 1 &&
          !this.streamPopupIsOpen &&
          this.tabActive
        ) {
          this.mitschnittService
            .listStreams(1, 0, this.streams.order, this.streams.simpleFilter)
            .subscribe((l) => {
              if (
                !METool.isNullOrUndefined(l) &&
                !METool.isNullOrUndefined(l.data)
              ) {
                if (l.data.length > 0) {
                  if (
                    l.data[0].connectionId !==
                    this.streams._data[0].connectionId
                  ) {
                    this.streams.reload();
                  } else if (
                    l.data[0].streamLength !==
                    this.streams._data[0].streamLength
                  ) {
                    this.streams.reload();
                  } else if (
                    l.data[0].maxReceived !== this.streams._data[0].maxReceived
                  ) {
                    this.streams.reload();
                  } else {
                    this.updateHelper.start();
                  }
                } else {
                  this.updateHelper.start();
                }
              } else {
                this.updateHelper.start();
              }
            }, undefined);
        }
      }, undefined)
    );

    this.streams.start();
  }

  ngOnDestroy() {
    MESubscriptionHelper.release(this);
    this.updateHelper.stop();
    this.updateHelper = null;
    this.streams.release();
  }

  showStream(e: MERowActionParams<Mitschnittstream>) {
    this.streamPopupIsOpen = true;
    MitschnittStreamDetailsPopupComponent.open(
      this.modalService,
      MitschnittStreamDetailsPopupComponent,
      e.row.data,
      MEGenericModalComponent.SIZE_LARGE
    ).then(
      () => {
        this.streamPopupIsOpen = false;
        this.streams.reload();
      },
      () => {
        this.streamPopupIsOpen = false;
      }
    );
  }

  setFilter() {
    this.streamPopupIsOpen = true;
    MitschnittstreamFilterDialogComponent.open(
      this.modalService,
      MitschnittstreamFilterDialogComponent,
      this.streams.simpleFilter,
      MEGenericModalComponent.SIZE_SMALL
    ).then(
      (f: SimpleMitschnittstreamFilter) => {
        this.streamPopupIsOpen = false;
        this.streams.simpleFilter.mitschnitt_id = f.mitschnitt_id;
        this.streams.simpleFilter.received = f.received;
        this.streams.simpleFilter.connected = f.connected;
        this.streams.simpleFilter.connection_id = f.connection_id;
        this.streams.reload();
      },
      () => {
        this.streamPopupIsOpen = false;
      }
    );
  }

  reloadList() {
    this.streams.reload();
  }

  tabActivated() {
    this.tabActive = true;

    this.streams.start();
  }

  tabDeactivated() {
    this.tabActive = false;
  }
}
