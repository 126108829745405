import { IMEListResult, MERemoteList } from '../../../maennl-commons';
import { CheckPing } from './check-ping.class';
import { CheckPingFilter } from './check-ping.filter';
import { CheckPingImpl } from './check-ping.impl';
import { ICheckPing } from './check-ping.interface';

export class CheckPingList extends MERemoteList<CheckPing> {
  __classname: string = 'CheckPingList';
  __instance = '';

  static fromListResult(res: IMEListResult<ICheckPing>): CheckPingList {
    const newList = new CheckPingList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new CheckPingFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'next_check',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return CheckPingImpl.fromResult(entity);
  }
}
