import {
  IMEListResult,
  MERemoteList
} from '../../maennl-commons/mecommon-list';
import { Alive } from './alive';
import { IAlive } from './ialive';
import { SimpleAliveFilter } from './simple-alive-filter';

export class AliveList extends MERemoteList<Alive> {
  __classname = 'AliveList';
  __instance = '';

  static fromListResult(res: IMEListResult<IAlive>): AliveList {
    const newList = new AliveList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new SimpleAliveFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'zeitpunkt',
    defaultOrder: string = 'desc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): Alive {
    return Alive.fromResult(entity);
  }
}
