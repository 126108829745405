<div
        class="d-flex align-items-start justify-content-between"
        *ngIf="liste !== null && liste !== undefined">
    <div class="flex-fill">
        <me-data-table
            #mttInterfaceTable
            [rowActionsAsButtons]="true"
            [tableActionsAsButtons]="true"
            [data]="liste.asDataTableRows()"
            [selectable]="false"
            [singleSelect]="false"
            [hideCheckboxes]="true"
            actionsicon="icofont icofont-navigation-menu">
            <me-data-table-column
                    [label]="'ID'"
                    [field]="'id'"></me-data-table-column>
            <ng-template #tenant let-p="item" let-row="row">
                <div title="Mandant">
                    [{{p}}]{{row.data._tenant_info}}
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'Mandant'"
                    [field]="'tenant'"
                    [template]="tenant"></me-data-table-column>

            <ng-template #typ let-p="item" let-row="row">
                <div title="Typ">
                    [{{p}}]{{row.data._typ_info}}
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'Job'"
                    [field]="'typ'"
                    [template]="typ">
                ></me-data-table-column>
            <ng-template #create_date let-p="item" let-row="row">
                <div title="zeitpunkt">
                    {{fDate(p,'medium') }}
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'Erstellungsdatum'"
                    [field]="'date_created'"
                    [template]="create_date">
                ></me-data-table-column>
            <ng-template #next_try let-p="item" let-row="row">
                <div title="zeitpunkt">
                    {{fDate(p,'medium') }}
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'nächster Versuch'"
                    [field]="'next_try'"
                    [template]="next_try">
                ></me-data-table-column>
            <ng-template #size let-p="item" let-row="row">
                <div title="Größe">
                    {{p|mebytes}}
                </div>
            </ng-template>
            <me-data-table-column
                    [label]="'Größe'"
                    [field]="'size'"
                    [template]="size">
            </me-data-table-column>
            <me-data-table-column
                    [label]="'Dateiname'"
                    [field]="'filename'"></me-data-table-column>

            <me-data-table-table-extra-header>
                <me-pagination
                        [total]="liste.total"
                        [page]="liste.page"
                        [perpage]="liste.size"
                        (pageChange)="liste.setPage($event)"
                        (pageCountChange)="liste.processPageCountChangedEvent($event)"
                        (queryChange)="liste.processQueryChangedEvent($event)"
                        settingsid="missing-terminal-transactions-info"
                        [settingsService]="benutzerService"
                        [showSearch]="false"></me-pagination>
            </me-data-table-table-extra-header>
        </me-data-table>
    </div>
</div>