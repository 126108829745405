import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';
import { noop } from '../../tools';
import { MEAddOnClickEvent } from '../events';

@Component({
  selector: 'me-text-field',
  template: `
    <div class="form-group row mb-1">
      <label
        for="{{ id }}"
        class="col-form-label"
        [ngClass]="'col-' + labelWidth"
        *ngIf="labelWidth > 0"
      >
        {{ label }}
      </label>
      <div [ngClass]="'col-' + (12 - labelWidth)">
        <div
          class=""
          [class.input-group]="hasAddon()"
          [class.input-group-sm]="hasAddon() && small"
        >
          <!--suppress XmlDuplicatedId -->
          <input
            *ngIf="!multiline"
            [attr.type]="type"
            class="form-control"
            [class.form-control-sm]="small"
            id="{{ id }}"
            [value]="value"
            (input)="update($event)"
            placeholder="{{ label }}"
            [attr.disabled]="disabled ? true : null"
            [class.disabled]="disabled"
          />
          <!--suppress XmlDuplicatedId -->
          <textarea
            *ngIf="multiline"
            class="form-control"
            [class.form-control-sm]="small"
            id="{{ id }}"
            [value]="value"
            (input)="update($event)"
            placeholder="{{ label }}"
            [attr.disabled]="disabled ? true : null"
            [class.disabled]="disabled"
            [rows]="rows"
            [cols]="cols"
          ></textarea>
          <span
            class="input-group-btn addon"
            *ngFor="let ai of addonIcons; let idx = index"
          >
            <button
              class="btn btn-secondary"
              [ngClass]="'addon-btn-' + idx"
              [class.btn-sm]="small"
              type="button"
              title="{{ _addOnLabels[idx] }}"
              (click)="aClick(idx)"
            >
              <i [ngClass]="ai"></i>
            </button>
          </span>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host .addon button:first-child {
        border-left: none;
        padding: 0 5px;
      }

      .addon {
      }
    `
  ]
})
export class METextFieldComponent implements OnInit {
  public _value: string | number = '';
  public _icons: string[] = [];

  @Input()
  public type = 'text';

  @Input() cols = 80;
  @Input() rows = 3;

  @Input() label = 'Textfeld';
  @Input() multiline = false;

  _addOnLabels: string[] = [];

  @Input() enableAddon = false;
  @Input() id = '';
  @Input() labelWidth = 3;
  @Input() disabled = false;
  @Input() small = false;

  @Output() valueChange = new EventEmitter<string | number>();
  @Output() addonClick = new EventEmitter<MEAddOnClickEvent>();

  @Input()
  set addonicon(v: any) {
    this._icons = [];
    if (Array.isArray(v)) {
      v.forEach((i) => {
        this._icons.push(i);
      });
    } else {
      this._icons.push(v);
    }
    while (this._addOnLabels.length < this._icons.length) {
      this._addOnLabels.push('');
    }
  }

  get addonicon(): any {
    if (this._icons.length < 1) {
      return '';
    } else if (this._icons.length < 2) {
      return this._icons[0];
    }
    return this._icons;
  }

  @Input()
  set addonlabel(v: any) {
    this._addOnLabels = [];
    if (Array.isArray(v)) {
      v.forEach((i) => {
        this._addOnLabels.push(i);
      });
    } else {
      this._addOnLabels.push(v);
    }
    while (this._addOnLabels.length < this._icons.length) {
      this._addOnLabels.push('');
    }
  }

  @Input()
  set value(v: string | number) {
    if (this._value !== v) {
      this._value = v;
      if (!this.disabled) {
        this.valueChange.emit(v);
      }
    }
  }

  get value(): string | number {
    return this._value;
  }

  constructor() {
    noop();
  }

  ngOnInit() {
    if (this.id === '') {
      this.id =
        'tf' +
        Md5.hashStr(
          'textfield-' + this.label + Math.random() + '-' + Math.random()
        );
    }
  }

  update(event) {
    if (!this.disabled) {
      this.value = event.target.value;
    }
  }

  hasAddon(): boolean {
    return !this.disabled && this.enableAddon && this._icons.length > 0;
  }

  get addonIcons(): any[] {
    if (this.hasAddon()) {
      return this._icons;
    }
    return [];
  }

  aClick(i?: any) {
    const e: MEAddOnClickEvent = new MEAddOnClickEvent();
    e.component = this;
    e.addon = i;
    this.addonClick.emit(e);
  }
}
