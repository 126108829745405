<me-data-table
        *ngIf="liste!==null && liste!==undefined"
        [rowActionsAsButtons]="true"
        [tableActionsAsButtons]="true"
        [data]="liste.asDataTableRows()"
        [selectable]="false"
        actionsicon="icofont icofont-navigation-menu"
        #applicationsTable
>
    <me-data-table-column [label]="'code'"
                          [field]="'code'"
    ></me-data-table-column>

    <me-data-table-column [label]="'Info'"
                          [field]="'display_text'"
    ></me-data-table-column>

    <me-data-table-column [label]="'Beschreibung'"
                          [field]="'description'"
    ></me-data-table-column>

    <me-data-table-column [label]="'aktiv?'"
                          [field]="'enabled'"
                          type="boolean"
    ></me-data-table-column>

    <me-data-table-column [label]="'IP-Adressen'"
                          [field]="'ip_addresses'"
    ></me-data-table-column>

    <me-data-table-column [label]="'Token'"
                          [field]="'app_token'"
    ></me-data-table-column>

    <me-data-table-column [label]="'Token-Interval'"
                          [field]="'refresh_token_lifetime'"
    ></me-data-table-column>

    <me-data-table-action
            label="Eintrag hinzufügen"
            [icon]="'far fa-plus'"
            (perform)="addEntry()"
    ></me-data-table-action>

    <me-data-table-rowaction
            (perform)="editEntry($event)"
            icon="far fa-pencil"
            label="Bearbeiten"
            title="Eintrag bearbeiten"
    ></me-data-table-rowaction>

    <me-data-table-rowaction
            (perform)="removeEntry($event)"
            icon="far fa-trash"
            label="Löschen"
            title="Eintrag löschen"
    ></me-data-table-rowaction>

    <me-data-table-table-extra-header>
        <me-pagination
                [total]="liste.total"
                [page]="liste.page"
                [perpage]="liste.size"
                (pageChange)="liste.setPage($event)"
                (pageCountChange)="liste.processPageCountChangedEvent($event)"
                (queryChange)="liste.processQueryChangedEvent($event)"
                settingsid="webfuel-applications"
                [settingsService]="benutzerService"
                [showSearch]="true"
        ></me-pagination>
    </me-data-table-table-extra-header>
</me-data-table>