import { IMESimpleFilter } from './simple-filter';

export class MEGenericFilter<T> implements IMESimpleFilter<T> {
  public _operations = {};

  public clear(): void {
    Object.keys(this).forEach((k) => {
      if (!k.startsWith('_')) {
        this[k] = null;
      }
    });
    this._operations = {};
  }

  public encodeField(fieldname: string): string {
    return (
      'filter.' +
      encodeURIComponent(fieldname) +
      '=' +
      encodeURIComponent('' + this[fieldname])
    );
  }

  public skipField(fieldname) {
    return false;
  }

  public asURL(): string {
    let url = '';

    Object.keys(this).forEach((k) => {
      if (
        this[k] !== null &&
        this[k] !== undefined &&
        this[k] !== '' &&
        k !== '_operations' &&
        !this.skipField(k)
      ) {
        url += url !== '' ? '&' : '';
        url += this.encodeField(k);
      }
    });

    if (this._operations !== undefined && this._operations !== null) {
      Object.keys(this._operations).forEach((k) => {
        if (
          this._operations[k] !== null &&
          this._operations[k] !== undefined &&
          this._operations[k] !== '' &&
          !this.skipField(k)
        ) {
          url += url !== '' ? '&' : '';
          url +=
            'operation.' +
            encodeURIComponent(k) +
            '=' +
            encodeURIComponent('' + this._operations[k]);
        }
      });
    }

    return url;
  }

  public hasFieldValue(key: string): boolean {
    return (
      this[key] !== null &&
      this[key] !== undefined &&
      this[key] !== '' &&
          !(Array.isArray(this[key]) && this[key].length === 0)
    );
  }

  public hasValues(): boolean {
    let r = false;
    Object.keys(this).forEach((k) => {
      if (!k.startsWith('_')) {
        if (this.hasFieldValue(k)) {
          r = true;
        }
      }
    });
    return r;
  }

  public copyFrom(filter: IMESimpleFilter<T>): void {
    this.clear();
    Object.keys(filter).forEach((k) => {
      this[k] = filter[k];
    });
  }

  public setOperation(field: string, op: string) {
    if (this._operations === null || this._operations === undefined) {
      this._operations = {};
    }
    this._operations[field] = op;
  }
}
