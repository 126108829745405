import {MEGenericEntity} from "../../../maennl-commons";
import {IIPSecInfoChild} from "./ipsec-info-child.interface";
import {TAPIDate, TNullableBoolean, TNullableNumber, TNullableString} from "../../../maennl-commons/tools/types";

export class IPSecInfoChild
    extends MEGenericEntity<IIPSecInfoChild>
    implements IIPSecInfoChild{
    bytes_in: TNullableNumber=null;
    bytes_out: TNullableNumber=null;
    dh_group: TNullableString=null;
    encap: TNullableBoolean=null;
    encr_alg: TNullableString=null;
    encr_keysize: TNullableNumber=null;
    if_id_in: TNullableString=null;
    if_id_out: TNullableString=null;
    install_time: TNullableNumber=null;
    integ_alg: TNullableString=null;
    life_time: TNullableNumber=null;
    local_ts: TNullableString=null;
    mode: TNullableString=null;
    name: TNullableString=null;
    packets_in: TNullableNumber=null;
    packets_out: TNullableNumber=null;
    protocol: TNullableString=null;
    rekey_time: TNullableNumber=null;
    remote_ts: TNullableString=null;
    req_id: TNullableNumber=null;
    spi_in: TNullableString=null;
    spi_out: TNullableString=null;
    state: TNullableString=null;
    unique_id: TNullableNumber=null;
    use_in: TNullableNumber=null;
    use_out: TNullableNumber=null;
    install_time_date: TAPIDate=null;

    constructor() {
        super();
    }
}