import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { METool } from '../../../maennl-commons/tools';
import { ZsrChunk } from './zsr-chunk';

@Component({
  selector: 'app-zsr-display',
  templateUrl: './zsrdisplay.component.html',
  styleUrls: ['./zsrdisplay.component.scss']
})
export class ZSRDisplayComponent implements OnInit {
  chunks: ZsrChunk[] = [];

  origin = [];
  public showOrigin = false;

  @Input()
  set data(v: string) {
    this.chunks = [];
    if (!METool.isNullOrUndefined(v)) {
      let currentChunk = new ZsrChunk();
      const bytes: string[] = v.match(/.{2}/g);
      this.origin = bytes;
      let stxFound = false;
      let pos = -1;
      let hasFF = false;
      while (pos < bytes.length - 1) {
        pos++;
        let oneByte = bytes[pos];
        let numByte = parseInt(oneByte, 16);
        switch (numByte) {
          case 0x02:
            if (!stxFound) {
              if (currentChunk.hasBytes()) {
                this.chunks.push(currentChunk);
                currentChunk = new ZsrChunk();
              }
              hasFF = false;
              stxFound = true;
            }
            currentChunk.add(numByte);

            break;
          case 0x03:
            currentChunk.add(numByte);
            if (stxFound) {
              if (currentChunk.hasFillbytes()) {
                // Fillbyte nach ETX kopieren
                pos++;
                if (pos < bytes.length) {
                  oneByte = bytes[pos];
                  numByte = parseInt(oneByte, 16);
                  if (numByte === 0xff) {
                    currentChunk.add(numByte);
                  } else {
                    pos--;
                  }
                } else {
                  pos--;
                }
              }
              // CRC kopieren
              pos++;
              if (pos < bytes.length) {
                oneByte = bytes[pos];
                numByte = parseInt(oneByte, 16);
                currentChunk.add(numByte);
              } else {
                pos--;
              }
              if (currentChunk.hasFillbytes()) {
                // Fillbyte nach CRC kopieren
                pos++;
                if (pos < bytes.length) {
                  oneByte = bytes[pos];
                  numByte = parseInt(oneByte, 16);
                  if (numByte === 0xff) {
                    currentChunk.add(numByte);
                  } else {
                    pos--;
                  }
                } else {
                  pos--;
                }
              }

              this.chunks.push(currentChunk);
              currentChunk = new ZsrChunk();
              stxFound = false;
            }
            break;
          default:
            currentChunk.add(numByte);
        }
      }
      if (currentChunk.hasBytes()) {
        this.chunks.push(currentChunk);
      }
    }
    this.cd.markForCheck();
  }

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit(): void {}
}
