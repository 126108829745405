import {BenutzerService} from "../../../benutzer/services/benutzer.service";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {IMEListResult, MEApiTool, MEResultMetaSort} from "../../../maennl-commons";
import {IOpenVPNStatus} from "./openvpn-status.interface";
import {IMESimpleFilter} from "../../../maennl-commons/filters";
import {take} from "rxjs/operators";

@Injectable({
    providedIn: 'root',
})
export class OpenvpnStatusService {
    constructor(public http: HttpClient, public loginService: BenutzerService) {
    }

    public list(
        max = 50,
        offset = 0,
        sort: MEResultMetaSort[] = [],
        filter: IMESimpleFilter<IOpenVPNStatus> = null,
        queryString = ''
    ): Observable<IMEListResult<IOpenVPNStatus>> {
        const uri = MEApiTool.buildListURL(
            '/api/meconnect/netzwerk/openvpnstatus',
            max,
            offset,
            sort,
            filter,
            queryString);
        return this.http
            .get<IMEListResult<IOpenVPNStatus>>(
                uri,
                this.loginService.getRequestOptions())
            .pipe(take(1));
    }
}
