import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit, ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {MEGenericModalComponent, MEPubSubService, METabCardComponent, METool} from "../../../../maennl-commons";
import {Tenant} from "../../commons/tenant";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TenantService} from "../../commons/tenant.service";
import {ToastrService} from "ngx-toastr";
import {TabTenantsEditorInfosComponent} from "./tab-tenants-editor-infos/tab-tenants-editor-infos.component";
import {TabTenantsEditorModulesComponent} from "./tab-tenants-editor-modules/tab-tenants-editor-modules.component";
import {TabTenantsEditorSettingsComponent} from "./tab-tenants-editor-settings/tab-tenants-editor-settings.component";

type TTabType=null
    | TabTenantsEditorInfosComponent
    | TabTenantsEditorModulesComponent
    | TabTenantsEditorSettingsComponent;

@Component({
    selector: 'app-tab-tenants-editor',
    templateUrl: './tab-tenants-editor.component.html',
    styleUrls: ['./tab-tenants-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TabTenantsEditorComponent
    extends MEGenericModalComponent<Tenant>
    implements OnInit, OnDestroy, AfterViewInit
{
    @ViewChild(METabCardComponent, {static: false}) tabs: METabCardComponent;
    @ViewChild('tenantEditorTab', {static: false}) ka: TabTenantsEditorInfosComponent;

    entity: Tenant=null;
    private _currentTab: TTabType = null;

    public initEntity(src: Tenant):Tenant{
        return Tenant.fromResult(src,true);
    }

    constructor(
        public activeModal: NgbActiveModal,
        public pubsub: MEPubSubService,
        public service: TenantService,
        public toastr: ToastrService,
        public cd: ChangeDetectorRef
    ) {
        super(activeModal, pubsub);
    }

    ngOnInit() {}

    public get currentTab():TTabType{
        return this._currentTab;
    }


    public set currentTab(value:TTabType){
        if(this._currentTab!==value){
            if(!METool.isNullOrUndefined(this._currentTab)) {
                this._currentTab.onDeactivate();
            }
            this._currentTab = value;
            if(!METool.isNullOrUndefined(this._currentTab)) {
                this._currentTab.onActivate();
            }
        }
    }

    ngAfterViewInit() {
        this.currentTab=this.ka;
    }

    public activate(tab:TTabType) {
        this.currentTab=tab;
    }

    save() {
        if(!this.entity.default_entry_point.startsWith('/')){
            this.entity.default_entry_point='/'+this.entity.default_entry_point;
        }
        if(this.entity.terminal_import_pattern.trim()===''){
            this.entity.terminal_import_pattern = "not_used=not_used";
        }
        this.service.store(this.entity).subscribe({
            next:(r) =>{
                if(r.success) {
                    this.ok();
                } else {
                    this.toastr.error('Fehler beim Speichern der Daten');
                }
            },
            error:() =>{
                this.toastr.error('Fehler beim Speichern der Daten');
            }
        });
    }

    changedEntity($event: Tenant) {
        this.entity=$event;
    }
}