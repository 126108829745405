import { Component, Input, OnInit } from '@angular/core';
import { DeviceType } from '../../common/device-type';
import { IDeviceType } from '../../common/idevice-type';

@Component({
  selector: 'app-device-type-display',
  template: ` <div *ngIf="isValid()">{{ value.label }}</div> `
})
export class DeviceTypeDisplayComponent implements OnInit {
  @Input() public value: IDeviceType | DeviceType = null;

  constructor() {}

  ngOnInit() {}

  isValid() {
    return this.value !== null && this.value !== undefined;
  }
}
