import {
  MEGenericEntity,
  MEGenericEntityConverterParams,
  MEGenericEntityFields
} from '../../maennl-commons/megeneric-entity';
import { DeviceType } from './device-type';
import { IDevice } from './idevice';
import { IDeviceType } from './idevice-type';
import { IModem } from './imodem';
import { ISim } from './isim';
import { Modem } from './modem';
import { Sim } from './sim';

export class Device extends MEGenericEntity<IDevice> implements IDevice {
  modem: Modem = null;
  notes = '';
  dateCreated: Date = null;
  lastUpdated: Date = null;
  lastSeen: Date = null;
  machine_id = '';
  diskCapacity = 0;
  diskFree = 0;
  diskAvailable = 0;
  load1 = 0;
  load5 = 0;
  load15 = 0;
  processes = '';
  remoteAccessPort = 0;
  online = false;
  tunnelEndpoint = '';
  id = 0;
  bezeichnung = '';
  deviceType: DeviceType = null;
  sim: Sim = null;
  username = '';
  password = '';
  seriennummer = '';
  boxnummer: number = null;

  static fromResult(entity: IDevice, createIfNull: boolean = false): Device {
    if (entity === null || entity === undefined) {
      if (createIfNull) {
        return new Device();
      }
      return null;
    }

    const instance: Device = new Device();
    instance.updateFromResult(
      entity,
      new MEGenericEntityFields()
        .addLongField('id')
        .addTextField('bezeichnung')
        .addTextField('notes')
        .addDateField('dateCreated')
        .addDateField('lastUpdated')
        .addDateField('lastSeen')
        .addTextField('machine_id')
        .addLongField('diskCapacity')
        .addLongField('diskFree')
        .addLongField('diskAvailable')
        .addDecimalField('load1')
        .addDecimalField('load5')
        .addDecimalField('load15')
        .addTextField('processes')
        .addLongField('remoteAccessPort')
        .addBooleanField('online')
        .addTextField('tunnelEndpoint')
        .addTextField('seriennummer')
        .addLongField('boxnmmer')
        .addConverterField(
          'deviceType',
          new MEGenericEntityConverterParams<DeviceType>((value: any) => {
            return DeviceType.fromResult(value);
          })
        )
        .addConverterField(
          'sim',
          new MEGenericEntityConverterParams<Sim>((value: any) => {
            return Sim.fromResult(value);
          })
        )
        .addConverterField(
          'modem',
          new MEGenericEntityConverterParams<Modem>((value: any) => {
            return Modem.fromResult(value);
          })
        )
        .addTextField('username')
        .addTextField('password')
    );
    return instance;
  }

  constructor() {
    super();
  }
}
