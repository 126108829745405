import { IMEListResult, MERemoteList } from '../../../maennl-commons';
import { INozzleReader } from './inozzle-reader';
import { NozzleReader } from './nozzle-reader';
import { NozzleReaderFilter } from './nozzle-reader.filter';

export class NozzleReaderList extends MERemoteList<NozzleReader> {
  __classname = 'VehicleTrackerList';
  __instance = '';

  static fromListResult(res: IMEListResult<INozzleReader>): NozzleReaderList {
    const newList = new NozzleReaderList();
    newList.populateFromListResult(res);
    return newList;
  }

  constructor(autorun: boolean = false, timeout: number = 400) {
    super();
    this.simpleFilter = new NozzleReaderFilter();
    this.initList(autorun, timeout);
  }

  public doPopulateFromListResult(
    res: IMEListResult<any>,
    defaultSort: string = 'nummer',
    defaultOrder: string = 'asc',
    clearFilter: boolean = true
  ): void {
    super.doPopulateFromListResult(res, defaultSort, defaultOrder, clearFilter);
  }

  convertEntity(entity: any): any {
    return NozzleReader.fromResult(entity);
  }
}
