import { IMESimpleFilter } from './simple-filter';

export class EmptyFilter implements IMESimpleFilter<any> {
  public clear(): void {
    // do nothing
  }

  public asURL(): string {
    return '';
  }

  public hasValues(): boolean {
    return false;
  }

  // noinspection JSUnusedLocalSymbols
  public copyFrom(filter: IMESimpleFilter<any>): void {
    // do nothing
  }
}
